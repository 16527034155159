import styled from '@emotion/styled';
import { Table } from '@mui/material';
import React from 'react';

export const ComparisonTableWrapper = styled(Table)(() => ({
    border: '1px solid',
    borderColor: '#E3E3E3',
    // display: 'table-cell',
    borderRadius: '4px',
    width: '100%',
}));
