import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useRef, useState } from 'react';
import { UtilsService } from '../../services/utils.service';
import { AccountActionHeader } from '../authorize/account-action-header';

export const HeaderContainerBox = styled(Box)(() => ({
    backgroundColor: '#F9F9FF',
    position: 'fixed',
    zIndex: 10,
    right: 0,
    boxShadow: '0px 12px 12px -19px #000',
}));

interface IProps {
    children: any;
}

export const HeaderContainer = (props: IProps) => {
    const [height, setHeight] = useState(0);
    const measuredRef = useRef();
    useEffect(() => {
        //@ts-ignore
        setHeight(measuredRef?.current?.clientHeight);
        //@ts-ignore
    }, [measuredRef?.current?.clientHeight, props.children]);

    useEffect(() => {}, [measuredRef]);
    return (
        <Box pb={`${height}px`}>
            <Grid container>
                <Grid item xs={12}>
                    <HeaderContainerBox
                        className={'available-width'}
                        ref={measuredRef}
                    >
                        <AccountActionHeader />
                        {props.children}
                    </HeaderContainerBox>
                </Grid>
            </Grid>
        </Box>
    );
};
