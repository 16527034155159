interface IProps {
    type: string;
    textBackgroundColor: string;
    size?: number;
}

export const FileIcon = (props: IProps) => {
    return (
        <svg
            width={props.size || 40}
            height={props.size || 40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M33.3859 6.34375V33.6562C33.3859 33.9878 33.2542 34.3057 33.0198 34.5401C32.7854 34.7745 32.4675 34.9062 32.1359 34.9062H12.5859C12.2544 34.9062 11.9365 34.7745 11.7021 34.5401C11.4676 34.3057 11.3359 33.9878 11.3359 33.6562V14.4312L18.2109 5.09375H32.1359C32.4675 5.09375 32.7854 5.22545 33.0198 5.45987C33.2542 5.69429 33.3859 6.01223 33.3859 6.34375Z"
                fill="#EDEEEF"
            />
            <path
                d="M18.2109 5.10645V13.1252C18.2109 13.4567 18.0792 13.7747 17.8448 14.0091C17.6104 14.2435 17.2925 14.3752 16.9609 14.3752H11.3672L18.2109 5.10645Z"
                fill="#CFD0D1"
            />
            <path
                d="M24.9344 22.1689H7.85937C7.16902 22.1689 6.60938 22.7286 6.60938 23.4189V29.6939C6.60938 30.3843 7.16902 30.9439 7.85937 30.9439H24.9344C25.6247 30.9439 26.1844 30.3843 26.1844 29.6939V23.4189C26.1844 22.7286 25.6247 22.1689 24.9344 22.1689Z"
                fill={props.textBackgroundColor}
            />
            <text x="27" y="71" transform="scale(.4,.4)" fill="#ffffff">
                {props.type}
            </text>
        </svg>
    );
};
