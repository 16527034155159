export const XIcon = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_8593_112769)">
            <mask
                id="mask0_8593_112769"
                maskUnits="userSpaceOnUse"
                x="0"
                y="-1"
                width="21"
                height="21"
            >
                <path
                    d="M0.00195312 -0.0078125H20.002V19.9922H0.00195312V-0.0078125Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_8593_112769)">
                <path
                    d="M15.752 0.929688H18.8191L12.1191 8.60683L20.002 19.0554H13.8305L8.99338 12.7197L3.46481 19.0554H0.39481L7.56052 10.8411L0.00195312 0.931116H6.33052L10.6962 6.72112L15.752 0.929688ZM14.6734 17.2154H16.3734L5.40195 2.67397H3.5791L14.6734 17.2154Z"
                    fill="black"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_8593_112769">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
