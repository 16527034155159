import * as React from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { InputDownArrow } from '../../icons/input-down-arrow';
import List from 'rc-virtual-list';
import {
    FormControlLabel,
    InputAdornment,
    Menu,
    Typography,
} from '@mui/material';
import { UtilsService } from '../../../services/utils.service';
import { FilterUnchecked } from '../../icons/filter-unchecked';
import { FilterChecked } from '../../icons/filter-checked';
import { SearchInsights } from '../../icons/search-insights';
import { Input } from '.';

const ITEM_HEIGHT = 58;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface IDownArrowProps {
    onClick: () => void;
}

export const SelectDownArrow = (props: IDownArrowProps) => {
    return (
        <Box onClick={props.onClick} sx={{ cursor: 'pointer' }} p={2.5} pr={3}>
            <InputDownArrow />
        </Box>
    );
};

interface IProps {
    options: any[];
    placeholder: string;
    getOptionText: Function;
    disableSearch?: boolean;
    getOptionId: Function;
    onChange: Function;
    textColor?: string;
    selectedValue?: any[];
    icon?: JSX.Element;
    pt?: number;
    width?: number;
    fullWidth?: boolean;
    contentWidth?: number;
    ptContainer?: number;
    pb?: number;
    ml?: number;
    mt?: number;
    backgroundColor?: string;
    border?: string;
    borderRadius?: number;
    title: string;
    disableCheckbox?: boolean;
}

export default function MultiSelect(props: IProps) {
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectEl, setSelectEl] = useState<null | HTMLElement>(null);
    const openSelect = Boolean(selectEl);
    const selectContainer = React.useRef();
    const [selectedValues, setSelectedValues] = useState<any[]>(
        props.selectedValue || []
    );

    const handleChange = (value: SelectChangeEvent<typeof selectedValues>) => {
        // handleSelectClose();
        let values: any[] = [];
        if (
            selectedValues
                .map((o) => props.getOptionId(o))
                .includes(props.getOptionId(value))
        ) {
            values = selectedValues.filter(
                (o) => props.getOptionId(o) !== props.getOptionId(value)
            );
        } else {
            values = [...selectedValues, value];
        }
        setSelectedValues([...values]);
        props.onChange([...values]);
    };

    const handleSelectClick = (event: React.MouseEvent<HTMLElement>) => {
        setSelectEl(event.currentTarget);
    };
    const handleSelectClose = () => {
        setSelectEl(null);
    };

    useEffect(() => {
        setSelectedValues(props.selectedValue || []);
    }, [props.selectedValue]);

    return (
        <div>
            <Box
                ml={props.ml}
                pt={
                    props.ptContainer == undefined
                        ? props.pt == undefined
                            ? 2
                            : props.pt
                        : props.ptContainer
                }
            >
                <Box
                    ref={selectContainer}
                    sx={{
                        border: props.border || '1px solid #E3E3E3',
                        borderRadius: props.borderRadius || 2,
                        backgroundColor: props.backgroundColor,
                        px: 2,
                        width: props.fullWidth ? '100%' : props.width,
                        pt: props.pt == undefined ? 3 : props.pt,
                        pb:
                            props.pb !== undefined
                                ? props.pb
                                : props.icon
                                ? 1
                                : 3,
                        mt: props.mt != undefined ? props.mt : 1,
                        cursor: 'pointer',
                    }}
                    onClick={handleSelectClick}
                    display="flex"
                    justifyContent="space-between"
                >
                    <Box display="flex">
                        <Box height={24}>{props.icon}</Box>
                        <Box
                            sx={{
                                width:
                                    (props.contentWidth
                                        ? props.contentWidth
                                        : 100) + 19,
                            }}
                            display="flex"
                        >
                            <Typography
                                aria-label="more"
                                id="summary-button"
                                variant="subtitle2"
                                className="clamp-line-1"
                                aria-controls={
                                    openSelect ? 'long-menu' : undefined
                                }
                                aria-expanded={openSelect ? 'true' : undefined}
                                aria-haspopup="true"
                                sx={{
                                    pl: 2,
                                    mt: 0.5,
                                    color: props.textColor,
                                    fontWeight: 400,
                                    width: props.contentWidth || '100px',
                                    fontSize: 16,
                                    lineHeight: '19px',
                                    textAlign: 'left',
                                }}
                            >
                                {props.selectedValue?.length
                                    ? UtilsService.trimText(
                                          props.selectedValue
                                              .map((o) =>
                                                  props.getOptionText(o)
                                              )
                                              .join(', '),
                                          20
                                      )
                                    : props.placeholder}
                            </Typography>
                            {props.selectedValue &&
                                props.selectedValue.length > 0 && (
                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            mt: 0.5,
                                            color: '#fff',
                                            height: 19,
                                            width: 19,
                                            borderRadius: '50%',
                                            ml: 1,
                                            lineHeight: `18px`,
                                            backgroundColor: '#29319B',
                                        }}
                                    >
                                        {props.selectedValue.length}
                                    </Box>
                                )}
                        </Box>
                    </Box>
                    <Box component="span" ml={4} mr={2}>
                        <InputDownArrow />
                    </Box>
                </Box>

                <Menu
                    id="summary-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                        py: 0,
                        '& .MuiList-root': {
                            paddingTop: '0px',
                        },
                    }}
                    anchorEl={selectEl}
                    open={openSelect}
                    onClose={handleSelectClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width:
                                Math.max(
                                    //@ts-ignore
                                    selectContainer.current?.offsetWidth,
                                    props.width || 180
                                ) || '28ch',
                        },
                    }}
                >
                    {!props.disableSearch && (
                        <Box px={2}>
                            <Input
                                placeholder={`Search ${props.title} here`}
                                type="text"
                                value={searchQuery}
                                onChange={(t) => setSearchQuery(t.target.value)}
                                startAdornment={
                                    <InputAdornment position="end">
                                        <SearchInsights
                                            height={10}
                                            width={10}
                                            fillColor="#000033"
                                        />
                                    </InputAdornment>
                                }
                                sx={{
                                    paddingLeft: 1,
                                    '&>.MuiInputBase-input': {
                                        fontSize: 10,
                                        padding: '4px 14px 4px 12px !important',
                                    },
                                    '&>.MuiInputBase-root': {
                                        paddingLeft: 4,
                                    },
                                }}
                            />
                        </Box>
                    )}
                    <List
                        style={{
                            marginTop: 10,
                        }}
                        data={props.options.filter((o) =>
                            props
                                .getOptionText(o)
                                .toLowerCase()
                                .includes(
                                    searchQuery ? searchQuery.toLowerCase() : ''
                                )
                        )}
                        height={Math.min(
                            200,
                            props.options.filter((o) =>
                                props
                                    .getOptionText(o)
                                    .toLowerCase()
                                    .includes(
                                        searchQuery
                                            ? searchQuery.toLowerCase()
                                            : ''
                                    )
                            ).length * 35
                        )}
                        itemHeight={30}
                        itemKey="id"
                    >
                        {(option) => (
                            <Box>
                                <FormControlLabel
                                    sx={{
                                        fontSize: 12,
                                        marginLeft: 0,
                                    }}
                                    control={
                                        <Checkbox
                                            size="small"
                                            icon={FilterUnchecked}
                                            onClick={() => handleChange(option)}
                                            checked={selectedValues
                                                .map((o) =>
                                                    props.getOptionId(o)
                                                )
                                                .includes(
                                                    props.getOptionId(option)
                                                )}
                                            checkedIcon={FilterChecked}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            {UtilsService.trimText(
                                                props.getOptionText(option),
                                                30
                                            )}
                                        </Typography>
                                    }
                                />
                                <br />
                            </Box>
                        )}
                    </List>

                    {/* {props.options
                        .filter((o) =>
                            props
                                .getOptionText(o)
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                        )
                        .map((option) => (
                            <Box>
                                <FormControlLabel
                                    sx={{
                                        fontSize: 12,
                                        marginLeft: 0,
                                    }}
                                    control={
                                        <Checkbox
                                            size="small"
                                            icon={FilterUnchecked}
                                            onClick={() => handleChange(option)}
                                            checked={selectedValues
                                                .map((o) =>
                                                    props.getOptionId(o)
                                                )
                                                .includes(
                                                    props.getOptionId(option)
                                                )}
                                            checkedIcon={FilterChecked}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            {UtilsService.trimText(
                                                props.getOptionText(option),
                                                30
                                            )}
                                        </Typography>
                                    }
                                />
                                <br />
                            </Box>
                        ))} */}
                </Menu>
            </Box>
        </div>
    );
}
