import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BorderedBox } from '../../../../components/core/box';
import { DriveElement } from './drive-element';
import { useDriveService } from '../../../../services/drive.service';
import {
    IIntegration,
    IIntegrationAccount,
} from '../../../../services/interfaces';
import { useParams, useSearchParams } from 'react-router-dom';
import { IntegrationAccount } from './integration-account';

export const Integration = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { accountId } = useParams();
    const { fetchIntegrations, fetchIntegrationAccounts } = useDriveService();
    const [integrations, setIntegrations] = useState<IIntegration[]>([]);
    const [integrationAccounts, setIntegrationAccounts] = useState<
        IIntegrationAccount[]
    >([]);
    const [integrationAccount, setIntegrationAccount] = useState<
        IIntegrationAccount | undefined
    >(undefined);

    const refreshIntegrations = async () => {
        const _integrations = await fetchIntegrations();
        setIntegrations(_integrations);
    };
    const refreshIntegrationAccounts = async () => {
        const _integrationAccounts = await fetchIntegrationAccounts();
        setIntegrationAccounts(_integrationAccounts);
    };
    useEffect(() => {
        if (integrations.length == 0) {
            refreshIntegrations();
        }
        if (integrationAccounts.length == 0) {
            refreshIntegrationAccounts();
        }
    }, []);

    const handleBackClick = () => {
        searchParams.delete('account_id');
        searchParams.delete('integration_name');
        searchParams.delete('add-source');
        setSearchParams(searchParams);
        setIntegrationAccount(undefined);
    };

    useEffect(() => {
        if (
            (searchParams.get('account_id') || accountId) &&
            integrationAccounts.length > 0
        ) {
            let _accountId = searchParams.get('account_id') || accountId;
            let ia = integrationAccounts.filter(
                (ia) => ia.account_id == _accountId
            )[0];
            if (ia) {
                setIntegrationAccount(ia);
            }
        }
    }, [searchParams, integrationAccounts]);

    return integrationAccount ? (
        <IntegrationAccount
            onBackClick={handleBackClick}
            integrationAccount={integrationAccount}
        />
    ) : (
        <Box px={4} pb={4}>
            {integrations.map((d) => (
                <DriveElement
                    onDelete={refreshIntegrationAccounts}
                    setIntegrationAccount={setIntegrationAccount}
                    integrationAccounts={integrationAccounts.filter(
                        (ia) => ia.integration_id == d.id
                    )}
                    driveId={d.id}
                />
            ))}
        </Box>
    );
};
