export const Valid = (
    <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_8918_57446)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.227234 8.5C0.227234 6.37827 1.07009 4.34344 2.57038 2.84315C4.07067 1.34285 6.1055 0.5 8.22723 0.5C10.349 0.5 12.3838 1.34285 13.8841 2.84315C15.3844 4.34344 16.2272 6.37827 16.2272 8.5C16.2272 10.6217 15.3844 12.6566 13.8841 14.1569C12.3838 15.6571 10.349 16.5 8.22723 16.5C6.1055 16.5 4.07067 15.6571 2.57038 14.1569C1.07009 12.6566 0.227234 10.6217 0.227234 8.5ZM7.7707 11.924L12.3766 6.16613L11.5446 5.50053L7.6171 10.4083L4.83523 8.0904L4.15257 8.9096L7.7707 11.9251V11.924Z"
                fill="#19AF55"
            />
        </g>
        <defs>
            <clipPath id="clip0_8918_57446">
                <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0.227234 0.5)"
                />
            </clipPath>
        </defs>
    </svg>
);
