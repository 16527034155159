import React from 'react';
import {
    EarningsTrend,
    IDiligenceTemplate,
} from '../../../../../services/interfaces';
import { BorderedBox } from '../../../../../components/core/box';
import { Delete } from '@mui/icons-material';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Box,
    Tooltip,
    IconButton,
    TableCell,
    styled,
    tableCellClasses,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { UtilsService } from '../../../../../services/utils.service';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        padding: '12px 24px',
        borderBottom: 'none',
        textAlign: 'left',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 'none',
        padding: '12px 24px',
        textAlign: 'left',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#F9F9FF',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface IProps {
    earningsTrends: EarningsTrend[];
    reportTitle: string;
}

export const GrowthEstimates = (props: IProps) => {
    const convertPeriod = (period: string) => {
        switch (period) {
            case '0q':
                return 'Current Quarter';
            case '+1q':
                return 'Next Quarter';
            case '0y':
                return 'Current Year';
            case '+1y':
                return 'Next Year';
            case '+5y':
                return 'Next 5 Years (per annum)';
            case '-5y':
                return 'Past 5 Years (per annum)';
            default:
                return period;
        }
    };
    return (
        <BorderedBox sx={{ backgroundColor: '#FFFFFF' }} p={3}>
            <Typography mb={4} variant="h6">
                Growth Estimates
            </Typography>
            <TableContainer
                sx={{
                    border: '1px solid #D7D9EC',
                    borderRadius: 2,
                }}
            >
                <Table stickyHeader aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell
                                sx={{ textAlign: 'left !important' }}
                            >
                                Time Frame
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {props.reportTitle}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                S&P 500
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.earningsTrends.map(
                            (row: EarningsTrend, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                            textAlign: 'left !important',
                                        }}
                                    >
                                        {convertPeriod(row.period)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {row.growthEstimate.companyGrowth
                                            ? `${(
                                                  row.growthEstimate
                                                      .companyGrowth * 100
                                              ).toFixed(2)}%`
                                            : '--'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.growthEstimate.snp500Growth
                                            ? `${(
                                                  row.growthEstimate
                                                      .snp500Growth * 100
                                              ).toFixed(2)}%`
                                            : '--'}
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </BorderedBox>
    );
};
