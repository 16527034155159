import {
    Box,
    Button,
    Drawer,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Close } from '../../../components/icons/close';
import { IDiligenceTemplateConfiguration } from '../../../services/interfaces';
import { AnswerLength, DataSourceOptions } from './config/common-input';

interface IProps {
    config: IDiligenceTemplateConfiguration;
    updateConfig: (config: IDiligenceTemplateConfiguration) => void;
    applyDefaultConfig: () => void;
}

export const DefaultConfiguration = (props: IProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const applyAndClose = () => {
        props.applyDefaultConfig();
        setIsOpen(false);
    };
    return (
        <Box>
            <Button
                sx={{ fontWeight: 600 }}
                onClick={() => setIsOpen(true)}
                variant="outlined"
            >
                Default Configuration
            </Button>
            <Drawer open={isOpen} anchor="right">
                <Box sx={{ width: 600, p: 4 }}>
                    <Box display="flex" pb={4} justifyContent={'space-between'}>
                        <Box>
                            <Typography color="#003" variant="h6">
                                Default Configuration
                            </Typography>
                            <Typography color="#8D8D8D" fontSize={12}>
                                Set the default configuration for all the
                                sections
                            </Typography>
                        </Box>

                        <Tooltip
                            arrow
                            placement="left"
                            title={`Apply and Close`}
                        >
                            <IconButton sx={{ p: 0 }} onClick={applyAndClose}>
                                <Tooltip
                                    arrow
                                    placement="left"
                                    title={`Apply and Close`}
                                >
                                    <Close />
                                </Tooltip>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <AnswerLength
                        updateConfig={props.updateConfig}
                        config={props.config}
                    />
                    <DataSourceOptions
                        updateConfig={props.updateConfig}
                        config={props.config}
                    />
                </Box>
            </Drawer>
        </Box>
    );
};
