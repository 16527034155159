import React, { useEffect } from 'react';
import { useAuthService } from '../../../services/auth.service';

export const Logout = () => {
    const { logOut } = useAuthService();
    useEffect(() => {
        logOut();
    });
    return <div>Logging out</div>;
};
