import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
export interface IProps {
    chartData: any;
}

export const RadarPolygonFill = (props: IProps) => {
    let options: ApexOptions = {
        chart: {
            height: 100,
            type: 'radar',
        },
        dataLabels: {
            enabled: true,
        },
        plotOptions: {
            radar: {
                size: 140,
                polygons: {
                    strokeColors: '#e9e9e9',
                    fill: {
                        colors: ['#f8f8f8', '#fff'],
                    },
                },
            },
        },
        colors: ['#FF4560'],
        xaxis: {
            categories: [
                'Openness to Experience',
                'Conscientiousness',
                'Extraversion',
                'Agreeableness',
                'Neuroticism',
            ],
        },
        yaxis: {
            min: 0,
            max: 100,
            tickAmount: 5,
        },
    };

    return (
        <div>
            <Chart
                options={options}
                series={[props.chartData]}
                type="radar"
                height={350}
            />
        </div>
    );
};
