import { Box, Button, Grid, Slider, Typography } from '@mui/material';
import React from 'react';
import { Input } from '../../../../components/core/input';
import { RangeInput } from '../../../../components/core/input/rangeInput';
import ColorPicker from '../../../../components/core/input/colorPicker';
import { BorderedBox } from '../../../../components/core/box';
import Dropzone from 'react-dropzone';
import { IconPicker } from './iconPicker';
import {
    IChatbotConfig,
    IChatbotConfigData,
} from '../../../../services/interfaces';
import { UtilsService } from '../../../../services/utils.service';

interface IProps {
    config: IChatbotConfig;
    updateConfig: (config: IChatbotConfig) => void;
}

export const ChatBotDesignSettings = (props: IProps) => {
    const updateName = (title: string) => {
        props.updateConfig({
            ...props.config,
            title: title || '',
        });
    };

    const updateWidth = (
        event: Event,
        value: number | number[],
        activeThumb: number
    ) => {
        if (typeof value == 'number')
            props.updateConfig({
                ...props.config,
                designConfig: {
                    ...props.config.designConfig,
                    data: {
                        ...props.config.designConfig.data,
                        config: {
                            ...props.config.designConfig.data.config,
                            appearance: {
                                ...props.config.designConfig.data.config
                                    .appearance,
                                shape: {
                                    ...props.config.designConfig.data.config
                                        .appearance.shape,
                                    container: {
                                        ...props.config.designConfig.data.config
                                            .appearance.shape.container,
                                        width: value,
                                    },
                                },
                            },
                        },
                    },
                },
            });
    };

    const updateIconSize = (
        event: Event,
        value: number | number[],
        activeThumb: number
    ) => {
        if (typeof value == 'number')
            props.updateConfig({
                ...props.config,
                designConfig: {
                    ...props.config.designConfig,
                    data: {
                        ...props.config.designConfig.data,
                        config: {
                            ...props.config.designConfig.data.config,
                            appearance: {
                                ...props.config.designConfig.data.config
                                    .appearance,
                                shape: {
                                    ...props.config.designConfig.data.config
                                        .appearance.shape,
                                    icon: {
                                        ...props.config.designConfig.data.config
                                            .appearance.shape.icon,
                                        size: value,
                                    },
                                },
                            },
                        },
                    },
                },
            });
    };

    const updateRadius = (
        event: Event,
        value: number | number[],
        activeThumb: number
    ) => {
        if (typeof value == 'number')
            props.updateConfig({
                ...props.config,
                designConfig: {
                    ...props.config.designConfig,
                    data: {
                        ...props.config.designConfig.data,
                        config: {
                            ...props.config.designConfig.data.config,
                            appearance: {
                                ...props.config.designConfig.data.config
                                    .appearance,
                                shape: {
                                    ...props.config.designConfig.data.config
                                        .appearance.shape,
                                    container: {
                                        ...props.config.designConfig.data.config
                                            .appearance.shape.container,
                                        radius: value,
                                    },
                                },
                            },
                        },
                    },
                },
            });
    };

    const updatePrimaryColor = (value: string) => {
        props.updateConfig({
            ...props.config,
            designConfig: {
                ...props.config.designConfig,
                data: {
                    ...props.config.designConfig.data,
                    config: {
                        ...props.config.designConfig.data.config,
                        appearance: {
                            ...props.config.designConfig.data.config.appearance,
                            colors: {
                                ...props.config.designConfig.data.config
                                    .appearance.colors,
                                primary: value,
                            },
                        },
                    },
                },
            },
        });
    };

    const updateTextColor = (value: string) => {
        props.updateConfig({
            ...props.config,
            designConfig: {
                ...props.config.designConfig,
                data: {
                    ...props.config.designConfig.data,
                    config: {
                        ...props.config.designConfig.data.config,
                        appearance: {
                            ...props.config.designConfig.data.config.appearance,
                            colors: {
                                ...props.config.designConfig.data.config
                                    .appearance.colors,
                                text: value,
                            },
                        },
                    },
                },
            },
        });
    };

    const updateChatImage = async (file: File) => {
        const fileUrl: string = await UtilsService.toBase64(file);
        props.updateConfig({
            ...props.config,
            designConfig: {
                ...props.config.designConfig,
                data: {
                    ...props.config.designConfig.data,
                    config: {
                        ...props.config.designConfig.data.config,
                        appearance: {
                            ...props.config.designConfig.data.config.appearance,
                            images: {
                                ...props.config.designConfig.data.config
                                    .appearance.images,
                                chatBot: fileUrl,
                            },
                        },
                    },
                },
            },
        });
    };

    const updateChatIcon = async (file: File) => {
        const fileUrl: string = await UtilsService.toBase64(file);
        props.updateConfig({
            ...props.config,
            designConfig: {
                ...props.config.designConfig,
                data: {
                    ...props.config.designConfig.data,
                    config: {
                        ...props.config.designConfig.data.config,
                        appearance: {
                            ...props.config.designConfig.data.config.appearance,
                            images: {
                                ...props.config.designConfig.data.config
                                    .appearance.images,
                                chatIcon: fileUrl,
                            },
                        },
                    },
                },
            },
        });
    };

    return (
        <Box>
            <Box>
                <Typography my={2} variant="h6">
                    My Bot Name
                </Typography>
                <Box
                    sx={{
                        p: 2,
                        mt: 1,
                        borderRadius: 2,
                        backgroundColor: '#F9F9FF',
                        border: '1px solid #D7D9EC',
                    }}
                >
                    <Input
                        mt={0}
                        pt={0}
                        pb={0}
                        autoFocus={true}
                        value={props.config.title}
                        backgroundColor="#fff"
                        type="text"
                        onChange={(e) => {
                            updateName(e.target.value.slice(0, 100));
                        }}
                        placeholder="Chatbot Name"
                    />
                </Box>
            </Box>
            <Box pt={2}>
                <Typography my={2} variant="h6">
                    Shape and Size{' '}
                </Typography>
                <Box>
                    <RangeInput
                        label="Width"
                        defaultValue={
                            props.config.designConfig.data.config.appearance
                                .shape.container.width
                        }
                        min={300}
                        max={600}
                        onChange={updateWidth}
                        valueLabelFormat={(value: number) => value + 'px'}
                        marks={[
                            {
                                value: 300,
                                label: '300px',
                            },
                            {
                                value: 600,
                                label: '600px',
                            },
                        ]}
                    />
                </Box>
                <Box>
                    <RangeInput
                        label="Radius"
                        defaultValue={
                            props.config.designConfig.data.config.appearance
                                .shape.container.radius
                        }
                        min={0}
                        max={40}
                        onChange={updateRadius}
                        marks={[
                            {
                                value: 0,
                                label: '0',
                            },
                            {
                                value: 40,
                                label: '40',
                            },
                        ]}
                    />
                </Box>
                <Box>
                    <RangeInput
                        label="Widget Icon"
                        defaultValue={
                            props.config.designConfig.data.config.appearance
                                .shape.icon
                                ? props.config.designConfig.data.config
                                      .appearance.shape.icon.size
                                : 55
                        }
                        min={40}
                        max={150}
                        onChange={updateIconSize}
                        valueLabelFormat={(value: number) => value + 'px'}
                        marks={[
                            {
                                value: 40,
                                label: '40px',
                            },
                            {
                                value: 150,
                                label: '150px',
                            },
                        ]}
                    />
                </Box>
            </Box>
            <Box pt={2}>
                <Typography my={2} variant="h6">
                    Appearance
                </Typography>
                <Box
                    sx={{
                        borderRadius: 2,
                        backgroundColor: '#F9F9FF',
                    }}
                >
                    <Box>
                        <Box
                            py={2}
                            px={2}
                            justifyContent="space-between"
                            display="flex"
                        >
                            <Typography
                                fontSize={14}
                                color="#4B4B4C"
                                fontWeight={400}
                            >
                                Primary
                            </Typography>
                            <ColorPicker
                                width={20}
                                onColorChange={updatePrimaryColor}
                                height={20}
                                color={
                                    props.config.designConfig.data.config
                                        .appearance.colors.primary
                                }
                            />
                        </Box>
                        <Box
                            sx={{
                                borderTop: '1px solid #D7D9EC',
                            }}
                            py={2}
                            px={2}
                            justifyContent="space-between"
                            display="flex"
                        >
                            <Typography
                                fontSize={14}
                                color="#4B4B4C"
                                fontWeight={400}
                            >
                                Text
                            </Typography>
                            <ColorPicker
                                onColorChange={updateTextColor}
                                width={20}
                                height={20}
                                color={
                                    props.config.designConfig.data.config
                                        .appearance.colors.text
                                }
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box pt={2}>
                <Typography my={2} variant="h6">
                    Widget Icon
                </Typography>
                <IconPicker
                    imageUrl={
                        props.config.designConfig.data.config.appearance.images
                            .chatBot
                    }
                    maxSizeKB={50}
                    onImageSelected={updateChatImage}
                />
            </Box>
            <Box pt={2}>
                <Typography my={2} variant="h6">
                    Chatbot Icon
                </Typography>
                <IconPicker
                    imageUrl={
                        props.config.designConfig.data.config.appearance.images
                            .chatIcon
                    }
                    maxSizeKB={50}
                    onImageSelected={updateChatIcon}
                />
            </Box>
        </Box>
    );
};
