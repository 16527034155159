import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
    styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAccountService } from '../../../services/account.service';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { IAccountAccessToken } from '../../../services/interfaces';
import { BorderedContainedBox } from '../../../components/core/box';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Add } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { UtilsService } from '../../../services/utils.service';
import { Input } from '../../../components/core/input';

const DialogWrapper = styled(Paper)(() => ({
    width: '60%',
    maxWidth: '650px !important',
}));

export const APIKeys = () => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        fetchAccountAccessTokens,
        createAccessToken,
        deteteAccessToken,
        updateAccessToken,
    } = useAccountService();
    const [dialogMode, setDialogMode] = React.useState<
        | 'NONE'
        | 'CREATE_TOKEN'
        | 'TOKEN_CREATED'
        | 'DELETE_TOKEN'
        | 'UPDATE_TOKEN'
    >('NONE');
    const [neverExpire, setNeverExpire] = useState(true);
    const [delay, setDelay] = useState(30);
    const [activeToken, setActiveToken] = useState<IAccountAccessToken | null>(
        null
    );
    const [title, setTitle] = React.useState('');
    const [isCreating, setIsCreating] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [tokens, setTokens] = useState<IAccountAccessToken[]>([]);
    const refreshTokens = async () => {
        let tokensResp = await fetchAccountAccessTokens();
        setTokens([...tokensResp]);
    };
    useEffect(() => {
        refreshTokens();
    }, []);

    const formatToken = (token: string) => {
        return token.substring(0, 7) + token.substring(token.length - 6);
    };

    const handleCopyToClipboard = () => {
        if (activeToken)
            navigator.clipboard.writeText(activeToken.longTermToken);
    };

    const handleClose = () => {
        setActiveToken(null);
        setTitle('');
        setDelay(30);
        setIsCreating(false);
        setIsDeleting(false);
        setDialogMode('NONE');
    };

    const deleteToken = async () => {
        if (!activeToken) return;

        setIsDeleting(true);
        let res = await deteteAccessToken(activeToken.tokenId);
        if (res) {
            await refreshTokens();
            enqueueSnackbar('Api Key deleted', { variant: 'error' });
        }
        handleClose();
    };

    const updateToken = async () => {
        if (!activeToken) return;
        setIsCreating(true);
        let res = await updateAccessToken(title, activeToken.tokenId);
        if (res) {
            await refreshTokens();
            enqueueSnackbar('API Key Name Updated', { variant: 'success' });
        }
        handleClose();
    };

    const openDeleteDialog = (token: IAccountAccessToken) => {
        setTitle('');
        setDelay(30);
        setDialogMode('DELETE_TOKEN');
        setActiveToken(token);
    };

    const openEditDialog = (token: IAccountAccessToken) => {
        setTitle(token.tokenName);
        setDelay(30);
        setDialogMode('UPDATE_TOKEN');
        setActiveToken(token);
    };

    const createToken = async () => {
        setIsCreating(true);
        let res = await createAccessToken(
            title,
            neverExpire ? undefined : delay
        );
        setActiveToken(res);
        if (res) {
            await refreshTokens();
        }
        setDialogMode('TOKEN_CREATED');
        setIsCreating(false);
    };
    return (
        <Box>
            <Typography variant="h3">API Keys</Typography>
            <Typography mt={4} variant="body2">
                Your secret API Keys are listed below. Please note that we do
                not display your secret API keys again after you generate them.
            </Typography>
            <Typography mt={4} variant="body2">
                Do not share your API key with others, or expose it in the
                browser or other client-side code. In order to protect the
                security of your account, Quantum Invest may also automatically
                rotate any API key that we’ve found has leaked publicly.
            </Typography>
            <Typography mt={4} variant="body2">
                For details on the API usage, Please refer to our{' '}
                <a
                    style={{
                        color: '#29319B',
                        fontSize: '16px',
                        fontWeight: 600,
                    }}
                    href="https://github.com/PhotonInsights/photon-public-api/wiki/Quantum-API-Access-Guide"
                    target="_blank"
                >
                    API Documentation
                </a>
            </Typography>

            {tokens.length == 0 && (
                <BorderedContainedBox mt={6}>
                    <Typography variant="h4">Create your first API</Typography>
                    <Typography variant="body2">
                        These keys allow you to send data to the App API
                    </Typography>
                    <Button
                        sx={{ mt: 4, mb: 6, fontWeight: 600 }}
                        startIcon={<Add />}
                        onClick={() => setDialogMode('CREATE_TOKEN')}
                        variant="contained"
                    >
                        Generate API Key
                    </Button>
                    <Box display="flex">
                        <WarningAmberIcon
                            sx={{
                                color: '#CF6100',
                                fontSize: 14,
                                mt: 0.25,
                                mr: 0.5,
                            }}
                        />
                        <Typography
                            fontSize={12}
                            textTransform={'uppercase'}
                            fontWeight={400}
                        >
                            Please do not give out your api keys to anybody who
                            you don’t want to access your files
                        </Typography>
                    </Box>
                </BorderedContainedBox>
            )}
            {tokens.length > 0 && (
                <BorderedContainedBox mt={6}>
                    <Typography variant="h4">Your API</Typography>
                    <Typography variant="body2">
                        These keys allow you to send data to the App API
                    </Typography>
                    <Grid mt={4} px={4} container>
                        <Grid item sm={3}>
                            <Typography fontSize={14} fontWeight={600}>
                                Name
                            </Typography>
                        </Grid>
                        <Grid item sm={2}>
                            <Typography fontSize={14} fontWeight={600}>
                                Key
                            </Typography>
                        </Grid>
                        <Grid item sm={2}>
                            <Typography fontSize={14} fontWeight={600}>
                                Created
                            </Typography>
                        </Grid>
                        <Grid item sm={2}>
                            <Typography fontSize={14} fontWeight={600}>
                                Expires
                            </Typography>
                        </Grid>
                        <Grid item sm={3}></Grid>
                    </Grid>
                    {tokens.map((t) => (
                        <Grid
                            sx={{
                                p: 4,
                                mt: 2,
                                backgroundColor: '#F9F9FF',
                                border: '1px solid #D7D9EC',
                                borderRadius: 2,
                            }}
                            container
                        >
                            <Grid item sm={3}>
                                <Typography fontSize={14} fontWeight={600}>
                                    {t.tokenName}
                                </Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <Typography fontSize={14} fontWeight={600}>
                                    {formatToken(t.longTermToken)}
                                </Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <Typography fontSize={14} fontWeight={600}>
                                    {UtilsService.formatDate(
                                        new Date(t.createdOn)
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <Typography fontSize={14} fontWeight={600}>
                                    {t.validTill
                                        ? UtilsService.formatDate(
                                              new Date(t.validTill)
                                          )
                                        : 'Never'}
                                </Typography>
                            </Grid>
                            <Grid sx={{ textAlign: 'end', pr: 4 }} item sm={3}>
                                <IconButton
                                    aria-label="Edit"
                                    onClick={() => openEditDialog(t)}
                                >
                                    <EditOutlinedIcon
                                        sx={{ color: '#29319B' }}
                                    />
                                </IconButton>
                                <IconButton
                                    aria-label="delete"
                                    sx={{ ml: 6 }}
                                    onClick={() => openDeleteDialog(t)}
                                >
                                    <DeleteIcon sx={{ color: '#D82F44' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                    <Button
                        sx={{ mt: 4, mb: 6, fontWeight: 600 }}
                        startIcon={<Add />}
                        onClick={() => setDialogMode('CREATE_TOKEN')}
                        variant="contained"
                    >
                        Generate API Key
                    </Button>
                </BorderedContainedBox>
            )}
            <Dialog
                PaperComponent={DialogWrapper}
                open={dialogMode != 'NONE'}
                onClose={handleClose}
            >
                <DialogContent>
                    {dialogMode == 'DELETE_TOKEN' && activeToken && (
                        <Box textAlign="center">
                            <ErrorOutlineIcon
                                sx={{ color: '#D82F44', fontSize: 64 }}
                            />
                            <Typography mt={3} textAlign="left" variant="h3">
                                Delete your API Key
                            </Typography>
                            <Typography
                                textAlign="left"
                                color="#4B4B4C"
                                variant="body2"
                            >
                                This API key will immediately be disabled. API
                                requests made using this key will be rejected,
                                which could cause any systems still depending on
                                it to break. Once revoked, you'll no longer be
                                able to view or modify this API key.
                            </Typography>
                            <Box
                                sx={{
                                    p: 4,
                                    mt: 4,
                                    backgroundColor: '#F9F9FF',
                                    border: '1px solid #D7D9EC',
                                    borderRadius: 2,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    sx={{
                                        overflowX: 'auto',
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                    }}
                                    variant="h6"
                                >
                                    {formatToken(activeToken.longTermToken)}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {dialogMode == 'UPDATE_TOKEN' && (
                        <Box>
                            <Box>
                                <Typography color="#000033" variant="h4">
                                    Edit API Key
                                </Typography>
                                <Typography
                                    color="#000033"
                                    mb={2}
                                    fontSize={14}
                                    variant="caption"
                                >
                                    Edit the name of your API Key
                                </Typography>
                            </Box>
                            <Box my={3}>
                                <Input
                                    type="text"
                                    autoFocus
                                    value={title}
                                    fontSize={14}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                    }}
                                    placeholder="Test key"
                                />
                            </Box>
                        </Box>
                    )}
                    {dialogMode == 'CREATE_TOKEN' && (
                        <Box>
                            <Box>
                                <Typography color="#000033" mb={1} variant="h4">
                                    Generate new API Key
                                </Typography>
                                <Typography
                                    color="#4B4B4C"
                                    mb={4}
                                    fontSize={16}
                                    fontWeight={400}
                                >
                                    API keys allow you to make API calls for
                                    your own account
                                </Typography>
                                <Typography color="#000033" variant="h6">
                                    Name your new API Key
                                </Typography>
                            </Box>
                            <Box>
                                <Input
                                    mt={4}
                                    type="text"
                                    autoFocus
                                    value={title}
                                    fontSize={14}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                    }}
                                    placeholder="Test key"
                                />
                            </Box>
                            <Box
                                py={4}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Box>
                                    <Typography color="#003" variant="h6">
                                        Expiration date
                                    </Typography>
                                    <Typography
                                        color="#8D8D8D"
                                        variant="subtitle2"
                                    >
                                        Date on which API will expire
                                    </Typography>
                                </Box>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={neverExpire}
                                                onChange={() =>
                                                    setNeverExpire(!neverExpire)
                                                }
                                            />
                                        }
                                        label="Never Expire"
                                    />
                                </Box>
                            </Box>
                            {!neverExpire && (
                                <Box
                                    pb={4}
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Box>
                                        <Typography
                                            color="#003"
                                            pt={2}
                                            fontSize={14}
                                            fontWeight={600}
                                        >
                                            The key will expire on{' '}
                                            {UtilsService.getDateAheadDays(
                                                new Date(),
                                                delay
                                            )}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            onKeyDown={(evt) => {
                                                if (
                                                    ![
                                                        'ArrowDown',
                                                        'ArrowUp',
                                                    ].includes(evt.code)
                                                )
                                                    evt.preventDefault();
                                            }}
                                            value={delay}
                                            onChange={(evt) =>
                                                setDelay(
                                                    parseInt(evt.target.value)
                                                )
                                            }
                                            InputProps={{
                                                inputProps: {
                                                    max: 365,
                                                    min: 1,
                                                },
                                            }}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )}
                    {dialogMode == 'TOKEN_CREATED' && (
                        <Box textAlign="center">
                            <WarningAmberIcon
                                sx={{ color: '#E59413', fontSize: 64 }}
                            />
                            <Typography mt={3} textAlign="left" variant="h3">
                                Copy this token as it will be only visible this
                                one time
                            </Typography>
                            <Typography
                                textAlign="left"
                                color="#4B4B4C"
                                variant="body2"
                            >
                                Please save this secret key somewhere safe and
                                accessible. For security reasons, you won't be
                                able to view it again through your Quantum API
                                account. If you lose this secret key, you'll
                                need to generate a new one.
                            </Typography>
                            <Box
                                sx={{
                                    p: 4,
                                    mt: 4,
                                    backgroundColor: '#F9F9FF',
                                    border: '1px solid #D7D9EC',
                                    borderRadius: 2,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    sx={{
                                        overflowX: 'auto',
                                        whiteSpace: 'nowrap',
                                        width: 'calc(100% - 40px)',
                                    }}
                                    variant="h6"
                                >
                                    {activeToken?.longTermToken}
                                </Typography>
                                <Box width="30px">
                                    <IconButton onClick={handleCopyToClipboard}>
                                        <ContentCopyRoundedIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', pb: 4 }}>
                    {dialogMode == 'DELETE_TOKEN' && (
                        <Grid px={3} pb={3} spacing={6} container>
                            <Grid xs={6} item>
                                <Button
                                    fullWidth
                                    sx={{ fontWeight: 600 }}
                                    variant="outlined"
                                    disabled={isDeleting}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid xs={6} item>
                                <Button
                                    fullWidth
                                    sx={{
                                        backgroundColor: '#D82F44',
                                        '&:hover': {
                                            backgroundColor: '#fe0000',
                                        },
                                        fontWeight: 600,
                                    }}
                                    variant="contained"
                                    disabled={isDeleting}
                                    onClick={deleteToken}
                                >
                                    {isDeleting ? (
                                        <CircularProgress
                                            size={25}
                                            sx={{
                                                color: '#ffffff',
                                                ml: 2,
                                            }}
                                        />
                                    ) : (
                                        'Delete Key'
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {dialogMode == 'CREATE_TOKEN' && (
                        <Grid px={3} pb={3} spacing={6} container>
                            <Grid xs={6} item>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    disabled={isCreating}
                                    sx={{ fontWeight: 600 }}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid xs={6} item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    disabled={isCreating || !title}
                                    startIcon={isCreating ? null : <Add />}
                                    onClick={createToken}
                                    sx={{ fontWeight: 600 }}
                                >
                                    {isCreating ? (
                                        <CircularProgress
                                            size={25}
                                            sx={{
                                                color: '#ffffff',
                                                ml: 2,
                                            }}
                                        />
                                    ) : (
                                        'Generate API Key'
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    )}

                    {dialogMode === 'UPDATE_TOKEN' && (
                        <Grid px={3} pb={3} spacing={6} container>
                            <Grid xs={6} item>
                                <Button
                                    fullWidth
                                    sx={{ fontWeight: 600 }}
                                    variant="outlined"
                                    disabled={isCreating}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid xs={6} item>
                                <Button
                                    fullWidth
                                    sx={{ fontWeight: 600 }}
                                    variant="contained"
                                    disabled={isCreating || !title}
                                    onClick={updateToken}
                                >
                                    {isCreating ? (
                                        <CircularProgress
                                            size={25}
                                            sx={{
                                                color: '#ffffff',
                                                ml: 2,
                                            }}
                                        />
                                    ) : (
                                        'Save'
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {dialogMode == 'TOKEN_CREATED' && (
                        <Button
                            variant="outlined"
                            sx={{ fontWeight: 600 }}
                            onClick={() => {
                                handleClose();
                                enqueueSnackbar('New API Key Generated', {
                                    variant: 'success',
                                });
                            }}
                        >
                            Close
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    );
};
