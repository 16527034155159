export const UploadFileIcon = () => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_7084_18868)">
            <path
                d="M9.18182 2C7.42376 2 6 3.44 6 5.21429V34.7857C6 36.56 7.42545 38 9.18182 38H30.8182C32.5745 38 34 36.56 34 34.7857V21.2857C34 19.5808 33.3295 17.9456 32.1361 16.74C30.9427 15.5344 29.3241 14.8571 27.6364 14.8571H24.4545C23.6107 14.8571 22.8014 14.5185 22.2047 13.9157C21.608 13.3129 21.2727 12.4953 21.2727 11.6429V8.42857C21.2727 6.72361 20.6023 5.08848 19.4089 3.88288C18.2154 2.67729 16.5968 2 14.9091 2H9.18182Z"
                fill="#9C9FD0"
            />
            <path
                d="M21.6484 2.54297C23.051 4.17662 23.8218 6.26733 23.8189 8.42983V11.6441C23.8189 11.999 24.104 12.287 24.4552 12.287H27.637C29.7777 12.284 31.8473 13.0627 33.4644 14.4795C32.7178 11.611 31.2304 8.99434 29.1543 6.89706C27.0782 4.79978 24.488 3.29719 21.6484 2.54297Z"
                fill="#9C9FD0"
            />
            <path
                d="M11.668 24.166H28.3346"
                stroke="#D7D9EC"
                stroke-linecap="round"
            />
            <path
                d="M11.668 30H20.0013"
                stroke="#D7D9EC"
                stroke-linecap="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_7084_18868">
                <rect width="40" height="40" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
