import { Constants } from '../constants';
import {
    IQueryEntitiesesponseData,
    IQueryEntitiesesponse,
    ChatFilterSource,
    TopicQueryRequest,
    LiveQueryRequest,
    IFetchScrapUrlsResponse,
    IRecentHeadlines,
    LiveQueryResponseDataSearch,
    LiveQueryResponseDataSearchResponse,
    TopicCompareQueryRequest,
    SECFilingQueryRequestReport,
    SECFilingQueryRequest,
    LiveTrendsQueryRequest,
    SentimentResultResponse,
    LiveSentimentsResponse,
    WebQueryRequest,
    FileFilters,
    IPredefinedQueryResponse,
    PredefinedQuery,
    IUpdatePredefinedQueryRequest,
    ServersListResponse,
    Server,
} from './interfaces';
import {
    INetworkResponse,
    NetworkRequestMethod,
    useNetworkAPI,
} from './network.service';

export const useChatbotService = () => {
    const { triggerRequest, triggerRequestWithChunkedResponse } =
        useNetworkAPI();

    const fetchChatbotLiveQueryResponse = async (
        query: string,
        chatBotPrompt: string,
        callback: (data: string) => void,
        userFileIds?: string[],
        chatThreadId?: string | null,
        filters?: FileFilters
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: LiveQueryRequest = {
                    query,
                    stream: true,
                    chatBotPrompt,
                    userFileIds,
                    chatThreadId,
                    filters,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveQuery,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchChatbotQueryResponseFromWeb = async (
        query: string,
        chatBotPrompt: string,
        callback: (data: string) => void,
        chatHistoryId: string,
        chatQueryId: string,
        userFileIds?: string[],
        chatThreadId?: string | null
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: WebQueryRequest = {
                    query,
                    stream: true,
                    chatBotPrompt,
                    userFileIds,
                    searchTheWeb: true,
                    chatHistoryId,
                    chatQueryId,
                    chatThreadId,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveQuery,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchPublicChatbotLiveQueryResponse = async (
        query: string,
        chatBotPrompt: string,
        settings: any,
        callback: (data: string) => void,
        userFileIds?: string[]
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: LiveQueryRequest = {
                    query,
                    stream: true,
                    chatBotPrompt,
                    userFileIds,
                    settings,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.publicLiveQuery,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchChatbotQueryRecentHeadlines = async (
        query: string
    ): Promise<LiveQueryResponseDataSearch> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: LiveQueryRequest = {
                query,
                stream: true,
                chatBotPrompt: 'INSTANT_INSIGHTS',
                detectEntities: true,
            };
            const result: {
                body: LiveQueryResponseDataSearchResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveChatbotQueryRecentHeadlines,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchChatbotQueryEntities = async (
        query: string
    ): Promise<IQueryEntitiesesponseData[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body = {
                query,
            };
            const result: {
                body: IQueryEntitiesesponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveChatbotQueryEntities,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data.data;
            } else {
                throw result.body.success;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchChatModels = async (): Promise<Server[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: ServersListResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.GET,
                    headers,
                    url: Constants.chatModels,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data.servers;
            } else {
                throw result.body.success;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchChatbotQueryResponse = async (
        query: string,
        topicSubscriptionIds: string[],
        startDate: string,
        endDate: string,
        callback: (data: string) => void,
        sources?: ChatFilterSource[]
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: TopicQueryRequest = {
                    query,
                    startDate,
                    endDate,
                    sources,
                    topicSubscriptionIds:
                        topicSubscriptionIds.length > 1
                            ? topicSubscriptionIds
                            : undefined,
                    topicSubscriptionId:
                        topicSubscriptionIds.length == 1
                            ? topicSubscriptionIds[0]
                            : undefined,
                    chatBotPrompt:
                        topicSubscriptionIds.length > 1
                            ? 'DASHBOARD'
                            : 'TOPIC_INSIGHTS',
                    stream: true,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveQuery,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchLiveInsightsYoutubeMediaResponse = async (
        query: string,
        entities: IQueryEntitiesesponseData[],
        callback: (data: string) => void,
        detectEntities?: boolean
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: LiveQueryRequest = {
                    query,
                    stream: true,
                    data: entities,
                    chatBotPrompt: 'INSTANT_INSIGHTS',
                    detectEntities,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveYoutubeMedia,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };
    const fetchLiveInsightsTvCaptionsResponse = async (
        query: string,
        entities: IQueryEntitiesesponseData[],
        callback: (data: string) => void,
        detectEntities?: boolean
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: LiveQueryRequest = {
                    query,
                    data: entities,
                    stream: true,
                    chatBotPrompt: 'INSTANT_INSIGHTS',
                    detectEntities,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveTVCaptions,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchChatbotQueryVolumeTrendResponse = async (
        query: string,
        callback: (data: string) => void,
        entities: IQueryEntitiesesponseData[],
        detectEnitities?: boolean
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: LiveQueryRequest = {
                    query,
                    stream: true,
                    data: entities,
                    chatBotPrompt: 'INSTANT_INSIGHTS',
                    detectEntities: detectEnitities,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveChatbotVolumeTrendQuery,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchLiveInsightsTrendsResponse = async (
        query: string,
        geocode: string,
        resolution: string,
        entities: IQueryEntitiesesponseData[],
        callback: (data: string) => void,
        detectEntities?: boolean
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: LiveTrendsQueryRequest = {
                    query,
                    stream: true,
                    geocode,
                    resolution,
                    data: entities,
                    chatBotPrompt: 'INSTANT_INSIGHTS',
                    detectEntities,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveTrendsInfo,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchChatbotTrendsResponse = async (
        query: string,
        entities: IQueryEntitiesesponseData[],
        callback: (data: string) => void,
        detectEntities?: boolean
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: LiveQueryRequest = {
                    query,
                    stream: true,
                    data: entities,
                    chatBotPrompt: 'INSTANT_INSIGHTS',
                    detectEntities,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveTrendsInfo,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchChatbotFinancialsResponse = async (
        query: string,
        entities: IQueryEntitiesesponseData[],
        callback: (data: string) => void,
        detectEntities?: boolean
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: LiveQueryRequest = {
                    query,
                    stream: true,
                    data: entities,
                    chatBotPrompt: 'INSTANT_INSIGHTS',
                    detectEntities,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveFinancialsInfo,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchLiveInsightsSentiments = async (
        query: string,
        entities: IQueryEntitiesesponseData[],
        detectEntities?: boolean
    ): Promise<LiveSentimentsResponse> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: LiveQueryRequest = {
                query,
                stream: true,
                data: entities,
                chatBotPrompt: 'INSTANT_INSIGHTS',
                detectEntities,
            };
            const result: {
                body: SentimentResultResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveSentiments,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.success;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };
    const fetchChatbotCorporateCultureResponse = async (
        query: string,
        entities: IQueryEntitiesesponseData[],
        callback: (data: string) => void,
        detectEntities?: boolean
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: LiveQueryRequest = {
                    query,
                    stream: true,
                    data: entities,
                    chatBotPrompt: 'INSTANT_INSIGHTS',
                    detectEntities,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveCorporateCulture,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchChatbotGoogleScholar = async (
        query: string,
        entities: IQueryEntitiesesponseData[],
        callback: (data: string) => void,
        detectEntities?: boolean
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: LiveQueryRequest = {
                    query,
                    stream: true,
                    data: entities,
                    chatBotPrompt: 'INSTANT_INSIGHTS',
                    detectEntities,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveGoogleScholar,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchChatbotGooglePatents = async (
        query: string,
        entities: IQueryEntitiesesponseData[],
        callback: (data: string) => void,
        detectEntities?: boolean
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: LiveQueryRequest = {
                    query,
                    stream: true,
                    data: entities,
                    chatBotPrompt: 'INSTANT_INSIGHTS',
                    detectEntities,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveGooglePatents,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchScrapUrlsResponse = async (
        urls: string[]
    ): Promise<IRecentHeadlines[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body = {
                urls,
            };
            const result: {
                body: IFetchScrapUrlsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.scrapUrls,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data.results;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchCompareQueryResponse = async (
        aspectsToCompare: string[],
        topicSubscriptionIds: string[],
        chatBotPrompt: string,
        callback: (data: string) => void
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: TopicCompareQueryRequest = {
                    aspectsToCompare,
                    topicSubscriptionIds,
                    chatBotPrompt,
                    stream: true,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveQuery,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchSECFilingsQueryResponse = async (
        query: string,
        topicSubscriptionId: string,
        callback: (data: string) => void,
        sources?: any[],
        reports?: SECFilingQueryRequestReport[]
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: SECFilingQueryRequest = {
                    query,
                    topicSubscriptionId,
                    sources,
                    reports,
                    chatBotPrompt: 'SEC_FILINGS',
                    stream: true,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveQuery,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchEarningsCallQueryResponse = async (
        query: string,
        topicSubscriptionId: string,
        callback: (data: string) => void,
        sources?: any[],
        sections?: string[]
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: SECFilingQueryRequest = {
                    query,
                    topicSubscriptionId,
                    sources,
                    sections,
                    chatBotPrompt: 'EARNINGS_CALLS',
                    stream: true,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveQuery,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const getPredefinedQueries = async (
        fetchAll: boolean,
        userFileIds?: string[],
        filters?: FileFilters
    ): Promise<PredefinedQuery[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body = {
                fetchAll,
                userFileIds,
                filters,
            };

            const result: {
                body: IPredefinedQueryResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.fetchPredefinedQueries,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data.predefinedQueries;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const updatePredefinedQueries = async (
        body: IUpdatePredefinedQueryRequest
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IPredefinedQueryResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.updatePredefinedQueries,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.success;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    return {
        fetchChatbotLiveQueryResponse,
        fetchChatbotQueryResponseFromWeb,
        fetchChatbotQueryRecentHeadlines,
        fetchChatbotQueryEntities,
        fetchChatbotQueryResponse,
        fetchChatbotQueryVolumeTrendResponse,
        fetchChatbotTrendsResponse,
        fetchChatbotFinancialsResponse,
        fetchChatbotCorporateCultureResponse,
        fetchScrapUrlsResponse,
        fetchCompareQueryResponse,
        fetchSECFilingsQueryResponse,
        fetchEarningsCallQueryResponse,
        fetchLiveInsightsTrendsResponse,
        fetchChatbotGoogleScholar,
        fetchChatbotGooglePatents,
        fetchLiveInsightsYoutubeMediaResponse,
        fetchLiveInsightsTvCaptionsResponse,
        fetchPublicChatbotLiveQueryResponse,
        fetchLiveInsightsSentiments,
        getPredefinedQueries,
        updatePredefinedQueries,
        fetchChatModels,
    };
};
