export const VolumeTrendsIcon = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_152_111224"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
        >
            <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_152_111224)">
            <path
                d="M3.75 16.25V10.4968H6.66665V16.25H3.75ZM8.54167 16.25V3.75H11.4583V16.25H8.54167ZM13.3333 16.25V7.83654H16.25V16.25H13.3333Z"
                fill="#000033"
            />
        </g>
    </svg>
);
