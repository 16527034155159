import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Button,
    IconButton,
    Chip,
    CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import { useBillingService } from '../../../services/billing.service';
import { IPaymentCard } from '../../../services/interfaces';
import loadingAnimation from '../../../components/animations/circular-loader.json';
import Lottie from 'lottie-react';
import { useSnackbar } from 'notistack';
import { useQueryparamServices } from '../../../services/query-param.service';
import { Cards } from '../../../components/images/cards';

// Styled Components
const BillingMethodContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
}));

const CardContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#F9F9FF',
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
}));

const CardDetails = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}));

const CardActions = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
}));

const DefaultChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: 12,
}));

const SetAsDefaultButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 12,
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.action.hover,
    },
}));

const AddNewCardButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 600,
    minWidth: 130,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
}));

// Types
interface CardInfo {
    cardType: string;
    cardNumber: string;
    expDate: string;
    isDefault: boolean;
}

// Card Component
const BillingCard: React.FC<{
    cardInfo: IPaymentCard;
    onSetDefault: () => void;
    onDelete: () => void;
}> = ({ cardInfo, onSetDefault, onDelete }) => (
    <CardContainer>
        <CardDetails>
            <Box display="flex">
                <Typography variant="h6" fontWeight={600}>
                    {cardInfo.type}
                </Typography>
                {!cardInfo.is_default ? (
                    <SetAsDefaultButton
                        variant="outlined"
                        sx={{
                            px: 2,
                            py: 1,
                            ml: 2,
                            borderRadius: 4,
                        }}
                        onClick={onSetDefault}
                    >
                        Set as default
                    </SetAsDefaultButton>
                ) : (
                    <DefaultChip
                        sx={{
                            ml: 2,
                            borderRadius: 4,
                        }}
                        color="success"
                        label="Default"
                    />
                )}
            </Box>
            <Box mt={2} display="flex">
                <Typography color="textSecondary">
                    Card number{' '}
                    <span style={{ color: '#000', marginLeft: 4 }}>
                        {' '}
                        **** **** **** {cardInfo.last_four_digits}
                    </span>
                </Typography>
                <Typography ml={6} color="textSecondary">
                    Exp Date{' '}
                    <span style={{ color: '#000' }}> {cardInfo.expiry}</span>
                </Typography>
            </Box>
        </CardDetails>
        <CardActions>
            {!cardInfo.is_default && (
                <IconButton color="error" onClick={onDelete}>
                    <DeleteIcon />
                </IconButton>
            )}
            {cardInfo.is_default && (
                <Typography color="textSecondary">
                    Default can’t be deleted
                </Typography>
            )}
        </CardActions>
    </CardContainer>
);

// Billing Method Component
export const BillingMethod: React.FC = () => {
    const {
        fetchPaymentDetails,
        addPaymentMethod,
        setPaymentMethodAsDefault,
        deletePaymentMethod,
    } = useBillingService();
    const { enqueueSnackbar } = useSnackbar();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [cards, setCards] = useState<IPaymentCard[] | undefined>(undefined);
    const { removeQueryParams, getQueryParam } = useQueryparamServices();
    const initialize = async () => {
        const res = await fetchPaymentDetails();
        setCards(res);
    };
    useEffect(() => {
        initialize();
    }, []);
    useEffect(() => {
        if (getQueryParam('success')) {
            let success = getQueryParam('success') == 'true';
            if (success) {
                enqueueSnackbar(`Card added successfully`, {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar(`Card adding failed`, {
                    variant: 'error',
                });
            }
            removeQueryParams('success');
        }
    }, [getQueryParam('success')]);
    const handleSetDefault = async (paymentMethodId: string) => {
        // Logic to set the selected card as default
        let res = await setPaymentMethodAsDefault(paymentMethodId);
        if (res.success) {
            enqueueSnackbar(`Card set to default successfully`, {
                variant: 'success',
            });
            initialize();
        }
    };
    const removePaymentMethod = async (paymentMethodId: string) => {
        // Logic to set the selected card as default
        let res = await deletePaymentMethod(paymentMethodId);
        if (res.success) {
            enqueueSnackbar(`Card deleted successfully`, {
                variant: 'success',
            });
            initialize();
        }
    };

    const handleAddNewCardClick = async () => {
        setIsProcessing(true);
        await addPaymentMethod();
        setIsProcessing(false);
    };

    return (
        <BillingMethodContainer>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={3}
            >
                <Box>
                    <Typography variant="h5" mb={1} fontWeight={600}>
                        Billing Method
                    </Typography>
                    <Typography variant="body2" color="textSecondary" mb={3}>
                        Enter a payment method. You won’t be charged for Free
                        Plan.
                    </Typography>
                </Box>
                <AddNewCardButton
                    onClick={handleAddNewCardClick}
                    variant="contained"
                    disabled={isProcessing}
                >
                    {isProcessing ? (
                        <CircularProgress
                            size={25}
                            sx={{
                                color: '#ffffff',
                                ml: 2,
                            }}
                        />
                    ) : (
                        'Add New Card'
                    )}
                </AddNewCardButton>
            </Box>
            {!cards && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 'calc(100vh - 150px)',
                    }}
                >
                    <Lottie animationData={loadingAnimation} loop={true} />
                </Box>
            )}
            {cards?.length == 0 && (
                <Box mt={10} textAlign={'center'}>
                    {Cards}
                    <Typography mt={2}>
                        Add your card details to initiate the payment
                    </Typography>
                    <AddNewCardButton
                        sx={{ mt: 2 }}
                        disabled={isProcessing}
                        onClick={handleAddNewCardClick}
                        variant="contained"
                    >
                        {isProcessing ? (
                            <CircularProgress
                                size={25}
                                sx={{
                                    color: '#ffffff',
                                    ml: 2,
                                }}
                            />
                        ) : (
                            'Add New Card'
                        )}
                    </AddNewCardButton>
                </Box>
            )}
            {cards?.map((card, index) => (
                <BillingCard
                    key={index}
                    cardInfo={card}
                    onSetDefault={() => handleSetDefault(card.id)}
                    onDelete={() => removePaymentMethod(card.id)}
                />
            ))}
        </BillingMethodContainer>
    );
};

export default BillingMethod;
