interface IProps {
    fillColor: string;
}
export const CustomIntelligence = (props: IProps) => (
    <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_9737_59019"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="25"
            height="24"
        >
            <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_9737_59019)">
            <path
                d="M5.6579 4.1579V10.9914V10.9595V21.8422V4.1579ZM8.9737 14.9342H11.9941C12.1542 14.6168 12.3341 14.3217 12.5339 14.049C12.7337 13.7762 12.9533 13.5187 13.1928 13.2764H8.9737C8.73883 13.2764 8.54196 13.3558 8.3831 13.5148C8.22422 13.6737 8.14478 13.8707 8.14478 14.1057C8.14478 14.3406 8.22422 14.5375 8.3831 14.6962C8.54196 14.8549 8.73883 14.9342 8.9737 14.9342ZM8.9737 19.3553H11.214C11.1729 19.079 11.1506 18.8027 11.147 18.5264C11.1435 18.25 11.1587 17.9737 11.1927 17.6974H8.9737C8.73883 17.6974 8.54196 17.7769 8.3831 17.9358C8.22422 18.0948 8.14478 18.2918 8.14478 18.5267C8.14478 18.7617 8.22422 18.9585 8.3831 19.1172C8.54196 19.2759 8.73883 19.3553 8.9737 19.3553ZM5.99962 23.5C5.44025 23.5 4.96714 23.3066 4.5803 22.9198C4.19345 22.5329 4.00003 22.0603 4.00003 21.502V4.49802C4.00003 3.93971 4.19345 3.46714 4.5803 3.0803C4.96714 2.69345 5.43971 2.50003 5.99802 2.50003H13.9496C14.2191 2.50003 14.476 2.55176 14.7203 2.65521C14.9645 2.75865 15.1774 2.90105 15.3588 3.08242L19.9966 7.72023C20.1779 7.90162 20.3204 8.11445 20.4238 8.35871C20.5272 8.603 20.579 8.85991 20.579 9.12944V11.2486C20.314 11.1664 20.0451 11.1048 19.7723 11.0637C19.4996 11.0226 19.2158 10.9985 18.9211 10.9914V9.13157H14.942C14.6601 9.13157 14.4239 9.03624 14.2333 8.84558C14.0427 8.65493 13.9474 8.41868 13.9474 8.13683V4.1579H5.99802C5.91299 4.1579 5.83504 4.19333 5.76417 4.26417C5.69333 4.33504 5.6579 4.41299 5.6579 4.49802V21.502C5.6579 21.5871 5.69333 21.665 5.76417 21.7359C5.83504 21.8067 5.91299 21.8422 5.99802 21.8422H12.0259C12.1861 22.1596 12.3678 22.4546 12.5711 22.7274C12.7745 23.0002 12.9994 23.2577 13.246 23.5H5.99962Z"
                fill={props.fillColor}
            />
        </g>
        <mask
            id="mask1_9737_59019"
            maskUnits="userSpaceOnUse"
            x="13"
            y="13"
            width="11"
            height="10"
        >
            <rect x="13.5" y="13" width="10" height="10" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask1_9737_59019)">
            <path
                d="M14.75 21.3333C14.5208 21.3333 14.3246 21.2517 14.1614 21.0885C13.9983 20.9253 13.9167 20.7292 13.9167 20.5C13.9167 20.2708 13.9983 20.0747 14.1614 19.9115C14.3246 19.7483 14.5208 19.6667 14.75 19.6667H14.8594C14.8906 19.6667 14.9236 19.6736 14.9583 19.6875L16.8542 17.7917C16.8403 17.7569 16.8333 17.724 16.8333 17.6927V17.5833C16.8333 17.3542 16.9149 17.158 17.0781 16.9948C17.2413 16.8316 17.4375 16.75 17.6667 16.75C17.8958 16.75 18.092 16.8316 18.2552 16.9948C18.4184 17.158 18.5 17.3542 18.5 17.5833C18.5 17.5972 18.493 17.6667 18.4792 17.7917L19.5417 18.8542C19.5764 18.8403 19.6094 18.8333 19.6406 18.8333H19.8594C19.8906 18.8333 19.9236 18.8403 19.9583 18.8542L21.4375 17.375C21.4236 17.3403 21.4167 17.3073 21.4167 17.276V17.1667C21.4167 16.9375 21.4983 16.7413 21.6614 16.5781C21.8246 16.4149 22.0208 16.3333 22.25 16.3333C22.4792 16.3333 22.6753 16.4149 22.8385 16.5781C23.0017 16.7413 23.0833 16.9375 23.0833 17.1667C23.0833 17.3958 23.0017 17.592 22.8385 17.7552C22.6753 17.9184 22.4792 18 22.25 18H22.1406C22.1094 18 22.0764 17.9931 22.0417 17.9792L20.5625 19.4583C20.5764 19.4931 20.5833 19.526 20.5833 19.5573V19.6667C20.5833 19.8958 20.5017 20.092 20.3385 20.2552C20.1753 20.4184 19.9792 20.5 19.75 20.5C19.5208 20.5 19.3246 20.4184 19.1614 20.2552C18.9983 20.092 18.9167 19.8958 18.9167 19.6667V19.5573C18.9167 19.526 18.9236 19.4931 18.9375 19.4583L17.875 18.3958C17.8403 18.4097 17.8073 18.4167 17.776 18.4167H17.6667C17.6528 18.4167 17.5833 18.4097 17.4583 18.3958L15.5625 20.2917C15.5764 20.3264 15.5833 20.3594 15.5833 20.3906V20.5C15.5833 20.7292 15.5017 20.9253 15.3385 21.0885C15.1753 21.2517 14.9792 21.3333 14.75 21.3333ZM15.1667 17.1562L14.9062 16.5938L14.3437 16.3333L14.9062 16.0729L15.1667 15.5104L15.4271 16.0729L15.9896 16.3333L15.4271 16.5938L15.1667 17.1562ZM19.75 16.75L19.3542 15.8958L18.5 15.5L19.3542 15.1042L19.75 14.25L20.1458 15.1042L21 15.5L20.1458 15.8958L19.75 16.75Z"
                fill={props.fillColor}
            />
        </g>
    </svg>
);
