import {
    INetworkResponse,
    NetworkRequestMethod,
    useNetworkAPI,
} from './network.service';
import { useNavigate } from 'react-router-dom';
import {
    IChatbotConversationData,
    IChatbotConversationResponse,
    IChatbotConfig,
    IChatbotConfigResponse,
    IChatbotConversationsStatsResponse,
    IChatbotConversationsStatsResponseData,
    IChatbotsResponse,
    IChatbotsResponseData,
    IGenerateWidgetIdResponse,
    IUpsertChatbotResponse,
    WidgetImageUploadLinkResponse,
    WidgetImageUploadLinkResponseData,
    IChatbotConversationHistoryData,
    IChatbotConversationHistory,
    ChatbotSourcesCountResponseData,
    ChatbotSourcesCountResponse,
    IChatbotLeadsResponse,
    IChatbotLeadsResponseData,
} from './interfaces';
import { Constants } from '../constants';
import { UtilsService } from './utils.service';

export const useChatWidgetService = () => {
    const { triggerRequest, triggerUploadRequest } = useNetworkAPI();
    const navigate = useNavigate();

    const generateChatwidgetId = async (): Promise<string> => {
        const headers = {
            'Content-Type': 'application/json',
        };
        const result: {
            body: IGenerateWidgetIdResponse;
            headers: { [key: string]: string };
        } = await triggerRequest({
            method: NetworkRequestMethod.GET,
            headers,
            url: Constants.generateWidgetId,
            retryCount: 3,
        });
        return result.body.data;
    };

    const upsertChatWidget = async (
        req: IChatbotConfig
    ): Promise<IUpsertChatbotResponse> => {
        const headers = {
            'Content-Type': 'application/json',
        };
        if (!req.widgetId) {
            req.widgetId = await generateChatwidgetId();
        }
        if (
            req.designConfig.data.config.appearance.images.chatBot.includes(
                'data:image'
            )
        ) {
            const fileDate = UtilsService.dataURLtoFile(
                req.designConfig.data.config.appearance.images.chatBot,
                'chatBot'
            );
            if (fileDate) {
                let imageUrl = await uploadImage(
                    fileDate.file,
                    fileDate.fileName,
                    req.widgetId
                );
                req.designConfig.data.config.appearance.images.chatBot =
                    imageUrl + '?version=' + Date.now().toString();
            }
        }
        if (
            req.designConfig.data.config.appearance.images.chatIcon.includes(
                'data:image'
            )
        ) {
            const fileDate = UtilsService.dataURLtoFile(
                req.designConfig.data.config.appearance.images.chatIcon,
                'chatIcon'
            );
            if (fileDate) {
                let imageUrl = await uploadImage(
                    fileDate.file,
                    fileDate.fileName,
                    req.widgetId
                );
                req.designConfig.data.config.appearance.images.chatIcon =
                    imageUrl + '?version=' + Date.now().toString();
            }
        }
        const result: {
            body: IUpsertChatbotResponse;
            headers: { [key: string]: string };
        } = await triggerRequest({
            method: NetworkRequestMethod.POST,
            headers,
            body: JSON.stringify(req),
            url: Constants.getWidget,
            retryCount: 3,
        });
        return result.body;
    };

    const getChatbots = async (): Promise<IChatbotsResponseData> => {
        const headers = {
            'Content-Type': 'application/json',
        };
        const result: {
            body: IChatbotsResponse;
            headers: { [key: string]: string };
        } = await triggerRequest({
            method: NetworkRequestMethod.GET,
            headers,
            url: `${Constants.getWidgets}`,
            retryCount: 3,
        });
        return result.body.data;
    };

    const getChatbotConversationStats = async (
        chatbotId: string
    ): Promise<IChatbotConversationsStatsResponseData> => {
        const headers = {
            'Content-Type': 'application/json',
        };
        const result: {
            body: IChatbotConversationsStatsResponse;
            headers: { [key: string]: string };
        } = await triggerRequest({
            method: NetworkRequestMethod.GET,
            headers,
            url: `${Constants.getWidgetConversationsStats}/${chatbotId}/conversations`,
            retryCount: 3,
        });
        return result.body.data;
    };

    const getChatbotConversations = async (
        chatbotId: string,
        pageNo: number,
        perPage: number
    ): Promise<IChatbotConversationData> => {
        const headers = {
            'Content-Type': 'application/json',
        };
        const result: {
            body: IChatbotConversationResponse;
            headers: { [key: string]: string };
        } = await triggerRequest(
            {
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.getWidgetConversations}/${chatbotId}/history?page=${pageNo}&perPage=${perPage}`,
                retryCount: 3,
            },
            ['X-Pi-Content-Length']
        );
        result.body.data.count = parseInt(
            result.headers['X-Pi-Content-Length']
        );
        return result.body.data;
    };

    const getChatBotLeads = async (
        chatbotId: string
    ): Promise<IChatbotLeadsResponseData[]> => {
        const headers = {
            'Content-Type': 'application/json',
        };
        const result: {
            body: IChatbotLeadsResponse;
            headers: { [key: string]: string };
        } = await triggerRequest(
            {
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.getWidgetLeads}/${chatbotId}`,
                retryCount: 3,
            },
            ['X-Pi-Content-Length']
        );

        return result.body.data;
    };

    const getChatbotConversationsHistory = async (
        chatbotId: string,
        widgetSessionChatHistoryId: string
    ): Promise<IChatbotConversationHistoryData> => {
        const headers = {
            'Content-Type': 'application/json',
        };
        const result: {
            body: IChatbotConversationHistory;
            headers: { [key: string]: string };
        } = await triggerRequest(
            {
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.getWidgetConversations}/${chatbotId}/history/${widgetSessionChatHistoryId}`,
                retryCount: 3,
            },
            ['X-Pi-Content-Length']
        );
        return result.body.data;
    };

    const getChatbotById = async (
        chatbotId: string
    ): Promise<IChatbotConfig> => {
        const headers = {
            'Content-Type': 'application/json',
        };
        const result: {
            body: IChatbotConfigResponse;
            headers: { [key: string]: string };
        } = await triggerRequest({
            method: NetworkRequestMethod.GET,
            headers,
            url: `${Constants.getWidget}/${chatbotId}`,
            retryCount: 3,
        });
        return result.body.data;
    };

    const getSourcesCount = async (
        fileTypes: string[],
        userTagIds: string[]
    ): Promise<ChatbotSourcesCountResponseData> => {
        const headers = {
            'Content-Type': 'application/json',
        };
        const result: {
            body: ChatbotSourcesCountResponse;
            headers: { [key: string]: string };
        } = await triggerRequest({
            method: NetworkRequestMethod.POST,
            body: JSON.stringify({
                fileTypes,
                userTagIds,
            }),
            headers,
            url: Constants.getWidgetSourcesCount,
            retryCount: 3,
        });
        return result.body.data;
    };

    const getUploadLink = async (
        chatbotId: string,
        filename: String
    ): Promise<WidgetImageUploadLinkResponseData> => {
        const headers = {
            'Content-Type': 'application/json',
        };
        const result: {
            body: WidgetImageUploadLinkResponse;
            headers: { [key: string]: string };
        } = await triggerRequest({
            method: NetworkRequestMethod.GET,
            headers,
            url: `${Constants.getWidgetImageUploadLink}/${chatbotId}/${filename}`,
            retryCount: 3,
        });
        return result.body.data;
    };

    const uploadImage = async (
        file: File,
        fileName: string,
        chatbotId: string
    ): Promise<string> => {
        const uploadLinkData = await getUploadLink(chatbotId, fileName);
        const result: {
            body: { status: boolean };
            headers: { [key: string]: string };
        } = await triggerUploadRequest({
            method: NetworkRequestMethod.PUT,
            body: file,
            url: uploadLinkData.s3StoragePutLink,
            retryCount: 3,
        });
        return uploadLinkData.s3StorageAccessLink;
    };

    const updateChatbotStatus = async (
        chatbotId: string,
        status: 'ACTIVE' | 'ARCHIVE'
    ): Promise<boolean> => {
        const headers = {
            'Content-Type': 'application/json',
        };
        const result: {
            body: INetworkResponse;
            headers: { [key: string]: string };
        } = await triggerRequest({
            method: NetworkRequestMethod.PATCH,
            headers,
            body: JSON.stringify({
                widgetId: chatbotId,
                action: status,
            }),
            url: Constants.getWidget,
            retryCount: 3,
        });
        return result.body.success;
    };

    const deleteChatbot = async (chatbotId: string): Promise<boolean> => {
        const headers = {
            'Content-Type': 'application/json',
        };
        const result: {
            body: INetworkResponse;
            headers: { [key: string]: string };
        } = await triggerRequest({
            method: NetworkRequestMethod.DELETE,
            headers,
            url: `${Constants.getWidget}/${chatbotId}`,
            retryCount: 3,
        });
        return result.body.success;
    };

    return {
        upsertChatWidget,
        getChatbots,
        getChatbotById,
        updateChatbotStatus,
        deleteChatbot,
        getChatbotConversationStats,
        getChatbotConversations,
        getChatbotConversationsHistory,
        getSourcesCount,
        getChatBotLeads,
    };
};
