import {
    Box,
    Button,
    CircularProgress,
    Divider,
    styled,
    Tooltip,
    Typography,
} from '@mui/material';
import BookmarkFilledIcon from '@mui/icons-material/Bookmark';
import { useEffect, useState } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { DocumentInsightChatSource } from '../../../services/interfaces';
import { ViewFile } from '../../../pages/document-insights/drive/view-file';
import { Circle } from '../../../components/icons/circle';
import { ThumbDown, ThumbUp } from '@mui/icons-material';
import { NavigationLink } from '../../../components/core/link';
import { Copy } from '../../../components/icons/copy';
import { Eye } from '../../../components/icons/eye';
import { BookmarkIcon } from '../../../components/icons/bookmark';

interface IProps {
    sources: DocumentInsightChatSource[];
    messageId: string;
    isBookmarked?: boolean;
    vote?: string;
    selectedCitationIndex?: number;
    onCopyToClipBoardClick?: () => void;
    onUpVoteClick?: () => void;
    onDownVoteClick?: () => void;
    onBookmarkClick?: () => void;
}

const SourceWrapper = styled(Box)(() => ({
    border: '1px solid #D7D9EC',
    borderRadius: 8,
    paddingRight: 8,
    paddingLeft: 8,
    fontSize: 14,
    lineHeight: '24px',
    color: '#4B4B4C',
    marginRight: 8,
    cursor: 'pointer',
}));

export const ActionButton = styled(Button)(() => ({
    marginLeft: 8,
    padding: 0,
    minWidth: 0,
    '&:hover  path': {
        fill: '#4B4B4C',
    },
    '&:hover': {
        backgroundColor: 'transparent !important',
    },
}));

export const ResponseSources = (props: IProps) => {
    const [selectedSource, setSelectedSource] = useState<number | null>(null);
    const scrollToSection = (citationIndex: number) => {
        setTimeout(() => {
            //@ts-ignore
            document
                .getElementById(`${props.messageId}citation${citationIndex}`)
                .scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start',
                });
        }, 0);
    };
    useEffect(() => {
        if (props.selectedCitationIndex && props.sources) {
            let source = props.sources.filter(
                (v) => v.citationIndex == props.selectedCitationIndex
            )[0];
            setSelectedSource(source.id);
            scrollToSection(props.selectedCitationIndex);
        }
    }, [props.selectedCitationIndex, props.sources]);
    return props.sources.length > 0 ? (
        <Box>
            <Box pt={4} pb={2}>
                <Divider />
            </Box>
            <Box>
                <Box display="flex" mb={selectedSource ? 2 : 0} flexWrap="wrap">
                    {props.sources.map((value: DocumentInsightChatSource) => (
                        <SourceWrapper
                            onClick={(event: any) => {
                                event.stopPropagation();
                                setSelectedSource(
                                    selectedSource != value.id ? value.id : null
                                );
                            }}
                            sx={{
                                border:
                                    selectedSource == value.id
                                        ? '1px solid #29319B'
                                        : '1px solid #D7D9EC',
                                color:
                                    selectedSource == value.id
                                        ? '#29319B'
                                        : '#4B4B4C',
                                mt: 2,
                                fontWeight:
                                    selectedSource == value.id ? 600 : 400,
                                backgroundColor: '#ffffff',
                            }}
                        >
                            {value.source ? (
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <img
                                        style={{
                                            maxWidth: 14,
                                            maxHeight: 14,
                                            marginRight: 4,
                                        }}
                                        src={value.icon}
                                    />
                                    {value.source}
                                    <Box
                                        sx={{
                                            marginLeft: '4px',
                                            backgroundColor: '#D7D9EC',
                                            borderRadius: '50%',
                                            height: '14px',
                                            width: '14px',
                                            fontSize: '10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: '#000033',
                                        }}
                                    >
                                        {value.citationIndex
                                            ? value.citationIndex
                                            : 1}
                                    </Box>
                                </span>
                            ) : (
                                `Source ${
                                    value.citationIndex
                                        ? value.citationIndex
                                        : 1
                                }`
                            )}
                        </SourceWrapper>
                    ))}
                </Box>

                {props.sources
                    .filter(
                        (value: DocumentInsightChatSource) =>
                            value.id == selectedSource
                    )
                    .map(
                        (
                            value: DocumentInsightChatSource,
                            index: number,
                            arr: DocumentInsightChatSource[]
                        ) => (
                            <Box
                                id={`${props.messageId}citation${value.citationIndex}`}
                                mt={1}
                            >
                                {!value.url && value.fileName && (
                                    <ViewFile
                                        fileName={value.fileName}
                                        pageNo={value.pageNo}
                                        fileId={value.userFileId}
                                        loader={
                                            <Typography
                                                fontSize={12}
                                                display={'flex'}
                                                variant="subtitle2"
                                                color="#A5A6AB"
                                            >
                                                <span
                                                    className="clamp-line-1"
                                                    style={{
                                                        overflow: 'hidden',
                                                        maxWidth: '80%',
                                                    }}
                                                >
                                                    {value.fileName}{' '}
                                                </span>
                                                {value.pageNo && (
                                                    <Box component="span">
                                                        <Circle
                                                            mt={0}
                                                            h={8}
                                                            mx={8}
                                                            w={8}
                                                        />
                                                        {`Page ${value.pageNo}`}
                                                    </Box>
                                                )}
                                                <Button
                                                    sx={{
                                                        px: 2,
                                                        py: 0,
                                                        minWidth: 30,
                                                    }}
                                                >
                                                    <CircularProgress
                                                        size={14}
                                                    />
                                                </Button>
                                            </Typography>
                                        }
                                    >
                                        <Typography
                                            fontSize={12}
                                            display={'flex'}
                                            variant="subtitle2"
                                            color={'#29319B'}
                                        >
                                            <span
                                                className="clamp-line-1"
                                                style={{
                                                    overflow: 'hidden',
                                                    maxWidth: '80%',
                                                }}
                                            >
                                                {value.fileName}{' '}
                                            </span>
                                            {value.pageNo && (
                                                <Box component="span">
                                                    <Circle
                                                        mt={0}
                                                        h={8}
                                                        mx={8}
                                                        w={8}
                                                    />
                                                    {`Page ${value.pageNo}`}
                                                </Box>
                                            )}
                                            <Button
                                                sx={{
                                                    px: 2,
                                                    py: 0,
                                                    minWidth: 30,
                                                }}
                                            >
                                                <Eye size={14} />
                                            </Button>
                                        </Typography>
                                    </ViewFile>
                                )}

                                {value.url && (
                                    <NavigationLink
                                        to={value.url}
                                        target="_blank"
                                    >
                                        <Typography
                                            sx={{ color: '#29319B' }}
                                            mt={2}
                                            variant="subtitle2"
                                            lineHeight="24px"
                                        >
                                            {value.title}
                                        </Typography>
                                    </NavigationLink>
                                )}

                                {arr.length > 1 && (
                                    <Typography
                                        sx={{
                                            border: '1px solid #a5a6ab',
                                            backgroundColor: '#a5a6ab',
                                            borderRadius: '50%',
                                            fontSize: 10,
                                            px: 1,
                                            color: '#fff',
                                            py: 0.25,
                                            mr: 1,
                                            position: 'relative',
                                            top: '-1px',
                                        }}
                                        component="span"
                                    >
                                        {value.citationIndex}
                                    </Typography>
                                )}
                                {value.summary.split('\n').map((text) => (
                                    <Box>
                                        <Typography
                                            variant="caption"
                                            color="#4B4B4C"
                                        >
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: text,
                                                }}
                                            />
                                        </Typography>
                                    </Box>
                                ))}
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        fontSize={10}
                                        variant="subtitle2"
                                        color="#A5A6AB"
                                    ></Typography>
                                    {index == arr.length - 1 &&
                                        props.onCopyToClipBoardClick && (
                                            <Box display="flex" mb={'12px'}>
                                                {props.onBookmarkClick && (
                                                    <Tooltip
                                                        arrow
                                                        placement="top"
                                                        title="Bookmark"
                                                    >
                                                        <ActionButton
                                                            onClick={
                                                                props.onBookmarkClick
                                                            }
                                                        >
                                                            {props.isBookmarked ? (
                                                                <BookmarkFilledIcon
                                                                    sx={{
                                                                        color: '#E59413',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <BookmarkIcon color="#A5A6AB" />
                                                            )}
                                                        </ActionButton>
                                                    </Tooltip>
                                                )}
                                                <Tooltip
                                                    arrow
                                                    placement="top"
                                                    title="Copy"
                                                >
                                                    <ActionButton
                                                        onClick={
                                                            props.onCopyToClipBoardClick
                                                        }
                                                    >
                                                        <Copy />
                                                    </ActionButton>
                                                </Tooltip>
                                                <Tooltip
                                                    arrow
                                                    placement="top"
                                                    title="Downvote"
                                                >
                                                    <ActionButton
                                                        onClick={
                                                            props.onDownVoteClick
                                                        }
                                                    >
                                                        {props.vote ==
                                                        'downvote' ? (
                                                            <ThumbDownIcon
                                                                sx={{
                                                                    color: '#E59413',
                                                                    fontSize: 20,
                                                                }}
                                                            />
                                                        ) : (
                                                            <ThumbDownOffAltIcon
                                                                sx={{
                                                                    fontSize: 20,
                                                                }}
                                                            />
                                                        )}
                                                    </ActionButton>
                                                </Tooltip>
                                                <Tooltip
                                                    arrow
                                                    placement="top"
                                                    title="Upvote"
                                                >
                                                    <ActionButton
                                                        onClick={
                                                            props.onUpVoteClick
                                                        }
                                                    >
                                                        {props.vote ==
                                                        'upvote' ? (
                                                            <ThumbUpIcon
                                                                sx={{
                                                                    color: '#E59413',
                                                                    fontSize: 20,
                                                                }}
                                                            />
                                                        ) : (
                                                            <ThumbUpOffAltIcon
                                                                sx={{
                                                                    fontSize: 20,
                                                                }}
                                                            />
                                                        )}
                                                    </ActionButton>
                                                </Tooltip>
                                            </Box>
                                        )}
                                </Box>
                            </Box>
                        )
                    )}
                {!selectedSource && props.onCopyToClipBoardClick && (
                    <Box
                        sx={{ float: 'right', mt: 1, mb: '12px' }}
                        display="flex"
                    >
                        {props.onBookmarkClick && (
                            <Tooltip arrow placement="top" title="Bookmark">
                                <ActionButton onClick={props.onBookmarkClick}>
                                    {props.isBookmarked ? (
                                        <BookmarkFilledIcon
                                            sx={{
                                                color: '#E59413',
                                            }}
                                        />
                                    ) : (
                                        <BookmarkIcon color="#A5A6AB" />
                                    )}
                                </ActionButton>
                            </Tooltip>
                        )}
                        <Tooltip arrow placement="top" title="Copy">
                            <ActionButton
                                onClick={props.onCopyToClipBoardClick}
                            >
                                <Copy />
                            </ActionButton>
                        </Tooltip>
                        <Tooltip arrow placement="top" title="Downvote">
                            <ActionButton onClick={props.onDownVoteClick}>
                                {props.vote == 'downvote' ? (
                                    <ThumbDownIcon
                                        sx={{
                                            color: '#E59413',
                                            fontSize: 20,
                                        }}
                                    />
                                ) : (
                                    <ThumbDownOffAltIcon
                                        sx={{
                                            fontSize: 20,
                                            color: '#ccc',
                                        }}
                                    />
                                )}
                            </ActionButton>
                        </Tooltip>
                        <Tooltip arrow placement="top" title="Upvote">
                            <ActionButton onClick={props.onUpVoteClick}>
                                {props.vote == 'upvote' ? (
                                    <ThumbUpIcon
                                        sx={{
                                            color: '#E59413',
                                            fontSize: 20,
                                        }}
                                    />
                                ) : (
                                    <ThumbUpOffAltIcon
                                        sx={{
                                            fontSize: 20,
                                            color: '#ccc',
                                        }}
                                    />
                                )}
                            </ActionButton>
                        </Tooltip>
                    </Box>
                )}
            </Box>
        </Box>
    ) : null;
};
