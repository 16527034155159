import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import groovyWalkAnimation from '../../../components/animations/report-loader.json';
import { useNavigate, useParams } from 'react-router-dom';
import { useDiligenceService } from '../../../services/diligence.service';
import { IDiligenceReport, ReportModule } from '../../../services/interfaces';
import { Overview } from './overview';
import { RecentHeadlines } from './recent-headlines';
import { MarkdownSources } from './markdown-sources';
import { SWOT } from './swot';
import { ManagementTeam } from './management-team';
import { Competitors } from './competitors';
import { REPORT_SECTIONS } from '../constant';
import { SASB } from './sasb';
import { Financials } from './financials';
import Lottie from 'lottie-react';
import { SentimentAnalysis } from './sentiment-analysis';

export const DiligenceReport = () => {
    const { fetchDiligenceReport } = useDiligenceService();
    const [selectedModule, setSelectedModule] = useState<ReportModule | null>(
        null
    );
    const { reportId } = useParams();
    const [report, setReport] = useState<IDiligenceReport | null>(null);
    const refreshReport = async () => {
        if (!reportId) return;
        const report = await fetchDiligenceReport(reportId);
        setSelectedModule(report.reportModules[0]);
        setReport(report);
    };
    useEffect(() => {
        refreshReport();
    }, [reportId]);

    const scrollToModule = (rm: ReportModule) => {
        let element = document.getElementById(rm.name || rm.section);
        if (rm && element) {
            var headerOffset = 150;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition =
                elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    };
    const navigate = useNavigate();
    return (
        <Box px={5} textAlign={'left'}>
            <Box
                sx={{
                    position: 'sticky',
                    width: '100%',
                    height: 40,
                    top: 75,
                    zIndex: 1,
                    backgroundColor: '#fff',
                }}
            >
                <Box
                    pt={6}
                    pb={2}
                    sx={{ backgroundColor: '#fff' }}
                    display="flex"
                >
                    <IconButton
                        sx={{ mt: 2 }}
                        onClick={() => {
                            navigate('/diligence-suite');
                        }}
                    >
                        <KeyboardBackspaceIcon
                            sx={{ cursor: 'pointer', color: '#29319B' }}
                        />
                    </IconButton>
                    <Box px={3} mr={3} sx={{ borderRight: '1px solid #ccc' }}>
                        <Typography variant="h4">Report Viewer</Typography>
                    </Box>

                    <Typography mt={1} color="#4a4b4c" variant="h6">
                        {report?.reportTitle}
                    </Typography>
                </Box>
                <Divider />
            </Box>
            {!report && (
                <Box pt={10} textAlign={'center'}>
                    <Box
                        sx={{
                            mt: 7,
                            height: 350,
                            display: 'flex',
                            justifyContent: 'center',
                            '& > div > svg > g > g:nth-child(1) > rect': {
                                display: 'none',
                            },
                        }}
                    >
                        <Lottie
                            animationData={groovyWalkAnimation}
                            loop={true}
                        />
                    </Box>
                    <Typography mt={10} variant="h4">
                        Loading Report...
                    </Typography>
                    <Typography mt={1} fontSize={14} color="4a4b4c">
                        Please wait till your report gets loaded
                    </Typography>
                </Box>
            )}
            {report && (
                <Box>
                    <Grid py={4} container>
                        <Grid
                            item
                            pt={2}
                            width={'200px'}
                            position={'sticky'}
                            height={'calc(100vh - 220px)'}
                            top={150}
                        >
                            {report?.reportModules
                                .filter((rm) => rm.section !== 'WHITE_LABELING')
                                .map((rm) => (
                                    <Box>
                                        <Box
                                            sx={{
                                                cursor: 'pointer',
                                                backgroundColor: (
                                                    selectedModule?.name
                                                        ? selectedModule?.name ===
                                                          rm.name
                                                        : selectedModule?.section ===
                                                          rm.section
                                                )
                                                    ? '#F9F9FF'
                                                    : 'white',
                                                borderLeft: (
                                                    selectedModule?.name
                                                        ? selectedModule?.name ===
                                                          rm.name
                                                        : selectedModule?.section ===
                                                          rm.section
                                                )
                                                    ? '2px solid #29319B'
                                                    : 'none',
                                            }}
                                            onClick={() => {
                                                setSelectedModule(rm);
                                                scrollToModule(rm);
                                            }}
                                            px={3}
                                            py={2}
                                            width={'100%'}
                                        >
                                            <Typography
                                                fontSize={14}
                                                fontWeight={600}
                                            >
                                                {REPORT_SECTIONS.filter(
                                                    (i) => i.tab == rm.section
                                                )[0]
                                                    ? REPORT_SECTIONS.filter(
                                                          (i) =>
                                                              i.tab ==
                                                              rm.section
                                                      )[0].label
                                                    : rm.name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                        </Grid>
                        <Grid item width={'calc(100% - 200px)'}>
                            <Box pt={2}>
                                {report?.reportModules.map((rm, index) => (
                                    <Box id={rm.name || rm.section} pl={4}>
                                        {/* {rm.section=='RECENT_HAPPENINGS'&& <LiveResponse text={rm.}/>} */}
                                        {rm.section == 'OVERVIEW' && (
                                            <Overview data={rm} />
                                        )}
                                        {rm.section == 'RECENT_HEADLINES' && (
                                            <RecentHeadlines data={rm} />
                                        )}
                                        {rm.section == 'BUSINESS_MODEL' && (
                                            <MarkdownSources
                                                title="Business Model/Strategy"
                                                text={rm.description}
                                                sources={rm.sources}
                                            />
                                        )}
                                        {rm.section == 'RECENT_HAPPENINGS' &&
                                            rm.happenings && (
                                                <MarkdownSources
                                                    title={'Recent Information'}
                                                    text={rm.happenings.text}
                                                    sources={
                                                        rm.happenings.sources
                                                    }
                                                />
                                            )}
                                        {rm.section == 'PRODUCT_OFFERINGS' && (
                                            <MarkdownSources
                                                title="Product Offerings & Services"
                                                text={rm.description}
                                                sources={rm.sources}
                                            />
                                        )}
                                        {rm.section == 'SWOT' && (
                                            <SWOT data={rm} />
                                        )}
                                        {rm.section == 'COMPETITORS' && (
                                            <Competitors data={rm} />
                                        )}
                                        {rm.section == 'MANAGEMENT' && (
                                            <ManagementTeam data={rm} />
                                        )}
                                        {rm.section == 'FINANCIALS' && (
                                            <Financials
                                                data={rm}
                                                reportTitle={report.reportTitle}
                                            />
                                        )}
                                        {rm.section == 'SENTIMENT_ANALYSIS' && (
                                            <SentimentAnalysis data={rm} />
                                        )}

                                        {rm.section == 'RISK' && (
                                            <SASB data={rm} />
                                        )}
                                        {rm.section == 'CUSTOMERS' && (
                                            <MarkdownSources
                                                title="Customers"
                                                text={rm.description}
                                                sources={rm.sources}
                                            />
                                        )}
                                        {rm.section == 'CUSTOM_CATEGORIES' &&
                                            rm.name && (
                                                <MarkdownSources
                                                    title={rm.name}
                                                    text={rm.description}
                                                    sources={rm.sources}
                                                />
                                            )}
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Box>
    );
};
