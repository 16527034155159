export const EmptyBox = (
    <svg
        width="280"
        height="238"
        viewBox="0 0 280 238"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.1"
            d="M278.244 164.094C270.622 125.645 225.971 94.9146 217.262 54.8475C201.433 3.87123 149.261 -4.18822 115.359 17.9507C61.6404 53.0302 118.947 61.7355 13.6225 144.731C-12.3844 165.225 -4.09746 255.687 77.7189 234.926C121.749 223.754 135.134 222.186 154.939 220.346C204.858 215.71 291.037 228.637 278.244 164.094Z"
            fill="#969696"
        />
        <path
            d="M218.607 173.731L141.452 214.498L64.4141 173.731V112.169H218.607V173.731Z"
            fill="white"
        />
        <path
            d="M141.454 215.123C141.352 215.123 141.252 215.098 141.162 215.05L64.126 174.283C64.0258 174.23 63.9419 174.151 63.8832 174.054C63.8246 173.957 63.7934 173.846 63.793 173.733V112.17C63.793 112.004 63.8588 111.845 63.976 111.728C64.0932 111.61 64.2522 111.544 64.418 111.544H218.609C218.775 111.544 218.934 111.61 219.051 111.728C219.168 111.845 219.234 112.004 219.234 112.17V173.731C219.234 173.844 219.202 173.955 219.144 174.052C219.085 174.149 219.001 174.228 218.901 174.281L141.746 215.049C141.657 215.097 141.556 215.123 141.454 215.123ZM65.041 173.355L141.454 213.791L217.984 173.354V112.795H65.041V173.355Z"
            fill="#262626"
        />
        <path
            d="M218.608 98.9592L141.453 58.1923L176.124 41.4736L253.278 82.2411L218.608 98.9592Z"
            fill="white"
        />
        <path
            d="M218.608 99.5846C218.506 99.5846 218.406 99.5599 218.316 99.5126L141.161 58.7451C141.059 58.691 140.974 58.6096 140.915 58.5099C140.856 58.4103 140.826 58.2963 140.828 58.1806C140.83 58.0649 140.864 57.9521 140.927 57.8547C140.989 57.7572 141.077 57.679 141.181 57.6287L175.852 40.91C175.94 40.8673 176.037 40.8459 176.135 40.8478C176.233 40.8496 176.329 40.8745 176.415 40.9205L253.57 81.688C253.672 81.742 253.758 81.8234 253.816 81.923C253.875 82.0226 253.905 82.1366 253.903 82.2522C253.901 82.3679 253.867 82.4807 253.804 82.5781C253.742 82.6755 253.654 82.7537 253.55 82.8039L218.879 99.5226C218.795 99.5635 218.702 99.5847 218.608 99.5846ZM142.839 58.2169L218.621 98.258L251.892 82.2147L176.11 42.1746L142.839 58.2169Z"
            fill="#262626"
        />
        <path
            d="M64.4126 98.9592L141.567 58.1923L106.897 41.4736L29.7422 82.2411L64.4126 98.9592Z"
            fill="white"
        />
        <path
            d="M64.4145 99.5846C64.3205 99.5846 64.2276 99.5634 64.143 99.5226L29.4745 82.8039C29.3704 82.7537 29.2822 82.6755 29.2199 82.5781C29.1575 82.4807 29.1233 82.3679 29.1212 82.2522C29.1191 82.1366 29.1491 82.0226 29.2078 81.923C29.2666 81.8234 29.3518 81.742 29.454 81.688L106.609 40.9205C106.695 40.8745 106.791 40.8496 106.889 40.8478C106.987 40.8459 107.084 40.8673 107.172 40.91L141.843 57.6287C141.947 57.679 142.035 57.7572 142.097 57.8547C142.16 57.9521 142.194 58.0649 142.196 58.1806C142.198 58.2963 142.168 58.4103 142.109 58.5099C142.051 58.6096 141.966 58.691 141.863 58.7451L64.7085 99.5126C64.6178 99.5603 64.5169 99.585 64.4145 99.5846ZM31.1305 82.2157L64.4015 98.259L140.183 58.2179L106.912 42.1746L31.1305 82.2157Z"
            fill="#262626"
        />
        <path
            d="M64.4165 98.9595L141.571 139.727L101.901 161.447L24.7461 120.68L64.4165 98.9595Z"
            fill="white"
        />
        <path
            d="M101.9 162.072C101.798 162.072 101.698 162.047 101.608 162L24.4533 121.232C24.3537 121.179 24.2702 121.1 24.2117 121.004C24.1532 120.908 24.1219 120.797 24.1211 120.684C24.1203 120.571 24.15 120.461 24.2071 120.363C24.2641 120.266 24.3464 120.186 24.4453 120.132L64.1157 98.4108C64.2063 98.3611 64.3078 98.3347 64.4112 98.334C64.5145 98.3333 64.6164 98.3583 64.7077 98.4068L141.863 139.174C141.962 139.227 142.046 139.306 142.104 139.402C142.163 139.499 142.194 139.609 142.195 139.722C142.195 139.835 142.166 139.946 142.109 140.043C142.052 140.14 141.969 140.22 141.871 140.275L102.2 161.996C102.108 162.046 102.005 162.072 101.9 162.072ZM26.0653 120.67L101.895 160.737L140.251 139.736L64.4212 99.6689L26.0653 120.67Z"
            fill="#262626"
        />
        <path
            d="M218.608 98.9595L141.453 139.727L181.124 161.447L258.278 120.68L218.608 98.9595Z"
            fill="white"
        />
        <path
            d="M181.127 162.072C181.022 162.072 180.919 162.046 180.827 161.996L141.156 140.275C141.057 140.22 140.975 140.14 140.918 140.043C140.861 139.946 140.831 139.835 140.832 139.722C140.833 139.609 140.864 139.499 140.923 139.402C140.981 139.306 141.065 139.227 141.164 139.174L218.319 98.4068C218.41 98.3583 218.512 98.3333 218.616 98.334C218.719 98.3347 218.82 98.3611 218.911 98.4108L258.581 120.132C258.68 120.186 258.763 120.266 258.82 120.363C258.877 120.461 258.906 120.571 258.906 120.684C258.905 120.797 258.873 120.908 258.815 121.004C258.757 121.1 258.673 121.179 258.573 121.232L181.419 162C181.329 162.047 181.228 162.072 181.127 162.072ZM142.777 139.736L181.133 160.737L256.962 120.67L218.606 99.6689L142.777 139.736Z"
            fill="#262626"
        />
        <path
            d="M141.569 58.1924L64.4141 98.9593L141.452 139.727L218.607 98.9593L141.569 58.1924Z"
            fill="#9C9FD0"
        />
        <path
            d="M142.078 139.726H140.828V214.498H142.078V139.726Z"
            fill="#262626"
        />
        <path
            d="M98.2248 95.2583C98.1213 95.2584 98.0204 95.226 97.9363 95.1657C97.8281 95.0891 97.7548 94.9725 97.7324 94.8417C97.7101 94.711 97.7406 94.5767 97.8173 94.4684C97.9098 94.3379 106.946 81.2683 96.8583 69.3532C95.0728 67.2334 92.8455 65.5298 90.3326 64.3619C87.8196 63.194 85.0817 62.59 82.3108 62.5923H82.1748C83.1328 65.9217 82.9618 68.882 81.6053 71.153C81.0273 72.1792 80.1744 73.0235 79.1426 73.5909C78.1108 74.1583 76.9411 74.4262 75.7653 74.3643C74.792 74.2846 73.8631 73.9226 73.0924 73.3227C72.3216 72.7228 71.7425 71.9111 71.4258 70.9869C70.3163 68.0917 71.4758 65.2845 74.5318 63.4752C76.4678 62.4071 78.619 61.7881 80.8263 61.6639C80.1082 59.7593 79.1745 57.9432 78.0433 56.2511C74.4008 50.6857 67.7624 46.8331 59.8294 45.6811C53.1064 44.7052 46.6634 45.8547 43.4129 48.6088C43.3117 48.6945 43.1805 48.7365 43.0484 48.7255C42.9162 48.7144 42.7938 48.6514 42.7082 48.5501C42.6225 48.4488 42.5806 48.3176 42.5916 48.1854C42.6026 48.0531 42.6657 47.9307 42.7669 47.845C46.2259 44.9143 52.9794 43.6752 59.9729 44.6937C68.1894 45.8872 75.0803 49.9004 78.8803 55.7054C80.1123 57.5464 81.1144 59.5313 81.8643 61.6159C84.8534 61.5441 87.8206 62.144 90.5471 63.3716C93.2737 64.5992 95.6903 66.4231 97.6188 68.7089C108.215 81.2238 98.7273 94.9126 98.6303 95.0497C98.5841 95.1141 98.5232 95.1666 98.4527 95.2029C98.3822 95.2392 98.3041 95.2582 98.2248 95.2583ZM81.1508 62.6288C79.0107 62.7201 76.9206 63.3046 75.0433 64.3366C72.4053 65.8982 71.4283 68.1922 72.3618 70.6292C72.6148 71.376 73.0798 72.0328 73.7 72.5192C74.3202 73.0057 75.0686 73.3008 75.8538 73.3684C76.8425 73.4127 77.824 73.181 78.6885 72.6992C79.553 72.2174 80.2666 71.5045 80.7493 70.6402C81.9943 68.5544 82.1083 65.7817 81.1493 62.6288H81.1508Z"
            fill="#292A2E"
        />
        <path
            d="M34.7825 56.6738C33.9328 56.9291 33.017 56.8426 32.2302 56.4326C31.4433 56.0225 30.8476 55.3214 30.5697 54.4785C30.2919 53.6356 30.3539 52.7175 30.7426 51.9196C31.1313 51.1218 31.8159 50.5072 32.6508 50.2068C33.4856 49.9065 34.4047 49.944 35.2123 50.3114C36.0199 50.6788 36.6522 51.3471 36.9746 52.174C37.2971 53.0009 37.2841 53.921 36.9385 54.7385C36.593 55.5559 35.9421 56.2062 35.1245 56.5507C35.0128 56.5979 34.8986 56.639 34.7825 56.6738ZM33.12 51.1154C33.039 51.1397 32.9594 51.1685 32.8815 51.2014C32.3062 51.4441 31.8483 51.9019 31.6054 52.4773C31.3625 53.0527 31.3537 53.7002 31.581 54.282C31.8082 54.8637 32.2535 55.3338 32.8221 55.5919C33.3906 55.8501 34.0374 55.876 34.6247 55.6641C35.212 55.4522 35.6935 55.0193 35.9665 54.4576C36.2395 53.8959 36.2825 53.2497 36.0863 52.6567C35.8902 52.0637 35.4703 51.5708 34.9163 51.2829C34.3622 50.995 33.7177 50.9349 33.12 51.1154Z"
            fill="#292A2E"
        />
        <path
            d="M34.7751 48.8352C33.9714 49.0752 33.1073 49.0096 32.349 48.6509C31.5907 48.2922 30.9917 47.6657 30.6671 46.8918C30.407 46.2714 30.3365 45.5878 30.4647 44.9274C30.5929 44.2669 30.914 43.6594 31.3873 43.1815C31.8607 42.7036 32.4651 42.3769 33.124 42.2427C33.783 42.1085 34.467 42.1728 35.0894 42.4275C35.7119 42.6821 36.2448 43.1158 36.6209 43.6735C36.997 44.2312 37.1993 44.888 37.2023 45.5608C37.2053 46.2335 37.0087 46.8921 36.6376 47.4531C36.2664 48.0141 35.7373 48.4524 35.1171 48.7126C35.0053 48.7594 34.8912 48.8003 34.7751 48.8352ZM33.1146 43.2763C33.0327 43.3009 32.9521 43.3298 32.8731 43.3628C32.2866 43.6093 31.8218 44.0787 31.5811 44.6678C31.3403 45.257 31.3432 45.9177 31.5891 46.5047C31.7685 46.9326 32.068 47.2993 32.4513 47.5605C32.8346 47.8217 33.2854 47.9662 33.7491 47.9765C34.2127 47.9869 34.6695 47.8625 35.064 47.6186C35.4586 47.3747 35.774 47.0216 35.9722 46.6021C36.1704 46.1826 36.2429 45.7147 36.1808 45.2548C36.1188 44.795 35.9248 44.363 35.6225 44.0111C35.3202 43.6592 34.9225 43.4025 34.4774 43.272C34.0323 43.1415 33.559 43.1433 33.1146 43.2763Z"
            fill="#292A2E"
        />
        <path
            d="M33.8054 52.3727C36.2608 52.3727 38.2514 51.0426 38.2514 49.4019C38.2514 47.7612 36.2608 46.4312 33.8054 46.4312C31.3499 46.4312 29.3594 47.7612 29.3594 49.4019C29.3594 51.0426 31.3499 52.3727 33.8054 52.3727Z"
            fill="white"
        />
        <path
            d="M35.7729 52.5912C35.1354 52.7797 34.4741 52.8747 33.8094 52.8733C31.0354 52.8733 28.8594 51.3527 28.8594 49.4063C28.8594 47.46 31.0289 45.9338 33.8019 45.9308C35.0108 45.9076 36.2003 46.2379 37.2244 46.8812C37.6843 47.1209 38.0698 47.4822 38.3389 47.9256C38.6081 48.3691 38.7507 48.8779 38.7511 49.3967C38.7516 49.9155 38.6099 50.4246 38.3416 50.8685C38.0732 51.3125 37.6884 51.6744 37.2289 51.9149C36.7797 52.2112 36.289 52.4391 35.7729 52.5912ZM32.1419 47.1694C30.8059 47.5695 29.8589 48.4334 29.8594 49.4038C29.8594 50.7429 31.6679 51.8729 33.8094 51.8709C34.821 51.8922 35.8168 51.6182 36.6754 51.0825C36.9973 50.9342 37.27 50.6965 37.4611 50.3978C37.6521 50.099 37.7535 49.7517 37.7532 49.3971C37.7529 49.0425 37.6509 48.6953 37.4593 48.3969C37.2677 48.0985 36.9946 47.8614 36.6724 47.7136C35.8132 47.179 34.8169 46.9068 33.8054 46.9303C33.2422 46.9288 32.6818 47.0093 32.1419 47.1694Z"
            fill="#292A2E"
        />
        <path
            d="M34.8954 52.2823L34.7904 46.5048L33.3544 46.4468L33.2539 52.3498L34.8954 52.2823Z"
            fill="#292A2E"
        />
        <path
            d="M27.9082 50.6587C28.1617 50.6587 28.3672 50.4532 28.3672 50.1997C28.3672 49.9462 28.1617 49.7407 27.9082 49.7407C27.6547 49.7407 27.4492 49.9462 27.4492 50.1997C27.4492 50.4532 27.6547 50.6587 27.9082 50.6587Z"
            fill="#292A2E"
        />
        <path
            d="M28.2676 48.2012C28.5211 48.2012 28.7266 47.9957 28.7266 47.7422C28.7266 47.4887 28.5211 47.2832 28.2676 47.2832C28.0141 47.2832 27.8086 47.4887 27.8086 47.7422C27.8086 47.9957 28.0141 48.2012 28.2676 48.2012Z"
            fill="#292A2E"
        />
        <path
            d="M176.788 66.4109C176.681 66.4109 176.577 66.3765 176.491 66.3128C176.405 66.2492 176.341 66.1596 176.31 66.0572C174.651 59.8614 174.209 53.4028 175.007 47.0386C176.299 37.7346 181.428 25.5239 198.522 20.1771C200.927 19.4035 203.394 18.8377 205.896 18.4859C206.701 13.4532 209.765 7.9939 216.618 4.22729C232.899 -4.72253 243.896 3.3074 244.005 3.38944C244.111 3.46904 244.181 3.58754 244.2 3.71887C244.219 3.8502 244.185 3.9836 244.105 4.08974C244.026 4.19587 243.907 4.26603 243.776 4.28479C243.645 4.30356 243.511 4.26938 243.405 4.18978C243.301 4.11175 232.791 -3.52202 217.1 5.10467C210.697 8.62467 207.766 13.6768 206.932 18.3668C214.472 17.624 218.082 20.1401 219.703 22.0404C220.705 23.1832 221.35 24.5958 221.555 26.1023C221.761 27.6088 221.519 29.1426 220.86 30.5125C220.339 31.6198 219.494 32.5429 218.438 33.1599C217.381 33.777 216.162 34.0589 214.942 33.9685C210.732 33.7214 207.247 30.1779 206.066 24.9406C205.677 23.1678 205.573 21.344 205.759 19.5383C203.406 19.8765 201.085 20.4105 198.821 21.1345C185.498 25.3018 177.821 34.065 175.997 47.1791C175.216 53.399 175.646 59.7113 177.266 65.7671C177.289 65.8419 177.294 65.921 177.281 65.9981C177.267 66.0752 177.236 66.1481 177.19 66.211C177.143 66.2739 177.083 66.3251 177.013 66.3603C176.943 66.3955 176.866 66.4139 176.788 66.4139V66.4109ZM206.779 19.4133C206.571 21.1845 206.659 22.9781 207.042 24.7201C208.122 29.5111 211.246 32.749 215.001 32.9696C216.024 33.0429 217.046 32.8051 217.932 32.2874C218.817 31.7697 219.526 30.9962 219.965 30.0683C220.536 28.8737 220.744 27.5373 220.562 26.2254C220.38 24.9136 219.816 23.6843 218.941 22.6907C216.603 19.946 212.336 18.8375 206.779 19.4133Z"
            fill="#292A2E"
        />
        <path
            d="M255.653 8.44534C254.98 8.44393 254.321 8.25203 253.752 7.8918C253.182 7.53157 252.727 7.01769 252.437 6.40947C252.147 5.80125 252.036 5.12348 252.114 4.45438C252.193 3.78529 252.46 3.15215 252.883 2.62803C253.306 2.10391 253.869 1.71019 254.506 1.49229C255.143 1.2744 255.829 1.24122 256.484 1.39658C257.14 1.55194 257.738 1.88951 258.209 2.37034C258.681 2.85116 259.007 3.45563 259.15 4.11399C259.262 4.63587 259.256 5.17622 259.133 5.69548C259.009 6.21473 258.771 6.69974 258.436 7.115C258.1 7.53025 257.677 7.86523 257.195 8.09542C256.714 8.3256 256.187 8.44516 255.653 8.44534ZM255.661 2.30071C255.129 2.3007 254.61 2.46571 254.176 2.77299C253.742 3.08027 253.414 3.5147 253.237 4.0164C253.06 4.5181 253.043 5.06238 253.188 5.57421C253.333 6.08605 253.633 6.54024 254.047 6.8742C254.461 7.20815 254.969 7.40543 255.499 7.43884C256.03 7.47224 256.558 7.34014 257.011 7.06072C257.463 6.78131 257.818 6.36834 258.026 5.87875C258.234 5.38915 258.286 4.84702 258.173 4.32708C258.051 3.75182 257.735 3.23619 257.277 2.86707C256.819 2.49796 256.249 2.29794 255.661 2.30071Z"
            fill="#292A2E"
        />
        <path
            d="M254.147 16.6094C253.231 16.6093 252.349 16.2578 251.684 15.6271C251.019 14.9965 250.621 14.1347 250.572 13.2192C250.522 12.3037 250.826 11.4042 251.42 10.7058C252.013 10.0075 252.852 9.56339 253.763 9.46503C254.674 9.36666 255.588 9.6215 256.317 10.1771C257.046 10.7326 257.535 11.5467 257.682 12.4516C257.829 13.3565 257.624 14.2834 257.109 15.0415C256.593 15.7996 255.807 16.3312 254.912 16.5269C254.66 16.5817 254.404 16.6094 254.147 16.6094ZM254.16 10.4643C253.975 10.4644 253.79 10.4843 253.61 10.5238C252.971 10.6641 252.41 11.044 252.043 11.5853C251.676 12.1266 251.53 12.7881 251.635 13.4338C251.741 14.0794 252.09 14.66 252.61 15.0562C253.13 15.4524 253.783 15.634 254.433 15.5636C255.083 15.4933 255.681 15.1763 256.105 14.678C256.529 14.1796 256.745 13.5378 256.71 12.8845C256.675 12.2313 256.391 11.6163 255.917 11.1661C255.442 10.7159 254.814 10.4647 254.16 10.4643Z"
            fill="#292A2E"
        />
        <path
            d="M259.518 9.90679C259.833 8.19823 258.016 6.43049 255.459 5.95846C252.902 5.48643 250.573 6.48884 250.258 8.1974C249.943 9.90596 251.76 11.6737 254.317 12.1457C256.874 12.6177 259.203 11.6153 259.518 9.90679Z"
            fill="white"
        />
        <path
            d="M255.575 12.763C255.122 12.7623 254.671 12.7204 254.225 12.638C252.968 12.4292 251.799 11.858 250.861 10.9943C250.45 10.6439 250.135 10.1957 249.943 9.69105C249.752 9.1864 249.691 8.6416 249.765 8.10702C249.886 7.581 250.138 7.09398 250.497 6.69095C250.856 6.28791 251.31 5.98185 251.819 5.80103C253.008 5.35163 254.298 5.23609 255.548 5.4669C256.798 5.69772 257.962 6.26611 258.912 7.11059C259.323 7.46093 259.638 7.90914 259.83 8.41379C260.021 8.91844 260.082 9.46324 260.008 9.99783C259.887 10.5238 259.635 11.0109 259.276 11.4139C258.917 11.8169 258.463 12.123 257.954 12.3038C257.2 12.616 256.391 12.7722 255.575 12.763ZM254.194 6.34126C253.515 6.33262 252.842 6.46143 252.214 6.71993C251.858 6.8389 251.538 7.04466 251.283 7.31856C251.027 7.59247 250.843 7.92587 250.749 8.2886C250.708 8.66118 250.76 9.03813 250.901 9.38531C251.043 9.7325 251.268 10.039 251.558 10.2769C252.367 10.9831 253.35 11.4586 254.406 11.6536C255.462 11.8485 256.55 11.7557 257.558 11.3849C257.913 11.2659 258.233 11.0602 258.489 10.7863C258.745 10.5124 258.928 10.179 259.023 9.81625C259.064 9.44364 259.012 9.06661 258.87 8.71937C258.729 8.37214 258.503 8.06571 258.214 7.8279C257.418 7.10147 256.427 6.62293 255.364 6.45081C254.979 6.37906 254.588 6.3424 254.196 6.34126H254.194Z"
            fill="#292A2E"
        />
        <path
            d="M254.31 5.84326L253.305 11.8788L254.788 12.217L256.032 6.08987L254.31 5.84326Z"
            fill="#292A2E"
        />
        <path
            d="M260.338 12.3358C260.606 12.3358 260.824 12.1182 260.824 11.8498C260.824 11.5814 260.606 11.3638 260.338 11.3638C260.069 11.3638 259.852 11.5814 259.852 11.8498C259.852 12.1182 260.069 12.3358 260.338 12.3358Z"
            fill="#292A2E"
        />
        <path
            d="M261.185 9.847C261.454 9.847 261.671 9.62941 261.671 9.361C261.671 9.09259 261.454 8.875 261.185 8.875C260.917 8.875 260.699 9.09259 260.699 9.361C260.699 9.62941 260.917 9.847 261.185 9.847Z"
            fill="#292A2E"
        />
    </svg>
);
