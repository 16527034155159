import React from 'react';

interface IProps {
    color: string;
    size: number;
}
export const Question = (props: IProps) => {
    return (
        <svg
            width={props.size}
            height={props.size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.8333 12.5007L15 16.6673M15 7.50065C15 6.39558 14.561 5.33577 13.7796 4.55437C12.9982 3.77297 11.9384 3.33398 10.8333 3.33398H9.16667C8.0616 3.33398 7.00179 3.77297 6.22039 4.55437C5.43899 5.33577 5 6.39558 5 7.50065V12.5007C5 13.6057 5.43899 14.6655 6.22039 15.4469C7.00179 16.2283 8.0616 16.6673 9.16667 16.6673H10.8333C11.9384 16.6673 12.9982 16.2283 13.7796 15.4469C14.561 14.6655 15 13.6057 15 12.5007V7.50065Z"
                stroke={props.color}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
