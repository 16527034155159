import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { sentimentChartBarClick } from '../../../../analytics/analytics';
import {
    AreaLineChart,
    AreaLineChartProps,
} from '../../../../components/charts/area-line-chart';
import {
    BarLineChart,
    BarLineChartProps,
} from '../../../../components/charts/bar-line-chart';
import { AppContext } from '../../../../providers/app-provider';
import { SentimentChartData } from '../../../../services/interfaces';
import { useTopicService } from '../../../../services/topic.service';

// interface IProps

interface IProps {
    onBarClick?: (date: string) => void;
    topicSubscriptionId?: string;
}

export const StockPricePlot = (props: IProps) => {
    const { getTopicSentiments, fetchTopicStockPrice } = useTopicService();
    const { topicSubscriptionId } = props;
    const appContext = useContext(AppContext);
    const [sentimentData, setSentimentData] =
        useState<AreaLineChartProps | null>(null);

    const refreshSentiments = async () => {
        if (topicSubscriptionId) {
            setSentimentData(null);
            let s: SentimentChartData[] = await getTopicSentiments([
                topicSubscriptionId,
            ]);
            const topicSentiments = s.filter(
                (t: SentimentChartData) =>
                    t.topicSubscriptionId == topicSubscriptionId
            )[0];
            if (topicSentiments) {
                const positiveSeries = topicSentiments.chartDetails.map(
                    (dateDetails) =>
                        dateDetails.sentiment.positive
                            ? dateDetails.sentiment.positive / 1000
                            : 0
                );
                const negativeSeries = topicSentiments.chartDetails.map(
                    (dateDetails) =>
                        dateDetails.sentiment.negative
                            ? (-1 * dateDetails.sentiment.negative) / 1000
                            : 0
                );
                const labels = topicSentiments.chartDetails.map((t) => t.date);

                const stockPrice = await fetchTopicStockPrice(
                    topicSubscriptionId
                );
                const stockPriceData = stockPrice
                    ? stockPrice.map((value) => value.closingPrice)
                    : [];
                let stockMin = 0;
                let stockMax = 0;
                if (stockPriceData.length > 0) {
                    stockMin = Math.min(...stockPriceData) * 1000;
                    stockMax = Math.max(...stockPriceData) * 1000;
                    let diff = stockMax - stockMin;
                    diff = Math.ceil(diff / 10);
                    stockMin = (stockMin - diff) / 1000;
                    stockMax = (stockMax + diff) / 1000;
                }

                let sentimentData: AreaLineChartProps = {
                    series: [
                        {
                            name: 'Positive',
                            data: positiveSeries,
                        },
                        {
                            name: 'Negative',
                            data: negativeSeries,
                        },
                        {
                            name: 'Stock',
                            data: stockPriceData,
                        },
                    ],
                    height: 536,
                    labels: labels,
                    stockMin,
                    stockMax,
                };
                setSentimentData({ ...sentimentData });
            }
        }
    };

    useEffect(() => {
        refreshSentiments();
    }, [topicSubscriptionId, appContext?.filters.date, appContext?.category]);

    return (
        <Box>
            {sentimentData && (
                <AreaLineChart
                    showLinesOnly={false}
                    onBarClick={(date: string) => {
                        props.onBarClick && props.onBarClick(date);
                        sentimentChartBarClick(
                            date,
                            topicSubscriptionId || '',
                            '',
                            'Dashboard'
                        );
                    }}
                    {...sentimentData}
                />
            )}
            {!sentimentData && (
                <Skeleton
                    sx={{
                        transform: 'scale(1, 1)',
                    }}
                    height={536}
                />
            )}
        </Box>
    );
};
