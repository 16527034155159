export const RecentHeadlinesIcon = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_12814_126741"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
        >
            <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_12814_126741)">
            <path
                d="M3.5865 17.0865C3.16555 17.0865 2.80924 16.9407 2.51758 16.649C2.22591 16.3574 2.08008 16.0011 2.08008 15.5801V4.42634C2.08008 4.00539 2.22591 3.64909 2.51758 3.35742C2.80924 3.06576 3.16555 2.91992 3.5865 2.91992H16.407C16.8279 2.91992 17.1842 3.06576 17.4759 3.35742C17.7675 3.64909 17.9134 4.00539 17.9134 4.42634V15.5801C17.9134 16.0011 17.7675 16.3574 17.4759 16.649C17.1842 16.9407 16.8279 17.0865 16.407 17.0865H3.5865ZM3.5865 15.8366H16.407C16.4711 15.8366 16.5298 15.8099 16.5833 15.7564C16.6367 15.703 16.6634 15.6442 16.6634 15.5801V4.42634C16.6634 4.36223 16.6367 4.30346 16.5833 4.25003C16.5298 4.19661 16.4711 4.1699 16.407 4.1699H3.5865C3.52238 4.1699 3.46361 4.19661 3.41018 4.25003C3.35677 4.30346 3.33006 4.36223 3.33006 4.42634V15.5801C3.33006 15.6442 3.35677 15.703 3.41018 15.7564C3.46361 15.8099 3.52238 15.8366 3.5865 15.8366ZM5.31727 13.8494H14.6762V12.5994H5.31727V13.8494ZM5.31727 10.6282H8.39416V6.15711H5.31727V10.6282ZM10.2211 10.6282H14.6762V9.37826H10.2211V10.6282ZM10.2211 7.40707H14.6762V6.15711H10.2211V7.40707Z"
                fill="#000033"
            />
        </g>
    </svg>
);
