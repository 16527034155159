import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UpsertDomainList } from './domain-list';
import { useAccountService } from '../../../services/account.service';
import { ITrustedDomain } from '../../../services/interfaces';
import cluster from 'cluster';
import { BorderedBox } from '../../../components/core/box';
import { AddOrEditWatchlist } from '../../watchlist-and-subscriptions/watchlist/add-edit-watchlist.tsx';
import { DeleteWatchlist } from '../../watchlist-and-subscriptions/watchlist/delete-watchlist.tsx';
import { SetAlerts } from '../../watchlist-and-subscriptions/watchlist/set-alerts.tsx';
import { Delete } from '../../../components/icons/delete';

interface IDeleteDomainListProps {
    id: string;
    refreshList: () => Promise<void>;
}

const DeleteDomainList = (props: IDeleteDomainListProps) => {
    const { deleteDomainlist } = useAccountService();
    const [isDeleting, setIsDeleting] = useState(false);
    const deleteList = async () => {
        setIsDeleting(true);
        await deleteDomainlist(props.id);
        await props.refreshList();
        setIsDeleting(false);
    };
    return (
        <IconButton
            sx={{ p: 0 }}
            disabled={isDeleting}
            onClick={deleteList}
            color="primary"
        >
            {isDeleting ? (
                <CircularProgress sx={{ color: '#ccc' }} size={20} />
            ) : (
                <Delete color="#D82F44" />
            )}
        </IconButton>
    );
};

export const CustomDomains = () => {
    const { fetchDomainsList, deleteDomainlist } = useAccountService();
    const [data, setData] = useState<ITrustedDomain[]>([]);
    const refreshDomains = async () => {
        let d = await fetchDomainsList();
        setData(d);
    };

    useEffect(() => {
        refreshDomains();
    }, []);
    return (
        <Box pt={4}>
            <Box display="flex" justifyContent={'space-between'}>
                <Typography variant="h4">Custom Domains List</Typography>
                <UpsertDomainList refreshList={refreshDomains} />
            </Box>
            <Box
                sx={{ pt: 4, height: 'calc(100vh - 238px)', overflowY: 'auto' }}
            >
                {data &&
                    data.map((domainList, index) => (
                        <BorderedBox
                            key={index}
                            sx={{
                                mt: index == 0 ? 0 : 4,
                                p: 4,
                                backgroundColor: '#F9F9FF',
                            }}
                        >
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                pr={8}
                            >
                                <Typography variant="h6">
                                    {domainList.displayName}
                                </Typography>
                                <Box display="flex">
                                    <UpsertDomainList
                                        domainList={domainList}
                                        refreshList={refreshDomains}
                                    />
                                    <Box pl={7}>
                                        {domainList.id && (
                                            <DeleteDomainList
                                                refreshList={refreshDomains}
                                                id={domainList.id}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                            <Box display="flex" flexWrap="wrap" mt={1}>
                                {domainList.domains.map((d) => (
                                    <Box
                                        sx={{
                                            mr: 2,
                                            mt: 2,
                                            border: '1px solid #ccc',
                                            backgroundColor: '#fff',
                                            borderRadius: '20px',
                                            px: 3,
                                            py: 1,
                                        }}
                                    >
                                        {d.domainName}
                                    </Box>
                                ))}
                            </Box>
                        </BorderedBox>
                    ))}
            </Box>
        </Box>
    );
};
