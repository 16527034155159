import {
    Box,
    Container,
    Grid,
    Tooltip,
    TooltipProps,
    Typography,
    styled,
    tooltipClasses,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReportModule } from '../../../../services/interfaces';
import { BorderedBox } from '../../../../components/core/box';
import { useFinancialsService } from '../../../../services/financials.service';
import { UtilsService } from '../../../../services/utils.service';

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF',
        color: 'black',
        maxWidth: 400,
        padding: '12px',
        fontSize: 12,
        border: '1px solid #D7D9EC',
        boxShadow: '0px 10px 20px -10px rgba(0, 0, 0, 0.50)',
    },
}));

const MatrixBlock = (props: { data: IMatrixData; background: string }) => {
    return (
        <BorderedBox
            sx={{ backgroundColor: '#FFFFFF' }}
            borderRadius={'4px !important'}
        >
            <Typography m={2} variant="h6">
                {props.data.title}
            </Typography>
            <Box>
                {props.data.matrix.map(
                    (
                        m: {
                            key: string;
                            value: string | number;
                            description: string;
                        },
                        index: number
                    ) => (
                        <HtmlTooltip
                            sx={{ padding: 2 }}
                            placement="left"
                            title={
                                <Box>
                                    <Typography variant="caption">
                                        {m.description}
                                    </Typography>
                                </Box>
                            }
                        >
                            <Box
                                px={2}
                                sx={{
                                    background:
                                        index % 2 == 0 ? props.background : '',
                                    cursor: 'pointer',
                                }}
                                py={1.5}
                                justifyContent="space-between"
                                display="flex"
                            >
                                <Typography className="clamp-line-1">
                                    {m.key}
                                </Typography>
                                <Typography fontWeight={600}>
                                    {m.value
                                        ? UtilsService.convertToInternationalCurrencySystem(
                                              m.value
                                          )
                                        : 'N/A'}
                                </Typography>
                            </Box>
                        </HtmlTooltip>
                    )
                )}
            </Box>
        </BorderedBox>
    );
};

interface IMatrixData {
    title: string;
    matrix: {
        key: string;
        value: string | number;
        description: string;
    }[];
}

interface IProps {
    data?: ReportModule;
}

export const KeyRatio = (props: IProps) => {
    let { topicSubscriptionId } = useParams();
    const { fetchFinancialKeyMatrix } = useFinancialsService();
    const [valuation, setValuation] = useState<IMatrixData | null>(null);
    const [profitability, setProfitability] = useState<IMatrixData | null>(
        null
    );
    const [liquidity, setLiquidity] = useState<IMatrixData | null>(null);
    const [cashFlow, setCashFlow] = useState<IMatrixData | null>(null);

    const refreshKeyMatrix = async () => {
        if (!props.data?.data?.yahoo?.coreFinancials) return;
        let data = props.data;

        if (!data?.data) return;
        setValuation({
            title: 'Valuation Metrics',
            matrix: [
                {
                    key: 'Enterprise Value',
                    value: data.data.yahoo.coreFinancials.valuationMetrics
                        .enterpriseValue,
                    description:
                        'The total value of a company, representing its market capitalization plus debt, minus cash and cash equivalents.',
                },
                {
                    key: 'Forward P/E Ratio',
                    value: data.data?.yahoo.coreFinancials.valuationMetrics
                        .forwardPe,
                    description: `The ratio of a company's current stock price to its estimated earnings per share for the next twelve months.`,
                },
                {
                    key: 'Trailing P/E Ratio',
                    value: data.data?.yahoo.coreFinancials.valuationMetrics
                        .trailingPe,
                    description: `The ratio of a company's current stock price to its earnings per share over the past twelve months.`,
                },
                {
                    key: 'PEG Ratio',
                    value: data.data?.yahoo.coreFinancials.valuationMetrics
                        .pegRatio,
                    description: `The price/earnings to growth ratio, which measures a company's valuation relative to its expected earnings growth rate.`,
                },
                {
                    key: 'Forward EPS',
                    value: data.data?.yahoo.coreFinancials.valuationMetrics
                        .forwardEps,
                    description: `The estimated earnings per share for a company for the next twelve months.`,
                },
                {
                    key: 'Trailing EPS',
                    value: data.data?.yahoo.coreFinancials.valuationMetrics
                        .trailingsEps,
                    description: `The earnings per share for a company over the past twelve months.`,
                },
                {
                    key: 'Price-to-Sales(P/S) ratio ',
                    value: data.data?.yahoo.coreFinancials.valuationMetrics
                        .priceToSales,
                    description: `The ratio of a company's market capitalization to its total revenue over the past twelve months.`,
                },
                {
                    key: 'Price-to-Book(P/B) ratio',
                    value: data.data?.yahoo.coreFinancials.valuationMetrics
                        .priceToBook,
                    description: `The ratio of a company's market capitalization to its book value, representing the value of its assets minus liabilities.`,
                },
            ],
        });
        setProfitability({
            title: 'Profitability Metrics',
            matrix: [
                {
                    key: 'Profit Margins',
                    value: data.data?.yahoo.coreFinancials.profitabilityMetrics
                        .profitMargins,
                    description: `The percentage of revenue that remains as profit after accounting for all expenses.`,
                },
                {
                    key: 'Return on Assets (ROA)',
                    value: data.data?.yahoo.coreFinancials.profitabilityMetrics
                        .returnOnAssets,
                    description: `A measure of a company's profitability in relation to its total assets.`,
                },
                {
                    key: 'Return on Equity (ROE)',
                    value: data.data?.yahoo.coreFinancials.profitabilityMetrics
                        .returnOnEquity,
                    description: `A measure of a company's profitability in relation to its shareholders' equity.`,
                },
                {
                    key: 'Gross Profits',
                    //@ts-ignore
                    value:
                        //@ts-ignore
                        data.data?.yahoo.coreFinancials.profitabilityMetrics
                            .grossProfits,
                    description: `The amount remaining after deducting the cost of goods sold from total revenue.`,
                },
                {
                    key: 'Gross Margins',
                    value: data.data?.yahoo.coreFinancials.profitabilityMetrics
                        .profitMargins,
                    description: `The percentage of revenue that exceeds the cost of goods sold.`,
                },
                {
                    key: 'Operating Margins',
                    value: data.data?.yahoo.coreFinancials.profitabilityMetrics
                        .operatingMargins,
                    description: `The percentage of revenue that remains after deducting operating expenses.`,
                },
                {
                    key: 'EBITDA Margins',
                    value: data.data?.yahoo.coreFinancials.profitabilityMetrics
                        .ebidtaMargins,
                    description: `The percentage of revenue that exceeds operating expenses, excluding taxes, depreciation, and amortization.`,
                },
                {
                    key: 'EBITDA',
                    value: data.data?.yahoo.coreFinancials.profitabilityMetrics
                        .ebidta,
                    description: `Earnings Before Interest, Taxes, Depreciation, and Amortization; a measure of a company's operating performance.`,
                },
            ],
        });
        setLiquidity({
            title: 'Liquidity and Solvency Metrics',
            matrix: [
                {
                    key: 'Total Cash',
                    value:
                        data.data?.yahoo?.coreFinancials
                            .liquidityAndSolvencyMetrics.totalCash || 'NA',
                    description: `The total amount of cash and cash equivalents held by a company at a specific point in time.`,
                },
                {
                    key: 'Current Ratio',
                    value:
                        data.data?.yahoo?.coreFinancials
                            .liquidityAndSolvencyMetrics.currentRatio || 'NA',
                    description: `A measure of a company's short-term liquidity, calculated by dividing current assets by current liabilities.`,
                },
                {
                    key: 'Quick Ratio',
                    value:
                        data.data?.yahoo?.coreFinancials
                            .liquidityAndSolvencyMetrics.quickRatio || 'NA',
                    description: `Also known as the Acid-Test Ratio, it measures a company's ability to pay off short-term liabilities with its most liquid assets (cash and cash equivalents, and marketable securities).`,
                },
                {
                    key: 'Debt To Equity Ratio ',
                    value:
                        data.data?.yahoo?.coreFinancials
                            .liquidityAndSolvencyMetrics.totalCashPerShare ||
                        'NA',
                    description: `A financial leverage ratio that compares a company's total debt to its shareholders' equity, indicating the proportion of financing provided by debt relative to equity.`,
                },
                {
                    key: 'Total Debt',
                    value: data.data?.yahoo.coreFinancials
                        .liquidityAndSolvencyMetrics.totalDebt,
                    description: `The total amount of money a company owes to external creditors and lenders.`,
                },
            ],
        });
        setCashFlow({
            title: 'Cash Flow Metrics',
            matrix: [
                {
                    key: 'Free Cash Flow',
                    value:
                        data.data?.yahoo?.coreFinancials.cashFlowMetrics
                            .freeCashFlow || 'NA',
                    description: `The amount of cash generated by a business after accounting for capital expenditures and working capital requirements.`,
                },
                {
                    key: 'Operating Cash Flow',
                    value:
                        data.data?.yahoo?.coreFinancials.cashFlowMetrics
                            .operatingCashFlow || 'NA',
                    description: `The cash generated from a company's core business operations, excluding financing and investing activities.`,
                },
            ],
        });
    };

    useEffect(() => {
        refreshKeyMatrix();
    }, [topicSubscriptionId, props.data]);

    return (
        <BorderedBox sx={{ backgroundColor: '#FFFFFF' }} py={3}>
            <Box>
                <Typography px={4} variant="h6">
                    Key Metrics & Ratios
                </Typography>
                <Grid px={2.5} spacing={2} mt={1} container>
                    <Grid item sm={4}>
                        {valuation && (
                            <MatrixBlock
                                background={`linear-gradient(90deg, rgba(249, 174, 43, .2) 0%, rgba(249, 174, 43, 0.00) 100%)`}
                                data={valuation}
                            />
                        )}
                    </Grid>
                    <Grid item sm={4}>
                        {profitability && (
                            <MatrixBlock
                                background={`linear-gradient(90deg, rgba(25, 175, 85, .2) 0%, rgba(25, 175, 85, 0.00) 100%)`}
                                data={profitability}
                            />
                        )}
                    </Grid>
                    <Grid item sm={4}>
                        {liquidity && (
                            <MatrixBlock
                                background={`linear-gradient(90deg, rgba(208, 212, 254, .4) 0%, rgba(208, 212, 254, 0.00) 100%)`}
                                data={liquidity}
                            />
                        )}
                        <Box mt={2}>
                            {cashFlow && (
                                <MatrixBlock
                                    background={`linear-gradient(90deg, rgba(204, 243, 245, .5) 0%, rgba(204, 243, 245, 0.00) 100%)`}
                                    data={cashFlow}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </BorderedBox>
    );
};
