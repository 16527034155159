import React, { useEffect, useState } from 'react';
import {
    IRecentHeadlines,
    LiveQueryResponseVolumeTrendsData,
} from '../../../services/interfaces';
import { UtilsService } from '../../../services/utils.service';
import { NavigationLink } from '../../../components/core/link';
import { Box, Button, Divider, Skeleton, Typography } from '@mui/material';
import { useChatbotService } from '../../../services/chatbot.service';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { NewsImagePlaceholder } from '../../../components/images/news-image-placeholder';

interface IEntityArticleDataProps {
    d: IRecentHeadlines | null;
}

export const EntityArticleData = (props: IEntityArticleDataProps) => {
    const [expand, setExpand] = useState(false);
    const { d } = props;
    return (
        <Box width={'100%'}>
            {d && (
                <Typography fontSize={14} fontWeight={400}>
                    {d.article || d.text}
                </Typography>
            )}
            {!(d?.article || d?.text) && (
                <Box>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </Box>
            )}
            <Box mt={2} justifyContent={'space-between'} display="flex">
                {
                    <Box height={16} display="flex">
                        <Typography
                            color="#4B4B4C"
                            variant="caption"
                            pr={2}
                            mr={2}
                            sx={
                                d?.source
                                    ? { borderRight: '1px solid #ccc' }
                                    : {}
                            }
                        >
                            {d
                                ? UtilsService.formatStringDateInMDYFormat(
                                      d?.datetime
                                  )
                                : ''}
                            {!d && <Skeleton width={130} variant="text" />}
                        </Typography>
                        {d?.source && (
                            <Typography
                                color="#4B4B4C"
                                pr={1}
                                variant="caption"
                            >
                                {d?.source}
                            </Typography>
                        )}
                        {/* {!d?.source && (
                            <Typography
                                color="#4B4B4C"
                                pr={1}
                                variant="caption"
                            >
                                <Skeleton width={130} variant="text" />
                            </Typography>
                        )} */}
                    </Box>
                }
            </Box>
        </Box>
    );
};

interface IProps {
    data: LiveQueryResponseVolumeTrendsData;
    date: string;
}
export const EntityArticles = (props: IProps) => {
    const [headlines, setHeadlines] = useState<IRecentHeadlines[]>([]);
    const { fetchScrapUrlsResponse } = useChatbotService();
    useEffect(() => {
        if (!props.date) return;
        let newsVolumeData = props.data.newsVolume.filter(
            (v) => UtilsService.formatStringDate(v.date) == props.date
        );
        if (newsVolumeData.length > 0) {
            setHeadlines([
                ...newsVolumeData[0]?.topArticles.map((d) => {
                    return {
                        url: d.url,
                        source: '',
                        title: d.title,
                        datetime: '',
                        engine: '',
                        article: '',
                        position: 0,
                        time: 0,
                        icon: '',
                    };
                }),
            ]);
            let urls = newsVolumeData[0]?.topArticles.map((d) => d.url);
            refreshHeadlines(urls);
        }
    }, [props.data, props.date]);

    const refreshHeadlines = async (urls: string[]) => {
        let data = await fetchScrapUrlsResponse(urls);
        setHeadlines(data);
    };

    return (
        <div>
            {headlines.map((d: IRecentHeadlines, index: number) => {
                return (
                    <Box key={index} width={'100%'} px={1}>
                        <Box
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#f9f9ff',
                                },
                            }}
                        >
                            <NavigationLink to={d.url} target="_blank">
                                <Typography
                                    className="clamp-line-2"
                                    fontSize={14}
                                    fontWeight={600}
                                >
                                    <img
                                        style={{
                                            maxWidth: 14,
                                            maxHeight: 14,
                                            marginRight: 4,
                                            paddingTop: 2,
                                        }}
                                        src={d.icon}
                                    />{' '}
                                    {d.title}
                                </Typography>
                            </NavigationLink>
                            <Box px={1} pt={2} width={'100%'} display="flex">
                                <Box mr={2} pt={1} maxWidth={64}>
                                    {d.image ? (
                                        <img
                                            style={{
                                                width: 64,
                                                height: 64,
                                                borderRadius: '8px',
                                            }}
                                            src={d.image}
                                        />
                                    ) : (
                                        <NewsImagePlaceholder />
                                    )}
                                </Box>
                                <EntityArticleData d={d} />
                            </Box>
                        </Box>
                        {index + 1 !== headlines?.length && (
                            <Box py={4}>
                                <Divider />
                            </Box>
                        )}
                    </Box>
                );
            })}
        </div>
    );
};
