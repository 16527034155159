import { useContext } from 'react';
import { Constants } from '../constants';
import {
    AddFileRequest,
    Answer,
    DocumentFile,
    DocumentQueryRequest,
    DocumentQueryResponse,
    FileInsightsResponse,
    InsightsResponseData,
    FileListResponse,
    S3LinkResponse,
    S3LinkResponseData,
    UpdateDocumentAspects,
    UpsertGroupRequest,
    FileDownloadLinkResponse,
    UpdateDocumentTags,
    FileFiltersListResponse,
    FileFiltersListResponseData,
    FileFilters,
    UploadUrlData,
    UpdateDocumentName,
    SharedFileListResponse,
    DocumentQuerySourcesResponseData,
    DocumentQuerySourcesResponse,
    DocumentSourcesQueryRequest,
    SECFilingQueryRequest,
    DocumentInsightQueryRequest,
    TopicBookmarksRequest,
    DocumentInsightBookmarksRequest,
    SECBookmarksResponseDataElement,
    TopicBookmarksResponse,
    TopicChatUpdateRequest,
    DocumentInsightBookmarkResponseDataElement,
    IDocumentQueryRequest,
    IDocumentQueryResponse,
    IDocumentQuerySuggestionResponseFile,
    IDocumentMatchesRequest,
    IDocumentMatchedResponse,
    IDocumentMatchedResponseFile,
    IFileUploadResponse,
    FileUploadRequest,
    FileTag,
    IFilesCountResponse,
    IFilesCount,
    IDocumentInsightFiltersResponseData,
    IDocumentInsightFiltersResponse,
} from './interfaces';
import {
    INetworkResponse,
    NetworkRequestMethod,
    useNetworkAPI,
} from './network.service';
import { AppContext } from '../providers/app-provider';

export interface FileUploadStatus {
    progress: number;
    created?: boolean;
}

export const useDocumentService = () => {
    const appContext = useContext(AppContext);
    const {
        triggerRequest,
        triggerRequestWithChunkedResponse,
        triggerRequestWithChunkedResponseWithRef,
    } = useNetworkAPI();
    const uploadFile = async (
        file: File,
        uploadProgress: (value: FileUploadStatus) => void
    ) => {
        const linkRes: S3LinkResponseData = await getS3Link(file.name);
        const xhr = new XMLHttpRequest();
        const success = await new Promise((resolve) => {
            xhr.upload.addEventListener('progress', async (event) => {
                if (event.lengthComputable) {
                    let progress = event.loaded / event.total;
                    if (progress == 1) {
                        await pushFile({
                            fileName: file.name,
                            userFileId: linkRes.userFileId,
                            storageS3Link: linkRes.storageS3Link,
                            metadata: {
                                size: file.size,
                            },
                        });
                        uploadProgress({
                            progress: event.loaded / event.total,
                            created: true,
                        });
                    } else {
                        uploadProgress({
                            progress: event.loaded / event.total,
                        });
                    }
                }
            });
            xhr.addEventListener('loadend', () => {
                resolve(xhr.readyState === 4 && xhr.status === 200);
            });
            xhr.open('PUT', linkRes.storageS3PresignedURL, true);
            xhr.setRequestHeader('Content-Type', 'application/octet-stream');
            xhr.send(file);
        });
    };

    const uploadFileToS3 = (
        file: File,
        link: string,
        uploadProgress: (value: FileUploadStatus) => void
    ): XMLHttpRequest => {
        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener('progress', async (event) => {
            if (event.lengthComputable) {
                let progress = event.loaded / event.total;
                if (progress == 1) {
                    uploadProgress({
                        progress: event.loaded / event.total,
                        created: true,
                    });
                } else {
                    uploadProgress({
                        progress: event.loaded / event.total,
                    });
                }
            }
        });
        xhr.addEventListener('loadend', () => {});
        xhr.open('PUT', link, true);
        xhr.setRequestHeader('Content-Type', 'application/octet-stream');
        xhr.send(file);

        return xhr;
    };

    const pushFile = async (data: AddFileRequest): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: AddFileRequest = data;
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PUT,
                headers,
                body: JSON.stringify(body),
                url: Constants.pushDocument,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return false;
        }
    };

    const downloadFile = async (fileId: string) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: FileDownloadLinkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.getDownloadLink}/${fileId}/download`,
                retryCount: 3,
            });
            window.open(result.body.data.link, '_blank');
            return;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const getFileUrl = async (fileId: string): Promise<string> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: FileDownloadLinkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.getDownloadLink}/${fileId}/download`,
                retryCount: 3,
            });
            // window.open(result.body.data.link, '_blank');
            return result.body.data.link;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const queryDocument = async (
        data: DocumentQueryRequest,
        callback: (data: string) => void
    ): Promise<void> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: DocumentQueryRequest = data;
            const result: {
                body: DocumentQueryResponse;
                headers: { [key: string]: string };
            } = await triggerRequestWithChunkedResponse({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.customUploadQuery,
                retryCount: 0,
                callback,
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchUploadFileResponse = async (
        data: FileUploadRequest,
        callback: (data: string) => void
    ): Promise<XMLHttpRequest> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: FileUploadRequest = data;
            const xhr: XMLHttpRequest =
                await triggerRequestWithChunkedResponseWithRef({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.uploadFile,
                    retryCount: 0,
                    callback,
                });
            return xhr;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchSourcesForQuery = async (
        data: DocumentSourcesQueryRequest
    ): Promise<DocumentQuerySourcesResponseData> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: DocumentSourcesQueryRequest = data;
            const result: {
                body: DocumentQuerySourcesResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.fetchQuerySources,
                retryCount: 3,
            });
            return result.body.data;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const submitFile = async (
        userFileIds: string[],
        tagsOpt?: FileTag[]
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body = { userFileIds, tagsOpt };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PATCH,
                headers,
                body: JSON.stringify(body),
                url: Constants.submitFile,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const updateDocumentTags = async (
        data: UpdateDocumentTags
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: UpdateDocumentTags = data;
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PATCH,
                headers,
                body: JSON.stringify(body),
                url: Constants.updateDocumentTags,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const updateMultipleDocumentTags = async (
        data: UpdateDocumentTags
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: UpdateDocumentTags = data;
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PATCH,
                headers,
                body: JSON.stringify(body),
                url: Constants.updateMultipleDocumentTags,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const updateDocumentName = async (
        data: UpdateDocumentName
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: UpdateDocumentTags = data;
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PATCH,
                headers,
                body: JSON.stringify(body),
                url: Constants.updateFileName,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const uploadUrl = async (data: UploadUrlData): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: UploadUrlData = data;
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.uploadUrl,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const updateDocumentAspects = async (
        data: UpdateDocumentAspects
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: UpdateDocumentAspects = data;
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PATCH,
                headers,
                body: JSON.stringify(body),
                url: Constants.updateDocumentAspects,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const upsertGroup = async (data: UpsertGroupRequest): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: UpsertGroupRequest = data;
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: data.userFileGroupId
                    ? NetworkRequestMethod.PATCH
                    : NetworkRequestMethod.PUT,
                headers,
                body: JSON.stringify(body),
                url: Constants.upsertGroup,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const deleteDocument = async (fileId: string): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.DELETE,
                headers,
                url: `${Constants.deleteDocument}/${fileId}`,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const shareDocument = async (fileId: string): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PUT,
                headers,
                url: `${Constants.shareDocument}/${fileId}`,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const revokeFileSharing = async (fileId: string): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.DELETE,
                headers,
                url: `${Constants.shareDocument}/${fileId}`,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const deleteFileGroup = async (
        userFileGroupId: string
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.DELETE,
                headers,
                url: `${Constants.deleteFileGroup}/${userFileGroupId}`,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchFiles = async (
        currentPage: number,
        filters?: FileFilters
    ): Promise<{ files: DocumentFile[]; count: string }> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: FileListResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(filters ? { filters } : {}),
                    url: `${Constants.getFilesList}?perPage=50&page=${currentPage}`,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return {
                files: result.body.data.files,
                count: result.headers['x-pi-content-length'],
            };
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchFilteredFileCount = async (
        filters: FileFilters
    ): Promise<IFilesCount> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IFilesCountResponse;
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(filters ? { filters } : {}),
                    url: Constants.getFilesCount,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.data;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchSharedFiles = async (
        currentPage: number
    ): Promise<{ files: DocumentFile[]; count: string }> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: SharedFileListResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.GET,
                    headers,
                    url: `${Constants.getSharedFilesList}?perPage=50&page=${currentPage}`,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return {
                files: result.body.data.files,
                count: result.headers['x-pi-content-length'],
            };
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchFilterFileSuggestion = async (
        req: IDocumentQueryRequest
    ): Promise<IDocumentQuerySuggestionResponseFile[]> => {
        try {
            if (req.searchableField && req.searchableField == 'EVERYWHERE') {
                req.searchableFields = ['title', 'rawText'];
            }
            if (
                req.searchableField &&
                req.searchableField == 'FILE_CONTENT_ONLY'
            ) {
                req.searchableFields = ['rawText'];
            }
            if (
                req.searchableField &&
                req.searchableField == 'FILE_NAME_ONLY'
            ) {
                req.searchableFields = ['title'];
            }

            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IDocumentQueryResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(req),
                url: Constants.documentSearchSuggestions,
                retryCount: 3,
            });

            return result.body.data.files;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchFilterQueryFiles = async (
        req: IDocumentQueryRequest
    ): Promise<{ files: DocumentFile[]; count: string }> => {
        try {
            if (req.searchableField && req.searchableField == 'EVERYWHERE') {
                req.searchableFields = ['title', 'rawText'];
            }
            if (
                req.searchableField &&
                req.searchableField == 'FILE_CONTENT_ONLY'
            ) {
                req.searchableFields = ['rawText'];
            }
            if (
                req.searchableField &&
                req.searchableField == 'FILE_NAME_ONLY'
            ) {
                req.searchableFields = ['title'];
            }

            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: FileListResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(req),
                    url: `${Constants.documentQueryResults}?perPage=10000`,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );

            return {
                files: result.body.data.files,
                count: result.headers['x-pi-content-length'],
            };
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchDocumentMatches = async (
        req: IDocumentMatchesRequest
    ): Promise<IDocumentMatchedResponseFile> => {
        try {
            if (req.searchableField && req.searchableField == 'EVERYWHERE') {
                req.searchableFields = ['title', 'rawText'];
            }
            if (
                req.searchableField &&
                req.searchableField == 'FILE_CONTENT_ONLY'
            ) {
                req.searchableFields = ['rawText'];
            }
            if (
                req.searchableField &&
                req.searchableField == 'FILE_NAME_ONLY'
            ) {
                req.searchableFields = ['title'];
            }

            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IDocumentMatchedResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(req),
                    url: Constants.documentQueryMatchesResults,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );

            return result.body.data.files[0];
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    // const fetchDocumentInsightsQueryResponse = async (
    //     question: string,
    //     userFileIds: string[],
    //     callback: (data: string) => void
    // ): Promise<string> => {
    //     try {
    //         return new Promise((resolve, reject) => {
    //             const headers = {
    //                 'Content-Type': 'application/json',
    //             };
    //             const body: DocumentInsightQueryRequest = {
    //                 question,
    //                 userFileIds,
    //                 chatBotPrompt: 'DOCUMENT_INSIGHTS',
    //             };
    //             triggerRequestWithChunkedResponse({
    //                 method: NetworkRequestMethod.POST,
    //                 headers,
    //                 body: JSON.stringify(body),
    //                 url: Constants.liveQuery,
    //                 retryCount: 0,
    //                 callback,
    //             }).then((data) => {
    //                 resolve(data);
    //             });
    //         });
    //     } catch (error: any) {
    //         console.error(error);
    //         throw error;
    //     }
    // };

    const updateDocumentInsightChat = async (
        documentChatId: string,
        isBookmarked: boolean,
        vote: 'upvote' | 'downvote' | 'neutral',
        chatBotPrompt: string,
        chatQueryId: string,
        chatAnswerId: string
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: TopicChatUpdateRequest = {
                isBookmarked,
                vote,
                chatBotPrompt,
                chatQueryId,
                chatAnswerId,
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PATCH,
                headers,
                body: JSON.stringify(body),
                url: `${Constants.updateDocumentChat}/${documentChatId}`,
                retryCount: 3,
            });

            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchDocumentInsightChatBookmarks = async (
        userFileIds: string[]
    ): Promise<DocumentInsightBookmarkResponseDataElement[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: DocumentInsightBookmarksRequest = {
                userFileIds,
                chatBotPrompt: 'DOCUMENT_INSIGHTS',
                isBookmarked: true,
            };
            const result: {
                body: TopicBookmarksResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: `${Constants.getDocumentChat}?fetch=all`,
                retryCount: 3,
            });

            return result.body.data
                .elements as DocumentInsightBookmarkResponseDataElement[];
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const shareMultipleFiles = async (
        userFileIds: string[]
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body = {
                userFileIds,
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PUT,
                headers,
                body: JSON.stringify(body),
                url: Constants.getSharedFilesList,
                retryCount: 3,
            });

            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const untagMultipleFiles = async (
        userFileIds: string[]
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body = {
                userFileIds,
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.DELETE,
                headers,
                body: JSON.stringify(body),
                url: Constants.updateMultipleDocumentTags,
                retryCount: 3,
            });

            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const deleteMultipleFiles = async (
        userFileIds: string[]
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body = {
                userFileIds,
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.DELETE,
                headers,
                body: JSON.stringify(body),
                url: Constants.getFile,
                retryCount: 3,
            });

            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchFilesFilters =
        async (): Promise<FileFiltersListResponseData> => {
            try {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const result: {
                    body: FileFiltersListResponse;
                    headers: { [key: string]: string };
                } = await triggerRequest({
                    method: NetworkRequestMethod.GET,
                    headers,
                    url: Constants.getFilesFiltersList,
                    retryCount: 3,
                });
                return result.body.data;
            } catch (error: any) {
                console.error(error);
                throw error;
            }
        };

    const getFileInsight = async (
        fileId: string
    ): Promise<InsightsResponseData> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: FileInsightsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.getFile}/${fileId}`,
                retryCount: 3,
            });
            return result.body.data;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const getFileGroupInsight = async (
        groupId: string
    ): Promise<InsightsResponseData> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: FileInsightsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.getFileGroups}/${groupId}`,
                retryCount: 3,
            });
            return result.body.data;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const getS3Link = async (fileName: string): Promise<S3LinkResponseData> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: S3LinkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.getS3FileUploadLink}/${fileName}`,
                retryCount: 3,
            });

            return result.body.data;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchDocumentInsightQueryFilter =
        async (): Promise<IDocumentInsightFiltersResponseData> => {
            if (!appContext) throw '';
            try {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body = {
                    feature: 'DOCUMENT_INSIGHTS',
                };
                const result: {
                    body: IDocumentInsightFiltersResponse;
                    headers: { [key: string]: string };
                } = await triggerRequest({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getChatbotFilters,
                    retryCount: 3,
                });

                return result.body.data;
            } catch (error: any) {
                console.error(error);
                return error;
            }
        };
    const updateDocumentInsightQueryFilter = async (
        settings: IDocumentInsightFiltersResponseData
    ): Promise<boolean> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body = {
                feature: 'DOCUMENT_INSIGHTS',
                settings,
            };
            const result: {
                body: IDocumentInsightFiltersResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.updateChatbotFilters,
                retryCount: 3,
            });

            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };
    return {
        uploadFile,
        fetchFiles,
        getFileInsight,
        fetchFilesFilters,
        queryDocument,
        updateDocumentAspects,
        deleteDocument,
        deleteFileGroup,
        getFileGroupInsight,
        upsertGroup,
        downloadFile,
        updateDocumentTags,
        uploadUrl,
        updateDocumentName,
        fetchSharedFiles,
        shareDocument,
        revokeFileSharing,
        fetchSourcesForQuery,
        // fetchDocumentInsightsQueryResponse,
        fetchDocumentInsightChatBookmarks,
        updateDocumentInsightChat,
        fetchFilterFileSuggestion,
        fetchFilterQueryFiles,
        fetchDocumentMatches,
        updateMultipleDocumentTags,
        shareMultipleFiles,
        untagMultipleFiles,
        deleteMultipleFiles,
        getFileUrl,
        uploadFileToS3,
        fetchUploadFileResponse,
        submitFile,
        fetchFilteredFileCount,
        fetchDocumentInsightQueryFilter,
        updateDocumentInsightQueryFilter,
    };
};
