import React, { useState } from 'react';
import { EarningsTrend } from '../../../../../services/interfaces';
import { Typography, Box } from '@mui/material';
import MixedChart from '../../../../../components/charts/eps-chart';
import { BorderedBox } from '../../../../../components/core/box';
import SingleSelectTab from '../../../../../components/core/input/single-select-tab';

interface IProps {
    earningsTrends: EarningsTrend[];
    estimate: 'revenue' | 'earning';
}

export const EstimatePlot: React.FC<IProps> = ({
    earningsTrends,
    estimate,
}) => {
    const [duration, setDuration] = useState('Quarter');

    const getCategoryLabel = (trend: EarningsTrend) => {
        const currentYear = new Date().getFullYear();
        const currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3);

        let periodLabel = '';

        if (trend.period.endsWith('q')) {
            const quarterOffset = parseInt(trend.period);
            const quarter = ((currentQuarter + quarterOffset - 1) % 4) + 1;
            const year =
                currentYear +
                Math.floor((currentQuarter + quarterOffset - 1) / 4);
            periodLabel = `Q${quarter} ${year}`;
        } else if (trend.period.endsWith('y')) {
            const yearOffset = parseInt(trend.period);
            periodLabel = `${currentYear + yearOffset}`;
        }

        return `${periodLabel}\n (Analysts: ${
            (estimate == 'earning'
                ? trend.earningsEstimate
                : trend.revenueEstimate
            ).analysts || 0
        })`;
    };

    const filteredData = earningsTrends.filter(
        (trend) =>
            (duration === 'Quarter'
                ? trend.period.endsWith('q')
                : trend.period.endsWith('y')) &&
            !['+5y', '-5y'].includes(trend.period)
    );

    const categories = filteredData.map((trend) => getCategoryLabel(trend));

    const seriesTitles =
        estimate == 'earning'
            ? ['Year ago EPS', 'EPS Range']
            : ['Year ago Sales', 'EPS Sales'];

    const series = [
        {
            name: seriesTitles[0],
            type: 'boxPlot',
            data: filteredData.map((trend) => {
                const estimates =
                    estimate == 'earning'
                        ? trend.earningsEstimate
                        : trend.revenueEstimate;
                return {
                    x: getCategoryLabel(trend),
                    y: [
                        estimates.lowEstimate ?? 0,
                        estimates.avgEstimate ?? 0,
                        estimates.avgEstimate ?? 0,
                        estimates.avgEstimate ?? 0,
                        estimates.highEstimate ?? 0,
                    ],
                };
            }),
        },
        {
            name: seriesTitles[1],
            type: 'scatter',
            data: filteredData.map((trend) => ({
                x: getCategoryLabel(trend),
                y:
                    (estimate == 'earning'
                        ? trend.earningsEstimate.yearAgoEps
                        : trend.revenueEstimate.yearAgoSales) ?? 0,
            })),
        },
    ];

    const markers = filteredData.map((_, index) => ({
        seriesIndex: 1,
        dataPointIndex: index,
        fillColor: '#FFDD44',
        strokeColor: '#FFDD44',
        size: 8,
    }));

    return (
        <BorderedBox p={3}>
            <Box pb={4} display={'flex'} justifyContent={'space-between'}>
                <Typography variant="h6">
                    {estimate == 'earning' ? 'Earnings' : 'Revenue'} Estimate
                </Typography>

                <SingleSelectTab
                    getOptionText={(v: string) => v}
                    placeholder="Select source"
                    pb={3}
                    fontSize={12}
                    width={130}
                    options={['Annual', 'Quarter']}
                    selectedValue={duration}
                    onChange={setDuration}
                />
            </Box>
            <MixedChart
                categories={categories}
                series={series as any} // Type assertion to bypass the type mismatch
                markers={markers}
                seriesTitles={seriesTitles}
                chartTitle=""
                xaxisTitle=""
                yaxisTitle={estimate == 'earning' ? 'EPS' : 'Sales'}
                markerColors={['#FFDD44', '#000']}
            />
        </BorderedBox>
    );
};
