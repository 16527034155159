export const TVCaptions = (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_152_118154)">
            <path
                d="M1.33398 13.3327V5.99935C1.33398 5.64573 1.47446 5.30659 1.72451 5.05654C1.97456 4.80649 2.3137 4.66602 2.66732 4.66602H13.334C13.6876 4.66602 14.0267 4.80649 14.2768 5.05654C14.5268 5.30659 14.6673 5.64573 14.6673 5.99935V13.3327C14.6673 13.6863 14.5268 14.0254 14.2768 14.2755C14.0267 14.5255 13.6876 14.666 13.334 14.666H2.66732C2.3137 14.666 1.97456 14.5255 1.72451 14.2755C1.47446 14.0254 1.33398 13.6863 1.33398 13.3327Z"
                stroke="#000033"
                stroke-width="1.5"
            />
            <path
                d="M5.66797 1.66602L8.0013 3.99935L10.3346 1.66602"
                stroke="#000033"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_152_118154">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
