// @ts-ignore
import { addDays, addMinutes, format, formatDistance } from 'date-fns';
import Cookies from 'js-cookie';
import { ComponentType, lazy } from 'react';
import { ACCESS_TOKEN_KEY, COMPANY_ID_KEY } from './auth.service';
import jwt_decode from 'jwt-decode';
import { CookieService } from './cookie.service';
import { REF_CODE_KEY } from '../components/referal-tracker';
const { REACT_APP_DEMO_BASE } = process.env;
const cookieService = new CookieService();

export class UtilsService {
    static isValidEmail(email: string) {
        var mailformat =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.match(mailformat)) {
            return true;
        } else {
            return false;
        }
    }

    static convertToCSV = (objArray: any) => {
        var array =
            typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ',';

                line += array[i][index];
            }

            str += line + '\r\n';
        }

        return str;
    };

    static exportCSVFile = (
        headers: string[],
        items: any,
        fileTitle: string
    ) => {
        if (headers) {
            items.unshift(headers);
        }

        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);

        var csv = this.convertToCSV(jsonObject);

        var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        //@ts-ignore
        if (navigator.msSaveBlob) {
            // IE 10+
            //@ts-ignore
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            var link = document.createElement('a');
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    static isDemoApp = () => {
        if (!REACT_APP_DEMO_BASE) return false;
        return REACT_APP_DEMO_BASE.split(',').includes(window.location.host);
    };

    static average = (data: number[]) => {
        const sum = data.reduce((a, b) => a + b, 0);
        return sum / data.length;
    };

    static mbToBytes = (mb: number) => {
        return mb * 1024 * 1024;
    };

    static getUserId = async (): Promise<string> => {
        let token = (await cookieService.get(ACCESS_TOKEN_KEY)) || '';
        if (token) {
            var decoded: any = jwt_decode(token);
            if (decoded.userId) {
                return decoded.userId;
            }
        }
        return '';
    };

    static toBase64 = (file: File): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = reject;
        });

    static getCompanyId = async (): Promise<string> => {
        return (await cookieService.get(COMPANY_ID_KEY)) || '';
    };
    static getRefCode = async (): Promise<string> => {
        return (await cookieService.get(REF_CODE_KEY)) || '';
    };

    static lazyWithRetry = (componentImport: Function) =>
        lazy(async () => {
            const pageHasAlreadyBeenForceRefreshed = JSON.parse(
                window.localStorage.getItem('page-has-been-force-refreshed') ||
                    'false'
            );

            try {
                const component = await componentImport();

                window.localStorage.setItem(
                    'page-has-been-force-refreshed',
                    'false'
                );

                return component;
            } catch (error) {
                if (!pageHasAlreadyBeenForceRefreshed) {
                    // Assuming that the user is not on the latest version of the application.
                    // Let's refresh the page immediately.
                    window.localStorage.setItem(
                        'page-has-been-force-refreshed',
                        'true'
                    );
                    return window.location.reload();
                }

                // The page has already been reloaded
                // Assuming that user is already using the latest version of the application.
                // Let's let the application crash and raise the error.
                throw error;
            }
        });

    static timeSinceUTC = (date: Date): string => {
        date = addMinutes(date, -1 * date.getTimezoneOffset());
        return formatDistance(date, new Date(), { addSuffix: true });
    };

    static timeSince = (date: Date): string => {
        return formatDistance(date, new Date(), { addSuffix: true });
    };

    static getFileType = (title: string): string => {
        const extension = title.split('.').pop();
        return extension?.toLocaleUpperCase() || '';
    };

    static getFileSize = (fSize: number): string => {
        var fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
        let i = 0;
        while (fSize > 900) {
            fSize /= 1024;
            i++;
        }

        return Math.round(fSize * 100) / 100 + ' ' + fSExt[i];
    };
    static uuidv4() {
        //@ts-ignore
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (
                c ^
                (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
        );
    }
    static formatStringDate(dateString: string, format?: string) {
        try {
            let date = new Date(dateString);
            return UtilsService.formatDate(date, format);
        } catch (error) {
            return '';
        }
    }

    static formatStringDateInMDYFormat(dateString: string) {
        try {
            let date = new Date(dateString);
            return UtilsService.formatDateInMDYFormat(date);
        } catch (error) {
            return '';
        }
    }

    static formatFormattedStringDate(dateString: string) {
        try {
            var months = {
                jan: 0,
                feb: 1,
                mar: 2,
                apr: 3,
                may: 4,
                jun: 5,
                jul: 6,
                aug: 7,
                sep: 8,
                oct: 9,
                nov: 10,
                dec: 11,
            };
            var p = dateString.split('-');
            //@ts-ignore
            let date = new Date(p[2], months[p[1].toLowerCase()], p[0]);
            date = addMinutes(date, date.getTimezoneOffset());
            return format(date, 'MMM dd yyyy');
        } catch (error) {
            return '';
        }
    }

    static formatStringDateForPotentialIndicators(dateString: string) {
        try {
            let date = new Date(dateString);
            date = addMinutes(date, date.getTimezoneOffset());
            return format(date, 'MMM dd yyyy');
        } catch (error) {
            return '';
        }
    }

    static getMonday = (d: Date) => {
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    };

    static formatDate(date: Date, dateFormat?: string) {
        try {
            date = addMinutes(date, date.getTimezoneOffset());
            return format(date, dateFormat || 'do MMM, yyyy');
        } catch (error) {
            return '';
        }
    }

    static formatCurrency(value: number, currencySymbol: string) {
        try {
            const absValue = Math.abs(value).toFixed(2);
            return value < 0
                ? `-${currencySymbol}${absValue}`
                : `${currencySymbol}${absValue}`;
        } catch (error) {
            return value;
        }
    }

    static formatTime(date: Date) {
        try {
            date = addMinutes(date, date.getTimezoneOffset());
            return format(date, 'HH:mm:ss a');
        } catch (error) {
            return '';
        }
    }

    static formatDateInMDYFormat(date: Date) {
        try {
            date = addMinutes(date, date.getTimezoneOffset());
            return format(date, 'MMM d, yyyy');
        } catch (error) {
            return '';
        }
    }

    static formatStringDateWithoutOffset(dateString: string) {
        try {
            let date = new Date(dateString);
            return format(date, 'do MMM, yyyy');
        } catch (error) {
            return '';
        }
    }

    static formatStringDateTimeWithoutOffset(dateString: string) {
        try {
            let date = new Date(dateString);
            return format(date, 'hh:mm:ss a, do MMM, yyyy');
        } catch (error) {
            return '';
        }
    }

    static formatUTCStringDateTime(dateString: string) {
        try {
            let date = new Date(dateString);
            date = addMinutes(date, -1 * date.getTimezoneOffset());
            return format(date, 'hh:mm:ss a, do MMM, yyyy');
        } catch (error) {
            return '';
        }
    }

    static formatUTCStringtoDateTime12H(dateString: string) {
        try {
            let date = new Date(dateString);
            date = addMinutes(date, -1 * date.getTimezoneOffset());
            return `${format(date, ' MMM dd, yyyy')} at ${format(
                date,
                'hh:mm:ss a'
            )}`;
        } catch (error) {
            return '';
        }
    }

    static formatDateWithoutYear(date: Date) {
        try {
            date = addMinutes(date, date.getTimezoneOffset());
            return format(date, 'do MMM');
        } catch (error) {
            return '';
        }
    }

    static getMinDate(dates: Date[]) {
        try {
            let date: Date = dates.reduce((a, b) => (a < b ? a : b));
            date = addMinutes(date, -1 * date.getTimezoneOffset());
            return date;
        } catch (error) {
            throw error;
        }
    }

    static getSessionStorage = (key: string) => {
        return sessionStorage.getItem(key);
    };

    static setSessionStorage = (key: string, value: string) => {
        return sessionStorage.setItem(key, value);
    };

    static isUrlValid = (url: string) => {
        var res = url.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        if (res == null) return false;
        else return true;
    };

    static formatDateWithoutYearWithoutOffset(date: Date) {
        try {
            return format(date, 'do MMM');
        } catch (error) {
            return '';
        }
    }

    static formatDateForNetworkCall(date: Date) {
        date = addMinutes(date, date.getTimezoneOffset());
        return format(date, 'yyyy-MM-dd');
    }

    static isDay(date: Date, day: number) {
        return date.getDay() == day;
    }

    static isFirstMonday(date: Date) {
        return date.getDay() == 1 && date.getDate() < 7;
    }
    static isThirdMonday(date: Date) {
        return date.getDay() == 1 && date.getDate() < 15 && date.getDate() > 6;
    }

    static formatDateForDateTimeNetworkCall(date: Date) {
        date = addMinutes(date, date.getTimezoneOffset());
        return date.toJSON().split('.')[0];
    }

    static formatDateForNetworkCallWithoutOffset(date: Date) {
        return format(date, 'yyyy-MM-dd');
    }

    static getDateWeeksAgo(weeks: number) {
        let days = weeks * 7;
        let d = new Date();
        d.setDate(d.getDate() - days);
        return d;
    }

    static updateQueryStringParameter = (
        uri: string,
        key: string,
        value: string
    ) => {
        var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
        var separator = uri.indexOf('?') !== -1 ? '&' : '?';
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + '=' + value + '$2');
        } else {
            return uri + separator + key + '=' + value;
        }
    };

    static getDateDaysAgo(days: number) {
        let d = new Date();
        d.setDate(d.getDate() - days);
        return d;
    }
    static getDateMonthsAgo(months: number) {
        let d = new Date();
        d.setMonth(d.getMonth() - months);
        return d;
    }

    static getLastDateOfCurrentMonth() {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth() + 1, 0);
    }

    static getDatesBetweenDates = (
        stringStartDate: string,
        stringEndDate: string
    ): string[] => {
        const startDate = new Date(stringStartDate);
        const endDate = new Date(stringEndDate);
        var dateArray: string[] = new Array();
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            let d = new Date(currentDate);
            dateArray.push(UtilsService.formatDateForNetworkCall(d));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateArray;
    };

    static getUTCDateFromString = (date: string) => {
        let d = new Date(date);
        return d;
    };

    static getBrowser = () => {
        let browser = '';
        let c = navigator.userAgent.search('Chrome');
        let f = navigator.userAgent.search('Firefox');
        let m8 = navigator.userAgent.search('MSIE 8.0');
        let m9 = navigator.userAgent.search('MSIE 9.0');
        if (c > -1) {
            browser = 'Chrome';
        } else if (f > -1) {
            browser = 'Firefox';
        } else if (m9 > -1) {
            browser = 'MSIE 9.0';
        } else if (m8 > -1) {
            browser = 'MSIE 8.0';
        }
        return browser;
    };

    static isFirefox = () => {
        return UtilsService.getBrowser() == 'Firefox';
    };

    static numberToString = (value: number) => {
        // Convert the number to a string with exactly two decimal points
        let formatted = value.toFixed(2);

        // Remove the decimal point and the trailing zeros if the value is an integer
        if (formatted.endsWith('.00')) {
            formatted = formatted.slice(0, -3);
        } else if (formatted.endsWith('0')) {
            formatted = formatted.slice(0, -1);
        }

        return formatted;
    };
    /**
     * Converts a camelCase string to title case.
     * @param {string} str - The camelCase string to convert.
     * @return {string} - The converted title case string.
     */
    static camelCaseToTitleCase = (key: string) => {
        return key
            .replace(/([A-Z])/g, ' $1') // Add space before uppercase letters
            .replace(/^./, function (key) {
                return key.toUpperCase();
            }); // Capitalize the first letter
    };

    static isToday = (dateString: string): boolean => {
        const dateToCheck = new Date(dateString);
        const today = new Date();

        return (
            dateToCheck.getFullYear() === today.getFullYear() &&
            dateToCheck.getMonth() === today.getMonth() &&
            dateToCheck.getDate() === today.getDate()
        );
    };

    /**
     * Converts a snake_case string to title case.
     * @param {string} str - The snake_case string to convert.
     * @return {string} - The converted title case string.
     */
    static snakeCaseToTitleCase = (key: string) => {
        return key
            ? key
                  .split('_') // Split the string into an array of words
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
                  .join(' ')
            : ''; // Join the words back into a single string with spaces
    };
    static extractContentFromHtmlString = (html: string) => {
        var span = document.createElement('span');
        span.innerHTML = html;
        return span.textContent || span.innerText;
    };

    static convertToInternationalCurrencySystem = (
        labelValue: string | number
    ): string => {
        if (typeof labelValue == 'string') {
            labelValue = labelValue.split(',').join('');
        }
        // Nine Zeroes for Billions
        try {
            let r =
                Math.abs(Number(labelValue)) >= 1.0e9
                    ? UtilsService.numberToString(
                          Math.abs(Number(labelValue)) / 1.0e9
                      ) + 'B'
                    : // Six Zeroes for Millions
                    Math.abs(Number(labelValue)) >= 1.0e6
                    ? UtilsService.numberToString(
                          Math.abs(Number(labelValue)) / 1.0e6
                      ) + 'M'
                    : // Three Zeroes for Thousands
                    Math.abs(Number(labelValue)) >= 1.0e3
                    ? UtilsService.numberToString(
                          Math.abs(Number(labelValue)) / 1.0e3
                      ) + 'K'
                    : UtilsService.numberToString(Math.abs(Number(labelValue)));
            if (Number.isNaN(r)) return '';
            return `${Number(labelValue) < 0 ? '-' : ''}${r.toString()}`;
        } catch (error) {
            return '';
        }
    };

    static joinWithAnd = (items: string[]): string => {
        if (items.length === 0) {
            return '';
        } else if (items.length === 1) {
            return items[0];
        } else if (items.length === 2) {
            return `${items[0]} and ${items[1]}`;
        } else {
            const allButLast = items.slice(0, -1).join(', ');
            const lastItem = items[items.length - 1];
            return `${allButLast}, and ${lastItem}`;
        }
    };

    static getChangePercentage = (value1: number, value2: number) => {
        let change = ((value2 - value1) / Math.abs(value1)) * 100;
        return `${change < 0 ? '' : '+'}${change.toFixed(2)}`;
    };

    static formatStringDateWithoutYear(stringDate: string) {
        try {
            let date = new Date(stringDate);
            return UtilsService.formatDateWithoutYear(date);
        } catch (error) {
            return '';
        }
    }

    static formatDateWithoutDay(date: Date) {
        try {
            date = addMinutes(date, date.getTimezoneOffset());
            return format(date, 'MMM yyyy');
        } catch (error) {
            return '';
        }
    }
    static getDaysDifference(dateString: string) {
        const date = new Date(dateString);
        const today = new Date();

        // Reset the time to midnight to ignore the time part
        today.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);

        // Calculate the difference in time
        const timeDifference = date.getTime() - today.getTime();

        // Convert the time difference from milliseconds to days
        const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

        return Math.abs(dayDifference);
    }

    static getDateAheadDays = (date: Date, days: number) => {
        try {
            date = addDays(date, days);
            return format(date, 'MMM dd, yyyy');
        } catch (error) {
            return '';
        }
    };

    static formatStringDateWithoutDay(stringDate: string) {
        try {
            let date = new Date(stringDate);
            return UtilsService.formatDateWithoutDay(date);
        } catch (error) {
            return '';
        }
    }

    static getQuarter = (d: Date) => {
        return Math.floor(d.getMonth() / 3 + 1);
    };

    static generateDateGroups = (
        dates: string[]
    ): { title: string; cols: number }[] => {
        let res: { title: string; cols: number }[] = [];

        let data: { [key: string]: number } = {};

        for (let d of dates) {
            let key = UtilsService.formatStringDateWithoutDay(d);
            if (!data[key]) {
                data[key] = 0;
            }
            data[key]++;
        }

        for (let key in data) {
            res.push({
                title: key,
                cols: data[key],
            });
        }
        if (res.length > 10) {
            res = [];
            data = {};
            for (let d of dates) {
                let key = `Q${UtilsService.getQuarter(new Date(d))}-${new Date(
                    d
                ).getFullYear()}`;
                if (!data[key]) {
                    data[key] = 0;
                }
                data[key]++;
            }

            for (let key in data) {
                res.push({
                    title: key,
                    cols: data[key],
                });
            }
        }
        if (res.length > 10) {
            res = [];
            data = {};
            for (let d of dates) {
                let key = new Date(d).getFullYear();
                if (!data[key]) {
                    data[key] = 0;
                }
                data[key]++;
            }

            for (let key in data) {
                res.push({
                    title: key,
                    cols: data[key],
                });
            }
        }

        return res;
    };

    static dataURLtoFile = (
        dataurl: string,
        filename: string
    ): { file: File; fileName: string } | null => {
        try {
            var arr = dataurl.split(','),
                //@ts-ignore
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[arr.length - 1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return {
                file: new File([u8arr], filename, { type: mime }),
                fileName: filename + '.' + dataurl.split(';')[0].split('/')[1],
            };
        } catch (error) {
            return null;
        }
    };

    static waitUntilTokenFetched = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            var interval = setInterval(() => {
                //@ts-ignore
                if (!window.fetchingToken) {
                    clearInterval(interval);
                    resolve();
                }
            }, 100);
        });
    };

    static convertSentimentToPercentage = (value: number) => {
        value = Math.floor(value * 10000);
        return value / 100;
    };

    static getRandomDarkColor = (): string => {
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += Math.floor(Math.random() * 10);
        }
        return color;
    };

    static getForstNonZeroValue = (arr: number[]) => {
        for (let val of arr) {
            if (val > 0) return val;
        }
        return 0;
    };

    static firstCharUppercase = (str: string) => {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    static trimText = (text: string, charCount: number) => {
        return text.length > charCount
            ? text.substring(0, charCount - 3) + '...'
            : text;
    };

    static getRandomLightColor = () => {
        var letters = 'BCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * letters.length)];
        }
        return color;
    };

    static getNextNonZero = (arr: number[], start: number) => {
        for (let index = start + 1; index < arr.length; index++) {
            if (arr[index] != 0) {
                return index;
            }
        }
        return arr.length - 1;
    };

    static convertPlotLine = (arr: number[]) => {
        for (let index = 1; index < arr.length; index++) {
            if (arr[index] == 0) {
                let nextNonZero = UtilsService.getNextNonZero(arr, index - 1);
                if (nextNonZero < 0 || nextNonZero < index) {
                    continue;
                } else {
                    arr[index] =
                        arr[index - 1] +
                        (arr[nextNonZero] - arr[index - 1]) /
                            (nextNonZero - index + 1);
                }
            }
        }
        return [...arr];
    };

    static generateIdFromString = (text: string) => {
        return text
            .toLowerCase()
            .trim()
            .replace(/[^a-z0-9\s]/g, '') // Remove non-alphanumeric characters
            .replace(/\s+/g, '-'); // Replace spaces with hyphens
    };

    static triggerEvent = (e: string) => {
        const customEvent = new Event(e, {
            bubbles: true,
            cancelable: true,
            composed: false,
        });

        let root = document.getElementById('root');
        //@ts-ignore
        root.dispatchEvent(customEvent);
    };

    static highlightContent = (
        sectionId: string,
        defaultCoulor: string,
        highlightColor: string
    ) => {
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(sectionId).style.backgroundColor =
                highlightColor;
        }, 900);
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(sectionId).style.backgroundColor =
                defaultCoulor;
        }, 1900);
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(sectionId).style.backgroundColor =
                highlightColor;
        }, 2400);
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(sectionId).style.backgroundColor =
                defaultCoulor;
        }, 3400);
    };
}
