import React, { createContext, useContext, ReactNode } from 'react';
import {
    FeatureName,
    Permission,
    SubFeatureName,
} from '../services/interfaces';
import PermissionsService from '../services/permissions.service';

// Define the interface for the context
interface PermissionsContextValue {
    isFeatureAuthorized: (featureName: FeatureName) => boolean;
    isSubFeatureAuthorized: (
        featureName: FeatureName,
        subFeatureName: SubFeatureName
    ) => boolean;
}

// Create the context
const PermissionsContext = createContext<PermissionsContextValue | undefined>(
    undefined
);

// Provider component
type PermissionsProviderProps = {
    permissions: Permission[];
    children: ReactNode;
};

export const PermissionsProvider: React.FC<PermissionsProviderProps> = ({
    permissions,
    children,
}) => {
    const service = new PermissionsService(permissions);

    return (
        <PermissionsContext.Provider
            value={{
                isFeatureAuthorized: service.isFeatureAuthorized.bind(service),
                isSubFeatureAuthorized:
                    service.isSubFeatureAuthorized.bind(service),
            }}
        >
            {children}
        </PermissionsContext.Provider>
    );
};

// Custom hook to use permissions
export const usePermissions = (): PermissionsContextValue => {
    const context = useContext(PermissionsContext);
    if (!context) {
        throw new Error(
            'usePermissions must be used within a PermissionsProvider'
        );
    }
    return context;
};
