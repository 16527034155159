import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { UtilsService } from '../../services/utils.service';
import React from 'react';
import { Box } from '@mui/material';
export interface BarLineChartProps {
    series: {
        name: string;
        type: string;
        data: (number | null)[];
    }[];
    labels: string[];
    cssClass?: string;
    height: number;
    opacity?: number;
    yAxisTitle: string;
    showLinesOnly?: boolean;
    onBarClick?: (date: string) => void;
    onLabelClick?: (label: string) => void;
}

// colors: ['#19AF55', '#D82F44', '#29319B'],

export const BarLineDualAxisChart = (props: BarLineChartProps) => {
    const volume = props.series.filter((s: any) => s.name == 'Volume')[0]?.data;
    const { series } = props;
    const options: ApexOptions = {
        chart: {
            height: props.height,
            stacked: true,

            events: {
                dataPointSelection: (event, chartContext, config) => {
                    setTimeout(() => {
                        if (
                            props.labels[config.dataPointIndex] &&
                            props.onBarClick
                        ) {
                            props.onBarClick(
                                props.labels[config.dataPointIndex]
                            );
                        }
                    }, 10);
                },
            },
            toolbar: {
                show: !props.showLinesOnly,
                tools: {
                    download: !props.showLinesOnly,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: [],
                },
            },
        },

        legend: {
            show: true,
            onItemClick: {
                toggleDataSeries: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 2,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'all',
                columnWidth: '70%',
                barHeight: '70%',
                distributed: false,
                rangeBarOverlap: true,
                rangeBarGroupRows: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 0,
            colors: undefined,
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: 'circle',
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
                size: undefined,
                sizeOffset: 3,
            },
        },
        stroke: {
            width: 0,
            colors: [
                '#008FFB',
                '#8F1383',
                '#8032FF',
                '#00ADB5',
                '#E47676',
                '#103C42',
                '#E15022',
                '#D0B800',
                '#45056E',
            ],
            curve: 'smooth',
        },
        colors: [
            '#008FFB',
            '#8F1383',
            '#8032FF',
            '#00ADB5',
            '#E47676',
            '#103C42',
            '#E15022',
            '#D0B800',
            '#45056E',
        ],
        fill: {
            opacity: [
                props.opacity ? props.opacity : 1,
                props.opacity ? props.opacity : 1,
                1,
            ],
        },
        grid: {
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },

        yaxis: [
            {
                title: {
                    text: props.yAxisTitle,
                },
                // max: Math.max(...volume) * 5,
                // tickAmount: 10,
                // logarithmic: true,
                // logBase: 10,
                // forceNiceScale: true,
                labels: {
                    show: true,
                    formatter: function (val, opts): string {
                        if (val == null) {
                            return 'No Data Available';
                        }
                        if (opts && opts.seriesIndex == 2) {
                            let pVal = opts.series[0][opts.dataPointIndex];
                            if (pVal == 0) {
                                return 'No Data Available';
                            }
                        }
                        if (
                            [
                                -1, -0.8, -0.6, -0.4, -0.2, 0, 0.2, 0.4, 0.6,
                                0.8, 1,
                            ].includes(parseFloat(val.toFixed(3)))
                        ) {
                            return val.toFixed(1);
                        } else if (val !== 0) {
                            return val.toFixed(1);
                        } else {
                            return 'No Data Available';
                        }
                    },
                },
            },
        ],
        tooltip: {
            enabled: true,
            marker: {
                show: true,
            },
            x: {
                show: true,
            },
        },
        xaxis: {
            categories: props.labels,
            tooltip: {
                enabled: false,
            },
        },
    };

    return (
        <Box
            sx={{
                '& .apexcharts-tooltip': {
                    zIndex: 1,
                },
            }}
        >
            <Chart options={options} series={series} height={props.height} />
        </Box>
    );
};
