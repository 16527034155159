export const Copy = () => (
    <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_6925_57247"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="21"
        >
            <rect y="0.5" width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_6925_57247)">
            <path
                d="M4.79155 18.2564C4.37062 18.2564 4.01432 18.1106 3.72266 17.8189C3.43099 17.5273 3.28516 17.171 3.28516 16.75V6.79816C3.28516 6.62081 3.34498 6.47231 3.46464 6.35266C3.58429 6.233 3.73279 6.17318 3.91014 6.17318C4.08748 6.17318 4.23598 6.233 4.35564 6.35266C4.4753 6.47231 4.53514 6.62081 4.53514 6.79816V16.75C4.53514 16.8141 4.56184 16.8729 4.61526 16.9263C4.66868 16.9797 4.72744 17.0064 4.79155 17.0064H12.2434C12.4208 17.0064 12.5693 17.0663 12.6889 17.1859C12.8086 17.3056 12.8684 17.4541 12.8684 17.6314C12.8684 17.8088 12.8086 17.9573 12.6889 18.0769C12.5693 18.1966 12.4208 18.2564 12.2434 18.2564H4.79155ZM7.7082 15.3398C7.28725 15.3398 6.93095 15.1939 6.63928 14.9023C6.34763 14.6106 6.2018 14.2543 6.2018 13.8334V4.34624C6.2018 3.92531 6.34763 3.56901 6.63928 3.27734C6.93095 2.98568 7.28725 2.83984 7.7082 2.83984H14.6953C15.1163 2.83984 15.4726 2.98568 15.7642 3.27734C16.0559 3.56901 16.2017 3.92531 16.2017 4.34624V13.8334C16.2017 14.2543 16.0559 14.6106 15.7642 14.9023C15.4726 15.1939 15.1163 15.3398 14.6953 15.3398H7.7082ZM7.7082 14.0898H14.6953C14.7594 14.0898 14.8182 14.0631 14.8716 14.0097C14.9251 13.9563 14.9518 13.8975 14.9518 13.8334V4.34624C14.9518 4.28213 14.9251 4.22337 14.8716 4.16995C14.8182 4.11653 14.7594 4.08982 14.6953 4.08982H7.7082C7.64409 4.08982 7.58532 4.11653 7.53191 4.16995C7.47848 4.22337 7.45176 4.28213 7.45176 4.34624V13.8334C7.45176 13.8975 7.47848 13.9563 7.53191 14.0097C7.58532 14.0631 7.64409 14.0898 7.7082 14.0898Z"
                fill="#A5A6AB"
            />
        </g>
    </svg>
);
