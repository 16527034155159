interface IProps {
    size: number;
}

export const RiskFactors = (props: IProps) => (
    <svg
        width={props.size}
        height={props.size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.6666 12V10.6667H12.3999L8.93325 7.23333L6.26658 9.9L1.33325 4.93333L2.26659 4L6.26658 8L8.93325 5.33333L13.3333 9.73333V8H14.6666V12H10.6666Z"
            fill="#D82F44"
        />
    </svg>
);
