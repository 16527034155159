import { useContext } from 'react';
import { Constants } from '../constants';
import { AppContext } from '../providers/app-provider';
import {
    IDetailedInformation,
    DetailedInsightsRequest,
    DetailedInsightsResponse,
    IFetchScrapUrlsResponse,
    IQueryEntitiesesponseData,
    IRecentHeadlines,
    LatestInsight,
    LatestInsightsResponse,
    LiveQueryRequest,
    LiveQueryResponseDataSearch,
    LiveQueryResponseDataSearchResponse,
    PotentialIndicatorsRequest,
    PotentialIndicatorsResponse,
    PotentialIndicatorsResponseData,
    PotentialSentiment,
} from './interfaces';
import { NetworkRequestMethod, useNetworkAPI } from './network.service';
import { UtilsService } from './utils.service';

export enum Granularity {
    DAILY = 'DAILY',
    ARTICLE = 'ARTICLE',
    WEEKLY = 'WEEKLY',
}

export const useInsightsService = () => {
    const appContext = useContext(AppContext);
    const { triggerRequest, triggerRequestWithChunkedResponse } =
        useNetworkAPI();

    const fetchPotentialIndicators = async (
        topicSubscriptionIds: string[],
        granularity: Granularity,
        pageNo: number,
        sortBy: string
    ): Promise<{
        data: (PotentialSentiment | null)[][];
        count: string;
    }> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: PotentialIndicatorsRequest = {
                topicSubscriptionIds,
                startDate: appContext.filters.date.startDate,
                endDate: appContext.filters.date.endDate,
                categoryId: appContext?.category ? appContext?.category : '',
                granularity: granularity,
                sortBy: sortBy.toUpperCase(),
            };
            const result: {
                body: PotentialIndicatorsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: `${Constants.getPotentialIndicators}${pageNo}`,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return {
                    data: processPotentialIndicatorsResponse(result.body.data),
                    count: result.headers['x-pi-content-length'],
                };
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchAllPotentialIndicatorsForDate = async (
        topicSubscriptionIds: string[],
        granularity: Granularity,
        date: string
    ): Promise<PotentialIndicatorsResponseData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: PotentialIndicatorsRequest = {
                topicSubscriptionIds,
                startDate: date,
                endDate: date,
                categoryId: appContext?.category ? appContext?.category : '',
                granularity: granularity,
            };
            const result: {
                body: PotentialIndicatorsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getAllPotentialIndicators,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchAllDetailedInsightsForDate = async (
        topicSubscriptionIds: string[],
        granularity: Granularity,
        date?: string,
        categoryId?: string
    ): Promise<IDetailedInformation[]> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: DetailedInsightsRequest = {
                topicSubscriptionIds,
                startDate: date || appContext.filters.date.startDate,
                endDate: date || appContext.filters.date.endDate,
                categoryId: categoryId
                    ? categoryId
                    : appContext?.category
                    ? appContext?.category
                    : '',
                granularity: granularity,
            };
            const result: {
                body: DetailedInsightsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getAllDetailedInsights,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data.detailedInformation;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchLatestInsights = async (
        topicSubscriptionIds: string[],
        granularity: Granularity,
        pageNo: number
    ): Promise<{
        data: LatestInsight[];
        count: string;
    }> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: PotentialIndicatorsRequest = {
                topicSubscriptionIds,
                startDate: appContext.filters.date.startDate,
                endDate: appContext.filters.date.endDate,
                categoryId: appContext?.category ? appContext?.category : '',
                granularity: granularity,
            };
            const result: {
                body: LatestInsightsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getLatestInsights + pageNo,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return {
                    data: result.body.data.latestInsights,
                    count: result.headers['x-pi-content-length'],
                };
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchDetailedInsights = async (
        topicSubscriptionIds: string[],
        granularity: Granularity,
        pageNo: number,
        date?: string
    ): Promise<{
        data: IDetailedInformation[];
        count: string;
    }> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: DetailedInsightsRequest = {
                topicSubscriptionIds,
                startDate: date || appContext.filters.date.startDate,
                endDate: date || appContext.filters.date.endDate,
                categoryId: appContext?.category ? appContext?.category : '',
                granularity: granularity,
            };
            const result: {
                body: DetailedInsightsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getDetailedInsights + pageNo,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return {
                    data: result.body.data.detailedInformation,
                    count: result.headers['x-pi-content-length'],
                };
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const processPotentialIndicatorsResponse = (
        data: PotentialIndicatorsResponseData
    ) => {
        let _data: (PotentialSentiment | null)[][] = [];
        for (
            let index = 0;
            index <
            Math.max(
                data.potentialNegatives.length,
                data.potentialPositives.length
            );
            index++
        ) {
            _data.push([]);
            _data[index].push(
                data.potentialPositives[index]
                    ? data.potentialPositives[index]
                    : null
            );
            _data[index].push(
                data.potentialNegatives[index]
                    ? data.potentialNegatives[index]
                    : null
            );
        }
        return _data;
    };

    return {
        fetchPotentialIndicators,
        fetchLatestInsights,
        processPotentialIndicatorsResponse,
        fetchDetailedInsights,
        fetchAllPotentialIndicatorsForDate,
        fetchAllDetailedInsightsForDate,
    };
};
