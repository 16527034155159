export const PositiveInsight = (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.06 6.28065L8 3.22065L4.94 6.28065L4 5.33398L8 1.33398L12 5.33398L11.06 6.28065ZM11.06 10.2807L8 7.22065L4.94 10.2807L4 9.33399L8 5.33398L12 9.33399L11.06 10.2807ZM11.06 14.2807L8 11.2207L4.94 14.2807L4 13.334L8 9.33399L12 13.334L11.06 14.2807Z"
            fill="#19AF55"
        />
    </svg>
);
