import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Header } from './header';
import { ContainedBox } from '../../components/core/box';
import ResetPassword from '../auth/reset-password';
import { APIKeys } from './api-keys';
import { SlackIntegration } from './slack-integration';
import { MSTeamsIntegration } from './ms-teams-integration';
import { TabPanel } from '../../components/core/tab-panel';
import { CustomDomains } from './custom-domains';
import { useAuthService } from '../../services/auth.service';
import { useSearchParams } from 'react-router-dom';
import { PlansBilling } from './plans-billing';
import { useQueryparamServices } from '../../services/query-param.service';
import { QuotaManagement } from './plans-billing/quota-management';
import { HtmlTooltip } from '../../components/authorize';
import { CloneProps } from '../document-insights/drive';
import { FeatureName, SubFeatureName } from '../../services/interfaces';
import { usePermissions } from '../../providers/permissions-provider';
import { AppContext } from '../../providers/app-provider';

enum ETabs {
    API_KEYS = 'API_KEYS',
    RESET_PASSWORD = 'RESET_PASSWORD',
    INTEGRATION = 'INTEGRATION',
    CUSTOM_DOMAINS = 'CUSTOM_DOMAINS',
    PLANS_BILLING = 'PLANS_BILLING',
    USAGE_DASHBOARD = 'USAGE_DASHBOARD',
}

const Account = () => {
    const [value, setValue] = useState(ETabs.RESET_PASSWORD);
    const appContext = useContext(AppContext);
    const [searchParam, setSearchParams] = useSearchParams();
    const { getPlanType } = useAuthService();
    const [planType, setPlanType] = useState<string | undefined>('');
    const { addQueryParams } = useQueryparamServices();
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    const { getUserRole } = useAuthService();
    const [role, setRole] = useState<string | undefined>(undefined);
    const initialize = async () => {
        let userRole = await getUserRole();
        setRole(userRole);
        let pType = await getPlanType();
        setPlanType(pType);
    };

    useEffect(() => {
        initialize();
        let tab = searchParam.get('tab');
        if (
            tab == ETabs.API_KEYS &&
            !isSubFeatureAuthorized(
                FeatureName.CustomAPIs,
                SubFeatureName.CustomApiKey
            )
        ) {
            setValue(ETabs.RESET_PASSWORD);
        }
        if (tab) {
            setValue(ETabs[tab.toUpperCase() as keyof typeof ETabs]);
        }
    }, [searchParam.get('tab')]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        addQueryParams('tab', newValue.toString());
    };
    return (
        <Box>
            <Header />
            <Box textAlign={'left'} p={6}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        sx={{
                            '& .MuiTabs-flexContainer': {
                                flexWrap: 'wrap',
                            },
                        }}
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab
                            sx={{
                                fontWeight:
                                    value == ETabs.RESET_PASSWORD ? 600 : 400,
                            }}
                            value={ETabs.RESET_PASSWORD}
                            label="Reset Password"
                        />
                        <CloneProps>
                            {(tabProps: any) => (
                                <HtmlTooltip
                                    arrow
                                    placement={'top'}
                                    open={
                                        appContext &&
                                        appContext.permissions.length > 0 &&
                                        isSubFeatureAuthorized(
                                            FeatureName.CustomAPIs,
                                            SubFeatureName.CustomApiKey
                                        )
                                            ? false
                                            : undefined
                                    }
                                    title={
                                        <Box py={2} textAlign={'center'} px={1}>
                                            <Typography variant="h5">
                                                {'Feature Unavailable'}
                                            </Typography>
                                            <Typography mt={2}>
                                                {`This feature is not included in your current plan. Upgrade to access this functionality.`}
                                            </Typography>
                                        </Box>
                                    }
                                >
                                    <span>
                                        <Tab
                                            {...tabProps}
                                            disabled={
                                                !(
                                                    appContext &&
                                                    appContext.permissions
                                                        .length > 0 &&
                                                    isSubFeatureAuthorized(
                                                        FeatureName.CustomAPIs,
                                                        SubFeatureName.CustomApiKey
                                                    )
                                                )
                                            }
                                            sx={{
                                                fontWeight:
                                                    value == ETabs.API_KEYS
                                                        ? 600
                                                        : 400,
                                            }}
                                            value={ETabs.API_KEYS}
                                            label={'API Keys'}
                                        />
                                    </span>
                                </HtmlTooltip>
                            )}
                        </CloneProps>
                        <Tab
                            sx={{
                                fontWeight:
                                    value == ETabs.INTEGRATION ? 600 : 400,
                            }}
                            value={ETabs.INTEGRATION}
                            label="Integrations"
                        />
                        <Tab
                            sx={{
                                fontWeight:
                                    value == ETabs.CUSTOM_DOMAINS ? 600 : 400,
                            }}
                            value={ETabs.CUSTOM_DOMAINS}
                            label="Custom Domains Setup"
                        />
                        {role == 'admin' &&
                            planType &&
                            (planType == 'ENTERPRISE' ? (
                                <Tab
                                    value={ETabs.USAGE_DASHBOARD}
                                    sx={{
                                        fontWeight:
                                            value == ETabs.USAGE_DASHBOARD
                                                ? 600
                                                : 400,
                                    }}
                                    label="Usage Dashboard"
                                />
                            ) : (
                                <Tab
                                    value={ETabs.PLANS_BILLING}
                                    sx={{
                                        fontWeight:
                                            value == ETabs.PLANS_BILLING
                                                ? 600
                                                : 400,
                                    }}
                                    label="Plans and Billing"
                                />
                            ))}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={ETabs.RESET_PASSWORD}>
                    <ResetPassword />
                </TabPanel>
                <TabPanel value={value} index={ETabs.API_KEYS}>
                    <APIKeys />
                </TabPanel>
                <TabPanel value={value} index={ETabs.INTEGRATION}>
                    <SlackIntegration />
                    <MSTeamsIntegration />
                </TabPanel>
                <TabPanel value={value} index={ETabs.CUSTOM_DOMAINS}>
                    <CustomDomains />
                </TabPanel>

                {role == 'admin' &&
                    planType &&
                    (planType == 'ENTERPRISE' ? (
                        <TabPanel value={value} index={ETabs.USAGE_DASHBOARD}>
                            <QuotaManagement />
                        </TabPanel>
                    ) : (
                        <TabPanel value={value} index={ETabs.PLANS_BILLING}>
                            <PlansBilling />
                        </TabPanel>
                    ))}
            </Box>
        </Box>
    );
};

export default Account;
