import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
export interface IProps {
    series: {
        name: string;
        data: number[];
        color?: string;
    }[];
    labels: string[] | string[][];
    colors: string[];
}

export const ScatterChart = (props: IProps) => {
    let options: ApexOptions = {
        chart: {
            type: 'scatter',
            animations: {
                enabled: false,
            },
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },

        //@ts-ignore
        labels: props.labels,
        xaxis: {
            labels: {
                style: {
                    colors: props.colors,
                },
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            forceNiceScale: true,
            min: Math.min(...props.series.flatMap((series) => series.data)),
            max: Math.max(...props.series.flatMap((series) => series.data)),
        },
        markers: {
            size: 9,
            colors: ['#747474', '#747474'],
            hover: {
                size: undefined,
                sizeOffset: 1,
            },
            discrete: props.colors.map((c, index) => {
                return {
                    seriesIndex: 1,
                    dataPointIndex: index,
                    fillColor: c,
                    strokeColor: c,
                    size: 8,
                };
            }),
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
        },

        grid: {
            padding: {
                top: 0,
                right: 30,
                bottom: 0,
                left: 30,
            },
        },
        legend: {
            show: false,
        },
    };

    return (
        <div>
            <Chart
                options={options}
                type="scatter"
                series={props.series}
                height={300}
            />
        </div>
    );
};
