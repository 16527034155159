import { useEffect, useState } from 'react';
import { BorderedBox } from '../../../../../components/core/box';
import { Box, Divider, Typography } from '@mui/material';
import { BankOutlined } from '../../../../../components/icons/bank-outlined';
import { useFinancialsService } from '../../../../../services/financials.service';
import { FinancialAllFilingsResponseDataElement } from '../../../../../services/interfaces';
import { UtilsService } from '../../../../../services/utils.service';
import { EarningsCallIcon } from '../../../../../components/icons/earnings-call';
import { Subscribe } from '../../../../../utils/subscribe';
import { LockIcon } from '../../../../../components/icons/lock';

interface IProps {
    openFiling: (filing: FinancialAllFilingsResponseDataElement) => void;
    topicSubscriptionId: string;
    query: string;
}

export const AllFilings = (props: IProps) => {
    const { fetchAllFilings } = useFinancialsService();
    const [filings, setFilings] = useState<
        FinancialAllFilingsResponseDataElement[]
    >([]);
    let topicSubscriptionId = props.topicSubscriptionId;

    const refreshFilings = async () => {
        if (!topicSubscriptionId) return;
        const data = await fetchAllFilings(topicSubscriptionId);
        setFilings([...data.elements]);
    };

    useEffect(() => {
        refreshFilings();
    }, [topicSubscriptionId]);
    return (
        <BorderedBox sx={{ backgroundColor: '#FFFFFF' }} pt={3}>
            <Box>
                <Box display="flex" alignItems="center">
                    <Typography px={4} variant="h6">
                        Latest Filings & Reports
                    </Typography>
                    {!topicSubscriptionId && <LockIcon color="#161616" />}
                </Box>

                <Box pt={2}>
                    <Divider />
                </Box>

                <Box sx={{ height: '455px', overflowY: 'auto' }} pb={3}>
                    {/* {!topicSubscriptionId && (
                        <Box sx={{ position: 'relative' }}>
                            <Box
                                sx={{
                                    background:
                                        'linear-gradient(180deg, rgba(147, 158, 255, 0.25) 0%, rgba(255, 255, 255, 0.25) 100%)',
                                }}
                            >
                                {[1, 2, 3, 4, 5, 6, 7, 8].map((index, arr) => (
                                    <Box>
                                        <Box
                                            sx={{
                                                pointerEvents: 'none',
                                                opacity: 0.7,
                                            }}
                                        >
                                            <Box
                                                px={4}
                                                py={2}
                                                justifyContent="space-between"
                                                display="flex"
                                            >
                                                <Box display="flex">
                                                    <Box mr={1} pt={0.5}>
                                                        <BankOutlined
                                                            size={20}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Typography
                                                            color="#4B4B4C"
                                                            variant="h6"
                                                            className="clamp-line-1"
                                                            fontSize={14}
                                                        >
                                                            Q3 2023 SEC Filings
                                                            -10Q
                                                        </Typography>
                                                        <Typography
                                                            fontSize={10}
                                                            fontWeight={600}
                                                            color={'#A5A6AB'}
                                                        >
                                                            {UtilsService.formatStringDate(
                                                                '2023-10-20T18:41:03'
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        border: '1px solid #29319B',
                                                        borderRadius: 1,
                                                        color: '#29319B',
                                                        py: 0,
                                                        textTransform:
                                                            'uppercase',
                                                        cursor: 'pointer',
                                                        mt: 2.5,
                                                        fontSize: 14,
                                                        fontWeight: 600,
                                                        lineHeight: '20px',
                                                        px: 2,
                                                        minWidth: 130,
                                                        height: 'fit-content',
                                                        ml: 6,
                                                        '&:hover': {
                                                            backgroundColor:
                                                                '#EBECF6',
                                                        },
                                                    }}
                                                    variant="button"
                                                >
                                                    View Insights
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                            <Subscribe
                                message="Unlock Exclusive Insights from Earnings Calls & SEC Filings"
                                query={props.query}
                            />
                        </Box>
                    )} */}
                    {topicSubscriptionId &&
                        filings.map(
                            (
                                f: FinancialAllFilingsResponseDataElement,
                                index,
                                arr
                            ) => (
                                <Box>
                                    <Box
                                        px={4}
                                        py={2}
                                        justifyContent="space-between"
                                        display="flex"
                                    >
                                        <Box display="flex">
                                            <Box mr={1} pt={0.5}>
                                                {f.financeCategory ==
                                                'sec_filings' ? (
                                                    <BankOutlined size={20} />
                                                ) : (
                                                    <EarningsCallIcon
                                                        size={20}
                                                        color="#4B4B4C"
                                                    />
                                                )}
                                            </Box>
                                            <Box>
                                                <Typography
                                                    color="#4B4B4C"
                                                    variant="h6"
                                                    className="clamp-line-1"
                                                    fontSize={14}
                                                >
                                                    {`${
                                                        f.filterElement.quarter
                                                    } ${f.filterElement.year} ${
                                                        f.financeCategory ==
                                                        'earnings_calls'
                                                            ? 'Earnings Calls'
                                                            : `SEC Filings - ${f.filterElement.documentType
                                                                  .split('-')
                                                                  .join('')}`
                                                    }`}
                                                </Typography>
                                                <Typography
                                                    fontSize={10}
                                                    fontWeight={600}
                                                    color={'#A5A6AB'}
                                                >
                                                    {UtilsService.formatStringDate(
                                                        f.articleTimestamp
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Typography
                                            sx={{
                                                border: '1px solid #29319B',
                                                borderRadius: 1,
                                                color: '#29319B',
                                                py: 0,
                                                textTransform: 'uppercase',
                                                cursor: 'pointer',
                                                mt: 2.5,
                                                fontSize: 14,
                                                fontWeight: 600,
                                                lineHeight: '20px',
                                                px: 2,
                                                minWidth: 130,
                                                height: 'fit-content',
                                                ml: 6,
                                                '&:hover': {
                                                    backgroundColor: '#EBECF6',
                                                },
                                            }}
                                            onClick={() => {
                                                props.openFiling(f);
                                            }}
                                            variant="button"
                                        >
                                            View Insights
                                        </Typography>
                                    </Box>
                                    {index < arr.length - 1 && <Divider />}
                                </Box>
                            )
                        )}
                </Box>
            </Box>
        </BorderedBox>
    );
};
