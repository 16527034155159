import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
export interface IProps {
    value: number;
    color: string;
}

export const RadialChart = (props: IProps) => {
    let options: ApexOptions = {
        chart: {
            height: 100,
            type: 'radialBar',
            sparkline: {
                enabled: true,
            },
        },
        stroke: {
            lineCap: 'round',
        },
        colors: [props.color],
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    size: '50%',
                },
                dataLabels: {
                    show: false,
                },
                track: {
                    strokeWidth: '80%',
                    background: '#f2f2f2',
                },
            },
        },
    };

    return (
        <div>
            <Chart
                options={options}
                type="radialBar"
                series={[props.value]}
                height={100}
            />
        </div>
    );
};
