import { useContext, useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { BorderedBox } from '../../../components/core/box';
import { AppContext } from '../../../providers/app-provider';
import { useClusterService } from '../../../services/cluster.service';
import {
    AlertsCluster,
    Cluster,
    Topic,
    UserCluster,
    UserTopic,
} from '../../../services/interfaces';
import { DeleteWatchlist } from './delete-watchlist.tsx';
import { AddOrEditWatchlist } from './add-edit-watchlist.tsx';
import { SetAlerts } from './set-alerts.tsx';

export const Watchlists = () => {
    const [clusters, setClusters] = useState<Cluster[]>([]);
    const [alertsSettings, setAlertsSettings] = useState<AlertsCluster[]>([]);
    const appContext = useContext(AppContext);
    const { fetchAlertsSetting, reFetchUserClusters } = useClusterService();

    const refreshClusters = async () => await reFetchUserClusters();
    const reFetchSettings = async () => {
        let data = await fetchAlertsSetting();
        setAlertsSettings([...data]);
    };

    useEffect(() => {
        reFetchSettings();
    }, []);

    useEffect(() => {
        if (appContext?.clusters) {
            setClusters([
                ...appContext.clusters.map((item: UserCluster): Cluster => {
                    return {
                        clusterName: item.dashboardClusterName,
                        clusterId: item.dashboardClusterId,
                        topics: item.userTopicSubscriptions.map(
                            (topicItem: UserTopic): Topic => {
                                return {
                                    topicName: topicItem.subscriptionName,
                                    subscriptionName:
                                        topicItem.subscriptionName,
                                    topicSubscriptionId:
                                        topicItem.topicSubscriptionId,
                                    classification: [],
                                };
                            }
                        ),
                    };
                }),
            ]);
            reFetchSettings();
        }
    }, [appContext?.clusters]);

    return (
        <Box>
            <Box pt={4}>
                <Box mb={4} display="flex" justifyContent="space-between">
                    <Typography variant="h3">Watchlists</Typography>
                    <AddOrEditWatchlist refreshClusters={refreshClusters} />
                </Box>

                <Box sx={{ height: 'calc(100vh - 238px)', overflowY: 'auto' }}>
                    {clusters &&
                        clusters.map((cluster, index) => (
                            <BorderedBox
                                sx={{
                                    mt: index == 0 ? 0 : 4,
                                    p: 4,
                                    backgroundColor: '#F9F9FF',
                                }}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    pr={8}
                                >
                                    <Typography variant="h6">
                                        {cluster.clusterName}
                                    </Typography>
                                    <Box>
                                        <SetAlerts
                                            cluster={cluster}
                                            alertSettings={alertsSettings}
                                            reFetchSettings={() =>
                                                reFetchSettings()
                                            }
                                        />
                                        <AddOrEditWatchlist
                                            cluster={cluster}
                                            refreshClusters={() =>
                                                refreshClusters()
                                            }
                                        />
                                        <DeleteWatchlist
                                            cluster={cluster}
                                            refreshClusters={() =>
                                                refreshClusters()
                                            }
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" flexWrap="wrap" mt={1}>
                                    {cluster.topics.map((topic) => (
                                        <Box
                                            sx={{
                                                mr: 2,
                                                mt: 2,
                                                border: '1px solid #ccc',
                                                borderRadius: '20px',
                                                px: 3,
                                                py: 1,
                                            }}
                                        >
                                            {topic.subscriptionName}
                                        </Box>
                                    ))}
                                </Box>
                            </BorderedBox>
                        ))}
                </Box>
            </Box>
        </Box>
    );
};
