import {
    Avatar,
    Box,
    Grid,
    Pagination,
    PaginationItem,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import LinearProgress, {
    linearProgressClasses,
} from '@mui/material/LinearProgress';
import styled from '@emotion/styled';
import { Sort, SortOrder } from '../../../../components/icons/sort';
import { Expand } from '../../../../components/icons/expand';
import { BorderedContainedBox } from '../../../../components/core/box';
import {
    NegativeLinearProgress,
    PositiveLinearProgress,
} from '../../../../components/progress';
import {
    BorderedButton,
    BorderedLightButton,
} from '../../../../components/core/button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDistributionService } from '../../../../services/distribution.service';
import { ArticleInsight } from '../../../../services/interfaces';
import { Granularity } from '../../../../services/insights.service';
import { UtilsService } from '../../../../services/utils.service';
import { NavigationLink } from '../../../../components/core/link';
import { TextHighlight } from '../../../../components/sentiment-highlights';
import { AppContext } from '../../../../providers/app-provider';
import {
    sourceDistributionArticleListPageClick,
    sourceDistributionGoToArticlePageClick,
} from '../../../../analytics/analytics';

interface IProps {
    source: string;
    setSource: Function;
    topicSubscriptionId?: string;
}

export const DetailedInformation = (props: IProps) => {
    const { topicSubscriptionId } = props;
    const appContext = useContext(AppContext);
    const { fetchSourceDistributionArticleDetails } = useDistributionService();
    const [data, setData] = useState<ArticleInsight[] | null>(null);
    const [searchParam] = useSearchParams();
    const [pagesCount, setPagesCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const refreshData = async () => {
        let source = searchParam.get('source');
        if (topicSubscriptionId && source) {
            setData(null);
            let res: {
                data: ArticleInsight[];
                count: string;
            } = await fetchSourceDistributionArticleDetails(
                [topicSubscriptionId],
                Granularity.ARTICLE,
                source,
                currentPage
            );
            setPagesCount(Math.ceil(parseInt(res.count) / 10));
            setData([...res.data]);
            if (!props.source && res.data[0]) {
                props.setSource(res.data[0].sourceName);
            }
        }
    };
    useEffect(() => {
        let source = searchParam.get('source');
        if (topicSubscriptionId && source) {
            refreshData();
        }
    }, [
        topicSubscriptionId,
        searchParam,
        currentPage,
        appContext?.filters.date.startDate,
        appContext?.filters.date.endDate,
    ]);
    return (
        <Box>
            {data?.map((insight: ArticleInsight, index) => (
                <BorderedContainedBox key={index} my={4}>
                    <Grid spacing={8} container>
                        <Grid xs={12} sm={6} md={8} item>
                            <Box>
                                <Box sx={{ mb: 2 }}>
                                    <Typography
                                        sx={{
                                            color: '#000033',
                                            fontWeight: 600,
                                        }}
                                        variant="h4"
                                    >
                                        {UtilsService.formatStringDate(
                                            insight.date
                                        )}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        highlight={insight.highlightIndexes}
                                        text={insight.summary}
                                        component={TextHighlight}
                                        variant="caption"
                                    ></Typography>
                                </Box>
                                <NavigationLink
                                    target="_blank"
                                    to={insight.articleLink}
                                    onClick={() => {
                                        sourceDistributionGoToArticlePageClick(
                                            topicSubscriptionId || '',
                                            searchParam.get('source') || '',
                                            insight.articleLink
                                        );
                                    }}
                                >
                                    <BorderedButton
                                        sx={{
                                            mt: 4,
                                            backgroundColor: '#ffffff',
                                        }}
                                    >
                                        Go to Article page
                                    </BorderedButton>
                                </NavigationLink>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={6} md={4} item>
                            <Grid pt={4} spacing={8} container>
                                <Grid xs={12} sm={6} item>
                                    {insight.sentiment.positive && (
                                        <Box>
                                            <PositiveLinearProgress
                                                variant="determinate"
                                                value={UtilsService.convertSentimentToPercentage(
                                                    insight.sentiment.positive
                                                )}
                                            />
                                            <Typography
                                                sx={{ mt: 2 }}
                                                variant="h6"
                                            >
                                                {UtilsService.convertSentimentToPercentage(
                                                    insight.sentiment.positive
                                                )}
                                                %
                                            </Typography>
                                        </Box>
                                    )}
                                </Grid>
                                <Grid xs={12} sm={6} item>
                                    {insight.sentiment.negative && (
                                        <Box>
                                            <NegativeLinearProgress
                                                variant="determinate"
                                                value={UtilsService.convertSentimentToPercentage(
                                                    insight.sentiment.negative
                                                )}
                                            />
                                            <Typography
                                                sx={{ mt: 2 }}
                                                variant="h6"
                                            >
                                                {UtilsService.convertSentimentToPercentage(
                                                    insight.sentiment.negative
                                                )}
                                                %
                                            </Typography>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </BorderedContainedBox>
            ))}
            {!data && (
                <BorderedContainedBox my={4}>
                    <Grid spacing={8} container>
                        <Grid xs={12} sm={6} md={8} item>
                            <Box>
                                <Box sx={{ mb: 2 }}>
                                    <Skeleton
                                        width={200}
                                        height={40}
                                        variant="text"
                                    />
                                </Box>
                                <Box>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                </Box>
                                <BorderedButton
                                    sx={{
                                        mt: 4,
                                        backgroundColor: '#ffffff',
                                    }}
                                >
                                    <Skeleton width={100} variant="text" />
                                </BorderedButton>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={6} md={4} item>
                            <Grid pt={4} spacing={8} container>
                                {[1, 2].map(() => (
                                    <Grid xs={12} sm={6} item>
                                        <Box>
                                            <Skeleton variant="text" />

                                            <Skeleton
                                                width={30}
                                                variant="text"
                                            />
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </BorderedContainedBox>
            )}
            <Box sx={{ mt: 2 }}>
                <Stack spacing={2}>
                    <Pagination
                        sx={{ mr: 'auto' }}
                        count={pagesCount}
                        size="small"
                        onChange={(e, p) => {
                            setCurrentPage(p);
                            sourceDistributionArticleListPageClick(
                                topicSubscriptionId || '',
                                searchParam.get('source') || '',
                                p.toString()
                            );
                        }}
                        renderItem={(item) => (
                            <PaginationItem
                                slots={{
                                    previous: ArrowBackIcon,
                                    next: ArrowForwardIcon,
                                }}
                                {...item}
                            />
                        )}
                    />
                </Stack>
            </Box>
        </Box>
    );
};
