export const DownloadIcon = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_1039_111500"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1039_111500)">
            <path
                d="M12.7071 15.839C12.3166 16.2296 11.6834 16.2296 11.2929 15.839L7.50934 12.0555C7.15863 11.7048 7.15455 11.1374 7.50017 10.7817C7.8529 10.4187 8.43448 10.4145 8.79239 10.7724L11.1 13.0801V3.89997C11.1 3.40293 11.503 3 12 3C12.497 3 12.9 3.40293 12.9 3.89997V13.0801L15.2076 10.7724C15.5655 10.4145 16.1471 10.4187 16.4998 10.7817C16.8455 11.1374 16.8414 11.7048 16.4907 12.0555L12.7071 15.839ZM5.16925 21C4.56308 21 4.05 20.79 3.63 20.37C3.21 19.95 3 19.4369 3 18.8308V16.4769C3 15.9799 3.40294 15.5769 3.89999 15.5769C4.39704 15.5769 4.79998 15.9799 4.79998 16.4769V18.8308C4.79998 18.9231 4.83844 19.0077 4.91536 19.0846C4.9923 19.1616 5.07693 19.2 5.16925 19.2H18.8307C18.9231 19.2 19.0077 19.1616 19.0846 19.0846C19.1616 19.0077 19.2 18.9231 19.2 18.8308V16.4769C19.2 15.9799 19.603 15.5769 20.1 15.5769C20.5971 15.5769 21 15.9799 21 16.4769V18.8308C21 19.4369 20.79 19.95 20.37 20.37C19.95 20.79 19.4369 21 18.8307 21H5.16925Z"
                fill="#29319B"
            />
        </g>
    </svg>
);
