import { Box, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SourceSelection } from '../../../../components/core/source-selection';
import { DateInput } from '../../../../components/date-range-input';
import { TopicSourceDistribution } from './source-distribution';
import { AppContext } from '../../../../providers/app-provider';
import { Topic } from '../../../../services/interfaces';
import { UtilsService } from '../../../../services/utils.service';
import { TopicDetails } from '../../../topic/details';
import { Overview } from '../../../topic/overview';
import { ToggleHighLight } from '../../../../components/toggle-highlight';
import { DetailedInformation } from '../../../source-distribution/details/detailed-information';
import Breadcrumb, {
    INavigationStack,
} from '../../../../components/core/breadcrumd';

interface IProps {
    topicSubscriptionId: string;
    query?: string;
    disablePx?: boolean;
}

const TopicSentiments = (props: IProps) => {
    const { topicSubscriptionId } = props;
    const [searchParam] = useSearchParams();
    const [tab, setTab] = React.useState('sentiment');
    const [stack, setStack] = useState<INavigationStack[]>([]);
    const [source, setSource] = React.useState('');
    const appContext = useContext(AppContext);
    const [topic, setTopic] = useState<null | Topic>(null);
    const [topicSubscriptionIds, setTopicSubscriptionIds] = useState<string[]>(
        []
    );
    useEffect(() => {
        if (topicSubscriptionId && appContext?.topics.length) {
            let query = searchParam.get('query');
            setTopicSubscriptionIds([topicSubscriptionId]);
            let topic = appContext.topics.filter(
                (s: Topic) => s.topicSubscriptionId == topicSubscriptionId
            )[0];
            setTopic(topic);
            setStack([
                {
                    title: topic.subscriptionName,
                    link: `?query=${query}&id=${topicSubscriptionId}&&insights=true`,
                    enabled: true,
                },
                {
                    title: source,
                    link: `/topic/${topicSubscriptionId}`,
                    enabled: false,
                },
            ]);
        }
    }, [topicSubscriptionId, appContext?.topics, source, searchParam]);

    useEffect(() => {
        let _tab = searchParam.get('subtab');
        if (_tab) {
            setTab(_tab);
        } else {
            setTab('overview');
        }
        let _source = searchParam.get('source');
        if (_source) {
            setSource(_source);
        }
    }, [searchParam]);

    return (
        <Box>
            {tab == 'overview' && (
                <Box>
                    {/* <Box sx={{ py: 4, px: props.disablePx ? 0 : 8 }}>
                        <Grid justifyContent="space-between" container>
                            <Grid textAlign="left" item>
                                <Typography variant="h1">
                                    Overall Assessment
                                </Typography>
                                {appContext && (
                                    <Typography variant="subtitle1">
                                        {UtilsService.formatStringDate(
                                            appContext.filters.date.startDate
                                        )}{' '}
                                        -{' '}
                                        {UtilsService.formatStringDate(
                                            appContext.filters.date.endDate
                                        )}{' '}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                sx={{ pt: 4 }}
                                spacing={2}
                                display="flex"
                                item
                            >
                                <Box mr={2} mt={-3}>
                                    <DateInput />
                                </Box>
                                <Box mr={2} mt={-3}>
                                    <SourceSelection />
                                </Box>
                                <Box>
                                    {topicSubscriptionId && (
                                        <TopicSourceDistribution
                                            topicSubscriptionId={
                                                topicSubscriptionId
                                            }
                                            query={props.query}
                                            largeButton={true}
                                        />
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box> */}
                    <Overview
                        hideExecutiveSummary={true}
                        disablePx={props.disablePx}
                        topicSubscriptionId={topicSubscriptionId}
                    />
                    <Box sx={{ py: 4, px: props.disablePx ? 0 : 8 }}>
                        <TopicDetails
                            inbuiltDetail={true}
                            topicSubscriptionId={topicSubscriptionId}
                        />
                    </Box>
                </Box>
            )}
            {tab == 'distribution' && (
                <Box>
                    <Box sx={{ py: 4, px: props.disablePx ? 0 : 8 }}>
                        <Breadcrumb stack={stack} />
                        <Grid justifyContent="space-between" container>
                            <Grid textAlign="left" item>
                                <Typography variant="h1">{source}</Typography>
                                {appContext && (
                                    <Typography variant="subtitle1">
                                        {UtilsService.formatStringDate(
                                            appContext.filters.date.startDate
                                        )}{' '}
                                        -{' '}
                                        {UtilsService.formatStringDate(
                                            appContext.filters.date.endDate
                                        )}{' '}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                sx={{ pt: 4 }}
                                spacing={2}
                                display="flex"
                                item
                            >
                                <ToggleHighLight />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ px: props.disablePx ? 0 : 8 }}>
                        {source && (
                            <DetailedInformation
                                source={source}
                                topicSubscriptionId={topicSubscriptionId}
                                setSource={setSource}
                            />
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default TopicSentiments;
