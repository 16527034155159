import React from 'react';

interface IProps {
    style?: React.CSSProperties;
}

export const InputDownArrow = (props: IProps) => {
    return (
        <svg
            width="12"
            height="6"
            viewBox="0 0 12 6"
            fill="none"
            style={props.style}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 1L9.25269 2.76297C7.85925 4.1689 7.16253 4.87187 6.31333 4.98011C6.10527 5.00663 5.89473 5.00663 5.68667 4.98011C4.83748 4.87187 4.14075 4.1689 2.74731 2.76297L1 1"
                stroke="#4B4B4C"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};
