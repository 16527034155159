export const SentimentsIcon = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_152_111229"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
        >
            <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_152_111229)">
            <path
                d="M2.50065 16.6667C2.04232 16.6667 1.64996 16.5035 1.32357 16.1771C0.997179 15.8507 0.833984 15.4583 0.833984 15C0.833984 14.5417 0.997179 14.1493 1.32357 13.8229C1.64996 13.4965 2.04232 13.3333 2.50065 13.3333H2.7194C2.7819 13.3333 2.84787 13.3472 2.91732 13.375L6.70899 9.58333C6.68121 9.51389 6.66732 9.44792 6.66732 9.38542V9.16667C6.66732 8.70833 6.83051 8.31597 7.1569 7.98958C7.48329 7.66319 7.87565 7.5 8.33399 7.5C8.79232 7.5 9.18468 7.66319 9.51107 7.98958C9.83746 8.31597 10.0007 8.70833 10.0007 9.16667C10.0007 9.19444 9.98676 9.33333 9.95899 9.58333L12.084 11.7083C12.1534 11.6806 12.2194 11.6667 12.2819 11.6667H12.7194C12.7819 11.6667 12.8479 11.6806 12.9173 11.7083L15.8757 8.75C15.8479 8.68056 15.834 8.61458 15.834 8.55208V8.33333C15.834 7.875 15.9972 7.48264 16.3236 7.15625C16.65 6.82986 17.0423 6.66667 17.5007 6.66667C17.959 6.66667 18.3513 6.82986 18.6777 7.15625C19.0041 7.48264 19.1673 7.875 19.1673 8.33333C19.1673 8.79167 19.0041 9.18403 18.6777 9.51042C18.3513 9.83681 17.959 10 17.5007 10H17.2819C17.2194 10 17.1534 9.98611 17.084 9.95833L14.1257 12.9167C14.1534 12.9861 14.1673 13.0521 14.1673 13.1146V13.3333C14.1673 13.7917 14.0041 14.184 13.6777 14.5104C13.3513 14.8368 12.959 15 12.5007 15C12.0423 15 11.65 14.8368 11.3236 14.5104C10.9972 14.184 10.834 13.7917 10.834 13.3333V13.1146C10.834 13.0521 10.8479 12.9861 10.8757 12.9167L8.75065 10.7917C8.68121 10.8194 8.61524 10.8333 8.55274 10.8333H8.33399C8.30621 10.8333 8.16732 10.8194 7.91732 10.7917L4.12565 14.5833C4.15343 14.6528 4.16732 14.7188 4.16732 14.7813V15C4.16732 15.4583 4.00412 15.8507 3.67773 16.1771C3.35135 16.5035 2.95898 16.6667 2.50065 16.6667ZM3.33398 8.3125L2.81315 7.1875L1.68815 6.66667L2.81315 6.14583L3.33398 5.02083L3.85482 6.14583L4.97982 6.66667L3.85482 7.1875L3.33398 8.3125ZM12.5007 7.5L11.709 5.79167L10.0007 5L11.709 4.20833L12.5007 2.5L13.2923 4.20833L15.0007 5L13.2923 5.79167L12.5007 7.5Z"
                fill="#000033"
            />
        </g>
    </svg>
);
