import { FeatureName, Permission, SubFeatureName } from './interfaces';

export const AuthPermission: Permission = {
    featureName: FeatureName.Auth,
    subFeatures: [SubFeatureName.Login, SubFeatureName.Signup],
};

class PermissionsService {
    private permissions: Permission[];

    constructor(permissions: Permission[]) {
        this.permissions = permissions;
    }

    // Helper method to check if AllAccess is granted
    private hasAllAccess(): boolean {
        return this.permissions.some(
            (permission) => permission.featureName === FeatureName.AllAccess
        );
    }

    isFeatureAuthorized(featureName: FeatureName): boolean {
        // Check for AllAccess first
        if (this.hasAllAccess()) {
            return true;
        }

        // Check specific feature authorization
        return this.permissions.some(
            (permission) => permission.featureName === featureName
        );
    }

    isSubFeatureAuthorized(
        featureName: FeatureName,
        subFeatureName: SubFeatureName
    ): boolean {
        // Check for AllAccess first
        if (this.hasAllAccess()) {
            return true;
        }
        // Check specific sub-feature authorization
        const feature = this.permissions.find(
            (permission) => permission.featureName === featureName
        );

        return feature ? feature.subFeatures.includes(subFeatureName) : false;
    }
}

export default PermissionsService;
