import styled from '@emotion/styled';
import LinearProgress, {
    linearProgressClasses,
} from '@mui/material/LinearProgress';
import React from 'react';

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#F1F1F5',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 10,
    },
}));

export const PositiveLinearProgress = styled(BorderLinearProgress)(
    ({ theme }) => ({
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: '#38A237',
        },
    })
);

export const NegativeLinearProgress = styled(BorderLinearProgress)(
    ({ theme }) => ({
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: '#9A2A24',
        },
    })
);
