import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';

export const FilledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#EBECF6',
    color: '#29319B',
    padding: '4px 10px',
    borderRadius: 8,
    '&:hover': {
        backgroundColor: '#D7D9EC',
        color: '#29319B',
    },
}));

export const BorderedLightButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#EBECF6',
    color: '#29319B',
    padding: '4px 10px',
    border: '1px solid',
    borderColor: '#9C9FD0',
    borderRadius: 4,
    '&:hover': {
        backgroundColor: '#D7D9EC',
        color: '#29319B',
    },
}));

export const BorderedButton = styled(Button)(({ theme }) => ({
    color: '#29319B',
    padding: '4px 10px',
    border: '1px solid',
    borderColor: '#E3E3E3',
    borderRadius: 4,
    '&:hover': {
        backgroundColor: '#D7D9EC',
        color: '#29319B',
    },
}));
