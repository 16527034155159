import { Avator } from './avator';

interface IProps {
    percent: any;
    image?: string;
    rating?: any;
}

export const CircleProgressBar = (props: IProps) => (
    <svg width="110" height="110" viewBox="0 0 115 115">
        <circle
            className="circle-background"
            fill={props.image ? 'none' : 'url(#grad1)'}
            cx="55"
            cy="55"
            r="50"
            stroke="#f9f1e3"
            strokeWidth="4px"
        />
        <circle
            className="circle-progress"
            fill="none"
            cx="55"
            cy="55"
            r="50"
            stroke="#e9a939"
            strokeWidth={props.image && props.image !== 'NA' ? '8px' : '4px'}
            transform={`rotate(-90 55 55)`}
            style={{
                strokeDasharray: '332.84',
                strokeDashoffset:
                    332.84 -
                    (332.84 * (props.percent !== 'NA' ? props.percent : 0)) /
                        100,
            }}
        />

        <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop
                    offset="-2.75%"
                    stopColor="rgba(233, 169, 57, 0.10)"
                    stopOpacity="1"
                />
                <stop
                    offset="-2.75%"
                    stopColor="rgba(233, 169, 57, 0.10)"
                    stopOpacity="1"
                />
                <stop
                    offset="101.26%"
                    stopColor="rgba(227, 148, 11, 0.00)"
                    stopOpacity="1"
                />
            </linearGradient>
        </defs>
        {props.image && props.image !== 'NA' && (
            <image
                xlinkHref={props.image}
                x="5"
                y="5"
                width="100"
                height="100"
                style={{ borderRadius: '50%' }}
                clipPath="url(#myCircle)"
            />
        )}
        {props.image && props.image == 'NA' && <Avator size={110} />}
        <defs>
            <clipPath id="myCircle">
                <circle cx="55" cy="55" r="50" />
            </clipPath>
        </defs>

        {!props.image && (
            <text
                className="circle-text"
                x="47%"
                y="50%"
                dy=".3em"
                textAnchor="middle"
                style={{ fontSize: '20px', fontWeight: '600', fill: '#ECB73B' }}
            >
                {props.rating
                    ? props.rating
                    : props.percent !== 'NA'
                    ? `${props.percent.toFixed(0)}%`
                    : 'N/A'}
            </text>
        )}
    </svg>
);
