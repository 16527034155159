import { getCommonEventProperties, rudderanalytics, trackEvent } from '.';
import { AnalyticsEvent } from './analytics-events.enum';

const pageLoad = async (url: string) => {
    let props = await getCommonEventProperties();
    rudderanalytics.page(url, 'app', props);
};

const leftMenuExpanded = () => {
    trackEvent(AnalyticsEvent.LeftMenuExpanded, {});
};
const leftMenuCollapsed = () => {
    trackEvent(AnalyticsEvent.LeftMenuCollapsed, {});
};
const leftMenuItemClick = (menuItemTitle: string, menuItemPath: string) => {
    trackEvent(AnalyticsEvent.LeftMenuItemClick, {
        menuItemTitle,
        menuItemPath,
    });
};
const searchResultItemClick = (
    searchType: string,
    query: string,
    resultId: string,
    resultValue: string,
    navigationPath: string
) => {
    trackEvent(AnalyticsEvent.SearchResultItemClick, {
        searchType,
        query,
        resultId,
        resultValue,
        navigationPath,
    });
};
const searchResultRequestTopicClick = (topic: string) => {
    trackEvent(AnalyticsEvent.SearchResultRequestTopicClick, { topic });
};
const dateRangeChanges = (startDate: string, endDate: string) => {
    trackEvent(AnalyticsEvent.DateRangeChanges, { startDate, endDate });
};
const sourceChange = (
    sourceId: string,
    sourceName: string,
    eventSource: string
) => {
    trackEvent(AnalyticsEvent.SourceChange, {
        sourceId,
        sourceName,
        eventSource,
    });
};
const toggleHighlight = (status: boolean, eventSource: string) => {
    trackEvent(AnalyticsEvent.ToggleHighlight, { status, eventSource });
};
const dashboardPageLoad = () => {
    trackEvent(AnalyticsEvent.DashboardPageLoad, {});
};
const dashboardClusterClick = (clusterId: string, clusterName: string) => {
    trackEvent(AnalyticsEvent.DashboardClusterClick, {
        clusterId,
        clusterName,
    });
};
const createGroupClick = () => {
    trackEvent(AnalyticsEvent.CreateGroupClick, {});
};
const editGroupClick = () => {
    trackEvent(AnalyticsEvent.EditGroupClick, {});
};
const predefinedGroupSelected = (clusterName: string) => {
    trackEvent(AnalyticsEvent.PredefinedGroupSelected, { clusterName });
};
const createGroupNameCtaClick = (clusterName: string) => {
    trackEvent(AnalyticsEvent.CreateGroupNameCtaClick, { clusterName });
};
const editSpecificGroupClick = (clusterName: string, clusterId?: string) => {
    trackEvent(AnalyticsEvent.EditSpecificGroupClick, {});
};
const deleteGroupClick = (clusterName: string, clusterId: string) => {
    trackEvent(AnalyticsEvent.DeleteGroupClick, { clusterName, clusterId });
};
const editGroupName = (clusterName: string, clusterId?: string) => {
    trackEvent(AnalyticsEvent.EditGroupName, { clusterName, clusterId });
};
const addMoreTopicsClick = (clusterName: string, clusterId?: string) => {
    trackEvent(AnalyticsEvent.AddMoreTopicsClick, { clusterName, clusterId });
};
const clusterRemoveTopicClick = (
    clusterName: string,
    topicSubscriptionId: string,
    topicName: string,
    clusterId?: string
) => {
    trackEvent(AnalyticsEvent.ClusterRemoveTopicClick, {
        clusterId,
        clusterName,
        topicSubscriptionId,
        topicName,
    });
};
const clusterAddTopicClick = (
    clusterName: string,
    topicSubscriptionId: string,
    topicName: string,
    clusterId?: string
) => {
    trackEvent(AnalyticsEvent.ClusterAddTopicClick, {
        clusterId,
        clusterName,
        topicSubscriptionId,
        topicName,
    });
};
const clusterRequestTopicClick = (topicName: string) => {
    trackEvent(AnalyticsEvent.ClusterRequestTopicClick, { topicName });
};
const createGroup = (clusterName: string, clusterId?: string) => {
    trackEvent(AnalyticsEvent.CreateGroup, { clusterName, clusterId });
};
const updateGroup = (clusterName: string, clusterId?: string) => {
    trackEvent(AnalyticsEvent.UpdateGropup, { clusterName, clusterId });
};
const editGroupBackClick = (clusterName?: string, clusterId?: string) => {
    trackEvent(AnalyticsEvent.EditGroupBackClick, { clusterName, clusterId });
};
const editGroupCloseClick =
    (clusterName?: string, clusterId?: string) => () => {
        trackEvent(AnalyticsEvent.EditGroupCloseClick, {
            clusterName,
            clusterId,
        });
    };
const createGroupBackClick = () => {
    trackEvent(AnalyticsEvent.CreateGroupBackClick, {});
};
const createGroupCloseClick = () => {
    trackEvent(AnalyticsEvent.CreateGroupCloseClick, {});
};
const tabSwitchDashboard = (tab: number) => {
    trackEvent(AnalyticsEvent.TabSwitchDashboard, { tab });
};
const topicClick = (
    topicSubscriptionId: string,
    topicName: string,
    eventSource: string
) => {
    trackEvent(AnalyticsEvent.TopicClick, {
        topicSubscriptionId,
        topicName,
        eventSource,
    });
};
const sentimentChartBarClick = (
    date: string,
    topicSubscriptionId: string,
    topicName: string,
    eventSource: string
) => {
    trackEvent(AnalyticsEvent.SentimentChartBarClick, {
        date,
        topicSubscriptionId,
        topicName,
        eventSource,
    });
};
const potentialIndicatorPageClick = (pageNo: number) => {
    trackEvent(AnalyticsEvent.PotentialIndicatorPageClick, { pageNo });
};
const potentialIndicatorTitleClick = (
    summaryId: string,
    id: string,
    topicSubscriptionId: string,
    topicName: string,
    navigationPath: string
) => {
    trackEvent(AnalyticsEvent.PotentialIndicatorTitleClick, {
        summaryId,
        id,
        topicName,
        topicSubscriptionId,
        navigationPath,
    });
};
const latestInsightPageClick = (page: string) => {
    trackEvent(AnalyticsEvent.LatestInsightPageClick, { page });
};
const latestInsightTitleClick = (
    summaryId: string,
    id: string,
    topicSubscriptionId: string,
    topicName: string,
    navigationPath: string
) => {
    trackEvent(AnalyticsEvent.LatestInsightTitleClick, {
        summaryId,
        id,
        topicName,
        topicSubscriptionId,
        navigationPath,
    });
};
const latestInsightArrowClick = (
    summaryId: string,
    id: string,
    topicSubscriptionId: string,
    topicName: string,
    navigationPath: string
) => {
    trackEvent(AnalyticsEvent.LatestInsightArrowClick, {
        summaryId,
        id,
        topicName,
        topicSubscriptionId,
        navigationPath,
    });
};
const sourceDistributionClick = (
    topicSubscriptionId: string,
    topicName: string
) => {
    trackEvent(AnalyticsEvent.SourceDistributionClick, {
        topicSubscriptionId,
        topicName,
    });
};
const sourceDistributionItemTitleClick = (
    topicSubscriptionId: string,
    topicName: string,
    source: string
) => {
    trackEvent(AnalyticsEvent.SourceDistributionItemTitleClick, {
        topicSubscriptionId,
        topicName,
        source,
    });
};
const sourceDistributionItemArrowClick = (
    topicSubscriptionId: string,
    topicName: string,
    source: string
) => {
    trackEvent(AnalyticsEvent.SourceDistributionItemArrowClick, {
        topicSubscriptionId,
        topicName,
        source,
    });
};
const showStockPricesToggle = (
    topicSubscriptionId: string,
    topicName: string,
    showStockPrice: boolean
) => {
    trackEvent(AnalyticsEvent.ShowStockPricesToggle, {
        topicSubscriptionId,
        topicName,
        showStockPrice,
    });
};
const viewModeToggle = (
    topicSubscriptionId: string,
    topicName: string,
    viewMode: string
) => {
    trackEvent(AnalyticsEvent.ViewModeToggle, {
        topicSubscriptionId,
        topicName,
        viewMode,
    });
};
const dateInsightArrowClick = (
    summaryId: string,
    id: string,
    topicSubscriptionId: string,
    topicName: string,
    navigationPath: string
) => {
    trackEvent(AnalyticsEvent.DateInsightArrowClick, {
        summaryId,
        id,
        topicSubscriptionId,
        topicName,
        navigationPath,
    });
};
const topicPageLoad = (topicSubscriptionId: string, topicName: string) => {
    trackEvent(AnalyticsEvent.TopicPageLoad, {
        topicSubscriptionId,
        topicName,
    });
};
const tabSwitchTopic = (
    topicSubscriptionId: string,
    topicName: string,
    tab: string
) => {
    trackEvent(AnalyticsEvent.TabSwitchTopic, {
        topicSubscriptionId,
        topicName,
        tab,
    });
};
const tabSwitchInsight = (
    id: string,
    topicSubscriptionId: string,
    topicName: string,
    tab: string,
    summaryId?: string
) => {
    trackEvent(AnalyticsEvent.TabSwitchInsight, {
        id,
        summaryId,
        topicSubscriptionId,
        topicName,
        tab,
    });
};
const insightPageLoad = (
    id: string,
    topicSubscriptionId: string,
    topicName: string,
    sourceName: string,
    summaryId?: string
) => {
    trackEvent(AnalyticsEvent.InsightPageLoad, {
        id,
        summaryId,
        topicSubscriptionId,
        topicName,
        sourceName,
    });
};
const goToArticlePageClick = (
    topicSubscriptionId: string,
    topicName: string,
    id: string,
    articleUrl: string,
    summaryId?: string
) => {
    trackEvent(AnalyticsEvent.ArticleListPageClick, {
        topicSubscriptionId,
        topicName,
        articleUrl,
        id,
        summaryId,
    });
};
const articleListPageClick = (
    topicSubscriptionId: string,
    topicName: string,
    sourceName: string,
    pageNumber: string,
    id: string,
    summaryId?: string
) => {
    trackEvent(AnalyticsEvent.ArticleListPageClick, {
        topicSubscriptionId,
        topicName,
        sourceName,
        pageNumber,
        id,
        summaryId,
    });
};

const tabSwitchSourceDistribution = (
    topicSubscriptionId: string,
    source: string,
    tab: number
) => {
    trackEvent(AnalyticsEvent.TabSwitchInsight, {
        topicSubscriptionId,
        source,
        tab,
    });
};
const sourceDistributionPageLoad = (
    topicSubscriptionId: string,
    source: string
) => {
    trackEvent(AnalyticsEvent.InsightPageLoad, {
        topicSubscriptionId,
        source,
    });
};
const sourceDistributionGoToArticlePageClick = (
    topicSubscriptionId: string,
    source: string,
    articleUrl: string
) => {
    trackEvent(AnalyticsEvent.ArticleListPageClick, {
        topicSubscriptionId,
        source,
        articleUrl,
    });
};
const sourceDistributionArticleListPageClick = (
    topicSubscriptionId: string,
    source: string,
    pageNumber: string
) => {
    trackEvent(AnalyticsEvent.ArticleListPageClick, {
        topicSubscriptionId,
        source,
        pageNumber,
    });
};

const cirReportPageLoad = (
    date?: string,
    sourceId?: string,
    clusterId?: string,
    viewMode?: string
) => {
    trackEvent(AnalyticsEvent.CirReportPageLoad, {
        date,
        sourceId,
        clusterId,
        viewMode,
    });
};

const cirAlertPageLoad = () => {
    trackEvent(AnalyticsEvent.CirAlertPageLoad, {});
};
const cirReportDateChange = (date: string) => {
    trackEvent(AnalyticsEvent.CirReportDateChange, { date });
};
const cirReportGroupChange = (clusterId: string, clusterName: string) => {
    trackEvent(AnalyticsEvent.CirReportGroupChange, { clusterId, clusterName });
};
const cirReportSourceChange = (sourceId: string, sourceName: string) => {
    trackEvent(AnalyticsEvent.CirReportSourceChange, { sourceId, sourceName });
};
const cirReportViewModeChange = (mode: string) => {
    trackEvent(AnalyticsEvent.CirReportViewModeChange, { mode });
};
const cirReportGenerateCirClick = () => {
    trackEvent(AnalyticsEvent.CirReportGenerateCirClick, {});
};
const cirReportChartBarClick = (topic: string) => {
    trackEvent(AnalyticsEvent.CirReportChartBarClick, { topic });
};
const cirReportGenerateTopicTitleClick = (
    topicSubscriptionId: string,
    topicName: string
) => {
    trackEvent(AnalyticsEvent.CirReportGenerateTopicTitleClick, {
        topicSubscriptionId,
        topicName,
    });
};
const cirAlertToggle = (
    clusterId: string,
    clusterName: string,
    alertTitle: string,
    alertStatus: boolean
) => {
    trackEvent(AnalyticsEvent.CirAlertToggle, {
        clusterId,
        clusterName,
        alertTitle,
        alertStatus,
    });
};
const documentInsightPageLoad = () => {
    trackEvent(AnalyticsEvent.DocumentInsightPageLoad, {});
};
const tabSwitchDocumentInsight = (tab: string) => {
    trackEvent(AnalyticsEvent.TabSwitchDocumentInsight, { tab });
};
const documentInsightUploadClick = () => {
    trackEvent(AnalyticsEvent.DocumentInsightUploadClick, {});
};
const documentInsightReloadClick = () => {
    trackEvent(AnalyticsEvent.DocumentInsightReloadClick, {});
};
const documentInsightDropzoneClick = () => {
    trackEvent(AnalyticsEvent.DocumentInsightDropzoneClick, {});
};
const documentInsightFileUploadSuccess = (
    fileId: string,
    fileName: string,
    fileSize: number
) => {
    trackEvent(AnalyticsEvent.DocumentInsightFileUploadSuccess, {
        fileId,
        fileName,
        fileSize,
    });
};
const documentInsightFileClick = (fileId: string, fileName: string) => {
    trackEvent(AnalyticsEvent.DocumentInsightFileClick, { fileId, fileName });
};
const documentInsightFileDeleteClick = (fileId: string, fileName: string) => {
    trackEvent(AnalyticsEvent.DocumentInsightFileDeleteClick, {
        fileId,
        fileName,
    });
};
const documentInsightFileDeleteConfirm = (
    fileId: string,
    fileName?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightFileDeleteConfirm, {
        fileId,
        fileName,
    });
};
const documentInsightFileDeleteCancel = (fileId: string, fileName?: string) => {
    trackEvent(AnalyticsEvent.DocumentInsightFileDeleteCancel, {
        fileId,
        fileName,
    });
};
const documentInsightFileEditAspectsClick = (
    fileId: string,
    fileName: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightFileEditAspectsClick, {
        fileId,
        fileName,
    });
};
const documentInsightFileAddAspectsClick = (
    fileId: string,
    fileName: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightFileAddAspectsClick, {
        fileId,
        fileName,
    });
};
const documentInsightFileAspectsConfirm = (
    fileId: string,
    fileName?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightFileAspectsConfirm, {
        fileId,
        fileName,
    });
};
const documentInsightFileAspectsCancel = (
    fileId: string,
    fileName?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightFileAspectsCancel, {
        fileId,
        fileName,
    });
};
const documentInsightFolderExpand = (groupId: string, groupName?: string) => {
    trackEvent(AnalyticsEvent.DocumentInsightFolderExpand, {
        groupId,
        groupName,
    });
};
const documentInsightFolderCollapse = (groupId: string, groupName?: string) => {
    trackEvent(AnalyticsEvent.DocumentInsightFolderCollapse, {
        groupId,
        groupName,
    });
};
const documentInsightFolderInsightClick = (
    groupId: string,
    groupName: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightFolderInsightClick, {
        groupId,
        groupName,
    });
};
const documentInsightFolderMenuIconClick = (
    groupId: string,
    groupName: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightFolderMenuIconClick, {
        groupId,
        groupName,
    });
};
const documentInsightFolderAddFileClick = (
    groupId: string,
    groupName: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightFolderAddFileClick, {
        groupId,
        groupName,
    });
};
const documentInsightFolderEditFolderClick = (
    groupId: string,
    groupName: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightFolderEditFolderClick, {
        groupId,
        groupName,
    });
};
const documentInsightFolderDeleteFolderClick = (
    groupId: string,
    groupName: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightFolderDeleteFolderClick, {
        groupId,
        groupName,
    });
};
const documentInsightFolderEditAspectsClick = (
    groupId: string,
    groupName: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightFolderEditAspectsClick, {
        groupId,
        groupName,
    });
};
const documentInsightFolderAddAspectsClick = (
    groupId: string,
    groupName: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightFolderAddAspectsClick, {
        groupId,
        groupName,
    });
};
const documentInsightFolderAspectsConfirm = (
    groupId: string,
    groupName?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightFolderAspectsConfirm, {
        groupId,
        groupName,
    });
};
const documentInsightFolderAspectsCancel = (
    groupId: string,
    groupName?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightFolderAspectsCancel, {
        groupId,
        groupName,
    });
};
const documentInsightEditFolderFileAddClick = (
    fileId: string,
    fileName: string,
    groupId?: string,
    groupName?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightEditFolderFileAddClick, {
        groupId,
        groupName,
        fileId,
        fileName,
    });
};
const documentInsightEditFolderFileRemoveClick = (
    fileId: string,
    fileName: string,
    groupId?: string,
    groupName?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightEditFolderFileRemoveClick, {
        groupId,
        groupName,
        fileId,
        fileName,
    });
};
const documentInsightEditFolderUpdateClick = (
    groupId?: string,
    groupName?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightEditFolderUpdateClick, {
        groupId,
        groupName,
    });
};
const documentInsightCreateFolderClick = () => {
    trackEvent(AnalyticsEvent.DocumentInsightCreateFolderClick, {});
};
const documentInsightViewLoad = (
    documentInsightType?: string,
    documentInsightId?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightViewLoad, {
        documentInsightType,
        documentInsightId,
    });
};
const documentInsightViewSummaryModeChange = (
    mode: string,
    documentInsightType?: string,
    documentInsightId?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightViewSummaryModeChange, {
        mode,
        documentInsightType,
        documentInsightId,
    });
};
const documentInsightViewMoreIconClick = (
    documentInsightType?: string,
    documentInsightId?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightViewMoreIconClick, {
        documentInsightType,
        documentInsightId,
    });
};
const documentInsightViewAddAspectClick = (
    documentInsightType?: string,
    documentInsightId?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightViewAddAspectClick, {
        documentInsightType,
        documentInsightId,
    });
};
const documentInsightViewRemoveAspectClick = (
    documentInsightType?: string,
    documentInsightId?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightViewRemoveAspectClick, {
        documentInsightType,
        documentInsightId,
    });
};
const documentInsightViewAddFileClick = (
    groupId?: string,
    groupName?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightViewAddFileClick, {
        groupId,
        groupName,
    });
};
const documentInsightViewEditFolderClick = (
    groupId?: string,
    groupName?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightViewEditFolderClick, {
        groupId,
        groupName,
    });
};
const documentInsightViewDeleteClick = (
    documentInsightType?: string,
    documentInsightId?: string
) => {
    trackEvent(AnalyticsEvent.DocumentInsightViewDeleteClick, {
        documentInsightType,
        documentInsightId,
    });
};

const chatboxOpen = (variant: string) => {
    trackEvent(AnalyticsEvent.ChatBoxOpen, { variant });
};

const chatBoxClose = (variant: string) => {
    trackEvent(AnalyticsEvent.ChatBoxClose, { variant });
};

const chatBoxQuerySendClick = (variant: string) => {
    trackEvent(AnalyticsEvent.ChatBoxQuerySendClick, { variant });
};
const chatBoxQueryResponseReceived = (variant: string) => {
    trackEvent(AnalyticsEvent.ChatBoxQueryResponseReceived, { variant });
};
const chatBoxQueryAIResponseReceived = (variant: string) => {
    trackEvent(AnalyticsEvent.ChatBoxQueryAIResponseReceived, { variant });
};
const chatBoxQueryResponseSourceClick = (variant: string, link?: string) => {
    trackEvent(AnalyticsEvent.ChatBoxQueryResponseSourceClick, {
        variant,
        link,
    });
};

const signupOTPRequested = (email: string, refCode: string) => {
    trackEvent(AnalyticsEvent.SignUpOTPRequested, {
        email,
        refCode,
    });
};

const googleSignUp = (email: string, refCode: string) => {
    trackEvent(AnalyticsEvent.GoogleSignUp, {
        email,
        refCode,
    });
};

const signUpOTPVerified = (email: string, refCode: string) => {
    trackEvent(AnalyticsEvent.SignUpOTPVerified, {
        email,
        refCode,
    });
};

const signUpComplete = (email: string, refCode: string) => {
    trackEvent(AnalyticsEvent.SignUpComplete, {
        email,
        refCode,
    });
};

const purchaseSucessful = (refCode: string) => {
    trackEvent(AnalyticsEvent.PurchaseSucessful, {
        refCode,
    });
};

export {
    pageLoad,
    leftMenuExpanded,
    leftMenuCollapsed,
    leftMenuItemClick,
    searchResultItemClick,
    searchResultRequestTopicClick,
    dateRangeChanges,
    sourceChange,
    toggleHighlight,
    dashboardPageLoad,
    dashboardClusterClick,
    createGroupClick,
    editGroupClick,
    predefinedGroupSelected,
    createGroupNameCtaClick,
    editSpecificGroupClick,
    deleteGroupClick,
    editGroupName,
    addMoreTopicsClick,
    clusterRemoveTopicClick,
    clusterRequestTopicClick,
    clusterAddTopicClick,
    createGroup,
    updateGroup,
    editGroupBackClick,
    editGroupCloseClick,
    createGroupBackClick,
    createGroupCloseClick,
    tabSwitchDashboard,
    topicClick,
    sentimentChartBarClick,
    potentialIndicatorPageClick,
    potentialIndicatorTitleClick,
    latestInsightPageClick,
    latestInsightTitleClick,
    latestInsightArrowClick,
    sourceDistributionClick,
    sourceDistributionItemTitleClick,
    sourceDistributionItemArrowClick,
    showStockPricesToggle,
    viewModeToggle,
    dateInsightArrowClick,
    topicPageLoad,
    tabSwitchTopic,
    goToArticlePageClick,
    tabSwitchSourceDistribution,
    sourceDistributionPageLoad,
    sourceDistributionArticleListPageClick,
    sourceDistributionGoToArticlePageClick,
    tabSwitchInsight,
    insightPageLoad,
    articleListPageClick,
    cirReportPageLoad,
    cirReportDateChange,
    cirReportGroupChange,
    cirReportSourceChange,
    cirReportViewModeChange,
    cirReportGenerateCirClick,
    cirReportGenerateTopicTitleClick,
    cirAlertPageLoad,
    cirAlertToggle,
    cirReportChartBarClick,
    tabSwitchDocumentInsight,
    documentInsightPageLoad,
    documentInsightUploadClick,
    documentInsightReloadClick,
    documentInsightDropzoneClick,
    documentInsightFileUploadSuccess,
    documentInsightFileClick,
    documentInsightFileDeleteClick,
    documentInsightFileDeleteConfirm,
    documentInsightFileDeleteCancel,
    documentInsightFileEditAspectsClick,
    documentInsightFileAddAspectsClick,
    documentInsightFileAspectsConfirm,
    documentInsightFileAspectsCancel,
    documentInsightFolderExpand,
    documentInsightFolderCollapse,
    documentInsightFolderInsightClick,
    documentInsightFolderMenuIconClick,
    documentInsightFolderAddFileClick,
    documentInsightFolderEditFolderClick,
    documentInsightFolderDeleteFolderClick,
    documentInsightFolderEditAspectsClick,
    documentInsightFolderAddAspectsClick,
    documentInsightFolderAspectsConfirm,
    documentInsightFolderAspectsCancel,
    documentInsightEditFolderFileAddClick,
    documentInsightEditFolderFileRemoveClick,
    documentInsightEditFolderUpdateClick,
    documentInsightViewLoad,
    documentInsightViewSummaryModeChange,
    documentInsightViewMoreIconClick,
    documentInsightViewAddAspectClick,
    documentInsightViewRemoveAspectClick,
    documentInsightViewAddFileClick,
    documentInsightViewEditFolderClick,
    documentInsightViewDeleteClick,
    documentInsightCreateFolderClick,
    chatboxOpen,
    chatBoxClose,
    chatBoxQuerySendClick,
    chatBoxQueryAIResponseReceived,
    chatBoxQueryResponseReceived,
    chatBoxQueryResponseSourceClick,
    signupOTPRequested,
    googleSignUp,
    signUpOTPVerified,
    signUpComplete,
    purchaseSucessful,
};
