interface IProps {
    size?: number;
}
export const GoogleScholarIcon = (props: IProps) => (
    <svg
        width={props.size || '20'}
        height={props.size || '20'}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_1278_55994"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
        >
            <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1278_55994)">
            <path
                d="M10.0004 16.5381L4.5838 13.5958V9.07655L1.69922 7.49966L10.0004 2.98047L18.3017 7.49966V13.4932H17.0517V8.19195L15.4171 9.07655V13.5958L10.0004 16.5381ZM10.0004 10.583L15.7008 7.49966L10.0004 4.41632L4.30014 7.49966L10.0004 10.583ZM10.0004 15.115L14.1671 12.865V9.74322L10.0004 12.0174L5.83378 9.74322V12.865L10.0004 15.115Z"
                fill="#000033"
            />
        </g>
    </svg>
);
