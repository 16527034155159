import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { pageLoad } from './analytics';

export default function NavigationAnalytics() {
    const { pathname } = useLocation();

    useEffect(() => {
        console.log(pathname);
        pageLoad(pathname);
    }, [pathname]);

    return null;
}
