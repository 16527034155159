interface IProps {
    size: number;
}
export const Bank = (props: IProps) => (
    <svg
        width={props.size}
        height={props.size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_5400_36587)">
            <path
                d="M10 0L20 4.28571V5.71429H18.6667C18.6667 5.90774 18.5955 6.07515 18.4531 6.21652C18.3108 6.35789 18.1424 6.42857 17.9479 6.42857H2.05208C1.85764 6.42857 1.68924 6.35789 1.54687 6.21652C1.40451 6.07515 1.33333 5.90774 1.33333 5.71429H0V4.28571L10 0ZM2.66667 7.14286H5.33333V15.7143H6.66667V7.14286H9.33333V15.7143H10.6667V7.14286H13.3333V15.7143H14.6667V7.14286H17.3333V15.7143H17.9479C18.1424 15.7143 18.3108 15.785 18.4531 15.9263C18.5955 16.0677 18.6667 16.2351 18.6667 16.4286V17.1429H1.33333V16.4286C1.33333 16.2351 1.40451 16.0677 1.54687 15.9263C1.68924 15.785 1.85764 15.7143 2.05208 15.7143H2.66667V7.14286ZM19.2812 17.8571C19.4757 17.8571 19.6441 17.9278 19.7865 18.0692C19.9288 18.2106 20 18.378 20 18.5714V20H0V18.5714C0 18.378 0.0711806 18.2106 0.213542 18.0692C0.355903 17.9278 0.524306 17.8571 0.71875 17.8571H19.2812Z"
                fill="#585960"
            />
        </g>
        <defs>
            <clipPath id="clip0_5400_36587">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
