import { Grid, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PlotProps } from '../../../../../components/charts/bar-chart';
import { ScatterChart } from '../../../../../components/charts/scatter-chart';
import { BorderedBox } from '../../../../../components/core/box';
import { useFinancialsService } from '../../../../../services/financials.service';
import {
    ReportModule,
    FinancialTrends,
} from '../../../../../services/interfaces';
import { PieChart } from '../../../../../components/charts/pie-chart';
import { EarningsHistory } from './earnings-history';
import { AnalystRecommendations } from './analyst-recommendations';
import { EstimatePlot } from './estimate-plot';
import { EPSPlot } from './eps-plot';
import { GrowthEstimates } from './growth-estimates';

interface IProps {
    data?: ReportModule;
    reportTitle: string;
}

export const AnalystEstimate = (props: IProps) => {
    let { topicSubscriptionId } = useParams();
    const [data, setData] = useState<PlotProps | null>(null);
    const [selectedRange, setSelectedRange] = useState('ANNUAL');
    const [financials, setFinancials] = useState<FinancialTrends | null>(null);
    const { fetchFinancialtrends } = useFinancialsService();

    useEffect(() => {
        if (props.data?.data?.yahoo?.coreFinancials.financialTrends)
            setFinancials(
                props.data?.data?.yahoo.coreFinancials.financialTrends
            );
    }, [props.data]);

    useEffect(() => {
        renderPlot();
    }, [financials, selectedRange]);

    const renderPlot = () => {
        if (!financials) return;
        const revenueSeries = financials[
            selectedRange == 'QUARTER' ? 'quarterly' : 'yearly'
        ].map((d: any) => d.revenue);
        const earningsSeries = financials[
            selectedRange == 'QUARTER' ? 'quarterly' : 'yearly'
        ].map((d: any) => d.earnings);
        const labels = financials[
            selectedRange == 'QUARTER' ? 'quarterly' : 'yearly'
        ].map((d: any) => d.date.toString());

        let sentimentData: PlotProps = {
            series: [
                {
                    name: 'Revenue',
                    data: revenueSeries,
                },
                {
                    name: 'Earnings',
                    data: earningsSeries,
                },
            ],
            labels: labels,
        };
        setData({ ...sentimentData });
    };

    return (
        <BorderedBox sx={{ backgroundColor: '#FFFFFF' }} p={3}>
            <Grid spacing={4} container>
                {props.data?.data?.yahoo.coreFinancials.earningsHistory && (
                    <Grid item xs={12} md={6}>
                        <EarningsHistory data={props.data} />
                    </Grid>
                )}
                {props.data?.data?.yahoo.coreFinancials
                    .analystRecommendations && (
                    <Grid item xs={12} md={6}>
                        <AnalystRecommendations data={props.data} />
                    </Grid>
                )}
                {props.data?.data?.yahoo.coreFinancials.earningsTrends && (
                    <Grid item xs={12} md={6}>
                        <EstimatePlot
                            estimate="earning"
                            earningsTrends={
                                props.data.data.yahoo.coreFinancials
                                    .earningsTrends
                            }
                        />
                    </Grid>
                )}
                {props.data?.data?.yahoo.coreFinancials.earningsTrends && (
                    <Grid item xs={12} md={6}>
                        <EstimatePlot
                            estimate="revenue"
                            earningsTrends={
                                props.data.data.yahoo.coreFinancials
                                    .earningsTrends
                            }
                        />
                    </Grid>
                )}
                {props.data?.data?.yahoo.coreFinancials.earningsTrends && (
                    <Grid item xs={12} md={6}>
                        <GrowthEstimates
                            earningsTrends={
                                props.data.data.yahoo.coreFinancials
                                    .earningsTrends
                            }
                            reportTitle={props.reportTitle}
                        />
                    </Grid>
                )}
                {props.data?.data?.yahoo.coreFinancials.earningsTrends && (
                    <Grid item xs={12} md={6}>
                        <EPSPlot
                            earningsTrends={
                                props.data.data.yahoo.coreFinancials
                                    .earningsTrends
                            }
                        />
                    </Grid>
                )}
            </Grid>
        </BorderedBox>
    );
};
