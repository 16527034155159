import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { StockChart } from '../../../../../components/charts/stock-chart';
import { BorderedBox, ContainedBox } from '../../../../../components/core/box';
import { PriceChartResponseData } from '../../../../../services/interfaces';
import { useFinancialsService } from '../../../../../services/financials.service';
import { UtilsService } from '../../../../../services/utils.service';
import SingleSelectTab from '../../../../../components/core/input/single-select-tab';
import { subMonths, subYears } from 'date-fns';
import { StockChartSkeleton } from '../../../../../components/images/stock-chart-skeleton';
import { FinancialInfoResponseResult } from '../../../live-insights/financial-info-interface';

interface IProps {
    companyData: FinancialInfoResponseResult;
    openSearch: () => void;
    topicSubscriptionId: string;
}

export const PriceChart = (props: IProps) => {
    const [selectedRange, setSelectedRange] = useState('1Y');

    const [labels, setLabels] = useState<string[]>([]);
    const [priceSeries, setPriceSeries] = useState<number[]>([]);
    const [positiveVolumeSeries, setPositiveVolumeSeries] = useState<number[]>(
        []
    );
    const [negativeVolumeSeries, setNegativeVolumeSeries] = useState<number[]>(
        []
    );

    useEffect(() => {
        if (!props.companyData.stock_prices) return;

        let p = [];
        let n = [];
        let startDate = UtilsService.formatDateForNetworkCall(
            subYears(new Date(), 1)
        );
        switch (selectedRange) {
            case '3M':
                startDate = UtilsService.formatDateForNetworkCall(
                    subMonths(new Date(), 3)
                );
                break;
            case '6M':
                startDate = UtilsService.formatDateForNetworkCall(
                    subMonths(new Date(), 6)
                );
                break;
            case 'YTD':
                startDate = UtilsService.formatDateForNetworkCall(
                    new Date(new Date().getFullYear(), 0, 1)
                );
                break;
            case '1Y':
                startDate = UtilsService.formatDateForNetworkCall(
                    subYears(new Date(), 1)
                );
                break;
        }
        let updatedCompanyData = props.companyData.stock_prices.filter(
            (d) =>
                d.time_level >= startDate &&
                d.time_level <=
                    UtilsService.formatDateForNetworkCall(new Date())
        );

        setLabels(updatedCompanyData.map((d) => d.time_level));
        setPriceSeries(updatedCompanyData.map((d) => d.closing_price));
        for (let e of updatedCompanyData) {
            if (e.indicator == 'POSITIVE') {
                p.push(e.traded_volume);
                n.push(0);
            } else {
                n.push(e.traded_volume);
                p.push(0);
            }
        }
        setPositiveVolumeSeries([...p]);
        setNegativeVolumeSeries([...n]);
    }, [selectedRange, props.companyData.stock_prices]);

    return (
        <BorderedBox sx={{ backgroundColor: '#FFFFFF' }}>
            <Box pt={3} px={4} justifyContent="space-between" display="flex">
                <Box>
                    <Typography variant="h6">Price chart</Typography>
                    {props.companyData && (
                        <Box display="flex">
                            <Box>
                                <Typography color="#4B4B4C" variant="caption">
                                    High
                                </Typography>
                                <Typography variant="subtitle2">
                                    $
                                    {props.companyData?.max_closing_price.toFixed(
                                        2
                                    )}
                                </Typography>
                            </Box>
                            <Box ml={5}>
                                <Typography color="#4B4B4C" variant="caption">
                                    Low
                                </Typography>
                                <Typography variant="subtitle2">
                                    $
                                    {props.companyData?.min_closing_price.toFixed(
                                        2
                                    )}
                                </Typography>
                            </Box>
                            <Box ml={5}>
                                <Typography color="#4B4B4C" variant="caption">
                                    CAGR
                                </Typography>
                                <Typography
                                    color={
                                        props.companyData?.cagr > 0
                                            ? '#19AF55'
                                            : '#D82F44'
                                    }
                                    variant="subtitle2"
                                >
                                    {Math.floor(
                                        Math.abs(props.companyData?.cagr * 100)
                                    )}
                                    %
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Box>
                <Box>
                    <SingleSelectTab
                        getOptionText={(v: string) => v}
                        placeholder="Select source"
                        pb={3}
                        options={['3M', '6M', 'YTD', '1Y']}
                        selectedValue={selectedRange}
                        onChange={setSelectedRange}
                    />
                </Box>
            </Box>
            {props.companyData ? (
                <Box
                    sx={{
                        '& .apexcharts-xaxis-label': {
                            display: 'none',
                        },
                    }}
                >
                    <StockChart
                        volumePositive={positiveVolumeSeries}
                        volumeNegative={negativeVolumeSeries}
                        labels={labels}
                        price={priceSeries}
                    />
                </Box>
            ) : (
                <Box py={20} textAlign={'center'}>
                    {StockChartSkeleton}
                    <br />
                    <Typography variant="caption" mt={2}>
                        To see the Company Financials{' '}
                    </Typography>
                    <Typography
                        variant="h6"
                        color="#29319B"
                        sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                        onClick={props.openSearch}
                    >
                        Search a Company
                    </Typography>
                </Box>
            )}
            {props.companyData && (
                <Box py={1} px={8}>
                    <Grid spacing={10} container>
                        {[
                            {
                                key: '50 Day Average',
                                value: props.companyData?.market_metrics[
                                    '50_day_average'
                                ]
                                    ? props.companyData?.market_metrics[
                                          '50_day_average'
                                      ].toFixed(2)
                                    : 'NA',
                            },
                            {
                                key: '200 Day average',
                                value: props.companyData?.market_metrics[
                                    '200_day_average'
                                ]
                                    ? props.companyData?.market_metrics[
                                          '200_day_average'
                                      ].toFixed(2)
                                    : 'NA',
                            },
                            {
                                key: '52 Week high',
                                value: props.companyData?.market_metrics[
                                    '52_week_high'
                                ]
                                    ? props.companyData?.market_metrics[
                                          '52_week_high'
                                      ].toFixed(2)
                                    : 'NA',
                            },
                            {
                                key: '52 Week low',
                                value: props.companyData?.market_metrics[
                                    '52_week_low'
                                ]
                                    ? props.companyData?.market_metrics[
                                          '52_week_low'
                                      ].toFixed(2)
                                    : 'NA',
                            },
                        ]
                            .filter(
                                (data: { key: string; value: string }) =>
                                    !!data.value
                            )
                            .map((data: { key: string; value: string }) => (
                                <Grid item sm={3}>
                                    <Typography
                                        minWidth={150}
                                        textTransform="uppercase"
                                        color="#4B4B4C"
                                        fontSize={8}
                                    >
                                        {data.key}
                                    </Typography>
                                    <Typography variant="h6">
                                        {data.value}
                                    </Typography>
                                </Grid>
                            ))}
                    </Grid>
                    <Grid spacing={10} container>
                        {[
                            {
                                key: 'Market cap',
                                value: props.companyData?.market_metrics
                                    .market_cap
                                    ? UtilsService.convertToInternationalCurrencySystem(
                                          props.companyData?.market_metrics
                                              .market_cap
                                      )
                                    : 'NA',
                            },
                            {
                                key: 'shares outstanding',
                                value: props.companyData?.market_metrics
                                    .shares_outstanding
                                    ? UtilsService.convertToInternationalCurrencySystem(
                                          props.companyData?.market_metrics
                                              .shares_outstanding
                                      )
                                    : 'NA',
                            },
                            {
                                key: 'beta',
                                value: props.companyData?.market_metrics.beta
                                    ? props.companyData?.market_metrics.beta.toFixed(
                                          2
                                      )
                                    : 'NA',
                            },
                            {
                                key: ' Book value',
                                value: props.companyData?.market_metrics
                                    .book_value
                                    ? props.companyData?.market_metrics.book_value.toFixed(
                                          2
                                      )
                                    : 'NA',
                            },
                        ]
                            .filter(
                                (data: { key: string; value: string }) =>
                                    !!data.value
                            )
                            .map((data: { key: string; value: string }) => (
                                <Grid mt={2} item sm={3}>
                                    <Typography
                                        textTransform="uppercase"
                                        color="#4B4B4C"
                                        fontSize={8}
                                        minWidth={150}
                                    >
                                        {data.key}
                                    </Typography>
                                    <Typography variant="h6">
                                        {data.value}
                                    </Typography>
                                </Grid>
                            ))}
                    </Grid>
                </Box>
            )}
        </BorderedBox>
    );
};
