export const CorporateCultureIcon = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_1773_101943"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
        >
            <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1773_101943)">
            <path
                d="M3.58454 17.0833C3.16894 17.0833 2.81398 16.9361 2.51965 16.6417C2.2253 16.3474 2.07812 15.9924 2.07812 15.5768V6.92304C2.07812 6.50745 2.2253 6.15248 2.51965 5.85815C2.81398 5.5638 3.16894 5.41662 3.58454 5.41662H7.07813V4.0064C7.07813 3.5908 7.2253 3.23583 7.51965 2.9415C7.81398 2.64717 8.16894 2.5 8.58454 2.5H11.405C11.8206 2.5 12.1756 2.64717 12.4699 2.9415C12.7642 3.23583 12.9114 3.5908 12.9114 4.0064V5.41662H16.405C16.8206 5.41662 17.1756 5.5638 17.4699 5.85815C17.7642 6.15248 17.9114 6.50745 17.9114 6.92304V15.5768C17.9114 15.9924 17.7642 16.3474 17.4699 16.6417C17.1756 16.9361 16.8206 17.0833 16.405 17.0833H3.58454ZM8.3281 5.41662H11.6614V4.0064C11.6614 3.94228 11.6347 3.88352 11.5813 3.8301C11.5279 3.77667 11.4691 3.74996 11.405 3.74996H8.58454C8.52043 3.74996 8.46166 3.77667 8.40823 3.8301C8.35481 3.88352 8.3281 3.94228 8.3281 4.0064V5.41662ZM5.41146 6.6666H3.58454C3.52043 6.6666 3.46166 6.69331 3.40823 6.74673C3.35481 6.80016 3.3281 6.85893 3.3281 6.92304V15.5768C3.3281 15.6409 3.35481 15.6997 3.40823 15.7531C3.46166 15.8066 3.52043 15.8333 3.58454 15.8333H5.41146V6.6666ZM13.3281 15.8333V6.6666H6.66144V15.8333H13.3281ZM14.5781 6.6666V15.8333H16.405C16.4691 15.8333 16.5279 15.8066 16.5813 15.7531C16.6347 15.6997 16.6614 15.6409 16.6614 15.5768V6.92304C16.6614 6.85893 16.6347 6.80016 16.5813 6.74673C16.5279 6.69331 16.4691 6.6666 16.405 6.6666H14.5781Z"
                fill="#29319B"
            />
        </g>
    </svg>

    // <svg
    //     width="20"
    //     height="20"
    //     viewBox="0 0 20 20"
    //     fill="none"
    //     xmlns="http://www.w3.org/2000/svg"
    // >
    //     <mask
    //         id="mask0_152_111229"
    //         maskUnits="userSpaceOnUse"
    //         x="0"
    //         y="0"
    //         width="20"
    //         height="20"
    //     >
    //         <rect width="20" height="20" fill="#D9D9D9" />
    //     </mask>
    //     <g mask="url(#mask0_152_111229)">
    //         <path
    //             d="M2.50065 16.6667C2.04232 16.6667 1.64996 16.5035 1.32357 16.1771C0.997179 15.8507 0.833984 15.4583 0.833984 15C0.833984 14.5417 0.997179 14.1493 1.32357 13.8229C1.64996 13.4965 2.04232 13.3333 2.50065 13.3333H2.7194C2.7819 13.3333 2.84787 13.3472 2.91732 13.375L6.70899 9.58333C6.68121 9.51389 6.66732 9.44792 6.66732 9.38542V9.16667C6.66732 8.70833 6.83051 8.31597 7.1569 7.98958C7.48329 7.66319 7.87565 7.5 8.33399 7.5C8.79232 7.5 9.18468 7.66319 9.51107 7.98958C9.83746 8.31597 10.0007 8.70833 10.0007 9.16667C10.0007 9.19444 9.98676 9.33333 9.95899 9.58333L12.084 11.7083C12.1534 11.6806 12.2194 11.6667 12.2819 11.6667H12.7194C12.7819 11.6667 12.8479 11.6806 12.9173 11.7083L15.8757 8.75C15.8479 8.68056 15.834 8.61458 15.834 8.55208V8.33333C15.834 7.875 15.9972 7.48264 16.3236 7.15625C16.65 6.82986 17.0423 6.66667 17.5007 6.66667C17.959 6.66667 18.3513 6.82986 18.6777 7.15625C19.0041 7.48264 19.1673 7.875 19.1673 8.33333C19.1673 8.79167 19.0041 9.18403 18.6777 9.51042C18.3513 9.83681 17.959 10 17.5007 10H17.2819C17.2194 10 17.1534 9.98611 17.084 9.95833L14.1257 12.9167C14.1534 12.9861 14.1673 13.0521 14.1673 13.1146V13.3333C14.1673 13.7917 14.0041 14.184 13.6777 14.5104C13.3513 14.8368 12.959 15 12.5007 15C12.0423 15 11.65 14.8368 11.3236 14.5104C10.9972 14.184 10.834 13.7917 10.834 13.3333V13.1146C10.834 13.0521 10.8479 12.9861 10.8757 12.9167L8.75065 10.7917C8.68121 10.8194 8.61524 10.8333 8.55274 10.8333H8.33399C8.30621 10.8333 8.16732 10.8194 7.91732 10.7917L4.12565 14.5833C4.15343 14.6528 4.16732 14.7188 4.16732 14.7813V15C4.16732 15.4583 4.00412 15.8507 3.67773 16.1771C3.35135 16.5035 2.95898 16.6667 2.50065 16.6667ZM3.33398 8.3125L2.81315 7.1875L1.68815 6.66667L2.81315 6.14583L3.33398 5.02083L3.85482 6.14583L4.97982 6.66667L3.85482 7.1875L3.33398 8.3125ZM12.5007 7.5L11.709 5.79167L10.0007 5L11.709 4.20833L12.5007 2.5L13.2923 4.20833L15.0007 5L13.2923 5.79167L12.5007 7.5Z"
    //             fill="#000033"
    //         />
    //     </g>
    // </svg>
);
