interface IProps {
    size: number;
}
export const SlackIcon = (props: IProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size}
        height={props.size}
        viewBox="0 0 24 24"
        fill="none"
    >
        <g clip-path="url(#clip0_4863_239466)">
            <path
                d="M5.10938 15.1346C5.10938 16.509 3.9885 17.6316 2.61394 17.6316C1.23956 17.6316 0.117188 16.509 0.117188 15.1346C0.117188 13.7603 1.23975 12.6377 2.61412 12.6377H5.10956L5.10938 15.1346ZM6.36731 15.1346C6.36731 13.7603 7.48987 12.6377 8.86425 12.6377C10.2386 12.6377 11.3612 13.7601 11.3612 15.1346V21.3849C11.3612 22.7593 10.2388 23.8819 8.86425 23.8819C7.48987 23.8819 6.36731 22.7593 6.36731 21.3849V15.1346Z"
                fill="#DE1C59"
            />
            <path
                d="M8.86425 5.11035C7.48987 5.11035 6.36731 3.98948 6.36731 2.61491C6.36731 1.24054 7.48987 0.118164 8.86425 0.118164C10.2386 0.118164 11.3612 1.24073 11.3612 2.6151V5.11054L8.86425 5.11035ZM8.86425 6.36829C10.2386 6.36829 11.3612 7.49085 11.3612 8.86523C11.3612 10.2396 10.2388 11.3622 8.86425 11.3622H2.61394C1.23956 11.3622 0.117188 10.2398 0.117188 8.86523C0.117188 7.49085 1.23975 6.36829 2.61412 6.36829H8.86425Z"
                fill="#35C5F0"
            />
            <path
                d="M18.8885 8.86523C18.8885 7.49085 20.0094 6.36829 21.384 6.36829C22.7583 6.36829 23.8809 7.49085 23.8809 8.86523C23.8809 10.2396 22.7583 11.3622 21.384 11.3622H18.8885V8.86523ZM17.6306 8.86523C17.6306 10.2396 16.508 11.3622 15.1337 11.3622C13.7593 11.3622 12.6367 10.2398 12.6367 8.86523V2.61491C12.6367 1.24054 13.7591 0.118164 15.1337 0.118164C16.508 0.118164 17.6306 1.24073 17.6306 2.6151V8.86523Z"
                fill="#2EB57D"
            />
            <path
                d="M15.1337 18.8895C16.508 18.8895 17.6306 20.0104 17.6306 21.3849C17.6306 22.7593 16.508 23.8819 15.1337 23.8819C13.7593 23.8819 12.6367 22.7593 12.6367 21.3849V18.8895H15.1337ZM15.1337 17.6316C13.7593 17.6316 12.6367 16.509 12.6367 15.1346C12.6367 13.7603 13.7591 12.6377 15.1337 12.6377H21.384C22.7583 12.6377 23.8809 13.7601 23.8809 15.1346C23.8809 16.509 22.7583 17.6316 21.384 17.6316H15.1337Z"
                fill="#EBB02E"
            />
        </g>
        <defs>
            <clipPath id="clip0_4863_239466">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
