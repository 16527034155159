import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { Watchlists } from './watchlist';
import { Subscriptions } from './subscriptions';
import { ContainedBox } from '../../components/core/box';
import { useState } from 'react';
import { HeaderContainer } from '../../components/header/container';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 0 }}>{children}</Box>}
        </div>
    );
}

export const WatchlistsAndSubscriptions = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box>
            <HeaderContainer>
                <Grid sx={{ px: 8, py: 3 }} container>
                    <Grid item xs={12}>
                        <Box sx={{ textAlign: 'left' }}>
                            <Typography variant="h2">
                                My Watchlist & Alerts
                            </Typography>
                            <Typography sx={{ mt: 1 }} variant="caption">
                                Track Your Watchlists, Manage Alerts, and View
                                Subscriptions
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </HeaderContainer>
            <ContainedBox>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        sx={{
                            '& .MuiTabs-flexContainer': {
                                flexWrap: 'wrap',
                            },
                        }}
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab
                            sx={{ fontWeight: value == 0 ? 600 : 400 }}
                            label="Watchlists"
                        />
                        <Tab
                            sx={{ fontWeight: value == 1 ? 600 : 400 }}
                            label="Subscriptions"
                        />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Watchlists />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Subscriptions />
                </TabPanel>
            </ContainedBox>
        </Box>
    );
};
