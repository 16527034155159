import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { HeaderContainer } from '../../components/header/container';
import { useAuthService } from '../../services/auth.service';

export const Header = () => {
    const { getUserName } = useAuthService();
    const [name, setName] = useState<string | undefined>('');
    const refreshName = async () => {
        setName(await getUserName());
    };
    useEffect(() => {
        refreshName();
    }, []);
    return (
        <HeaderContainer>
            <Grid sx={{ px: 8, py: 3 }} container>
                <Grid item xs={12}>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant="h2">My Account</Typography>
                        <Typography sx={{ mt: 1 }} variant="caption">
                            Manage you account here
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </HeaderContainer>
    );
};
