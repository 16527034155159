import { Box, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactWordcloud from 'react-wordcloud';

interface IProps {
    data: { [key: string]: number };
}
interface Word {
    [key: string]: any;
    text: string;
    value: number;
}

export const WordCloud = (props: IProps) => {
    useEffect(() => {
        let w: Word[] = [];
        for (let key in props.data) {
            w.push({
                text: key,
                value: props.data[key] as number,
            });
        }
        setWords([...w]);
    }, [props.data]);
    const [words, setWords] = useState<Word[]>([]);
    return (
        <Box width={'100%'}>
            <Box
                sx={{
                    height: 'calc(100vh - 305px)',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ height: '70%', width: '100%' }}>
                    <ReactWordcloud
                        options={{
                            fontFamily: 'open-sans',
                            colors: [
                                '#29319B',
                                '#017542',
                                '#D606CF',
                                '#A50010',
                                '#CEB602',
                                '#550C9E',
                            ],
                            enableTooltip: false,
                            deterministic: false,
                            fontSizes: [18, 90],
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            padding: 1,
                            rotations: 3,
                            rotationAngles: [0, 0],
                            scale: 'sqrt',
                            spiral: 'archimedean',
                            transitionDuration: 1000,
                        }}
                        words={words}
                    />
                </Box>
            </Box>
        </Box>
    );
};
