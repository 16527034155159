import {
    Badge,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ButtonBase from '@material-ui/core/ButtonBase';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    IDriveItem,
    IIntegrationAccount,
} from '../../../../../services/interfaces';
import { DRIVE } from '../drive';
import { useDriveService } from '../../../../../services/drive.service';
import {
    BootstrapDialogLarge,
    BootstrapDialogMedium,
} from '../../../../../components/core/dialog';
import { useSearchParams } from 'react-router-dom';
import { WaitTime } from '../../../../../components/icons/wait-time';
import { Input } from '../../../../../components/core/input';
import { SearchInsights } from '../../../../../components/icons/search-insights';

interface IProps {
    integrationAccount: IIntegrationAccount;
    triggerAddSource: number;
    onSourceAdd: (di: IDriveItem) => void;
    sources: IDriveItem[];
    openWaitDialog: boolean;
    setOpenWaitDialog: (flag: boolean) => void;
}

export const AddSource = (props: IProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [directoryStack, setDirectoryStack] = useState<IDriveItem[]>([]);
    const [open, setOpen] = React.useState(false);
    const { openWaitDialog, setOpenWaitDialog } = props;
    const [searchQuery, setSearchQuery] = useState('');
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [selectedItem, setSelectedItem] = useState<IDriveItem | undefined>(
        undefined
    );
    const [driveItems, setDriveItems] = useState<IDriveItem[]>([]);
    const { fetchRoot, addSource, getSourceChildrens } = useDriveService();
    const drive = DRIVE.filter(
        (d) => d.id == props.integrationAccount.integration_id
    )[0];

    const renderRootFiles = async () => {
        const _files = await fetchRoot(
            props.integrationAccount.integration_name,
            props.integrationAccount.account_id
        );
        setDriveItems(_files);
    };

    useEffect(() => {
        if (driveItems.length == 0) {
            renderRootFiles();
        }
    }, [props.integrationAccount]);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        searchParams.delete('add-source');
        setSearchParams(searchParams);
        setSelectedItem(undefined);
        setOpen(false);
    };

    const handleBackClick = async () => {
        if (!directoryStack.length) {
            return;
        }
        let dStack = [...directoryStack];
        dStack.pop();
        setDirectoryStack([...dStack]);
        if (dStack.length) {
            const di: IDriveItem | undefined = dStack.pop();
            if (di) {
                setSelectedItem(undefined);
                const _files = await getSourceChildrens(
                    props.integrationAccount.integration_name,
                    props.integrationAccount.account_id,
                    di.item_id
                );
                setDriveItems(_files);
            } else {
                await renderRootFiles();
            }
        } else {
            await renderRootFiles();
        }
    };

    const openDirectory = async (di: IDriveItem) => {
        if (di.item_type == 'FILE') return;

        setSelectedItem(undefined);
        const _files = await getSourceChildrens(
            props.integrationAccount.integration_name,
            props.integrationAccount.account_id,
            di.item_id
        );
        if (_files) {
            setDirectoryStack([...directoryStack, di]);
            setDriveItems(_files);
        }
    };
    const handleAddSource = async () => {
        if (!selectedItem) return;
        setIsProcessing(true);
        let res: IDriveItem = await addSource(
            props.integrationAccount.integration_name,
            props.integrationAccount.account_id,
            selectedItem?.item_id
        );
        props.onSourceAdd(res);
        setIsProcessing(false);
        setOpenWaitDialog(true);
        handleClose();
    };

    useEffect(() => {
        if (props.triggerAddSource > 0) {
            handleClickOpen();
        }
    }, [props.triggerAddSource]);
    return (
        <Box>
            <Box
                onClick={handleClickOpen}
                sx={{ cursor: 'pointer' }}
                textAlign={'center'}
            >
                <AddCircleOutlineIcon sx={{ color: '#29319B' }} />
                <br />
                <Typography variant="caption">Add Source</Typography>
            </Box>
            <BootstrapDialogMedium
                onClose={() => {
                    setOpenWaitDialog(false);
                    setDirectoryStack([]);
                }}
                aria-labelledby="customized-dialog-title"
                open={openWaitDialog}
                sx={{
                    p: 4,
                    textAlign: 'center',
                }}
            >
                <DialogContent>
                    {WaitTime}
                    <Typography mt={4} variant="h3">
                        Relax! Wait for some time.
                    </Typography>
                    <Typography color="#4B4B4C" mt={1} fontSize={14}>
                        Files may take upto an hour for sync. Sit back and see
                        your files after some time.
                    </Typography>
                    <Box mt={4} pt={4}>
                        <Button
                            sx={{
                                minWidth: 200,
                                borderColor: '#D7D9EC',
                                color: '#29319B',
                                fontSize: 16,
                                fontWeight: 600,
                            }}
                            onClick={() => {
                                setOpenWaitDialog(false);
                            }}
                            variant="outlined"
                        >
                            Close
                        </Button>
                    </Box>
                </DialogContent>
            </BootstrapDialogMedium>
            <BootstrapDialogLarge
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 0 }} id="customized-dialog-title">
                    <Box display={'flex'}>
                        <Box
                            sx={{
                                mt: 2,
                                height: '4px',
                                transform: 'scale(.6,.6)',
                            }}
                        >
                            {drive.icon}
                        </Box>
                        <Typography mt={1} lineHeight={'34px'} variant="h3">
                            {drive.title}
                        </Typography>
                    </Box>
                    <Typography
                        fontSize={16}
                        ml={2}
                        fontWeight={400}
                        color={'#4b4b4c'}
                    >
                        Select a folder as a source from the drive to sync your
                        documents. Auto sync in every 20 minutes.
                    </Typography>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 24,
                        top: 24,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ mt: 2 }} dividers>
                    <Box pb={2} width={'50%'}>
                        <Input
                            placeholder="Search across files and folder"
                            type="text"
                            value={searchQuery}
                            onChange={(t) => setSearchQuery(t.target.value)}
                            startAdornment={
                                <InputAdornment position="end">
                                    <SearchInsights
                                        height={16}
                                        width={16}
                                        fillColor="#000033"
                                    />
                                </InputAdornment>
                            }
                            pb={2.5}
                            pt={2.5}
                            borderRadius={2}
                            sx={{
                                paddingLeft: 1,
                                '&>.MuiInputBase-input': {
                                    fontSize: 16,
                                    padding: '4px 14px 4px 12px !important',
                                },
                                '&>.MuiInputBase-root': {
                                    paddingLeft: 4,
                                },
                            }}
                        />
                    </Box>
                    {directoryStack.length > 0 && (
                        <Box mt={4} display={'flex'}>
                            <Box pt={0.5} mr={1}>
                                <KeyboardBackspaceIcon
                                    sx={{
                                        cursor: 'pointer',
                                        color: '#29319B',
                                        mr: 1,
                                    }}
                                    onClick={handleBackClick}
                                />
                            </Box>
                            <Typography variant="h6">
                                {props.integrationAccount.preferred_username}
                            </Typography>
                            {directoryStack.map((di: IDriveItem) => (
                                <Box display={'flex'}>
                                    <Typography mx={1} variant="h6">
                                        /
                                    </Typography>
                                    <Typography variant="h6">
                                        {di.display_name}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    )}
                    <Box
                        className="scrollable"
                        sx={{
                            height: '500px',
                            overflow: 'auto',
                        }}
                    >
                        <Box
                            mx={'auto'}
                            display={'flex'}
                            flexBasis={'100%'}
                            flexWrap={'wrap'}
                        >
                            {driveItems
                                .filter(
                                    (di: IDriveItem) =>
                                        di.display_name
                                            .toLowerCase()
                                            .includes(
                                                searchQuery.toLowerCase()
                                            ) || !searchQuery
                                )
                                .sort((a, b) =>
                                    b.display_name.localeCompare(a.display_name)
                                )
                                .sort((a, b) => (a.item_type == 'DIR' ? -1 : 1))
                                .map((di) => (
                                    <Badge
                                        invisible={
                                            !props.sources
                                                .map((s) => s.item_id)
                                                .includes(di.item_id)
                                        }
                                        badgeContent={
                                            <CheckCircleOutlineIcon color="success" />
                                        }
                                        sx={{
                                            '& .MuiBadge-badge': {
                                                right: 20,
                                                top: 16,
                                            },
                                        }}
                                    >
                                        <Box
                                            textAlign={'center'}
                                            px={3}
                                            py={3}
                                            onDoubleClick={() => {
                                                openDirectory(di);
                                            }}
                                            onClick={() => {
                                                if (
                                                    !props.sources
                                                        .map((s) => s.item_id)
                                                        .includes(di.item_id) &&
                                                    di.item_type !== 'FILE' &&
                                                    di.level !== null
                                                ) {
                                                    setSelectedItem(di);
                                                }
                                            }}
                                            sx={{
                                                cursor:
                                                    di.level == null ||
                                                    props.sources
                                                        .map((s) => s.item_id)
                                                        .includes(di.item_id)
                                                        ? ''
                                                        : 'pointer',
                                            }}
                                            width={'116px'}
                                        >
                                            <Tooltip
                                                placement="top"
                                                arrow
                                                title={di.display_name}
                                            >
                                                <Box>
                                                    {di.item_type == 'DIR' ? (
                                                        <FolderIcon
                                                            sx={{
                                                                fontSize: 70,
                                                                color:
                                                                    props.sources
                                                                        .map(
                                                                            (
                                                                                s
                                                                            ) =>
                                                                                s.item_id
                                                                        )
                                                                        .includes(
                                                                            di.item_id
                                                                        ) ||
                                                                    selectedItem?.item_id ==
                                                                        di.item_id
                                                                        ? '#6778f2'
                                                                        : '#95A2FF',
                                                            }}
                                                        />
                                                    ) : (
                                                        <InsertDriveFileIcon
                                                            sx={{
                                                                fontSize: 55,
                                                                mt: 2,
                                                                color:
                                                                    props.sources
                                                                        .map(
                                                                            (
                                                                                s
                                                                            ) =>
                                                                                s.item_id
                                                                        )
                                                                        .includes(
                                                                            di.item_id
                                                                        ) ||
                                                                    selectedItem?.item_id ==
                                                                        di.item_id
                                                                        ? '#6778f2'
                                                                        : '#95A2FF',
                                                            }}
                                                        />
                                                    )}

                                                    <Typography
                                                        className="clamp-line-1"
                                                        fontSize={14}
                                                        fontWeight={600}
                                                        mt={
                                                            di.item_type ==
                                                            'DIR'
                                                                ? 0
                                                                : 1.5
                                                        }
                                                    >
                                                        {di.display_name}
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Badge>
                                ))}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ mt: 3 }}>
                    <Button variant="text" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!selectedItem || isProcessing}
                        onClick={handleAddSource}
                        variant="contained"
                        sx={{
                            textTransform: 'none',
                            ml: '40px !important',
                        }}
                    >
                        Set as source
                    </Button>
                </DialogActions>
            </BootstrapDialogLarge>
        </Box>
    );
};
