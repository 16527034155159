import React from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box } from '@mui/material';

interface ThumbnailProps {
    title: string;
    height: number;
    width: number | string;
    caption: string;
    base64Image: string;
    hidePlay?: boolean;
}

export const Thumbnail: React.FC<ThumbnailProps> = ({
    title,
    height,
    width,
    caption,
    base64Image,
    hidePlay,
}) => {
    return (
        <div
            style={{
                position: 'relative',
                display: 'inline-block',
                textAlign: 'center',
                width,
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={width}
                height={height}
                viewBox="0 0 124 76"
                version="1.1"
            >
                <defs>
                    <clipPath id="clip1">
                        <path d="M 6.683594 0.746094 L 117.316406 0.746094 C 120.597656 0.746094 123.257812 3.414062 123.257812 6.707031 L 123.257812 69.292969 C 123.257812 72.585938 120.597656 75.253906 117.316406 75.253906 L 6.683594 75.253906 C 3.402344 75.253906 0.742188 72.585938 0.742188 69.292969 L 0.742188 6.707031 C 0.742188 3.414062 3.402344 0.746094 6.683594 0.746094 Z M 6.683594 0.746094 " />
                    </clipPath>
                    <image
                        id="image253"
                        width="124"
                        height="76"
                        xlinkHref={base64Image}
                    />
                </defs>
                <g clipPath="url(#clip1)" clipRule="nonzero">
                    <use xlinkHref="#image253" />
                </g>
                <text
                    x="50%"
                    y="35"
                    textAnchor="middle"
                    fontSize="6"
                    fontWeight="bold"
                >
                    {title}
                </text>
                <text
                    x="50%"
                    y="45"
                    textAnchor="middle"
                    fontSize="4"
                    fontStyle="italic"
                >
                    {caption}
                </text>
            </svg>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    borderRadius: 1,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
            />
            {!hidePlay && (
                <PlayCircleOutlineIcon
                    style={{
                        position: 'absolute',
                        top: 'calc(50% - 17px)', // Adjust these values to properly position the icon
                        left: 'calc(50% - 15px)', // Adjust these values to properly position the icon
                        fontSize: '30px',
                        color: 'white', // Adjust the color if needed
                    }}
                />
            )}
        </div>
    );
};
