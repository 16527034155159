export const ThumbDown = () => (
    <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_6925_57116"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="21"
        >
            <rect y="0.5" width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_6925_57116)">
            <path
                d="M2.7564 13.415C2.36002 13.415 2.00986 13.263 1.70592 12.9591C1.40197 12.6551 1.25 12.305 1.25 11.9086V10.5625C1.25 10.475 1.26122 10.3813 1.28365 10.2813C1.30608 10.1814 1.33118 10.0881 1.35896 10.0016L3.74715 4.36602C3.86656 4.09956 4.06676 3.87445 4.34773 3.69069C4.62872 3.50692 4.9219 3.41504 5.22727 3.41504H13.9903V13.415L9.14258 18.2211C8.97699 18.3867 8.78522 18.4855 8.56727 18.5175C8.34933 18.5496 8.14047 18.5122 7.94069 18.4054C7.74091 18.2985 7.59455 18.1468 7.50161 17.9502C7.40865 17.7537 7.38888 17.5501 7.44231 17.3397L8.33973 13.415H2.7564ZM12.7404 12.883V4.66502H5.22433C5.16557 4.66502 5.10547 4.68105 5.04404 4.7131C4.9826 4.74516 4.93585 4.79858 4.90379 4.87337L2.49996 10.4984V11.9086C2.49996 11.9834 2.524 12.0448 2.57208 12.0929C2.62017 12.141 2.6816 12.165 2.7564 12.165H9.91983L8.87496 16.7323L12.7404 12.883ZM16.4102 3.41504C16.8245 3.41504 17.1791 3.56255 17.4741 3.85756C17.7691 4.15256 17.9166 4.50719 17.9166 4.92146V11.9086C17.9166 12.3228 17.7691 12.6775 17.4741 12.9725C17.1791 13.2675 16.8245 13.415 16.4102 13.415H13.9903V12.165H16.4102C16.485 12.165 16.5464 12.141 16.5945 12.0929C16.6426 12.0448 16.6666 11.9834 16.6666 11.9086V4.92146C16.6666 4.84667 16.6426 4.78523 16.5945 4.73714C16.5464 4.68906 16.485 4.66502 16.4102 4.66502H13.9903V3.41504H16.4102Z"
                fill="#A5A6AB"
            />
        </g>
    </svg>
);
