import { useContext } from 'react';
import { Constants } from '../constants';
import { AppContext } from '../providers/app-provider';
import {
    AlertsCluster,
    CIRAlertsSettingsResponse,
    Cluster,
    ClusterExecutiveSummaryResponse,
    IUserClusterResponse,
    PredefinedClusterResponse,
    Topic,
    UpsertClusterRequest,
    UpsertClusterResponse,
    UserCluster,
    UserClusters,
} from './interfaces';
import {
    INetworkResponse,
    NetworkRequestMethod,
    useNetworkAPI,
} from './network.service';
import { useTopicService } from './topic.service';

export const useClusterService = () => {
    const appContext = useContext(AppContext);
    const { triggerRequest } = useNetworkAPI();
    const { fetchClusterTopicSentiments } = useTopicService();

    const {} = useTopicService();

    const getClusters = (): UserCluster[] => {
        return appContext?.clusters || [];
    };

    const fetchUserClusters = async () => {
        let clusters = getClusters();
        if (clusters.length) {
            return;
        }
        reFetchUserClusters();
    };

    const getClusterExecutiveSumary = async (
        clusterId: string
    ): Promise<string> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: ClusterExecutiveSummaryResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.clusterExecutiveSummary}/${clusterId}/executive-summary`,
                retryCount: 3,
            });

            return result.body.data.executiveSummary;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const reFetchUserClusters = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IUserClusterResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: Constants.getUserCluster,
                retryCount: 3,
            });

            await processUserClusterResponse(result.body.data);
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchUPrefefinedClusters = async (): Promise<Cluster[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: PredefinedClusterResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: Constants.getPredefinedClusters,
                retryCount: 3,
            });

            return result.body.data.clusters;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchAlertsSetting = async (): Promise<AlertsCluster[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: CIRAlertsSettingsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: Constants.clusterAlertsSettings,
                retryCount: 3,
            });

            return result.body.data.clusters;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const upsertCluster = async (
        data: UpsertClusterRequest
    ): Promise<UpsertClusterResponse> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: UpsertClusterRequest = data;
            const result: {
                body: UpsertClusterResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PATCH,
                headers,
                body: JSON.stringify(body),
                url: Constants.upsertUserCluster,
                retryCount: 3,
            });
            return result.body;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const updateClusterAlert = async (
        data: AlertsCluster
    ): Promise<boolean> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: AlertsCluster = data;
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PATCH,
                headers,
                body: JSON.stringify(body),
                url: Constants.clusterAlertsSettings,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return false;
        }
    };

    const deleteCluster = async (clusterId: string): Promise<boolean> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.DELETE,
                headers,
                url: `${Constants.deleteCluster}/${clusterId}`,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return false;
        }
    };

    const fetchClusterSentiments = (cluster: UserCluster) => {
        if (!appContext?.clusters) return;
        if (!cluster.sentimentStatus) {
            let _clusters = [...appContext.clusters];
            for (let c of _clusters) {
                if (c.dashboardClusterId == cluster.dashboardClusterId) {
                    c.sentimentStatus = true;
                }
            }
            appContext.setClusters([..._clusters]);
            fetchClusterTopicSentiments(cluster.userTopicSubscriptions);
        }
    };

    const processUserClusterResponse = async (
        userClustersResponse: UserClusters
    ) => {
        if (userClustersResponse) {
            appContext?.setClusters(userClustersResponse.clusters);
            appContext?.setSubscriptions(
                userClustersResponse.allTopicSubscriptions
            );
        }
    };
    return {
        getClusters,
        fetchUserClusters,
        processUserClusterResponse,
        reFetchUserClusters,
        fetchClusterSentiments,
        fetchUPrefefinedClusters,
        upsertCluster,
        deleteCluster,
        fetchAlertsSetting,
        updateClusterAlert,
        getClusterExecutiveSumary,
    };
};
