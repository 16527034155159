import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
    BorderedBox,
    BorderedContainedBox,
} from '../../../components/core/box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useBillingService } from '../../../services/billing.service';
import {
    IActiveSubscriptionResponseData,
    IAddOn,
} from '../../../services/interfaces';
import loadingAnimation from '../../../components/animations/circular-loader.json';
import Lottie from 'lottie-react';
import { PlansQuotaData } from './constants';
import { UtilsService } from '../../../services/utils.service';
import { useSearchParams } from 'react-router-dom';
import { useQueryparamServices } from '../../../services/query-param.service';
import { useSnackbar } from 'notistack';
import { purchaseSucessful } from '../../../analytics/analytics';

const FEATURES: {
    title: string;
    quotaFeature: {
        featureKey: string;
        suffix: string;
    }[];
    usecase?: string[];
}[] = [
    {
        title: 'Document Insights',
        quotaFeature: [
            { featureKey: 'AI Message Credits', suffix: 'Queries' },
            { featureKey: 'Word Count', suffix: 'Word Count' },
        ],
    },
    {
        title: 'Photon Live',
        quotaFeature: [{ featureKey: 'AI Message Credits', suffix: 'Queries' }],
        usecase: ['API Calls'],
    },
    {
        title: 'Diligence Reports',
        quotaFeature: [
            { featureKey: 'Diligence Reports', suffix: 'Diligence Reports' },
        ],
    },
    {
        title: 'Chatbot',
        quotaFeature: [{ featureKey: 'Chatbots', suffix: 'Chatbots' }],
    },
];

interface ISubscriptionsProps {
    availableAddOns: IAddOn[];
    subscriptionInfo: IActiveSubscriptionResponseData;
}

export const Subscriptions = (props: ISubscriptionsProps) => {
    const { subscriptionInfo } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { addQueryParams, getQueryParam, removeQueryParams } =
        useQueryparamServices();

    const getQuotaByKey = (key: string) => {
        for (const group of PlansQuotaData) {
            const data = group.items.find((item) => item.feature === key);
            if (
                subscriptionInfo &&
                data &&
                data[
                    subscriptionInfo.plan_type.toLowerCase() as keyof typeof data
                ]
            ) {
                return data[
                    subscriptionInfo.plan_type.toLowerCase() as keyof typeof data
                ];
            }
        }
        return '';
    };

    const triggerPurchaseEvent = async () => {
        purchaseSucessful(await UtilsService.getRefCode());
    };

    useEffect(() => {
        if (getQueryParam('success')) {
            let success = getQueryParam('success') == 'true';
            if (success) {
                enqueueSnackbar(`Purchase successful`, {
                    variant: 'success',
                });
                triggerPurchaseEvent();
            } else {
                enqueueSnackbar(`Purchase failed`, {
                    variant: 'error',
                });
            }
            removeQueryParams('success');
        }
    }, [getQueryParam('success')]);
    return subscriptionInfo ? (
        <BorderedBox p={4}>
            <Box display="flex" justifyContent={'space-between'}>
                <Box>
                    <Typography variant="h6">Your Plan</Typography>
                    <Typography variant="h1">
                        {subscriptionInfo.status == 'trialing'
                            ? 'Pro Trial'
                            : UtilsService.snakeCaseToTitleCase(
                                  subscriptionInfo.plan
                              )}{' '}
                        Plan
                    </Typography>
                    <Typography color={'#4B4B4C'}>
                        {subscriptionInfo.status == 'trialing'
                            ? 'This is the 7-day Trial Plan. After 7 days, you will be switched to the Free Plan. To avoid this, Buy PRO PLAN now!'
                            : 'Plan for all your needs'}
                    </Typography>
                </Box>
                <Box display="flex">
                    {subscriptionInfo.cancellation_date ? (
                        <Typography
                            sx={{
                                py: 2,
                                px: 3,
                                height: 38,
                                borderRadius: 6,
                                backgroundColor: '#EBECF6',
                            }}
                            color={'#4a4b4c'}
                            fontSize={14}
                        >
                            Plan Expired/ Cancelled on{' '}
                            <b style={{ color: '#000' }}>
                                {UtilsService.getDateAheadDays(
                                    new Date(
                                        subscriptionInfo.cancellation_date
                                    ),
                                    0
                                )}
                            </b>
                        </Typography>
                    ) : (
                        <Typography
                            sx={{
                                py: 1,
                                px: 3,
                                mt: 1,
                                height: 30,
                                borderRadius: 6,
                                backgroundColor: '#EBECF6',
                            }}
                            color={'#4a4b4c'}
                            fontSize={14}
                        >
                            Next Billing Cycle{' '}
                            <b style={{ color: '#000' }}>
                                {UtilsService.getDateAheadDays(
                                    new Date(
                                        subscriptionInfo.current_period_end
                                    ),
                                    1
                                )}
                            </b>
                        </Typography>
                    )}

                    {subscriptionInfo.status != 'active' && (
                        <Button
                            sx={{ height: 30, ml: 4, mt: 1 }}
                            onClick={() => addQueryParams('subTab', 'PLANS')}
                            variant="contained"
                        >
                            Buy Plan
                        </Button>
                    )}
                </Box>
            </Box>
            {subscriptionInfo.status == 'past_due' && (
                <Typography
                    sx={{
                        py: 1,
                        px: 3,
                        mt: 4,
                        height: 30,
                        borderRadius: 3,
                        backgroundColor: '#FFD2D2',
                    }}
                    color={'#4a4b4c'}
                    fontSize={14}
                >
                    {UtilsService.snakeCaseToTitleCase(subscriptionInfo.plan)}{' '}
                    subscription payment is overdue. Please update/add billing
                    method to continue using the plan. Or it will expire within{' '}
                    <b>
                        {UtilsService.getDaysDifference(
                            subscriptionInfo.cancellation_date
                        )}{' '}
                        Days
                    </b>{' '}
                    and you will be switched back to Free Plan
                </Typography>
            )}
            <Typography mt={5} variant="h4">
                Features Included
            </Typography>
            <Box
                mt={3}
                pr={4}
                justifyContent={'space-between'}
                display={'flex'}
            >
                {FEATURES.map((f) => (
                    <Box display="flex">
                        <CheckCircleIcon
                            sx={{
                                color: '#24A148',
                                fontSize: 18,
                                mt: 1,
                                mr: 2,
                            }}
                        />
                        <Box>
                            <Typography variant="h6">{f.title}</Typography>
                            {f.quotaFeature.map((q) => (
                                <Typography color="#4A4B4C">
                                    {getQuotaByKey(q.featureKey) == '-'
                                        ? '0'
                                        : UtilsService.convertToInternationalCurrencySystem(
                                              getQuotaByKey(q.featureKey)
                                          )}{' '}
                                    {q.suffix}
                                </Typography>
                            ))}
                            {f.usecase?.map((u) => (
                                <Typography color="#4A4B4C">{u}</Typography>
                            ))}
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box py={6}>
                <Divider />
            </Box>
            <Box>
                <Box justifyContent={'space-between'} display={'flex'}>
                    <Box>
                        <Typography variant="h4">Flexible Add-Ons</Typography>
                        <Typography>
                            Your Add-Ons will renew on 1st of every month.
                        </Typography>
                    </Box>
                    <Button
                        sx={{ height: 30 }}
                        onClick={() => addQueryParams('subTab', 'ADD_ONS')}
                        variant="contained"
                    >
                        View Add-Ons
                    </Button>
                </Box>
                {subscriptionInfo.add_ons.length > 0 && (
                    <Grid container mt={6}>
                        <Grid item sm={8}>
                            {subscriptionInfo.add_ons.map((a) => (
                                <BorderedContainedBox mb={4}>
                                    <Box
                                        display="flex"
                                        justifyContent={'space-between'}
                                    >
                                        <Typography variant="h4">
                                            {UtilsService.snakeCaseToTitleCase(
                                                a.product
                                            )}
                                        </Typography>
                                        <Typography>
                                            {UtilsService.convertToInternationalCurrencySystem(
                                                a.quantity
                                            )}{' '}
                                            {UtilsService.snakeCaseToTitleCase(
                                                a.product
                                            )}
                                        </Typography>
                                    </Box>
                                    <Typography mt={3}>
                                        Used In:{' '}
                                        {
                                            props.availableAddOns.filter(
                                                (p) => p.product == a.product
                                            )[0].feature
                                        }
                                    </Typography>
                                </BorderedContainedBox>
                            ))}
                        </Grid>
                    </Grid>
                )}
            </Box>
        </BorderedBox>
    ) : (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 150px)',
            }}
        >
            <Lottie animationData={loadingAnimation} loop={true} />
        </Box>
    );
};
