// components/EPSChart.tsx
import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';

interface EPSChartProps {
    series: any[];
    categories: string[];
}

export const SlopeChart: React.FC<EPSChartProps> = ({ series, categories }) => {
    const options: ApexOptions = {
        chart: {
            type: 'line',
            toolbar: {
                show: false,
            },
        },
        xaxis: {
            type: 'category',

            tooltip: {
                enabled: false,
            },
            categories: categories,
        },

        yaxis: {
            min: 0,
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },

        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
        },
        grid: {
            yaxis: {
                lines: {
                    show: false,
                },
            },
            xaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                top: 0,
                right: 50,
                bottom: 0,
                left: 50,
            },
        },
        dataLabels: {
            enabled: false,
        },
    };

    return (
        <div>
            <Chart options={options} series={series} type="line" height={300} />
        </div>
    );
};
