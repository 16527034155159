interface IProps {
    color?: string;
}
export const BookmarkIcon = (props: IProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_6526_63036"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_6526_63036)">
            <path
                d="M5.5 20.2494V5.30721C5.5 4.80208 5.675 4.37451 6.025 4.02451C6.375 3.67451 6.80257 3.49951 7.3077 3.49951H16.6922C17.1974 3.49951 17.6249 3.67451 17.9749 4.02451C18.3249 4.37451 18.4999 4.80208 18.4999 5.30721V20.2494L12 17.461L5.5 20.2494ZM6.99997 17.9495L12 15.7995L17 17.9495V5.30721C17 5.23028 16.9679 5.15975 16.9038 5.09564C16.8397 5.03154 16.7692 4.99949 16.6922 4.99949H7.3077C7.23077 4.99949 7.16024 5.03154 7.09612 5.09564C7.03202 5.15975 6.99997 5.23028 6.99997 5.30721V17.9495Z"
                fill={props.color || '#F9F9FF'}
            />
        </g>
    </svg>
);
