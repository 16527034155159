import styled from '@emotion/styled';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Paper,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DeleteImg } from '../icons/delete-img';

const DialogWrapper = styled(Paper)(() => ({
    width: '40%',
    maxWidth: '450px !important',
}));

interface IProps {
    title: string;
    description?: string;
    icon?: JSX.Element;
    open: boolean;
    actionTitle: string;
    discardTitle?: string;
    onActionButtonClick: () => Promise<void> | void;
    onClose: () => void;
}

export const ConfirmationDialog = (props: IProps) => {
    const [open, setOpen] = React.useState(props.open);
    const [isActionInProgress, setIsActionInProgress] =
        useState<boolean>(false);

    const handleAction = async () => {
        setIsActionInProgress(true);
        await props.onActionButtonClick();
        setIsActionInProgress(false);
        props.onClose();
    };

    useEffect(() => {
        setOpen(props.open);
        setIsActionInProgress(false);
    }, [props.open]);
    return (
        <Box>
            <Dialog
                sx={{
                    zIndex: 999999,
                }}
                PaperComponent={DialogWrapper}
                open={open}
                onClose={props.onClose}
            >
                <DialogContent>
                    <Box textAlign="center">
                        {props.icon && <Box>{props.icon}</Box>}
                        <Typography
                            color="#000033"
                            mb={2}
                            textAlign="center"
                            variant="h4"
                        >
                            {props.title}
                        </Typography>
                        {props.description && (
                            <Typography
                                color="#000033"
                                mb={2}
                                fontSize={14}
                                textAlign="center"
                                variant="caption"
                            >
                                {props.description}
                            </Typography>
                        )}
                    </Box>
                    <Box></Box>
                </DialogContent>
                <DialogActions>
                    <Grid px={6} py={4} spacing={6} container>
                        <Grid xs={6} item>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={props.onClose}
                            >
                                {props.discardTitle || 'Cancel'}
                            </Button>
                        </Grid>
                        <Grid xs={6} item>
                            <Button
                                fullWidth
                                variant="contained"
                                disabled={isActionInProgress}
                                onClick={handleAction}
                            >
                                {isActionInProgress ? (
                                    <CircularProgress
                                        size={25}
                                        sx={{
                                            color: '#ffffff',
                                            ml: 2,
                                        }}
                                    />
                                ) : (
                                    props.actionTitle
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
