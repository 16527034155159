import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { ContainedBox } from '../../../components/core/box';
import { ResetPasswordImage } from '../../../components/images/reset-password';
import { PasswordInput } from '../../../components/core/input/password-input';
import { Valid } from '../../../components/icons/valid';
import { Invalid } from '../../../components/icons/invalid';
import Lottie from 'lottie-react';
import { useSnackbar } from 'notistack';
import successAnimation from '../../../components/animations/success.json';
import { useAuthService } from '../../../services/auth.service';
import { useNetworkAPI } from '../../../services/network.service';
import { NavigationLink } from '../../../components/core/link';

const ResetPassword = () => {
    const { resetPassword } = useAuthService();
    const { triggerRequest } = useNetworkAPI();
    const { enqueueSnackbar } = useSnackbar();
    const [isUpdating, setIsUpdating] = useState(false);
    const [passwordResetSuccessful, setPasswordResetSuccessful] =
        useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const PASSWORD_VALIDATIONS: {
        title: string;
        isValid: (value: string) => boolean;
    }[] = [
        {
            title: 'Min 8 characters',
            isValid: (value: string) => value.length >= 8,
        },
        {
            title: 'Atleast 1 numeric value',
            isValid: (value: string) => /\d/.test(value),
        },
        {
            title: 'Atleast 1 uppercase character',
            isValid: (value: string) => value !== value.toLowerCase(),
        },
        {
            title: 'Atleast 1 lowercase character',
            isValid: (value: string) => value !== value.toUpperCase(),
        },
        {
            title: 'Atleast 1 special character',
            isValid: (value: string) => !!value.match(/\W/),
        },
    ];

    const onSubmitClick = async () => {
        setIsUpdating(true);
        let data = await resetPassword(
            currentPassword,
            newPassword,
            triggerRequest
        );
        setIsUpdating(false);
        if (data.success) {
            setPasswordResetSuccessful(true);
        } else {
            enqueueSnackbar(data.error.description, { variant: 'error' });
        }
    };
    return (
        <Box>
            <Grid container>
                <Grid item sm={4} md={6}>
                    <Box textAlign="center" pt={40}>
                        {ResetPasswordImage}
                    </Box>
                </Grid>

                {!passwordResetSuccessful && (
                    <Grid item sm={8} md={6}>
                        <ContainedBox>
                            <Typography variant="h1" color="#003">
                                Set New Password
                            </Typography>
                            <Typography
                                fontSize={16}
                                fontWeight={400}
                                color="#4B4B4C"
                            >
                                Securely reset your password in just a few
                                simple steps.
                            </Typography>
                            <Box py={3}>
                                <Typography mb={3} color="#003" variant="h6">
                                    Current Password
                                </Typography>
                                <PasswordInput
                                    handlePasswordChange={(e) =>
                                        setCurrentPassword(e.target.value)
                                    }
                                    placeholder="Current Password"
                                />
                            </Box>
                            <Box pt={3}>
                                <Typography mb={3} color="#003" variant="h6">
                                    New Password
                                </Typography>
                                <PasswordInput
                                    handlePasswordChange={(e) =>
                                        setNewPassword(e.target.value)
                                    }
                                    placeholder="New Password"
                                />
                                {newPassword && (
                                    <Grid mt={2} container>
                                        {PASSWORD_VALIDATIONS.map(
                                            (validation: any) => (
                                                <Grid item sm={6}>
                                                    <Box display="flex">
                                                        <Box pt={0.5} pr={2}>
                                                            {validation.isValid(
                                                                newPassword
                                                            )
                                                                ? Valid
                                                                : Invalid}
                                                        </Box>
                                                        <Typography
                                                            color={
                                                                validation.isValid(
                                                                    newPassword
                                                                )
                                                                    ? '#19AF55'
                                                                    : '#D82F44'
                                                            }
                                                        >
                                                            {validation.title}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                )}
                            </Box>
                            <Box py={3}>
                                <Typography mb={3} color="#003" variant="h6">
                                    Confirm Password
                                </Typography>
                                <PasswordInput
                                    handlePasswordChange={(e) =>
                                        setConfirmPassword(e.target.value)
                                    }
                                    placeholder="Confirm Password"
                                />
                                {newPassword && confirmPassword && (
                                    <Box mt={2} display="flex">
                                        <Box pt={0.5} pr={2}>
                                            {newPassword == confirmPassword
                                                ? Valid
                                                : Invalid}
                                        </Box>
                                        <Typography
                                            color={
                                                newPassword == confirmPassword
                                                    ? '#19AF55'
                                                    : '#D82F44'
                                            }
                                        >
                                            {`New password and confirm password ${
                                                newPassword == confirmPassword
                                                    ? 'matches'
                                                    : 'do not match'
                                            }`}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                            <Button
                                sx={{
                                    mt: 8,
                                    fontSize: '16px !important',
                                    height: 54,
                                    fontWeight: '600 !important',
                                }}
                                disabled={
                                    isUpdating ||
                                    !newPassword ||
                                    !currentPassword ||
                                    !confirmPassword ||
                                    confirmPassword != newPassword ||
                                    !PASSWORD_VALIDATIONS.reduce(
                                        (
                                            prev: boolean,
                                            check: {
                                                title: string;
                                                isValid: (
                                                    value: string
                                                ) => boolean;
                                            }
                                        ) => prev && check.isValid(newPassword),
                                        true
                                    )
                                }
                                fullWidth
                                onClick={onSubmitClick}
                                variant="contained"
                            >
                                {!isUpdating && <span>UPDATE PASSWORD</span>}
                                {isUpdating && (
                                    <CircularProgress
                                        sx={{
                                            color: '#ffffff',
                                        }}
                                    />
                                )}
                            </Button>
                        </ContainedBox>
                    </Grid>
                )}
                {passwordResetSuccessful && (
                    <Grid item sm={8} md={6}>
                        <Box textAlign="center" pt={40}>
                            <Typography variant="h1" color="#003">
                                Password Reset Successfully
                            </Typography>
                            <Box
                                sx={{
                                    mt: 7,
                                    height: 130,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    '& > div > svg > g > g:nth-child(1) > rect':
                                        {
                                            display: 'none',
                                        },
                                }}
                            >
                                <Lottie
                                    animationData={successAnimation}
                                    loop={true}
                                />
                            </Box>
                            <Typography
                                fontSize={16}
                                my={2}
                                fontWeight={400}
                                color="#4B4B4C"
                            >
                                Your password has been successfully reset.
                            </Typography>
                            <Typography
                                variant="h6"
                                component={NavigationLink}
                                to="/"
                                color="#29319B"
                            >
                                Back to dashboard
                            </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default ResetPassword;
