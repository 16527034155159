import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ContainedBox } from '../../../components/core/box';
import { QuotaManagement } from './quota-management';
import { Subscriptions } from './subscriptions';
import { PricingSection } from './plans';
import { AddOns } from './add-ons';
import { BillingHistory } from './billing-history';
import { BillingMethod } from './billing-method';
import { useSearchParams } from 'react-router-dom';
import {
    IActiveSubscriptionResponseData,
    IAddOn,
} from '../../../services/interfaces';
import { useBillingService } from '../../../services/billing.service';
import { UtilsService } from '../../../services/utils.service';
import { AppContext } from '../../../providers/app-provider';

enum ESubTabs {
    SUBSCRIPTIONS = 'SUBSCRIPTIONS',
    USAGE_DASHBOARD = 'USAGE_DASHBOARD',
    PLANS = 'PLANS',
    ADD_ONS = 'ADD_ONS',
    BILLING_HISTORY = 'BILLING_HISTORY',
    BILLING_METHOD = 'BILLING_METHOD',
}

interface ICustomTab {
    tab: string;
    activeTab: string;
    setTab: (tab: string) => void;
    value: string;
}

const CustomTab = (props: ICustomTab) => {
    const { tab, setTab, value, activeTab } = props;

    return (
        <Box
            sx={{
                p: 2,
                mr: 2,
                cursor: 'pointer',
                borderRadius: 1,
                backgroundColor: tab == activeTab ? '#EBECF6' : '#F9F9FF',
                border: `1px solid ${
                    tab == activeTab ? '#29319B' : '#F9F9FF'
                } `,
            }}
            onClick={() => setTab(tab)}
        >
            <Typography
                fontWeight={tab == activeTab ? 600 : 400}
                color={tab == activeTab ? '#29319B' : '#4B4B4C'}
            >
                {value}
            </Typography>
        </Box>
    );
};

const TABS = [
    {
        tab: 'SUBSCRIPTIONS',
        value: 'Subscriptions',
    },
    {
        tab: 'USAGE_DASHBOARD',
        value: 'Usage Dashboard',
    },
    {
        tab: 'PLANS',
        value: 'Plans',
    },
    {
        tab: 'ADD_ONS',
        value: 'Add-Ons',
    },
    {
        tab: 'BILLING_HISTORY',
        value: 'Billing History',
    },
    {
        tab: 'BILLING_METHOD',
        value: 'Billing Method',
    },
];

export const PlansBilling = () => {
    const appContext = useContext(AppContext);
    const [tab, setTab] = useState('SUBSCRIPTIONS');
    const { fetchAvailableAddons } = useBillingService();
    const [searchParam, setSearchParams] = useSearchParams();
    const [availableAddOns, setAvailableAddOns] = useState<
        IAddOn[] | undefined
    >(undefined);

    const initialize = async () => {
        const res = await fetchAvailableAddons();
        setAvailableAddOns(
            res.map((r) => {
                r.quantity = 0;
                return r;
            })
        );
    };

    useEffect(() => {
        initialize();
    }, []);
    useEffect(() => {
        let tab = searchParam.get('subTab');
        if (tab) {
            setTab(ESubTabs[tab.toUpperCase() as keyof typeof ESubTabs]);
        }
    }, [searchParam.get('subTab')]);

    const addQueryParams = (key: string, value: string) => {
        const param = searchParam.get(key);

        if (param) {
            searchParam.delete(key);
        }
        searchParam.set(key, value);

        // 👇️ update state after
        setSearchParams(searchParam);
    };
    const handleChange = (newValue: string) => {
        addQueryParams('subTab', newValue.toString());
    };
    return (
        <Box mt={6}>
            <Typography variant="h3">Plans & Billing</Typography>
            <Box mt={4} display="flex" justifyContent={'space-between'}>
                <Box display={'flex'}>
                    {TABS.map((t) => (
                        <CustomTab
                            tab={t.tab}
                            activeTab={tab}
                            setTab={handleChange}
                            value={t.value}
                        />
                    ))}
                </Box>
                {appContext?.subscriptionInfo?.status == 'past_due' ? (
                    <Typography
                        sx={{
                            py: 2,
                            px: 3,
                            height: 38,
                            borderRadius: 6,
                            backgroundColor: '#FFD2D2',
                        }}
                        color={'#4a4b4c'}
                        fontSize={14}
                    >
                        You are in overdue period of{' '}
                        <b style={{ color: '#000' }}>
                            {UtilsService.getDaysDifference(
                                appContext.subscriptionInfo.cancellation_date
                            )}{' '}
                            days
                        </b>
                    </Typography>
                ) : (
                    appContext?.subscriptionInfo?.current_period_end && (
                        <Typography
                            sx={{
                                py: 2,
                                px: 3,
                                height: 38,
                                borderRadius: 6,
                                backgroundColor: '#EBECF6',
                            }}
                            color={'#4a4b4c'}
                            fontSize={14}
                        >
                            Your plan expires on{' '}
                            <b style={{ color: '#000' }}>
                                {UtilsService.formatStringDate(
                                    appContext?.subscriptionInfo
                                        .current_period_end,
                                    'MMM dd, yyyy'
                                )}
                            </b>
                        </Typography>
                    )
                )}
            </Box>
            {appContext?.subscriptionInfo && (
                <Box mt={6}>
                    {tab == 'SUBSCRIPTIONS' && availableAddOns && (
                        <Subscriptions
                            subscriptionInfo={appContext?.subscriptionInfo}
                            availableAddOns={availableAddOns}
                        />
                    )}
                    {tab == 'USAGE_DASHBOARD' && <QuotaManagement />}
                    {tab == 'PLANS' && (
                        <PricingSection
                            subscriptionInfo={appContext?.subscriptionInfo}
                        />
                    )}
                    {tab == 'ADD_ONS' && availableAddOns && (
                        <AddOns
                            availableAddOns={availableAddOns}
                            subscriptionInfo={appContext?.subscriptionInfo}
                        />
                    )}
                    {tab == 'BILLING_HISTORY' && <BillingHistory />}
                    {tab == 'BILLING_METHOD' && <BillingMethod />}
                </Box>
            )}
        </Box>
    );
};
