import { useContext } from 'react';
import { Constants } from '../constants';
import { AppContext } from '../providers/app-provider';
import {
    INetworkResponse,
    NetworkRequestMethod,
    useNetworkAPI,
} from './network.service';
import { db } from '../utils/db';
import { UtilsService } from './utils.service';
import {
    IUsageHistoryResponse,
    IUsageHistoryResponseData,
    IUsageOverviewResponse,
    IUsageOverviewResponseData,
    IUsersUsageList,
    IUsersUsageResponse,
} from './interfaces';

export const useQuotaService = () => {
    const { triggerRequest, triggerUploadRequest } = useNetworkAPI();
    const appContext = useContext(AppContext);
    const fetchUsageOverview =
        async (): Promise<IUsageOverviewResponseData> => {
            try {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const result: {
                    body: IUsageOverviewResponse;
                    headers: { [key: string]: string };
                } = await triggerRequest(
                    {
                        method: NetworkRequestMethod.GET,
                        headers,
                        url: Constants.usageOverview,
                        retryCount: 3,
                    },
                    ['x-pi-content-length']
                );
                return result.body.data;
            } catch (error: any) {
                console.error(error);
                throw error;
            }
        };

    const fetchUsersUsage = async (): Promise<IUsersUsageList[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IUsersUsageResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.GET,
                    headers,
                    url: Constants.usersUsage,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.data.data;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchUsageHistory = async (
        quotaCategoryGroup: string,
        year: number,
        month: number
    ): Promise<IUsageHistoryResponseData> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IUsageHistoryResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    body: JSON.stringify({
                        quotaCategoryGroup,
                        year,
                        month,
                    }),
                    headers,
                    url: Constants.usageHistory,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.data;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };
    const updateThreshold = async (
        thresholdPercentage: number
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    body: JSON.stringify({
                        thresholdPercentage,
                    }),
                    headers,
                    url: Constants.updateThreshold,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    return {
        fetchUsageOverview,
        fetchUsageHistory,
        updateThreshold,
        fetchUsersUsage,
    };
};
