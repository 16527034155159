interface IProps {
    size?: number;
    color?: string;
}

export const BankOutlined = (props: IProps) => (
    <svg
        width={props.size || '20'}
        height={props.size || '20'}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.0001 13.34V7.99995H16.0921C16.9851 7.99995 17.3521 6.85195 16.6221 6.33495L10.7721 2.18295C10.5466 2.02273 10.2768 1.93665 10.0001 1.93665C9.72344 1.93665 9.45363 2.02273 9.2281 2.18295L3.3761 6.33495C2.6481 6.85195 3.0131 7.99895 3.9061 7.99895H4.0001V13.339C3.3991 13.725 3.0001 14.399 3.0001 15.166V16.499C3.0001 16.6316 3.05277 16.7587 3.14654 16.8525C3.24031 16.9463 3.36749 16.999 3.5001 16.999H16.5001C16.6327 16.999 16.7599 16.9463 16.8536 16.8525C16.9474 16.7587 17.0001 16.6316 17.0001 16.499V15.166C17.0001 14.399 16.6021 13.725 16.0001 13.34ZM9.8061 2.99895C9.86237 2.95908 9.92963 2.93767 9.9986 2.93767C10.0676 2.93767 10.1348 2.95908 10.1911 2.99895L15.8301 6.99895H4.1681L9.8071 2.99895H9.8061ZM14.9981 7.99895V13.005C14.9429 13.0009 14.8875 12.9989 14.8321 12.999H12.9981V7.99995L14.9981 7.99895ZM5.0001 13.005V7.99995H7.0001V13H5.1661C5.1101 13 5.0541 13.002 4.9991 13.006L5.0001 13.005ZM5.1671 13.999H14.8341C15.4781 13.999 16.0001 14.521 16.0001 15.166V15.999H4.0001V15.166C4.0001 14.521 4.5231 13.999 5.1671 13.999ZM9.5001 12.999H8.0001V7.99995H9.5001V13V12.999ZM10.5001 12.999V7.99995H12.0001V13H10.5001V12.999Z"
            fill={props.color || '#4B4B4C'}
        />
    </svg>
);
