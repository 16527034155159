interface IProps {
    height: number;
    width: number;
}
export const MSTeams = (props: IProps) => (
    <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_5957_75495)">
            <path
                d="M17.9296 8.96777H24.5698C25.1972 8.96777 25.7057 9.47639 25.7057 10.1037V16.152C25.7057 18.4576 23.8366 20.3268 21.531 20.3268H21.5112C19.2056 20.3271 17.3362 18.4583 17.3359 16.1526V9.56155C17.3359 9.48358 17.3513 9.40637 17.3811 9.33433C17.4109 9.26229 17.4547 9.19683 17.5098 9.14169C17.5649 9.08655 17.6304 9.04281 17.7024 9.01297C17.7744 8.98313 17.8516 8.96777 17.9296 8.96777Z"
                fill="#5059C9"
            />
            <path
                d="M22.4208 7.77222C23.9066 7.77222 25.1111 6.56773 25.1111 5.08191C25.1111 3.59609 23.9066 2.3916 22.4208 2.3916C20.935 2.3916 19.7305 3.59609 19.7305 5.08191C19.7305 6.56773 20.935 7.77222 22.4208 7.77222Z"
                fill="#5059C9"
            />
            <path
                d="M14.0501 7.77198C16.1962 7.77198 17.936 6.03217 17.936 3.88599C17.936 1.73982 16.1962 0 14.0501 0C11.9039 0 10.1641 1.73982 10.1641 3.88599C10.1641 6.03217 11.9039 7.77198 14.0501 7.77198Z"
                fill="#7B83EB"
            />
            <path
                d="M19.2304 8.96777H8.2696C7.64972 8.98314 7.15938 9.49758 7.17374 10.1175V17.0159C7.08718 20.7358 10.0301 23.8228 13.7499 23.9138C17.4697 23.8228 20.4127 20.7358 20.3262 17.0159V10.1175C20.3406 9.49758 19.8503 8.98314 19.2304 8.96777Z"
                fill="#7B83EB"
            />
            <path
                opacity="0.1"
                d="M14.3464 8.96777V18.6349C14.3449 18.8517 14.2795 19.0633 14.1582 19.243C14.037 19.4228 13.8653 19.5627 13.6648 19.6453C13.5343 19.7005 13.394 19.7289 13.2523 19.7289H7.69827C7.62055 19.5316 7.54885 19.3343 7.489 19.1311C7.27974 18.4451 7.17298 17.7319 7.17218 17.0147V10.1157C7.15782 9.49678 7.64736 8.98314 8.26624 8.96787L14.3464 8.96777Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M13.7522 8.96777V19.2327C13.7522 19.3744 13.7238 19.5147 13.6685 19.6453C13.586 19.8458 13.446 20.0174 13.2663 20.1387C13.0865 20.2599 12.875 20.3254 12.6581 20.3268H7.98315C7.88153 20.1295 7.78583 19.9322 7.70218 19.7289C7.62182 19.5335 7.55197 19.334 7.49291 19.1311C7.28364 18.4451 7.17688 17.7319 7.17609 17.0147V10.1157C7.16173 9.49678 7.65127 8.98314 8.27015 8.96787L13.7522 8.96777Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M13.7484 8.96777V18.0371C13.7439 18.6394 13.2567 19.1266 12.6543 19.1311H7.489C7.27973 18.4451 7.17297 17.7319 7.17218 17.0147V10.1157C7.15782 9.49678 7.64736 8.98314 8.26624 8.96787L13.7484 8.96777Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M13.1506 8.96777V18.0371C13.146 18.6394 12.6588 19.1266 12.0565 19.1311H7.489C7.27973 18.4451 7.17297 17.7319 7.17218 17.0147V10.1157C7.15782 9.49678 7.64736 8.98314 8.26624 8.96787L13.1506 8.96777Z"
                fill="black"
            />
            <path
                opacity="0.1"
                d="M14.3468 5.87726V7.76041C14.2452 7.76643 14.1495 7.77246 14.0479 7.77246C13.9463 7.77246 13.8506 7.76643 13.7489 7.76041C13.5471 7.74703 13.347 7.71503 13.1511 7.66481C12.5543 7.52347 11.9994 7.24287 11.5318 6.84595C11.0642 6.44902 10.6972 5.94705 10.4608 5.38109C10.3783 5.18828 10.3143 4.98809 10.2695 4.7832H13.2528C13.856 4.78551 14.3445 5.27395 14.3468 5.87726Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M13.7489 6.47482V7.76017C13.5472 7.7468 13.347 7.71479 13.1511 7.66457C12.5543 7.52322 11.9994 7.24261 11.5319 6.84569C11.0643 6.44877 10.6973 5.94681 10.4609 5.38086H12.6549C13.2582 5.38307 13.7466 5.8716 13.7489 6.47482Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M13.7489 6.47482V7.76017C13.5472 7.7468 13.347 7.71479 13.1511 7.66457C12.5543 7.52322 11.9994 7.24261 11.5319 6.84569C11.0643 6.44877 10.6973 5.94681 10.4609 5.38086H12.6549C13.2582 5.38307 13.7466 5.8716 13.7489 6.47482Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M13.1512 6.47492V7.66467C12.5544 7.52333 11.9995 7.24271 11.5319 6.84577C11.0643 6.44883 10.6973 5.94684 10.4609 5.38086H12.0572C12.6605 5.38317 13.1489 5.8717 13.1512 6.47492Z"
                fill="black"
            />
            <path
                d="M1.09587 5.38096H12.0565C12.6619 5.38096 13.1525 5.8716 13.1525 6.47683V17.4376C13.1525 18.0428 12.6619 18.5335 12.0566 18.5335H1.09587C0.490644 18.5335 0 18.0428 0 17.4376V6.47693C0 5.8715 0.490644 5.38096 1.09587 5.38096Z"
                fill="url(#paint0_linear_5957_75495)"
            />
            <path
                d="M9.45934 9.55195H7.26821V15.5184H5.87229V9.55195H3.69141V8.39453H9.45934V9.55195Z"
                fill="white"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_5957_75495"
                x1="228.485"
                y1="-80.2426"
                x2="1086.77"
                y2="1406.26"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5A62C3" />
                <stop offset="0.5" stop-color="#4D55BD" />
                <stop offset="1" stop-color="#3940AB" />
            </linearGradient>
            <clipPath id="clip0_5957_75495">
                <rect width="25.7071" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
