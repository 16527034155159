export const Arrow = (
    <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.24264 10.2426L9.72792 1.75736M9.72792 1.75736L1.6669 1.3331M9.72792 1.75736L10.1522 9.81838"
            stroke="#4B4B4C"
        />
    </svg>
);
