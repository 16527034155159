import { Grid, Slider, Typography } from '@mui/material';
import React from 'react';
import { Mark } from '@mui/base/SliderUnstyled';

interface IRangeInputProps {
    label: string;
    defaultValue: number;
    min: number;
    max: number;
    onChange?:
        | ((
              event: Event,
              value: number | number[],
              activeThumb: number
          ) => void)
        | undefined;
    valueLabelFormat?: (value: number) => string;
    marks: Mark[];
}

export const RangeInput = (props: IRangeInputProps) => {
    return (
        <Grid
            container
            sx={{
                pt: 2,
                px: 4,
                mt: 1,
                borderRadius: 2,
                backgroundColor: '#F9F9FF',
                border: '1px solid #D7D9EC',
            }}
        >
            <Grid sm={2} pt={1.5} item>
                <Typography fontSize={14} fontWeight={400}>
                    {props.label}
                </Typography>
            </Grid>
            <Grid sm={10} px={4} item>
                <Slider
                    size="small"
                    value={props.defaultValue}
                    min={props.min}
                    max={props.max}
                    sx={{
                        marginBottom: 2.5,
                        '& .MuiSlider-markLabel': {
                            fontSize: 12,
                            fontWeight: 600,
                            top: '20px',
                        },
                    }}
                    valueLabelFormat={props.valueLabelFormat}
                    getAriaValueText={props.valueLabelFormat}
                    aria-label="Small"
                    marks={props.marks}
                    onChange={props.onChange}
                    valueLabelDisplay="auto"
                />
            </Grid>
        </Grid>
    );
};
