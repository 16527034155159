export enum AnalyticsEvent {
    LeftMenuExpanded = 'left_menu_expanded',
    LeftMenuCollapsed = 'left_menu_collapsed',
    LeftMenuItemClick = 'left_menu_item_click',
    SearchResultItemClick = 'search_result_item_click',
    SearchResultRequestTopicClick = 'search_result_request_topic_click',
    DateRangeChanges = 'date_range_changes',
    SourceChange = 'source_change',
    ToggleHighlight = 'toggle_highlight',
    DashboardPageLoad = 'dashboard_page_load',
    DashboardClusterClick = 'dashboard_cluster_click',
    CreateGroupClick = 'create_group_click',
    EditGroupClick = 'edit_group_click',
    PredefinedGroupSelected = 'predefined_group_selected',
    CreateGroupNameCtaClick = 'create_group_name_cta_click',
    EditSpecificGroupClick = 'edit_specific_group_click',
    DeleteGroupClick = 'delete_group_click',
    EditGroupName = 'edit_group_name',
    AddMoreTopicsClick = 'add_more_topics_click',
    ClusterRemoveTopicClick = 'cluster_remove_topic_click',
    ClusterRequestTopicClick = 'cluster_request_topic_click',
    ClusterAddTopicClick = 'cluster_add_topic_click',
    CreateGroup = 'create_group',
    UpdateGropup = 'update_gropup',
    EditGroupBackClick = 'edit_group_back_click',
    EditGroupCloseClick = 'edit_group_close_click',
    CreateGroupBackClick = 'create_group_back_click',
    CreateGroupCloseClick = 'create_group_close_click',
    TabSwitchDashboard = 'tab_switch_dashboard',
    TopicClick = 'topic_click',
    SentimentChartBarClick = 'sentiment_chart_bar_click',
    PotentialIndicatorPageClick = 'potential_indicator_page_click',
    PotentialIndicatorTitleClick = 'potential_indicator_title_click',
    LatestInsightPageClick = 'latest_insight_page_click',
    LatestInsightTitleClick = 'latest_insight_title_click',
    LatestInsightArrowClick = 'latest_insight_arrow_click',
    SourceDistributionClick = 'source_distribution_click',
    SourceDistributionItemTitleClick = 'source_distribution_item_title_click',
    SourceDistributionItemArrowClick = 'source_distribution_item_arrow_click',
    ShowStockPricesToggle = 'show_stock_prices_toggle',
    ViewModeToggle = 'view_mode_toggle',
    DateInsightArrowClick = 'date_insight_arrow_click',
    TopicPageLoad = 'topic_page_load',
    TabSwitchTopic = 'tab_switch_topic',
    TabSwitchInsight = 'tab_switch_Insight',
    InsightPageLoad = 'insight_page_load',
    ArticleListPageClick = 'article_list_page_click',
    GoToArticlePageClick = 'go_to_article_page_click',
    TabSwitchSourceDistribution = 'tab_switch_source_distribution',
    SourceDistributionPageLoad = 'source_distribution_page_load',
    SourceDistributionArticleListPageClick = 'source_distribution_article_list_page_click',
    SourceDistributionGoToArticlePageClick = 'source_distribution_go_to_article_page_click',
    CirReportPageLoad = 'cir_report_page_load',
    CirAlertPageLoad = 'cir_alert_page_load',
    CirReportDateChange = 'cir_report_date_change',
    CirReportGroupChange = 'cir_report_group_change',
    CirReportSourceChange = 'cir_report_source_change',
    CirReportViewModeChange = 'cir_report_view_mode_change',
    CirReportGenerateCirClick = 'cir_report_generate_cir_click',
    CirReportChartBarClick = 'cir_report_chart_bar_click',
    CirReportGenerateTopicTitleClick = 'cir_report_generate_topic_title_click',
    CirAlertToggle = 'cir_alert_toggle',
    TabSwitchDocumentInsight = 'tab_switch_document_insight',
    DocumentInsightPageLoad = 'document_insight_page_load',
    DocumentInsightUploadClick = 'document_insight_upload_click',
    DocumentInsightReloadClick = 'document_insight_reload_click',
    DocumentInsightDropzoneClick = 'document_insight_dropzone_click',
    DocumentInsightFileUploadSuccess = 'document_insight_file_upload_success',
    DocumentInsightFileClick = 'document_insight_file_click',
    DocumentInsightFileDeleteClick = 'document_insight_file_delete_click',
    DocumentInsightFileDeleteConfirm = 'document_insight_file_delete_confirm',
    DocumentInsightFileDeleteCancel = 'document_insight_file_delete_cancel',
    DocumentInsightFileEditAspectsClick = 'document_insight_file_edit_aspects_click',
    DocumentInsightFileAddAspectsClick = 'document_insight_file_add_aspects_click',
    DocumentInsightFileAspectsConfirm = 'document_insight_file_aspects_confirm',
    DocumentInsightFileAspectsCancel = 'document_insight_file_aspects_cancel',
    DocumentInsightFolderExpand = 'document_insight_folder_expand',
    DocumentInsightFolderCollapse = 'document_insight_folder_collapse',
    DocumentInsightFolderInsightClick = 'document_insight_folder_insight_click',
    DocumentInsightFolderMenuIconClick = 'document_insight_folder_menu_icon_click',
    DocumentInsightFolderAddFileClick = 'document_insight_folder_add_file_click',
    DocumentInsightFolderEditFolderClick = 'document_insight_folder_edit_folder_click',
    DocumentInsightFolderDeleteFolderClick = 'document_insight_folder_delete_folder_click',
    DocumentInsightFolderEditAspectsClick = 'document_insight_folder_edit_aspects_click',
    DocumentInsightFolderAddAspectsClick = 'document_insight_folder_add_aspects_click',
    DocumentInsightFolderAspectsConfirm = 'document_insight_folder_aspects_confirm',
    DocumentInsightFolderAspectsCancel = 'document_insight_folder_aspects_cancel',
    DocumentInsightEditFolderFileAddClick = 'document_insight_edit_folder_file_add_click',
    DocumentInsightEditFolderFileRemoveClick = 'document_insight_edit_folder_file_remove_click',
    DocumentInsightEditFolderUpdateClick = 'document_insight_edit_folder_update_click',
    DocumentInsightCreateFolderClick = 'document_insight_create_folder_click',
    DocumentInsightViewLoad = 'document_insight_view_load',
    DocumentInsightViewSummaryModeChange = 'document_insight_view_summary_mode_change',
    DocumentInsightViewMoreIconClick = 'document_insight_view_more_icon_click',
    DocumentInsightViewAddAspectClick = 'document_insight_view_add_aspect_click',
    DocumentInsightViewRemoveAspectClick = 'document_insight_view_remove_aspect_click',
    DocumentInsightViewAddFileClick = 'document_insight_view_add_file_click',
    DocumentInsightViewEditFolderClick = 'document_insight_view_edit_folder_click',
    DocumentInsightViewDeleteClick = 'document_insight_view_delete_click',
    ChatBoxOpen = 'chatbox_open',
    ChatBoxClose = 'chatbox_close',
    ChatBoxQuerySendClick = 'chatbox_query_send_click',
    ChatBoxQueryResponseReceived = 'chatbox_query_response_received',
    ChatBoxQueryAIResponseReceived = 'chatbox_query_ai_response_received',
    ChatBoxQueryResponseSourceClick = 'chatbox_query_response_source_click',
    SignUpOTPRequested = 'signup_otp_requested',
    GoogleSignUp = 'google_sign_up',
    SignUpOTPVerified = 'sign_up_otp_verified',
    SignUpComplete = 'sign_up_complete',
    PurchaseSucessful = 'purchase_successful',
}
