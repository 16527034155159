interface IProps {
    size: number;
}

export const Business = (props: IProps) => (
    <svg
        width={props.size}
        height={props.size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_5400_36774"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
        >
            <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_5400_36774)">
            <path
                d="M7.0835 5.41663V4.0064C7.0835 3.58547 7.22933 3.22917 7.521 2.9375C7.81266 2.64583 8.16897 2.5 8.58991 2.5H11.4104C11.8313 2.5 12.1876 2.64583 12.4793 2.9375C12.771 3.22917 12.9168 3.58547 12.9168 4.0064V5.41663H16.4104C16.8313 5.41663 17.1876 5.56246 17.4793 5.85413C17.771 6.14579 17.9168 6.5021 17.9168 6.92304V15.5768C17.9168 15.9978 17.771 16.3541 17.4793 16.6458C17.1876 16.9374 16.8313 17.0833 16.4104 17.0833H3.58991C3.16897 17.0833 2.81266 16.9374 2.521 16.6458C2.22933 16.3541 2.0835 15.9978 2.0835 15.5768V6.92304C2.0835 6.5021 2.22933 6.14579 2.521 5.85413C2.81266 5.56246 3.16897 5.41663 3.58991 5.41663H7.0835ZM8.33348 5.41663H11.6668V4.0064C11.6668 3.94228 11.6401 3.88352 11.5867 3.8301C11.5333 3.77667 11.4745 3.74996 11.4104 3.74996H8.58991C8.5258 3.74996 8.46703 3.77667 8.4136 3.8301C8.36018 3.88352 8.33348 3.94228 8.33348 4.0064V5.41663ZM16.6668 12.2916H12.0835V12.9967C12.0835 13.2114 12.0116 13.3907 11.8679 13.5344C11.7242 13.6781 11.545 13.7499 11.3302 13.7499H8.67004C8.4553 13.7499 8.27609 13.6781 8.13239 13.5344C7.98868 13.3907 7.91683 13.2114 7.91683 12.9967V12.2916H3.33348V15.5768C3.33348 15.6409 3.36018 15.6997 3.4136 15.7531C3.46703 15.8066 3.5258 15.8333 3.58991 15.8333H16.4104C16.4745 15.8333 16.5333 15.8066 16.5867 15.7531C16.6401 15.6997 16.6668 15.6409 16.6668 15.5768V12.2916ZM9.16681 12.4999H10.8335V10.8333H9.16681V12.4999ZM3.33348 11.0416H7.91683V10.3365C7.91683 10.1218 7.98868 9.94255 8.13239 9.79886C8.27609 9.65515 8.4553 9.58329 8.67004 9.58329H11.3302C11.545 9.58329 11.7242 9.65515 11.8679 9.79886C12.0116 9.94255 12.0835 10.1218 12.0835 10.3365V11.0416H16.6668V6.92304C16.6668 6.85893 16.6401 6.80016 16.5867 6.74673C16.5333 6.69331 16.4745 6.6666 16.4104 6.6666H3.58991C3.5258 6.6666 3.46703 6.69331 3.4136 6.74673C3.36018 6.80016 3.33348 6.85893 3.33348 6.92304V11.0416Z"
                fill="#585960"
            />
        </g>
    </svg>
);
