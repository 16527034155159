import React, { useState, useEffect } from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Box,
} from '@mui/material';
import SingleSelect from './single-select';

interface YearMonthPickerProps {
    selectedYear: number;
    selectedMonth: number;
    onChange: (year: number, month: number) => void;
}

const YearMonthPicker: React.FC<YearMonthPickerProps> = ({
    selectedYear,
    selectedMonth,
    onChange,
}) => {
    const startYear = 2023;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Months are 0-based, so add 1

    const generateOptions = () => {
        const options = [];
        for (let year = startYear; year <= currentYear; year++) {
            const endMonth = year === currentYear ? currentMonth : 12;
            for (let month = 1; month <= endMonth; month++) {
                options.push({
                    value: `${year}-${month.toString().padStart(2, '0')}`,
                    label: `${year} ${new Date(year, month - 1).toLocaleString(
                        'default',
                        { month: 'long' }
                    )}`,
                });
            }
        }
        return options.reverse();
    };

    const options = generateOptions();

    const [selectedValue, setSelectedValue] = useState<{
        value: string;
        label: string;
    } | null>(options[0]);

    const handleChange = (event: { value: string; label: string }) => {
        const [year, month] = event.value.split('-').map(Number);
        setSelectedValue(event);
        onChange(year, month);
    };

    return (
        <Box sx={{ minWidth: 240 }}>
            <SingleSelect
                getOptionText={(v: any) => {
                    return v ? v.label : v;
                }}
                disableCheckbox
                placeholder="Auto Open Behaviour"
                pt={1.5}
                ptContainer={0}
                pb={2.5}
                width={200}
                backgroundColor="#fff"
                mt={0}
                options={options}
                selectedValue={selectedValue}
                onChange={handleChange}
            />
        </Box>
    );
};

export default YearMonthPicker;
