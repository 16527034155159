export const Cards = (
    <svg
        width="320"
        height="238"
        viewBox="0 0 320 238"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M320 209.15H0V209.31H320V209.15Z" fill="#E0E0E0" />
        <path
            d="M275.021 217.494H256.852V217.654H275.021V217.494Z"
            fill="#E0E0E0"
        />
        <path
            d="M252.159 217.57H235.922V217.73H252.159V217.57Z"
            fill="#E0E0E0"
        />
        <path
            d="M177.036 213.871H155.18V214.031H177.036V213.871Z"
            fill="#E0E0E0"
        />
        <path
            d="M218.257 218.822H187.883V218.982H218.257V218.822Z"
            fill="#E0E0E0"
        />
        <path
            d="M91.4158 213.686H62.0078V213.846H91.4158V213.686Z"
            fill="#E0E0E0"
        />
        <path
            d="M104.959 213.686H94.9688V213.846H104.959V213.686Z"
            fill="#E0E0E0"
        />
        <path
            d="M65.711 216.561H39.9766V216.721H65.711V216.561Z"
            fill="#E0E0E0"
        />
        <path
            d="M151.679 180.659H28.1011C27.133 180.658 26.2052 180.272 25.5212 179.589C24.8373 178.905 24.4531 177.978 24.4531 177.012V3.61574C24.4616 2.65497 24.8494 1.73631 25.5324 1.05931C26.2155 0.38231 27.1385 0.00164586 28.1011 0L151.679 0C152.648 0 153.577 0.384308 154.263 1.06838C154.948 1.75245 155.333 2.68026 155.333 3.64768V177.012C155.333 177.979 154.948 178.907 154.263 179.591C153.577 180.275 152.648 180.659 151.679 180.659ZM28.1011 0.127765C27.1755 0.129457 26.2883 0.497686 25.6343 1.15162C24.9804 1.80556 24.6131 2.69178 24.6131 3.61574V177.012C24.6131 177.936 24.9804 178.822 25.6343 179.476C26.2883 180.13 27.1755 180.498 28.1011 180.5H151.679C152.604 180.496 153.491 180.128 154.146 179.474C154.801 178.821 155.17 177.936 155.173 177.012V3.61574C155.17 2.69171 154.801 1.80648 154.146 1.15309C153.491 0.499694 152.604 0.131131 151.679 0.127765H28.1011Z"
            fill="#E0E0E0"
        />
        <path
            d="M290.121 180.659H166.537C165.569 180.656 164.641 180.27 163.957 179.587C163.272 178.904 162.886 177.978 162.883 177.012V3.61574C162.893 2.65438 163.282 1.73573 163.966 1.05891C164.65 0.382086 165.574 0.00162791 166.537 0L290.121 0C291.083 0.00332475 292.004 0.384731 292.686 1.06155C293.367 1.73837 293.754 2.65607 293.763 3.61574V177.012C293.764 177.49 293.67 177.963 293.488 178.405C293.305 178.848 293.037 179.249 292.699 179.588C292.361 179.927 291.959 180.196 291.517 180.38C291.074 180.563 290.6 180.658 290.121 180.659ZM166.537 0.127765C165.611 0.131131 164.725 0.499694 164.07 1.15309C163.415 1.80648 163.046 2.69171 163.043 3.61574V177.012C163.046 177.936 163.415 178.821 164.07 179.474C164.725 180.128 165.611 180.496 166.537 180.5H290.121C291.047 180.496 291.934 180.128 292.588 179.474C293.243 178.821 293.612 177.936 293.616 177.012V3.61574C293.612 2.69171 293.243 1.80648 292.588 1.15309C291.934 0.499694 291.047 0.131131 290.121 0.127765H166.537Z"
            fill="#E0E0E0"
        />
        <path
            d="M210.152 15.5039H45.6719V98.2699H210.152V15.5039Z"
            fill="#E0E0E0"
        />
        <path
            d="M212.3 15.5039H47.8203V98.2699H212.3V15.5039Z"
            fill="#EFEFEF"
        />
        <path
            d="M208.453 19.3809H51.6719V94.3787H208.453V19.3809Z"
            fill="#F4F4F4"
        />
        <path
            d="M63.2812 94.3865L105.803 19.3887H125.726L83.2108 94.3865H63.2812Z"
            fill="white"
        />
        <path
            d="M89.8906 94.3865L132.412 19.3887H152.335L109.82 94.3865H89.8906Z"
            fill="white"
        />
        <path
            d="M145.281 94.3865L187.796 19.3887H196.001L153.486 94.3865H145.281Z"
            fill="white"
        />
        <path
            d="M157.408 19.3828H151.609V94.3807H157.408V19.3828Z"
            fill="#E0E0E0"
        />
        <path
            d="M104.22 19.3809H98.4219V94.3787H104.22V19.3809Z"
            fill="#E0E0E0"
        />
        <path d="M159.439 16.5H153.641V96.8512H159.439V16.5Z" fill="#F4F4F4" />
        <path d="M106.259 16.5H100.461V96.8512H106.259V16.5Z" fill="#F4F4F4" />
        <path
            d="M208.447 19.3809H206.828V94.3787H208.447V19.3809Z"
            fill="#E0E0E0"
        />
        <path
            d="M250.772 122.705C250.772 122.705 239.034 102.512 255.316 86.4395C255.316 86.4395 259.566 89.1992 259.502 92.9171C259.438 96.6351 254.951 97.5741 255.911 100.679C256.871 103.784 261.114 106.824 258.337 109.386C255.559 111.948 258.778 114.235 258.593 117.371C258.407 120.508 250.772 122.705 250.772 122.705Z"
            fill="#E0E0E0"
        />
        <path
            d="M252.623 126.008C252.623 126.008 257.379 103.151 236.847 93.0195C236.847 93.0195 233.692 96.9802 234.927 100.487C236.163 103.995 240.719 103.477 240.783 106.722C240.847 109.968 237.788 114.19 241.238 115.749C244.687 117.308 242.345 120.489 243.516 123.415C244.687 126.341 252.623 126.008 252.623 126.008Z"
            fill="#EBEBEB"
        />
        <path
            d="M242.172 120.201C242.172 120.201 242.377 129.732 250.274 129.732C258.172 129.732 258.383 120.201 258.383 120.201H242.172Z"
            fill="#F4F4F4"
        />
        <path
            d="M187.367 122.309C187.367 122.309 187.636 129.732 198.023 129.732C208.41 129.732 208.686 122.309 208.686 122.309H187.367Z"
            fill="#EBEBEB"
        />
        <path
            d="M211.477 124.148C211.477 124.148 211.681 129.732 219.489 129.732C227.297 129.732 227.496 124.148 227.496 124.148H211.477Z"
            fill="#EBEBEB"
        />
        <path
            d="M194.688 134.363H177.203V204.359H194.688V134.363Z"
            fill="#EBEBEB"
        />
        <path
            d="M266.768 204.354H180.547V209.151H266.768V204.354Z"
            fill="#EBEBEB"
        />
        <path
            d="M270.129 134.357H194.68V204.353H270.129V134.357Z"
            fill="#F4F4F4"
        />
        <path
            d="M263.265 141.129H201.562V165.954H263.265V141.129Z"
            fill="#EBEBEB"
        />
        <path
            d="M263.265 170.477H201.562V195.301H263.265V170.477Z"
            fill="#EBEBEB"
        />
        <path
            d="M239.364 143.85H225.482C225.03 143.85 224.596 143.671 224.276 143.353C223.956 143.034 223.775 142.602 223.773 142.151C223.777 141.7 223.958 141.268 224.278 140.949C224.597 140.629 225.03 140.449 225.482 140.445H239.364C239.816 140.449 240.249 140.629 240.568 140.949C240.888 141.268 241.069 141.7 241.073 142.151C241.071 142.602 240.89 143.034 240.57 143.353C240.25 143.671 239.816 143.85 239.364 143.85Z"
            fill="#F4F4F4"
        />
        <path
            d="M239.364 173.198H225.482C225.03 173.198 224.596 173.019 224.276 172.701C223.956 172.382 223.775 171.95 223.773 171.499C223.777 171.047 223.958 170.615 224.278 170.296C224.597 169.977 225.03 169.796 225.482 169.793H239.364C239.816 169.796 240.249 169.977 240.568 170.296C240.888 170.615 241.069 171.047 241.073 171.499C241.071 171.95 240.89 172.382 240.57 172.701C240.25 173.019 239.816 173.198 239.364 173.198Z"
            fill="#F4F4F4"
        />
        <path
            d="M272.451 129.732H194.672V134.364H272.451V129.732Z"
            fill="#EBEBEB"
        />
        <path
            d="M194.68 129.727H174.75V134.358H194.68V129.727Z"
            fill="#E0E0E0"
        />
        <path
            d="M268.555 27.1562H230.68V70.7112H268.555V27.1562Z"
            fill="#EBEBEB"
        />
        <path
            d="M272.864 27.1562H231.82V70.7112H272.864V27.1562Z"
            fill="#F4F4F4"
        />
        <path d="M269.42 30.5859H235.25V67.2736H269.42V30.5859Z" fill="white" />
        <path
            d="M258.123 39.4785H246.546C244.881 39.4785 243.531 40.8256 243.531 42.4874V55.3724C243.531 57.0342 244.881 58.3813 246.546 58.3813H258.123C259.788 58.3813 261.138 57.0342 261.138 55.3724V42.4874C261.138 40.8256 259.788 39.4785 258.123 39.4785Z"
            fill="#F4F4F4"
        />
        <path
            d="M172.217 168.598H167.82V209.15H172.217V168.598Z"
            fill="#E0E0E0"
        />
        <path
            opacity="0.5"
            d="M172.225 168.598H170.023V209.15H172.225V168.598Z"
            fill="#F4F4F4"
        />
        <path
            d="M152.962 179.956H149.941C150.272 179.676 150.538 179.328 150.721 178.935C150.903 178.542 150.997 178.115 150.997 177.682H115.202C115.203 178.115 115.298 178.543 115.481 178.936C115.665 179.329 115.932 179.677 116.264 179.956H113.282C112.213 179.956 111.187 180.38 110.431 181.135C109.675 181.889 109.25 182.913 109.25 183.98V195.716C109.25 196.783 109.675 197.807 110.431 198.561C111.187 199.316 112.213 199.74 113.282 199.74H152.962C154.031 199.74 155.057 199.316 155.813 198.561C156.569 197.807 156.994 196.783 156.994 195.716V183.98C156.994 182.913 156.569 181.889 155.813 181.135C155.057 180.38 154.031 179.956 152.962 179.956Z"
            fill="#F4F4F4"
        />
        <path
            d="M119.854 188.625H95.7839C94.2852 188.625 93.0703 189.838 93.0703 191.334V197.032C93.0703 198.528 94.2852 199.741 95.7839 199.741H119.854C121.353 199.741 122.568 198.528 122.568 197.032V191.334C122.568 189.838 121.353 188.625 119.854 188.625Z"
            fill="#EBEBEB"
        />
        <path
            d="M121.659 186.172H93.9791C93.4772 186.172 93.0703 186.578 93.0703 187.079V188.114C93.0703 188.615 93.4772 189.021 93.9791 189.021H121.659C122.161 189.021 122.568 188.615 122.568 188.114V187.079C122.568 186.578 122.161 186.172 121.659 186.172Z"
            fill="#E0E0E0"
        />
        <path
            d="M125.289 150.488C125.289 150.488 125.705 163.904 141.449 163.904C157.193 163.904 157.609 150.488 157.609 150.488H125.289Z"
            fill="#EBEBEB"
        />
        <path
            d="M68.9868 163.91H49.3516V168.599H68.9868V163.91Z"
            fill="#E0E0E0"
        />
        <path
            d="M187.456 163.91H68.9922V168.599H187.456V163.91Z"
            fill="#F4F4F4"
        />
        <path
            d="M68.9868 199.74H49.3516V204.429H68.9868V199.74Z"
            fill="#E0E0E0"
        />
        <path
            d="M53.7562 168.598H49.3594V209.15H53.7562V168.598Z"
            fill="#E0E0E0"
        />
        <path
            opacity="0.5"
            d="M53.7485 168.598H51.5469V209.15H53.7485V168.598Z"
            fill="#F4F4F4"
        />
        <path
            d="M71.1859 168.598H66.7891V209.15H71.1859V168.598Z"
            fill="#F4F4F4"
        />
        <path
            d="M68.9907 168.598H66.7891V209.15H68.9907V168.598Z"
            fill="#E0E0E0"
        />
        <path
            d="M187.444 168.598H183.047V209.15H187.444V168.598Z"
            fill="#F4F4F4"
        />
        <path
            d="M185.248 168.598H183.047V209.15H185.248V168.598Z"
            fill="#E0E0E0"
        />
        <path
            d="M187.456 199.74H68.9922V204.429H187.456V199.74Z"
            fill="#F4F4F4"
        />
        <path
            d="M100.588 161.143H76.7031V163.909H100.588V161.143Z"
            fill="#E0E0E0"
        />
        <path
            d="M109.135 161.137H100.578V163.903H109.135V161.137Z"
            fill="#EBEBEB"
        />
        <path
            d="M160.004 238C228.536 238 284.093 234.762 284.093 230.769C284.093 226.775 228.536 223.537 160.004 223.537C91.4709 223.537 35.9141 226.775 35.9141 230.769C35.9141 234.762 91.4709 238 160.004 238Z"
            fill="#F5F5F5"
        />
        <path
            d="M107.07 79.3853L106.526 79.2831C106.417 79.2831 106.347 79.1937 106.372 79.1234L107.076 76.862C107.076 76.7917 107.198 76.7597 107.307 76.7789L107.851 76.8811C107.953 76.8811 108.03 76.977 108.004 77.0408L107.3 79.3087C107.281 79.3853 107.179 79.4173 107.07 79.3853Z"
            fill="#407BFF"
        />
        <path
            opacity="0.3"
            d="M107.07 79.3853L106.526 79.2831C106.417 79.2831 106.347 79.1937 106.372 79.1234L107.076 76.862C107.076 76.7917 107.198 76.7597 107.307 76.7789L107.851 76.8811C107.953 76.8811 108.03 76.977 108.004 77.0408L107.3 79.3087C107.281 79.3853 107.179 79.4173 107.07 79.3853Z"
            fill="black"
        />
        <path
            d="M66.3027 116.635L99.4803 188.962C100.241 190.606 101.622 191.883 103.322 192.514C105.023 193.144 106.904 193.078 108.555 192.329L113.823 189.927L235.787 134.19C237.435 133.433 238.715 132.056 239.349 130.36C239.982 128.663 239.916 126.786 239.167 125.138L209.848 61.2552L206.008 52.8163C205.245 51.1749 203.864 49.9004 202.164 49.27C200.465 48.6396 198.585 48.7042 196.933 49.4497L69.6947 107.583C68.0472 108.34 66.7663 109.716 66.1309 111.411C65.4956 113.107 65.5573 114.984 66.3027 116.635Z"
            fill="#407BFF"
        />
        <path
            opacity="0.1"
            d="M66.3027 116.635L99.4803 188.962C100.241 190.606 101.622 191.883 103.322 192.514C105.023 193.144 106.904 193.078 108.555 192.329L113.823 189.927L235.787 134.19C237.435 133.433 238.715 132.056 239.349 130.36C239.982 128.663 239.916 126.786 239.167 125.138L209.848 61.2552L206.008 52.8163C205.245 51.1749 203.864 49.9004 202.164 49.27C200.465 48.6396 198.585 48.7042 196.933 49.4497L69.6947 107.583C68.0472 108.34 66.7663 109.716 66.1309 111.411C65.4956 113.107 65.5573 114.984 66.3027 116.635Z"
            fill="white"
        />
        <path
            d="M219.448 82.1525L79.7656 145.986L96.1131 181.627L235.795 117.793L219.448 82.1525Z"
            fill="#407BFF"
        />
        <path
            opacity="0.3"
            d="M219.448 82.1525L79.7656 145.986L96.1131 181.627L235.795 117.793L219.448 82.1525Z"
            fill="white"
        />
        <path
            d="M213.669 98.6416L125.398 138.98L131.249 151.736L219.519 111.397L213.669 98.6416Z"
            fill="#407BFF"
        />
        <path
            opacity="0.8"
            d="M213.669 98.6416L125.398 138.98L131.249 151.736L219.519 111.397L213.669 98.6416Z"
            fill="white"
        />
        <path
            d="M214.669 69.164L74.875 133.58L83.6762 152.61L223.47 88.1939L214.669 69.164Z"
            fill="#407BFF"
        />
        <path
            opacity="0.3"
            d="M214.669 69.164L74.875 133.58L83.6762 152.61L223.47 88.1939L214.669 69.164Z"
            fill="black"
        />
        <path
            d="M85.2716 117.543L113.822 189.902L235.787 134.165C237.434 133.408 238.715 132.031 239.348 130.335C239.981 128.639 239.916 126.761 239.166 125.113L209.848 61.2305L89.1372 108.682C87.4522 109.351 86.1004 110.658 85.3763 112.318C84.6522 113.978 84.6146 115.856 85.2716 117.543Z"
            fill="#407BFF"
        />
        <path
            d="M246.705 94.6691L246.102 94.8809L247.101 97.7196L247.705 97.5078L246.705 94.6691Z"
            fill="#407BFF"
        />
        <path
            opacity="0.1"
            d="M246.705 94.6691L246.102 94.8809L247.101 97.7196L247.705 97.5078L246.705 94.6691Z"
            fill="white"
        />
        <path
            d="M241.666 80.4016L241.062 80.6133L244.998 91.8001L245.602 91.5884L241.666 80.4016Z"
            fill="#407BFF"
        />
        <path
            opacity="0.1"
            d="M241.666 80.4016L241.062 80.6133L244.998 91.8001L245.602 91.5884L241.666 80.4016Z"
            fill="white"
        />
        <path
            d="M93.7687 108.931L225.801 62.6481C227.514 62.0531 229.393 62.1592 231.027 62.9433C232.662 63.7274 233.919 65.1257 234.524 66.8324L260.93 141.888C261.527 143.597 261.422 145.473 260.638 147.106C259.853 148.738 258.454 149.995 256.745 150.601L124.713 196.884C122.999 197.476 121.12 197.368 119.486 196.584C117.851 195.801 116.592 194.405 115.983 192.7L89.5767 117.644C88.9804 115.934 89.0866 114.057 89.8721 112.424C90.6575 110.792 92.0585 109.536 93.7687 108.931Z"
            fill="#407BFF"
        />
        <path
            opacity="0.1"
            d="M93.7687 108.931L225.801 62.6481C227.514 62.0531 229.393 62.1592 231.027 62.9433C232.662 63.7274 233.919 65.1257 234.524 66.8324L260.93 141.888C261.527 143.597 261.422 145.473 260.638 147.106C259.853 148.738 258.454 149.995 256.745 150.601L124.713 196.884C122.999 197.476 121.12 197.368 119.486 196.584C117.851 195.801 116.592 194.405 115.983 192.7L89.5767 117.644C88.9804 115.934 89.0866 114.057 89.8721 112.424C90.6575 110.792 92.0585 109.536 93.7687 108.931Z"
            fill="white"
        />
        <path
            d="M244.635 95.5549L99.6875 146.363L111.175 179.014L256.122 128.205L244.635 95.5549Z"
            fill="#407BFF"
        />
        <path
            opacity="0.3"
            d="M244.635 95.5549L99.6875 146.363L111.175 179.014L256.122 128.205L244.635 95.5549Z"
            fill="white"
        />
        <path
            d="M227.275 79.6651L197.312 90.168L198.952 94.8271L228.915 84.3242L227.275 79.6651Z"
            fill="#407BFF"
        />
        <path
            opacity="0.8"
            d="M227.275 79.6651L197.312 90.168L198.952 94.8271L228.915 84.3242L227.275 79.6651Z"
            fill="white"
        />
        <path
            d="M247.352 136.722L223.312 145.148L224.169 147.583L248.208 139.157L247.352 136.722Z"
            fill="#407BFF"
        />
        <path
            opacity="0.8"
            d="M247.352 136.722L223.312 145.148L224.169 147.583L248.208 139.157L247.352 136.722Z"
            fill="white"
        />
        <path
            d="M248.993 141.38L218.016 152.238L218.872 154.673L249.85 143.815L248.993 141.38Z"
            fill="#407BFF"
        />
        <path
            opacity="0.8"
            d="M248.993 141.38L218.016 152.238L218.872 154.673L249.85 143.815L248.993 141.38Z"
            fill="white"
        />
        <path
            d="M138.536 176.95L123.047 182.379L124.89 187.617L140.378 182.187L138.536 176.95Z"
            fill="#407BFF"
        />
        <path
            opacity="0.8"
            d="M138.536 176.95L123.047 182.379L124.89 187.617L140.378 182.187L138.536 176.95Z"
            fill="white"
        />
        <path
            d="M221.352 132.088L239.439 125.751C240.392 125.412 241.173 124.712 241.612 123.801C242.051 122.891 242.111 121.844 241.781 120.89C241.442 119.938 240.74 119.158 239.828 118.721C238.916 118.283 237.868 118.222 236.911 118.551L218.818 124.895C217.865 125.234 217.084 125.934 216.645 126.845C216.206 127.755 216.146 128.802 216.476 129.756C216.816 130.708 217.52 131.488 218.433 131.924C219.347 132.361 220.396 132.42 221.352 132.088Z"
            fill="#407BFF"
        />
        <path
            d="M190.37 142.948L208.462 136.605C209.409 136.264 210.183 135.567 210.62 134.663C211.057 133.759 211.121 132.719 210.798 131.769C210.458 130.818 209.755 130.04 208.843 129.604C207.931 129.167 206.884 129.107 205.928 129.437L187.842 135.78C186.888 136.119 186.107 136.82 185.669 137.73C185.23 138.641 185.169 139.687 185.499 140.642C185.845 141.588 186.549 142.36 187.461 142.791C188.372 143.223 189.417 143.279 190.37 142.948Z"
            fill="#407BFF"
        />
        <path
            d="M159.404 153.807L177.496 147.464C178.44 147.121 179.212 146.423 179.647 145.519C180.081 144.615 180.143 143.577 179.82 142.628C179.479 141.677 178.777 140.899 177.865 140.463C176.953 140.026 175.905 139.966 174.949 140.296L156.844 146.614C155.891 146.954 155.112 147.656 154.675 148.566C154.237 149.476 154.177 150.522 154.508 151.476C154.848 152.432 155.555 153.215 156.473 153.652C157.39 154.089 158.445 154.145 159.404 153.807Z"
            fill="#407BFF"
        />
        <path
            d="M128.409 164.669L146.501 158.325C147.449 157.986 148.225 157.29 148.663 156.385C149.101 155.481 149.166 154.441 148.844 153.489C148.503 152.539 147.801 151.761 146.889 151.324C145.977 150.888 144.929 150.828 143.973 151.158L125.881 157.501C124.927 157.84 124.146 158.541 123.708 159.451C123.269 160.361 123.208 161.408 123.538 162.363C123.884 163.308 124.589 164.081 125.5 164.512C126.411 164.944 127.456 165 128.409 164.669Z"
            fill="#407BFF"
        />
        <g opacity="0.8">
            <path
                d="M221.352 132.088L239.439 125.751C240.392 125.412 241.173 124.712 241.612 123.801C242.051 122.891 242.111 121.844 241.781 120.89C241.442 119.938 240.74 119.158 239.828 118.721C238.916 118.283 237.868 118.222 236.911 118.551L218.818 124.895C217.865 125.234 217.084 125.934 216.645 126.845C216.206 127.755 216.146 128.802 216.476 129.756C216.816 130.708 217.52 131.488 218.433 131.924C219.347 132.361 220.396 132.42 221.352 132.088Z"
                fill="white"
            />
            <path
                d="M190.37 142.948L208.462 136.605C209.409 136.264 210.183 135.567 210.62 134.663C211.057 133.759 211.121 132.719 210.798 131.769C210.458 130.818 209.755 130.04 208.843 129.604C207.931 129.167 206.884 129.107 205.928 129.437L187.842 135.78C186.888 136.119 186.107 136.82 185.669 137.73C185.23 138.641 185.169 139.687 185.499 140.642C185.845 141.588 186.549 142.36 187.461 142.791C188.372 143.223 189.417 143.279 190.37 142.948Z"
                fill="white"
            />
            <path
                d="M159.404 153.807L177.496 147.464C178.44 147.121 179.212 146.423 179.647 145.519C180.081 144.615 180.143 143.577 179.82 142.628C179.479 141.677 178.777 140.899 177.865 140.463C176.953 140.026 175.905 139.966 174.949 140.296L156.844 146.614C155.891 146.954 155.112 147.656 154.675 148.566C154.237 149.476 154.177 150.522 154.508 151.476C154.848 152.432 155.555 153.215 156.473 153.652C157.39 154.089 158.445 154.145 159.404 153.807Z"
                fill="white"
            />
            <path
                d="M128.409 164.669L146.501 158.325C147.449 157.986 148.225 157.29 148.663 156.385C149.101 155.481 149.166 154.441 148.844 153.489C148.503 152.539 147.801 151.761 146.889 151.324C145.977 150.888 144.929 150.828 143.973 151.158L125.881 157.501C124.927 157.84 124.146 158.541 123.708 159.451C123.269 160.361 123.208 161.408 123.538 162.363C123.884 163.308 124.589 164.081 125.5 164.512C126.411 164.944 127.456 165 128.409 164.669Z"
                fill="white"
            />
        </g>
        <path
            d="M230.746 97.4403L214.648 103.083C213.28 103.563 212.561 105.058 213.041 106.422L216.396 115.958C216.876 117.323 218.374 118.04 219.741 117.561L235.84 111.918C237.207 111.439 237.926 109.944 237.446 108.579L234.091 99.0437C233.611 97.6789 232.114 96.961 230.746 97.4403Z"
            fill="#407BFF"
        />
        <path
            opacity="0.3"
            d="M230.746 97.4403L214.648 103.083C213.28 103.563 212.561 105.058 213.041 106.422L216.396 115.958C216.876 117.323 218.374 118.04 219.741 117.561L235.84 111.918C237.207 111.439 237.926 109.944 237.446 108.579L234.091 99.0437C233.611 97.6789 232.114 96.961 230.746 97.4403Z"
            fill="black"
        />
        <path
            d="M133.594 115.159C133.193 114.013 132.569 112.959 131.759 112.054C130.948 111.15 129.967 110.414 128.872 109.888C127.776 109.362 126.588 109.057 125.374 108.991C124.161 108.924 122.946 109.096 121.799 109.499L108.794 114.373L108.628 114.418L108.244 114.577L108.007 114.667C105.907 115.613 104.253 117.331 103.389 119.463C102.524 121.594 102.516 123.977 103.365 126.115C104.215 128.252 105.856 129.981 107.949 130.943C110.043 131.904 112.426 132.023 114.605 131.276L126.919 127.188C127.274 127.108 127.624 127.01 127.968 126.894C128.223 126.805 128.473 126.703 128.717 126.587C130.81 125.631 132.47 123.929 133.372 121.815C134.274 119.701 134.353 117.328 133.594 115.159Z"
            fill="#407BFF"
        />
        <path
            opacity="0.8"
            d="M133.594 115.159C133.193 114.013 132.569 112.959 131.759 112.054C130.948 111.15 129.967 110.414 128.872 109.888C127.776 109.362 126.588 109.057 125.374 108.991C124.161 108.924 122.946 109.096 121.799 109.499L108.794 114.373L108.628 114.418L108.244 114.577L108.007 114.667C105.907 115.613 104.253 117.331 103.389 119.463C102.524 121.594 102.516 123.977 103.365 126.115C104.215 128.252 105.856 129.981 107.949 130.943C110.043 131.904 112.426 132.023 114.605 131.276L126.919 127.188C127.274 127.108 127.624 127.01 127.968 126.894C128.223 126.805 128.473 126.703 128.717 126.587C130.81 125.631 132.47 123.929 133.372 121.815C134.274 119.701 134.353 117.328 133.594 115.159Z"
            fill="white"
        />
    </svg>
);
