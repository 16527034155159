export const BackArrowCircle = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.5"
            y="0.5"
            width="23"
            height="23"
            rx="11.5"
            stroke="#29319B"
        />
        <path
            d="M8.61958 12.7649L12.3949 16.5402C12.549 16.6943 12.6228 16.8741 12.6164 17.0796C12.61 17.285 12.5297 17.4648 12.3756 17.6189C12.2215 17.7601 12.0417 17.834 11.8363 17.8404C11.6308 17.8468 11.451 17.773 11.297 17.6189L6.21188 12.5338C6.13483 12.4568 6.08026 12.3733 6.04815 12.2834C6.01605 12.1935 6 12.0972 6 11.9945C6 11.8918 6.01605 11.7954 6.04815 11.7056C6.08026 11.6157 6.13483 11.5322 6.21188 11.4552L11.297 6.37008C11.4382 6.22883 11.6148 6.1582 11.8266 6.1582C12.0385 6.1582 12.2215 6.22883 12.3756 6.37008C12.5297 6.52417 12.6067 6.70716 12.6067 6.91904C12.6067 7.13092 12.5297 7.3139 12.3756 7.46799L8.61958 11.224H17.2295C17.4478 11.224 17.6308 11.2979 17.7785 11.4455C17.9262 11.5932 18 11.7762 18 11.9945C18 12.2128 17.9262 12.3958 17.7785 12.5434C17.6308 12.6911 17.4478 12.7649 17.2295 12.7649H8.61958Z"
            fill="#29319B"
        />
    </svg>
);
