import { IPlatformInterface } from './interfaces/platform.interface';
import { PlatformService } from './platform/platform.service';

export class CookieService {
    platform: IPlatformInterface = new PlatformService();

    get = async (key: string) => {
        return await this.platform.getCookie(key);
    };
    set = async (key: string, value: string) => {
        return await this.platform.setCookie(key, value);
    };
}
