import { useContext } from 'react';
import { Constants } from '../constants';
import { AppContext } from '../providers/app-provider';
import { Granularity, useInsightsService } from './insights.service';
import {
    ArticleInsight,
    PotentialSentiment,
    SourceDistributionArticleInsightsRequest,
    SourceDistributionArticleInsightsResponse,
    SourceDistributionIndicatorsResponse,
} from './interfaces';
import { NetworkRequestMethod, useNetworkAPI } from './network.service';

export const useDistributionService = () => {
    const { triggerRequest } = useNetworkAPI();
    const { processPotentialIndicatorsResponse } = useInsightsService();
    const appContext = useContext(AppContext);

    const fetchSourceDistributionArticleDetails = async (
        topicSubscriptionIds: string[],
        granularity: Granularity,
        metadataSearchTerm: string,
        pageNo: number
    ): Promise<{
        data: ArticleInsight[];
        count: string;
    }> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: SourceDistributionArticleInsightsRequest = {
                topicSubscriptionIds,
                startDate: appContext.filters.date.startDate,
                endDate: appContext.filters.date.endDate,
                categoryId: appContext?.category ? appContext?.category : '',
                metadataSearchTerm,
                granularity: granularity,
            };
            const result: {
                body: SourceDistributionArticleInsightsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getTopicSourceArticleDetails + pageNo,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return {
                    data: result.body.data.insights,
                    count: result.headers['x-pi-content-length'],
                };
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchSourceDistributionPotentialIndicators = async (
        topicSubscriptionIds: string[],
        granularity: Granularity,
        metadataSearchTerm: string,
        pageNo: number
    ): Promise<{ data: (PotentialSentiment | null)[][]; count: string }> => {
        try {
            if (!appContext) throw '';

            const headers = {
                'Content-Type': 'application/json',
            };
            const body: SourceDistributionArticleInsightsRequest = {
                topicSubscriptionIds,
                startDate: appContext.filters.date.startDate,
                endDate: appContext.filters.date.endDate,
                categoryId: appContext?.category ? appContext?.category : '',
                metadataSearchTerm,
                granularity: granularity,
            };
            const result: {
                body: SourceDistributionIndicatorsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url:
                        Constants.getSourceDistributionPotentialIndicators +
                        pageNo,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return {
                    data: processPotentialIndicatorsResponse(result.body.data),
                    count: result.headers['x-pi-content-length'],
                };
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    return {
        fetchSourceDistributionArticleDetails,
        fetchSourceDistributionPotentialIndicators,
    };
};
