interface IProps {
    color?: string;
    size?: number;
}
export const Delete = (props: IProps) => {
    return (
        <svg
            width={props.size || '20'}
            height={props.size || '20'}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.9987 1.04163C7.81257 1.04163 6.04036 2.81383 6.04036 4.99996V5.20829H3.33203C2.98685 5.20829 2.70703 5.48811 2.70703 5.83329C2.70703 6.17847 2.98685 6.45829 3.33203 6.45829H16.6654C17.0105 6.45829 17.2904 6.17847 17.2904 5.83329C17.2904 5.48811 17.0105 5.20829 16.6654 5.20829H13.957V4.99996C13.957 2.81383 12.1848 1.04163 9.9987 1.04163ZM9.9987 2.29163C11.4945 2.29163 12.707 3.50419 12.707 4.99996V5.20829H7.29036V4.99996C7.29036 3.50419 8.50293 2.29163 9.9987 2.29163Z"
                fill={props.color || '#4B4B4C'}
            />
            <path
                d="M4.78756 7.44084C4.75491 7.09721 4.44988 6.84511 4.10625 6.87776C3.76262 6.91041 3.51052 7.21545 3.54317 7.55908C3.62189 8.38764 3.76397 9.40855 3.9466 10.7208L4.18122 12.4067C4.40553 14.019 4.53274 14.9334 4.80794 15.6824C5.32012 17.0765 6.23374 18.194 7.40874 18.6898C8.0468 18.9591 8.77663 18.9588 9.86314 18.9583H10.1343C11.2208 18.9588 11.9506 18.9591 12.5886 18.6898C13.7637 18.194 14.6773 17.0765 15.1895 15.6824C15.4647 14.9334 15.5919 14.019 15.8162 12.4067L16.0508 10.7209C16.2334 9.40856 16.3755 8.38765 16.4542 7.55908C16.4869 7.21545 16.2348 6.91041 15.8911 6.87776C15.5475 6.84511 15.2425 7.09721 15.2098 7.44084C15.134 8.23915 14.9958 9.23332 14.8107 10.5634L14.5921 12.1342C14.3499 13.8739 14.2391 14.6446 14.0161 15.2514C13.5862 16.4215 12.8714 17.2138 12.1027 17.5382C11.7275 17.6965 11.273 17.7083 9.9987 17.7083C8.72439 17.7083 8.26989 17.6965 7.89473 17.5382C7.12603 17.2138 6.41117 16.4215 5.98126 15.2514C5.75833 14.6446 5.64745 13.8739 5.40535 12.1342L5.18673 10.5634C5.00163 9.23332 4.86341 8.23915 4.78756 7.44084Z"
                fill={props.color || '#4B4B4C'}
            />
            <path
                d="M8.95703 8.33329C8.95703 7.98812 8.67721 7.70829 8.33203 7.70829C7.98685 7.70829 7.70703 7.98812 7.70703 8.33329V15C7.70703 15.3451 7.98685 15.625 8.33203 15.625C8.67721 15.625 8.95703 15.3451 8.95703 15V8.33329Z"
                fill={props.color || '#4B4B4C'}
            />
            <path
                d="M12.2904 8.33329C12.2904 7.98812 12.0105 7.70829 11.6654 7.70829C11.3202 7.70829 11.0404 7.98812 11.0404 8.33329V15C11.0404 15.3451 11.3202 15.625 11.6654 15.625C12.0105 15.625 12.2904 15.3451 12.2904 15V8.33329Z"
                fill={props.color || '#4B4B4C'}
            />
        </svg>
    );
};
