import {
    Box,
    Button,
    Grid,
    Tooltip,
    Typography,
    Rating,
    TooltipProps,
    styled,
    tooltipClasses,
    List,
    ListItemButton,
    ListItemText,
    Collapse,
    Avatar,
    Divider,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    CorporateCultureData,
    CorporateCulturePro,
    IQueryEntitiesesponseData,
    LiveInsightsQueryResponse,
} from '../../../services/interfaces';
import { AccountCircle } from '../../../components/icons/account-circle';
import { Info } from '../../../components/icons/info';
import { DoubleStar } from '../../../components/icons/double-star';
import { useChatbotService } from '../../../services/chatbot.service';
import { BorderedBox } from '../../../components/core/box';
import { UtilsService } from '../../../services/utils.service';
import { NoDataImage } from '../../../components/images/no-data';
import { NegativeInsight } from '../../../components/icons/negative-insight';
import { PositiveInsight } from '../../../components/icons/positive-insight';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { RecommendTiles } from '../../../components/icons/recommend-tiles';
import { CircleProgressBar } from '../../../components/icons/circle-progress-bar';
import AddToWatchList from '../../add-to-watchlist';
import { AppContext } from '../../../providers/app-provider';

interface IProps {
    entities: IQueryEntitiesesponseData[];
    onFetched: () => void;
    query?: string;
}

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF',
        color: 'black',
        maxWidth: 700,
        padding: '24px',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #D7D9EC',
        boxShadow: '0px 10px 20px -10px rgba(0, 0, 0, 0.50)',
    },
}));

export const CorporateCulture = (props: IProps) => {
    const [searchParam] = useSearchParams();
    const appContext = useContext(AppContext);
    const { fetchChatbotCorporateCultureResponse } = useChatbotService();

    const [open, setOpen] = React.useState<string>('');
    const [corporateData, setCorporateData] = useState<CorporateCultureData[]>(
        []
    );
    const [selectedEntity, setSelectedEntity] =
        useState<CorporateCultureData | null>(null);
    const ratingCategoryKeys = [
        'workLifeBalance',
        'cultureAndValues',
        'diversityAndInclusion',
        'seniorLeadership',
        'careerOpportunities',
        'compensationAndBenefits',
    ];

    let multipleEntitities =
        corporateData[0]?.query &&
        (corporateData.filter((e) => !!e.query).length > 1 ||
            corporateData[0]?.query !==
                (props.query?.toLowerCase().trim() ||
                    searchParam.get('query')));

    useEffect(() => {
        refreshCorporateCulture();
    }, [props.query, searchParam.get('query'), props.entities]);

    const refreshCorporateCulture = async () => {
        let message = props.query || searchParam.get('query');
        if (!message) return;
        await fetchChatbotCorporateCultureResponse(
            message,
            props.entities.filter((e) => e.entityGroup == 'ORG'),
            (data: string) => {
                if (data) {
                    processQueryResponse(data.split('\n'));
                }
            },
            true
        );
    };

    useEffect(() => {
        if (corporateData.filter((e) => !!e.results.companyName).length > 0) {
            setSelectedEntity(corporateData[0]);
        }
    }, [corporateData]);

    const processQueryResponse = (responses: string[]) => {
        let _entities: CorporateCultureData[] = [];
        for (let res of responses) {
            try {
                let parsedRes: LiveInsightsQueryResponse = JSON.parse(res);
                switch (parsedRes.chunk) {
                    case 'GLASSDOOR':
                        let data = parsedRes.data as CorporateCultureData;
                        _entities.push(data);
                        break;
                    case 'TERMINATION':
                        setCorporateData(_entities);
                        props.onFetched();
                        break;
                }
            } catch (error) {}
        }
    };

    const handleClick = (id: string) => {
        if (open == id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };

    const camelCaseToWords = (str: string) => {
        if (str === 'recommendToFriend') {
            str = str.split('T')[0];
        }

        let result = str.replace(/([A-Z])/g, ' $1');
        if (result === 'ceo Rating') {
            result = 'CEO Rating';
        }
        return result.charAt(0).toUpperCase() + result.slice(1);
    };

    const getHighlightSentence = (
        sentence: string,
        position: number,
        length: number,
        type: string
    ) => {
        return (
            <Typography
                pl={2}
                fontSize={14}
                color="#4B4B4C"
                lineHeight={'20px'}
                variant="caption"
            >
                {sentence.slice(0, position)}
                <span
                    style={
                        type === 'pros'
                            ? {
                                  color: '#19AF55',
                                  fontWeight: '500',
                              }
                            : { color: '#D82F44', fontWeight: '500' }
                    }
                >
                    {sentence.slice(position, length + position)}
                </span>

                {sentence.slice(length + position)}
            </Typography>
        );
    };

    return (
        <Box>
            {multipleEntitities && (
                <Box
                    px={6}
                    py={4}
                    justifyContent="space-between"
                    sx={{
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                        borderBottom: '1px solid #D7D9EC',
                    }}
                >
                    {corporateData.filter((e) => !!e.results.companyName)
                        .length > 0 && (
                        <Typography
                            mt={3}
                            variant="subtitle1"
                            sx={{
                                backgroundColor: 'rgba(229, 148, 19, 0.20)',
                                width: 'fit-content',
                                paddingX: '8px',
                                paddingY: '4px',
                                borderRadius: '4px',
                            }}
                        >
                            Multiple Entities detected in the searched query.
                            Explore related entities for deeper analysis from
                            your query below.
                        </Typography>
                    )}

                    {corporateData.filter((e) => !!e.results.companyName)
                        .length > 0 && (
                        <Box display="flex">
                            <Typography
                                mr={4}
                                my={1}
                                variant="h6"
                                color={'#4b4b4c'}
                            >
                                Detected Entities:{' '}
                            </Typography>
                            {corporateData.map((e) => (
                                <Box
                                    sx={{
                                        backgroundColor:
                                            selectedEntity?.query == e.query
                                                ? '#EBECF6'
                                                : '#fff',
                                        border: `1px solid ${
                                            selectedEntity?.query == e.query
                                                ? '#29319B'
                                                : '#D7D9EC'
                                        }`,
                                        borderRadius: 1,
                                        mr: 2,
                                        color:
                                            selectedEntity?.query == e.query
                                                ? '#29319B'
                                                : '#4B4B4C',
                                        py: 0,
                                        px: 2,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Button
                                        sx={{}}
                                        onClick={() => {
                                            setSelectedEntity(e);
                                        }}
                                    >
                                        <Typography variant="h6">
                                            {e.query}
                                        </Typography>
                                    </Button>
                                    <Divider orientation="vertical" />
                                    <Box ml={2}>
                                        <AddToWatchList
                                            query={e.query}
                                            entity={true}
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
            )}

            <Box
                px={6}
                sx={{
                    maxHeight: multipleEntitities
                        ? `calc(100vh - ${
                              appContext?.accountActionBannerVisible
                                  ? '22px'
                                  : '0px'
                          } - 380px)`
                        : `calc(100vh - ${
                              appContext?.accountActionBannerVisible
                                  ? '22px'
                                  : '0px'
                          } - 255px)`,
                    overflowY: 'auto',
                }}
            >
                {corporateData.filter((e) => !!e.results.companyName).length ==
                    0 && (
                    <Box textAlign={'center'} px={4} py={20}>
                        <Box>{NoDataImage}</Box>
                        <br />
                        <Typography variant="h4">
                            Sorry, No data available
                        </Typography>
                        <Typography fontSize={14} fontWeight={400} mt={2}>
                            No data available for this entities. Please try with
                            some different query
                        </Typography>
                    </Box>
                )}

                {selectedEntity && (
                    <Box>
                        <BorderedBox
                            p={6}
                            sx={{ backgroundColor: '#f9f9ff80' }}
                        >
                            <Grid spacing={3} p={0} container>
                                <Grid item sm={4} pt={0}>
                                    <Box
                                        sx={{
                                            borderRight: '1px solid #D7D9EC',
                                        }}
                                    >
                                        <Typography mb={3.5} variant="h4">
                                            Overall Review
                                        </Typography>
                                        <Grid container alignItems="center">
                                            <Grid
                                                item
                                                sx={{
                                                    height: '100px',
                                                    width: '100px',
                                                }}
                                                sm={5}
                                            >
                                                <img
                                                    src={
                                                        selectedEntity.results
                                                            .companyInfo.logo
                                                    }
                                                    style={{
                                                        borderRadius: '8px',
                                                    }}
                                                    alt="Company Logo"
                                                />
                                            </Grid>
                                            <Grid item sm={7}>
                                                <Box
                                                    mr={6}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: '#ECB73B',
                                                            fontSize: '60px',
                                                            lineHeight: '72px',
                                                        }}
                                                    >
                                                        {selectedEntity.results.rating.overallRating.toFixed(
                                                            1
                                                        )}
                                                    </Typography>
                                                    <Rating
                                                        value={
                                                            selectedEntity
                                                                .results.rating
                                                                .overallRating
                                                        }
                                                        precision={0.5}
                                                        readOnly
                                                        size="large"
                                                    />
                                                    <Typography
                                                        variant="subtitle1"
                                                        mt={1}
                                                    >
                                                        {
                                                            selectedEntity
                                                                .results
                                                                .totalReviews
                                                        }{' '}
                                                        Total Reviews
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item sm={8} pt={0}>
                                    <Typography
                                        mb={3.5}
                                        variant="h4"
                                        align="center"
                                    >
                                        Ratings by category
                                    </Typography>
                                    <Grid
                                        container
                                        rowSpacing={4}
                                        columnSpacing={12}
                                    >
                                        {Object.keys(
                                            selectedEntity.results.rating
                                        )
                                            .filter((key) =>
                                                ratingCategoryKeys.includes(key)
                                            )
                                            .map((category) => (
                                                <Grid item sm={4}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            lineHeight: '24px',
                                                        }}
                                                    >
                                                        <DoubleStar
                                                            rating={
                                                                selectedEntity
                                                                    .results
                                                                    .rating[
                                                                    category as keyof typeof selectedEntity.results.rating
                                                                ]
                                                            }
                                                        />
                                                        <Typography
                                                            variant="h6"
                                                            align="center"
                                                            ml={1}
                                                        >
                                                            {selectedEntity.results.rating[
                                                                category as keyof typeof selectedEntity.results.rating
                                                            ]?.toFixed(1)}
                                                        </Typography>
                                                    </Box>

                                                    <Typography
                                                        variant="subtitle1"
                                                        align="center"
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {camelCaseToWords(
                                                            category
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </BorderedBox>

                        <BorderedBox
                            p={6}
                            mt={6}
                            sx={{ backgroundColor: '#f9f9ff80' }}
                        >
                            <Box>
                                <Grid spacing={3} p={0} container>
                                    <Grid item sm={6} pt={0}>
                                        <Box
                                            sx={{
                                                borderRight:
                                                    '1px solid #D7D9EC',
                                                display: 'flex',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    width: '50%',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <CircleProgressBar
                                                        percent={
                                                            selectedEntity
                                                                .results.rating
                                                                .ceoRating
                                                                ? selectedEntity
                                                                      .results
                                                                      .rating
                                                                      .ceoRating *
                                                                  100
                                                                : 0
                                                        }
                                                        image={
                                                            selectedEntity
                                                                .results.ceo
                                                                ?.image
                                                                ? selectedEntity
                                                                      .results
                                                                      .ceo.image
                                                                : 'NA'
                                                        }
                                                    />
                                                    <Typography
                                                        align="center"
                                                        variant="h6"
                                                    >
                                                        {selectedEntity.results
                                                            .ceo?.name
                                                            ? selectedEntity
                                                                  .results.ceo
                                                                  .name
                                                            : 'N/A'}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    pl={3}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="h3"
                                                        sx={{
                                                            color: '#ECB73B',
                                                        }}
                                                    >
                                                        {selectedEntity.results
                                                            .rating.ceoRating
                                                            ? `${(
                                                                  selectedEntity
                                                                      .results
                                                                      .rating
                                                                      .ceoRating *
                                                                  100
                                                              ).toFixed(0)}%`
                                                            : 'N/A'}
                                                    </Typography>
                                                    <Typography variant="subtitle1">
                                                        Approved of CEO
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    width: '50%',
                                                }}
                                            >
                                                <CircleProgressBar
                                                    percent={
                                                        selectedEntity.results
                                                            .rating
                                                            .ceoRatingsCount
                                                            ? 100
                                                            : 0
                                                    }
                                                    rating={
                                                        selectedEntity.results
                                                            .rating
                                                            .ceoRatingsCount
                                                            ? selectedEntity
                                                                  .results
                                                                  .rating
                                                                  .ceoRatingsCount
                                                            : 'N/A'
                                                    }
                                                />
                                                <Typography
                                                    align="center"
                                                    variant="h6"
                                                >
                                                    CEO Ratings Count
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        sm={6}
                                        pt={0}
                                        sx={{ display: 'flex' }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                width: '50%',
                                            }}
                                        >
                                            <CircleProgressBar
                                                percent={
                                                    selectedEntity.results
                                                        .rating
                                                        .recommendToFriend
                                                        ? selectedEntity.results
                                                              .rating
                                                              .recommendToFriend *
                                                          100
                                                        : 'NA'
                                                }
                                            />
                                            <Typography
                                                align="center"
                                                variant="h6"
                                            >
                                                Recommend to a Friend
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                width: '50%',
                                            }}
                                        >
                                            <CircleProgressBar
                                                percent={
                                                    selectedEntity.results
                                                        .rating.businessOutlook
                                                        ? selectedEntity.results
                                                              .rating
                                                              .businessOutlook *
                                                          100
                                                        : 'NA'
                                                }
                                            />
                                            <Typography
                                                align="center"
                                                variant="h6"
                                            >
                                                Business Outlook
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </BorderedBox>

                        {(selectedEntity.results.highlightedReviews.pros
                            .length > 0 ||
                            selectedEntity.results.highlightedReviews.cons
                                .length > 0) && (
                            <BorderedBox
                                p={4}
                                mt={6}
                                sx={{ backgroundColor: '#f9f9ff80' }}
                            >
                                <Typography fontWeight={600} variant="h4">
                                    Sentiment Highlights on Reviews
                                </Typography>

                                <Grid spacing={4} pt={2} container>
                                    <Grid item sm={6}>
                                        <Typography variant="h6">
                                            Pros
                                        </Typography>
                                        <Box pt={2} pl={'0px !important'}>
                                            {selectedEntity.results.highlightedReviews.pros.map(
                                                (
                                                    h: CorporateCulturePro,
                                                    index
                                                ) => (
                                                    <Box
                                                        my={1}
                                                        key={index}
                                                        display="flex"
                                                    >
                                                        <Box
                                                            pt={0.5}
                                                            sx={{ width: 16 }}
                                                        >
                                                            {PositiveInsight}
                                                        </Box>
                                                        {getHighlightSentence(
                                                            h.sentence,
                                                            h.indexing[0]
                                                                .position,
                                                            h.indexing[0]
                                                                .length,
                                                            'pros'
                                                        )}
                                                    </Box>
                                                )
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Typography variant="h6">
                                            Cons
                                        </Typography>
                                        <Box pt={2}>
                                            {selectedEntity.results.highlightedReviews.cons.map(
                                                (h: CorporateCulturePro) => (
                                                    <Box my={1} display="flex">
                                                        <Box
                                                            pt={1}
                                                            sx={{ width: 16 }}
                                                        >
                                                            {NegativeInsight}
                                                        </Box>
                                                        {getHighlightSentence(
                                                            h.sentence,
                                                            h.indexing[0]
                                                                .position,
                                                            h.indexing[0]
                                                                .length,
                                                            'cons'
                                                        )}
                                                    </Box>
                                                )
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </BorderedBox>
                        )}

                        {/* <BorderedBox p={6} mt={4} sx={{ backgroundColor: '#f9f9ff80' }}>
                    <Box>
                        <Typography fontWeight={600} variant="h4">
                            Similar Companies
                        </Typography>
                        <Grid spacing={4} p={0} container>
                            {similarCompanies.map((company, index) => (
                                <Grid key={index} item sm={3} pt={0}>
                                    <BorderedBox
                                        sx={{ backgroundColor: '#ffffff' }}
                                    >
                                        <Grid
                                            container
                                            p={3}
                                            alignItems="center"
                                        >
                                            <Grid item sm={5} xl={4}>
                                                <BorderedBox
                                                    sx={{
                                                        height: '60px',
                                                        width: '60px',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {company.logo}
                                                </BorderedBox>
                                            </Grid>
                                            <Grid item sm={7} xl={8}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        lineHeight: '24px',
                                                    }}
                                                >
                                                    <DoubleStar
                                                        rating={company.rating}
                                                    />
                                                    <Typography variant="h6">
                                                        {company.rating.toFixed(
                                                            1
                                                        )}
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                        fontWeight: '600',
                                                        lineHeight: '18px',
                                                    }}
                                                >
                                                    {company.name}
                                                </Typography>
                                                <Typography variant="caption">
                                                    {company.reviews} Reviews
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </BorderedBox>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </BorderedBox> */}

                        <BorderedBox
                            px={4}
                            pt={4}
                            my={6}
                            sx={{ backgroundColor: '#f9f9ff80' }}
                        >
                            <Box>
                                <Typography fontWeight={600} variant="h4">
                                    Recent Reviews
                                </Typography>
                                {selectedEntity.results.reviews.map(
                                    (review, index) => (
                                        <Box
                                            key={index}
                                            sx={
                                                selectedEntity.results.reviews
                                                    .length !==
                                                index + 1
                                                    ? {
                                                          borderBottom:
                                                              '1px solid #D7D9EC',
                                                      }
                                                    : {}
                                            }
                                        >
                                            <Box pt={3}>
                                                <Typography variant="caption">
                                                    {UtilsService.formatStringDate(
                                                        review.timestamp
                                                    )}
                                                </Typography>
                                                <Typography variant="h3">
                                                    {review.summary}
                                                </Typography>
                                                <Box display="flex" pt={3}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            borderRight:
                                                                '1px solid #D7D9EC',
                                                            paddingRight:
                                                                '12px',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Box
                                                            mr={2}
                                                            display="flex"
                                                            alignItems="center"
                                                        >
                                                            <AccountCircle />
                                                        </Box>

                                                        <Box>
                                                            <Typography variant="h6">
                                                                {review.jobTitle ||
                                                                    'Anonymous Employee'}
                                                            </Typography>
                                                            <Typography variant="caption">
                                                                {/* {review.serviceDuration} */}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        pl={3}
                                                    >
                                                        <Rating
                                                            value={
                                                                review.rating
                                                                    .overallRating
                                                            }
                                                            precision={0.5}
                                                            readOnly
                                                            size="large"
                                                        />
                                                        {Object.keys(
                                                            review.rating
                                                        ).filter((key) =>
                                                            ratingCategoryKeys.includes(
                                                                key
                                                            )
                                                        ).length > 0 && (
                                                            <HtmlTooltip
                                                                placement="bottom-start"
                                                                title={
                                                                    <Box
                                                                        sx={{
                                                                            minWidth:
                                                                                '300px',
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant="h6"
                                                                            pb={
                                                                                2
                                                                            }
                                                                        >
                                                                            Subratings
                                                                        </Typography>

                                                                        {Object.keys(
                                                                            review.rating
                                                                        )
                                                                            .filter(
                                                                                (
                                                                                    key
                                                                                ) =>
                                                                                    ratingCategoryKeys.includes(
                                                                                        key
                                                                                    )
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    category
                                                                                ) => (
                                                                                    <Box
                                                                                        sx={{
                                                                                            display:
                                                                                                'flex',
                                                                                            lineHeight:
                                                                                                '20px',
                                                                                            justifyContent:
                                                                                                'space-between',
                                                                                            paddingTop:
                                                                                                '4px',
                                                                                        }}
                                                                                    >
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                                fontWeight:
                                                                                                    '400',
                                                                                            }}
                                                                                        >
                                                                                            {camelCaseToWords(
                                                                                                category
                                                                                            )}
                                                                                        </Typography>
                                                                                        <Rating
                                                                                            value={Number(
                                                                                                review
                                                                                                    .rating[
                                                                                                    category as keyof typeof review.rating
                                                                                                ]
                                                                                            )}
                                                                                            precision={
                                                                                                0.5
                                                                                            }
                                                                                            readOnly
                                                                                            size="small"
                                                                                        />
                                                                                    </Box>
                                                                                )
                                                                            )}
                                                                    </Box>
                                                                }
                                                            >
                                                                <ExpandMore />
                                                            </HtmlTooltip>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>

                                            <Box>
                                                <List
                                                    sx={{
                                                        paddingTop: '0px',
                                                        paddingBottom: '12px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                width: '50%',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}
                                                        >
                                                            {[
                                                                'recommendToFriend',
                                                                'ceoRating',
                                                                'businessOutlook',
                                                            ].map((tile) => (
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <RecommendTiles
                                                                        value={String(
                                                                            review
                                                                                .rating[
                                                                                tile as keyof typeof review.rating
                                                                            ]
                                                                        )}
                                                                    />
                                                                    <Typography
                                                                        ml={2}
                                                                        variant="h6"
                                                                    >
                                                                        {camelCaseToWords(
                                                                            tile
                                                                        )}
                                                                    </Typography>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                        <Box>
                                                            <ListItemButton
                                                                onClick={() =>
                                                                    handleClick(
                                                                        review.timestamp
                                                                    )
                                                                }
                                                            >
                                                                {open ==
                                                                review.timestamp ? (
                                                                    <>
                                                                        <ListItemText
                                                                            primaryTypographyProps={{
                                                                                fontSize: 16,
                                                                                fontWeight: 600,
                                                                                color: '#29319b',
                                                                            }}
                                                                            primary="Show Less"
                                                                        />
                                                                        <ExpandLess
                                                                            sx={{
                                                                                color: '#29319b',
                                                                            }}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <ListItemText
                                                                            primaryTypographyProps={{
                                                                                fontSize: 16,
                                                                                fontWeight: 600,
                                                                                color: '#29319b',
                                                                            }}
                                                                            primary="Show More"
                                                                        />
                                                                        <ExpandMore
                                                                            sx={{
                                                                                color: '#29319b',
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}
                                                            </ListItemButton>
                                                        </Box>
                                                    </Box>
                                                    <Collapse
                                                        in={
                                                            open ==
                                                            review.timestamp
                                                        }
                                                        timeout={100}
                                                        unmountOnExit
                                                    >
                                                        <List
                                                            component="div"
                                                            disablePadding
                                                        >
                                                            <Box mt={3}>
                                                                {review.pros && (
                                                                    <Box>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize:
                                                                                    '14px',
                                                                                fontWeight:
                                                                                    '600',
                                                                                color: '#19AF55',
                                                                            }}
                                                                        >
                                                                            Pros
                                                                        </Typography>
                                                                        <Typography
                                                                            fontSize={
                                                                                14
                                                                            }
                                                                        >
                                                                            {
                                                                                review.pros
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                                {review.cons && (
                                                                    <Box mt={4}>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize:
                                                                                    '14px',
                                                                                fontWeight:
                                                                                    '600',
                                                                                color: '#D82F44',
                                                                            }}
                                                                        >
                                                                            Cons
                                                                        </Typography>
                                                                        <Typography
                                                                            fontSize={
                                                                                14
                                                                            }
                                                                        >
                                                                            {
                                                                                review.cons
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                                {review.advice && (
                                                                    <Box mt={4}>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize:
                                                                                    '14px',
                                                                                fontWeight:
                                                                                    '600',
                                                                            }}
                                                                        >
                                                                            Advice
                                                                            to
                                                                            management
                                                                        </Typography>
                                                                        <Typography
                                                                            fontSize={
                                                                                14
                                                                            }
                                                                        >
                                                                            {
                                                                                review.advice
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        </List>
                                                    </Collapse>
                                                </List>
                                            </Box>
                                        </Box>
                                    )
                                )}
                            </Box>
                        </BorderedBox>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
