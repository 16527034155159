import React, { useContext, useEffect, useState } from 'react';
import { BorderedBox } from '../../../../components/core/box';
import { Box, Typography, Tabs, Divider, Switch } from '@mui/material';
import { CustomTab } from '../../../chat-widget/chatbot/settings';
import SingleSelect from '../../../../components/core/input/single-select';
import { DriveFilters } from '../../../document-insights/drive/drive-filters';
import {
    FileFilters,
    IDiligenceTemplateConfiguration,
} from '../../../../services/interfaces';
import { DocumentInsightsContext } from '../../../../providers/document-provider';
import { useDocumentService } from '../../../../services/document.service';
import { IOSSwitch } from '../../../../components/core/ios-switch';
import { DataSourceOptions } from './common-input';

interface IProps {
    config: IDiligenceTemplateConfiguration;
    updateConfig: (config: IDiligenceTemplateConfiguration) => void;
}

export const SASB = (props: IProps) => {
    const [value, setValue] = useState(0);
    const documentContext = useContext(DocumentInsightsContext);
    const { fetchFilesFilters } = useDocumentService();
    const [enableInternalData, setEnableInternalData] = useState(false);
    const refreshFilters = async () => {
        let filters = await fetchFilesFilters();
        documentContext?.setFilters({ ...filters });
    };
    useEffect(() => {
        if (!documentContext?.filters) refreshFilters();
    }, [documentContext?.filters]);
    const [filters, setFilters] = useState<FileFilters>({
        includeSharedFiles: true,
    });
    const [behaviour, setBehaviour] = useState('ONCE_PER_VISIT');
    return (
        <BorderedBox sx={{ backgroundColor: '#F9F9FF', p: 3 }}>
            <Box py={1} display="flex" justifyContent="space-between">
                <Box>
                    <Typography color="#003" variant="h6">
                        SASB Standards Compliance
                    </Typography>
                    <Typography color="#8D8D8D" fontSize={12}>
                        Compliance matrix based on the Sustainability Accounting
                        Standards Board (SASB) standards.
                    </Typography>
                </Box>
                <Box></Box>
            </Box>
            <DataSourceOptions
                updateConfig={props.updateConfig}
                config={props.config}
            />
        </BorderedBox>
    );
};
