export const EditSquare = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_7603_30818"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_7603_30818)">
            <path
                d="M5.3077 20.5009C4.80257 20.5009 4.375 20.3259 4.025 19.9759C3.675 19.6259 3.5 19.1983 3.5 18.6932V5.30865C3.5 4.80352 3.675 4.37595 4.025 4.02595C4.375 3.67595 4.80257 3.50095 5.3077 3.50095H13.9635L12.4635 5.00092H5.3077C5.23077 5.00092 5.16024 5.03297 5.09612 5.09707C5.03202 5.16119 4.99997 5.23172 4.99997 5.30865V18.6932C4.99997 18.7701 5.03202 18.8407 5.09612 18.9048C5.16024 18.9689 5.23077 19.0009 5.3077 19.0009H18.6923C18.7692 19.0009 18.8397 18.9689 18.9038 18.9048C18.9679 18.8407 19 18.7701 19 18.6932V11.4739L20.5 9.974V18.6932C20.5 19.1983 20.325 19.6259 19.975 19.9759C19.625 20.3259 19.1974 20.5009 18.6923 20.5009H5.3077ZM9.5 14.5009V11.0682L18.5596 2.00865C18.7147 1.85352 18.8853 1.74037 19.0711 1.66922C19.257 1.59807 19.4461 1.5625 19.6385 1.5625C19.8346 1.5625 20.023 1.59807 20.2038 1.66922C20.3846 1.74037 20.5493 1.85031 20.698 1.99902L21.9538 3.25095C22.0987 3.40607 22.2099 3.57722 22.2874 3.7644C22.365 3.95157 22.4038 4.1413 22.4038 4.3336C22.4038 4.52592 22.3708 4.71246 22.3048 4.89323C22.2387 5.07398 22.1281 5.24192 21.973 5.39705L12.8846 14.5009H9.5ZM11 13.0009H12.2461L18.4789 6.7682L17.8558 6.14512L17.1885 5.50282L11 11.6913V13.0009Z"
                fill="#29319B"
            />
        </g>
    </svg>
);
