export const ShowPassword = (
    <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.4546 8.25C10.3835 8.25 8.7046 9.92893 8.7046 12C8.7046 14.0711 10.3835 15.75 12.4546 15.75C14.5257 15.75 16.2046 14.0711 16.2046 12C16.2046 9.92893 14.5257 8.25 12.4546 8.25ZM10.2046 12C10.2046 10.7574 11.212 9.75 12.4546 9.75C13.6972 9.75 14.7046 10.7574 14.7046 12C14.7046 13.2426 13.6972 14.25 12.4546 14.25C11.212 14.25 10.2046 13.2426 10.2046 12Z"
            fill="#A5A6AB"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.4546 3.25C10.3136 3.25 8.37784 4.30899 6.80632 5.59572C5.22622 6.88946 3.93421 8.47865 3.06601 9.68801L2.9944 9.78759C2.47174 10.5138 2.0415 11.1117 2.0415 12C2.0415 12.8883 2.47174 13.4862 2.9944 14.2124L3.06601 14.312C3.93421 15.5214 5.22623 17.1105 6.80632 18.4043C8.37784 19.691 10.3136 20.75 12.4546 20.75C14.5956 20.75 16.5314 19.691 18.1029 18.4043C19.683 17.1105 20.975 15.5214 21.8432 14.312L21.9148 14.2124C22.4375 13.4862 22.8677 12.8883 22.8677 12C22.8677 11.1117 22.4375 10.5138 21.9148 9.78759L21.8432 9.68801C20.975 8.47865 19.683 6.88946 18.1029 5.59572C16.5314 4.30899 14.5956 3.25 12.4546 3.25ZM4.28452 10.5628C5.11379 9.40765 6.31807 7.93414 7.75659 6.75631C9.20369 5.57146 10.8088 4.75 12.4546 4.75C14.1004 4.75 15.7055 5.57146 17.1526 6.75631C18.5911 7.93414 19.7954 9.40765 20.6247 10.5628C21.2486 11.4319 21.3677 11.6415 21.3677 12C21.3677 12.3585 21.2486 12.5681 20.6247 13.4372C19.7954 14.5923 18.5911 16.0659 17.1526 17.2437C15.7055 18.4285 14.1004 19.25 12.4546 19.25C10.8088 19.25 9.20369 18.4285 7.75659 17.2437C6.31808 16.0659 5.11379 14.5923 4.28452 13.4372C3.66056 12.5681 3.5415 12.3585 3.5415 12C3.5415 11.6415 3.66056 11.4319 4.28452 10.5628Z"
            fill="#A5A6AB"
        />
    </svg>
);
