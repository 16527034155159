import { Box, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BorderedBox } from '../../../../components/core/box';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import { useFinancialsService } from '../../../../services/financials.service';
import {
    FinancialOwnershipResponseData,
    InstituteOwnerShip,
    InstitutionOwnership,
    Investment,
    Investor,
    ReportModule,
} from '../../../../services/interfaces';
import { UtilsService } from '../../../../services/utils.service';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        padding: '10px 16px',
        borderBottom: 'none',
        textAlign: 'center',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 'none',
        padding: '10px 16px',
        textAlign: 'center',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#F9F9FF',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface IProps {
    data: ReportModule;
}

export const Investments = (props: IProps) => {
    let { topicSubscriptionId } = useParams();
    const { fetchFinancialOwnership } = useFinancialsService();
    const [data, setData] = useState<Investment[] | null>(null);

    useEffect(() => {
        if (props.data.data?.investments) {
            setData(props.data.data?.investments);
        }
    }, [data]);

    return (
        <BorderedBox sx={{ backgroundColor: '#FFFFFF' }} pt={3}>
            <Box>
                <Box display={'flex'}>
                    {[
                        {
                            title: data?.length,
                            caption: ' Number of investments',
                        },
                        {
                            title: data?.filter((d) => d.isLeadInvestor).length,
                            caption: ' Number of lead investments',
                        },
                    ].map((data) => (
                        <Box
                            sx={{
                                border: '1px solid #D7D9EC',
                                borderRadius: 1,
                                backgroundColor: '#F9F9FF',
                                p: 4,
                                ml: 4,
                                mb: 4,
                                minWidth: 225,
                            }}
                        >
                            <Typography fontSize={32} fontWeight={600}>
                                {data.title}
                            </Typography>
                            <Typography variant="h6">{data.caption}</Typography>
                        </Box>
                    ))}
                </Box>
                <Box pb={3}>
                    {' '}
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 700 }}
                            aria-label="customized table"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        sx={{ textAlign: 'left !important' }}
                                    >
                                        Announced Date
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Organisation name
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Funding round 
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Lead investor
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Money Raised
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((row: Investment, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                            sx={{
                                                textAlign: 'left !important',
                                            }}
                                        >
                                            {UtilsService.formatStringDate(
                                                row.announcedOn
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {row.organization}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {row.description}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {row.isLeadInvestor ? 'Yes' : 'No'}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {row.valueUsd > 0
                                                ? '$' +
                                                  UtilsService.convertToInternationalCurrencySystem(
                                                      row.valueUsd
                                                  )
                                                : '-'}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </BorderedBox>
    );
};
