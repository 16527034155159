import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    IconButton,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Tooltip,
    Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
    IUpdatePredefinedQueryRequest,
    PredefinedQueriesToUpdate,
    PredefinedQuery,
    Tag,
} from '../../../services/interfaces';
import { useSearchParams } from 'react-router-dom';
import { useChatbotService } from '../../../services/chatbot.service';
import { DocumentInsightsContext } from '../../../providers/document-provider';
import { useSnackbar } from 'notistack';
import {
    BootstrapDialogLarge,
    BootstrapDialogTitle,
} from '../../../components/core/dialog';
import { ChatBubbleQuestion } from '../../../components/icons/chat-question-bubble';
import { Info } from '../../../components/icons/info';
import { Input } from '../../../components/core/input';
import { Add } from '@mui/icons-material';
import { Delete } from '../../../components/icons/delete';

interface IProps {
    editQueries: boolean;
    setEditQueries: (flag: boolean, refresh: boolean) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const EditPredefinedQuestions = (props: IProps) => {
    const { editQueries, setEditQueries } = props;
    const [processing, setProcessing] = useState<boolean>(false);
    const [checkError, setCheckError] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const documentContext = useContext(DocumentInsightsContext);
    const { getPredefinedQueries, updatePredefinedQueries } =
        useChatbotService();
    const [searchParam] = useSearchParams();
    const [predefinedQueries, setPredefinedQueries] = useState<
        PredefinedQuery[]
    >([]);
    const [editedPredefinedQueries, setEditedPredefinedQueries] = useState<
        PredefinedQuery[]
    >([]);
    const closeDialog = (refresh: boolean) => {
        setPredefinedQueries([]);
        setEditedPredefinedQueries([]);
        setProcessing(false);
        setCheckError(false);
        setEditQueries(false, refresh);
    };

    const refreshQueries = async () => {
        const queries = await getPredefinedQueries(true);
        setPredefinedQueries(queries);
        setEditedPredefinedQueries(
            [...queries]
                .sort((a, b) => {
                    return a.order < b.order ? -1 : 1;
                })
                .map((q, index) => {
                    return {
                        ...q,
                        order: index + 1,
                    };
                })
        );
        if (queries.length == 0) {
            addPredefinedQuery({
                predefinedQuery: '',
                predefinedQueryId: '',
                userTagIds: [],
                order: 1,
            });
        }
    };

    const deletePredefinedQuery = (pq: PredefinedQuery) => {
        setEditedPredefinedQueries(
            [...editedPredefinedQueries.filter((p) => p.order != pq.order)]
                .sort((a, b) => {
                    return a.order < b.order ? -1 : 1;
                })
                .map((q, index) => {
                    q.order = index + 1;
                    return q;
                })
        );
    };

    const updatePredefinedQuery = (query: PredefinedQuery) => {
        setEditedPredefinedQueries(
            [
                ...editedPredefinedQueries.filter(
                    (p) => p.order != query.order
                ),
                query,
            ].sort((a, b) => {
                return a.order < b.order ? -1 : 1;
            })
        );
    };
    const addPredefinedQuery = (query: PredefinedQuery) => {
        setEditedPredefinedQueries(
            [...editedPredefinedQueries, query].sort((a, b) => {
                return a.order < b.order ? -1 : 1;
            })
        );
    };

    useEffect(() => {
        if (props.editQueries && !editedPredefinedQueries.length) {
            refreshQueries();
        }
    }, [props]);

    const handleSave = async () => {
        setCheckError(true);
        if (
            editedPredefinedQueries.filter((q) => !q.predefinedQuery).length > 0
        ) {
            enqueueSnackbar('Invalid predefined query', { variant: 'error' });
            return;
        }
        let data: IUpdatePredefinedQueryRequest = {
            predefinedQueriesToAdd: [],
            predefinedQueriesToDelete: predefinedQueries
                .map((q) => q.predefinedQueryId)
                .filter(
                    (pq) =>
                        !editedPredefinedQueries
                            .map((q) => q.predefinedQueryId)
                            .includes(pq)
                ),
            predefinedQueriesToUpdate: [],
        };

        for (let i = 0; i < editedPredefinedQueries.length; i++) {
            if (editedPredefinedQueries[i].predefinedQueryId) {
                let originalQuery = predefinedQueries.filter(
                    (q) =>
                        q.predefinedQueryId ==
                        editedPredefinedQueries[i].predefinedQueryId
                )[0];
                if (
                    originalQuery.predefinedQuery !==
                        editedPredefinedQueries[i].predefinedQuery ||
                    originalQuery.userTagIds.length !==
                        editedPredefinedQueries[i].userTagIds.length ||
                    originalQuery.userTagIds
                        .sort((a, b) => (a < b ? -1 : 1))
                        .join('') !=
                        editedPredefinedQueries[i].userTagIds
                            .sort((a, b) => (a < b ? -1 : 1))
                            .join('')
                ) {
                    let updatePredefinedQueryRequest: PredefinedQueriesToUpdate =
                        {
                            predefinedQueryId:
                                editedPredefinedQueries[i].predefinedQueryId,
                            predefinedQuery:
                                editedPredefinedQueries[i].predefinedQuery,
                            userTagIdsToAdd: editedPredefinedQueries[
                                i
                            ].userTagIds.filter(
                                (t) => !originalQuery.userTagIds.includes(t)
                            ),
                            userTagIdsToRemove: originalQuery.userTagIds.filter(
                                (t) =>
                                    !editedPredefinedQueries[
                                        i
                                    ].userTagIds.includes(t)
                            ),
                        };
                    data.predefinedQueriesToUpdate.push(
                        updatePredefinedQueryRequest
                    );
                }
            } else {
                data.predefinedQueriesToAdd.push(editedPredefinedQueries[i]);
            }
        }
        setProcessing(true);
        let res = await updatePredefinedQueries(data);
        await refreshQueries();
        setProcessing(false);
        enqueueSnackbar(`Predefined queries updated successfully!`);
        closeDialog(true);
    };

    return (
        <BootstrapDialogLarge
            onClose={closeDialog}
            aria-labelledby="customized-dialog-title"
            open={editQueries}
            PaperProps={{
                sx: {
                    paddingRight: '0px !important',
                    borderRadius: 2,
                },
            }}
            sx={{ px: 0 }}
        >
            <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={() => closeDialog(false)}
            >
                <Box mb={4} pr={4} display="flex">
                    <Box display="flex">
                        <ChatBubbleQuestion color="#000000" size={24} />
                        <Typography ml={2} lineHeight={'24px'} variant="h4">
                            Question Templates
                        </Typography>
                    </Box>
                </Box>
                <Divider />
            </BootstrapDialogTitle>
            <DialogContent
                sx={{
                    pr: '0px !important',
                }}
            >
                <Box>
                    <Box pr={4} pb={3} pt={4} display="flex">
                        <Box width={'60%'}>
                            <Typography variant="h6">Question</Typography>
                        </Box>
                        <Box width={'30%'}>
                            <Typography variant="h6">
                                Tags (Optional){' '}
                                <Tooltip
                                    placement="top"
                                    arrow
                                    title={
                                        'Adding tag is optional, you can leave it blank to make your query applicable to all.'
                                    }
                                >
                                    <Box
                                        sx={{ position: 'relative', top: 5 }}
                                        component="span"
                                    >
                                        <Info size={20} />
                                    </Box>
                                </Tooltip>
                            </Typography>
                        </Box>
                        <Box width={'10%'}></Box>
                    </Box>
                    <Box
                        sx={{
                            height: 400,
                            overflowY: 'auto',
                        }}
                    >
                        {editedPredefinedQueries.map((pq) => (
                            <Box my={2} display="flex">
                                <Box pr={2} width={'60%'}>
                                    <Input
                                        mt={0}
                                        pt={0}
                                        pb={0}
                                        noShadow
                                        borderRadius={2}
                                        error={
                                            pq.predefinedQuery === '' &&
                                            checkError
                                        }
                                        sx={{
                                            '&:hover .MuiOutlinedInput-notchedOutline':
                                                {
                                                    borderColor:
                                                        pq.predefinedQuery ===
                                                            '' && checkError
                                                            ? 'red !important'
                                                            : '',
                                                },
                                        }}
                                        value={pq.predefinedQuery}
                                        backgroundColor="#fff"
                                        type="text"
                                        onChange={(e) => {
                                            updatePredefinedQuery({
                                                predefinedQuery: e.target.value,
                                                predefinedQueryId:
                                                    pq.predefinedQueryId,
                                                userTagIds: pq.userTagIds,
                                                order: pq.order,
                                            });
                                        }}
                                        placeholder="Enter your question here.."
                                    />
                                </Box>
                                <Box width={'35%'}>
                                    {documentContext?.filters?.tags && (
                                        <FormControl
                                            sx={{ m: 0, width: '100%' }}
                                        >
                                            <Select
                                                multiple
                                                sx={{
                                                    py: 0.25,
                                                    height: 44,
                                                    borderRadius: 2,
                                                    '&.Mui-focused': {
                                                        boxShadow: 'none',
                                                    },
                                                }}
                                                displayEmpty
                                                value={pq.userTagIds}
                                                onChange={(
                                                    event: SelectChangeEvent<
                                                        string[]
                                                    >
                                                ) => {
                                                    const {
                                                        target: { value },
                                                    } = event;

                                                    updatePredefinedQuery({
                                                        predefinedQuery:
                                                            pq.predefinedQuery,
                                                        predefinedQueryId:
                                                            pq.predefinedQueryId,
                                                        userTagIds:
                                                            value as string[],
                                                        order: pq.order,
                                                    });
                                                }}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    if (
                                                        selected.length === 0 ||
                                                        !documentContext.filters
                                                    ) {
                                                        return (
                                                            <>
                                                                Click to add
                                                                tags to this
                                                                question
                                                            </>
                                                        );
                                                    }
                                                    let res =
                                                        documentContext.filters.tags
                                                            .filter((t) =>
                                                                selected.includes(
                                                                    t.userTagId
                                                                )
                                                            )
                                                            .map((t) => t.title)
                                                            .join(', ');

                                                    return (
                                                        <Box
                                                            display="flex"
                                                            justifyContent={
                                                                'space-between'
                                                            }
                                                        >
                                                            <Typography
                                                                maxWidth={
                                                                    'calc(100% - 25px)'
                                                                }
                                                                sx={{
                                                                    overflow:
                                                                        'hidden',
                                                                    textOverflow:
                                                                        'ellipsis',
                                                                }}
                                                            >
                                                                {res}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    px: 1.75,
                                                                    color: '#fff',
                                                                    height: 21,
                                                                    borderRadius:
                                                                        '50%',
                                                                    ml: 0,
                                                                    backgroundColor:
                                                                        '#29319B',
                                                                }}
                                                            >
                                                                {
                                                                    selected.length
                                                                }
                                                            </Box>
                                                        </Box>
                                                    );
                                                }}
                                                MenuProps={MenuProps}
                                                inputProps={{
                                                    'aria-label':
                                                        'Without label',
                                                }}
                                            >
                                                {documentContext.filters.tags
                                                    .filter(
                                                        (t) =>
                                                            t.title !=
                                                            'UNTAGGED'
                                                    )
                                                    .map((t: Tag) => (
                                                        <MenuItem
                                                            key={t.userTagId}
                                                            value={t.userTagId}
                                                        >
                                                            <Checkbox
                                                                checked={pq.userTagIds.includes(
                                                                    t.userTagId
                                                                )}
                                                            />
                                                            <ListItemText
                                                                primary={
                                                                    t.title
                                                                }
                                                            />
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Box>
                                <Box width={'5%'}>
                                    <IconButton
                                        onClick={() => {
                                            deletePredefinedQuery(pq);
                                        }}
                                        sx={{
                                            mt: 3,
                                            p: 0,
                                            ml: 4,
                                            '&.Mui-disabled': {
                                                fillOpacity: 0.35,
                                            },
                                        }}
                                    >
                                        <Delete color="#D82F44" size={18} />
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box>
                        <Button
                            sx={{
                                mt: 2,
                                mb: 6,
                                fontWeight: 600,
                                float: 'left',
                                textTransform: 'none',
                            }}
                            startIcon={<Add />}
                            onClick={() =>
                                addPredefinedQuery({
                                    predefinedQuery: '',
                                    predefinedQueryId: '',
                                    userTagIds: [],
                                    order: editedPredefinedQueries.length + 1,
                                })
                            }
                            variant="text"
                        >
                            Add new question
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{ pr: 4 }}>
                <Button variant="text" onClick={() => closeDialog(false)}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    disabled={processing}
                    variant="contained"
                    sx={{
                        textTransform: 'none',
                        ml: '16px !important',
                        px: 2,
                        mr: 4,
                        borderRadius: 2,
                    }}
                >
                    {!processing && <span>Save</span>}
                    {processing && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: '#ffffff',
                            }}
                        />
                    )}
                </Button>
            </DialogActions>
        </BootstrapDialogLarge>
    );
};
