import { Box, Grid, Skeleton, Tooltip, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { UtilsService } from '../../../services/utils.service';
import { RelatedHeadlines } from './related-headlines';
import {
    HighlightIndexes,
    LiveQueryEntities,
    LiveQueryResponseDataSourcesResult,
} from '../../../services/interfaces';
import { BorderedBox } from '../../../components/core/box';
import { Link } from 'react-router-dom';
import { Info } from '../../../components/icons/info';
import { LiveResponse } from '../../../components/live/live-response';
import { AppContext } from '../../../providers/app-provider';

interface IProps {
    aiResponse: string;
    query: string;
    sources: LiveQueryResponseDataSourcesResult[] | null;
    entities: LiveQueryEntities[];
    highlights: HighlightIndexes | undefined;
}

export const Overview = (props: IProps) => {
    const { aiResponse, query, sources } = props;
    const appContext = useContext(AppContext);
    return (
        <Box>
            <Box>
                {query && (
                    <Grid container>
                        <Grid item xs={7}>
                            <Box
                                sx={{
                                    '& .markdown p': {
                                        fontSize: '15px !important',
                                    },
                                    '& .markdown li': {
                                        fontSize: '15px !important',
                                    },
                                    '& .markdown td': {
                                        fontSize: '15px !important',
                                    },
                                    borderRight: '1px solid #ccc',
                                    textAlign: 'left',
                                    height: `calc(100vh - ${
                                        appContext?.accountActionBannerVisible
                                            ? '22px'
                                            : '0px'
                                    } - 255px)`,
                                    overflowY: 'auto',
                                }}
                                px={4}
                            >
                                {aiResponse && (
                                    <LiveResponse
                                        entities={props.entities}
                                        text={aiResponse}
                                        onCitationClick={(index) => {
                                            UtilsService.highlightContent(
                                                `related-headlines-${
                                                    index - 1
                                                }`,
                                                '#fff',
                                                '#F9F9FF'
                                            );
                                            //@ts-ignore
                                            document
                                                .getElementById(
                                                    `related-headlines-${
                                                        index - 1
                                                    }`
                                                )
                                                .scrollIntoView({
                                                    behavior: 'smooth',
                                                    block: 'center',
                                                    inline: 'center',
                                                });
                                        }}
                                        highlight={props.highlights}
                                    />
                                )}
                                {!aiResponse && (
                                    <Box>
                                        {Array.from(Array(5).keys()).map(() => (
                                            <Box my={3}>
                                                {Array.from(
                                                    Array(5).keys()
                                                ).map(() => (
                                                    <Skeleton variant="text" />
                                                ))}
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={5}
                            sx={{
                                height: `calc(100vh - ${
                                    appContext?.accountActionBannerVisible
                                        ? '22px'
                                        : '0px'
                                } - 255px)`,
                                overflowY: 'auto',
                            }}
                        >
                            <RelatedHeadlines headlines={sources} />
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Box>
    );
};
