import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Grid,
    Button,
    Divider,
    IconButton,
    CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
    BorderedBox,
    BorderedContainedBox,
} from '../../../components/core/box';
import { useBillingService } from '../../../services/billing.service';
import {
    IActiveSubscriptionResponseData,
    IAddOn,
} from '../../../services/interfaces';
import { UtilsService } from '../../../services/utils.service';
import { useQueryparamServices } from '../../../services/query-param.service';
import { useSnackbar } from 'notistack';

// Styled Components
const Container = styled(Box)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#FFFFFF',
    marginBottom: theme.spacing(2),
}));

const SummaryContainer = styled(Box)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#FFFFFF',
}));

const AddOnTitle = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '32px',
    color: 'var(--Primary-dark, #003)',
    marginBottom: theme.spacing(1),
}));

const AddOnSubtitle = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(4),
}));

const AddOnContainer = styled(Box)<{ isActive: boolean }>(
    ({ theme, isActive }) => ({
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(4),
        backgroundColor: isActive ? '#F9F9FF' : '#FFFFFF',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 8,
        transition: 'background-color 0.3s ease',
    })
);

const AddOnDetails = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}));

const QuantityControl = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: 'fit-content',
    float: 'right',
    marginBottom: 8,
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    backgroundColor: '#fff',
}));

const SummaryItem = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.palette.text.primary,
}));

const PayNowButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.5, 3),
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: theme.shape.borderRadius,
}));

interface IAddOnProps {
    availableAddOns: IAddOn[];
    subscriptionInfo?: IActiveSubscriptionResponseData;
}

export const AddOns = (props: IAddOnProps) => {
    const { fetchAvailableAddons, purchaseAddOn } = useBillingService();
    const [availableAddOns, setAvailableAddOns] = useState<IAddOn[]>([]);
    const [isProcessingPayment, setIsProcessingPayment] =
        useState<boolean>(false);
    const { removeQueryParams, getQueryParam } = useQueryparamServices();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setAvailableAddOns(
            props.availableAddOns.map((r) => {
                r.quantity = 0;
                return r;
            })
        );
    }, [props.availableAddOns]);

    const handleIncrement = (product: string) => {
        setAvailableAddOns((prevAddOns) =>
            prevAddOns.map((addOn) =>
                addOn.product === product
                    ? { ...addOn, quantity: addOn.quantity + 1 }
                    : addOn
            )
        );
    };

    const handleDecrement = (product: string) => {
        setAvailableAddOns((prevAddOns) =>
            prevAddOns.map((addOn) =>
                addOn.product === product && addOn.quantity > 0
                    ? { ...addOn, quantity: addOn.quantity - 1 }
                    : addOn
            )
        );
    };

    const calculateTotal = () => {
        return availableAddOns.reduce(
            (total, addOn) => total + addOn.quantity * addOn.price,
            0
        );
    };

    const handlePayNowClick = async () => {
        if (calculateTotal() > 0) {
            setIsProcessingPayment(true);
            await purchaseAddOn(
                availableAddOns
                    .filter((a) => a.quantity > 0)
                    .map((a) => {
                        return {
                            product: a.product,
                            quantity: a.quantity * a.increment,
                            increment: 0,
                            price: 0,
                        };
                    })
            );
            setIsProcessingPayment(false);
        }
    };
    useEffect(() => {
        if (getQueryParam('success')) {
            let success = getQueryParam('success') == 'true';
            if (success) {
                enqueueSnackbar(`Purchase successful`, {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar(`Purchase failed`, {
                    variant: 'error',
                });
            }
            removeQueryParams('success');
        }
    }, [getQueryParam('success')]);
    return (
        <BorderedContainedBox>
            <AddOnTitle>Flexible Add-Ons</AddOnTitle>
            <AddOnSubtitle>
                Increase the limit of your favorite feature as per your need.
            </AddOnSubtitle>
            <Grid container spacing={4}>
                <Grid item xs={12} md={7}>
                    <Container>
                        {availableAddOns.map((addOn) => (
                            <AddOnContainer
                                key={addOn.product}
                                isActive={addOn.quantity > 0}
                            >
                                <AddOnDetails>
                                    <Typography variant="h4">
                                        {UtilsService.snakeCaseToTitleCase(
                                            addOn.product
                                        )}
                                    </Typography>
                                    <Typography variant="h6">
                                        Used In:
                                    </Typography>

                                    <Typography>{addOn.feature}</Typography>
                                </AddOnDetails>
                                <Box>
                                    <QuantityControl>
                                        <IconButton
                                            onClick={() =>
                                                handleDecrement(addOn.product)
                                            }
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                        <Typography variant="h6" sx={{ mx: 2 }}>
                                            {addOn.quantity}
                                        </Typography>
                                        <IconButton
                                            onClick={() =>
                                                handleIncrement(addOn.product)
                                            }
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </QuantityControl>
                                    <Box>
                                        <Typography
                                            display={'inline-block'}
                                            textAlign={'right'}
                                        >
                                            {`$${
                                                addOn.price
                                            } per ${UtilsService.convertToInternationalCurrencySystem(
                                                addOn.increment
                                            )} ${UtilsService.snakeCaseToTitleCase(
                                                addOn.product
                                            )}`}
                                        </Typography>
                                    </Box>
                                </Box>
                            </AddOnContainer>
                        ))}
                    </Container>
                </Grid>
                {availableAddOns.filter((a) => a.quantity > 0).length > 0 && (
                    <Grid item xs={12} md={5}>
                        <BorderedBox p={4}>
                            <SummaryContainer>
                                <Typography
                                    variant="h4"
                                    fontWeight={600}
                                    mb={4}
                                    gutterBottom
                                >
                                    Bill Summary
                                </Typography>
                                {availableAddOns
                                    .filter((a) => a.quantity > 0)
                                    .map((addOn, index) => (
                                        <Box>
                                            {index !== 0 && (
                                                <Box py={3}>
                                                    <Divider />
                                                </Box>
                                            )}
                                            <SummaryItem key={addOn.product}>
                                                {`${
                                                    addOn.quantity < 10
                                                        ? '0' + addOn.quantity
                                                        : addOn.quantity
                                                } × ${UtilsService.snakeCaseToTitleCase(
                                                    addOn.product
                                                )} = $${
                                                    addOn.quantity * addOn.price
                                                }`}
                                            </SummaryItem>
                                        </Box>
                                    ))}
                                <Box sx={{ py: 6 }}>
                                    <Divider />
                                </Box>
                                <Typography variant="h6">
                                    Total Amount
                                </Typography>
                                <Typography
                                    fontSize={32}
                                    fontWeight={700}
                                    gutterBottom
                                >
                                    {`$${calculateTotal()}`}
                                </Typography>
                                <PayNowButton
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={isProcessingPayment}
                                    onClick={handlePayNowClick}
                                >
                                    {isProcessingPayment ? (
                                        <CircularProgress
                                            size={25}
                                            sx={{
                                                color: '#ffffff',
                                                ml: 2,
                                            }}
                                        />
                                    ) : (
                                        'Pay Now'
                                    )}
                                </PayNowButton>
                            </SummaryContainer>
                        </BorderedBox>
                        {props.subscriptionInfo && (
                            <Typography color="#F04901" sx={{ mt: 2 }}>
                                Please note that any Add-Ons purchased today
                                will expire on the last day of the quota cycle,
                                which is{' '}
                                {UtilsService.formatDate(
                                    UtilsService.getLastDateOfCurrentMonth(),
                                    'MMM dd, yyyy'
                                )}
                                , as all credits will be renewed on{' '}
                                {UtilsService.getDateAheadDays(
                                    UtilsService.getLastDateOfCurrentMonth(),
                                    1
                                )}
                                .
                            </Typography>
                        )}
                    </Grid>
                )}
            </Grid>
        </BorderedContainedBox>
    );
};
