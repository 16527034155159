const DB_NAME = 'PHOTON_INSIGHTS';
const DB_VERSION = 1; // Use a long long for this value (don't use a float)
const STORE_NAME = 'PHOTON_INSIGHTS_DATA';

interface MyData {
    id: string;
    data: any; // Replace 'any' with more specific type if possible
}

class DB {
    private db: IDBDatabase | null = null;

    async init() {
        return new Promise<void>((resolve, reject) => {
            const request = indexedDB.open(DB_NAME, DB_VERSION);

            request.onupgradeneeded = (event) => {
                this.db = request.result;
                if (!this.db.objectStoreNames.contains(STORE_NAME)) {
                    this.db.createObjectStore(STORE_NAME, { keyPath: 'id' });
                }
            };

            request.onerror = (event) => {
                reject(`Database error: ${request.error?.toString()}`);
            };

            request.onsuccess = (event) => {
                this.db = request.result;
                resolve();
            };
        });
    }

    async addData(data: MyData) {
        return new Promise<void>((resolve, reject) => {
            const transaction = this.db?.transaction(STORE_NAME, 'readwrite');
            const store = transaction?.objectStore(STORE_NAME);
            const request = store?.add(data);
            if (!request) {
                throw 'Error accessing DB';
            }
            request.onsuccess = () => resolve();
            request.onerror = () =>
                reject(`Error adding data: ${request?.error?.toString()}`);
        });
    }

    async getData(id: string): Promise<MyData | undefined> {
        return new Promise((resolve, reject) => {
            const transaction = this.db?.transaction(STORE_NAME, 'readonly');
            const store = transaction?.objectStore(STORE_NAME);
            const request = store?.get(id);
            if (!request) {
                throw 'Error accessing DB';
            }
            request.onsuccess = () => {
                resolve(request.result);
            };
            request.onerror = () => {
                reject(`Error fetching data: ${request?.error?.toString()}`);
            };
        });
    }
}

export const db = new DB();
