import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StockChart } from '../../../../components/charts/stock-chart';
import { BorderedBox, ContainedBox } from '../../../../components/core/box';
import {
    PriceChartResponseData,
    ReportModule,
    StockPrice,
} from '../../../../services/interfaces';
import { useFinancialsService } from '../../../../services/financials.service';
import { useParams } from 'react-router-dom';
import { UtilsService } from '../../../../services/utils.service';
import SingleSelectTab from '../../../../components/core/input/single-select-tab';
import { subDays, subMonths, subYears } from 'date-fns';
import { StockChartSkeleton } from '../../../../components/images/stock-chart-skeleton';

interface IProps {
    data: ReportModule;
}

export const PriceChart = (props: IProps) => {
    const { fetchPriceChartDetails } = useFinancialsService();
    const { data } = props;
    const [selectedRange, setSelectedRange] = useState('1Y');
    let { topicSubscriptionId } = useParams();
    const [priceChartData, setPriceChartData] = useState<StockPrice[]>([]);
    // '3M', '6M', 'YTD', '1Y', '3Y', '5Y'

    const updatePriceChartdata = () => {
        if (!data.data || !data.data?.yahoo?.stockPrices) return;

        if (data.data.yahoo.stockPrices.length > 1400) {
            data.data.yahoo.stockPrices = [
                ...data.data?.yahoo.stockPrices.filter(
                    (e, index) =>
                        UtilsService.isFirstMonday(new Date(e.timeLevel)) ||
                        UtilsService.isThirdMonday(new Date(e.timeLevel)) ||
                        index == data.data!.yahoo!.stockPrices.length - 1
                ),
            ];
        } else if (data.data.yahoo.stockPrices.length > 800) {
            data.data.yahoo.stockPrices = [
                ...data.data?.yahoo.stockPrices.filter(
                    (e, index) =>
                        UtilsService.isDay(new Date(e.timeLevel), 5) ||
                        index == data.data!.yahoo!.stockPrices.length - 1
                ),
            ];
        } else if (data.data.yahoo.stockPrices.length > 500) {
            data.data.yahoo.stockPrices = [
                ...data.data?.yahoo.stockPrices.filter(
                    (e, index) =>
                        UtilsService.isDay(new Date(e.timeLevel), 5) ||
                        UtilsService.isDay(new Date(e.timeLevel), 2) ||
                        index == data.data!.yahoo!.stockPrices.length - 1
                ),
            ];
        }
        setPriceChartData(data.data.yahoo.stockPrices);
    };

    useEffect(updatePriceChartdata, [data]);

    const [labels, setLabels] = useState<string[]>([]);
    const [priceSeries, setPriceSeries] = useState<number[]>([]);
    const [positiveVolumeSeries, setPositiveVolumeSeries] = useState<number[]>(
        []
    );
    const [negativeVolumeSeries, setNegativeVolumeSeries] = useState<number[]>(
        []
    );
    useEffect(() => {
        if (!priceChartData) return;
        let p = [];
        let n = [];
        setLabels(priceChartData.map((d) => d.timeLevel));
        setPriceSeries(priceChartData.map((d) => d.closingPrice));
        for (let e of priceChartData) {
            if (e.indicator == 'POSITIVE') {
                p.push(e.tradedVolume);
                n.push(0);
            } else {
                n.push(e.tradedVolume);
                p.push(0);
            }
        }
        setPositiveVolumeSeries([...p]);
        setNegativeVolumeSeries([...n]);
    }, [priceChartData]);

    return (
        <BorderedBox sx={{ backgroundColor: '#FFFFFF' }}>
            <Box pt={3} px={4} justifyContent="space-between" display="flex">
                <Box>
                    <Typography variant="h6">Price chart</Typography>
                    {priceChartData && (
                        <Box display="flex">
                            <Box>
                                <Typography color="#4B4B4C" variant="caption">
                                    High
                                </Typography>
                                <Typography variant="subtitle2">
                                    $
                                    {data.data?.yahoo?.maxClosingPrice.toFixed(
                                        2
                                    )}
                                </Typography>
                            </Box>
                            <Box ml={5}>
                                <Typography color="#4B4B4C" variant="caption">
                                    Low
                                </Typography>
                                <Typography variant="subtitle2">
                                    $
                                    {data.data?.yahoo?.minClosingPrice.toFixed(
                                        2
                                    )}
                                </Typography>
                            </Box>
                            <Box ml={5}>
                                <Typography color="#4B4B4C" variant="caption">
                                    CAGR
                                </Typography>
                                <Typography
                                    color={
                                        data.data?.yahoo?.cagr! > 0
                                            ? '#19AF55'
                                            : '#D82F44'
                                    }
                                    variant="subtitle2"
                                >
                                    {Math.floor(
                                        Math.abs(data.data?.yahoo?.cagr! * 100)
                                    )}
                                    %
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Box>
                <Box></Box>
            </Box>

            <Box
                sx={{
                    '& .apexcharts-xaxis-label': {
                        display: 'none',
                    },
                }}
            >
                <StockChart
                    volumePositive={positiveVolumeSeries}
                    volumeNegative={negativeVolumeSeries}
                    labels={labels}
                    price={priceSeries}
                />
            </Box>
        </BorderedBox>
    );
};
