export const Comment = (
    <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_2455_73900"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="12"
            height="13"
        >
            <rect y="0.132812" width="12" height="12" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2455_73900)">
            <path
                d="M3.125 7.00779H6.87498V6.25781H3.125V7.00779ZM3.125 5.50779H8.87498V4.75781H3.125V5.50779ZM3.125 4.00779H8.87498V3.25781H3.125V4.00779ZM1.25 10.652V2.28666C1.25 2.0341 1.3375 1.82031 1.5125 1.64531C1.6875 1.47031 1.90128 1.38281 2.15385 1.38281H9.84613C10.0987 1.38281 10.3125 1.47031 10.4875 1.64531C10.6625 1.82031 10.75 2.0341 10.75 2.28666V7.97894C10.75 8.23151 10.6625 8.44529 10.4875 8.62029C10.3125 8.79529 10.0987 8.88279 9.84613 8.88279H3.01923L1.25 10.652ZM2.7 8.1328H9.84613C9.88459 8.1328 9.91986 8.11678 9.95191 8.08473C9.98396 8.05267 9.99999 8.01741 9.99999 7.97894V2.28666C9.99999 2.2482 9.98396 2.21293 9.95191 2.18088C9.91986 2.14883 9.88459 2.1328 9.84613 2.1328H2.15385C2.11538 2.1328 2.08012 2.14883 2.04806 2.18088C2.01601 2.21293 1.99999 2.2482 1.99999 2.28666V8.82511L2.7 8.1328Z"
                fill="#A5A6AB"
            />
        </g>
    </svg>
);
