import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { sentimentChartBarClick } from '../../../../analytics/analytics';
import {
    BarLineChart,
    BarLineChartProps,
} from '../../../../components/charts/bar-line-chart';
import { AppContext } from '../../../../providers/app-provider';
import { SentimentChartData } from '../../../../services/interfaces';
import { useTopicService } from '../../../../services/topic.service';
import { UtilsService } from '../../../../services/utils.service';

// interface IProps

interface IProps {
    onBarClick?: (date: string) => void;
    granularity: string;
    topicSubscriptionId?: string;
}

export const TopicSentimentPlot = (props: IProps) => {
    const { getTopicSentiments } = useTopicService();
    const { topicSubscriptionId } = props;
    const appContext = useContext(AppContext);
    const [sentimentData, setSentimentData] =
        useState<BarLineChartProps | null>(null);
    const [isDataUnavailable, setIsDataUnavaialable] = useState(false);

    const refreshSentiments = async () => {
        if (topicSubscriptionId) {
            setIsDataUnavaialable(false);
            let s: SentimentChartData[] = await getTopicSentiments(
                [topicSubscriptionId],
                props.granularity
            );
            const topicSentiments = s.filter(
                (t: SentimentChartData) =>
                    t.topicSubscriptionId == topicSubscriptionId
            )[0];
            if (topicSentiments) {
                const positiveSeries = topicSentiments.chartDetails.map(
                    (dateDetails) =>
                        dateDetails.sentiment.positive
                            ? dateDetails.sentiment.positive / 1000
                            : 0
                );
                const negativeSeries = topicSentiments.chartDetails.map(
                    (dateDetails) =>
                        dateDetails.sentiment.negative
                            ? (-1 * dateDetails.sentiment.negative) / 1000
                            : 0
                );
                let neutralSeries = topicSentiments.chartDetails.map(
                    (dateDetails) =>
                        dateDetails.sentiment.negative &&
                        dateDetails.sentiment.positive
                            ? (-1 * dateDetails.sentiment.negative +
                                  dateDetails.sentiment.positive) /
                              1000
                            : 0
                );

                neutralSeries = UtilsService.convertPlotLine(neutralSeries);

                const labels = topicSentiments.chartDetails.map((t) => t.date);

                let sentimentData: BarLineChartProps = {
                    series: [
                        {
                            name: 'Positive',
                            data: positiveSeries,
                        },
                        {
                            name: 'Negative',
                            data: negativeSeries,
                        },
                        {
                            name: 'Average',
                            data: neutralSeries,
                        },
                    ],
                    height: 536,
                    labels: labels,
                };
                setSentimentData({ ...sentimentData });
            } else {
                setIsDataUnavaialable(true);
            }
        }
    };

    useEffect(() => {
        setSentimentData(null);
        refreshSentiments();
    }, [
        topicSubscriptionId,
        props.granularity,
        appContext?.filters.date,
        appContext?.category,
    ]);

    return (
        <Box>
            {!isDataUnavailable && (
                <Box>
                    {sentimentData && (
                        <BarLineChart
                            onBarClick={(date: string) => {
                                props.onBarClick && props.onBarClick(date);
                                sentimentChartBarClick(
                                    date,
                                    topicSubscriptionId || '',
                                    '',
                                    'Dashboard'
                                );
                            }}
                            showLinesOnly={false}
                            {...sentimentData}
                        />
                    )}
                    {!sentimentData && (
                        <Skeleton
                            sx={{
                                transform: 'scale(1, 1)',
                                mt: 4,
                            }}
                            height={532}
                        />
                    )}
                </Box>
            )}
            {isDataUnavailable && (
                <Box height={536}>
                    <Typography variant="h6">Data Unavailable</Typography>
                </Box>
            )}
        </Box>
    );
};
