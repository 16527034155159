import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';
import { DocumentFile } from '../../../services/interfaces';
import { DocumentInsightsContext } from '../../../providers/document-provider';
import { useDocumentService } from '../../../services/document.service';
import shareAnimation from '../../../components/animations/share.json';
import { useNavigate } from 'react-router-dom';
import { UtilsService } from '../../../services/utils.service';
import Lottie from 'lottie-react';
interface IProps {
    file: DocumentFile;
    open: boolean;
    onClose: () => void;
}

const DialogWrapper = styled(Paper)(() => ({
    width: '40%',
    maxWidth: '450px !important',
}));

export const ShareFile = (props: IProps) => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(props.open);
    const documentContext = useContext(DocumentInsightsContext);
    const [isSharing, setIsSharing] = useState<boolean>(false);
    const { fetchSharedFiles, shareDocument, revokeFileSharing } =
        useDocumentService();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleConfirmClick = async () => {
        if (props.file.isShared) {
            await revokeFileShare();
        } else {
            await shareFile();
        }
    };

    const shareFile = async () => {
        setIsSharing(true);
        let res = await shareDocument(props.file.userFileId);
        if (res) {
            enqueueSnackbar(
                `${UtilsService.trimText(
                    props.file.fileName,
                    35
                )} shared successfully`
            );
            await refreshSharedFiles();
        }
        setIsSharing(false);
    };

    const revokeFileShare = async () => {
        setIsSharing(true);
        let res = await revokeFileSharing(props.file.userFileId);
        if (res) {
            enqueueSnackbar(
                `${UtilsService.trimText(
                    props.file.fileName,
                    35
                )} revoked successfully`
            );
            await refreshSharedFiles();
        }
        setIsSharing(false);
    };

    const refreshSharedFiles = async () => {
        if (documentContext) {
            documentContext.setRefreshCount(documentContext.refreshCount + 1);

            handleClose();
        }
    };

    const handleClose = () => {
        setOpen(false);
        props.onClose();
    };
    return (
        <Box>
            <Dialog
                PaperComponent={DialogWrapper}
                open={open}
                onClose={handleClose}
            >
                <DialogContent>
                    <Box textAlign="center">
                        <Box
                            sx={{
                                height: 200,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Lottie
                                animationData={shareAnimation}
                                loop={true}
                            />
                        </Box>
                        <Typography
                            color="#000033"
                            mb={2}
                            textAlign="center"
                            variant="h4"
                        >
                            {props.file.isShared ? 'Revoke sharing' : 'Share'}{' '}
                            {UtilsService.trimText(props.file.fileName, 30)}{' '}
                            File?
                        </Typography>
                        <Typography
                            color="#000033"
                            mb={2}
                            fontSize={14}
                            textAlign="center"
                            variant="caption"
                        >
                            You are about to{' '}
                            {props.file.isShared ? 'revoke sharing' : 'share'} a
                            file with your Organisation.
                        </Typography>
                    </Box>
                    <Box></Box>
                </DialogContent>
                <DialogActions>
                    <Grid px={6} py={4} spacing={6} container>
                        <Grid xs={6} item>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid xs={6} item>
                            <Button
                                fullWidth
                                variant="contained"
                                disabled={isSharing}
                                onClick={handleConfirmClick}
                            >
                                {isSharing ? (
                                    <CircularProgress
                                        size={25}
                                        sx={{
                                            color: '#ffffff',
                                            ml: 2,
                                        }}
                                    />
                                ) : props.file.isShared ? (
                                    'Revoke'
                                ) : (
                                    'Share'
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
