interface IProps {
    color?: string;
}
export const Edit = (props: IProps) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.2958 3.60458C12.6473 3.2302 12.1067 2.918 11.6441 2.72789C11.1558 2.52722 10.6694 2.42505 10.1509 2.56398C9.63246 2.70291 9.2623 3.0346 8.9398 3.4525C8.63426 3.84843 8.32211 4.38914 7.94776 5.03757L4.71808 10.6315C4.50095 11.0068 4.32357 11.3135 4.23392 11.6427C4.22112 11.6898 4.2101 11.7373 4.20101 11.7855C4.12832 12.1713 4.18764 12.5587 4.26266 13.0486L4.28288 13.181C4.42137 14.0905 4.53552 14.8401 4.70768 15.4086C4.88774 16.0031 5.16454 16.5177 5.70893 16.832C6.25332 17.1463 6.83738 17.1287 7.44228 16.9874C8.02067 16.8523 8.72695 16.5763 9.58381 16.2415L9.7086 16.1928C10.1704 16.0128 10.5355 15.8705 10.8333 15.6147C11.1311 15.3588 11.3267 15.0192 11.5742 14.5898L14.8038 8.99591C15.1782 8.34751 15.4904 7.80681 15.6805 7.34424C15.8812 6.856 15.9833 6.36959 15.8444 5.8511C15.7055 5.33261 15.3738 4.96245 14.9559 4.63996C14.56 4.33442 14.0192 4.02226 13.3708 3.64792L13.2958 3.60458ZM13.3871 8.9497L8.69613 6.24137L5.84326 11.1827C5.53246 11.721 5.45769 11.8668 5.4294 12.0169C5.40111 12.1671 5.4177 12.3301 5.51129 12.9446C5.659 13.9144 5.76102 14.5741 5.90402 15.0463C6.04215 15.5024 6.1846 15.6633 6.33393 15.7495C6.48326 15.8357 6.69384 15.8786 7.1579 15.7702C7.6383 15.6579 8.2606 15.4165 9.17432 15.0595C9.7533 14.8333 9.90276 14.7662 10.0187 14.6666C10.1346 14.567 10.2234 14.4293 10.5342 13.891L13.3871 8.9497ZM14.0108 7.86642C14.245 7.4537 14.4139 7.13776 14.5243 6.86906C14.6718 6.51018 14.6767 6.32297 14.637 6.17462C14.5972 6.02628 14.4994 5.86661 14.1922 5.62956C13.8707 5.38142 13.404 5.11046 12.7083 4.70878C12.0126 4.30711 11.5446 4.03845 11.1689 3.88405C10.81 3.73655 10.6228 3.73164 10.4745 3.77139C10.3261 3.81114 10.1665 3.90899 9.9294 4.21617C9.75192 4.44616 9.56276 4.7504 9.32244 5.15959L14.0108 7.86642Z"
            fill={props.color || '#4B4B4C'}
        />
    </svg>
);
