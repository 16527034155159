import styled from '@emotion/styled';
import { List, ListItem, ListItemText, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { RadialChart } from '../../../components/charts/radial';
import { BorderedBox, ContainedBox } from '../../../components/core/box';
import { SearchImage } from '../../../components/icons/search-image';
import { AppContext } from '../../../providers/app-provider';
import { Granularity } from '../../../services/insights.service';
import { TopicSentimentAssesmentResponseData } from '../../../services/interfaces';
import { useTopicService } from '../../../services/topic.service';
import { UtilsService } from '../../../services/utils.service';

export const SectionPercentage = styled(Typography)(() => ({
    fontSize: 40,
    fontWeight: 400,
    lineHeight: '54px',
}));
interface IProps {
    topicSubscriptionId?: string;
    hideExecutiveSummary?: boolean;
    disablePx?: boolean;
}

export const Overview = (props: IProps) => {
    let { topicSubscriptionId } = props;
    const appContext = useContext(AppContext);
    const [topicAssesment, setTopicAssesment] =
        useState<TopicSentimentAssesmentResponseData | null>(null);
    const { fetchTopicSentimentAssesment } = useTopicService();

    const initializeTopicAssesment = async () => {
        setTopicAssesment(null);
        if (topicSubscriptionId) {
            let data = await fetchTopicSentimentAssesment(
                [topicSubscriptionId],
                Granularity.DAILY
            );
            setTopicAssesment({ ...data });
        }
    };

    useEffect(() => {
        initializeTopicAssesment();
    }, [topicSubscriptionId, appContext?.filters.date, appContext?.category]);

    return (
        <Box sx={{ px: props.disablePx ? 0 : 8 }}>
            <BorderedBox>
                <Grid
                    sx={{
                        backgroundColor: '#F9F9FF',
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                    }}
                    justifyContent="space-between"
                    textAlign="left"
                    container
                >
                    <Grid p={4} pb={2} xs={12} sm={4} item>
                        <Grid container>
                            <Grid item>
                                <Typography variant="h4">
                                    Potential Positive
                                </Typography>
                                {topicAssesment?.sentiment.positive && (
                                    <SectionPercentage
                                        sx={{ color: '#19AF55' }}
                                    >
                                        {UtilsService.convertSentimentToPercentage(
                                            topicAssesment.sentiment.positive
                                        )}
                                        %
                                    </SectionPercentage>
                                )}
                                {!topicAssesment?.sentiment.positive && (
                                    <Skeleton
                                        sx={{ transform: 'scale(1,1)', mt: 2 }}
                                        height={44}
                                        width={100}
                                        variant="text"
                                    />
                                )}

                                {!topicAssesment && (
                                    <Skeleton
                                        sx={{ mt: 1, transform: 'scale(1,1)' }}
                                    />
                                )}
                                {topicAssesment && (
                                    <Typography color="#A5A6AB" variant="h6">
                                        {
                                            topicAssesment.indicatorsCount
                                                .positive
                                        }{' '}
                                        potential positives
                                    </Typography>
                                )}
                            </Grid>
                            <Grid xs={4} item>
                                {topicAssesment?.sentiment.positive != null && (
                                    <RadialChart
                                        color="#1ACB7F"
                                        value={UtilsService.convertSentimentToPercentage(
                                            topicAssesment.sentiment.positive
                                        )}
                                    />
                                )}
                                {!topicAssesment?.sentiment.positive && (
                                    <Skeleton
                                        sx={{
                                            transform: 'scale(1,1)',
                                            mt: 4,
                                            ml: 8,
                                            mb: 3,
                                        }}
                                        height={75}
                                        width={75}
                                        variant="circular"
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid p={4} pb={2} xs={12} sm={4} item>
                        <Grid container>
                            <Grid item>
                                <Typography variant="h4">
                                    Potential Negative
                                </Typography>
                                {topicAssesment?.sentiment.negative && (
                                    <SectionPercentage
                                        sx={{ color: '#D82F44' }}
                                    >
                                        {UtilsService.convertSentimentToPercentage(
                                            topicAssesment.sentiment.negative
                                        )}
                                        %
                                    </SectionPercentage>
                                )}
                                {!topicAssesment?.sentiment.negative && (
                                    <Skeleton
                                        sx={{ transform: 'scale(1,1)', mt: 2 }}
                                        height={44}
                                        width={100}
                                        variant="text"
                                    />
                                )}

                                {topicAssesment?.indicatorsCount.negative !=
                                    null && (
                                    <Typography color="#A5A6AB" variant="h6">
                                        {
                                            topicAssesment.indicatorsCount
                                                .negative
                                        }{' '}
                                        potential negatives
                                    </Typography>
                                )}
                                {!topicAssesment && (
                                    <Skeleton
                                        sx={{ mt: 1, transform: 'scale(1,1)' }}
                                    />
                                )}
                            </Grid>
                            <Grid xs={4} item>
                                {topicAssesment?.sentiment.negative && (
                                    <RadialChart
                                        color="#D82F44"
                                        value={UtilsService.convertSentimentToPercentage(
                                            topicAssesment.sentiment.negative
                                        )}
                                    />
                                )}
                                {!topicAssesment?.sentiment.negative && (
                                    <Skeleton
                                        sx={{
                                            transform: 'scale(1,1)',
                                            mt: 4,
                                            ml: 8,
                                            mb: 3,
                                        }}
                                        height={75}
                                        width={75}
                                        variant="circular"
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid p={4} pb={2} xs={12} sm={4} item>
                        <Grid container>
                            <Grid xs={7} item>
                                <Typography variant="h4">
                                    Articles Crunched
                                </Typography>
                                {topicAssesment && (
                                    <SectionPercentage>
                                        {topicAssesment.articlesSearched}
                                    </SectionPercentage>
                                )}
                                {!topicAssesment && (
                                    <Skeleton
                                        sx={{ transform: 'scale(1,1)', mt: 2 }}
                                        height={44}
                                        width={100}
                                        variant="text"
                                    />
                                )}
                            </Grid>
                            <Grid xs={5} item>
                                {SearchImage}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {!props.hideExecutiveSummary && (
                    <Box>
                        {topicAssesment &&
                            topicAssesment?.executiveSummary.length > 0 && (
                                <ContainedBox>
                                    <Typography variant="h4">
                                        Last Week executive summary
                                    </Typography>
                                    <ul style={{ paddingInlineStart: 16 }}>
                                        {[
                                            topicAssesment.executiveSummary.map(
                                                (summary: string) => (
                                                    <li>
                                                        <Typography variant="caption">
                                                            {summary}
                                                        </Typography>
                                                    </li>
                                                )
                                            ),
                                        ]}
                                    </ul>
                                </ContainedBox>
                            )}
                        {!topicAssesment?.executiveSummary && (
                            <ContainedBox>
                                <Typography variant="h4">
                                    <Skeleton width={300} variant="text" />
                                </Typography>
                                <Typography variant="caption">
                                    <Box>
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                    </Box>
                                </Typography>
                            </ContainedBox>
                        )}
                    </Box>
                )}
            </BorderedBox>
        </Box>
    );
};
