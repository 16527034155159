import React, { useEffect, useState } from 'react';
import { useDocumentService } from '../../../services/document.service';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    CircularProgress,
    DialogContent,
    Divider,
    IconButton,
    InputAdornment,
    Tooltip,
    Typography,
} from '@mui/material';
import { Eye } from '../../../components/icons/eye';
import {
    BootstrapDialogLarge,
    BootstrapDialogMedium,
    BootstrapDialogTitle,
} from '../../../components/core/dialog';
// Import Worker
import { Worker } from '@react-pdf-viewer/core';
// Import the main Viewer component
import { Viewer } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
// default layout plugin
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles of default layout plugin
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { createStore, Plugin, PluginFunctions } from '@react-pdf-viewer/core';
import { UtilsService } from '../../../services/utils.service';
import { InsightsResponseData } from '../../../services/interfaces';
import { Input } from '../../../components/core/input';
import { SearchInsights } from '../../../components/icons/search-insights';
import { Close } from '../../../components/icons/close';
import { Cancel } from '@mui/icons-material';

interface StoreProps {
    jumpToPage?(pageIndex: number): void;
}

interface JumpToPagePlugin extends Plugin {
    jumpToPage(pageIndex: number): void;
}

interface IJumpToPluginProps {
    store: any;
}

const Highlighted = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
        return <span>{text}</span>;
    }
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
        <span>
            {parts
                .filter((part) => part)
                .map((part, i) =>
                    new RegExp(`(${highlight})`, 'gi').test(part) ? (
                        <mark id={`highlight-${i}`} key={i}>
                            {part}
                        </mark>
                    ) : (
                        <span key={i}>{part}</span>
                    )
                )}
        </span>
    );
};

interface IProps {
    fileId?: string;
    fileName: string;
    fileType?: string;
    pageNo?: number;
    children?: React.ReactNode;
    loader?: React.ReactNode;
}

export const ViewFile = (props: IProps) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [activeMatch, setActiveMatch] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const store = React.useMemo(() => createStore<StoreProps>(), []);
    const jumpToPagePluginInstance = {
        install: (pluginFunctions: PluginFunctions) => {
            store.update('jumpToPage', pluginFunctions.jumpToPage);
        },
        jumpToPage: (pageIndex: number) => {
            const fn = store.get('jumpToPage');
            if (fn) {
                fn(pageIndex);
            }
        },
    };
    const { jumpToPage, install } = jumpToPagePluginInstance;

    const jumToFilePage = (pageNo: number) => {
        jumpToPage(pageNo - 1);
    };
    const { getFileInsight, getFileGroupInsight, getFileUrl } =
        useDocumentService();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [insight, setInsight] = useState<InsightsResponseData | null>(null);
    const [isDownloadingFile, setIsDownloadingFile] = useState<boolean>(false);
    const [documentUrl, setDocumenturl] = useState('');
    const { fileId } = props;

    const fileType = props.fileType
        ? props.fileType.toLowerCase() == 'application/pdf'
            ? 'pdf'
            : 'txt'
        : UtilsService.getFileType(props.fileName).toLowerCase() == 'pdf'
        ? 'pdf'
        : 'txt';

    useEffect(() => {
        focusIndex(activeMatch);
    }, [activeMatch]);

    const focusIndex = (index: number) => {
        let elList = [...document.getElementsByTagName('mark')];
        elList.forEach((el, i: number) => {
            el.style.backgroundColor = '#FAE20580';
            if (index - 1 == i) {
                el.style.backgroundColor = '#FAE205';
                el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                });
            }
        });
    };

    const closeDialog = () => {
        setOpenDialog(false);
    };

    const fetchFileInsight = async () => {
        if (!fileId) return;
        setInsight(null);
        let res = await getFileInsight(fileId);
        setInsight({ ...res });
    };

    const viewDocumentClick = async () => {
        if (!isDownloadingFile) {
            try {
                setIsDownloadingFile(true);
                if (fileId) {
                    if (fileType == 'pdf') {
                        const url = await getFileUrl(fileId);
                        setDocumenturl(url);
                    } else {
                        await fetchFileInsight();
                    }
                    setOpenDialog(true);
                }
            } finally {
                setIsDownloadingFile(false);
            }
        }
    };
    const [pdfFile, setPdfFile] = useState<string | null>(null);

    // pdf file error state
    const [pdfError, setPdfError] = useState('');

    // handle file onChange event
    const allowedFiles = ['application/pdf'];

    return (
        <Box>
            <Box onClick={viewDocumentClick} sx={{ cursor: 'pointer' }}>
                {isDownloadingFile && props.loader
                    ? props.loader
                    : props.children}
            </Box>

            <BootstrapDialogLarge
                onClose={closeDialog}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                sx={{
                    p: 4,
                    '& .MuiPaper-root': {
                        maxWidth: '750px',
                    },
                }}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={closeDialog}
                >
                    <Box
                        mb={2}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        display="flex"
                    >
                        <Box>
                            <Typography className="clamp-line-1" variant="h5">
                                {props.fileName}
                            </Typography>
                        </Box>
                        {fileType !== 'pdf' && (
                            <Box pr={4} ml={4}>
                                <Input
                                    containerWidth={'220px'}
                                    placeholder="Enter to search"
                                    type="text"
                                    value={searchQuery}
                                    onChange={(t) =>
                                        setSearchQuery(t.target.value)
                                    }
                                    startAdornment={
                                        <InputAdornment position="end">
                                            <SearchInsights
                                                height={12}
                                                width={12}
                                                fillColor="#000033"
                                            />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <Box
                                            sx={{
                                                color: '#4B4B4C',
                                                cursor: 'pointer',
                                                ml: 2,
                                                mt: 1,
                                                visibility: searchQuery
                                                    ? 'visible'
                                                    : 'hidden',
                                            }}
                                            onClick={() => {
                                                setSearchQuery('');
                                            }}
                                        >
                                            <Cancel
                                                fontSize="small"
                                                sx={{
                                                    color: '#4b4b4c',
                                                    opacity: '70%',
                                                }}
                                            />
                                        </Box>
                                    }
                                    pb={1}
                                    pt={1}
                                    mt={0}
                                    borderRadius={2}
                                    sx={{
                                        paddingLeft: 1,
                                        '&>.MuiInputBase-input': {
                                            fontSize: 12,
                                            padding:
                                                '4px 14px 4px 12px !important',
                                        },
                                        '&>.MuiInputBase-root': {
                                            paddingLeft: 4,
                                        },
                                    }}
                                />
                            </Box>
                        )}
                    </Box>

                    {fileType !== 'pdf' &&
                        insight &&
                        insight.documentData &&
                        document.getElementsByTagName('mark').length > 0 && (
                            <Box
                                sx={{
                                    border: '1px solid #D7D9EC',
                                    borderRadius: 1,
                                    px: 2,
                                    py: 1,
                                    mr: 3,
                                    ml: 'auto',
                                    width: 'fit-content',
                                    position: 'absolute',
                                    right: '50px',
                                    top: '76px',
                                    backgroundColor: '#ffffff',
                                }}
                                display="flex"
                            >
                                <Typography>
                                    {activeMatch} of{' '}
                                    {
                                        document.getElementsByTagName('mark')
                                            .length
                                    }
                                </Typography>

                                <Box
                                    sx={{
                                        borderLeft: '1px solid #D7D9EC',
                                        ml: 2,
                                        pl: 2,
                                        pt: 0.5,
                                    }}
                                    display="flex"
                                >
                                    <IconButton
                                        sx={{ mr: 1 }}
                                        onClick={() => {
                                            setActiveMatch(
                                                Math.max(activeMatch - 1, 1)
                                            );
                                        }}
                                    >
                                        <KeyboardArrowUpIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            insight.documentData &&
                                                setActiveMatch(
                                                    Math.min(
                                                        activeMatch + 1,
                                                        document.getElementsByTagName(
                                                            'mark'
                                                        ).length
                                                    )
                                                );
                                        }}
                                    >
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        )}
                </BootstrapDialogTitle>
                <Box position={'relative'}>
                    <Divider />
                </Box>
                <DialogContent>
                    <Box
                    // height={
                    //     insight?.documentData
                    //         ? ''
                    //         : window.innerHeight - 128
                    // }
                    >
                        {fileType == 'pdf' && documentUrl && (
                            <Box height={window.innerHeight - 200}>
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                                    <Viewer
                                        onDocumentLoad={() => {
                                            props.pageNo &&
                                                jumToFilePage(props.pageNo);
                                        }}
                                        fileUrl={documentUrl}
                                        plugins={[
                                            defaultLayoutPluginInstance,
                                            jumpToPagePluginInstance,
                                        ]}
                                    ></Viewer>
                                </Worker>
                            </Box>
                        )}
                        {fileType == 'txt' && (
                            <Box textAlign="left">
                                {insight?.documentData && (
                                    <Box pb={2}>
                                        {insight.documentData.rawText
                                            .split('\n')
                                            .map((data: string) => (
                                                // <Typography fontSize={16}>
                                                //     {data}
                                                // </Typography>
                                                <Box pb={2}>
                                                    <Highlighted
                                                        text={data}
                                                        highlight={searchQuery}
                                                    />
                                                </Box>
                                            ))}
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>
                </DialogContent>
            </BootstrapDialogLarge>
        </Box>
    );
};
