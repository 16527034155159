import { useContext } from 'react';
import { Constants } from '../constants';
import { AppContext } from '../providers/app-provider';
import {
    ChatFiltersRequest,
    ChatFiltersResponse,
    DocumenFinancialsQueryRequest,
    SECFilingsFilterSource,
    EarningsFiltersRequest,
    EarningsFiltersResponse,
    EarningsFiltersResponseData,
    FinancialEventsResponse,
    FinancialEventsResponseData,
    FinancialsSummaryResponse,
    FinancialsSummaryResponseData,
    KeyInsightsRequest,
    KeyInsightsResponse,
    KeyInsightsResponseData,
    SECEventsRequest,
    SECEventsResponse,
    SECEventsResponseData,
    SECEventsSummaryResponse,
    SECEventsSummaryResponseData,
    SECFilingQueryRequest,
    SECFilingQueryRequestReport,
    TopicQueryRequest,
    TopicChatUpdateRequest,
    TopicBookmarksRequest,
    TopicBookmarksResponse,
    TopicBookmarksResponseDataElement,
    SECBookmarksResponseDataElement,
    TopicStockPriceRequest,
    PriceChartResponse,
    PriceChartResponseData,
    TopicSentimentChartRequest,
    TopicSentimentChartResponseData,
    TopicSentimentChartResponse,
    ExecutiveSummaryResponse,
    ExecutiveSummaryResponseData,
    PotentialSentiment,
    PotentialIndicatorsResponse,
    RecentHeadlinesResponseData,
    RecentHeadlinesResponse,
    FinancialTrendsResponseData,
    FinancialKeyMatrixResponseData,
    FinancialKeyMatrixResponse,
    FinancialOwnershipResponseData,
    FinancialOwnershipResponse,
    FinancialInsiderTradingResponseData,
    FinancialInsiderTradingResponse,
    FinancialAllFilingsResponseData,
    FinancialAllFilingsResponse,
    PersonalityTraitResponseData,
    PersonalityTraitData,
} from './interfaces';
import {
    INetworkResponse,
    NetworkRequestMethod,
    useNetworkAPI,
} from './network.service';
import { useInsightsService } from './insights.service';
import {
    FinancialTrendsData,
    FinancialTrendsResponse,
} from '../pages/research/live-insights/financial-info-interface';

export const useFinancialsService = () => {
    const appContext = useContext(AppContext);
    const { processPotentialIndicatorsResponse } = useInsightsService();
    const { triggerRequest, triggerRequestWithChunkedResponse } =
        useNetworkAPI();
    const fetchKeyInsights = async (
        request: KeyInsightsRequest
    ): Promise<KeyInsightsResponseData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: KeyInsightsRequest = request;
            const result: {
                body: KeyInsightsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getKeyInsights,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchFinancialsSummary = async (
        request: KeyInsightsRequest
    ): Promise<FinancialsSummaryResponseData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: KeyInsightsRequest = request;
            const result: {
                body: FinancialsSummaryResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getFinancialSummaries,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const queryData = async (
        data: DocumenFinancialsQueryRequest,
        callback: (data: string) => void
    ): Promise<void> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: DocumenFinancialsQueryRequest = data;
            const result = await triggerRequestWithChunkedResponse({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.topicCompareQuery,
                retryCount: 0,
                callback,
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchEarningsFilters = async (
        request: EarningsFiltersRequest
    ): Promise<EarningsFiltersResponseData> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: EarningsFiltersRequest = request;
            const result: {
                body: EarningsFiltersResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getFinancialFilters,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchSECSummary = async (
        request: SECEventsRequest
    ): Promise<SECEventsSummaryResponseData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: KeyInsightsRequest = request;
            const result: {
                body: SECEventsSummaryResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getFinancialSummaries,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchSECFilingsChatBookmarks = async (
        chatBotPrompt: string,
        topicSubscriptionId?: string
    ): Promise<SECBookmarksResponseDataElement[]> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: TopicBookmarksRequest = {
                topicSubscriptionId,
                chatBotPrompt,
                isBookmarked: true,
            };
            const result: {
                body: TopicBookmarksResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: `${Constants.getTopicChat}?fetch=all`,
                retryCount: 3,
            });

            return result.body.data
                .elements as SECBookmarksResponseDataElement[];
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchChatFilters = async (
        req: ChatFiltersRequest
    ): Promise<SECFilingsFilterSource[]> => {
        // return [
        //     {
        //         "scrapingSourceId": "SC4039034094359840",
        //         "title": "CNN"
        //     },
        //     {
        //         "scrapingSourceId": "SC4398349345894399",
        //         "title": "CNBC"
        //     }
        // ]
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: ChatFiltersRequest = req;
            const result: {
                body: ChatFiltersResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.topicChatFilters,
                retryCount: 3,
            });
            return result.body.data.sources as SECFilingsFilterSource[];
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const updateFinancialChat = async (
        chatId: string,
        isBookmarked: boolean,
        vote: 'upvote' | 'downvote' | 'neutral',
        chatBotPrompt: string,
        chatQueryId: string,
        chatAnswerId: string
    ): Promise<boolean> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: TopicChatUpdateRequest = {
                isBookmarked,
                vote,
                chatBotPrompt,
                chatQueryId,
                chatAnswerId,
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PATCH,
                headers,
                body: JSON.stringify(body),
                url: `${Constants.updateTopicChat}/${chatId}`,
                retryCount: 3,
            });

            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchFinancialPotentialIndicators = async (
        topicSubscriptionId: string,
        pageNo: number
    ): Promise<{
        data: (PotentialSentiment | null)[][];
        count: string;
    }> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body = {
                topicSubscriptionId,
                categoryId: 'CAT762672153847094467',
            };
            const result: {
                body: PotentialIndicatorsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getFinanciaPlotentialIndicators + pageNo,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return {
                    data: processPotentialIndicatorsResponse(result.body.data),
                    count: result.headers['x-pi-content-length'],
                };
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchSECEvents = async (
        request: SECEventsRequest
    ): Promise<SECEventsResponseData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: KeyInsightsRequest = request;
            const result: {
                body: SECEventsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getFinancialSummariesEvents,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchRecentHeadlines = async (
        topicSubscriptionId: string
    ): Promise<RecentHeadlinesResponseData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body = {
                topicSubscriptionId,
                categoryId: 'CAT762672153847094467',
            };
            const result: {
                body: RecentHeadlinesResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.getRecentHeadlined,
                retryCount: 3,
            });
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchFinancialsSummaryEvents = async (
        request: KeyInsightsRequest
    ): Promise<FinancialEventsResponseData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: KeyInsightsRequest = request;
            const result: {
                body: FinancialEventsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getFinancialSummariesEvents,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchPersonalityTraits = async (
        request: KeyInsightsRequest
    ): Promise<PersonalityTraitData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: KeyInsightsRequest = request;
            const result: {
                body: PersonalityTraitResponseData;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getPersonalityTraits,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.data;
        } catch (error: any) {
            throw error;
        }
    };

    const fetchPriceChartDetails = async (
        request: TopicStockPriceRequest
    ): Promise<PriceChartResponseData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: TopicStockPriceRequest = request;
            const result: {
                body: PriceChartResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getPriceChart,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchExecutiveSummary = async (
        topicSubscriptionId: string
    ): Promise<ExecutiveSummaryResponseData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body = {
                topicSubscriptionId,
                categoryId: 'CAT762672153847094467',
            };
            const result: {
                body: ExecutiveSummaryResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getExecutiveSummary,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchSentimentChartDetails = async (
        request: TopicSentimentChartRequest
    ): Promise<TopicSentimentChartResponseData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: TopicStockPriceRequest = request;
            const result: {
                body: TopicSentimentChartResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getSentimentChart,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchFinancialtrends = async (
        topicSubscriptionId: string
    ): Promise<FinancialTrendsData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };

            const result: {
                body: FinancialTrendsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.getFinancialtrends}/${topicSubscriptionId}`,
                retryCount: 3,
            });

            return result.body.data;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchFinancialKeyMatrix = async (
        topicSubscriptionId: string
    ): Promise<FinancialKeyMatrixResponseData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };

            const result: {
                body: FinancialKeyMatrixResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.getFinancialKeymatrix}/${topicSubscriptionId}`,
                retryCount: 3,
            });
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchFinancialOwnership = async (
        topicSubscriptionId: string
    ): Promise<FinancialOwnershipResponseData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };

            const result: {
                body: FinancialOwnershipResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.getFinancialOwnership}/${topicSubscriptionId}`,
                retryCount: 3,
            });
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchFinancialInsiderTrading = async (
        topicSubscriptionId: string
    ): Promise<FinancialInsiderTradingResponseData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };

            const result: {
                body: FinancialInsiderTradingResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.getFinancialInsiderTrading}/${topicSubscriptionId}`,
                retryCount: 3,
            });
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchAllFilings = async (
        topicSubscriptionId: string
    ): Promise<FinancialAllFilingsResponseData> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };

            const result: {
                body: FinancialAllFilingsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.getFinancialAllFilings}/${topicSubscriptionId}`,
                retryCount: 3,
            });
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    return {
        fetchKeyInsights,
        fetchFinancialsSummary,
        fetchFinancialsSummaryEvents,
        fetchSECEvents,
        fetchSECSummary,
        fetchEarningsFilters,
        queryData,
        fetchPriceChartDetails,
        fetchChatFilters,
        updateFinancialChat,
        fetchExecutiveSummary,
        fetchSECFilingsChatBookmarks,
        fetchSentimentChartDetails,
        fetchFinancialPotentialIndicators,
        fetchRecentHeadlines,
        fetchFinancialtrends,
        fetchFinancialKeyMatrix,
        fetchFinancialOwnership,
        fetchFinancialInsiderTrading,
        fetchAllFilings,
        fetchPersonalityTraits,
    };
};
