// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button(theme: any) {
    const disabledStyle = {
        '&.Mui-disabled': {
            backgroundColor: '#4B4B4C',
            opacity: 0.35,
            color: '#ffffff',
        },
    };

    return {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    fontWeight: 400,
                },
                contained: {
                    ...disabledStyle,
                },
                outlined: {
                    ...disabledStyle,
                },
            },
        },
    };
}
