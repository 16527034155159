export const SomethingWentWrong = (
    <svg
        width="270"
        height="272"
        viewBox="0 0 270 272"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1166_116493)">
            <path d="M270 270.5H0V271.5H270V270.5Z" fill="#3F3D56" />
            <path
                d="M258.518 126.095V222.685H7.3283V126.095C7.28302 98.9125 16.1001 72.456 32.4433 50.7351C43.4069 36.1146 57.4295 24.0631 73.532 15.422C89.6346 6.7809 107.429 1.7584 125.673 0.705118C128.068 0.565118 130.485 0.496783 132.923 0.500116C134.308 0.500116 135.693 0.52012 137.063 0.57012C168.931 1.57945 199.212 14.711 221.728 37.2851C222.408 37.9651 223.073 38.6501 223.743 39.3401C227.185 42.9449 230.411 46.7498 233.403 50.7351C249.744 72.4571 258.561 98.9129 258.518 126.095Z"
                fill="#E6E6E6"
            />
            <path
                d="M137.066 0.572552V222.686H125.672V0.707181C128.07 0.567364 130.487 0.498315 132.923 0.500031C134.311 0.500031 135.694 0.520767 137.066 0.572552Z"
                fill="white"
            />
            <path
                d="M233.403 50.7379H32.4414C35.4329 46.7521 38.6583 42.9474 42.1005 39.3438H223.744C227.186 42.9474 230.411 46.7521 233.403 50.7379Z"
                fill="white"
            />
            <path
                d="M239.398 83.3672H150.373V222.687H239.398V83.3672Z"
                fill="white"
            />
            <path
                d="M179.363 108.828H165.896V122.294H179.363V108.828Z"
                fill="#E6E6E6"
            />
            <path
                d="M201.058 108.828H187.592V122.294H201.058V108.828Z"
                fill="#E6E6E6"
            />
            <path
                d="M222.753 108.828H209.287V122.294H222.753V108.828Z"
                fill="#E6E6E6"
            />
            <path
                d="M179.736 139.5H166.27V152.966H179.736V139.5Z"
                fill="#E6E6E6"
            />
            <path
                d="M201.433 139.5H187.967V152.966H201.433V139.5Z"
                fill="#E6E6E6"
            />
            <path
                d="M223.128 139.5H209.662V152.966H223.128V139.5Z"
                fill="#E6E6E6"
            />
            <path
                d="M180.111 170.172H166.645V183.638H180.111V170.172Z"
                fill="#E6E6E6"
            />
            <path
                d="M201.806 170.172H188.34V183.638H201.806V170.172Z"
                fill="#E6E6E6"
            />
            <path
                d="M223.501 170.172H210.035V183.638H223.501V170.172Z"
                fill="#E6E6E6"
            />
            <path
                d="M180.486 200.848H167.02V214.314H180.486V200.848Z"
                fill="#E6E6E6"
            />
            <path
                d="M202.181 200.848H188.715V214.314H202.181V200.848Z"
                fill="#E6E6E6"
            />
            <path
                d="M223.876 200.848H210.41V214.314H223.876V200.848Z"
                fill="#E6E6E6"
            />
            <path
                d="M180.486 200.848H167.02V214.314H180.486V200.848Z"
                fill="#E6E6E6"
            />
            <path
                d="M202.181 200.848H188.715V214.314H202.181V200.848Z"
                fill="#E6E6E6"
            />
            <path
                d="M223.876 200.848H210.41V214.314H223.876V200.848Z"
                fill="#E6E6E6"
            />
            <path
                d="M37.1866 192.041C47.4368 192.041 55.7462 183.732 55.7462 173.481C55.7462 163.231 47.4368 154.922 37.1866 154.922C26.9364 154.922 18.627 163.231 18.627 173.481C18.627 183.732 26.9364 192.041 37.1866 192.041Z"
                fill="#29319B"
            />
            <path
                d="M38.144 222.685H36.334L36.899 192.035L37.239 173.48H37.289L37.614 192.035L37.794 202.59L37.814 203.615L38.144 222.685Z"
                fill="white"
            />
            <path
                d="M40.617 202.144L40.1895 201.332L37.1215 202.947L37.549 203.759L40.617 202.144Z"
                fill="white"
            />
            <path
                d="M91.7505 174.306C107.338 174.306 119.974 161.67 119.974 146.083C119.974 130.495 107.338 117.859 91.7505 117.859C76.1633 117.859 63.5273 130.495 63.5273 146.083C63.5273 161.67 76.1633 174.306 91.7505 174.306Z"
                fill="#F9F9FF"
            />
            <path
                d="M93.243 222.687H90.418L91.003 190.882L91.308 174.302L91.828 146.082H91.908L92.398 174.297L92.678 190.352L92.708 191.912L93.243 222.687Z"
                fill="white"
            />
            <path
                d="M96.9666 189.669L96.3164 188.434L91.651 190.89L92.3012 192.125L96.9666 189.669Z"
                fill="white"
            />
            <path
                d="M33.5385 119.132L38.3062 115.319C34.6024 114.91 33.0805 116.93 32.4577 118.529C29.5641 117.328 26.4141 118.902 26.4141 118.902L35.9535 122.366C35.4722 121.08 34.6345 119.959 33.5385 119.132Z"
                fill="#29319B"
            />
            <path
                d="M83.7768 88.0581L88.5445 84.2448C84.8407 83.8362 83.3188 85.8562 82.696 87.455C79.8024 86.2535 76.6523 87.8282 76.6523 87.8282L86.1917 91.2913C85.7105 90.0059 84.8728 88.8843 83.7768 88.0581Z"
                fill="#29319B"
            />
            <path
                d="M64.6147 117.582L69.3824 113.768C65.6786 113.36 64.1567 115.38 63.5339 116.978C60.6403 115.777 57.4902 117.352 57.4902 117.352L67.0297 120.815C66.5484 119.529 65.7107 118.408 64.6147 117.582Z"
                fill="#29319B"
            />
        </g>
        <defs>
            <clipPath id="clip0_1166_116493">
                <rect
                    width="270"
                    height="271"
                    fill="white"
                    transform="translate(0 0.5)"
                />
            </clipPath>
        </defs>
    </svg>
);
