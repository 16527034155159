export const Directory = (
    <svg
        width="247"
        height="184"
        viewBox="0 0 247 184"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M246.618 161.486H0V161.61H246.618V161.486Z" fill="#EBEBEB" />
        <path
            d="M221.906 169.422H205.57V169.545H221.906V169.422Z"
            fill="#EBEBEB"
        />
        <path
            d="M199.362 169.422H164.436V169.545H199.362V169.422Z"
            fill="#EBEBEB"
        />
        <path
            d="M145.174 164.846H135.709V164.969H145.174V164.846Z"
            fill="#EBEBEB"
        />
        <path
            d="M42.7881 165.674H25.875V165.797H42.7881V165.674Z"
            fill="#EBEBEB"
        />
        <path
            d="M77.931 165.674H47.1777V165.797H77.931V165.674Z"
            fill="#EBEBEB"
        />
        <path
            d="M111.047 167.756H92.8906V167.879H111.047V167.756Z"
            fill="#EBEBEB"
        />
        <path
            d="M116.896 139.488H21.6571C20.9111 139.486 20.196 139.189 19.6689 138.661C19.1417 138.133 18.8457 137.417 18.8457 136.671V2.79172C18.8522 2.04991 19.1511 1.34061 19.6775 0.817895C20.2039 0.295182 20.9153 0.00127077 21.6571 0H116.896C117.643 0 118.359 0.296726 118.887 0.8249C119.416 1.35308 119.712 2.06943 119.712 2.81639V136.671C119.712 137.418 119.416 138.134 118.887 138.663C118.359 139.191 117.643 139.488 116.896 139.488ZM21.6571 0.0986479C20.9438 0.0999544 20.26 0.384264 19.756 0.889172C19.2521 1.39408 18.969 2.07833 18.969 2.79172V136.671C18.969 137.385 19.2521 138.069 19.756 138.574C20.26 139.079 20.9438 139.363 21.6571 139.364H116.896C117.61 139.363 118.294 139.079 118.799 138.574C119.304 138.069 119.588 137.385 119.589 136.671V2.79172C119.588 2.07788 119.304 1.39364 118.799 0.888871C118.294 0.384104 117.61 0.0999517 116.896 0.0986479H21.6571Z"
            fill="#EBEBEB"
        />
        <path
            d="M223.589 139.488H128.346C127.599 139.486 126.884 139.189 126.356 138.661C125.828 138.133 125.531 137.418 125.529 136.671V2.79172C125.537 2.04946 125.837 1.34016 126.364 0.817587C126.891 0.29501 127.603 0.00125691 128.346 0H223.589C224.33 0.00256705 225.041 0.297052 225.566 0.819626C226.091 1.3422 226.39 2.05076 226.396 2.79172V136.671C226.396 137.416 226.101 138.131 225.575 138.659C225.049 139.187 224.335 139.485 223.589 139.488ZM128.346 0.0986479C127.632 0.0999517 126.948 0.384104 126.443 0.888871C125.938 1.39364 125.654 2.07788 125.653 2.79172V136.671C125.654 137.385 125.938 138.069 126.443 138.574C126.948 139.079 127.632 139.363 128.346 139.364H223.589C224.303 139.363 224.988 139.079 225.492 138.574C225.997 138.069 226.281 137.385 226.283 136.671V2.79172C226.281 2.07788 225.997 1.39364 225.492 0.888871C224.988 0.384104 224.303 0.0999517 223.589 0.0986479H128.346Z"
            fill="#EBEBEB"
        />
        <path
            d="M37.4097 68.1738L104.342 68.1738V10.1839L37.4097 10.1839V68.1738Z"
            fill="#E6E6E6"
        />
        <path
            d="M35.9117 68.1738L102.844 68.1738V10.1839L35.9117 10.1839V68.1738Z"
            fill="#F5F5F5"
        />
        <path
            d="M100.174 66.1026V12.2559L38.5835 12.2559V66.1026H100.174Z"
            fill="#FAFAFA"
        />
        <path
            d="M75.4757 66.1026L86.6376 12.2559H63.2878L52.1309 66.1026H75.4757Z"
            fill="white"
        />
        <path
            d="M39.7109 66.1084V12.2617H38.5864V66.1084H39.7109Z"
            fill="#E6E6E6"
        />
        <path
            d="M37.0179 56.4512L101.74 56.4512V53.6693L37.0179 53.6693V56.4512Z"
            fill="#F5F5F5"
        />
        <path
            d="M101.232 158.551H111.92V112.201H101.232V158.551Z"
            fill="#F0F0F0"
        />
        <path
            d="M31.4338 161.486H109.873V158.552H31.4338V161.486Z"
            fill="#F0F0F0"
        />
        <path
            d="M101.232 112.197H29.377V158.547H101.232V112.197Z"
            fill="#F5F5F5"
        />
        <path
            d="M97.0348 117.904H33.5801V133.106H97.0348V117.904Z"
            fill="#F0F0F0"
        />
        <path
            d="M97.0348 137.643H33.5801V152.844H97.0348V137.643Z"
            fill="#F0F0F0"
        />
        <path
            d="M48.2028 126.545H82.4087C82.6843 126.544 82.9483 126.434 83.1432 126.239C83.3381 126.044 83.4482 125.78 83.4495 125.505C83.4482 125.229 83.3383 124.964 83.1435 124.768C82.9487 124.573 82.6848 124.462 82.4087 124.459H48.2028C47.9268 124.462 47.6628 124.573 47.4681 124.768C47.2733 124.964 47.1634 125.229 47.1621 125.505C47.1634 125.78 47.2735 126.044 47.4684 126.239C47.6633 126.434 47.9272 126.544 48.2028 126.545Z"
            fill="#F5F5F5"
        />
        <path
            d="M48.2028 146.284H82.4087C82.6843 146.282 82.9483 146.172 83.1432 145.977C83.3381 145.782 83.4482 145.519 83.4495 145.243C83.4495 144.966 83.34 144.701 83.1449 144.505C82.9499 144.309 82.6852 144.199 82.4087 144.197H48.2028C47.9264 144.199 47.6617 144.309 47.4666 144.505C47.2716 144.701 47.1621 144.966 47.1621 145.243C47.1634 145.519 47.2735 145.782 47.4684 145.977C47.6633 146.172 47.9272 146.282 48.2028 146.284Z"
            fill="#F5F5F5"
        />
        <path
            d="M212.891 40.4944C218.977 38.8174 221.059 27.7738 205.68 23.2656C209.073 34.3733 202.281 43.4045 212.891 40.4944Z"
            fill="#EBEBEB"
        />
        <path
            d="M214.238 45.2891C219.038 40.1052 214.5 28.376 197.217 33.1703C206.993 41.723 205.878 54.3301 214.238 45.2891Z"
            fill="#E0E0E0"
        />
        <path
            d="M209.502 46.622C205.487 42.6465 211.239 28.8112 224.497 35.2233C216.985 42.4196 216.531 53.552 209.502 46.622Z"
            fill="#FAFAFA"
        />
        <path
            d="M205.497 54.3656H217.182L218.065 46.459H204.619L205.497 54.3656Z"
            fill="#E6E6E6"
        />
        <path
            d="M204.484 47.873H218.196C218.428 47.873 218.65 47.781 218.814 47.6173C218.977 47.4536 219.069 47.2315 219.069 47C219.069 46.7684 218.977 46.5464 218.814 46.3827C218.65 46.2189 218.428 46.127 218.196 46.127H204.484C204.253 46.127 204.031 46.2189 203.867 46.3827C203.703 46.5464 203.611 46.7684 203.611 47C203.611 47.2315 203.703 47.4536 203.867 47.6173C204.031 47.781 204.253 47.873 204.484 47.873Z"
            fill="#E6E6E6"
        />
        <path
            d="M107.186 148.095V134.704H118.338V121.317H129.495V107.926H140.647V94.5345H151.804V81.148H162.956V67.7566H174.113V54.3652H185.27H246.619V161.487H107.186H96.0293V148.095H107.186Z"
            fill="#FAFAFA"
        />
        <path
            d="M168.533 148.095V134.704H179.69V121.317H190.842V107.926H201.999V94.5345H213.151V81.148H224.308V67.7566H235.46V54.3652H246.617V67.7566V161.487H168.533H157.381V148.095H168.533Z"
            fill="#E0E0E0"
        />
        <path
            d="M157.383 148.096H95.373V152.111H157.383V148.096Z"
            fill="#EBEBEB"
        />
        <path
            d="M168.539 134.703H106.529V138.718H168.539V134.703Z"
            fill="#EBEBEB"
        />
        <path
            d="M179.691 121.316H117.682V125.331H179.691V121.316Z"
            fill="#EBEBEB"
        />
        <path
            d="M190.847 107.924H128.838V111.939H190.847V107.924Z"
            fill="#EBEBEB"
        />
        <path d="M202 94.5332H139.99V98.5482H202V94.5332Z" fill="#EBEBEB" />
        <path
            d="M213.156 81.1465H151.146V85.1614H213.156V81.1465Z"
            fill="#EBEBEB"
        />
        <path
            d="M224.308 67.7559H162.299V71.7708H224.308V67.7559Z"
            fill="#EBEBEB"
        />
        <path
            d="M235.465 54.3652H173.455V58.3802H235.465V54.3652Z"
            fill="#EBEBEB"
        />
        <path
            d="M123.307 183.761C176.124 183.761 218.941 181.261 218.941 178.177C218.941 175.094 176.124 172.594 123.307 172.594C70.4904 172.594 27.6738 175.094 27.6738 178.177C27.6738 181.261 70.4904 183.761 123.307 183.761Z"
            fill="#F5F5F5"
        />
        <path
            d="M205.845 156.982C205.754 156.979 205.664 156.956 205.583 156.915C205.502 156.873 205.431 156.813 205.376 156.74C205.332 156.694 205.3 156.637 205.284 156.575C205.269 156.512 205.27 156.447 205.288 156.385C205.455 155.843 207.004 155.399 207.182 155.349C207.201 155.342 207.222 155.342 207.242 155.348C207.261 155.354 207.278 155.367 207.29 155.384C207.301 155.401 207.307 155.42 207.307 155.441C207.307 155.461 207.301 155.48 207.29 155.497C207.014 156.025 206.467 156.913 205.904 156.977L205.845 156.982ZM206.989 155.625C206.378 155.823 205.579 156.163 205.51 156.449C205.502 156.476 205.503 156.505 205.51 156.532C205.518 156.559 205.533 156.583 205.554 156.602C205.593 156.659 205.647 156.704 205.709 156.733C205.772 156.763 205.84 156.775 205.909 156.77C206.195 156.735 206.595 156.311 206.989 155.625Z"
            fill="#D7D9EC"
        />
        <path
            d="M206.501 155.646C206.039 155.693 205.577 155.562 205.209 155.281C205.157 155.216 205.129 155.135 205.129 155.052C205.129 154.968 205.157 154.887 205.209 154.822C205.252 154.754 205.311 154.696 205.38 154.653C205.449 154.611 205.527 154.584 205.608 154.576C206.249 154.497 207.221 155.335 207.26 155.37C207.275 155.381 207.286 155.397 207.292 155.414C207.298 155.431 207.299 155.45 207.295 155.468C207.293 155.488 207.285 155.506 207.273 155.521C207.261 155.535 207.244 155.546 207.226 155.552C206.99 155.619 206.746 155.651 206.501 155.646ZM205.717 154.783H205.648C205.597 154.788 205.548 154.805 205.505 154.831C205.462 154.858 205.424 154.893 205.396 154.936C205.322 155.049 205.347 155.108 205.396 155.148C205.588 155.399 206.466 155.493 206.999 155.394C206.634 155.079 206.191 154.867 205.717 154.783Z"
            fill="#D7D9EC"
        />
        <path
            d="M165.751 173.27C165.37 173.304 164.989 173.207 164.671 172.993C164.603 172.932 164.551 172.855 164.517 172.769C164.484 172.684 164.471 172.592 164.479 172.5C164.483 172.442 164.502 172.385 164.534 172.336C164.566 172.287 164.61 172.247 164.661 172.219C165.209 171.918 166.984 172.826 167.187 172.934C167.206 172.945 167.221 172.961 167.23 172.98C167.24 173 167.244 173.021 167.241 173.043C167.238 173.064 167.229 173.085 167.214 173.101C167.198 173.117 167.179 173.128 167.157 173.132C166.694 173.22 166.223 173.267 165.751 173.27ZM164.957 172.347C164.889 172.343 164.821 172.357 164.76 172.387C164.739 172.397 164.721 172.413 164.709 172.433C164.697 172.453 164.69 172.477 164.691 172.5C164.683 172.562 164.69 172.625 164.711 172.683C164.733 172.741 164.768 172.794 164.814 172.836C165.086 173.082 165.801 173.122 166.787 172.959C166.217 172.652 165.598 172.445 164.957 172.347Z"
            fill="#D7D9EC"
        />
        <path
            d="M167.137 173.127H167.092C166.56 172.885 165.509 171.923 165.613 171.42C165.613 171.302 165.716 171.149 166.007 171.119C166.112 171.108 166.219 171.119 166.319 171.15C166.42 171.182 166.514 171.233 166.594 171.302C167.152 171.771 167.255 172.969 167.26 173.023C167.261 173.041 167.258 173.06 167.25 173.076C167.243 173.093 167.231 173.107 167.216 173.117C167.204 173.124 167.191 173.129 167.177 173.131C167.164 173.132 167.15 173.131 167.137 173.127ZM166.071 171.317H166.012C165.815 171.317 165.805 171.415 165.8 171.44C165.751 171.741 166.466 172.501 167.008 172.826C166.964 172.319 166.764 171.839 166.436 171.45C166.336 171.358 166.207 171.306 166.071 171.302V171.317Z"
            fill="#D7D9EC"
        />
        <path
            d="M182.609 71.5211L183.073 73.1438C183.137 73.2671 182.989 73.3954 182.777 73.3954H164.138C163.975 73.3954 163.837 73.3164 163.827 73.2129L163.664 71.595C163.664 71.4816 163.792 71.3879 163.975 71.3879H182.318C182.374 71.3831 182.43 71.3929 182.481 71.4162C182.532 71.4396 182.576 71.4757 182.609 71.5211Z"
            fill="#D7D9EC"
        />
        <path
            opacity="0.3"
            d="M182.609 71.5211L183.073 73.1438C183.137 73.2671 182.989 73.3954 182.777 73.3954H164.138C163.975 73.3954 163.837 73.3164 163.827 73.2129L163.664 71.595C163.664 71.4816 163.792 71.3879 163.975 71.3879H182.318C182.374 71.3831 182.43 71.3929 182.481 71.4162C182.532 71.4396 182.576 71.4757 182.609 71.5211Z"
            fill="white"
        />
        <path
            d="M180.149 73.5908H180.642C180.736 73.5908 180.81 73.5415 180.805 73.4774L180.573 71.3713C180.573 71.3121 180.484 71.2578 180.385 71.2578H179.892C179.794 71.2578 179.72 71.3121 179.729 71.3713L179.956 73.4774C179.966 73.5415 180.03 73.5908 180.149 73.5908Z"
            fill="#263238"
        />
        <path
            d="M165.521 73.5908H166.014C166.113 73.5908 166.187 73.5415 166.182 73.4774L165.95 71.3713C165.95 71.3121 165.856 71.2578 165.763 71.2578H165.269C165.171 71.2578 165.097 71.3121 165.102 71.3713L165.334 73.4774C165.339 73.5415 165.422 73.5908 165.521 73.5908Z"
            fill="#263238"
        />
        <path
            d="M172.833 73.5908H173.326C173.425 73.5908 173.499 73.5415 173.494 73.4774L173.262 71.3713C173.262 71.3121 173.173 71.2578 173.075 71.2578H172.582C172.483 71.2578 172.409 71.3121 172.419 71.3713L172.631 73.4922C172.631 73.5415 172.735 73.5908 172.833 73.5908Z"
            fill="#263238"
        />
        <path
            d="M170.619 30.3591L169.736 30.1914C169.736 30.1914 170.239 30.8228 170.619 30.3591Z"
            fill="#263238"
        />
        <path
            opacity="0.2"
            d="M154.292 93.1684C145.935 96.4149 136.804 97.1105 128.052 95.1673C119.3 93.2241 111.321 88.7294 105.124 82.2515C98.9266 75.7736 94.7893 67.6035 93.2351 58.7743C91.681 49.9451 92.7799 40.8533 96.3929 32.6486C100.006 24.4439 105.971 17.4948 113.533 12.68C121.095 7.86524 129.915 5.40101 138.878 5.59894C147.841 5.79688 156.544 8.64809 163.886 13.792C171.228 18.936 176.88 26.1416 180.128 34.4978C182.284 40.0465 183.327 45.9657 183.196 51.9174C183.065 57.8692 181.763 63.7367 179.363 69.1851C176.964 74.6334 173.515 79.5556 169.213 83.6708C164.912 87.7859 159.841 91.0132 154.292 93.1684Z"
            fill="#D7D9EC"
        />
        <path
            opacity="0.1"
            d="M146.092 6.33822L110.54 87.0812C109.993 86.6669 109.45 86.2377 108.917 85.8037C105.481 82.9611 102.489 79.6212 100.039 75.8946L130.748 6.15079C135.836 5.33136 141.026 5.39476 146.092 6.33822Z"
            fill="white"
        />
        <path
            opacity="0.1"
            d="M171.366 20.3454L137.939 96.2596C130.996 96.267 124.145 94.6772 117.914 91.6133L154.413 8.71484C160.88 11.2437 166.679 15.2227 171.366 20.3454Z"
            fill="white"
        />
        <path
            d="M100.56 20.3812C106.6 13.0009 114.693 7.57748 123.815 4.79663C132.937 2.01579 142.679 2.00246 151.809 4.75835C160.939 7.51424 169.047 12.9156 175.107 20.2793C181.167 27.643 184.907 36.6384 185.855 46.128C186.803 55.6175 184.915 65.175 180.431 73.5919C175.947 82.0087 169.068 88.9069 160.664 93.414C152.259 97.9212 142.707 99.8349 133.215 98.9132C123.723 97.9915 114.717 94.2758 107.337 88.2359C97.4411 80.1358 91.168 68.4368 89.8971 55.7119C88.6262 42.9871 92.4616 30.2785 100.56 20.3812ZM172.138 78.9581C177.682 72.1809 181.092 63.9119 181.937 55.1967C182.782 46.4815 181.024 37.7114 176.885 29.9956C172.745 22.2797 166.411 15.9644 158.683 11.8484C150.954 7.73241 142.179 6.00047 133.466 6.87159C124.754 7.74272 116.495 11.1778 109.735 16.7424C102.974 22.3071 98.0159 29.7514 95.4862 38.1341C92.9565 46.5168 92.9693 55.4614 95.5229 63.8368C98.0765 72.2122 103.056 79.6424 109.833 85.1877C114.332 88.8701 119.514 91.6301 125.08 93.31C130.647 94.9899 136.49 95.5567 142.276 94.9783C148.061 94.3998 153.677 92.6873 158.8 89.9385C163.924 87.1897 168.456 83.4586 172.138 78.9581Z"
            fill="#D7D9EC"
        />
        <path
            d="M176.397 78.79C181.146 81.6261 185.664 84.7434 190.104 87.9593C194.543 91.1752 198.868 94.5243 203.125 97.9622C207.382 101.4 211.559 104.937 215.589 108.651C217.608 110.505 219.581 112.409 221.508 114.362C223.441 116.335 225.34 118.308 227.131 120.449C227.544 120.944 227.772 121.569 227.776 122.214C227.779 122.86 227.557 123.486 227.149 123.986C226.74 124.486 226.17 124.828 225.536 124.953C224.903 125.078 224.246 124.978 223.678 124.671C221.212 123.339 218.904 121.869 216.6 120.365C214.297 118.861 212.048 117.292 209.828 115.679C205.389 112.463 201.093 109.075 196.886 105.578C192.678 102.081 188.535 98.5047 184.505 94.7857C180.476 91.0667 176.525 87.2589 172.806 83.165C172.341 82.6587 172.075 82.0019 172.056 81.3151C172.036 80.6283 172.265 79.9576 172.701 79.4259C173.136 78.8943 173.748 78.5373 174.425 78.4205C175.102 78.3037 175.799 78.4349 176.387 78.79H176.397Z"
            fill="#D7D9EC"
        />
        <path
            d="M151.839 86.0963C152.274 84.9083 153.059 83.8799 154.09 83.1465C155.121 82.4131 156.35 82.0091 157.615 81.9876H197.099C197.781 81.9621 198.46 82.0866 199.089 82.3524C199.718 82.6182 200.281 83.0187 200.738 83.5258C201.195 84.0329 201.536 84.6341 201.735 85.287C201.935 85.94 201.989 86.6287 201.893 87.3047L191.693 172.886C191.476 174.343 190.749 175.676 189.64 176.645C188.531 177.615 187.113 178.158 185.641 178.179H60.3589C59.6789 178.205 59.0015 178.081 58.3744 177.817C57.7473 177.553 57.1857 177.155 56.7291 176.65C56.2726 176.146 55.9321 175.547 55.7318 174.897C55.5314 174.247 55.4761 173.56 55.5695 172.886L64.1222 100.982C64.3387 99.5253 65.0664 98.1928 66.1752 97.2232C67.2839 96.2536 68.7015 95.7101 70.1742 95.6898H138.349C141.303 95.6394 144.173 94.696 146.581 92.9837C148.989 91.2713 150.822 88.8703 151.839 86.0963Z"
            fill="#D7D9EC"
        />
        <path
            d="M57.8223 174.239H188.732L196.19 86.1562H65.2849L57.8223 174.239Z"
            fill="#F0F0F0"
        />
        <path
            d="M57.8223 174.239H188.732L194.128 86.1562H65.2849L57.8223 174.239Z"
            fill="#E0E0E0"
        />
        <path
            d="M57.8223 174.238H188.732L193.23 83.3594H62.3255L57.8223 174.238Z"
            fill="#F0F0F0"
        />
        <path
            d="M57.8223 174.238H188.732L190.513 83.3594H62.3255L57.8223 174.238Z"
            fill="#E0E0E0"
        />
        <path
            d="M57.8223 174.238H188.732L189.511 80.8926H58.6065L57.8223 174.238Z"
            fill="#F0F0F0"
        />
        <path
            d="M57.8241 174.238H188.734L186.046 82.6582H55.9844L57.8241 174.238Z"
            fill="#E0E0E0"
        />
        <path
            d="M57.8212 174.238H188.731L184.844 83.3594H53.9395L57.8212 174.238Z"
            fill="#F0F0F0"
        />
        <path
            d="M57.8238 174.238H188.734L182.543 84.7305H51.6387L57.8238 174.238Z"
            fill="#E0E0E0"
        />
        <path
            d="M57.8259 174.237H188.736L181.559 85.4941H50.6543L57.8259 174.237Z"
            fill="#F0F0F0"
        />
        <path
            d="M123.379 91.6553C123.387 91.083 123.509 90.518 123.739 89.994C123.969 89.47 124.303 88.9977 124.719 88.6051C125.136 88.2126 125.627 87.9078 126.164 87.709C126.7 87.5102 127.271 87.4214 127.843 87.4479H167.327C168.855 87.5066 170.321 88.0713 171.494 89.0533C172.667 90.0353 173.481 91.379 173.808 92.8736L189.409 172.778C189.991 175.772 188.038 178.204 185.044 178.204H59.7618C56.763 178.204 53.843 175.772 53.2758 172.778L40.4073 106.906C39.8253 103.912 41.7785 101.481 44.7724 101.481H112.952C118.871 101.481 123.177 97.4064 123.379 91.6553Z"
            fill="#D7D9EC"
        />
        <path
            opacity="0.2"
            d="M123.379 91.6553C123.387 91.083 123.509 90.518 123.739 89.994C123.969 89.47 124.303 88.9977 124.719 88.6051C125.136 88.2126 125.627 87.9078 126.164 87.709C126.7 87.5102 127.271 87.4214 127.843 87.4479H167.327C168.855 87.5066 170.321 88.0713 171.494 89.0533C172.667 90.0353 173.481 91.379 173.808 92.8736L189.409 172.778C189.991 175.772 188.038 178.204 185.044 178.204H59.7618C56.763 178.204 53.843 175.772 53.2758 172.778L40.4073 106.906C39.8253 103.912 41.7785 101.481 44.7724 101.481H112.952C118.871 101.481 123.177 97.4064 123.379 91.6553Z"
            fill="#9C9FD0"
        />
    </svg>
);
