import { Button, Tooltip } from '@mui/material';
import React, { useContext } from 'react';
import { toggleHighlight } from '../../analytics/analytics';
import { AppContext } from '../../providers/app-provider';
import { HighlighterOutlined } from '../icons/highlighter-outlined';
import { HighlighterFilled } from '../icons/highlighter-filled';

interface IProps {
    min?: boolean;
    size?: number;
}

export const ToggleHighLight = (props: IProps) => {
    const appContext = useContext(AppContext);
    return (
        <>
            {appContext && (
                <Tooltip
                    arrow
                    placement="top"
                    title={
                        props.min
                            ? (appContext.showHighlights ? 'Hide' : 'Show') +
                              ' Highlights'
                            : ''
                    }
                >
                    <Button
                        onClick={() => {
                            toggleHighlight(!appContext.showHighlights, '');
                            appContext.setShowHighlights(
                                !appContext.showHighlights
                            );
                        }}
                        sx={{
                            marginLeft: 'auto',
                            minWidth: 0,
                            width: props.min ? '' : 150,
                        }}
                        startIcon={
                            appContext.showHighlights ? (
                                <HighlighterFilled size={props.size} />
                            ) : (
                                <HighlighterOutlined size={props.size} />
                            )
                        }
                    >
                        {props.min
                            ? ''
                            : appContext.showHighlights
                            ? 'Hide Highlights'
                            : 'Show Highlights'}
                    </Button>
                </Tooltip>
            )}
        </>
    );
};
