import { Constants } from '../constants';
import { Granularity, useInsightsService } from './insights.service';
import {
    ArticleDetailsRequest,
    ArticleDetailsResponse,
    ArticleInsight,
    ArticleInsightsResponse,
    ArticlePotentialIndicatorsResponse,
    ArticleTopicDetailsRequest,
    Insight,
    PotentialIndicatorsResponseData,
    PotentialSentiment,
} from './interfaces';
import { NetworkRequestMethod, useNetworkAPI } from './network.service';
import { useTopicService } from './topic.service';

export const useArticleService = () => {
    const { triggerRequest } = useNetworkAPI();
    const { processPotentialIndicatorsResponse } = useInsightsService();

    const fetchArticleInsight = async (
        id: string,
        summaryId?: string
    ): Promise<Insight[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: ArticleDetailsRequest = {
                id,
            };
            if (summaryId) {
                body.summaryId = parseInt(summaryId);
            }
            const result: {
                body: ArticleInsightsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getInsightDetails,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data.insights;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchArticleDetails = async (
        id: string,
        page: number,
        summaryId?: string
    ): Promise<{ data: ArticleInsight[]; count: string }> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: ArticleDetailsRequest = {
                id,
            };
            if (summaryId) {
                body.summaryId = parseInt(summaryId);
            }
            const result: {
                body: ArticleDetailsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getArticleDetails + page,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return {
                    data: result.body.data.insights,
                    count: result.headers['x-pi-content-length'],
                };
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchArticlePotentialIndicators = async (
        id: string,
        page: number,
        summaryId?: string
    ): Promise<{ data: (PotentialSentiment | null)[][]; count: string }> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: ArticleDetailsRequest = {
                id,
            };
            if (summaryId) {
                body.summaryId = parseInt(summaryId);
            }
            const result: {
                body: ArticlePotentialIndicatorsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getArticlsPotentialIndicators + page,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return {
                    data: processPotentialIndicatorsResponse(result.body.data),
                    count: result.headers['x-pi-content-length'],
                };
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchTopicArticleDetails = async (
        topicSubscriptionId: string,
        date: string,
        categoryId: string,
        page: number
    ): Promise<{ data: ArticleInsight[]; count: string }> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: ArticleTopicDetailsRequest = {
                topicSubscriptionIds: [topicSubscriptionId],
                startDate: date,
                endDate: date,
                granularity: Granularity.DAILY,
                categoryId: categoryId,
            };
            const result: {
                body: ArticleDetailsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getTopicArticleDetails + page,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return {
                    data: result.body.data.insights,
                    count: result.headers['x-pi-content-length'],
                };
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    return {
        fetchArticleDetails,
        fetchTopicArticleDetails,
        fetchArticlePotentialIndicators,
        fetchArticleInsight,
    };
};
