import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';

export interface INavigationStack {
    title: string;
    link?: string;
    enabled: boolean;
}

interface IProps {
    stack: INavigationStack[];
}

export default function Breadcrumb(props: IProps) {
    const breadcrumbs: any[] = [];
    for (let history of props.stack) {
        if (history.enabled) {
            breadcrumbs.push(
                <Typography
                    color="inherit"
                    component={Link}
                    sx={{
                        textDecoration: 'none',
                    }}
                    key={history.title}
                    to={history.link!}
                >
                    {history.title}
                </Typography>
            );
        } else {
            breadcrumbs.push(
                <Typography key={history.title} color="text.primary">
                    {history.title}
                </Typography>
            );
        }
    }
    return (
        <Stack spacing={1}>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    );
}
