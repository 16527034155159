export const RecentHeadlinesImage = (
    <svg
        width="264"
        height="200"
        viewBox="0 0 263 220"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity="0.8" clip-path="url(#clip0_152_107957)">
            <path
                d="M245.194 208.148C245.078 212.206 244.352 216.228 243.036 220.096C243.006 220.186 242.974 220.275 242.943 220.364H234.887C234.895 220.283 234.904 220.194 234.912 220.096C235.449 214.32 238.546 179.167 234.844 173.155C235.168 173.643 245.817 189.796 245.194 208.148Z"
                fill="#D7D9EC"
            />
            <path
                d="M243.901 219.804C243.761 219.992 243.614 220.18 243.464 220.365H230.826C230.922 220.205 231.033 220.017 231.162 219.804C233.25 216.279 239.429 205.744 245.166 194.817C251.33 183.077 256.986 170.892 256.51 166.482C256.656 167.477 260.92 197.798 243.901 219.804Z"
                fill="#D7D9EC"
            />
            <path
                d="M19.7739 201.993C19.947 208.036 21.0293 214.026 22.9889 219.786C23.0324 219.92 23.0806 220.052 23.1275 220.185H35.124C35.1114 220.066 35.0984 219.932 35.0858 219.786C34.2859 211.185 29.674 158.834 35.1883 149.881C34.7056 150.608 18.8419 174.662 19.7739 201.993Z"
                fill="#D7D9EC"
            />
            <path
                d="M20.6724 219.786C20.7728 219.92 20.8771 220.054 20.984 220.185H29.9835C29.9153 220.072 29.8362 219.938 29.7445 219.786C28.2578 217.277 23.8571 209.772 19.7722 201.993C15.3841 193.633 11.3549 184.956 11.6942 181.815C11.5895 182.523 8.55169 204.116 20.6724 219.786Z"
                fill="#D7D9EC"
            />
            <path
                d="M227.101 176.444H101.858C101.243 176.443 100.654 176.214 100.219 175.808C99.7845 175.401 99.5399 174.85 99.5391 174.275V145.215C99.5399 144.64 99.7845 144.089 100.219 143.682C100.654 143.275 101.243 143.047 101.858 143.046H227.101C227.715 143.047 228.305 143.275 228.74 143.682C229.174 144.089 229.419 144.64 229.42 145.215V174.274C229.419 174.849 229.174 175.401 228.74 175.808C228.305 176.214 227.715 176.443 227.101 176.444ZM101.858 143.912C101.49 143.913 101.137 144.05 100.876 144.294C100.615 144.537 100.469 144.868 100.468 145.212V174.271C100.469 174.616 100.615 174.946 100.876 175.19C101.137 175.434 101.49 175.571 101.858 175.572H227.101C227.469 175.571 227.822 175.434 228.083 175.19C228.343 174.947 228.49 174.616 228.491 174.271V145.215C228.49 144.87 228.343 144.54 228.083 144.296C227.822 144.053 227.469 143.915 227.101 143.915L101.858 143.912Z"
                fill="#4B4B4C"
            />
            <path
                d="M90.0354 211.517C95.2531 211.517 99.4828 207.561 99.4828 202.681C99.4828 197.8 95.2531 193.844 90.0354 193.844C84.8177 193.844 80.5879 197.8 80.5879 202.681C80.5879 207.561 84.8177 211.517 90.0354 211.517Z"
                fill="#A5A6AB"
            />
            <path
                d="M111.856 197.094C111.438 197.094 111.038 197.249 110.742 197.525C110.447 197.801 110.281 198.176 110.281 198.566C110.281 198.957 110.447 199.332 110.742 199.608C111.038 199.884 111.438 200.039 111.856 200.039H186.087C186.294 200.04 186.499 200.002 186.69 199.928C186.881 199.855 187.055 199.746 187.201 199.61C187.348 199.473 187.464 199.311 187.543 199.133C187.623 198.954 187.664 198.763 187.665 198.569C187.665 198.376 187.625 198.184 187.546 198.006C187.467 197.827 187.351 197.664 187.206 197.527C187.06 197.39 186.886 197.281 186.695 197.207C186.505 197.133 186.3 197.094 186.093 197.094H111.856Z"
                fill="#A5A6AB"
            />
            <path
                d="M111.857 205.93C111.439 205.93 111.039 206.084 110.743 206.36C110.447 206.635 110.28 207.01 110.279 207.4C110.278 207.791 110.444 208.166 110.738 208.442C111.033 208.719 111.433 208.875 111.851 208.876H143.799C144.216 208.876 144.617 208.721 144.912 208.445C145.208 208.168 145.373 207.794 145.373 207.403C145.373 207.013 145.208 206.638 144.912 206.362C144.617 206.086 144.216 205.93 143.799 205.93H111.857Z"
                fill="#A5A6AB"
            />
            <path
                d="M121.317 168.441C126.534 168.441 130.764 164.484 130.764 159.604C130.764 154.723 126.534 150.767 121.317 150.767C116.099 150.767 111.869 154.723 111.869 159.604C111.869 164.484 116.099 168.441 121.317 168.441Z"
                fill="#A5A6AB"
            />
            <path
                d="M143.137 154.017C142.719 154.017 142.319 154.172 142.024 154.448C141.728 154.725 141.562 155.099 141.562 155.49C141.562 155.88 141.728 156.255 142.024 156.531C142.319 156.807 142.719 156.963 143.137 156.963H217.368C217.786 156.963 218.186 156.807 218.482 156.531C218.777 156.255 218.943 155.88 218.943 155.49C218.943 155.099 218.777 154.725 218.482 154.448C218.186 154.172 217.786 154.017 217.368 154.017H143.137Z"
                fill="#A5A6AB"
            />
            <path
                d="M143.137 162.854C142.719 162.854 142.319 163.009 142.024 163.285C141.728 163.561 141.562 163.936 141.562 164.326C141.562 164.717 141.728 165.092 142.024 165.368C142.319 165.644 142.719 165.799 143.137 165.799H175.079C175.497 165.799 175.897 165.644 176.192 165.368C176.488 165.092 176.653 164.717 176.653 164.326C176.653 163.936 176.488 163.561 176.192 163.285C175.897 163.009 175.497 162.854 175.079 162.854H143.137Z"
                fill="#A5A6AB"
            />
            <path
                d="M202.769 104.514H77.5262C76.9114 104.513 76.3219 104.285 75.8872 103.878C75.4524 103.471 75.2078 102.92 75.207 102.345V2.16829C75.2078 1.5932 75.4524 1.04187 75.8872 0.635217C76.3219 0.228564 76.9114 -0.00022389 77.5262 -0.000976562H202.769C203.383 -0.00022389 203.973 0.228564 204.408 0.635217C204.842 1.04187 205.087 1.5932 205.088 2.16829V102.344C205.087 102.919 204.842 103.471 204.408 103.878C203.973 104.284 203.383 104.513 202.769 104.514V104.514ZM77.5262 0.865023C77.1577 0.865774 76.8046 1.00302 76.544 1.24674C76.2834 1.49045 76.1367 1.82078 76.1359 2.16544V102.344C76.1367 102.689 76.2834 103.019 76.544 103.263C76.8046 103.507 77.1577 103.644 77.5262 103.645H202.769C203.137 103.644 203.49 103.507 203.751 103.263C204.011 103.019 204.158 102.689 204.159 102.344V2.16829C204.158 1.82363 204.011 1.4933 203.751 1.24958C203.49 1.00587 203.137 0.86862 202.769 0.867869L77.5262 0.865023Z"
                fill="#4B4B4C"
            />
            <path
                d="M96.9865 26.4509C102.204 26.4509 106.434 22.4945 106.434 17.6141C106.434 12.7337 102.204 8.77734 96.9865 8.77734C91.7688 8.77734 87.5391 12.7337 87.5391 17.6141C87.5391 22.4945 91.7688 26.4509 96.9865 26.4509Z"
                fill="#29319B"
            />
            <path
                d="M118.807 11.7236C118.389 11.7236 117.989 11.8788 117.694 12.155C117.398 12.4312 117.232 12.8058 117.232 13.1964C117.232 13.587 117.398 13.9615 117.694 14.2377C117.989 14.5139 118.389 14.6691 118.807 14.6691H193.038C193.456 14.6691 193.856 14.5139 194.151 14.2377C194.447 13.9615 194.613 13.587 194.613 13.1964C194.613 12.8058 194.447 12.4312 194.151 12.155C193.856 11.8788 193.456 11.7236 193.038 11.7236H118.807Z"
                fill="#A5A6AB"
            />
            <path
                d="M118.807 20.5605C118.389 20.5605 117.989 20.7157 117.694 20.9919C117.398 21.2681 117.232 21.6427 117.232 22.0333C117.232 22.4239 117.398 22.7985 117.694 23.0747C117.989 23.3508 118.389 23.506 118.807 23.506H150.749C151.166 23.506 151.567 23.3508 151.862 23.0747C152.158 22.7985 152.323 22.4239 152.323 22.0333C152.323 21.6427 152.158 21.2681 151.862 20.9919C151.567 20.7157 151.166 20.5605 150.749 20.5605H118.807Z"
                fill="#A5A6AB"
            />
            <path
                d="M232.527 91.5183H107.284C106.669 91.5176 106.08 91.2889 105.645 90.8822C105.21 90.4755 104.966 89.9241 104.965 89.349V37.9388C104.966 37.3637 105.21 36.8123 105.645 36.4056C106.08 35.9989 106.669 35.7702 107.284 35.7695H232.527C233.142 35.7702 233.731 35.9989 234.166 36.4056C234.601 36.8123 234.845 37.3637 234.846 37.9388V89.349C234.845 89.9241 234.601 90.4755 234.166 90.8822C233.731 91.2889 233.142 91.5176 232.527 91.5183Z"
                fill="#29319B"
            />
            <path
                d="M132.789 53.6885C132.372 53.6885 131.971 53.8436 131.676 54.1198C131.381 54.396 131.215 54.7706 131.215 55.1612C131.215 55.5518 131.381 55.9264 131.676 56.2026C131.971 56.4788 132.372 56.6339 132.789 56.6339H207.021C207.439 56.6339 207.839 56.4788 208.134 56.2026C208.43 55.9264 208.595 55.5518 208.595 55.1612C208.595 54.7706 208.43 54.396 208.134 54.1198C207.839 53.8436 207.439 53.6885 207.021 53.6885H132.789Z"
                fill="white"
            />
            <path
                d="M132.789 62.2227C132.372 62.2227 131.971 62.3778 131.676 62.654C131.381 62.9302 131.215 63.3048 131.215 63.6954C131.215 64.086 131.381 64.4606 131.676 64.7368C131.971 65.013 132.372 65.1681 132.789 65.1681H207.021C207.439 65.1681 207.839 65.013 208.134 64.7368C208.43 64.4606 208.595 64.086 208.595 63.6954C208.595 63.3048 208.43 62.9302 208.134 62.654C207.839 62.3778 207.439 62.2227 207.021 62.2227H132.789Z"
                fill="white"
            />
            <path
                d="M132.789 70.6523C132.372 70.6523 131.971 70.8075 131.676 71.0837C131.381 71.3599 131.215 71.7345 131.215 72.1251C131.215 72.5157 131.381 72.8903 131.676 73.1665C131.971 73.4426 132.372 73.5978 132.789 73.5978H164.731C165.149 73.5978 165.549 73.4426 165.845 73.1665C166.14 72.8903 166.306 72.5157 166.306 72.1251C166.306 71.7345 166.14 71.3599 165.845 71.0837C165.549 70.8075 165.149 70.6523 164.731 70.6523H132.789Z"
                fill="white"
            />
            <path
                d="M195.817 219.52H70.575C69.9602 219.52 69.3708 219.291 68.936 218.884C68.5013 218.478 68.2567 217.926 68.2559 217.351V188.292C68.2565 187.716 68.5011 187.165 68.9359 186.758C69.3707 186.352 69.9602 186.123 70.575 186.122H195.817C196.432 186.123 197.022 186.352 197.456 186.759C197.891 187.165 198.136 187.716 198.137 188.292V217.351C198.136 217.926 197.891 218.477 197.456 218.884C197.022 219.29 196.432 219.52 195.817 219.52ZM70.575 186.988C70.2066 186.989 69.8534 187.126 69.5928 187.37C69.3323 187.614 69.1856 187.944 69.1847 188.289V217.348C69.1856 217.693 69.3323 218.023 69.5928 218.267C69.8534 218.51 70.2066 218.648 70.575 218.648H195.817C196.186 218.648 196.539 218.51 196.8 218.267C197.06 218.023 197.207 217.693 197.208 217.348V188.292C197.207 187.947 197.06 187.617 196.8 187.373C196.539 187.129 196.186 186.992 195.817 186.991L70.575 186.988Z"
                fill="#4B4B4C"
            />
        </g>
        <defs>
            <clipPath id="clip0_152_107957">
                <rect width="263" height="220" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
