import {
    Autocomplete,
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Tab,
    Tabs,
    TextField,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { TagBlock } from '../../document-insights/tags/edit-tags';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../../themes/theme';
import { Add } from '../../../components/icons/add';
import { BorderedBox, ContainedBox } from '../../../components/core/box';
import { TabPanel } from '../../../components/core/tab-panel';
import { Reports } from './reports';
import { Templates } from './templates';
import { NavigationLink } from '../../../components/core/link';
import { Link, useParams } from 'react-router-dom';
import { useDiligenceService } from '../../../services/diligence.service';
import {
    IDiligenceReportEntity,
    IDiligenceTemplate,
} from '../../../services/interfaces';
import { useSnackbar } from 'notistack';
import { useInterval } from '../../../hooks/use-interval';
import { usePermissions } from '../../../providers/permissions-provider';
import { Authorize, HtmlTooltip } from '../../../components/authorize';
import { FeatureName, SubFeatureName } from '../../../services/interfaces';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 210,
            marginTop: '10px',
            padding: ' 0px 8px',
        },
    },
};

const DEFULT_TEMPLATE: IDiligenceTemplate = {
    id: 'default',
    templateTitle: 'Default',
};

export const DiligenceSuiteHome = () => {
    const [isProcessing, setIsProcessing] = useState(false);
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    const [companyName, setCompanyname] = useState('');
    const [companyNames, setCompanyNames] = useState<string[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<string>('');
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = React.useState(0);
    const { fetchDiligencetemplates, generateReport, fetchDiligenceReports } =
        useDiligenceService();
    const [templates, setTemplates] = useState<IDiligenceTemplate[]>([]);
    const [reports, setReports] = useState<IDiligenceReportEntity[]>([]);
    const [open, setOpen] = useState(false);

    const handleClick = (newValue: string) => {
        setSelectedTemplate(newValue);
        setOpen(false); // Close the select after selecting a value
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const refreshReports = async () => {
        let data = await fetchDiligenceReports();
        if (data) {
            setReports(data);
        }
    };

    useInterval(() => {
        refreshReports();
    }, 30 * 1000);

    const refreshTemplates = async () => {
        const templatesList = await fetchDiligencetemplates();
        setTemplates([DEFULT_TEMPLATE, ...templatesList]);
    };

    useEffect(() => {
        refreshReports();
        refreshTemplates();
    }, []);

    const generateDiligenceReport = async () => {
        setIsProcessing(true);
        let res = await generateReport(
            companyNames,
            selectedTemplate == 'default' ? undefined : selectedTemplate
        );
        setIsProcessing(false);
        if (res) {
            enqueueSnackbar(
                'Report creation request submitted successfully, we will notify you via email when your report is ready.',
                {
                    variant: 'success',
                    autoHideDuration: 10000,
                }
            );
            setCompanyNames([]);
            setCompanyname('');
            refreshReports();
        } else {
            enqueueSnackbar('Report creation failed', { variant: 'error' });
        }
    };
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const handleChange = (
        event: SelectChangeEvent<typeof selectedTemplate>
    ) => {
        const {
            target: { value },
        } = event;
        setSelectedTemplate(
            // On autofill we get a stringified value.
            value
        );
    };

    const handleTagInput = (
        event: React.SyntheticEvent<Element, Event>,
        value: string[],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<string> | undefined
    ) => {
        if (['createOption', 'selectOption'].includes(reason)) {
            if (details && details?.option.trim()) {
                setCompanyNames([...companyNames, details?.option]);
                setCompanyname('');
            }
        }
        if (reason == 'removeOption') {
            if (details?.option) {
                setCompanyNames([
                    ...companyNames.filter((t: string) => t != details.option),
                ]);
            }
        }
    };

    return (
        <ContainedBox mt={10}>
            <Box display={'flex'} justifyContent={'space-around'}>
                <Box maxWidth={850} width="60%">
                    <Typography textAlign={'center'} mb={4} variant="h4">
                        Enter a list of companies to get reports
                    </Typography>
                    <Autocomplete
                        multiple
                        id="tags-filled"
                        inputValue={companyName}
                        options={[]}
                        freeSolo
                        sx={{
                            '& .MuiInputBase-root': {
                                alignItems: 'baseline',
                                fontSize: 16,
                                borderRadius: 2,
                            },
                        }}
                        defaultValue={companyNames}
                        value={[...companyNames]}
                        onChange={handleTagInput}
                        renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option: string, index: number) => (
                                <TagBlock
                                    variant="outlined"
                                    label={option}
                                    deleteIcon={
                                        <Tooltip
                                            arrow
                                            placement="top"
                                            title={`Remove`}
                                        >
                                            <CloseIcon />
                                        </Tooltip>
                                    }
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                multiline
                                autoFocus={true}
                                rows={3}
                                {...params}
                                variant="outlined"
                                placeholder="Add a company name and press Enter to list it"
                                sx={{
                                    backgroundColor: '#F9F9FF',
                                    boxShadow:
                                        '0px 11px 20px 0px rgba(5, 16, 55, 0.10)',
                                }}
                                onChange={(e) => {
                                    setCompanyname(
                                        e.target.value.slice(0, 200)
                                    );
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <Button
                                            variant="contained"
                                            disabled={!companyName}
                                            onClick={() => {
                                                if (
                                                    !companyName ||
                                                    companyNames.includes(
                                                        companyName
                                                    )
                                                ) {
                                                    return;
                                                }
                                                setCompanyNames([
                                                    ...companyNames,
                                                    companyName,
                                                ]);
                                                setCompanyname('');
                                            }}
                                        >
                                            Add
                                        </Button>
                                    ),
                                }}
                            />
                        )}
                    />
                    <Box display={'flex'} justifyContent={'center'} mt={4}>
                        <FormControl sx={{ width: 210 }}>
                            <Select
                                displayEmpty
                                value={selectedTemplate}
                                onChange={handleChange}
                                open={open}
                                onOpen={handleOpen}
                                onClose={handleClose}
                                sx={{
                                    borderRadius: 2,
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <>Attach/Create template</>;
                                    }

                                    return templates.filter(
                                        (t) => t.id == selected
                                    )[0].templateTitle;
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <Tooltip title={DEFULT_TEMPLATE.templateTitle}>
                                    <MenuItem
                                        sx={{ px: 2, borderRadius: 1 }}
                                        key={DEFULT_TEMPLATE.id}
                                        value={DEFULT_TEMPLATE.id}
                                        onClick={() =>
                                            handleClick(DEFULT_TEMPLATE.id)
                                        }
                                    >
                                        {DEFULT_TEMPLATE.templateTitle}
                                    </MenuItem>
                                </Tooltip>
                                {templates
                                    .filter((t) => t.id != DEFULT_TEMPLATE.id)
                                    .map((t: IDiligenceTemplate) => (
                                        <Authorize
                                            featureName={FeatureName.Diligence}
                                            subFeatureName={
                                                SubFeatureName.CustomTemplate
                                            }
                                        >
                                            <MenuItem
                                                disabled={
                                                    !isSubFeatureAuthorized(
                                                        FeatureName.Diligence,
                                                        SubFeatureName.CustomTemplate
                                                    )
                                                }
                                                sx={{ px: 2, borderRadius: 1 }}
                                                key={t.id}
                                                value={t.id}
                                                onClick={() =>
                                                    handleClick(t.id)
                                                }
                                            >
                                                {t.templateTitle}
                                            </MenuItem>
                                        </Authorize>
                                    ))}
                                <Box py={1}>
                                    <Divider />
                                </Box>
                                <Authorize
                                    featureName={FeatureName.Diligence}
                                    subFeatureName={
                                        SubFeatureName.CustomTemplate
                                    }
                                >
                                    <MenuItem
                                        component={NavigationLink}
                                        disabled={
                                            !isSubFeatureAuthorized(
                                                FeatureName.Diligence,
                                                SubFeatureName.CustomTemplate
                                            )
                                        }
                                        sx={{ px: 2, borderRadius: 1 }}
                                        to={'/diligence-suite/template/new'}
                                        onClick={() => {}}
                                    >
                                        <Typography
                                            variant="h6"
                                            color="primary"
                                        >
                                            <Box mr={2} component="span">
                                                <Add
                                                    size={12}
                                                    color="#29319B"
                                                />
                                            </Box>
                                            New template
                                        </Typography>
                                    </MenuItem>
                                </Authorize>
                            </Select>
                        </FormControl>
                        <Button
                            sx={{
                                padding: '4px 54px 4px 30px',
                                ml: 4,
                                fontWeight: 600,
                                borderRadius: 2,
                                fontSize: 16,
                            }}
                            variant="contained"
                            onClick={generateDiligenceReport}
                            disabled={
                                companyNames.length === 0 ||
                                !selectedTemplate ||
                                isProcessing
                            }
                        >
                            {isProcessing ? (
                                <CircularProgress
                                    size={25}
                                    sx={{
                                        color: '#ffffff',
                                        mr: 2,
                                    }}
                                />
                            ) : (
                                <Box>
                                    Generate report
                                    <ExpandCircleDownOutlinedIcon
                                        sx={{
                                            color: '#fff',
                                            transform: 'rotate(270deg)',
                                            position: 'absolute',
                                            top: 8,
                                            ml: 7.5,
                                            right: 16,
                                        }}
                                    />
                                </Box>
                            )}
                        </Button>
                    </Box>
                </Box>
            </Box>
            <BorderedBox maxWidth={'1200px'} mx={'auto'} mt={10} pt={2}>
                <Box sx={{ borderBottom: 1, pl: 6, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                    >
                        <Tab
                            sx={{ backgroundColor: 'transparent !important' }}
                            label="My Reports"
                        />
                        <Tab
                            sx={{ backgroundColor: 'transparent !important' }}
                            label="Report templates"
                        />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Reports
                        refreshReports={refreshReports}
                        reports={reports}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Templates
                        refreshtemplate={refreshTemplates}
                        templates={templates}
                    />
                </TabPanel>
            </BorderedBox>
        </ContainedBox>
    );
};
