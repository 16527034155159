import { Delete } from '@mui/icons-material';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Paper,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import React, { useState } from 'react';
import { DeleteImg } from '../../../../../components/icons/delete-img';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { UtilsService } from '../../../../../services/utils.service';
import { useSnackbar } from 'notistack';
import { IIntegrationAccount } from '../../../../../services/interfaces';
import { useDriveService } from '../../../../../services/drive.service';
import { ErrorIcon } from '../../../../../components/icons/error';

const DialogWrapper = styled(Paper)(() => ({
    width: '40%',
    maxWidth: '450px !important',
}));

interface IProps {
    integrationAccount: IIntegrationAccount;
    onDelete: () => void;
}

export const DeleteIntegration = (props: IProps) => {
    const { deleteIntegration } = useDriveService();
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleDelete = async () => {
        setIsDeleting(true);
        const res = await deleteIntegration(
            props.integrationAccount.integration_name,
            props.integrationAccount.account_id
        );
        if (res) {
            props.onDelete();
            setOpen(false);
            setIsDeleting(false);
            enqueueSnackbar('Integration account deleted successfully', {
                variant: 'success',
            });
        } else {
            setOpen(false);
            enqueueSnackbar('Failed to delete integration account', {
                variant: 'error',
            });
            setIsDeleting(false);
        }
    };
    return (
        <Box>
            <IconButton
                aria-label="close"
                onClick={() => setOpen(true)}
                sx={{
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Tooltip arrow placement="top" title={`Delete`}>
                    <DeleteOutlineRoundedIcon color="error" />
                </Tooltip>
            </IconButton>
            <Dialog PaperComponent={DialogWrapper} open={open}>
                <DialogContent>
                    <Box textAlign="center">
                        <Box>{ErrorIcon}</Box>
                        <Typography
                            color="#000033"
                            mb={2}
                            textAlign="center"
                            variant="h4"
                        >
                            Delete {props.integrationAccount.preferred_username}{' '}
                            account.
                        </Typography>
                        <Typography
                            color="#000033"
                            mb={2}
                            fontSize={14}
                            textAlign="center"
                            variant="caption"
                        >
                            You are about to delete an account Insights. Once
                            deleted, you will lose the insights for the files.
                        </Typography>
                    </Box>
                    <Box></Box>
                </DialogContent>
                <DialogActions>
                    <Grid px={6} py={4} spacing={6} container>
                        <Grid xs={6} item>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid xs={6} item>
                            <Button
                                fullWidth
                                variant="contained"
                                disabled={isDeleting}
                                onClick={handleDelete}
                            >
                                {isDeleting ? (
                                    <CircularProgress
                                        size={25}
                                        sx={{
                                            color: '#ffffff',
                                            ml: 2,
                                        }}
                                    />
                                ) : (
                                    'Delete'
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
