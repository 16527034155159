import { INetworkRequestProps, INetworkResponse } from '../network.service';

export interface IAuthRequest {
    emailId: string;
    password: string;
}

export interface IExternalTokenRequest {
    authRequest: string;
    token: string;
}

export interface IResetForgotPasswordRequest {
    emailId: string;
    newPassword: string;
}

export interface IEmailVerification {
    emailId: string;
}

export interface IOtpVerification {
    emailId: string;
    code: number;
}

export interface ICreateUserRequest {
    emailId: string;
    firstName: string;
    lastName: string;
    password: string;
    validFor: number;
    maxSeats: number;
}

export interface IPasswordResetRequest {
    newPassword: string;
    currentPassword: string;
}

export interface INetworKError {
    code: string;
    title: string;
    traceId: string;
    description: string;
    param: string;
    docURL: string;
}

export interface IAuthResponseData {
    userId: string;
    emailId: string;
    role: string;
    planType: string;
    companyId: string;
    firstName: string;
    lastName: string;
}

export interface IAuthResponse {
    success: boolean;
    status: number;
    data: IAuthResponseData;
    error: INetworKError;
}

export interface IEmailVerificationResponse {
    status: number;
    success: boolean;
    error?: IEmailVerificationResponseError;
    data?: IEmailVerificationResponseData;
}

export interface IEmailVerificationResponseData {
    emailSent: boolean;
}

export interface IEmailVerificationResponseError {
    code: string;
    title: string;
    traceId: string;
    description: string;
    param: string;
    docURL: string;
}

export interface IExternalTokenVerification {
    headers: IExternalTokenVerificationHeader;
    body: IExternalTokenVerificationBody;
}

export interface IExternalTokenVerificationHeader {
    'x-pi-bearer': string;
}

export interface IExternalTokenVerificationBody {
    status: number;
    success: boolean;
    data: IExternalTokenVerificationData;
    error: IExternalTokenVerificationError;
}

export interface IExternalTokenVerificationData {
    firstName: string;
    lastName: string;
    emailId: string;
}

export interface IExternalTokenVerificationError {
    code: string;
    title: string;
    traceId: string;
    description: string;
    param: string;
    docURL: string;
}

export interface IOtpVerificationResponse {
    headers: IOtpVerificationResponseHeader;
    body: IOtpVerificationResponseBody;
}

export interface IOtpVerificationResponseHeader {
    'x-pi-bearer': string;
}

export interface IOtpVerificationResponseBody {
    status: number;
    success: boolean;
    data: IOtpVerificationResponseData;
}

export interface IOtpVerificationResponseData {
    emailId: string;
    verified: boolean;
}

export interface ICreateUserResponse {
    status: number;
    success: boolean;
    data: ICreateUserResponseData;
}

export interface ICreateUserResponseData {
    userId: string;
    companyId: string;
    emailId: string;
}

export interface IResetForgotPassword {
    status: number;
    success: boolean;
    data: IResetForgotPasswordData;
}

export interface IResetForgotPasswordData {
    description: string;
    rowsUpdated: number;
}

export interface IPasswordResetResponse extends INetworkResponse {
    data: {
        description: string;
    };
}

export interface IPermissionsResponse extends INetworkResponse {
    data: IPermissionsResponseData;
}

export interface IPermissionsResponseData {
    accessCodes: Permission[];
}

// Enums for feature names
export enum FeatureName {
    Auth = 'AUTH',
    TopicInsights = 'TOPIC_INSIGHTS_FEATURE',
    CIRAlerts = 'CIR_ALERTS_FEATURE',
    Chatbot = 'CHATBOT_FEATURE',
    DocumentInsights = 'DOCUMENT_INSIGHTS_FEATURE',
    LiveQA = 'LIVE_QA_FEATURE',
    InstantInsights = 'INSTANT_INSIGHTS_FEATURE',
    PhotonChat = 'PHOTON_CHAT_FEATURE',
    BrowserExtension = 'BROWSER_EXTENSION_FEATURE',
    Integration = 'INTEGRATION_FEATURE',
    Diligence = 'DILIGENCE_FEATURE',
    CustomAPIs = 'CUSTOM_APIS_FEATURE',
    AllAccess = 'ALL', // AllAccess grants permissions to all features
}

// Enums for sub-feature names
export enum SubFeatureName {
    Login = 'LOGIN',
    Signup = 'SIGN_UP',
    // Document Insights Sub-Features
    ElasticSearch = 'ELASTIC_SEARCH_SUB_FEATURE',
    WebCrawl = 'WEB_CRAWL_SUB_FEATURE',
    Integrations = 'INTEGRATIONS_SUB_FEATURE',
    TeamsDocu = 'TEAMS_DOCU_SUB_FEATURE',
    MultipleFilesChat = 'MULTIPLE_FILES_CHAT_SUB_FEATURE',
    AllowedFileTypes = 'ALLOWED_FILE_TYPES_SUB_FEATURE',
    MaxFileSize = 'MAX_FILE_SIZE_SUB_FEATURE',

    // Live QA and Instant Insights Sub-Features
    InsightsMode = 'INSIGHTS_MODE_SUB_FEATURE',
    WebDocs = 'WEB_DOCS_SUB_FEATURE',
    CustomDomains = 'CUSTOM_DOMAINS_SUB_FEATURE',
    TeamsLive = 'TEAMS_LIVE_SUB_FEATURE',

    // Photon Chat Sub-Features
    ChatbotWhiteLabeling = 'CHATBOT_WHITE_LABELING_SUB_FEATURE',
    CaptureLeads = 'CAPTURE_LEADS_SUB_FEATURE',
    ExportLeads = 'EXPORT_LEADS_SUB_FEATURE',
    ConversationHistory = 'CONVERSATION_HISTORY_SUB_FEATURE',

    // Diligence Sub-Features
    DiligenceWhiteLabeling = 'DILIGENCE_WHITE_LABELING_SUB_FEATURE',
    EditableReport = 'EDITABLE_REPORT_SUB_FEATURE',
    CustomTemplate = 'CUSTOM_TEMPLATE_SUB_FEATURE',

    // Chatbot Sub-Features
    ModelSelection = 'MODEL_SELECTION_SUB_FEATURE',
    CustomApiKey = 'CUSTOM_API_KEY_SUB_FEATURE',

    // Topic Insights Sub-Features
    TopicSubscription = 'TOPIC_SUBSCRIPTION_SUB_FEATURE',
}

export interface Permission {
    featureName: FeatureName;
    subFeatures: SubFeatureName[];
}

export interface UserTopic {
    topicSubscriptionId: string;
    subscriptionName: string;
    sentiments?: SentimentChartData;
}

export interface UserCluster {
    dashboardClusterId: string;
    dashboardClusterName: string;
    userTopicSubscriptions: UserTopic[];
    highlights?: HighlightedTopicsResponseData | null;
    sentimentStatus?: boolean;
    highlightStatus?: boolean;
}

export interface UserClusters {
    allTopicSubscriptions: UserTopic[];
    clusters: UserCluster[];
}

export interface IUserClusterResponse extends INetworkResponse {
    data: UserClusters;
}

export interface HighlightIndexes {
    positive: number[][];
    negative: number[][];
}

export interface LatestInsightsResponse extends INetworkResponse {
    data: LatestInsightsResponseData;
}

export interface LatestInsightsResponseData {
    latestInsights: LatestInsight[];
}

export interface LatestInsight {
    id: string;
    topicName: string;
    topicSubscriptionId: string;
    date: string;
    sentiment: Sentiment;
    insights: Insight[];
}

export interface IDetailedInformation extends LatestInsight {
    mentions: number;
}

export interface Insight {
    summaryId: number;
    summary: string;
    highlightIndexes: HighlightIndexes;
    sources: string[];
    isPotentialIndicator: boolean;
    sentiment: Sentiment;
}

export interface PotentialSentiment {
    id: string;
    summaryId: number;
    topicName: string;
    sourceName: string;
    topicSubscriptionId: string;
    date: string;
    summary: string;
    sources: string[];
    highlightIndexes: HighlightIndexes;
}

export interface PotentialIndicatorsResponseData {
    potentialPositives: PotentialSentiment[];
    potentialNegatives: PotentialSentiment[];
}
export interface PotentialIndicatorsResponse extends INetworkResponse {
    data: PotentialIndicatorsResponseData;
}
export interface ArticlePotentialIndicatorsResponse
    extends PotentialIndicatorsResponse {}

export interface SourceDistributionIndicatorsResponse
    extends PotentialIndicatorsResponse {}

export interface SourceDistributionResponse extends INetworkResponse {
    data: SourceDistributionResponseData;
}
export interface SourceDistributionResponseData {
    sourceDistributions: SourceDistribution[];
}

export interface SourceDistribution {
    title: string;
    sentiment: Sentiment;
    mentions: number;
}

export interface ClusterSentimentsRequest {
    topicSubscriptionIds: string[];
    startDate: string;
    endDate: string;
    categoryId: string;
    granularity: string;
}

export interface TopicStockPriceRequest {
    topicSubscriptionId: string;
    startDate: string;
    endDate: string;
}
export interface TopicSentimentChartRequest extends TopicStockPriceRequest {
    categoryId: string;
}
export interface TopicStockPriceResponse extends INetworkResponse {
    data: TopicStockPriceResponseData;
}

export interface TopicStockPriceResponseData {
    ticker?: Ticker;
}

export interface Ticker {
    id: string;
    name: string;
    stockInformation: StockInformation[];
}

export interface StockInformation {
    closingPrice: number;
    date: string;
}

export interface PotentialIndicatorsRequest extends ClusterSentimentsRequest {
    sortBy?: string;
}

export interface TopicSentimentAssesmentRequest
    extends ClusterSentimentsRequest {}
export interface DetailedInsightsRequest extends ClusterSentimentsRequest {}
export interface SourceDistributionArticleInsightsRequest
    extends ClusterSentimentsRequest {
    metadataSearchTerm: string;
}

export interface ClusterSentimentsResponse extends INetworkResponse {
    data: { sentimentChartData: SentimentChartData[] };
}

export interface SentimentChartData {
    topicName: string;
    chartDetails: ChartDetail[];
    sentimentAggregate: Sentiment;
    topicSubscriptionId: string;
}

export interface Sentiment {
    positive: number | null;
    negative: number | null;
    neutral: number | null;
}

export interface ChartDetail {
    date: string;
    sentiment: Sentiment;
    stockPrice?: number;
}

export interface HighlightedTopicsResponse extends INetworkResponse {
    data: HighlightedTopicsResponseData;
}
export interface TopicSentimentAssesmentResponse extends INetworkResponse {
    data: TopicSentimentAssesmentResponseData;
}

export interface TopicSentimentAssesmentResponseData {
    sentiment: Sentiment;
    executiveSummary: string[];
    articlesSearched: number;
    indicatorsCount: Sentiment;
}

export interface IHighlightTopic {
    topicSubscriptionId: string;
    topicName: string;
}

export interface HighlightedTopicsResponseData {
    highlyPositive: IHighlightTopic[];
    moderatelyPositive: IHighlightTopic[];
    moderatelyNegative: IHighlightTopic[];
    highlyNegative: IHighlightTopic[];
}

export interface DetailedInsightsResponse extends INetworkResponse {
    data: DetailedInsightsResponseData;
}

export interface DetailedInsightsResponseData {
    detailedInformation: IDetailedInformation[];
}

export interface ArticleDetailsRequest {
    id: string;
    summaryId?: number;
}

export interface ArticleTopicDetailsRequest extends ClusterSentimentsRequest {}

export interface ArticleDetailsResponseData {
    insights: ArticleInsight[];
}
export interface ArticleDetailsResponse extends INetworkResponse {
    data: ArticleDetailsResponseData;
}

export interface IAccountAccessTokensResponse extends INetworkResponse {
    data: IAccountAccessToken[];
}

export interface ICreateAccountAccessTokensResponse extends INetworkResponse {
    data: IAccountAccessToken;
}

export interface IAccountAccessToken {
    longTermToken: string;
    tokenName: string;
    tokenId: string;
    validTill: number;
    createdOn: number;
}

export interface ArticleInsightsResponse extends INetworkResponse {
    data: ArticleInsightsResponseData;
}
export interface SourceDistributionArticleInsightsResponse
    extends INetworkResponse {
    data: ArticleDetailsResponseData;
}

export interface ArticleInsightsResponseData {
    insights: Insight[];
}

export interface ArticleInsight {
    sourceName: string;
    articleLink: string;
    summary: string;
    sentiment: Sentiment;
    highlightIndexes: HighlightIndexes;
    date: string;
}

export interface PredefinedClusterResponse extends INetworkResponse {
    data: PredefinedClusterResponseData;
}

export interface CIRAlertsSettingsResponse extends INetworkResponse {
    data: CIRAlertsSettingsResponseData;
}

export interface CIRAlertsSettingsResponseData {
    clusters: AlertsCluster[];
}

export interface AddFileRequest {
    userFileId: string;
    storageS3Link: string;
    fileName: string;
    metadata: { size: number };
}

export interface S3LinkResponse extends INetworkResponse {
    data: S3LinkResponseData;
}

export interface S3LinkResponseData {
    storageS3Link: string;
    storageS3PresignedURL: string;
    userFileId: string;
}

export interface AlertsCluster {
    dashboardClusterId: string;
    dashboardClusterName: string;
    userTopicSubscriptions: UserTopic[];
    dailyAlert: boolean;
    weeklyAlert: boolean;
    preMarketAlert: boolean;
}

export interface PredefinedClusterResponseData {
    clusters: Cluster[];
}

export interface Cluster {
    clusterId?: string;
    clusterName: string;
    topics: Topic[];
}

export interface Topic {
    topicSubscriptionId: string;
    subscriptionName: string;
    topicName?: string;
    noMatch?: boolean;
    topicId?: string;
    classification: string[];
}

export interface PredefinedTopicsResponseData {
    topics: Topic[];
}

export interface PredefinedTopicsResponse extends INetworkResponse {
    data: PredefinedTopicsResponseData;
}
export interface TopicSubscribeResponse extends INetworkResponse {}

export interface UpsertClusterRequest {
    dashboardClusterId?: string;
    dashboardClusterName: string;
    topicSubscriptionIds: string[];
}
export interface UpsertClusterResponse extends INetworkResponse {
    data: UpsertClusterResponseData;
}
export interface UpsertClusterResponseData {
    description: string;
    dashboardClusterId: string;
}

export interface FileListResponse extends INetworkResponse {
    data: FilesData;
}

export interface SharedFileListResponse extends INetworkResponse {
    data: FilesData;
}

export interface FileFiltersListResponse extends INetworkResponse {
    data: FileFiltersListResponseData;
}

export interface FileFiltersListResponseData {
    tags: Tag[];
    fileTypes: string[];
    integrationTypes: TIntegrationType[];
}

export interface Tag {
    userTagId: string;
    title: string;
    filesCount: number;
}

export interface FileDownloadLinkResponse extends INetworkResponse {
    data: { link: string };
}

export interface FilesData {
    files: DocumentFile[];
}

export interface DocumentFile {
    fileName: string;
    userFileId: string;
    status: string;
    lastUpdated: string;
    ownerFirstName: string;
    ownerLastName: string;
    aspectKeys?: string[];
    tags?: FileTag[];
    metadata?: FileMetadata;
    fileType: string;
    isOwner: boolean;
    sharedOn: string;
    ownerUserId: string;
    isShared: boolean;
}

export interface FileTag {
    userTagId?: string;
    title: string;
}

export interface FileMetadata {
    size: number;
    ext: string;
    mime: string;
}

export interface FileInsightsResponse extends INetworkResponse {
    data: InsightsResponseData;
}
export interface InsightsResponseData {
    tldr: DocumentInsight;
    standard: DocumentInsight;
    overview?: DocumentInsight;
    extended: DocumentInsight;
    documentData?: DocumentData;
    withAspects: DocumentInsight;
    groupData?: DocumentInsight;
    fileData: DocumentFile;
}

export interface DocumentData {
    sentenceCount: number;
    wordCount: number;
    characterCount: number;
    rawText: string;
}

export interface DocumentInsight {
    insights: Insight[];
    documentReduction: number;
    sentenceCount: number;
    wordCount: number;
    characterCount: number;
    sentimentAggregate: Sentiment;
    keywords: string[];
}

export interface DocumentSourcesQueryRequest {
    query: string;
    userFileIds: string[];
}

export interface DocumentQueryRequest extends DocumentSourcesQueryRequest {
    text: string;
    lang: string;
}

export interface DocumentQuerySourcesResponse extends INetworkResponse {
    data: DocumentQuerySourcesResponseData;
}

export interface DocumentQuerySourcesResponseData {
    sources: DocumentQuerySource[];
    lang: string;
    isSummaryContext: boolean;
}

export interface DocumentQuerySource {
    text: string;
    metadata: Metadata;
}

export interface Metadata {
    pageNo: number;
    fileName: string;
}

export interface DocumenFinancialsQueryRequest {
    query: string;
    articleDataIds: string[];
    promptPage: string;
}

export interface DocumentQueryResponse {
    answers: Answer[];
    description: any;
}

export interface Answer {
    answer: string;
    score: number;
}

export interface UpdateDocumentAspects {
    userFileId: string;
    aspectKeys?: string[];
    title: string;
}

export interface UpdateDocumentTags {
    userFileId?: string;
    userFileIds?: string[];
    userTags?: FileTag[];
}

export interface UpdateDocumentName {
    userFileId: string;
    title: string;
}

export interface UploadUrlData {
    url: string;
    linkType: string;
}

export interface UserGroupFileDetail {
    fileName: string;
    uploadedOn: string;
    userFileId: string;
}

export interface UpsertGroupRequest {
    groupName: string;
    userFileGroupId?: string;
    aspectKeys?: string[];
    tagKeys?: string[];
    userFileIds: string[];
}

export interface TopicQueryArticleRequest {
    query: string;
    topicSubscriptionIds: string[];
    promptPage: string;
}
export interface TopicQueryArticleResponse extends INetworkResponse {
    data: TopicQueryArticleResponseData;
}

export interface TopicQueryArticleResponseData {
    articles: Article[];
}

export interface Article {
    articleDataId: string;
    summary: string;
    sentiment: Sentiment;
    highlights: HighlightIndexes;
    sourceName: string;
    articleLink: string;
    articleTimeStamp: string;
}

export interface TopicQueryRequest {
    startDate: string;
    endDate: string;
    topicSubscriptionIds?: string[];
    topicSubscriptionId?: string;
    sources?: ChatFilterSource[];
    query: string;
    chatBotPrompt: string;
    stream: boolean;
}

export interface TopicQueryRequest1 {
    startDate: string;
    endDate: string;
    topicSubscriptionIds?: string[];
    topicSubscriptionId?: string;
    sources?: ChatFilterSource[];
    query: string;
    chatBotPrompt: string;
}

export interface DocumentInsightQueryRequest {
    userFileIds: string[];
    chatBotPrompt: string;
    query: string;
}

export interface SECFilingQueryRequest {
    topicSubscriptionId: string;
    chatBotPrompt: string;
    sources?: SECFilingQueryRequestSource[];
    query: string;
    reports?: SECFilingQueryRequestReport[];
    sections?: string[];
    stream: boolean;
}

export interface LiveTrendsQueryRequest extends LiveQueryRequest {
    geocode: string;
    resolution: string;
}

export interface SECFilingQueryRequestSource {
    year: number;
    quarter: string;
}

export interface SECFilingQueryRequestReport {
    documentType: string;
    sections: string[];
}

export interface TopicCompareQueryRequest {
    chatBotPrompt: string;
    aspectsToCompare: string[];
    topicSubscriptionIds: string[];
    stream: boolean;
}

export interface AIQueryResponse {
    id: string;
    object: string;
    created: number;
    model: string;
    choices: Choice[];
}

export interface AIQueryChatResponse {
    chunk: string;
    index: number;
    sources:
        | TopicQueryChatResponseSource[]
        | DocumentInsightChatSource[]
        | SECFilingQueryChatResponseSource[]
        | TopicComparisonSourcesResponseDataTopic[];
    chatHistoryId: string;
}

export interface QueryChatResponseSourceData {
    sources:
        | SECFilingQueryChatResponseSource[]
        | TopicQueryChatResponseSource[]
        | TopicComparisonSourcesResponseDataTopic[]
        | DocumentInsightChatSource[];
}

export interface DocumentInsightChatSource {
    citationIndex?: number;
    id: number;
    summary: string;
    pageNo?: number;
    userFileId?: string;
    fileName?: string;
    answerSource?: string;
    url?: string;
    source?: string;
    title?: string;
    datetime?: string;
    icon?: string;
    image?: string;
}

export interface SECFilingQueryChatResponseSource {
    citationIndex?: number;
    year: number;
    id: number;
    quarter: string;
    documentType: string;
    section: string;
    summary: string;
}

export interface TopicQueryChatResponseSource {
    citationIndex?: number;
    answerSource: string;
    url: string;
    source: string;
    title: string;
    datetime: string;
    icon: string;
    image?: string;
    summary: string;
}

export interface Choice {
    delta: Delta;
    index: number;
    finish_reason: string;
}

export interface Delta {
    content: string;
}

export interface TopicComparisonSourcesResponse extends INetworkResponse {
    data: TopicComparisonSourcesResponseData;
}

export interface TopicComparisonSourcesResponseData {
    promptPage: string;
    topics: TopicComparisonSourcesResponseDataTopic[];
}

export interface TopicComparisonSourcesResponseDataTopic {
    topicSubscriptionId: string;
    articles: Article[];
}

export interface TopicTrendsRequest {
    topicSubscriptionIds: string[];
    startDate: string;
    endDate: string;
    categoryId: string;
}

export interface TopicBookmarksRequest {
    chatBotPrompt: string;
    isBookmarked: boolean;
    topicSubscriptionId?: string;
}

export interface DocumentInsightBookmarksRequest {
    chatBotPrompt: string;
    isBookmarked: boolean;
    userFileIds: string[];
}

export interface TopicChatUpdateRequest {
    chatBotPrompt: string;
    isBookmarked: boolean;
    vote: string;

    chatQueryId: string;
    chatAnswerId: string;
}

export interface ChatFiltersRequest {
    topicSubscriptionIds?: string[];
    startDate?: string;
    endDate?: string;
    topicSubscriptionId?: string;
    chatBotPrompt: string;
}

export interface ChatFiltersResponse extends INetworkResponse {
    data: ChatFiltersResponseData;
}

export interface ChatFiltersResponseData {
    chatBotPrompt: string;
    sources: ChatFilterSource[] | SECFilingsFilterSource[];
}

export interface ChatFilterSource {
    scrapingSourceId: string;
    sourceName: string;
}

export interface SECFilingsFilterSource {
    year: number;
    quarter: string;
    documentType: string;
    sections: string[];
}

export interface LatestSentimentsRequest {
    topicSubscriptionIds: string[];
    granularity: string;
    categoryId: string;
}

export interface TopicTrendsResponse extends INetworkResponse {
    data: TopicTrendsResponseData;
}

export interface TopicBookmarksResponse extends INetworkResponse {
    data: TopicBookmarksResponseData;
}

export interface TopicBookmarksResponseData {
    elements:
        | TopicBookmarksResponseDataElement[]
        | SECBookmarksResponseDataElement[]
        | DocumentInsightBookmarkResponseDataElement[];
}

export interface TopicBookmarksResponseDataElement {
    chatHistoryId: string;
    chatQueryId: string;
    query: string;
    answers: TopicBookmarksResponseDataElementAnswer[];
    createdAt: string;
    isBookmarked: boolean;
    vote: 'upvote' | 'downvote' | 'neutral';
}

export interface TopicBookmarksResponseDataElementAnswer {
    chatAnswerId: string;
    answer: string;
    createdAt: string;
    isBookmarked: boolean;
    vote: 'upvote' | 'downvote' | 'neutral';
    appliedFilters: AppliedFilters;
    responseSent: {
        sources: TopicQueryChatResponseSource[];
    };
}

export interface SECBookmarksResponseDataElement {
    chatHistoryId: string;
    query: string;
    answer: string;
    createdAt: string;
    isBookmarked: boolean;
    vote: string;
    appliedFilters: AppliedFilters;
    responseSent: {
        sources: SECQueryChatResponseSource[];
    };
}

export interface DocumentInsightBookmarkResponseDataElement {
    chatHistoryId: string;
    chatQueryId: string;
    query: string;
    answers: DocumentInsightBookmarkResponseDataElementAnswer[];
    createdAt: string;
    isBookmarked: boolean;
}

export interface DocumentInsightBookmarkResponseDataElementAnswer {
    chatAnswerId: string;
    vote: 'upvote' | 'downvote' | 'neutral';
    answer: string;
    createdAt: string;
    isBookmarked: boolean;
    appliedFilters: DocumentSourcesQueryRequest;
    responseSent: DocumentInsightChatSource[];
}

export interface DocumentInsightBookmarkResponseDataElementAnswer {
    chatAnswerId: string;
    vote: 'upvote' | 'downvote' | 'neutral';
    answer: string;
    createdAt: string;
    isBookmarked: boolean;
    appliedFilters: DocumentSourcesQueryRequest;
    responseSent: DocumentInsightChatSource[];
}

export interface SECQueryChatResponseSource {
    year: number;
    quarter: string;
    section: string;
    summary: string;
    documentType: string;
}

export interface SECFilingBookmarksResponse extends INetworkResponse {
    data: SECFilingBookmarksResponseData;
}

export interface SECFilingBookmarksResponseData {
    elements: SECFilingBookmarksResponseDataElement[];
}

export interface SECFilingBookmarksResponseDataElement {
    chatHistoryId: string;
    query: string;
    chatQueryId: string;
    answers: SECFilingBookmarksResponseDataElementAnswer[];
    createdAt: string;
    isBookmarked: boolean;
}

export interface SECFilingBookmarksResponseDataElementAnswer {
    chatAnswerId: string;
    answer: string;
    createdAt: string;
    isBookmarked: boolean;
    vote: 'upvote' | 'downvote' | 'neutral';
    appliedFilters: AppliedFilters | EarningsAppliedFilters;
    responseSent: {
        sources: SECFilingQueryChatResponseSource[];
    };
}

export interface AppliedFilters {
    endDate: string;
    sources: ChatFilterSource[];
    query: string;
    topicSubscriptionIds: string[];
    startDate: string;
    topicSubscriptionId: string;
}

export interface EarningsAppliedFilters {
    sources: EarningFilter[];
    query: string;
    sections: string[];
    reports: SECFilingQueryRequestReport[];
    topicSubscriptionId: string;
}

export interface ClusterExecutiveSummaryResponse extends INetworkResponse {
    data: { executiveSummary: string };
}

export interface TopicTrendsResponseData {
    topicMetrics: TopicMetric[];
}

export interface TopicMetric {
    topicSubscriptionId: string;
    topicName: string;
    dayMetrics: TopicTrendDayMetric[];
}

export interface TopicTrendDayMetric {
    metricDate: string;
    volume: number;
}

export interface FileFilters {
    userTagIds?: string[];
    startDate?: string;
    endDate?: string;
    includeSharedFiles?: boolean;
    fileTypes?: string[];
    dataSourceIds?: string[];
}

export interface TIntegrationType {
    integrationType: TAvailableIntegrationType;
    dataSourceId: string;
    dataSourceName: string;
    accountName: string;
    filesCount: number;
}

export type TAvailableIntegrationType =
    | 'PHOTON'
    | 'ONEDRIVE'
    | 'SHAREPOINT'
    | 'DROPBOX'
    | 'GOOGLE_DRIVE';

export const EIntegrationTypes = {
    PHOTON: 'Quantum Platform',
    ONEDRIVE: 'OneDrive',
    SHAREPOINT: 'SharePoint',
    DROPBOX: 'Dropbox',
    GOOGLE_DRIVE: 'Google Drive',
};

export interface IProximityFilters {
    searchableFields: 'EVERYWHERE' | 'FILE_NAME_ONLY' | 'FILE_CONTENT_ONLY';
    fuzziness: number;
    proximity: number;
}

export interface IDocumentMatchesRequest {
    userQuery: string;
    filters: {
        userFileId: string;
        includeSharedFiles?: boolean;
    };
    searchableField: string;
    searchableFields?: string[];
    fuzziness: number;
    proximity: number;
}

export interface IDocumentMatchedResponse {
    data: {
        files: IDocumentMatchedResponseFile[];
    };
}

export interface IDocumentMatchedResponseFile {
    rawText: string;
    fileName: string;
    userFileId: string;
    matchCount: number;
}

export interface IDocumentQueryRequest {
    userQuery: string;
    searchableFields?: string[];
    searchableField?: 'EVERYWHERE' | 'FILE_NAME_ONLY' | 'FILE_CONTENT_ONLY';
    fuzziness: number;
    proximity: number;
    filters?: FileFilters;
}

export interface IDocumentQueryResponse extends INetworkRequestProps {
    data: IDocumentQuerySuggestionResponseData;
}

export interface IDocumentQuerySuggestionResponseData {
    files: IDocumentQuerySuggestionResponseFile[];
}

export interface IDocumentQuerySuggestionResponseFile {
    rawText: string;
    fileName: string;
    userFileId: string;
    isShared: boolean;
}

export interface KeyInsightsRequest {
    financeCategory: string;
    topicSubscriptionId: string;
    year: number;
    quarter: string;
}

export interface KeyInsightsResponse extends INetworkResponse {
    data: KeyInsightsResponseData;
}

export interface KeyInsightsResponseData {
    financeCategory: string;
    positiveSummaries: PositiveSummary[];
    negativeSummaries: NegativeSummary[];
}

export interface PositiveSummary {
    orderId: number;
    summary: string;
}

export interface NegativeSummary {
    orderId: number;
    summary: string;
}

export interface FinancialsSummaryResponse extends INetworkResponse {
    data: FinancialsSummaryResponseData;
}

export interface FinancialsSummaryResponseData {
    financeCategory: string;
    summaries: Statements[];
}

export interface Statements {
    speakerName: string;
    speakerRole: string;
    sections: Sections;
    speakerTexts: SpeakerText[];
}

export interface EventStatements {
    orderId: number;
    rawText: string;
    summary?: string;
    speakerName: string;
    speakerRole: string;
}

export interface SpeakerText {
    orderId: number;
    rawText: string;
    highlights: TextHighlightsIndexes;
    sentiments: Sentiments;
}

export interface Sections {
    level1: string;
    level2?: string;
}

export interface TextHighlightsIndexes extends HighlightIndexes {
    forwardLookingStatement?: number[][];
}

export interface FinancialEventsResponse extends INetworkResponse {
    data: FinancialEventsResponseData;
}

export interface FinancialEventsResponseData {
    financeCategory: string;
    forwardLookingStatements: EventStatements[];
    keyPositives: EventStatements[];
    keyNegatives: EventStatements[];
}

export interface PersonalityTraitResponseData {
    status: number;
    success: boolean;
    data: PersonalityTraitData;
}

export interface PersonalityTraitData {
    financeCategory: string;
    speakers: PersonalityTraitSpeaker[];
}

export interface PersonalityTraitSpeaker {
    speakerName: string;
    speakerRole: string;
    personalityDetectionData: PersonalityDetectionData;
}

export interface PersonalityDetectionData {
    openness: number;
    conscientiousness: number;
    extraversion: number;
    agreeableness: number;
    neuroticism: number;
}

export interface SECEventsRequest extends KeyInsightsRequest {
    documentType?: string;
}

export interface EarningsFiltersRequest {
    financeCategory: string;
    topicSubscriptionId: string;
}

export interface EarningsFiltersResponse extends INetworkResponse {
    data: EarningsFiltersResponseData;
}

export interface EarningsFiltersResponseData {
    financeCategory: string;
    filters: EarningFilter[];
}

export interface EarningFilter {
    year: number;
    quarter: string;
    documentType: string;
    articleDataIds: string[];
}

export interface SECEventsResponse extends INetworkResponse {
    data: SECEventsResponseData;
}

export interface SECEventsResponseData {
    financeCategory: string;
    forwardLookingStatements: SECEventStatements[];
    keyPositives: SECEventStatements[];
    keyNegatives: SECEventStatements[];
}

export interface SECEventStatements {
    section: string;
    summaries: EventStatements[];
}

export interface SECEventsSummaryResponse extends INetworkResponse {
    data: SECEventsSummaryResponseData;
}

export interface SECEventsSummaryResponseData {
    financeCategory: string;
    articleLink: string;
    secElements: SecElement[];
}

export interface SecElementSummary {
    orderId: number;
    summary: string;
    rawText: string;
    sentiments: Sentiments;
    highlights: TextHighlightsIndexes;
}

export interface SecElement {
    section: string;
    summaries: SecElementSummary[];
}

export interface Summary {
    orderId: number;
    summary: string;
    rawText: string;
    sentiments: Sentiments;
    highlights: TextHighlightsIndexes;
}

export interface Sentiments {
    forwardLookingStatementScore: number;
    positive: number;
    negative: number;
}

export interface IChatbotConfigResponse extends INetworkResponse {
    data: IChatbotConfig;
}

export interface IChatbotConfig {
    title: string;
    whiteListedDomains: string[];
    designConfig: IChatbotDesignConfigData;
    dataSourceConfig: DataSourceConfig;
    widgetId?: string;
    withCitations?: boolean;
    rateLimit: number;
    status: string;
    visitorInfo?: IChatbotVisitorInfo;
}

export interface IChatbotVisitorInfo {
    message: string;
    isInfoRequired?: boolean;
    mendatoryFields: IChatbotVisitorInfoField[];
    optionalFields?: IChatbotVisitorInfoField[];
}

export interface IChatbotVisitorInfoField {
    order: number;
    title: string;
    required: boolean;
    dataType: 'text' | 'number';
    multiline: boolean;
}

export interface DataSourceConfig {
    withCitations: boolean;
    server: string;
    answerFormat: 'BRIEF' | 'DESCRIPTIVE';
    customData: DataSourceConfigCustomData;
    customErrorMessage?: string;
}

export interface DataSourceConfigCustomData {
    chatBotPrompt?: string;
    searchTheWeb?: boolean;
    fileTypes: string[];
    userTagIds: string[];
}

export interface LiveQueryRequest {
    chatBotPrompt: string;
    query: string;
    stream: boolean;
    detectEntities?: boolean;
    data?: IQueryEntitiesesponseData[];
    userFileIds?: string[];
    chatThreadId?: string | null;
    settings?: any;
    filters?: FileFilters;
}

export interface IChatbotDesignConfigData {
    data: IChatbotDesignConfig;
    whiteLabelingLink?: string;
}

export interface IChatbotDesignConfig {
    version: string;
    config: IChatbotConfigData;
}

export interface IChatbotConfigData {
    appearance: IChatbotAppearance;
    behaviour: IChatbotBehaviourConfig;
    text: IChatbotTextConfig;
}

export interface IChatbotAppearance {
    colors: IChatbotColors;
    shape: IChatbotShape;
    images: IChatbotImagesConfig;
    font: IChatbotFontConfig;
}

export interface IChatbotColors {
    primary: string;
    text: string;
}

export interface IChatbotShape {
    container: IChatbotContainerConfig;
    icon?: {
        size: number;
    };
}

export interface IChatbotContainerConfig {
    width: number;
    radius: number;
}

export interface IChatbotImagesConfig {
    chatIcon: string;
    chatBot: string;
}

export interface IChatbotFontConfig {
    fontSize: number;
    fontFamily: string;
}

export interface IChatbotBehaviourConfig {
    sourcesCitation: boolean;
    autoOpen: IChatbotAutoOpenConfig;
}

export interface IChatbotAutoOpenConfig {
    frequency: 'ONCE_PER_VISIT' | 'EVERYTIME';
    delay: number;
}

export interface IChatbotTextConfig {
    title: string;
    inputPlaceholder: string;
    welcomeMessages: IChatbotWelcomeMessageConfig[];
    predefinedQuestions?: IChatbotPredefinedQuestionConfig[];
}

export interface IChatbotPredefinedQuestionConfig {
    order: number;
    question: string;
    useDocumentsForGeneratingAnswer: boolean;
    answer?: string;
}

export interface IChatbotWelcomeMessageConfig {
    order: number;
    message: IMessage;
}

export interface IMessage {
    messageType: 'INCOMING' | 'OUTGOING' | 'FILTERS_UPDATED';
    messageID: string;
    vote: 'upvote' | 'downvote' | 'neutral';
    isBookmarked: boolean;
    chatHistoryId?: string;
    chatQueryId?: string;
    chatAnswerId?: string;

    data: {
        query?: string;
        answer?: string;
        sources?:
            | DocumentInsightChatSource[]
            | SECFilingQueryChatResponseSource[]
            | IChatWidgetMessageSource[];
        filters?: IFilters;
    };
}

export interface IFilters {
    sections: string[];
    sources: SECFilingsFilterSource[];
}

export interface IChatWidgetMessageSource {
    citationIndex?: number;
    id: number;
    summary: string;
    pageNo: number;
    userFileId: string;
    fileName: string;
    answerSource: string;
    uploadedOn?: string;
    fileType?: string;
    url?: string;
    source?: string;
    title?: string;
    datetime?: string;
    icon?: string;
    image?: string;
}

export interface IChatbotsResponse extends INetworkResponse {
    data: IChatbotsResponseData;
}

export interface IChatbotsResponseData {
    totalConversationsAggregated: number;
    totalChatBots: number;
    totalUsersInteractedAggregated: number;
    widgets: Widget[];
}

export interface IChatbotConversationsStatsResponse extends INetworkResponse {
    data: IChatbotConversationsStatsResponseData;
}
export interface IChatbotConversationsStatsResponseData {
    totalConversations: number;
    thisMonth: number;
    thisWeek: number;
    today: number;
}

export interface Widget {
    widgetId: string;
    title: string;
    status: 'ACTIVE' | 'ARCHIVED' | 'DELETED' | 'DEACTIVATED';
    updatedAt: string;
    totalConversations: number;
    totalInteractedUsers: number;
}

export interface IUpsertChatbotResponse extends INetworkResponse {
    data: {
        description: string;
        widgetId: string;
    };
}

export interface IGenerateWidgetIdResponse extends INetworkResponse {
    data: string;
}

export interface WidgetImageUploadLinkResponse extends INetworkResponse {
    data: WidgetImageUploadLinkResponseData;
}

export interface WidgetImageUploadLinkResponseData {
    s3StoragePutLink: string;
    s3StorageAccessLink: string;
    bucket: string;
    path: string;
    widgetId: string;
}

export interface IWhiteLableS3LinkResponse extends INetworkResponse {
    data: IWhiteLableS3Link;
}

export interface IWhiteLableS3Link {
    s3Link: string;
    imagePresignedUrl: string;
}

export interface ChatbotSourcesCountResponse extends INetworkResponse {
    data: ChatbotSourcesCountResponseData;
}

export interface ChatbotSourcesCountResponseData {
    filtersFileCount: number;
    totalFileCount: number;
}

export interface IChatbotLeadsResponse extends INetworkResponse {
    data: IChatbotLeadsResponseData[];
}

export interface IChatbotLeadsResponseData {
    widgetSessionUserId: string;
    widgetSessionChatId: string;
    chatInitiated: boolean;
    senderEmailId: string;
    metadata: any;
    dateTime: number;
}

export interface IChatbotConversationResponse extends INetworkResponse {
    data: IChatbotConversationData;
}

export interface IChatbotConversationData {
    conversations: IChatbotConversation[];
    count: number;
}

export interface IChatbotConversation {
    widgetSessionChatId: string;
    widgetSessionUserId: string;
    sessionChat: SessionChat;
    createdAt: string;
    messageCount: number;
}

export interface SessionChat {
    query: string;
    answer: string[];
}

export interface IChatbotConversationHistory extends INetworkResponse {
    data: IChatbotConversationHistoryData;
}

export interface IChatbotConversationHistoryData {
    histories: IChatbotConversationHistoryDataHistory[];
}

export interface IChatbotConversationHistoryDataHistory {
    chatHistoryId: string;
    chatQueryId: string;
    widgetSessionChatId: string;
    query: string;
    answers: IChatbotConversationHistoryDataHistoryAnswer[];
    createdAt: string;
}
export interface IChatbotConversationHistoryDataHistoryAnswer {
    answer: string;
    chatAnswerId: string;
    createdAt: string;
    sourceData: IChatbotConversationHistoryDataHistoryAnswerSourceData;
}

export interface IChatbotConversationHistoryDataHistoryAnswerSourceData {
    stream: boolean;
    userTagIds: string[];
    fileTypes: any[];
    query: string;
    detectEntitiesFromAnswer: boolean;
    searchTheWeb: boolean;
}
export interface FinancialTrendsResponse extends INetworkResponse {
    data: FinancialTrendsResponseData;
}
export interface FinancialTrendsResponseData {
    financialTrends: FinancialTrends;
}

export interface FinancialTrends {
    yearly: FinancialTrendsDataYearly[];
    quarterly: FinancialTrendsData[];
}

export interface FinancialTrendsDataYearly {
    date: number;
    revenue: number;
    earnings: number;
}

export interface FinancialTrendsData {
    date: string;
    revenue: number;
    earnings: number;
}
export interface FinancialAllFilingsResponse extends INetworkResponse {
    data: FinancialAllFilingsResponseData;
}

export interface FinancialAllFilingsResponseData {
    elements: FinancialAllFilingsResponseDataElement[];
}

export interface FinancialAllFilingsResponseDataElement {
    financeCategory: string;
    filterElement: EarningFilter;
    articleTimestamp: string;
}

export interface FinancialInsiderTradingResponse extends INetworkResponse {
    data: FinancialInsiderTradingResponseData;
}

export interface FinancialInsiderTradingResponseData {
    elements: FinancialInsiderTradingResponseDataElement[];
}

export interface FinancialInsiderTradingResponseDataElement {
    filerName: string;
    filerRelation: string;
    transactionText: string;
    ownershipType: string;
    shareValue?: number;
    transactionDate: string;
    shareVolume: number;
}

export interface FinancialOwnershipResponse extends INetworkResponse {
    data: FinancialOwnershipResponseData;
}

export interface FinancialOwnershipResponseData {
    institutionOwnership: InstitutionOwnership[];
}

export interface InstitutionOwnership {
    value: number;
    maxAge: number;
    pctHeld: number;
    position: number;
    reportDate: string;
    organization: string;
}

export interface FinancialKeyMatrixResponse extends INetworkResponse {
    data: FinancialKeyMatrixResponseData;
}

export interface FinancialKeyMatrixResponseData {
    valuationMetrics: ValuationMetrics;
    profitabilityMetrics: ProfitabilityMetrics;
    liquidityAndSolvencyMetrics: LiquidityAndSolvencyMetrics;
    cashFlowMetrics: CashFlowMetrics;
}

export interface ValuationMetrics {
    trailing_pe: any;
    peg_ratio: number;
    forward_pe: number;
    forward_eps: number;
    price_to_book: number;
    trailings_eps: number;
    price_to_sales: any;
    enterprise_value: number;
    enterprise_to_ebitda: any;
    enterprise_to_revenue: any;
}

export interface ProfitabilityMetrics {
    ebitda: any;
    gross_margins: number;
    gross_profits: number;
    ebitda_margins: number;
    profit_margins: number;
    return_on_assets: any;
    return_on_equity: any;
    operating_margins: number;
}

export interface LiquidityAndSolvencyMetrics {
    total_cash: any;
    total_debt: any;
    quick_ratio: any;
    current_ratio: any;
    debt_to_equity: any;
    total_cash_per_share: any;
}

export interface CashFlowMetrics {
    free_cash_flow: any;
    operating_cash_flow: any;
}

export interface TopicSentimentChartResponse extends INetworkResponse {
    data: TopicSentimentChartResponseData;
}
export interface TopicSentimentChartResponseData {
    overallSentimentData: OverallSentimentData;
    elements: Element[];
}

export interface OverallSentimentData {
    positive: number;
    negative: number;
    neutral: number;
}

export interface Element {
    date: string;
    sentimentData: SentimentData;
}

export interface SentimentData {
    positive: number;
    negative: number;
}

export interface ExecutiveSummaryResponse extends INetworkResponse {
    data: ExecutiveSummaryResponseData;
}

export interface ExecutiveSummaryResponseData {
    executiveSummaries: string[];
    tickerName: string;
    sector: string;
    industry: string;
    companyDescription: string;
}

export interface PriceChartResponse extends INetworkResponse {
    data: PriceChartResponseData;
}

export interface PriceChartResponseData {
    cagr: number;
    marketMetrics: MarketMetrics;
    minClosingPrice: number;
    maxClosingPrice: number;
    elements: PriceChartResponseDataElement[];
}

export interface MarketMetrics {
    beta: number;
    book_value: number;
    market_cap: any;
    '52_week_low': number;
    '52_week_high': number;
    '50_day_average': number;
    average_volume: number;
    '200_day_average': number;
    shares_outstanding: number;
}

export interface PriceChartResponseDataElement {
    date: string;
    closingPrice: number;
    tradedVolume: number;
    volumeIndicator: 'POSITIVE' | 'NEGATIVE';
}

export interface RecentHeadlinesResponse extends INetworkResponse {
    data: RecentHeadlinesResponseData;
}

export interface RecentHeadlinesResponseData {
    elements: RecentHeadlinesResponseDataElement[];
}

export interface RecentHeadlinesResponseDataElement {
    articleDataId: string;
    title: string;
    sourceName: string;
    imageUrl: string;
    date: string;
    sentimentData: OverallSentimentData;
    summary: string;
    articleLink: string;
}

export interface LiveQueryResponse {
    chunk:
        | 'SOURCES'
        | 'SUMMARY'
        | 'LLM'
        | 'ANSWER_SENTIMENTS'
        | 'TERMINATION'
        | 'ANSWER_ENTITIES';
    data:
        | DocumentInsightChatSource[]
        | IDocumentInsightChatSources
        | QueryChatResponseSourceData
        | LiveQueryResponseDataSources
        | LiveQueryResponseLLMData
        | LiveQueryResponseTerminationData
        | HighlightIndexes
        | LiveQueryEntities[]
        | LiveQueryResponseSummaryData;
}
export interface IDocumentInsightChatSources {
    sources: DocumentInsightChatSource[];
}

export interface LiveQueryEntities {
    entityGroup: string;
    score: number;
    word: string;
    start: number;
    end: number;
}

export interface LiveInsightsQueryResponse {
    chunk:
        | 'GDELT'
        | 'TRENDS'
        | 'SEARCH'
        | 'SOURCES'
        | 'LLM'
        | 'GOOGLE_SCHOLAR'
        | 'GOOGLE_PATENTS'
        | 'YOUTUBE_DATA'
        | 'TV_CAPTIONS'
        | 'SUMMARY'
        | 'VOLUME_TRENDS'
        | 'TICKER_DATA'
        | 'GLASSDOOR'
        | 'TERMINATION'
        | 'ANSWER_SENTIMENTS'
        | 'ANSWER_ENTITIES';
    data: any;
}

export interface GooglePatentsData {
    results: GooglePatentsDataResult[];
}

export interface GooglePatentsDataResult {
    title: string;
    url: string;
    snippet: string;
    priorityDate: string;
    filingDate: string;
    publicationDate: string;
    inventor: string;
    assignee: string;
    publicationNumber: string;
    countryStatus: PatentCountryStatus[];
}

export interface PatentCountryStatus {
    countryCode: string;
    state: string;
}

export interface GoogleScholarData {
    results: GoogleScholarDataResult[];
}

export interface GoogleScholarDataResult {
    title: string;
    url: string;
    authors: string;
    paperAbstract: string;
    citationCount?: string;
}

export interface SearchTrendsData {
    entityGroupMap: EntityGroupMap[];
    interestByRegion: InterestByRegion[];
    interestOverTime: InterestOverTime[];
}

export interface EntityGroupMap {
    entityGroup: string;
    query: string;
}

export interface InterestByRegion {
    query: string;
    data: CountryScoe[];
}

export interface CountryScoe {
    code: string;
    score: number;
}

export interface InterestOverTime {
    date: string;
    data: TipicInterests[];
}

export interface TipicInterests {
    query: string;
    value: number;
}

export interface CorporateCultureData {
    entityGroup: string;
    query: string;
    results: CorporateCultureResults;
}

export interface CorporateCultureResults {
    reviews: CorporateCultureReview[];
    totalReviews: number;
    companyName: string;
    rating: CorporateCultureRating2;
    highlightedReviews: CorporateCultureHighlightedReviews;
    ceo?: CorporateCultureCeo;
    companyInfo: CorporateCultureCompanyInfo;
}

export interface CorporateCultureReview {
    url: string;
    timestamp: string;
    summary: string;
    pros: string;
    cons: string;
    score: number;
    jobTitle?: string;
    rating: CorporateCultureRating;
    advice?: string;
}

export interface CorporateCultureRating {
    overallRating: number;
    ceoRating?: string;
    businessOutlook?: string;
    workLifeBalance?: number;
    cultureAndValues?: number;
    seniorLeadership?: number;
    careerOpportunities?: number;
    compensationAndBenefits?: number;
    diversityAndInclusion?: number;
    recommendToFriend?: string;
}

export interface CorporateCultureRating2 {
    overallRating: number;
    ceoRating?: number;
    businessOutlook?: number;
    workLifeBalance: number;
    cultureAndValues: number;
    diversityAndInclusion: number;
    seniorLeadership: number;
    recommendToFriend?: number;
    careerOpportunities: number;
    compensationAndBenefits: number;
    reviewCount: number;
    ceoRatingsCount?: number;
}

export interface CorporateCultureHighlightedReviews {
    pros: CorporateCulturePro[];
    cons: CorporateCultureCon[];
}

export interface CorporateCulturePro {
    reviewCount: number;
    sentence: string;
    indexing: CorporateCultureIndexing[];
}

export interface CorporateCultureIndexing {
    position: number;
    length: number;
}

export interface CorporateCultureCon {
    reviewCount: number;
    sentence: string;
    indexing: CorporateCultureIndexing2[];
}

export interface CorporateCultureIndexing2 {
    position: number;
    length: number;
}

export interface CorporateCultureCeo {
    name?: string;
    image?: string;
}

export interface CorporateCultureCompanyInfo {
    shortname: string;
    website: string;
    logo?: string;
}

export interface FinancialInfoData {
    results: FinancialInfoDataResults[];
    topicSubscriptions: TopicSubscription[];
}

export interface FinancialInfoDataResults {
    ticker: string;
    tickerData: TickerData;
    query: string;
}

export interface TickerData {
    sector: string;
    industry: string;
    company_description: string;
    liquidity_and_solvency_metrics: LiquidityAndSolvencyMetrics;
    profitability_metrics: ProfitabilityMetrics;
    cash_flow_metrics: CashFlowMetrics;
    valuation_metrics: ValuationMetrics;
    market_metrics: MarketMetrics;
    financial_trends: FinancialTrends;
    institution_ownership: InstitutionOwnership[];
}

export interface InstituteOwnerShip {
    organization: string;
    position: number;
    value: number;
}

export interface LiveQueryResponseLLMData {
    value: string;
}

export interface LiveQueryResponseTerminationData {
    chatHistoryId: string;
    chatAnswerId: string;
    chatQueryId: string;
    chatThreadId: string;
}

export interface LiveQueryResponseSummaryData {
    server: string;
    data: string[];
}

export interface LiveQueryResponseDataSources {
    searchTime: number;
    extractTime: number;
    sources: LiveQueryResponseDataSourcesResult[];
    relatedQuestions: string[];
    relatedSearches: string[];
}

export interface LiveQueryResponseVolumeTrendsData {
    entityGroup?: string;
    query?: string;
    newsVolume: NewsVolume[];
    newsSentiment: NewsSentiment[];
}

export interface NewsVolume {
    date: string;
    value: number;
    topArticles: TopArticle[];
}

export interface TopArticle {
    url: string;
    title: string;
}

export interface NewsSentiment {
    date: string;
    value: number;
}

export interface NewsSourceCountry {
    country: string;
    data: Daum[];
}

export interface Daum {
    date: string;
    value: number;
}

export interface LiveQueryResponseDataTrends {
    entityGroup: string;
    query: string;
    interestByRegion: InterestByRegion[];
    interestOverTime: { [key: string]: number };
    relatedTopics: RelatedTopics;
    relatedQueries: RelatedQueries;
}

export interface InterestByRegion {
    name: string;
    code: string;
    score: number;
}

export interface IQueryEntitiesesponse {
    status: number;
    success: boolean;
    data: {
        data: IQueryEntitiesesponseData[];
    };
}

export interface IQueryEntitiesesponseData {
    entityGroup: string;
    score: number;
    word: string;
}

export interface TVCaptionsData {
    results: TVCaptionsDataResult[];
}

export interface TVCaptionsDataResult {
    identifier: string;
    videoUrl: string;
    title: string;
    captionText: string;
    timestamp: string;
    captionUrl: string;
    source: string;
    language: string;
    thumbnailUrl: string;
}

export interface YoutubeMediaData {
    entityGroup: string;
    query: string;
    videos: YoutubeMediaDataVideo[];
}

export interface SentimentResultResponse extends INetworkResponse {
    data: LiveSentimentsResponse;
}

export interface LiveSentimentsResponse {
    runtime: number;
    results: SentimentResult[];
    topicSubscription: TopicSubscription;
}

export interface SentimentResult {
    date: string;
    searchResults: IRecentHeadlines[];
}

export interface TopicSubscription {
    topicDetails: TopicDetails;
}

export interface TopicDetails {
    topicSubscriptionId: string;
    subscriptionName: string;
    classifierType: string;
    query: string;
}

export interface WebQueryRequest {
    chatBotPrompt: string;
    query: string;
    userFileIds?: string[];
    stream: boolean;
    searchTheWeb: boolean;
    chatHistoryId: string;
    chatQueryId: string;
    chatThreadId?: string | null;
}

export interface YoutubeMediaDataVideo {
    title: string;
    description: string;
    videoUrl: string;
    videoId: string;
    thumbnailUrl: string;
    channelName: string;
    publishedAt: string;
    captions: string;
    captionLanguage: string;
    views: number;
    likes: number;
    comments: number;
}

export interface LiveQueryResponseDataSearchResponse extends INetworkResponse {
    data: LiveQueryResponseDataSearch;
}

export interface IFetchScrapUrlsResponse extends INetworkResponse {
    data: IFetchScrapUrlsResponseData;
}

export interface IFetchScrapUrlsResponseData {
    results: IRecentHeadlines[];
}

export interface IFetchScrapUrlsResponseResult {
    text: string;
    title: string;
    pagetype: string;
    duration: number;
    image: string;
    datetime: string;
    url: string;
}

export interface LiveQueryResponseDataSearch {
    searchTime: number;
    extractTime: number;
    results: IRecentHeadlines[];
    peopleAlsoAsk: string[];
    relatedSearches: string[];
    wordmap: { [key: string]: number };
}

export interface IRecentHeadlines {
    url: string;
    source: string;
    text?: string;
    title: string;
    datetime: string;
    engine: string;
    article: string;
    position: number;
    time: number;
    image?: string;
    icon: string;
    pageType?: string;
    sentiments?: Sentiments;
}
export interface RelatedTopics {
    top: Top[];
    rising: Rising[];
}

export interface Top {
    score: number;
    topicTitle: string;
    topicType: string;
}

export interface Rising {
    score: number;
    topicTitle: string;
    topicType: string;
}

export interface RelatedQueries {
    top: Top2[];
    rising: Rising2[];
}

export interface Top2 {
    score: string;
    value: number;
}

export interface Rising2 {
    score: string;
    value: number;
}

export interface LiveQueryResponseDataSourcesResult {
    id?: number;
    citationIndex?: number;
    url: string;
    source: string;
    image?: string;
    article: string;
    title: string;
    datetime: string;
    icon?: string;
    answerSource: 'ARTICLE' | 'USER_DATA';
    userFileId?: string;
    fileName: string;
    uploadedOn: string;
    pageNo: number;
    fileType: string;
}

export interface ILiveQAFiltersResponse extends INetworkResponse {
    data: ILiveQAFiltersResponseData;
}

export interface IInsightsFiltersResponse extends INetworkResponse {
    data: IInsightsFiltersResponseData;
}

export interface LiveQueryRequest {
    chatBotPrompt: string;
    query: string;
    detectEntities?: boolean;
    data?: IQueryEntitiesesponseData[];
}

export interface LiveTrendsQueryRequest extends LiveQueryRequest {
    geocode: string;
    resolution: string;
}

export interface ILiveQAFiltersResponseData {
    dateRange: string;
    server?: string;
    insightsMode: boolean;
    onlineSearchSource?: string;
    sentimentHighlight: boolean;
    entitiesFromAnswer: boolean;
    customDomains: string[];
    fileTypes?: string[];
    userTagIds?: string[];

    userTrustedDomainId?: any;
    dataSource: string;
}

export interface IInsightsFiltersResponseData {
    dateRange: string;
    onlineSearchSource: string;
    sentimentHighlight: boolean;
    entitiesFromAnswer: boolean;
    userConfiguration: IUserConfiguration;
}

export interface IUserConfiguration {
    overview: boolean;
    chat: boolean;
    'company-analysis': boolean;
    sentiment: boolean;
    'employee-sentiment': boolean;
    'recent-headlines': boolean;
    'news-volume': boolean;
    'research-papers': boolean;
    patents: boolean;
    'web-media': boolean;
    'word-cloud': boolean;
}

export interface ICookieSetRequest {
    cookieName: string;
    cookieValue?: string;
}

export interface IIOSRequest {
    requestId: string;
    functionName: IOSBridgeFunctions;
    data: ICookieSetRequest;
}

export interface IIOSResponse {
    requestId: string;
    data: ICookieSetRequest;
}

export enum IOSBridgeFunctions {
    COOKIE_GET = 'COOKIE_GET',
    COOKIE_SET = 'COOKIE_SET',
}

export interface RecentSearchesResponse {
    status: number;
    success: boolean;
    data: RecentSearchesResponseData;
}

export interface RecentSearchesResponseData {
    searches: Search[];
}

export interface Search {
    chatQueryIds: string[];
    query: string;
}

export interface RecentSearchesRequest {
    status: number;
    success: boolean;
    data: RecentSearchesRequestData;
}

export interface RecentSearchesRequestData {
    description: string;
    rowsUpdated: number;
}

export interface IntegrationsList extends INetworkResponse {
    data: IIntegrationData;
}

export interface IIntegrationData {
    integrations: IIntegration[];
}

export interface IIntegration {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
}

export interface ILoginUrl extends INetworkResponse {
    data: ILoginUrlData;
}

export interface ILoginUrlData {
    login_url: string;
}

export interface IntegrationAccountsResponse extends INetworkResponse {
    data: IntegrationAccountsResponseData;
}

export interface IntegrationAccountsResponseData {
    accounts: IIntegrationAccount[];
}

export interface IIntegrationAccount {
    external_system_id: string;
    integration_id: string;
    preferred_username: string;
    updated_at: string;
    user_id: string;
    created_at: string;
    integration_name: string;
    account_id: string;
}

export interface DriveItemResponse extends INetworkResponse {
    data: DriveItemResponseData;
}

export interface DriveItemResponseData {
    items: IDriveItem[];
}

export interface IDriveItem {
    item_id: string;
    external_resource_id: string;
    drive_id: string;
    site_id: any;
    account_id: string;
    display_name: string;
    item_type: string;
    integration_name: string;
    is_data_source: boolean;
    level: number;
    data_source_id: any;
    next_scheduled_sync: string;
    sync_status: 'COMPLETED' | 'IN-PROGRESS' | null;
    id: string;
    last_synced: any;
    is_deleted: boolean;
    created_at: string;
    updated_at: string;
}

export interface IAddDataSourceResponse extends INetworkResponse {
    data: IDriveItem;
}

export interface IIntegrationSourcesResponse extends INetworkResponse {
    data: IIntegrationSourcesResponseData;
}

export interface IIntegrationSourcesResponseData {
    data_sources: IDriveItem[];
}

export enum EFileUploadType {
    WEB_CRAWL = 'WEB_CRAWL',
    URL = 'URL',
    TEXT = 'TEXT',
    FILE = 'FILE',
}

export interface FileUploadRequest {
    upload: EFileUploadType;
    fileDetails?: FileDetail[];
    userEnteredText?: string;
    fileName?: string;
    url?: string;
    tags?: Tag[];
}

export interface FileDetail {
    fileName: string;
    metadata?: FileMetadata;
}

export interface IFileUploadResponse {
    chunkType:
        | 'TERMINATION'
        | 'DB_UPDATE_SUCCESS'
        | 'STREAM_ERROR'
        | 'S3_UPLOAD_LINK'
        | 'URL_TYPE'
        | 'UPLOAD_PROGRESS'
        | 'UPLOAD_COMPLETE';
    data:
        | IFileUploadResponseChunkS3UploadLinkData
        | IFileUploadResponseChunkDBUpdateStatusData
        | IFileUploadResponseChunkStreamError
        | IFileUploadResponseChunkUploadProgressData;
}

export interface IFileUploadResponseChunkS3UploadLinkData {
    userFileId: string;
    fileName: string;
    preSignedUrl: string;
}

export interface IFileUploadResponseChunkDBUpdateStatusData {
    userFileId: string;
    fileName: string;
}
export interface IFileUploadResponseChunkUploadProgressData {
    value: number;
    details: IFileUploadResponseChunkUploadProgressDetails;
}
export interface IFileUploadResponseChunkStreamError {
    message: string;
    errorMessage: string;
    errorCode: string;
}

export interface IFileUploadResponseChunkUploadProgressDetails {
    userFileId: string;
    fileName: string;
    url: string;
}

export interface IFileUploadResponseChunkURLTypeDetails {
    url: string;
    type: 'WEB_ARTICLE' | 'PDF' | 'YOUTUBE';
}

export interface IPredefinedQueryResponse extends INetworkResponse {
    data: IPredefinedQueryResponseData;
}

export interface IPredefinedQueryResponseData {
    predefinedQueries: PredefinedQuery[];
}

export interface PredefinedQuery {
    order: number;
    predefinedQueryId: string;
    predefinedQuery: string;
    userTagIds: string[];
}

export interface IUpdatePredefinedQueryRequest {
    predefinedQueriesToAdd: PredefinedQueriesToAdd[];
    predefinedQueriesToDelete: string[];
    predefinedQueriesToUpdate: PredefinedQueriesToUpdate[];
}

export interface PredefinedQueriesToAdd {
    predefinedQuery: string;
    userTagIds?: string[];
}

export interface PredefinedQueriesToUpdate {
    predefinedQueryId: string;
    predefinedQuery: string;
    userTagIdsToAdd: string[];
    userTagIdsToRemove?: string[];
}

export interface IDiligenceTemplateListResponse extends INetworkResponse {
    data: IDiligenceTemplateListResponseData;
}

export interface IDiligenceTemplateListResponseData {
    templates: IDiligenceTemplate[];
}

export interface IDiligenceTemplate {
    id: string;
    templateTitle: string;
    createdAt?: string;
    lastUpdatedAt?: string;
}

export interface IDiligenceReportListResponse extends INetworkResponse {
    data: IDiligenceReportListData;
}

export interface IDiligenceReportListData {
    reports: IDiligenceReportEntity[];
}

export interface IDiligenceReportEntity {
    companyTitle: string;
    createdAt: string;
    status: string;
    id: string;
}

export interface IDiligenceTemplateConfigurationResponse
    extends INetworkResponse {
    data: IDiligenceTemplateConfigurationResponseData;
}

export interface IDiligenceTemplateConfigurationResponseData {
    id: string;
    templateTitle: string;
    configurations: IDiligenceTemplateConfiguration[];
    defaultTemplateConfiguration: SectionTemplate;
}

export interface IDiligenceTemplateConfiguration {
    id?: string;
    guid: string;
    isEnabled: boolean;
    sectionTemplate: SectionTemplate;
    templateConfiguration: TemplateConfiguration;
}

export interface SectionTemplate {
    diligenceSection: string;
    uploadedImageS3Link?: string;
    preparedBy?: string;
    enableInternalData?: boolean;
    dateRange?: string;
    answerLength?: string;
    userFilesFilter?: FileFilters;
    querySearchStartDate?: string;
    querySearchEndDate?: string;
    startDate?: string;
    endDate?: string;
    dataSource?: string;
    companyType?: string;
    enablePriceChart?: boolean;
    enableFinancialStatements?: boolean;
    enableAnalystEstimates?: boolean;
    enableFinancialTrends?: boolean;
    enableKeyRatios?: boolean;
    enableInstituteOwnership?: boolean;
    enableInsiderTrading?: boolean;
    enableInvestments?: boolean;
    enableAcquisitions?: boolean;
    onlineSearchSource?: string;
    name?: string;
    customDomains?: string[];
    explanation?: string;
    enableFundingRounds?: boolean;
    enableInvestors?: boolean;
    userTrustedDomainId?: string;
    imagePresignedUrl?: any;
    companyName?: any;
    primaryAppearance?: any;
    secondaryAppearance?: any;
    mainHeading?: any;
    subHeading?: any;
    paragraph?: any;
    website?: any;
    footerText?: any;
}

export interface TemplateConfiguration {
    relativeOrder: number;
}

export interface IDiligenceTemplateData {
    id?: string;
    templateTitle: string;
    configurations: IDiligenceTemplateConfiguration[];
    defaultTemplateConfiguration?: SectionTemplate;
}

export interface IFilesCountResponse extends INetworkResponse {
    data: IFilesCount;
}
export interface IFilesCount {
    filteredCount: number;
    totalCount: number;
}

export interface IDiligenceReportResponse extends INetworkResponse {
    data: IDiligenceReport;
}

export interface IDiligenceReport {
    reportTitle: string;
    reportModules: ReportModule[];
}

export interface ReportModule {
    section: string;
    companyDescription?: string;
    ipoStatus?: string;
    website?: string;
    industries?: string[];
    foundingDate?: string;
    founders?: string[];
    legalName?: string;
    stockSymbol?: string;
    contactEmail?: string;
    socialMedia?: ISocialMedia[];
    competitors?: Competitor[];
    employeeDetails?: EmployeeDetail[];
    strengths?: IReportSWOTData;
    weaknesses?: IReportSWOTData;
    opportunities?: IReportSWOTData;
    threats?: IReportSWOTData;
    topics?: RiskTopic[];
    articles?: HeadlineArticle[];
    sources?: ReportSectionSource[];
    id?: string;
    name?: string;
    description?: string;
    location?: Location;
    numEmployees?: string;
    lastFundingType?: string;
    sectorName?: string;
    industryName?: string;
    data?: FinancialData;
    happenings?: Happenings;
    sentimentData?: ReportSentimentData;
    potentialPositives: PotentialIndicator[];
    potentialNegatives: PotentialIndicator[];
    startDate: string;
    endDate: string;
}

export interface PotentialIndicator {
    title: string;
    summary: string;
    sourceName: string;
    sourceType: string;
    url: string;
    icon: string;
    timestamp: string;
    sentimentScore: Sentiment;
}

export interface ReportSentimentData {
    mode: string;
    data: Data;
}

export interface Data {
    articleSentiments: ArticleSentiment[];
    averagePositive: number;
    averageNegative: number;
    totalArticles: number;
}

export interface ArticleSentiment {
    date: string;
    results: SentimentArticle[];
    maxPositive: number;
    maxNegative: number;
    averagePositive: number;
    averageNegative: number;
}

export interface SentimentArticle {
    title: string;
    summary: string;
    sourceName: string;
    sourceType: string;
    url: string;
    icon: string;
    timestamp: string;
    sentimentScore: Sentiment;
}
export interface Happenings {
    text: string;
    sources: ReportSectionSource[];
}

export interface FinancialData {
    yahoo: Yahoo;
    earningsCalls?: EarningsCall[];
    fundingRoundsInfo?: FundingRoundsInfo;
    fundingRounds?: FundingRound[];
    investors?: Investor[];
    investments?: Investment[];
    acquisitions?: Acquisition[];
}

export interface Yahoo {
    stockPrices: StockPrice[];
    insiderTransactions: InsiderTransaction[];
    coreFinancials: CoreFinancials;
    minClosingPrice: number;
    maxClosingPrice: number;
    balanceSheets: BalanceSheet[];
    cagr: number;
}

export interface BalanceSheet {
    grossAccountsReceivable?: number;
    goodwill?: number;
    totalAssets?: number;
    otherShortTermInvestments?: number;
    machineryFurnitureEquipment?: number;
    otherProperties?: number;
    totalLiabilitiesNetMinorityInterest?: number;
    stockholdersEquity?: number;
    hedgingAssetsCurrent?: number;
    netTangibleAssets?: number;
    payables?: number;
    incomeTaxPayable?: number;
    grossPpe?: number;
    accountsReceivable?: number;
    currentDeferredRevenue?: number;
    rawMaterials?: number;
    investedCapital?: number;
    commonStockEquity?: number;
    longTermDebtAndCapitalLeaseObligation?: number;
    netDebt?: number;
    retainedEarnings?: number;
    accountsPayable?: number;
    payablesAndAccruedExpenses?: number;
    totalTaxPayable?: number;
    tangibleBookValue?: number;
    pensionandOtherPostRetirementBenefitPlansCurrent?: number;
    nonCurrentDeferredRevenue?: number;
    longTermDebt?: number;
    workingCapital?: number;
    currentAssets?: number;
    longTermCapitalLeaseObligation?: number;
    cashCashEquivalentsAndShortTermInvestments?: number;
    totalCapitalization?: number;
    receivables?: number;
    totalNonCurrentLiabilitiesNetMinorityInterest?: number;
    allowanceForDoubtfulAccountsReceivable?: number;
    otherNonCurrentAssets?: number;
    asOfDate: string;
    finishedGoods?: number;
    tradeandOtherPayablesNonCurrent?: number;
    currentDebtAndCapitalLeaseObligation?: number;
    capitalLeaseObligations?: number;
    inventory?: number;
    goodwillAndOtherIntangibleAssets?: number;
    otherCurrentAssets?: number;
    otherNonCurrentLiabilities?: number;
    longTermEquityInvestment?: number;
    nonCurrentDeferredLiabilities?: number;
    leases?: number;
    shareIssued?: number;
    currentLiabilities?: number;
    landAndImprovements?: number;
    cashFinancial?: number;
    ordinarySharesNumber?: number;
    cashAndCashEquivalents?: number;
    currentDebt?: number;
    totalNonCurrentAssets?: number;
    totalEquityGrossMinorityInterest?: number;
    totalDebt?: number;
    nonCurrentDeferredTaxesLiabilities?: number;
    capitalStock?: number;
    gainsLossesNotAffectingRetainedEarnings?: number;
    accumulatedDepreciation?: number;
    investmentsAndAdvances?: number;
    netPpe?: number;
    periodType: string;
    cashEquivalents?: number;
    commonStock?: number;
    otherCurrentLiabilities?: number;
    workInProcess?: number;
    otherEquityAdjustments?: number;
    otherIntangibleAssets?: number;
    currentDeferredLiabilities?: number;
    currencyCode: string;
    buildingsAndImprovements?: number;
    properties?: number;
    commercialPaper?: number;
    otherCurrentBorrowings?: number;
}

export interface IncomeStatement {
    netIncomeFromContinuingOperationNetMinorityInterest?: number;
    operatingExpense?: number;
    taxRateForCalcs?: number;
    taxProvision?: number;
    netInterestIncome?: number;
    totalExpenses?: number;
    interestIncome?: number;
    dilutedEps?: number;
    interestExpense?: number;
    otherIncomeExpense?: number;
    interestExpenseNonOperating?: number;
    researchAndDevelopment?: number;
    basicAverageShares?: number;
    netIncomeIncludingNoncontrollingInterests?: number;
    totalRevenue?: number;
    normalizedEbitda?: number;
    taxEffectOfUnusualItems?: number;
    grossProfit?: number;
    otherNonOperatingIncomeExpenses?: number;
    operatingIncome?: number;
    dilutedAverageShares?: number;
    dilutedNiAvailtoComStockholders?: number;
    reconciledCostOfRevenue?: number;
    ebit?: number;
    interestIncomeNonOperating?: number;
    pretaxIncome?: number;
    ebitda?: number;
    costOfRevenue?: number;
    reconciledDepreciation?: number;
    netNonOperatingInterestIncomeExpense?: number;
    netIncomeCommonStockholders?: number;
    totalOperatingIncomeAsReported?: number;
    netIncomeContinuousOperations?: number;
    periodType: string;
    operatingRevenue?: number;
    basicEps?: number;
    sellingGeneralAndAdministration?: number;
    normalizedIncome?: number;
    netIncome?: number;
    asOfDate: string;
    currencyCode: string;
    netIncomeFromContinuingAndDiscontinuedOperation?: number;
}

export interface CashFlow {
    changesInCash?: number;
    cashFlowFromContinuingOperatingActivities?: number;
    longTermDebtIssuance?: number;
    depreciationAndAmortization?: number;
    repaymentOfDebt?: number;
    netPpePurchaseAndSale?: number;
    gainLossOnSaleOfPpe?: number;
    stockBasedCompensation?: number;
    investingCashFlow?: number;
    netBusinessPurchaseAndSale?: number;
    changeInCashSupplementalAsReported?: number;
    issuanceOfDebt?: number;
    endCashPosition?: number;
    changeInInventory?: number;
    purchaseOfPpe?: number;
    purchaseOfIntangibles?: number;
    changeInPrepaidAssets?: number;
    changeInReceivables?: number;
    netLongTermDebtIssuance?: number;
    depreciationAmortizationDepletion?: number;
    effectOfExchangeRateChanges?: number;
    cashFlowFromContinuingInvestingActivities?: number;
    netOtherInvestingChanges?: number;
    beginningCashPosition?: number;
    netForeignCurrencyExchangeGainLoss?: number;
    interestPaidSupplementalData?: number;
    changeInOtherCurrentAssets?: number;
    deferredIncomeTax?: number;
    asOfDate: string;
    netInvestmentPurchaseAndSale?: number;
    saleOfBusiness?: number;
    netIncomeFromContinuingOperations?: number;
    commonStockIssuance?: number;
    financingCashFlow?: number;
    saleOfIntangibles?: number;
    changeInAccountPayable?: number;
    changeInPayable?: number;
    freeCashFlow?: number;
    changeInWorkingCapital?: number;
    operatingGainsLosses?: number;
    saleOfInvestment?: number;
    changeInOtherCurrentLiabilities?: number;
    depreciation?: number;
    otherNonCashItems?: number;
    purchaseOfBusiness?: number;
    changeInOtherWorkingCapital?: number;
    capitalExpenditure?: number;
    netIssuancePaymentsOfDebt?: number;
    purchaseOfInvestment?: number;
    cashFlowFromContinuingFinancingActivities?: number;
    incomeTaxPaidSupplementalData?: number;
    proceedsFromStockOptionExercised?: number;
    changeInPayablesAndAccruedExpense?: number;
    longTermDebtPayments?: number;
    periodType: string;
    netOtherFinancingCharges?: number;
    assetImpairmentCharge?: number;
    netIntangiblesPurchaseAndSale?: number;
    issuanceOfCapitalStock?: number;
    netCommonStockIssuance?: number;
    netIncome?: number;
    deferredTax?: number;
    operatingCashFlow?: number;
    currencyCode: string;
    changesInAccountReceivables?: number;
}

export interface StockPrice {
    timeLevel: string;
    closingPrice: number;
    openingPrice: number;
    highestPrice: number;
    lowestPrice: number;
    tradedVolume: number;
    indicator: string;
}

export interface InsiderTransaction {
    transactionDate: string;
    filerName: string;
    filerRelation: string;
    ownershipType: string;
    transactionText: string;
    shareVolume: number;
    shareValue?: number;
}

export interface CoreFinancials {
    ticker: string;
    sector: string;
    industry: string;
    analystRecommendations: AnalystRecommendation[];
    earningsHistory: EarningsHistory[];
    companyDescription: string;
    earningsTrends: EarningsTrend[];
    liquidityAndSolvencyMetrics: CoreFinancialsLiquidityAndSolvencyMetrics;
    profitabilityMetrics: CoreFinancialsProfitabilityMetrics;
    cashFlowMetrics: CoreFinancialsCashFlowMetrics;
    valuationMetrics: CoreFinancialsValuationMetrics;
    marketMetrics: CoreFinancialsMarketMetrics;
    financialTrends: FinancialTrends;
    instituteOwnerShip: InstituteOwnerShip[];
    incomeStatement: IncomeStatement[];
    cashFlow: CashFlow[];
}

export interface EarningsTrend {
    period: string;
    earningsEstimate: FutureEstimate;
    revenueEstimate: FutureEstimate;
    growthEstimate: GrowthEstimate;
    epsTrend: EpsTrend;
}

export interface GrowthEstimate {
    companyGrowth?: number;
    snp500Growth?: number;
}

export interface FutureEstimate {
    analysts?: number;
    lowEstimate?: number;
    highEstimate?: number;
    avgEstimate?: number;
    yearAgoEps?: number;
    yearAgoSales?: number;
}

export interface EpsTrend {
    currentEstimate?: number;
    sevenDaysAgo?: number;
    thirtyDaysAgo?: number;
    sixtyDaysAgo?: number;
    ninetyDaysAgo?: number;
}
export interface AnalystRecommendation {
    buy: number;
    strongBuy: number;
    sell: number;
    strongSell: number;
    hold: number;
    month: string;
    year: string;
}

export interface EarningsHistory {
    date: string;
    actual: number;
    estimate: number;
    difference: number;
}

export interface CoreFinancialsLiquidityAndSolvencyMetrics {
    totalCash: number;
    totalCashPerShare: number;
    totalDebt: number;
    quickRatio: number;
    currentRatio: number;
    debtToEquity: number;
}

export interface CoreFinancialsProfitabilityMetrics {
    ebidta: number;
    returnOnAssets: number;
    returnOnEquity: number;
    grossMargins: number;
    ebidtaMargins: number;
    operatingMargins: number;
    profitMargins: number;
    grossProfits: number;
}

export interface CoreFinancialsCashFlowMetrics {
    freeCashFlow: number;
    operatingCashFlow: number;
}

export interface CoreFinancialsValuationMetrics {
    enterpriseValue: number;
    forwardPe: number;
    forwardEps: number;
    trailingsEps: number;
    pegRatio: number;
    priceToBook: number;
    enterpriseToRevenue: number;
    enterpriseToEbidta: number;
    trailingPe: number;
    priceToSales: number;
}

export interface CoreFinancialsMarketMetrics {
    sharesOutstanding: number;
    beta: number;
    bookValue: number;
    peRatio: number;
    marketCap: number;
    fiftyDayAverage: number;
    twoHundredDayAverage: number;
    fiftyTwoWeekLow: number;
    fiftyTwoWeekHigh: number;
    averageVolume: number;
}

export interface InstituteOwnerShip {
    maxAge: number;
    reportDate: string;
    organization: string;
    pctHeld: number;
    position: number;
    value: number;
    pctChange: number;
}

export interface EarningsCall {
    url: string;
    title: string;
    timestamp: string;
    closestTradingDate: string;
    mp3Url: string;
    insights: Insights;
    quarter: string;
}

export interface Insights {
    sections: Statements[];
    keyInsights: KeyInsights;
    events: Events;
}

export interface KeyInsights {
    keyPositives: EventStatements[];
    keyNegatives: EventStatements[];
}

export interface Section {
    speakerName: string;
    speakerRole: string;
    sections: Sections;
    speakerTexts: SectionSpeakerText[];
}

export interface Sections {
    level1: string;
    level2?: string;
}

export interface SectionSpeakerText {
    orderId: number;
    rawText: string;
    highlights: SectionSpeakerTextHighlights;
    sentiments: SectionSpeakerTextSentiments;
}

export interface SectionSpeakerTextHighlights {
    forwardLookingStatements: number[][];
    positive: number[][];
    negative: number[][];
}

export interface SectionSpeakerTextSentiments {
    forwardLookingStatementScore: number;
    positive: number;
    negative: number;
}

export interface Events {
    forwardLookingStatements: EventStatements[];
    keyPositives: EventStatements[];
    keyNegatives: EventStatements[];
}

export interface EventsKeys {
    orderId: number;
    speakerName: string;
    speakerRole: string;
    rawText: string;
}

export interface RequestedElement {
    name: string;
    description: string;
    sources: ReportSectionSource[];
}

export interface ReportSectionSource {
    answerSource: string;
    pageNo: number;
    title: string;
    summary: string;
    sourceName: string;
    sourceType: string;
    userFileId: string;
    uploadedOn: string;
    url: string;
    icon: string;
    timestamp: string;
}

export interface Location {
    city: string;
    region: string;
    country: string;
    continent: string;
}

export interface ISocialMedia {
    platform: string;
    url: string;
}

export interface Competitor {
    name: string;
    url: string;
    imageData: string;
    position: number;
    competitorInfo: CompetitorInfo;
}

export interface CompetitorInfo {
    companyDescription: string;
    location?: Location;
    numEmployees?: string;
    lastFundingType: string;
    ipoStatus?: string;
    website?: string;
    industries: string[];
    foundingDate: string;
    founders: string[];
    legalName: string;
    stockSymbol?: string;
    contactEmail?: string;
    socialMedia: ISocialMedia[];
}

export interface EmployeeDetail {
    employeeName: string;
    firstName: string;
    lastName: string;
    role: string;
    linkedInData?: LinkedInData;
    employeeSummary?: string;
    profilePicture?: string;
}

export interface LinkedInData {
    url: string;
    profile: LinkedInProfile;
}

export interface LinkedInProfile {
    experiences: LinkedInExperience[];
    education: LinkedInEducation[];
    publications: LinkedInPublication[];
    certifications: LinkedInCertification[];
    projects: LinkedInProject[];
}

export interface LinkedInCertification {
    startsAt?: IReportDate;
    endsAt?: IReportDate;
    name: string;
}

export interface LinkedInProject {
    description: string;
    title: string;
}

export interface LinkedInExperience {
    startsAt?: IReportDate;
    companyName: string;
    companyLinkedInUrl?: string;
    roleTitle: string;
    companyLogo?: string;
    roleDescription?: string;
    endsAt?: IReportDate;
}

export interface IReportDate {
    day: number;
    month: number;
    year: number;
}

export interface LinkedInEducation {
    instituteName: string;
    courseName?: string;
    startsAt?: IReportDate;
    endsAt?: IReportDate;
}

export interface LinkedInPublication {
    name: string;
    publisher: string;
    publishedOn?: IReportDate;
    description?: string;
}

export interface IReportSWOTData {
    points: string[];
    sources: ReportSectionSource[];
}

export interface RiskTopic {
    topicName: string;
    questions: RiskTopicQuestion[];
}

export interface RiskTopicQuestion {
    question: string;
    answer: string;
    positiveAnswer: boolean;
    answerExplanation: string;
    SASBCodes: string[];
    sources: RiskTopicQuestionSource[];
}

export interface RiskTopicQuestionSource {
    title: string;
    summary: string;
    sourceName: string;
    url: string;
}

export interface HeadlineArticle {
    icon: string;
    imageUrl: string;
    title: string;
    summary: string;
    sourceName: string;
    url: string;
    timestamp: string;
}
export interface FundingRound {
    description: string;
    announcedOn: string;
    numInvestors: number;
    valueUSD: number;
    leadInvestors: LeadInvestor[];
}

export interface LeadInvestor {
    name: string;
    investorType: string;
}

export interface Investor {
    description: string;
    isLeadInvestor: boolean;
    name: string;
    investorType: string;
    partners: string[];
}
export interface Investment {
    description: string;
    isLeadInvestor: boolean;
    name: string;
    organization: string;
    valueUsd: number;
    partners: any[];
    announcedOn: string;
}

export interface Acquisition {
    organization: string;
    description: string;
    announcedOn: string;
    price?: number;
}
export interface FundingRoundsInfo {
    totalValueUSD: number;
    currency: string;
    numFundingRounds: number;
    lastFundingType: string;
    lastFundingDate: string;
}

export interface ITrustedDomain {
    id?: string;
    displayName: string;
    domains: Domain[];
}

export interface Domain {
    id?: string;
    domainName: string;
    status?: string;
}

export interface ITrustedDomainListResponse extends INetworkResponse {
    data: ITrustedDomainListResponseData;
}

export interface ITrustedDomainListResponseData {
    trustedDomains: ITrustedDomain[];
}

export interface IUsersUsageResponse extends INetworkResponse {
    data: IUsersUsageResponseData;
}
export interface IActiveSubscriptionResponse extends INetworkResponse {
    data: IActiveSubscriptionResponseData;
}

export interface IActiveSubscriptionResponseData {
    subscription_id: string;
    plan: string;
    plan_type: string;
    current_period_start: string;
    current_period_end: string;
    cancellation_date: any;
    status: 'trialing' | 'active' | 'past_due';
    add_ons: AddOn[];
    payment_method_added?: boolean;
}

export interface IAddOnsListResponse extends INetworkResponse {
    data: IAddOnsListResponseData;
}

export interface IAddOnsListResponseData {
    products: IAddOn[];
}

export interface IAddOn {
    product: string;
    increment: number;
    price: number;
    feature: string;
    quantity: number;
}

export interface AddOnForPurchase {
    product: string;
    quantity: number;
    price: number;
    increment: number;
}

export interface IInvoicesResponse extends INetworkResponse {
    data: IInvoicesResponseData;
}

export interface IInvoicesResponseData {
    invoices: IInvoice[];
}

export interface IInvoice {
    id: string;
    amount: number;
    status: string;
    invoice_date: string;
    payment_link: string;
    pdf_link: string;
    invoice_type: string;
    plan?: string;
    plan_type?: string;
    products?: AddOnForPurchase[];
    due_date?: string;
}

export interface IPaymentDetailsResponse extends INetworkResponse {
    data: IPaymentDetailsResponseData;
}

export interface ICheckoutResponse extends INetworkResponse {
    data: ICheckoutResponseData;
}
export interface ICheckoutResponseData {
    checkout_url: string;
}

export interface IPaymentDetailsResponseData {
    cards: IPaymentCard[];
}

export interface IPaymentCard {
    id: string;
    type: string;
    last_four_digits: number;
    expiry: string;
    is_default: boolean;
}

export interface AddOn {
    product: string;
    quantity: number;
}

export interface IUsersUsageResponseData {
    data: IUsersUsageList[];
}

export interface IUsersUsageList {
    userId: string;
    id: string;
    firstName: string;
    elements: IUserFeatureUsage[];
    DOCUMENTS?: number;
    QUERIES?: number;
    DILIGENCE?: number;
    [key: string]: any;
}

export interface IUserFeatureUsage {
    quotaCategoryGroup: string;
    quotaConsumed: number;
}

export interface IUsageOverviewResponse extends INetworkResponse {
    data: IUsageOverviewResponseData;
}

export interface IUsageOverviewResponseData {
    usageOverviews: IUsageOverview[];
    featureWiseBreakdowns: IFeatureWiseBreakdown[];
    notificationAlertThreshold: number;
    validTill: string;
}

export interface IUsageOverview {
    quotaCategoryGroup: string;
    quotaAllocated: number;
    quotaConsumed: number;
}

export interface IFeatureWiseBreakdown {
    quotaCategory: string;
    quotaConsumed: number;
}

export interface IUsageHistoryResponse extends INetworkResponse {
    data: IUsageHistoryResponseData;
}

export interface IUsageHistoryResponseData {
    elements: IUsageHistoryResponseDataElement[];
}

export interface IUsageHistoryResponseDataElement {
    date: string;
    categories: IUsageHistoryResponseDataElementCategory[];
}

export interface IUsageHistoryResponseDataElementCategory {
    quotaCategory: string;
    quotaConsumed: number;
}
export interface SupportData {
    photonLive: SupportFeature;
    diligence: SupportFeature;
    documentInsights: SupportFeature;
}

export interface SupportFeature {
    mainVideo: SupportVideo;
    featuredVideos: SupportVideo[];
}

export interface SupportVideo {
    title: string;
    duration: string;
    supademoURL: string;
}

export interface ServersListResponse extends INetworkResponse {
    data: ServersListResponseData;
}

export interface ServersListResponseData {
    servers: Server[];
}

export interface Server {
    quotaConsumptionAmount: number;
    name: string;
    displayName: string;
    company: string;
    description: string;
    status: 'ACTIVE' | 'DISABLED';
}

export interface IDocumentInsightFiltersResponse extends INetworkResponse {
    data: IDocumentInsightFiltersResponseData;
}

export interface IDocumentInsightFiltersResponseData {
    server: string;
}

export interface ISubscriptionsResponse extends INetworkResponse {
    data: ISubscriptionsResponseData;
}

export interface ISubscriptionsResponseData {
    plans: ISubscription[];
}

export interface ISubscription {
    plan: string;
    plan_type: string;
    price: number;
    cycle: string;
}

export interface IProratedAmountResponse extends INetworkResponse {
    data: IProratedAmountResponseData;
}

export interface IProratedAmountResponseData {
    amount: number;
    payment_date: string;
    prorated_date: number;
}

export interface IDownlodInvoiceResponse extends INetworkResponse {
    data: IDownlodInvoiceResponseData;
}

export interface IDownlodInvoiceResponseData {
    download_link: string;
}
