import { Box, Collapse, Tooltip, Typography } from '@mui/material';
import BookmarkFilledIcon from '@mui/icons-material/Bookmark';
import { useContext, useEffect, useState } from 'react';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import { ActionButton, ResponseSources } from './response-sources';
import { AppContext } from '../../../providers/app-provider';
import {
    DocumentInsightBookmarkResponseDataElement,
    DocumentInsightBookmarkResponseDataElementAnswer,
    DocumentInsightChatSource,
} from '../../../services/interfaces';
import { UtilsService } from '../../../services/utils.service';
import { useDocumentService } from '../../../services/document.service';
import { DocumentInsightsContext } from '../../../providers/document-provider';
import { BorderedBox } from '../../../components/core/box';
import { Copy } from '../../../components/icons/copy';
import { Question } from '../../../components/icons/question';
import { LiveResponse } from '../../../components/live/live-response';

interface IProps {
    userFileIds: string[];
}

export const Bookmarks = (props: IProps) => {
    const [rowNumber, setRowNumber] = useState<number>(0);
    const [expanded, setExpanded] = useState<number[]>([]);
    const [userFileIds, setUserFileIds] = useState<string[]>([]);
    const [selectedCitationIndex, setSelectedCitationIndex] = useState<
        number | undefined
    >(undefined);
    const documentInsightsContext = useContext(DocumentInsightsContext);
    const [bookmarks, setBookmarks] = useState<
        DocumentInsightBookmarkResponseDataElement[]
    >([]);
    const { fetchDocumentInsightChatBookmarks, updateDocumentInsightChat } =
        useDocumentService();
    const appContext = useContext(AppContext);
    const handleExpandSection = (index: number) => {
        if (expanded.includes(index)) {
            setExpanded([...expanded.filter((i) => i != index)]);
        } else {
            setExpanded([...expanded, index]);
        }
    };

    const processResponseStringCitation = (
        responseString: DocumentInsightBookmarkResponseDataElementAnswer,
        sources: DocumentInsightChatSource[]
    ): {
        responseString: string;
        sources: DocumentInsightChatSource[];
    } => {
        let available = 0;
        for (let i = 0; i < sources.length; i++) {
            sources[i].id = i + 1;
            if (responseString.answer.includes(`[${i + 1}]`)) {
                available++;
                sources[i].citationIndex = available;
                responseString.answer = responseString.answer
                    .split(`[${i + 1}]`)
                    .join(`[${available}]`);
            }
        }
        return {
            responseString: responseString.answer,
            sources: sources.filter((v) => v.citationIndex),
            // sources,
        };
    };

    const getSourcesText = (
        sources: DocumentInsightChatSource[] | undefined
    ) => {
        if (!sources || !sources.length) {
            return '';
        }
        return `Sources: \n\n${sources
            .filter((s: DocumentInsightChatSource) => s.citationIndex)
            .map(
                (s: DocumentInsightChatSource) =>
                    `Source [${s.citationIndex}]: Page No - ${s.pageNo}\nSummary: ${s.summary}\n`
            )
            .join('\n')}`;
    };

    const handleCitationClick = (citationIndex: number, index: number) => {
        if (!expanded.includes(index)) {
            setExpanded([...expanded, index]);
        }
        setTimeout(() => {
            setSelectedCitationIndex(citationIndex);
        }, 0);
    };

    const handleCopyToClipboard = (
        bookmark: DocumentInsightBookmarkResponseDataElement,
        answer: DocumentInsightBookmarkResponseDataElementAnswer
    ) => {
        let data = processResponseStringCitation(answer, [
            ...answer.responseSent,
        ]);
        let text = `Question: ${bookmark.query}\nAnswer: ${
            data.responseString
        }\n\n${getSourcesText(data.sources)}`;
        navigator.clipboard.writeText(text);
    };

    const refreshBookmarks = async () => {
        let data: any[] = [];
        data = await fetchDocumentInsightChatBookmarks(userFileIds);

        data = [
            ...data.map((value: DocumentInsightBookmarkResponseDataElement) => {
                value.answers = value.answers.map(
                    (a: DocumentInsightBookmarkResponseDataElementAnswer) => {
                        let citationRes = processResponseStringCitation(a, [
                            ...a.responseSent,
                        ]);
                        a.answer = citationRes.responseString;
                        a.responseSent = citationRes.sources;
                        return a;
                    }
                );

                return value;
            }),
        ];
        setBookmarks(data);
    };

    const removeBookmark = async (
        bookmark: DocumentInsightBookmarkResponseDataElement,
        answer: DocumentInsightBookmarkResponseDataElementAnswer
    ) => {
        let status = await updateDocumentInsightChat(
            bookmark.chatHistoryId,
            false,
            answer.vote,
            'DOCUMENT_INSIGHTS',
            bookmark.chatQueryId,
            answer.chatAnswerId
        );
        if (status) refreshBookmarks();
    };

    useEffect(() => {
        if (JSON.stringify(props.userFileIds) != JSON.stringify(userFileIds)) {
            setUserFileIds(props.userFileIds);
        }
    }, [props.userFileIds]);

    useEffect(() => {
        refreshBookmarks();
    }, [userFileIds]);
    return (
        <Box px={4} py={3}>
            <Box display="flex">
                <Box>
                    <BookmarkFilledIcon sx={{ color: '#E59413' }} />
                </Box>
                <Typography variant="h6">Bookmarked</Typography>
            </Box>
            <BorderedBox
                sx={{
                    backgroundColor: '#F9F9FF',
                    mt: 2.5,
                    px: 4,
                }}
            >
                {bookmarks.map(
                    (
                        bookmark: DocumentInsightBookmarkResponseDataElement,
                        index,
                        arr
                    ) => (
                        <Box
                            sx={{
                                borderBottom:
                                    index + 1 != arr.length
                                        ? '1px solid #D7D9EC'
                                        : '',
                            }}
                        >
                            <Box
                                onClick={() => handleExpandSection(index)}
                                sx={{ cursor: 'pointer' }}
                                py={4}
                                display="flex"
                            >
                                <Box width={20} mr={2}>
                                    <Question color="#000033" size={20} />
                                </Box>
                                <Box sx={{ width: 'calc(100% - 20px)' }}>
                                    {bookmark.answers.map((a) => (
                                        <Box>
                                            <Box
                                                mb={2}
                                                justifyContent="space-between"
                                                display="flex"
                                            >
                                                <Box>
                                                    <Typography
                                                        variant="h6"
                                                        color="#000033"
                                                    >
                                                        {bookmark.query}{' '}
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Remove Bookmark"
                                                        >
                                                            <ActionButton
                                                                onClick={() =>
                                                                    removeBookmark(
                                                                        bookmark,
                                                                        a
                                                                    )
                                                                }
                                                            >
                                                                <BookmarkRemoveIcon
                                                                    sx={{
                                                                        color: '#29319B',
                                                                    }}
                                                                />
                                                            </ActionButton>
                                                        </Tooltip>
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Copy"
                                                        >
                                                            <ActionButton
                                                                sx={{
                                                                    ml: 1,
                                                                }}
                                                                onClick={() => {
                                                                    handleCopyToClipboard(
                                                                        bookmark,
                                                                        a
                                                                    );
                                                                }}
                                                            >
                                                                <Copy />
                                                            </ActionButton>
                                                        </Tooltip>
                                                    </Typography>
                                                </Box>
                                                <Box display="flex">
                                                    <Tooltip
                                                        arrow
                                                        placement="top"
                                                        title={
                                                            expanded.includes(
                                                                index
                                                            )
                                                                ? 'Collapse'
                                                                : 'Expand'
                                                        }
                                                    >
                                                        <ActionButton
                                                            sx={{
                                                                '&:hover path':
                                                                    {
                                                                        fill: 'transparent !important',
                                                                    },
                                                                transform: `rotate(${
                                                                    expanded.includes(
                                                                        index
                                                                    )
                                                                        ? 180
                                                                        : 0
                                                                }deg)`,
                                                            }}
                                                            onClick={(
                                                                event
                                                            ) => {
                                                                event.stopPropagation();
                                                                handleExpandSection(
                                                                    index
                                                                );
                                                            }}
                                                        >
                                                            <svg
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17 10L15.2527 11.763C13.8592 13.1689 13.1625 13.8719 12.3133 13.9801C12.1053 14.0066 11.8947 14.0066 11.6867 13.9801C10.8375 13.8719 10.1408 13.1689 8.74731 11.763L7 10"
                                                                    stroke="#29319B"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                />
                                                            </svg>
                                                        </ActionButton>
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                            <Typography
                                                variant="caption"
                                                fontSize={14}
                                                color="#4B4B4C"
                                                className={
                                                    expanded.includes(index)
                                                        ? ''
                                                        : 'clamp-line-2'
                                                }
                                                lineHeight={1.57}
                                            >
                                                <LiveResponse
                                                    onCitationClick={(
                                                        citationIndex: number
                                                    ) =>
                                                        handleCitationClick(
                                                            citationIndex,
                                                            index
                                                        )
                                                    }
                                                    text={a.answer}
                                                />
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                fontSize={10}
                                                mt={1}
                                                color="#A5A6AB"
                                            >
                                                {UtilsService.formatStringDate(
                                                    bookmark.createdAt
                                                )}
                                            </Typography>

                                            <Collapse
                                                in={expanded.includes(index)}
                                                timeout={100}
                                                unmountOnExit
                                            >
                                                <ResponseSources
                                                    selectedCitationIndex={
                                                        selectedCitationIndex
                                                    }
                                                    messageId={
                                                        bookmark.chatHistoryId
                                                    }
                                                    sources={a.responseSent}
                                                />
                                            </Collapse>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    )
                )}
            </BorderedBox>
        </Box>
    );
};
