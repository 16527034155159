import * as React from 'react';
import Popover from '@mui/material/Popover';
import { SketchPicker } from 'react-color';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { Box } from '@mui/material';

interface IColorPickerProps {
    color: string;
    onColorChange: (hex: string) => void;
    width: number;
    height: number;
}

export default function ColorPicker(props: IColorPickerProps) {
    const [color, setColor] = useState(props.color);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<any>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleColorChange = (color: any) => {
        setColor(color.hex);
        props.onColorChange(color.hex);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Box
                aria-describedby={id}
                onClick={handleClick}
                sx={{
                    width: props.width,
                    height: props.height,
                    backgroundColor: color,
                    border: '1px solid #D7D9EC',
                }}
            ></Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <SketchPicker onChange={handleColorChange} color={color} />
            </Popover>
        </div>
    );
}
