import {
    Autocomplete,
    AutocompleteRenderOptionState,
    Button,
    Chip,
    Divider,
    FilterOptionsState,
    Grid,
    IconButton,
    InputAdornment,
    Skeleton,
    TextField,
    Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import React, { useContext, useEffect, useState } from 'react';
import { Topic, UserTopic } from '../../../services/interfaces';
import { ContainedBox } from '../../core/box';
import AddIcon from '@mui/icons-material/Add';
import { useTopicService } from '../../../services/topic.service';
import { AppContext } from '../../../providers/app-provider';
import { Input } from '../../core/input';
import { SearchInsights } from '../../icons/search-insights';
import { useSnackbar } from 'notistack';
import { clusterRequestTopicClick } from '../../../analytics/analytics';
import { UtilsService } from '../../../services/utils.service';
import { CustomPaper, SearchOption } from '../../../pages/research/insights';
import List from 'rc-virtual-list';
import { KeyboardKeys } from '../../core/enums/keyboard-keys.enum';
import { Close } from '../../icons/close';
const { distance, closest } = require('fastest-levenshtein');

const ListBoxComponent = (props: any) => {
    return (
        <List
            style={{
                marginTop: 10,
                display:
                    props.children.filter(
                        (c: any) => c.props.topic.subscriptionName
                    ).length > 0
                        ? ''
                        : 'none',
            }}
            onClick={(event: any) => {
                event.preventDefault();
                event.stopPropagation();
            }}
            data={props.children}
            height={Math.min(
                300,
                props.children.filter(
                    (c: any) => c.props.topic.subscriptionName
                ).length * 50
            )}
            itemHeight={45}
            itemKey="key"
        >
            {(option) => option}
        </List>
    );
};

interface IProps {
    addTopic: (topic: Topic) => void;
    selectedTopics: Topic[];
}

export const AvailableTopicsContainer = (props: IProps) => {
    const [open, setOpen] = useState(false);
    const optionSelected = () => {
        // closePopper();
    };
    const keyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key == KeyboardKeys.ENTER) {
            // setUserQuery(queryMessage);
            // closePopper();
        }
    };
    const [queryMessage, setQueryMessage] = useState<string>('');
    const [userQuery, setUserQuery] = useState<string>('');
    const openPopper = (meta?: string) => {
        setOpen(true);
    };
    const closePopper = () => {
        setTimeout(() => {
            setOpen(false);
        }, 200);
    };
    const appContext = useContext(AppContext);
    const [searchQuery, setSearchQuery] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const { subscribeToTopic, getPredefinedTopics } = useTopicService();

    const noOptionClick = async () => {
        const res = await subscribeToTopic(searchQuery);
        if (res) {
            enqueueSnackbar(`${searchQuery} topic request received!`);
            clusterRequestTopicClick(searchQuery);
            setSearchQuery('');
            let topics = await getPredefinedTopics();
            appContext?.setTopics([...topics]);
        }
    };

    const selected = (topic: Topic) => {
        props.addTopic(topic);
        setUserQuery(
            topic.topicName
                ? `${topic.subscriptionName} (${topic.topicName})`
                : `${topic.subscriptionName}`
        );
    };

    const handleFilterOptions = (
        options: Topic[],
        state: FilterOptionsState<Topic>
    ) => {
        let results = options
            .filter((a: Topic) => {
                let d = distance(
                    a.subscriptionName.toLowerCase(),
                    state.inputValue.toLowerCase()
                );
                if (
                    a.subscriptionName.toLowerCase() ==
                    state.inputValue.toLowerCase()
                ) {
                    d = -1;
                } else if (
                    a.subscriptionName
                        .toLowerCase()
                        .startsWith(state.inputValue.toLowerCase())
                ) {
                    d = 0;
                }
                return (
                    d < 3 ||
                    a.subscriptionName
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                );
            })
            .sort((a: Topic, b: Topic) => {
                let d1 = distance(
                    a.subscriptionName.toLowerCase(),
                    state.inputValue.toLowerCase()
                );
                let d2 = distance(
                    b.subscriptionName.toLowerCase(),
                    state.inputValue.toLowerCase()
                );
                if (
                    a.subscriptionName
                        .toLocaleLowerCase()
                        .includes(state.inputValue.toLowerCase())
                ) {
                    d1 = Math.min(d1, 1);
                }

                if (
                    b.subscriptionName
                        .toLocaleLowerCase()
                        .includes(state.inputValue.toLowerCase())
                ) {
                    d2 = Math.min(d2, 1);
                }

                if (
                    a.subscriptionName.toLowerCase() ==
                    state.inputValue.toLowerCase()
                ) {
                    d1 = -1;
                } else if (
                    a.subscriptionName
                        .toLowerCase()
                        .startsWith(state.inputValue.toLowerCase())
                ) {
                    d1 = 0;
                }

                if (
                    b.subscriptionName.toLowerCase() ==
                    state.inputValue.toLowerCase()
                ) {
                    d2 = -1;
                } else if (
                    b.subscriptionName
                        .toLowerCase()
                        .startsWith(state.inputValue.toLowerCase())
                ) {
                    d2 = 0;
                }

                return d1 < d2 ? -1 : 1;
            });
        if (
            options.filter(
                (a: Topic) =>
                    a.subscriptionName.toLocaleLowerCase() ==
                    state.inputValue.toLocaleLowerCase()
            ).length == 0
        ) {
            results.push({
                topicName: '',
                subscriptionName: '',
                topicSubscriptionId: '',
                noMatch: true,
                classification: [],
            });
        }
        return results;
    };

    return (
        <Box sx={{ maxHeight: '285px', overflowY: 'auto' }}>
            {appContext && (
                <Box>
                    <Box
                        sx={{
                            paddingLeft: appContext?.isMobile ? '0px' : '0px',
                            width: `calc(100%)`,
                        }}
                    >
                        <Autocomplete
                            open={open}
                            //@ts-ignore
                            inputValue={queryMessage}
                            ListboxComponent={ListBoxComponent}
                            //@ts-ignore
                            value={queryMessage}
                            onInputChange={(e, v) => {
                                //@ts-ignore
                                setQueryMessage(v);
                            }}
                            onKeyDown={keyPress}
                            //@ts-ignore
                            onHover={() => {
                                UtilsService.triggerEvent('searchHover');
                            }}
                            onMouseUp={() => {
                                UtilsService.triggerEvent('searchHover');
                            }}
                            onMouseOver={() => {
                                UtilsService.triggerEvent('searchHover');
                            }}
                            onBlur={() => {
                                UtilsService.triggerEvent('searchBlur');
                            }}
                            // onOpen={openPopper}
                            onClose={closePopper}
                            id="free-solo-demo"
                            sx={{
                                width: '100%',
                                '& .MuiFormControl-root': { mt: 3 },
                                '& .MuiInputBase-root': {
                                    p: '7px',
                                    borderRadius: '8px',
                                    paddingRight: '7px !important',
                                    backgroundColor: '#fff',
                                },
                            }}
                            PaperComponent={CustomPaper}
                            options={appContext.topics.filter(
                                (t) =>
                                    !props.selectedTopics
                                        .map((st) => st.topicSubscriptionId)
                                        .includes(t.topicSubscriptionId)
                            )}
                            //@ts-ignore
                            getOptionLabel={(option: Topic) => {
                                if (typeof option == 'string') return option;
                                return option.topicName
                                    ? `${option.subscriptionName} (${option.topicName})`
                                    : `${option.subscriptionName}`;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    onClick={() =>
                                        openPopper('textbox on click')
                                    }
                                    fullWidth
                                    placeholder={'Add new topics'}
                                    InputProps={{
                                        ...params.InputProps, // this is new
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Box
                                                    sx={{
                                                        height: 20,
                                                        '&>svg': {
                                                            height: 20,
                                                        },
                                                    }}
                                                >
                                                    <SearchInsights fillColor="#000033" />
                                                </Box>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <Box
                                                display={'flex'}
                                                alignItems={'center'}
                                                sx={{
                                                    pr: 2,
                                                }}
                                            >
                                                {queryMessage && (
                                                    <Box
                                                        display={'flex'}
                                                        alignItems={'center'}
                                                    >
                                                        <IconButton
                                                            sx={{
                                                                minWidth: 0,
                                                                padding: 0,
                                                                marginRight:
                                                                    '8px',
                                                                width: 'auto',
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setQueryMessage(
                                                                    ''
                                                                );
                                                            }}
                                                        >
                                                            <Close
                                                                color="#4B4B4C"
                                                                size={24}
                                                            />
                                                        </IconButton>
                                                    </Box>
                                                )}
                                            </Box>
                                        ),
                                    }}
                                />
                                // <TextField {...params} label="Highlights" variant="outlined" margin="normal" />
                            )}
                            renderOption={(
                                props: React.HTMLAttributes<HTMLLIElement>,
                                option: Topic,
                                state: AutocompleteRenderOptionState
                            ) => {
                                let index = parseInt(
                                    // @ts-ignore
                                    props['data-option-index']
                                );
                                return (
                                    <SearchOption
                                        close={optionSelected}
                                        index={index}
                                        key={index}
                                        // noOptionClick={noOptionClick}
                                        onSelected={selected}
                                        topic={option}
                                        query={queryMessage}
                                    />
                                );
                            }}
                            filterOptions={handleFilterOptions}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};
