import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
    EarningFilter,
    EarningsCall,
    ReportModule,
} from '../../../../../services/interfaces';
import { KeyInsights } from './key-insights';
import { Summary } from './summary';
import { BorderedBox } from '../../../../../components/core/box';
import SingleSelect from '../../../../../components/core/input/single-select';

interface IProps {
    // selectedEarningFilter: EarningFilter;
    data: ReportModule;
}

export const EarningCall = (props: IProps) => {
    const { data } = props;
    const [earningCall, setEarningCall] = useState<EarningsCall | null>(null);
    const [options, setOptions] = useState<EarningsCall[]>([]);
    useEffect(() => {
        if (data.data?.earningsCalls) {
            setOptions(data.data?.earningsCalls);
            if (data.data?.earningsCalls.length > 0) {
                setEarningCall(data.data?.earningsCalls[0]);
            }
        }
    }, [data]);
    return (
        <BorderedBox sx={{ backgroundColor: '#FFFFFF' }} p={3}>
            <Box display="flex" justifyContent={'space-between'}>
                <Typography variant="h6">Earnings Call Summary</Typography>
                <SingleSelect
                    getOptionText={(v: EarningsCall) => (v ? v.quarter : '')}
                    disableCheckbox
                    placeholder="Auto Open Behaviour"
                    pt={2}
                    pb={3}
                    mt={0}
                    options={options}
                    selectedValue={
                        options.filter(
                            (v) => v.quarter == earningCall?.quarter
                        )[0]
                    }
                    onChange={(v: any) => {
                        setEarningCall(v);
                    }}
                />
            </Box>
            <Grid pt={3} spacing={4} container>
                <Grid item sm={12}>
                    {earningCall && <KeyInsights earningCall={earningCall} />}
                </Grid>
            </Grid>
            <Box pt={4}>
                {earningCall && <Summary earningCall={earningCall} />}
            </Box>
        </BorderedBox>
    );
};
