//@ts-ignore
import {
    convertToRaw,
    EditorState,
    convertFromHTML,
    ContentState,
    //@ts-ignore
} from 'draft-js';
import { useEffect, useState } from 'react';
//@ts-ignore
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Fragment } from 'react';
import { Box } from '@mui/material';

interface IProps {
    content: string;
    placeholder?: string;
    onContentChange: (html: string) => void;
}

export const HtmlEditor = (props: IProps) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [text, setText] = useState();
    const onEditorStateChange = function (editorState: any) {
        setEditorState(editorState);
        const { blocks } = convertToRaw(editorState.getCurrentContent());
        let text = editorState.getCurrentContent().getPlainText('\u0001');
        setText(text);
        let htmlContent = stateToHTML(editorState.getCurrentContent());
        htmlContent = htmlContent
            .split('<a href')
            .join("<a target='_blank' href");
        props.onContentChange(htmlContent);
    };

    useEffect(() => {
        const blocksFromHTML = convertFromHTML(props.content);
        //@ts-ignore
        const content = ContentState.createFromBlockArray(blocksFromHTML);
        let state = EditorState.createWithContent(content);
        setEditorState(state);
    }, [props.content]);

    return (
        <>
            {editorState && (
                <Box
                    sx={{
                        backgroundColor: '#fff',
                        border: '1px solid #e0e0e0',
                        borderRadius: 1,
                        '& .public-DraftStyleDefault-block': {
                            margin: 0,
                        },
                        '& .DraftEditor-root': {
                            px: 2,
                        },
                    }}
                >
                    <Editor
                        placeholder={props.placeholder}
                        toolbar={{
                            options: ['inline', 'link', 'list'],
                            link: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                popupClassName: undefined,
                                dropdownClassName: undefined,
                                showOpenOptionOnHover: false,
                                defaultTargetOption: '_blank',
                                options: ['link'],
                                linkCallback: undefined,
                            },
                            inline: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: [
                                    'bold',
                                    'italic',
                                    'underline',
                                    'strikethrough',
                                ],
                            },
                            list: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: ['unordered', 'ordered'],
                            },
                        }}
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                    />
                </Box>
            )}
        </>
    );
};
