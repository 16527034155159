import React, { useState } from 'react';
import {
    DocumentFile,
    FileFiltersListResponseData,
} from '../services/interfaces';

export interface DocumentInsightsContext {
    filters: FileFiltersListResponseData | null;
    setFilters: (filters: FileFiltersListResponseData | null) => void;
    uploadFiles: IUploadFile[];
    setUploadFiles: (files: IUploadFile[]) => void;
    setRefreshCount: (value: number) => void;
    refreshCount: number;
    setActiveContextFileCount: (value: number) => void;
    activeContextFileCount: number;
}

export const DocumentInsightsContext =
    React.createContext<DocumentInsightsContext | null>(null);

interface IProps {
    children: any;
}

export interface IUploadFile {
    file: File;
    id: string;
    uploaded?: boolean;
}

const DocumentProvider = (props: IProps) => {
    const [files, setFiles] = useState<DocumentFile[] | null>(null);
    const [sharedFiles, setSharedFiles] = useState<DocumentFile[] | null>(null);
    const [activeContextFileCount, setActiveContextFileCount] =
        useState<number>(0);

    const [uploadFiles, setUploadFiles] = useState<IUploadFile[]>([]);
    const [refreshCount, setRefreshCount] = useState<number>(0);
    const [filters, setFilters] = useState<FileFiltersListResponseData | null>(
        null
    );
    const { children } = props;

    return (
        <DocumentInsightsContext.Provider
            value={{
                refreshCount,
                setRefreshCount,
                filters,
                uploadFiles,
                setUploadFiles,
                setFilters,
                activeContextFileCount,
                setActiveContextFileCount,
            }}
        >
            {children}
        </DocumentInsightsContext.Provider>
    );
};

export default DocumentProvider;
