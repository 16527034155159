import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useBillingService } from '../../services/billing.service';
import { IActiveSubscriptionResponseData } from '../../services/interfaces';
import { UtilsService } from '../../services/utils.service';
import { NavigationLink } from '../core/link';
import { Link } from 'react-router-dom';
import { AppContext } from '../../providers/app-provider';
import { useAuthService } from '../../services/auth.service';

export const AccountActionHeader = () => {
    const { fetchActiveSubscription } = useBillingService();
    const { getPlanType } = useAuthService();
    const appContext = useContext(AppContext);

    const initialize = async () => {
        let planType = await getPlanType();
        if (
            appContext &&
            !appContext?.subscriptionInfo &&
            planType &&
            planType != 'ENTERPRISE'
        ) {
            let subscriptionRes = await fetchActiveSubscription();
            appContext.setSubscriptionInfo(subscriptionRes);
        }
    };

    useEffect(() => {
        initialize();
    }, []);
    return (
        <Box sx={{ backgroundColor: '#ffbd8f' }}>
            {appContext && appContext.subscriptionInfo && (
                <>
                    {appContext.subscriptionInfo.status == 'trialing' &&
                        !appContext.subscriptionInfo.payment_method_added && (
                            <Typography>
                                You are on the <strong>Pro</strong> trial plan
                                which expires in{' '}
                                {UtilsService.getDaysDifference(
                                    appContext.subscriptionInfo
                                        .current_period_end
                                )}{' '}
                                Days - Click{' '}
                                <Link
                                    style={{
                                        color: '#29319B',
                                    }}
                                    to="/my-account?tab=PLANS_BILLING&subTab=PLANS"
                                >
                                    here
                                </Link>{' '}
                                to upgrade.
                            </Typography>
                        )}
                    {appContext.subscriptionInfo.status == 'past_due' &&
                        appContext.subscriptionInfo.cancellation_date && (
                            <Typography>
                                You are in Overdue Period of{' '}
                                {UtilsService.getDaysDifference(
                                    appContext.subscriptionInfo
                                        .cancellation_date
                                )}{' '}
                                Days, Click{' '}
                                <Link
                                    style={{
                                        color: '#29319B',
                                    }}
                                    to="/my-account?tab=PLANS_BILLING&subTab=BILLING_HISTORY"
                                >
                                    here
                                </Link>{' '}
                                to pay now.
                            </Typography>
                        )}
                </>
            )}
        </Box>
    );
};
