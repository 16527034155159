import styled from '@emotion/styled';
import { SxProps, TableCell, Theme } from '@mui/material';
import Box from '@mui/system/Box';
import { Sort, SortOrder } from '../../../icons/sort';

export const StyledTableCell = styled(TableCell)(() => ({
    padding: '10px !important',
    minWidth: 120,
}));

interface IProps {
    children: any;
    sortable?: boolean;
    sortOrder?: SortOrder;
    alignCenter?: boolean;
    minWidth?: number;
}

export const HeadCell = (props: IProps) => {
    return (
        <StyledTableCell sx={{ minWidth: `${props.minWidth}px !important` }}>
            <Box
                justifyContent={props.alignCenter ? 'center' : ''}
                display="flex"
            >
                {props.children}
                {props.sortable && (
                    <Box sx={{ ml: 2 }}>
                        <Sort sortOrder={props.sortOrder || SortOrder.NONE} />
                    </Box>
                )}
            </Box>
        </StyledTableCell>
    );
};
