export const FilterIntermediate = (
    <svg
        width="16"
        height="16"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.75293"
            y="0.0634766"
            width="16"
            height="16"
            rx="2"
            fill="#29319B"
        />
        <path
            d="M3.75293 9.06348C3.20064 9.06348 2.75293 8.61576 2.75293 8.06348V8.06348C2.75293 7.51119 3.20065 7.06348 3.75293 7.06348H13.7529C14.3052 7.06348 14.7529 7.51119 14.7529 8.06348V8.06348C14.7529 8.61576 14.3052 9.06348 13.7529 9.06348H3.75293Z"
            fill="white"
        />
    </svg>
);
