import React, { useEffect, useState } from 'react';
import {
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
    TableHead,
    TableContainer,
    TableRow,
    Table,
    TableBody,
} from '@mui/material';
import { BorderedBox } from '../../../../../components/core/box';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { RadarPolygonFill } from '../../../../../components/charts/radar-polygon-fill';
import { PersonalityTraitData } from '../../../../../services/interfaces';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        paddingTop: '12px',
        paddingBottom: '16px',
        textAlign: 'center',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 'none',
        padding: '10px 16px',
        textAlign: 'left',
    },
}));

const rows = [
    {
        low: 'Practical, conventional, prefers routine',
        trait: 'Openness to Experience',
        high: 'Curious, wide range of interests, independent',
    },
    {
        low: 'Impulsive, careless, disorganized',
        trait: 'Conscientiousness',
        high: 'Hardworking, dependable, organized',
    },
    {
        low: 'Quiet, reserved, withdrawn',
        trait: 'Extraversion',
        high: 'Outgoing, warn, seeks adventure',
    },
    {
        low: 'Critical, uncooperative, suspicious',
        trait: 'Agreeableness',
        high: 'Helpful, trusting, empathetic',
    },
    {
        low: 'Calm, even-tempered, secure',
        trait: 'Neuroticism',
        high: 'Anxious, unhappy, prone to negative emotions',
    },
];

interface IProps {
    personalityTraitData: PersonalityTraitData | null;
}

export const PersonalityTraits = (props: IProps) => {
    const [value, setValue] = useState('');
    const [data, setData] = useState({});

    useEffect(() => {
        if (props.personalityTraitData) {
            setValue(props.personalityTraitData.speakers[0].speakerName);
        }
    }, []);

    useEffect(() => {
        let speakerData = props.personalityTraitData?.speakers.find(
            (speaker) => speaker.speakerName === value
        );

        if (speakerData) {
            setData({
                name: speakerData?.speakerName,
                data: [
                    (
                        speakerData.personalityDetectionData.openness * 100
                    ).toFixed(2),
                    (
                        speakerData.personalityDetectionData.conscientiousness *
                        100
                    ).toFixed(2),
                    (
                        speakerData.personalityDetectionData.extraversion * 100
                    ).toFixed(2),
                    (
                        speakerData.personalityDetectionData.agreeableness * 100
                    ).toFixed(2),
                    (
                        speakerData.personalityDetectionData.neuroticism * 100
                    ).toFixed(2),
                ],
            });
        }
    }, [value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    return (
        <Box>
            {' '}
            <Box
                px={6}
                py={3}
                sx={{
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                    borderBottom: '1px solid #D7D9EC',
                }}
            >
                <Typography variant="h4">Personality Traits</Typography>
            </Box>
            <Box sx={{ margin: '24px' }}>
                <Typography variant="h6">
                    The “Big Five” Personality Traits
                </Typography>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ minWidth: '550px' }}>
                        <RadarPolygonFill chartData={data} />
                    </Box>

                    <Box sx={{ marginLeft: '40px' }}>
                        <FormControl>
                            <Typography sx={{ color: '#525252' }}>
                                Management Speakers
                            </Typography>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={handleChange}
                            >
                                {props.personalityTraitData?.speakers.map(
                                    (speaker) => (
                                        <FormControlLabel
                                            value={speaker.speakerName}
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: '#161616',
                                                        '&.Mui-checked': {
                                                            color: '#161616',
                                                        },
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography fontWeight={600}>
                                                    {speaker.speakerName}{' '}
                                                    <span
                                                        style={{
                                                            color: '#4B4B4C',
                                                            fontWeight: '400',
                                                        }}
                                                    >
                                                        | {speaker.speakerRole}
                                                    </span>
                                                </Typography>
                                            }
                                        />
                                    )
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>

                <BorderedBox>
                    <TableContainer
                        style={{
                            borderRadius: '8%',
                        }}
                    >
                        <Table stickyHeader aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>
                                        Low score tends to
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Personality Trait
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        High score tends to
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow>
                                        <StyledTableCell>
                                            {row.low}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '28px',
                                                    fontWeight: '600',
                                                    lineHeight: '36px',
                                                    marginRight: '16px',
                                                }}
                                            >
                                                {row.trait.charAt(0)}
                                            </Typography>
                                            <BorderedBox
                                                sx={{
                                                    backgroundColor: '#EBECF6',
                                                    padding: '10px 20px',
                                                    boxShadow:
                                                        '0px 3px 4px 0px rgba(0, 0, 0, 0.10)',
                                                    minWidth: '220px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#000033',
                                                        fontSize: '14px',
                                                        fontWeight: '600',
                                                        lineHeight: '20px',
                                                    }}
                                                    align="center"
                                                >
                                                    {row.trait}
                                                </Typography>
                                            </BorderedBox>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.high}
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </BorderedBox>
            </Box>
        </Box>
    );
};
