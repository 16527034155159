import { Box, Grid } from '@mui/material';
import { EarningFilter } from '../../../../../services/interfaces';
import { KeyInsights } from './key-insights';
import { Summary } from './summary';

interface IProps {
    selectedEarningFilter: EarningFilter | null;
    topicSubscriptionId: string;
    query: string;
}

export const EarningCall = (props: IProps) => {
    return (
        <Box>
            <Grid pt={3} spacing={4} container>
                <Grid item sm={12}>
                    <KeyInsights
                        selectedEarningFilter={props.selectedEarningFilter}
                        topicSubscriptionId={props.topicSubscriptionId}
                        query={props.query}
                    />
                </Grid>
            </Grid>
            <Box pt={4}>
                <Summary
                    selectedEarningFilter={props.selectedEarningFilter}
                    topicSubscriptionId={props.topicSubscriptionId}
                    query={props.query}
                />
            </Box>
        </Box>
    );
};
