import {
    Box,
    Button,
    Chip,
    Dialog,
    Divider,
    Grid,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { IOSSwitch } from '../../../../components/core/ios-switch';
import { DriveFilters } from '../../../document-insights/drive/drive-filters';
import {
    FileFilters,
    IFilesCount,
    ITrustedDomain,
} from '../../../../services/interfaces';
import { CustomTab } from '../../../chat-widget/chatbot/settings';
import SingleSelect from '../../../../components/core/input/single-select';
import { IDiligenceTemplateConfiguration } from '../../../../services/interfaces';
import { UtilsService } from '../../../../services/utils.service';
import { Config } from 'prettier';
import {
    DateRange,
    DateRangePicker,
} from '../../../../components/core/input/date-range-picker';
import { BorderedBox } from '../../../../components/core/box';
import { Calendar } from '../../../../components/icons/calendar';
import { DocumentInsightsContext } from '../../../../providers/document-provider';
import { useDocumentService } from '../../../../services/document.service';
import { useSnackbar } from 'notistack';
import { Input } from '../../../../components/core/input';
import { useAccountService } from '../../../../services/account.service';

const DATA_SOURCES = [
    {
        id: 'WEB',
        value: 'Web Sources',
    },
    {
        id: 'DOCUMENTS',
        value: 'User Documents',
    },
    {
        id: 'ALL',
        value: 'All',
    },
];

const WEB_SOURCES = [
    {
        id: 'INTERNET',
        value: 'Internet',
    },
    {
        id: 'NEWS',
        value: 'News',
    },
    {
        id: 'CUSTOM_DOMAINS',
        value: 'Custom Domains',
    },
];

interface IProps {
    config: IDiligenceTemplateConfiguration;
    updateConfig: (config: IDiligenceTemplateConfiguration) => void;
}

export const AnswerLength = (props: IProps) => {
    const [value, setValue] = useState(
        props.config.sectionTemplate.answerLength == 'DEFAULT' ? 0 : 1
    );
    useEffect(() => {
        setValue(
            props.config.sectionTemplate.answerLength == 'DEFAULT' ? 0 : 1
        );
    }, [props.config.sectionTemplate.answerLength]);
    return (
        <Box py={1} display="flex" justifyContent="space-between">
            <Box>
                <Typography color="#003" variant="h6">
                    Length
                </Typography>
                <Typography color="#8D8D8D" fontSize={12}>
                    Select the length of the answer, generated by Quantum AI
                </Typography>
            </Box>
            <Box pt={1}>
                <Tabs
                    value={value}
                    onChange={(e, v) => {
                        props.updateConfig({
                            ...props.config,
                            sectionTemplate: {
                                ...props.config.sectionTemplate,
                                answerLength:
                                    v == 0 ? 'DEFAULT' : 'DESCRIPTIVE',
                            },
                        });
                    }}
                    sx={{
                        height: 'fit-content',
                        minHeight: 0,
                        '& .MuiTabs-fixed': {
                            height: 'fit-content',
                        },
                        '& .MuiTabs-indicator': { display: 'none' },
                        '& .MuiTab-root:first-of-type': {
                            borderTopLeftRadius: '8px !important',
                            borderBottomLeftRadius: '8px !important',
                        },
                        '& .MuiTab-root:last-of-type': {
                            borderTopRightRadius: '8px !important',
                            borderBottomRightRadius: '8px !important',
                        },
                    }}
                    aria-label="basic tabs example"
                >
                    <CustomTab label="Default" />
                    <CustomTab label="Descriptive" />
                </Tabs>
            </Box>
        </Box>
    );
};

const DATE_RANGE_OPTIONS = [
    {
        id: 'PAST_DAY',
        value: 'Past Day',
    },
    {
        id: 'PAST_WEEK',
        value: 'Past Week',
    },
    {
        id: 'PAST_2_WEEKS',
        value: 'Past 2 Weeks',
    },
    {
        id: 'PAST_MONTH',
        value: 'Past Month',
    },
    {
        id: 'PAST_QUARTER',
        value: 'Past Quarter',
    },
    {
        id: 'PAST_YEAR',
        value: 'Past year',
    },
    {
        id: 'ANYTIME',
        value: 'Anytime',
    },
    {
        id: 'CUSTOM',
        value: 'Custom',
    },
];

interface IDaterangeProps extends IProps {
    enabledIds?: string[];
}

export const DateRangeSelect = (props: IDaterangeProps) => {
    const [dateRange, setDateRange] = useState(
        props.config.sectionTemplate.dateRange || 'PAST_MONTH'
    );
    const [isOpen, setIsOpen] = useState(false);
    const [dates, setDates] = useState<DateRange | null>(null);

    const handleClose = () => {
        if (!dates || !dates.startDate || !dates.endDate) return;
        setIsOpen(false);
        // props.onChange({ key: 'date_range', data: dates });
        props.updateConfig({
            ...props.config,
            sectionTemplate: {
                ...props.config.sectionTemplate,
                dateRange: 'CUSTOM',
                querySearchStartDate:
                    UtilsService.formatDateForNetworkCallWithoutOffset(
                        dates.startDate
                    ),
                querySearchEndDate:
                    UtilsService.formatDateForNetworkCallWithoutOffset(
                        dates.endDate
                    ),
            },
        });
    };

    useEffect(() => {
        if (
            props.config.sectionTemplate.querySearchStartDate &&
            props.config.sectionTemplate.querySearchEndDate
        ) {
            setDates({
                startDate: new Date(
                    props.config.sectionTemplate.querySearchStartDate
                ),
                endDate: new Date(
                    props.config.sectionTemplate.querySearchEndDate
                ),
            });
            setDateRange('CUSTOM');
        } else if (props.config.sectionTemplate.dateRange) {
            setDateRange(props.config.sectionTemplate.dateRange);
        }
    }, [props.config]);

    return (
        <Box display="flex" justifyContent={'space-between'}>
            <Box>
                {dateRange === 'CUSTOM' && (
                    <Box>
                        <BorderedBox
                            onClick={() => setIsOpen(true)}
                            sx={{
                                py: 2.5,
                                px: 2,
                                cursor: 'pointer',
                                backgroundColor: '#fff',
                            }}
                        >
                            <Grid justifyContent="space-between" container>
                                <Grid sx={{ verticalAlign: 'middle' }} item>
                                    {dates &&
                                    dates.startDate &&
                                    dates?.endDate ? (
                                        <>
                                            {UtilsService.formatDateWithoutYearWithoutOffset(
                                                dates.startDate
                                            )}
                                            {' - '}

                                            {UtilsService.formatDateWithoutYearWithoutOffset(
                                                dates.endDate
                                            )}
                                        </>
                                    ) : (
                                        <>Choose Date</>
                                    )}
                                </Grid>

                                <Tooltip
                                    arrow
                                    placement="top"
                                    title={'Select Custom Date Range'}
                                >
                                    <Grid sx={{ verticalAlign: 'middle' }} item>
                                        <Calendar color="#29319B" />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </BorderedBox>
                        <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
                            <Box
                                sx={{
                                    backgroundColor: '#ccc',
                                    '& .MuiPaper-elevation': {
                                        boxShadow: 'none',
                                    },
                                    '& .MuiBox > div > div': {
                                        color: '#fff',
                                    },
                                }}
                            >
                                <DateRangePicker
                                    open={true}
                                    toggle={() => {}}
                                    initialDateRange={
                                        dates &&
                                        dates.startDate &&
                                        dates?.endDate
                                            ? {
                                                  startDate: new Date(
                                                      dates.startDate
                                                  ),
                                                  endDate: new Date(
                                                      dates.endDate
                                                  ),
                                              }
                                            : {}
                                    }
                                    minDate={new Date('2020-01-01')}
                                    maxDate={new Date()}
                                    onChange={(dateRange: DateRange) => {
                                        setDates(dateRange);
                                    }}
                                    wrapperClassName="date-range-picker"
                                    closeOnClickOutside={true}
                                />
                            </Box>
                            <Button
                                onClick={() => {
                                    handleClose();
                                }}
                            >
                                Close
                            </Button>
                        </Dialog>
                    </Box>
                )}
            </Box>
            <SingleSelect
                getOptionText={(v: any) => {
                    return v ? v.value : '';
                }}
                disableCheckbox
                placeholder="Auto Open Behaviour"
                pt={1.5}
                ptContainer={0}
                pb={2.5}
                width={200}
                backgroundColor="#fff"
                mt={0}
                options={
                    props.enabledIds
                        ? DATE_RANGE_OPTIONS.filter((d) =>
                              props.enabledIds?.includes(d.id)
                          )
                        : DATE_RANGE_OPTIONS
                }
                selectedValue={
                    DATE_RANGE_OPTIONS.filter((v) => v.id == dateRange)[0]
                }
                onChange={(v: any) => {
                    if (v.id == 'CUSTOM') {
                        props.updateConfig({
                            ...props.config,
                            sectionTemplate: {
                                ...props.config.sectionTemplate,
                                dateRange: v.id,
                            },
                        });
                    } else {
                        props.updateConfig({
                            ...props.config,
                            sectionTemplate: {
                                ...props.config.sectionTemplate,
                                dateRange: v.id,
                                querySearchStartDate: undefined,
                                querySearchEndDate: undefined,
                            },
                        });
                    }
                }}
            />
        </Box>
    );
};

interface IDataSourceOptionsProps extends IProps {
    enabledSource?: 'WEB' | 'DOCUMENTS';
    enabledDataRangeIds?: string[];
}

export const DataSourceOptions = (props: IDataSourceOptionsProps) => {
    const [value, setValue] = useState(0);
    const { fetchDomainsList } = useAccountService();
    const [domainsList, setDomainsList] = useState<ITrustedDomain[]>([]);
    const refreshDomains = async () => {
        let d = await fetchDomainsList();
        setDomainsList(d);
    };
    useEffect(() => {
        refreshDomains();
    }, []);
    const { fetchFilteredFileCount } = useDocumentService();
    const [filteredFilesCount, setFilteredFilesCount] =
        useState<IFilesCount | null>(null);
    const [filters, setFilters] = useState<FileFilters>(
        props.config.sectionTemplate.userFilesFilter
            ? props.config.sectionTemplate.userFilesFilter
            : {
                  includeSharedFiles: true,
              }
    );
    const [domainInput, setDomainInput] = useState('');
    const documentContext = useContext(DocumentInsightsContext);
    const { fetchFilesFilters } = useDocumentService();
    const removeDomain = (domain: string) => {
        if (props.config.sectionTemplate.customDomains?.includes(domain)) {
            // setDomains([domainInput, ...domains]);
            props.updateConfig({
                ...props.config,
                sectionTemplate: {
                    ...props.config.sectionTemplate,
                    customDomains: [
                        ...(
                            props.config.sectionTemplate.customDomains || []
                        ).filter((d: string) => d != domain),
                    ],
                },
            });
        }
    };

    const refreshFilesCount = async () => {
        if (filters) {
            const count = await fetchFilteredFileCount(filters);
            setFilteredFilesCount(count);
        }
    };

    useEffect(() => {
        refreshFilesCount();
    }, [filters]);
    const refreshFilters = async () => {
        let filters = await fetchFilesFilters();
        documentContext?.setFilters({ ...filters });
    };
    useEffect(() => {
        if (!documentContext?.filters) refreshFilters();
    }, [documentContext?.filters]);

    useEffect(() => {
        setFilters(
            props.config.sectionTemplate.userFilesFilter
                ? props.config.sectionTemplate.userFilesFilter
                : {
                      includeSharedFiles: true,
                  }
        );
    }, [props.config]);
    const { enqueueSnackbar } = useSnackbar();
    const [dataSource, setDataSource] = useState(
        props.enabledSource
            ? props.enabledSource
            : props.config.sectionTemplate.enableInternalData &&
              props.config.sectionTemplate.onlineSearchSource
            ? 'ALL'
            : props.config.sectionTemplate.enableInternalData
            ? 'DOCUMENTS'
            : 'WEB'
    );
    const [webSource, setWebSource] = useState(
        props.config.sectionTemplate.onlineSearchSource || 'INTERNET'
    );

    const updateDataSource = (dSource: string) => {
        if (dSource == 'WEB') {
            props.updateConfig({
                ...props.config,
                sectionTemplate: {
                    ...props.config.sectionTemplate,
                    enableInternalData: false,
                    onlineSearchSource:
                        props.config.sectionTemplate.onlineSearchSource ||
                        'INTERNET',
                    customDomains:
                        props.config.sectionTemplate.onlineSearchSource ==
                        'CUSTOM_DOAMINS'
                            ? props.config.sectionTemplate.customDomains
                            : undefined,
                    userTrustedDomainId:
                        props.config.sectionTemplate.onlineSearchSource ==
                        'CUSTOM_DOAMINS'
                            ? props.config.sectionTemplate.userTrustedDomainId
                            : undefined,
                    dataSource: dSource,
                },
            });
        }
        if (dSource == 'DOCUMENTS') {
            props.updateConfig({
                ...props.config,
                sectionTemplate: {
                    ...props.config.sectionTemplate,
                    enableInternalData: true,
                    onlineSearchSource: undefined,
                    dataSource: dSource,
                    userFilesFilter: props.config.sectionTemplate
                        .userFilesFilter || {
                        includeSharedFiles: true,
                    },
                },
            });
        }
        if (dSource == 'ALL') {
            props.updateConfig({
                ...props.config,
                sectionTemplate: {
                    ...props.config.sectionTemplate,
                    enableInternalData: true,
                    dataSource: dSource,
                    onlineSearchSource:
                        props.config.sectionTemplate.onlineSearchSource ||
                        'INTERNET',
                    customDomains:
                        props.config.sectionTemplate.onlineSearchSource ==
                        'CUSTOM_DOAMINS'
                            ? props.config.sectionTemplate.customDomains
                            : undefined,
                    userTrustedDomainId:
                        props.config.sectionTemplate.onlineSearchSource ==
                        'CUSTOM_DOAMINS'
                            ? props.config.sectionTemplate.userTrustedDomainId
                            : undefined,
                    userFilesFilter: props.config.sectionTemplate
                        .userFilesFilter || {
                        includeSharedFiles: true,
                    },
                },
            });
        }
    };

    useEffect(() => {
        setDataSource(
            props.config.sectionTemplate.enableInternalData &&
                props.config.sectionTemplate.onlineSearchSource
                ? 'ALL'
                : props.config.sectionTemplate.enableInternalData
                ? 'DOCUMENTS'
                : 'WEB'
        );
    }, [props]);
    const addDomain = () => {
        if (domainInput) {
            try {
                if (
                    !props.config.sectionTemplate.customDomains?.includes(
                        domainInput
                    )
                ) {
                    // setDomains([domainInput, ...domains]);
                    props.updateConfig({
                        ...props.config,
                        sectionTemplate: {
                            ...props.config.sectionTemplate,
                            customDomains: [
                                domainInput,
                                ...(props.config.sectionTemplate
                                    .customDomains || []),
                            ],
                        },
                    });
                }
                setDomainInput('');
            } catch (error) {
                enqueueSnackbar('invalid domain', {
                    variant: 'error',
                });
            }
        }
    };

    useEffect(() => {
        setWebSource(
            props.config.sectionTemplate.onlineSearchSource || 'INTERNET'
        );
    }, [props.config]);

    return (
        <Box>
            {!props.enabledSource && (
                <Box py={1} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Data Sources Selection
                        </Typography>
                        <Typography color="#8D8D8D" fontSize={12}>
                            Search across various sources
                        </Typography>
                    </Box>
                    <Box>
                        <SingleSelect
                            getOptionText={(v: any) => (v ? v.value : '')}
                            placeholder="Auto Open Behaviour"
                            pt={2}
                            disableCheckbox
                            ptContainer={0}
                            pb={3}
                            width={200}
                            backgroundColor="#fff"
                            mt={0}
                            options={DATA_SOURCES}
                            selectedValue={
                                DATA_SOURCES.filter(
                                    (v) => v.id == dataSource
                                )[0]
                            }
                            onChange={(v: any) => {
                                updateDataSource(v.id);
                            }}
                        />
                    </Box>
                </Box>
            )}
            <Box pt={1}></Box>
            {['WEB', 'ALL'].includes(dataSource) && (
                <Box>
                    <Box py={1} display="flex" justifyContent="space-between">
                        <Box>
                            <Typography
                                color="#003"
                                fontSize={14}
                                fontWeight={600}
                            >
                                Date Range for search
                            </Typography>
                            <Typography color="#8D8D8D" fontSize={12}>
                                Filter your search with the date range
                            </Typography>
                        </Box>
                        <Box>
                            <DateRangeSelect
                                enabledIds={props.enabledDataRangeIds}
                                updateConfig={props.updateConfig}
                                config={props.config}
                            />
                        </Box>
                    </Box>
                    <Box py={1} display="flex" justifyContent="space-between">
                        <Box>
                            <Typography
                                color="#003"
                                fontSize={14}
                                fontWeight={600}
                            >
                                Web sources
                            </Typography>
                            <Typography color="#8D8D8D" fontSize={12}>
                                Only search the specified domain
                            </Typography>
                        </Box>
                        <Box>
                            <Box sx={{ width: 200 }}>
                                <SingleSelect
                                    getOptionText={(v: any) =>
                                        v ? v.value : ''
                                    }
                                    placeholder="Auto Open Behaviour"
                                    disableCheckbox
                                    pt={1.5}
                                    ptContainer={0}
                                    pb={2.5}
                                    backgroundColor="#fff"
                                    mt={0}
                                    options={WEB_SOURCES}
                                    selectedValue={
                                        WEB_SOURCES.filter(
                                            (v) => v.id == webSource
                                        )[0]
                                    }
                                    onChange={(v: any) => {
                                        // setWebSource(v.id);
                                        props.updateConfig({
                                            ...props.config,
                                            sectionTemplate: {
                                                ...props.config.sectionTemplate,
                                                onlineSearchSource: v.id,
                                            },
                                        });
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    {webSource == 'CUSTOM_DOMAINS' && (
                        <Box>
                            <Box
                                py={1}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Box>
                                    <Typography
                                        color="#003"
                                        fontSize={14}
                                        fontWeight={600}
                                    >
                                        Custom Domains
                                    </Typography>
                                    <Typography color="#8D8D8D" fontSize={12}>
                                        Attach a list and/or enter the domains
                                        below
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box sx={{ width: 200 }}>
                                        <SingleSelect
                                            getOptionText={(
                                                v: ITrustedDomain
                                            ) =>
                                                v
                                                    ? v.displayName
                                                    : 'Select Domain List'
                                            }
                                            placeholder="Select Domain List"
                                            pt={1.5}
                                            allowClearSelection={true}
                                            disableCheckbox
                                            ptContainer={0}
                                            pb={2.5}
                                            backgroundColor="#fff"
                                            mt={0}
                                            options={domainsList}
                                            selectedValue={
                                                domainsList.filter(
                                                    (v: ITrustedDomain) =>
                                                        v.id ==
                                                        props.config
                                                            .sectionTemplate
                                                            .userTrustedDomainId
                                                )[0]
                                            }
                                            onChange={(v: any) => {
                                                // setWebSource(v.id);
                                                props.updateConfig({
                                                    ...props.config,
                                                    sectionTemplate: {
                                                        ...props.config
                                                            .sectionTemplate,
                                                        userTrustedDomainId: v
                                                            ? v.id
                                                            : undefined,
                                                    },
                                                });
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box>
                                    <Input
                                        value={domainInput}
                                        onEnterPress={addDomain}
                                        pb={0}
                                        pt={0}
                                        noShadow
                                        backgroundColor="#FFF"
                                        borderColor="#29319B"
                                        onKeyDown={(e: KeyboardEvent) => {
                                            e.stopPropagation();
                                        }}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            setDomainInput(e.target.value);
                                            e.preventDefault();
                                        }}
                                        endAdornment={
                                            <Button
                                                onClick={addDomain}
                                                sx={{
                                                    pt: 1,
                                                    pb: 1,
                                                }}
                                                disabled={!domainInput}
                                                variant="contained"
                                            >
                                                add
                                            </Button>
                                        }
                                        sx={{
                                            '&.MuiOutlinedInput-root': {
                                                paddingRight: '8px !important',
                                            },
                                        }}
                                        placeholder={'e.g., cnn.com'}
                                        type={''}
                                    />
                                </Box>
                                <Box
                                    className="scrollable"
                                    sx={{
                                        maxHeight: '200px',
                                        overflow: 'auto',
                                    }}
                                >
                                    {(
                                        props.config.sectionTemplate
                                            .customDomains || []
                                    ).map((domain: string) => (
                                        <Chip
                                            sx={{
                                                mt: 2,
                                                mr: 2,
                                                backgroundColor: '#FFF',
                                            }}
                                            label={domain}
                                            variant="outlined"
                                            onDelete={() =>
                                                removeDomain(domain)
                                            }
                                        />
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
            {['DOCUMENTS', 'ALL'].includes(dataSource) && (
                <Box>
                    <Box py={3}>
                        <Divider />
                    </Box>
                    <Box pb={4} display="flex" justifyContent="space-between">
                        <Box>
                            <Typography
                                color="#003"
                                fontSize={14}
                                fontWeight={600}
                            >
                                My Files
                            </Typography>
                            {filteredFilesCount && (
                                <Box display="flex">
                                    <Typography color="#8D8D8D" fontSize={12}>
                                        Total Files{' '}
                                        {filteredFilesCount.totalCount}
                                    </Typography>

                                    <Typography
                                        px={2}
                                        mx={2}
                                        sx={{ borderLeft: '1px solid #ccc' }}
                                        color="#8D8D8D"
                                        fontSize={12}
                                    >
                                        {filteredFilesCount.filteredCount}/
                                        {filteredFilesCount.totalCount} files
                                        included
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        <Box>
                            <DriveFilters
                                appliedFilters={filters}
                                setAppliedFilters={(v) => {
                                    if (v) {
                                        props.updateConfig({
                                            ...props.config,
                                            sectionTemplate: {
                                                ...props.config.sectionTemplate,
                                                userFilesFilter: v,
                                            },
                                        });
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};
