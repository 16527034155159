import React from 'react';
import { ReportModule } from '../../../../../services/interfaces';
import { Box, Typography } from '@mui/material';
import { ScatterChart } from '../../../../../components/charts/scatter-chart';
import { BorderedBox } from '../../../../../components/core/box';
import { Circle } from '../../../../../components/icons/circle';
import { UtilsService } from '../../../../../services/utils.service';
interface IProps {
    data: ReportModule;
}
export const EarningsHistory = (props: IProps) => {
    return (
        <BorderedBox p={4}>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant="h6">Earnings History</Typography>
                <Typography>
                    {' '}
                    <Circle h={12} mt={1} w={12} color="#747474" /> Consensus
                    EPS
                </Typography>
            </Box>
            <ScatterChart
                series={[
                    {
                        name: 'Estimate',
                        data:
                            props.data?.data?.yahoo.coreFinancials.earningsHistory.map(
                                (d) => d.estimate
                            ) || [],
                        color: '#747474',
                    },
                    {
                        name: 'Actual',
                        data:
                            props.data?.data?.yahoo.coreFinancials.earningsHistory.map(
                                (d) => d.actual
                            ) || [],
                    },
                ]}
                labels={
                    props.data?.data?.yahoo.coreFinancials.earningsHistory.map(
                        (d) => [
                            d.date,
                            !d.actual
                                ? ''
                                : d.actual > d.estimate
                                ? 'Beat'
                                : 'Missed',
                            `${UtilsService.formatCurrency(
                                d.actual - d.estimate,
                                '$'
                            )}`,
                        ]
                    ) || []
                }
                colors={
                    props.data?.data?.yahoo.coreFinancials.earningsHistory.map(
                        (d) =>
                            !d.actual
                                ? '#fff'
                                : d.actual > d.estimate
                                ? '#24A148'
                                : '#DA1E28'
                    ) || []
                }
            />
        </BorderedBox>
    );
};
