export const DeleteImg = (
    <svg
        width="169"
        height="104"
        viewBox="0 0 169 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_2210_9763"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="169"
            height="104"
        >
            <path d="M169 0H0V103.807H169V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_2210_9763)">
            <path
                d="M118.344 28.7542L118.256 84.808H50.5703L50.6578 17.956C50.6578 14.3163 53.6091 11.365 57.2488 11.365H99.5521L118.344 28.7542Z"
                fill="#D8E2EA"
            />
            <path
                d="M118.342 28.7542H101.156C100.269 28.7542 99.5508 28.0356 99.5508 27.1493V11.365L118.342 28.7542Z"
                fill="#B0BFCF"
            />
            <path
                d="M81.3852 36.5151H56.5792C56.0457 36.5151 55.6133 36.0825 55.6133 35.549V32.2458C55.6133 31.7123 56.0457 31.2797 56.5792 31.2797H81.3852C81.9187 31.2797 82.3511 31.7123 82.3511 32.2458V35.549C82.3511 36.0825 81.9187 36.5151 81.3852 36.5151Z"
                fill="#B0BFCF"
            />
            <path
                d="M72.5972 45.3369H56.5792C56.0457 45.3369 55.6133 44.9043 55.6133 44.3708V41.0676C55.6133 40.5341 56.0457 40.1014 56.5792 40.1014H72.5972C73.1307 40.1014 73.5631 40.5341 73.5631 41.0676V44.3708C73.5631 44.9043 73.1307 45.3369 72.5972 45.3369Z"
                fill="#B0BFCF"
            />
            <path
                d="M111.302 54.6557H56.5792C56.0457 54.6557 55.6133 54.2231 55.6133 53.6896V50.3864C55.6133 49.8529 56.0457 49.4203 56.5792 49.4203H111.302C111.835 49.4203 112.268 49.8529 112.268 50.3864V53.6896C112.268 54.2231 111.835 54.6557 111.302 54.6557Z"
                fill="#B0BFCF"
            />
            <path
                d="M152.528 89.2563H16.4664C14.5088 89.2563 12.9219 87.6694 12.9219 85.7119V59.6937C12.9219 57.7361 14.5088 56.1492 16.4664 56.1492H152.528C154.486 56.1492 156.073 57.7361 156.073 59.6937V85.7119C156.073 87.6694 154.486 89.2563 152.528 89.2563Z"
                fill="#6D7C89"
            />
            <path
                d="M121.211 79.1973H47.784C45.2282 79.1973 43.1562 77.1254 43.1562 74.5696C43.1562 72.0137 45.2282 69.9418 47.784 69.9418H121.211C123.766 69.9418 125.838 72.0137 125.838 74.5696C125.838 77.1254 123.766 79.1973 121.211 79.1973Z"
                fill="#4F5E69"
            />
            <path
                d="M137.707 62.5092C137.707 63.7897 136.669 64.8278 135.389 64.8278C134.108 64.8278 133.07 63.7897 133.07 62.5092C133.07 61.2288 134.108 60.1907 135.389 60.1907C136.669 60.1907 137.707 61.2288 137.707 62.5092Z"
                fill="#B0BFCF"
            />
            <path
                d="M144.036 62.5092C144.036 63.7897 142.997 64.8278 141.717 64.8278C140.437 64.8278 139.398 63.7897 139.398 62.5092C139.398 61.2288 140.437 60.1907 141.717 60.1907C142.997 60.1907 144.036 61.2288 144.036 62.5092Z"
                fill="#B0BFCF"
            />
            <path
                d="M150.645 62.5092C150.645 63.7897 149.607 64.8278 148.326 64.8278C147.046 64.8278 146.008 63.7897 146.008 62.5092C146.008 61.2288 147.046 60.1907 148.326 60.1907C149.607 60.1907 150.645 61.2288 150.645 62.5092Z"
                fill="#E93E52"
            />
            <path
                d="M57.8917 82.3411H50.6562V74.4639H57.8917V82.3411Z"
                fill="#D8E2EA"
            />
            <path
                d="M69.9815 88.1678H62.7461V74.4167H69.9815V88.1678Z"
                fill="#D8E2EA"
            />
            <path
                d="M82.0714 82.2409H74.8359V74.3636H82.0714V82.2409Z"
                fill="#D8E2EA"
            />
            <path
                d="M94.1612 83.2468H86.9258V74.4187H94.1612V83.2468Z"
                fill="#D8E2EA"
            />
            <path
                d="M106.251 82.3273H99.0156V74.4501H106.251V82.3273Z"
                fill="#D8E2EA"
            />
            <path
                d="M118.341 84.9737H111.105V74.3931H118.341V84.9737Z"
                fill="#D8E2EA"
            />
        </g>
    </svg>
);
