import { Box } from '@mui/material';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number | string;
    value: number | string;
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value.toString() === index.toString() && (
                <Box sx={{ pt: 0 }}>{children}</Box>
            )}
        </div>
    );
};
