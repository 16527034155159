import styled from '@emotion/styled';
import {
    Breadcrumbs,
    Checkbox,
    Divider,
    FormControlLabel,
    Stack,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import {
    showStockPricesToggle,
    viewModeToggle,
} from '../../../../analytics/analytics';
import { BorderedBox, ContainedBox } from '../../../../components/core/box';
import { CheckedIcon } from '../../../../components/icons/checked-icon';
import { UncheckedIcon } from '../../../../components/icons/unchecked-icon';
import { AppContext } from '../../../../providers/app-provider';
import { Granularity } from '../../../../services/insights.service';
import { UtilsService } from '../../../../services/utils.service';
import { TopicInsights } from './insights-section';
import { StockPricePlot } from './stock-price-plot';
import { TopicSentimentPlot } from './topic-sentiment-plot';
import { id } from 'date-fns/locale';
import { DetailedInformation } from '../../../insight/details/detailed-information';
import { Topic } from '../../../../services/interfaces';

export const ViewModeText = styled(Typography)(() => ({
    textAlign: 'center',
    borderRadius: 8,
    cursor: 'pointer',
}));

export enum ViewMode {
    WEEK = 'WEEK',
    DAY = 'DAY',
}

interface IProps {
    topicSubscriptionId?: string;

    inbuiltDetail?: boolean;
}

export const SentimentPlot = (props: IProps) => {
    const appContext = useContext(AppContext);
    const [tSubscriptionId, setTSubscriptionId] = useState('');
    const [date, setDate] = useState('');
    const [id, setId] = useState('');
    const [summaryId, setSummaryId] = useState(0);
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.DAY);
    const [showStockPrice, setShowStockPrice] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [topicName, setTopicName] = useState('');
    const [barClickCount, setBarClickCount] = useState(0);
    const { topicSubscriptionId } = props;
    const updateDate = (date: string) => {
        setBarClickCount(barClickCount + 1);
        setSelectedDate(date);
    };

    const updateViewMode = (mode: ViewMode) => {
        if (appContext) {
            if (
                mode == ViewMode.WEEK &&
                appContext.filters.date.startDate ==
                    UtilsService.formatDateForNetworkCallWithoutOffset(
                        UtilsService.getDateWeeksAgo(2)
                    ) &&
                appContext.filters.date.endDate ==
                    UtilsService.formatDateForNetworkCallWithoutOffset(
                        new Date()
                    )
            ) {
                appContext.setFilters({
                    ...appContext.filters,
                    date: {
                        startDate: UtilsService.formatDateForNetworkCall(
                            UtilsService.getDateWeeksAgo(8)
                        ),
                        endDate: UtilsService.formatDateForNetworkCall(
                            new Date()
                        ),
                    },
                });
                updateDate(
                    UtilsService.formatDateForNetworkCall(
                        UtilsService.getMonday(new Date())
                    )
                );
            }
            if (
                mode == ViewMode.DAY &&
                appContext.filters.date.startDate ==
                    UtilsService.formatDateForNetworkCallWithoutOffset(
                        UtilsService.getDateWeeksAgo(8)
                    ) &&
                appContext.filters.date.endDate ==
                    UtilsService.formatDateForNetworkCallWithoutOffset(
                        new Date()
                    )
            ) {
                appContext.setFilters({
                    ...appContext.filters,
                    date: {
                        startDate: UtilsService.formatDateForNetworkCall(
                            UtilsService.getDateWeeksAgo(2)
                        ),
                        endDate: UtilsService.formatDateForNetworkCall(
                            new Date()
                        ),
                    },
                });

                updateDate(UtilsService.formatDateForNetworkCall(new Date()));
            }
        }
        setViewMode(mode);
        if (topicSubscriptionId) {
            viewModeToggle(topicSubscriptionId, '', mode);
        }
    };

    useEffect(() => {
        if (topicSubscriptionId && appContext?.topics.length) {
            let topic = appContext.topics.filter(
                (s: Topic) => s.topicSubscriptionId == topicSubscriptionId
            )[0];
            if (topic) {
                setTopicName(topic.subscriptionName);
            }
        }
    }, [topicSubscriptionId, appContext?.topics]);

    useEffect(() => {
        if (appContext?.filters.date.endDate) {
            setSelectedDate(appContext.filters.date.endDate);
        }
    }, [appContext?.filters.date.endDate, appContext?.category]);

    const handleChartToggle = () => {
        setShowStockPrice(!showStockPrice);
        if (topicSubscriptionId) {
            showStockPricesToggle(topicSubscriptionId, '', !showStockPrice);
        }
    };

    const renderDetails = (
        _topicSubscriptionId: string,
        _date: string,
        _id: string,
        _summaryId: number
    ) => {
        setTSubscriptionId(_topicSubscriptionId);
        setDate(_date);
        setId(_id);
        setSummaryId(_summaryId);
    };

    return (
        <Box>
            {tSubscriptionId ? (
                <Box pt={6}>
                    <Stack spacing={1}>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            <Typography
                                color="#29319B"
                                sx={{
                                    textDecoration: 'none',
                                }}
                            >
                                {topicName}
                            </Typography>
                            <Typography
                                onClick={() => {
                                    setTSubscriptionId('');
                                }}
                                color="#29319B"
                                sx={{
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                Article Insight
                            </Typography>
                            <Typography
                                color="inherit"
                                sx={{
                                    textDecoration: 'none',
                                }}
                            >
                                Insight
                            </Typography>
                        </Breadcrumbs>
                    </Stack>
                    <Box pt={2}>
                        <Typography variant="h1">Insight collected</Typography>
                        {appContext && (
                            <Typography variant="subtitle1">
                                {UtilsService.formatStringDate(date)}
                            </Typography>
                        )}
                    </Box>
                    <DetailedInformation
                        topicSubscriptionId={topicSubscriptionId}
                        level="article"
                        filter1={id}
                        filter2={summaryId.toString()}
                    />
                </Box>
            ) : (
                <Box>
                    <BorderedBox p={3}>
                        <Grid justifyContent="space-between" px={7} container>
                            <Grid xs={3} item>
                                {viewMode == ViewMode.DAY && (
                                    <BorderedBox mr={2} px={2} mt={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checkedIcon={
                                                        <CheckedIcon />
                                                    }
                                                    icon={<UncheckedIcon />}
                                                    onChange={() =>
                                                        handleChartToggle()
                                                    }
                                                    checked={showStockPrice}
                                                />
                                            }
                                            label="Show stock prices"
                                        />
                                    </BorderedBox>
                                )}
                            </Grid>
                            <Grid xs={6} sm={7} md={5} item>
                                <Grid justifyContent="end" container>
                                    <Grid xs={6} minWidth={240} item>
                                        <BorderedBox mt={4}>
                                            <Grid container>
                                                <Grid
                                                    onClick={() => {
                                                        updateViewMode(
                                                            ViewMode.DAY
                                                        );
                                                    }}
                                                    p={1}
                                                    item
                                                    xs={6}
                                                >
                                                    <ViewModeText
                                                        variant="body2"
                                                        py={2}
                                                        px={2}
                                                        sx={{
                                                            color:
                                                                viewMode ==
                                                                ViewMode.DAY
                                                                    ? '#29319B'
                                                                    : '#4B4B4C',
                                                            backgroundColor:
                                                                viewMode ==
                                                                ViewMode.DAY
                                                                    ? '#EBECF6'
                                                                    : '#ffffff',
                                                        }}
                                                    >
                                                        DAY VIEW
                                                    </ViewModeText>
                                                </Grid>

                                                <Grid
                                                    onClick={() => {
                                                        updateViewMode(
                                                            ViewMode.WEEK
                                                        );
                                                    }}
                                                    p={1}
                                                    item
                                                    xs={6}
                                                >
                                                    <ViewModeText
                                                        variant="body2"
                                                        py={2}
                                                        px={2}
                                                        sx={{
                                                            color:
                                                                viewMode ==
                                                                ViewMode.WEEK
                                                                    ? '#29319B'
                                                                    : '#4B4B4C',
                                                            backgroundColor:
                                                                viewMode ==
                                                                ViewMode.WEEK
                                                                    ? '#EBECF6'
                                                                    : '#ffffff',
                                                        }}
                                                    >
                                                        WEEK VIEW
                                                    </ViewModeText>
                                                </Grid>
                                            </Grid>
                                        </BorderedBox>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box>
                            {!showStockPrice && (
                                <TopicSentimentPlot
                                    topicSubscriptionId={topicSubscriptionId}
                                    granularity={
                                        viewMode == ViewMode.WEEK
                                            ? 'WEEKLY'
                                            : 'DAILY'
                                    }
                                    onBarClick={updateDate}
                                />
                            )}
                            {showStockPrice && (
                                <StockPricePlot
                                    topicSubscriptionId={topicSubscriptionId}
                                    onBarClick={updateDate}
                                />
                            )}
                        </Box>
                    </BorderedBox>
                    <TopicInsights
                        topicSubscriptionId={topicSubscriptionId}
                        granularity={
                            viewMode == ViewMode.DAY
                                ? Granularity.DAILY
                                : Granularity.WEEKLY
                        }
                        barClickCount={barClickCount}
                        renderDetails={renderDetails}
                        date={selectedDate}
                    />
                </Box>
            )}
        </Box>
    );
};
