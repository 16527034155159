import {
    Box,
    Button,
    Menu,
    Tooltip,
    Switch,
    Typography,
    FormControl,
    Divider,
    Chip,
    CircularProgress,
    Modal,
    Slide,
} from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { useSnackbar } from 'notistack';
import SingleSelect from '../../../../components/core/input/single-select';
import { Calendar } from '../../../../components/icons/calendar';
import { Input } from '../../../../components/core/input';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {
    DataSourceConfigCustomData,
    Domain,
    ILiveQAFiltersResponseData,
    ITrustedDomain,
} from '../../../../services/interfaces';
import { useTopicService } from '../../../../services/topic.service';
import { InternetIcon } from '../../../../components/icons/internet';
import { ChatbotFilters } from '../../../chat-widget/chatbot/settings/chatbotFilters';
import { useChatWidgetService } from '../../../../services/chat-widget.service';
import { UtilsService } from '../../../../services/utils.service';
import { CookieService } from '../../../../services/cookie.service';
import { BorderedBox } from '../../../../components/core/box';
import { useAccountService } from '../../../../services/account.service';
import GPTSelect from '../../../../components/GTP-model-select';
import { useLiveServices } from '../../../../services/live.service';
import { AppContext } from '../../../../providers/app-provider';
import { Authorize } from '../../../../components/authorize';
import { FeatureName, SubFeatureName } from '../../../../services/interfaces';
import { usePermissions } from '../../../../providers/permissions-provider';

export interface ISelectOption {
    title: string;
    value: string;
    icon?: JSX.Element;
}

const DATA_SOURCES = [
    {
        id: 'WEB',
        value: 'Web Sources',
    },
    {
        id: 'DOCUMENTS',
        value: 'User Documents',
    },
    {
        id: 'ALL',
        value: 'All',
    },
];

export const DATE_RANGES: ISelectOption[] = [
    {
        title: 'Last Day',
        value: 'PAST_DAY',
    },
    {
        title: 'Last Week',
        value: 'PAST_WEEK',
    },
    {
        title: 'Last Month',
        value: 'PAST_MONTH',
    },
    {
        title: 'Last Quarter',
        value: 'PAST_QUARTER',
    },
    {
        title: 'Last Year',
        value: 'PAST_YEAR',
    },
    {
        title: 'Anytime',
        value: 'ANYTIME',
    },
];

export const SOURCES: ISelectOption[] = [
    {
        title: 'News',
        value: 'NEWS',
    },
    {
        title: 'Internet',
        value: 'INTERNET',
    },
    {
        title: 'Custom Domains',
        value: 'CUSTOM_DOMAINS',
    },
];

export interface IProps {
    onOpen?: () => void;
    filtersFetched: boolean;
    min: boolean;
    color?: string;
    open?: boolean;
    outlined?: boolean;
    setOpen?: (status: boolean) => void;
    setFiltersfetched: (status: boolean) => void;
    onFiltersUpdate?: (filters: ILiveQAFiltersResponseData) => void;
}

export const Filters = (props: IProps) => {
    const [open, setModalOpen] = useState(false);
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    const appContext = useContext(AppContext);
    const [dataSourceCount, setDataSourcesCount] = useState<number | undefined>(
        undefined
    );
    const [dataSource, setDataSource] = useState('');
    const [domainsList, setDomainsList] = useState<ITrustedDomain[]>([]);
    const [enableHighlights, setEnableHighlights] = useState(false);
    const [enableInsights, setEnableInsights] = useState(false);
    const { fetchDomainsList } = useAccountService();
    const [enableEntityHighlights, setEnableEntityHighlights] = useState(false);
    const [domainListId, setDomainListId] = useState<string | undefined>('');
    const [fileFilters, setFileFilters] = useState<DataSourceConfigCustomData>({
        fileTypes: [],
        userTagIds: [],
    });
    const [processing, setProcessing] = useState<boolean>(false);
    const [domains, setDomains] = useState<string[]>([]);
    const [domainInput, setDomainInput] = useState('');
    const [server, setServer] = useState('');
    const [selectedServer, setSelectedServer] = useState('');
    const { getSourcesCount } = useChatWidgetService();
    const { fetchLiveQueryFilter, updateLiveQueryFilter } = useLiveServices();
    const [filters, setFilters] = useState<ILiveQAFiltersResponseData>({
        sentimentHighlight: false,
        entitiesFromAnswer: false,
        customDomains: [],
        insightsMode: false,
        dateRange: 'PAST_MONTH',
        onlineSearchSource: 'Internet',
        fileTypes: [],
        userTagIds: [],
        dataSource: 'ALL',
    });
    const { enqueueSnackbar } = useSnackbar();
    const [selectedDateRange, setSelectedDateRange] = useState<ISelectOption>({
        title: 'Last Day',
        value: 'PAST_DAY',
    });
    const [selectedSource, setSelectedSource] = useState<ISelectOption>({
        title: 'Internet',
        value: 'INTERNET',
    });
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (props.open !== undefined) {
            setModalOpen(props.open);
        }
    }, [props.open]);

    const setOpen = (o: boolean) => {
        setModalOpen(o);
        if (props.setOpen) {
            props.setOpen(o);
        }
    };

    const refreshFilters = async () => {
        const data = await fetchLiveQueryFilter();
        setFilters(data);
        if (data.server) {
            setServer(data.server);
            setSelectedServer(data.server);
        }
        props.setFiltersfetched && props.setFiltersfetched(true);
        setFileFilters({
            fileTypes: data.fileTypes || [],
            userTagIds: data.userTagIds || [],
        });
        setDataSource(data.dataSource);
        setDomainListId(data.userTrustedDomainId);
    };

    const refreshFilesCount = async () => {
        let data = await getSourcesCount(
            fileFilters.fileTypes,
            fileFilters.userTagIds
        );
        setDataSourcesCount(data.filtersFileCount);
    };

    const setLocalFilters = async () => {
        let f = await new CookieService().get('live-settings');
        if (f) {
            setFilters(JSON.parse(f));
        }
    };
    const refreshDomains = async () => {
        let d = await fetchDomainsList();
        setDomainsList(d);
    };

    useEffect(() => {
        if (props.filtersFetched) {
            refreshFilesCount();
        }
    }, [fileFilters, props.filtersFetched]);

    useEffect(() => {
        if (UtilsService.isDemoApp()) {
            setLocalFilters();
            return;
        }
        refreshFilters();
        refreshDomains();
    }, []);

    useEffect(() => {
        if (open) {
            if (props.onOpen) {
                props.onOpen();
            }
            if (UtilsService.isDemoApp()) {
                setLocalFilters();
                return;
            } else {
                resetFilters();
            }
        }
    }, [open]);

    const handleServerModelChange = async (s: string) => {
        setProcessing(true);
        setServer(s);
        let res = await updateLiveQueryFilter({
            ...filters,
            server: s,
        });
        if (res) {
            setSelectedServer(s);
            enqueueSnackbar('Model Updated Successfully', {
                variant: 'success',
            });
        }
        setProcessing(false);
    };

    const resetFilters = () => {
        props.onFiltersUpdate && props.onFiltersUpdate(filters);
        setEnableHighlights(filters.sentimentHighlight);
        setEnableEntityHighlights(filters.entitiesFromAnswer);
        setEnableInsights(filters.insightsMode);
        setFileFilters({
            fileTypes: filters.fileTypes || [],
            userTagIds: filters.userTagIds || [],
        });
        setSelectedDateRange(
            DATE_RANGES.filter(
                (r) => r.value == filters.dateRange.toLocaleUpperCase()
            )[0]
                ? DATE_RANGES.filter(
                      (r) => r.value == filters.dateRange.toLocaleUpperCase()
                  )[0]
                : selectedDateRange
        );
        if (filters.onlineSearchSource == 'CUSTOM_DOMAINS') {
            setSelectedSource(
                SOURCES.filter((r) => r.value == 'CUSTOM_DOMAINS')[0]
            );
            setDomains(filters.customDomains);
        } else {
            setDomainInput('');
            setDomains([]);
            if (filters.onlineSearchSource) {
                setSelectedSource(
                    SOURCES.filter(
                        (r) =>
                            r.value ==
                            filters.onlineSearchSource?.toLocaleUpperCase()
                    )[0]
                        ? SOURCES.filter(
                              (r) =>
                                  r.value ==
                                  filters.onlineSearchSource?.toLocaleUpperCase()
                          )[0]
                        : selectedSource
                );
            }
        }
    };

    useEffect(resetFilters, [filters]);

    const saveFilters = async () => {
        let f = {
            sentimentHighlight: enableHighlights,
            entitiesFromAnswer: enableEntityHighlights,
            server,
            customDomains:
                selectedSource.value == 'CUSTOM_DOMAINS' ? domains : [],
            dateRange: selectedDateRange.value,
            onlineSearchSource:
                dataSource == 'DOCUMENTS' ? undefined : selectedSource.value,
            insightsMode: enableInsights,
            dataSource: dataSource,
            userTrustedDomainId: domainListId,
            fileTypes:
                dataSource == 'WEB'
                    ? undefined
                    : fileFilters.fileTypes.length > 0
                    ? fileFilters.fileTypes
                    : [],
            userTagIds:
                dataSource == 'WEB'
                    ? undefined
                    : fileFilters.userTagIds.length > 0
                    ? fileFilters.userTagIds
                    : [],
        };
        if (UtilsService.isDemoApp()) {
            setProcessing(true);
            await new CookieService().set('live-settings', JSON.stringify(f));
            setProcessing(false);
            setOpen(false);
            return;
        }
        setProcessing(true);
        let res = await updateLiveQueryFilter(f);
        setProcessing(false);
        if (res) {
            setSelectedServer(server);
            setFilters({
                sentimentHighlight: enableHighlights,
                insightsMode: enableInsights,
                entitiesFromAnswer: enableEntityHighlights,
                customDomains:
                    selectedSource.value == 'CUSTOM_DOMAINS' ? domains : [],
                dateRange: selectedDateRange.value,
                onlineSearchSource: selectedSource.value,
                dataSource: dataSource,
                userTrustedDomainId: domainListId,
                fileTypes:
                    dataSource == 'WEB'
                        ? undefined
                        : fileFilters.fileTypes.length > 0
                        ? fileFilters.fileTypes
                        : [],
                userTagIds:
                    dataSource == 'WEB'
                        ? undefined
                        : fileFilters.userTagIds.length > 0
                        ? fileFilters.userTagIds
                        : [],
            });
            setOpen(false);
            enqueueSnackbar('Filters Updated', {
                variant: 'success',
            });
        }
    };
    const removeDomain = (domain: string) => {
        setDomains([...domains.filter((d: string) => d != domain)]);
    };

    const addDomain = () => {
        if (domainInput) {
            try {
                if (!domains.includes(domainInput)) {
                    setDomains([domainInput, ...domains]);
                }
                setDomainInput('');
            } catch (error) {
                enqueueSnackbar('invalid domain', {
                    variant: 'error',
                });
            }
        }
    };

    return (
        <Box
            onClick={(event: any) => {
                event.stopPropagation();
            }}
        >
            {props.filtersFetched && (
                <Box>
                    {!props.min && (
                        <Box display="flex">
                            {['WEB', 'ALL'].includes(dataSource) && (
                                <Tooltip
                                    placement="bottom"
                                    title={
                                        selectedSource.value == 'CUSTOM_DOMAINS'
                                            ? [
                                                  ...(domainsList
                                                      .filter(
                                                          (v: ITrustedDomain) =>
                                                              v.id ==
                                                              domainListId
                                                      )[0]
                                                      ?.domains.map(
                                                          (d: Domain) =>
                                                              d.domainName
                                                      ) || []),
                                                  ...domains,
                                              ].join(', ')
                                            : ''
                                    }
                                >
                                    <Typography sx={{ mr: 6 }} color="#a5a6ab">
                                        <Box component={'span'} pr={1}>
                                            {' '}
                                            <LanguageOutlinedIcon
                                                sx={{
                                                    fontSize: 16,

                                                    verticalAlign:
                                                        'text-bottom',
                                                }}
                                            />
                                        </Box>
                                        {selectedSource.value ==
                                        'CUSTOM_DOMAINS' ? (
                                            domainListId ? (
                                                <Box component="span">
                                                    {
                                                        domainsList.filter(
                                                            (
                                                                v: ITrustedDomain
                                                            ) =>
                                                                v.id ==
                                                                domainListId
                                                        )[0]?.displayName
                                                    }
                                                    {domains.length > 0 ? (
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                backgroundColor:
                                                                    '#dddeed',
                                                                width: 17,
                                                                ml: 1,
                                                                fontSize: 10,
                                                                display:
                                                                    'inline-block',
                                                                borderRadius:
                                                                    '50%',
                                                            }}
                                                        >
                                                            +{domains.length}
                                                        </Box>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Box>
                                            ) : (
                                                `${domains.length} Domains`
                                            )
                                        ) : (
                                            UtilsService.firstCharUppercase(
                                                selectedSource.value
                                            )
                                        )}
                                    </Typography>
                                </Tooltip>
                            )}
                            <Typography color="#a5a6ab" sx={{ mr: 6 }}>
                                <Box component={'span'} pr={1}>
                                    <DescriptionOutlinedIcon
                                        sx={{
                                            fontSize: 16,
                                            verticalAlign: 'text-bottom',
                                        }}
                                    />
                                </Box>
                                {dataSource == 'WEB'
                                    ? 'None'
                                    : dataSourceCount
                                    ? dataSourceCount + ' Documents'
                                    : ''}
                            </Typography>
                            {['WEB', 'ALL'].includes(dataSource) && (
                                <Typography color="#a5a6ab" sx={{ mr: 6 }}>
                                    <Box component={'span'} pr={1}>
                                        <Calendar
                                            verticalAlign="sub"
                                            size={16}
                                        />
                                    </Box>
                                    {selectedDateRange.title}
                                </Typography>
                            )}
                            {/* <Box mr={4}>
                                <GPTSelect
                                    selectedValue={selectedServer}
                                    onChange={handleServerModelChange}
                                    min
                                />
                            </Box> */}
                            <Button
                                id="settings-button"
                                onClick={(event) => {
                                    setOpen(true);
                                }}
                                sx={{
                                    p: 0,
                                    pr: 1,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    height: 24,
                                    marginRight: 0,
                                    minWidth: 24,
                                    backgroundColor: '#FFFFFF',
                                    color: '#29319B',

                                    '&:hover': {
                                        backgroundColor: '#FFFFFF',
                                        color: '#29319B',
                                    },
                                }}
                            >
                                <SettingsIcon
                                    sx={{ fontSize: `16px !important`, mr: 1 }}
                                />
                                Settings
                            </Button>
                        </Box>
                    )}
                    {props.min && (
                        <Button
                            onClick={(event) => {
                                setOpen(true);
                            }}
                            sx={{
                                p: 0,
                                pr: 1,
                                fontSize: 12,
                                fontWeight: 600,
                                height: 24,
                                marginRight: 0,
                                minWidth: 24,
                                backgroundColor: '#FFFFFF',
                                color: props.color || '#29319B',
                                '&:hover': {
                                    backgroundColor: '#FFFFFF',
                                    color: '#29319B',
                                },
                            }}
                        >
                            {props.outlined ? (
                                <SettingsOutlinedIcon />
                            ) : (
                                <SettingsIcon />
                            )}
                        </Button>
                    )}
                </Box>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                hideBackdrop={appContext?.isMobile}
                aria-labelledby="modal-modal-title"
                slotProps={{
                    backdrop: {
                        timeout: appContext?.isMobile ? 0 : undefined,
                    },
                }}
                aria-describedby="modal-modal-description"
                sx={{
                    backdropFilter: appContext?.isMobile
                        ? undefined
                        : 'blur(2px)',
                }}
            >
                <Slide
                    direction="left"
                    in={open}
                    timeout={appContext?.isMobile ? 300 : 0}
                    mountOnEnter
                    unmountOnExit
                >
                    <Box
                        p={{ xs: 4, sm: 5 }}
                        sx={{
                            position: appContext?.isMobile
                                ? undefined
                                : ('absolute' as 'absolute'),
                            top: '20%',
                            borderRadius: appContext?.isMobile ? 0 : 2,
                            left: '32%',
                            transform: 'translate(-50%, -50%)',
                            width: appContext?.isMobile ? '100%' : 550,
                            height: appContext?.isMobile ? '100vh' : undefined,
                            maxWidth: '100%',
                            bgcolor: '#FFFFFF',
                            maxHeight: appContext?.isMobile
                                ? '100vh'
                                : 'calc(100vh - 50px)',
                            overflowY: 'auto',
                        }}
                    >
                        <Box pt={1}>
                            <FormControl sx={{ width: '100%' }}>
                                <Box
                                    sx={{
                                        height: appContext?.isMobile
                                            ? 'calc(100vh - 50px)'
                                            : undefined,
                                        position: appContext?.isMobile
                                            ? 'relative'
                                            : undefined,
                                    }}
                                >
                                    <Typography variant="h4">
                                        Settings
                                    </Typography>

                                    <Box>
                                        <Box
                                            py={4}
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Box>
                                                <Typography
                                                    color="#003"
                                                    variant="h6"
                                                >
                                                    Sentiment Highlights
                                                </Typography>
                                                <Typography
                                                    color="#8D8D8D"
                                                    fontSize={12}
                                                    mt={0.5}
                                                    lineHeight={'16px'}
                                                >
                                                    Enable highlights on AI
                                                    generated response
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Switch
                                                    onChange={(e) => {
                                                        setEnableHighlights(
                                                            e.target.checked
                                                        );
                                                    }}
                                                    checked={enableHighlights}
                                                />
                                            </Box>
                                        </Box>
                                        <Box
                                            pb={4}
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Box>
                                                <Typography
                                                    color="#003"
                                                    variant="h6"
                                                >
                                                    Entity Highlights
                                                </Typography>
                                                <Typography
                                                    color="#8D8D8D"
                                                    mt={0.5}
                                                    fontSize={12}
                                                    lineHeight={'16px'}
                                                >
                                                    Highlight the entity in the
                                                    summary generated
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Switch
                                                    onChange={(e) => {
                                                        setEnableEntityHighlights(
                                                            e.target.checked
                                                        );
                                                    }}
                                                    checked={
                                                        enableEntityHighlights
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                        {appContext?.isMobile === false && (
                                            <Authorize
                                                featureName={
                                                    FeatureName.InstantInsights
                                                }
                                                subFeatureName={
                                                    SubFeatureName.InsightsMode
                                                }
                                            >
                                                <Box
                                                    pb={4}
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    <Box>
                                                        <Typography
                                                            color={
                                                                isSubFeatureAuthorized(
                                                                    FeatureName.InstantInsights,
                                                                    SubFeatureName.InsightsMode
                                                                )
                                                                    ? '#003'
                                                                    : '#ccc'
                                                            }
                                                            variant="h6"
                                                        >
                                                            Insights Mode
                                                        </Typography>
                                                        <Typography
                                                            color={
                                                                isSubFeatureAuthorized(
                                                                    FeatureName.InstantInsights,
                                                                    SubFeatureName.InsightsMode
                                                                )
                                                                    ? '#8D8D8D'
                                                                    : '#ccc'
                                                            }
                                                            fontSize={12}
                                                            lineHeight={'16px'}
                                                        >
                                                            Enable insights mode
                                                            by default for all
                                                            queries
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Switch
                                                            onChange={(e) => {
                                                                setEnableInsights(
                                                                    e.target
                                                                        .checked
                                                                );
                                                            }}
                                                            disabled={
                                                                !isSubFeatureAuthorized(
                                                                    FeatureName.InstantInsights,
                                                                    SubFeatureName.InsightsMode
                                                                )
                                                            }
                                                            checked={
                                                                enableInsights
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            </Authorize>
                                        )}
                                        <Divider />
                                        <Authorize
                                            featureName={FeatureName.Chatbot}
                                            subFeatureName={
                                                SubFeatureName.ModelSelection
                                            }
                                        >
                                            <Box
                                                pt={4}
                                                display="flex"
                                                justifyContent="space-between"
                                            >
                                                <Box>
                                                    <Typography
                                                        color={
                                                            isSubFeatureAuthorized(
                                                                FeatureName.Chatbot,
                                                                SubFeatureName.ModelSelection
                                                            )
                                                                ? '#003'
                                                                : '#ccc'
                                                        }
                                                        variant="h6"
                                                    >
                                                        Select AI Model
                                                    </Typography>
                                                    <Typography
                                                        color={
                                                            isSubFeatureAuthorized(
                                                                FeatureName.Chatbot,
                                                                SubFeatureName.ModelSelection
                                                            )
                                                                ? '#8D8D8D'
                                                                : '#ccc'
                                                        }
                                                        fontSize={12}
                                                        lineHeight={'16px'}
                                                    >
                                                        {appContext?.servers.filter(
                                                            (s) =>
                                                                s.name == server
                                                        )[0]
                                                            ? `Consumes ${
                                                                  appContext?.servers.filter(
                                                                      (s) =>
                                                                          s.name ==
                                                                          server
                                                                  )[0]
                                                                      .quotaConsumptionAmount
                                                              } Credit per response`
                                                            : ''}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <GPTSelect
                                                        width={
                                                            appContext?.isMobile
                                                                ? 170
                                                                : 210
                                                        }
                                                        disabled={
                                                            !isSubFeatureAuthorized(
                                                                FeatureName.Chatbot,
                                                                SubFeatureName.ModelSelection
                                                            )
                                                        }
                                                        selectedValue={server}
                                                        onChange={(s) =>
                                                            setServer(s)
                                                        }
                                                        borderColor="#8D8D8D"
                                                    />
                                                </Box>
                                            </Box>
                                        </Authorize>
                                        <Box
                                            pt={4}
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Box>
                                                <Typography
                                                    color="#003"
                                                    variant="h6"
                                                >
                                                    Data Sources Selection
                                                </Typography>
                                                <Typography
                                                    color="#8D8D8D"
                                                    fontSize={12}
                                                    mt={0.5}
                                                    lineHeight={'16px'}
                                                >
                                                    Search across various
                                                    sources
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <SingleSelect
                                                    getOptionText={(v: any) =>
                                                        v ? v.value : ''
                                                    }
                                                    placeholder="Data Source"
                                                    pt={1}
                                                    ptContainer={0}
                                                    pb={2}
                                                    width={
                                                        appContext?.isMobile
                                                            ? 170
                                                            : 210
                                                    }
                                                    disableCheckbox
                                                    backgroundColor="#fff"
                                                    border={'1px solid #8D8D8D'}
                                                    borderRadius={1}
                                                    mt={0}
                                                    options={DATA_SOURCES.filter(
                                                        (o) => o.id == 'WEB'
                                                    )}
                                                    authOptions={DATA_SOURCES.filter(
                                                        (o) => o.id !== 'WEB'
                                                    ).map((o) => {
                                                        return {
                                                            option: o,
                                                            featureName:
                                                                FeatureName.LiveQA,
                                                            subFeatureName:
                                                                SubFeatureName.WebDocs,
                                                        };
                                                    })}
                                                    selectedValue={
                                                        DATA_SOURCES.filter(
                                                            (v) =>
                                                                v.id ==
                                                                dataSource
                                                        )[0]
                                                    }
                                                    onChange={(v: any) => {
                                                        setDataSource(v.id);
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        {['WEB', 'ALL'].includes(
                                            dataSource
                                        ) && (
                                            <Box>
                                                <Box
                                                    pt={4}
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    <Box>
                                                        <Typography
                                                            color="#003"
                                                            fontSize={14}
                                                            fontWeight={600}
                                                        >
                                                            Date Range for
                                                            Search
                                                        </Typography>
                                                        <Typography
                                                            color="#8D8D8D"
                                                            fontSize={12}
                                                            mt={0.5}
                                                            lineHeight={'16px'}
                                                        >
                                                            Filter your search
                                                            with the date range
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <SingleSelect
                                                            getOptionText={(
                                                                v: ISelectOption
                                                            ) => v.title}
                                                            placeholder="Select Source"
                                                            disableCheckbox
                                                            pt={1}
                                                            ptContainer={0}
                                                            pb={2}
                                                            width={
                                                                appContext?.isMobile
                                                                    ? 170
                                                                    : 210
                                                            }
                                                            border={
                                                                '1px solid #8D8D8D'
                                                            }
                                                            borderRadius={1}
                                                            mt={0}
                                                            icon={
                                                                <Tooltip
                                                                    arrow
                                                                    placement="top"
                                                                    title={
                                                                        'Select Date Range'
                                                                    }
                                                                >
                                                                    <Calendar
                                                                        color={
                                                                            '#003'
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            }
                                                            options={
                                                                DATE_RANGES
                                                            }
                                                            selectedValue={
                                                                selectedDateRange
                                                            }
                                                            onChange={(
                                                                date: ISelectOption
                                                            ) =>
                                                                setSelectedDateRange(
                                                                    date
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box
                                                    pt={4}
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    <Box>
                                                        <Typography
                                                            color="#003"
                                                            fontSize={14}
                                                            fontWeight={600}
                                                        >
                                                            Online Search
                                                            Sources
                                                        </Typography>
                                                        <Typography
                                                            color="#8D8D8D"
                                                            fontSize={12}
                                                            mt={0.5}
                                                            lineHeight={'16px'}
                                                        >
                                                            Search across
                                                            various online
                                                            sources
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <SingleSelect
                                                            getOptionText={(
                                                                v: ISelectOption
                                                            ) => v.title}
                                                            placeholder="Select Source"
                                                            pt={1}
                                                            ptContainer={0}
                                                            pb={2}
                                                            width={
                                                                appContext?.isMobile
                                                                    ? 170
                                                                    : 210
                                                            }
                                                            border={
                                                                '1px solid #8D8D8D'
                                                            }
                                                            borderRadius={1}
                                                            mt={0}
                                                            disableCheckbox
                                                            icon={
                                                                <Tooltip
                                                                    arrow
                                                                    placement="top"
                                                                    title={
                                                                        'Select Search Source'
                                                                    }
                                                                >
                                                                    {
                                                                        InternetIcon
                                                                    }
                                                                </Tooltip>
                                                            }
                                                            options={SOURCES.filter(
                                                                (o) =>
                                                                    o.value !=
                                                                    'CUSTOM_DOMAINS'
                                                            )}
                                                            authOptions={SOURCES.filter(
                                                                (o) =>
                                                                    o.value ==
                                                                    'CUSTOM_DOMAINS'
                                                            ).map((o) => {
                                                                return {
                                                                    option: o,
                                                                    featureName:
                                                                        FeatureName.LiveQA,
                                                                    subFeatureName:
                                                                        SubFeatureName.CustomDomains,
                                                                };
                                                            })}
                                                            selectedValue={
                                                                selectedSource
                                                            }
                                                            onChange={(
                                                                date: ISelectOption
                                                            ) =>
                                                                setSelectedSource(
                                                                    date
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                                {selectedSource.value ==
                                                    'CUSTOM_DOMAINS' && (
                                                    <Box>
                                                        <Box
                                                            pt={4}
                                                            display="flex"
                                                            justifyContent="space-between"
                                                        >
                                                            <Box>
                                                                <Typography
                                                                    color="#003"
                                                                    fontSize={
                                                                        14
                                                                    }
                                                                    fontWeight={
                                                                        600
                                                                    }
                                                                >
                                                                    Custom
                                                                    Domains
                                                                </Typography>
                                                                <Typography
                                                                    color="#8D8D8D"
                                                                    fontSize={
                                                                        12
                                                                    }
                                                                    mt={0.5}
                                                                    lineHeight={
                                                                        '16px'
                                                                    }
                                                                >
                                                                    Attach a
                                                                    list and/or
                                                                    enter the
                                                                    domains
                                                                    below
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <SingleSelect
                                                                    getOptionText={(
                                                                        v: ITrustedDomain
                                                                    ) =>
                                                                        v
                                                                            ? v.displayName
                                                                            : 'Select Domain List'
                                                                    }
                                                                    placeholder="Select Doamin List"
                                                                    allowClearSelection
                                                                    pt={1}
                                                                    ptContainer={
                                                                        0
                                                                    }
                                                                    pb={2}
                                                                    border={
                                                                        '1px solid #8D8D8D'
                                                                    }
                                                                    borderRadius={
                                                                        1
                                                                    }
                                                                    mt={0}
                                                                    width={
                                                                        appContext?.isMobile
                                                                            ? 170
                                                                            : 210
                                                                    }
                                                                    disableCheckbox
                                                                    backgroundColor="#fff"
                                                                    options={
                                                                        domainsList
                                                                    }
                                                                    selectedValue={
                                                                        domainsList.filter(
                                                                            (
                                                                                v: ITrustedDomain
                                                                            ) =>
                                                                                v.id ==
                                                                                domainListId
                                                                        )[0]
                                                                    }
                                                                    onChange={(
                                                                        v: ITrustedDomain
                                                                    ) => {
                                                                        setDomainListId(
                                                                            v
                                                                                ? v.id
                                                                                : undefined
                                                                        );
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>

                                                        <Box>
                                                            <Input
                                                                value={
                                                                    domainInput
                                                                }
                                                                onEnterPress={
                                                                    addDomain
                                                                }
                                                                pb={0}
                                                                pt={0}
                                                                borderColor="#29319B"
                                                                onKeyDown={(
                                                                    e: KeyboardEvent
                                                                ) => {
                                                                    e.stopPropagation();
                                                                }}
                                                                onChange={(
                                                                    e: ChangeEvent<HTMLInputElement>
                                                                ) => {
                                                                    setDomainInput(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                    e.preventDefault();
                                                                }}
                                                                endAdornment={
                                                                    <Button
                                                                        onClick={
                                                                            addDomain
                                                                        }
                                                                        sx={{
                                                                            pt: 1,
                                                                            pb: 1,
                                                                        }}
                                                                        disabled={
                                                                            !domainInput
                                                                        }
                                                                        variant="contained"
                                                                    >
                                                                        add
                                                                    </Button>
                                                                }
                                                                sx={{
                                                                    '&.MuiOutlinedInput-root':
                                                                        {
                                                                            paddingRight:
                                                                                '8px !important',
                                                                        },
                                                                }}
                                                                placeholder={
                                                                    'e.g., cnn.com'
                                                                }
                                                                type={''}
                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                maxHeight:
                                                                    '200px',
                                                                overflow:
                                                                    'auto',
                                                            }}
                                                            mb={2}
                                                        >
                                                            {domains.map(
                                                                (
                                                                    domain: string
                                                                ) => (
                                                                    <Chip
                                                                        sx={{
                                                                            mt: 2,
                                                                            mr: 2,
                                                                        }}
                                                                        label={
                                                                            domain
                                                                        }
                                                                        variant="outlined"
                                                                        onDelete={() =>
                                                                            removeDomain(
                                                                                domain
                                                                            )
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                        )}
                                        {!UtilsService.isDemoApp() &&
                                            ['DOCUMENTS', 'ALL'].includes(
                                                dataSource
                                            ) && (
                                                <Box pt={2}>
                                                    <ChatbotFilters
                                                        contentWidth={70}
                                                        updateFilters={
                                                            setFileFilters
                                                        }
                                                        datasourceConfig={
                                                            fileFilters
                                                        }
                                                    />
                                                </Box>
                                            )}
                                    </Box>
                                    <Box
                                        sx={
                                            appContext?.isMobile
                                                ? {
                                                      position: 'absolute',
                                                      bottom: 50,
                                                      width: '100%',
                                                  }
                                                : undefined
                                        }
                                        pt={2}
                                        my={2}
                                    >
                                        <Box display="table" mx="auto">
                                            <Button
                                                sx={{
                                                    mr: 2,
                                                    fontWeight: 600,
                                                }}
                                                onClick={() => {
                                                    setOpen(false);
                                                }}
                                                variant="text"
                                            >
                                                Close
                                            </Button>
                                            <Button
                                                disabled={
                                                    processing ||
                                                    (dataSource !=
                                                        'DOCUMENTS' &&
                                                        selectedSource.value ==
                                                            'CUSTOM_DOMAINS' &&
                                                        domains.length == 0 &&
                                                        !domainListId)
                                                }
                                                onClick={saveFilters}
                                                sx={{
                                                    ml: 2,
                                                    px: 5,
                                                    minWidth: 70,
                                                    borderRadius: 2,
                                                }}
                                                variant="contained"
                                            >
                                                {!processing && (
                                                    <span>Save</span>
                                                )}
                                                {processing && (
                                                    <CircularProgress
                                                        size={24}
                                                        sx={{
                                                            color: '#ffffff',
                                                        }}
                                                    />
                                                )}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </FormControl>
                        </Box>
                    </Box>
                </Slide>
            </Modal>
        </Box>
    );
};
