export const StockChartSkeleton = (
    <svg
        width="303"
        height="149"
        viewBox="0 0 303 149"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.09201 92.9011L1 96.6362V140.375H302.25V6.79524L300.158 7.58932C298.066 8.38341 293.882 9.97158 289.698 14.9168C285.514 19.8619 281.33 28.1641 277.146 32.617C272.962 37.0698 268.778 37.6734 264.594 33.2271C260.41 28.7808 256.226 19.2847 252.042 12.3025C247.858 5.32028 243.674 0.851941 239.49 1.00375C235.306 1.15556 231.122 5.92752 226.938 11.8436C222.753 17.7598 218.569 24.8201 214.385 32.0614C210.201 39.3028 206.017 46.7251 201.833 45.891C197.649 45.0569 193.465 35.9663 189.281 29.4019C185.097 22.8375 180.913 18.7994 176.729 23.0755C172.545 27.3515 168.361 39.9416 164.177 44.4768C159.993 49.012 155.809 45.4923 151.625 46.3297C147.441 47.1671 143.257 52.3616 139.073 54.1021C134.889 55.8426 130.705 54.1289 126.521 59.099C122.337 64.069 118.153 75.7227 113.969 74.5591C109.785 73.3954 105.601 59.4144 101.417 50.9903C97.2326 42.5661 93.0486 39.6989 88.8646 47.1637C84.6805 54.6285 80.4965 72.4252 76.3125 78.2378C72.1285 84.0503 67.9444 77.8786 63.7604 82.9796C59.5764 88.0806 55.3924 104.454 51.2083 109.527C47.0243 114.6 42.8403 108.371 38.6562 103.077C34.4722 97.7825 30.2882 93.4223 26.1042 88.7695C21.9201 84.1166 17.7361 79.1711 13.5521 80.4335C9.36805 81.6958 5.18403 89.166 3.09201 92.9011Z"
            fill="url(#paint0_linear_8999_59023)"
            fill-opacity="0.46"
            stroke="url(#paint1_linear_8999_59023)"
            stroke-width="1.09375"
        />
        <rect x="1" y="139.125" width="75" height="2.5" fill="#D82F44" />
        <rect x="78.5" y="139.125" width="75" height="2.5" fill="#19AF55" />
        <rect x="156" y="139.125" width="75" height="2.5" fill="#19AF55" />
        <rect x="233.5" y="139.125" width="68.75" height="2.5" fill="#19AF55" />
        <path
            d="M156.625 148.5V141.625H155.375V148.5H156.625Z"
            fill="#19AF55"
            mask="url(#path-6-inside-1_8999_59023)"
        />
        <path
            d="M79.125 148.5V141.625H77.875V148.5H79.125Z"
            fill="#19AF55"
            mask="url(#path-8-inside-2_8999_59023)"
        />
        <path
            d="M1.625 148.5V141.625H0.375V148.5H1.625Z"
            fill="#D82F44"
            mask="url(#path-10-inside-3_8999_59023)"
        />
        <path
            d="M234.125 148.5V141.625H232.875V148.5H234.125Z"
            fill="#19AF55"
            mask="url(#path-12-inside-4_8999_59023)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_8999_59023"
                x1="151.625"
                y1="1"
                x2="151.625"
                y2="115.062"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#DFBF8C" stop-opacity="0.54" />
                <stop offset="1" stop-color="#E59413" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_8999_59023"
                x1="186.534"
                y1="96.1029"
                x2="186.429"
                y2="139.828"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#D67A47" />
                <stop offset="1" stop-color="#FFAD4D" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
);
