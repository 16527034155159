import { Dropbox } from '../../../../components/icons/dropbox';
import { GoogleDriveIcon } from '../../../../components/icons/google-drive';
import { OneDriveIcon } from '../../../../components/icons/onedrive';
import { SharePointIcon } from '../../../../components/icons/sharepoint';

interface IDrive {
    id: string;
    name: string;
    title: string;
    icon: any;
}

export const DRIVE: IDrive[] = [
    {
        title: 'Google Drive',
        name: 'google_drive',
        id: 'f4f4e47e-1bca-473e-a72c-b0cb7d968c72',
        icon: GoogleDriveIcon,
    },
    {
        id: 'f06a364e-66a9-4c07-87d1-873939dd11bc',
        title: 'Share Point',
        name: 'sharepoint',
        icon: SharePointIcon,
    },
    {
        id: 'f4a2b87d-5e0a-4c59-b3fc-f1492d5812b0',
        title: 'One Drive',
        name: 'onedrive',
        icon: OneDriveIcon,
    },
    {
        id: '5631a362-41bc-49a9-b25a-868549f97e3d',
        title: 'Dropbox',
        name: 'dropbox',
        icon: Dropbox,
    },
];
