export const ErrorIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="65"
        height="64"
        viewBox="0 0 65 64"
        fill="none"
    >
        <mask
            id="mask0_3910_132213"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="65"
            height="64"
        >
            <rect x="0.5" width="64" height="64" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3910_132213)">
            <path
                d="M32.4973 44.6141C33.1076 44.6141 33.6191 44.4077 34.0319 43.9949C34.4447 43.5821 34.6511 43.0706 34.6511 42.4603C34.6511 41.8501 34.4447 41.3385 34.0319 40.9257C33.6191 40.5129 33.1076 40.3065 32.4973 40.3065C31.8871 40.3065 31.3755 40.5129 30.9627 40.9257C30.5499 41.3385 30.3435 41.8501 30.3435 42.4603C30.3435 43.0706 30.5499 43.5821 30.9627 43.9949C31.3755 44.4077 31.8871 44.6141 32.4973 44.6141ZM30.4974 34.8706H34.4973V18.8706H30.4974V34.8706ZM32.5018 57.3321C28.998 57.3321 25.7046 56.6672 22.6215 55.3374C19.5385 54.0076 16.8566 52.203 14.5761 49.9234C12.2954 47.6438 10.49 44.9632 9.1596 41.8815C7.82924 38.7998 7.16406 35.5071 7.16406 32.0033C7.16406 28.4994 7.82895 25.206 9.15873 22.123C10.4885 19.0399 12.2932 16.3581 14.5727 14.0775C16.8523 11.7969 19.533 9.99142 22.6147 8.66106C25.6963 7.33071 28.989 6.66553 32.4929 6.66553C35.9967 6.66553 39.2901 7.33042 42.3731 8.6602C45.4562 9.98997 48.138 11.7946 50.4186 14.0742C52.6992 16.3538 54.5047 19.0344 55.8351 22.1161C57.1654 25.1978 57.8306 28.4905 57.8306 31.9943C57.8306 35.4982 57.1657 38.7916 55.8359 41.8746C54.5062 44.9577 52.7015 47.6395 50.4219 49.9201C48.1423 52.2007 45.4617 54.0062 42.38 55.3365C39.2984 56.6669 36.0056 57.3321 32.5018 57.3321ZM32.4973 53.3321C38.4529 53.3321 43.4973 51.2655 47.6307 47.1321C51.764 42.9988 53.8307 37.9544 53.8307 31.9988C53.8307 26.0432 51.764 20.9988 47.6307 16.8655C43.4973 12.7321 38.4529 10.6655 32.4973 10.6655C26.5418 10.6655 21.4973 12.7321 17.364 16.8655C13.2307 20.9988 11.164 26.0432 11.164 31.9988C11.164 37.9544 13.2307 42.9988 17.364 47.1321C21.4973 51.2655 26.5418 53.3321 32.4973 53.3321Z"
                fill="#D82F44"
            />
        </g>
    </svg>
);
