import { Box, Divider, Typography } from '@mui/material';
import { Business } from '../../../../../components/icons/business';
import { Management } from '../../../../../components/icons/management';
import { RiskFactors } from '../../../../../components/icons/risk-factor';
import {
    EventStatements,
    SECEventStatements,
} from '../../../../../services/interfaces';

interface IProps {
    data: SECEventStatements;
    isLast: boolean;
    scrollToOrder: (orderId: number, section: string) => void;
}

export const SECEventFilter = (props: IProps) => {
    return (
        <Box>
            {props.data.summaries.length > 0 && (
                <>
                    <Box px={4} display="flex">
                        {props.data.section == 'Business' && (
                            <Box pt={0.5} sx={{ width: 20 }}>
                                <Business size={16} />
                            </Box>
                        )}
                        {props.data.section == 'MD and A' && (
                            <Box pt={1} sx={{ width: 20 }}>
                                <Management size={16} />
                            </Box>
                        )}
                        {props.data.section == 'Risk Factors' && (
                            <Box pt={1} sx={{ width: 20 }}>
                                <RiskFactors size={16} />
                            </Box>
                        )}
                        <Typography ml={1} variant="subtitle1">
                            {props.data.section == 'MD and A'
                                ? 'Management Discussion and Analysis'
                                : props.data.section}
                        </Typography>
                    </Box>
                    <Box pt={0.5} pb={0}>
                        {props.data.summaries.map(
                            (s: EventStatements, index) => (
                                <Box
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        props.scrollToOrder(
                                            s.orderId,
                                            props.data.section
                                        )
                                    }
                                    pb={0.5}
                                >
                                    <Typography
                                        px={4}
                                        color="#4B4B4C"
                                        variant="caption"
                                        pt={0}
                                        lineHeight={'20px'}
                                        className="clamp-line-2"
                                        fontSize={14}
                                    >
                                        {s.summary}
                                    </Typography>

                                    {!(
                                        props.isLast &&
                                        props.data.summaries.length ===
                                            index + 1
                                    ) && (
                                        <Box
                                            py={
                                                props.data.summaries.length ===
                                                index + 1
                                                    ? 3
                                                    : 2
                                            }
                                        >
                                            <Divider
                                                sx={{
                                                    borderWidth:
                                                        props.data.summaries
                                                            .length ===
                                                        index + 1
                                                            ? 1
                                                            : 0.5,
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            )
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
};
