import {
    Box,
    Divider,
    Typography,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { BorderedBox } from '../../../../../components/core/box';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { UtilsService } from '../../../../../services/utils.service';
import {
    InsiderTransaction,
    InsiderTransactionData,
} from '../../../live-insights/financial-info-interface';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        padding: '10px 16px',
        borderBottom: 'none',
        textAlign: 'center',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 'none',
        padding: '10px 16px',
        textAlign: 'center',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#F9F9FF',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number
) {
    return { name, calories, fat, carbs, protein };
}

interface IProps {
    data?: InsiderTransactionData;
}

export const Trading = (props: IProps) => {
    const [data, setData] = useState<InsiderTransactionData | null>(null);
    const refreshOwnership = async () => {
        if (!props.data) return;
        if (props.data) {
            setData(props.data);
        }
    };
    useEffect(() => {
        refreshOwnership();
    }, [props.data]);

    return (
        <BorderedBox sx={{ backgroundColor: '#FFFFFF' }} pt={3}>
            <Box>
                <Typography px={4} variant="h6">
                    Insider Trading
                </Typography>
                <Box pt={2}>
                    <Divider />
                </Box>
                <Box pb={3} sx={{ maxHeight: '455px', overflowY: 'auto' }}>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 700 }}
                            aria-label="customized table"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        sx={{ textAlign: 'left !important' }}
                                    >
                                        Insider
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Transaction
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Type
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Value
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Date
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Shares
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.insiderTrading.map(
                                    (row: InsiderTransaction, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                                sx={{
                                                    textAlign:
                                                        'left !important',
                                                }}
                                            >
                                                {row.filer_name}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {row.transaction_text}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {row.ownership_type === 'D'
                                                    ? 'Direct'
                                                    : row.ownership_type === 'I'
                                                    ? 'Indirect'
                                                    : 'Direct/Indirect'}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {row?.share_value?.toLocaleString(
                                                    'en',
                                                    {
                                                        minimumFractionDigits: 0,
                                                    }
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {UtilsService.formatStringDate(
                                                    row.transaction_date
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {row?.share_volume?.toLocaleString(
                                                    'en',
                                                    {
                                                        minimumFractionDigits: 0,
                                                    }
                                                )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </BorderedBox>
    );
};
