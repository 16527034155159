import { useContext, useEffect, useState } from 'react';
import { BorderedBox } from '../../../../components/core/box';
import { Box, Typography, Tabs, Divider, Switch } from '@mui/material';
import { CustomTab } from '../../../chat-widget/chatbot/settings';
import { DocumentInsightsContext } from '../../../../providers/document-provider';
import { useDocumentService } from '../../../../services/document.service';
import { IOSSwitch } from '../../../../components/core/ios-switch';
import { AnswerLength, DataSourceOptions } from './common-input';
import { IDiligenceTemplateConfiguration } from '../../../../services/interfaces';

interface IProps {
    config: IDiligenceTemplateConfiguration;
    updateConfig: (config: IDiligenceTemplateConfiguration) => void;
}

export const ProductOffering = (props: IProps) => {
    const [value, setValue] = useState(0);
    const documentContext = useContext(DocumentInsightsContext);
    const { fetchFilesFilters } = useDocumentService();
    const [enableInternalData, setEnableInternalData] = useState(false);
    const refreshFilters = async () => {
        let filters = await fetchFilesFilters();
        documentContext?.setFilters({ ...filters });
    };
    useEffect(() => {
        if (!documentContext?.filters) refreshFilters();
    }, [documentContext?.filters]);
    return (
        <BorderedBox sx={{ backgroundColor: '#F9F9FF', p: 3 }}>
            <Box py={1} display="flex" justifyContent="space-between">
                <Box>
                    <Typography color="#003" variant="h6">
                        Product Offerings
                    </Typography>
                    <Typography color="#8D8D8D" fontSize={12}>
                        Products and services offered by the company
                    </Typography>
                </Box>
                <Box></Box>
            </Box>

            <AnswerLength
                updateConfig={props.updateConfig}
                config={props.config}
            />
            <DataSourceOptions
                updateConfig={props.updateConfig}
                config={props.config}
            />
        </BorderedBox>
    );
};
