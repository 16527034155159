import { FC, useContext, useEffect, useState } from 'react';
import { OpenAI } from '../icons/open-ai';
import { useChatbotService } from '../../services/chatbot.service';
import { AppContext } from '../../providers/app-provider';
import SingleSelectTooltip from '../core/input/single-select-tooltip';

interface IProps {
    min?: boolean;
    disabled?: boolean;
    selectedValue?: string;
    borderColor?: string;
    fontSize?: number;
    iconSize?: number;
    width?: number;
    pt?: number;
    pb?: number;
    onChange?: (value: string) => void;
}
const GPTSelect = (props: IProps) => {
    const appContext = useContext(AppContext);
    const [options, setOptions] = useState<any[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const { fetchChatModels } = useChatbotService();
    const initialize = async () => {
        setIsFetching(true);
        let res = await fetchChatModels();
        appContext?.setServers(res);

        setIsFetching(true);
    };
    useEffect(() => {
        if (appContext && appContext.servers.length == 0 && !isFetching) {
            initialize();
        }
    }, []);

    useEffect(() => {
        if (appContext?.servers) {
            setOptions([
                ...appContext.servers.map((s) => {
                    return {
                        id: s.name,
                        displayName: s.displayName,
                        additionalInfo: `Consumes ${s.quotaConsumptionAmount} Credits per response`,
                        description: s.description,
                        status: s.status,
                    };
                }),
            ]);
        }
    }, [appContext]);
    return (
        <div>
            {options.length > 0 && (
                <SingleSelectTooltip
                    options={options}
                    onChange={props.onChange}
                    icon={OpenAI}
                    pt={props.pt || (props.min ? 0 : 1.5)}
                    pb={props.pb || (props.min ? 0 : 1.5)}
                    fontSize={props.fontSize || (props.min ? 14 : 16)}
                    mt={0}
                    disabled={props.disabled}
                    width={props.width || (props.min ? undefined : 210)}
                    textMt={0.75}
                    iconMt={0}
                    iconPt={props.min ? 0.25 : 0}
                    renderColor={
                        props.disabled ? '#ccc' : props.min ? '#a5a6ab' : ''
                    }
                    renderIconSize={props.iconSize || (props.min ? 14 : 20)}
                    borderRadius={1}
                    ptContainer={0}
                    border={
                        props.min
                            ? 'none'
                            : props.disabled
                            ? '1px solid #CCC'
                            : '1px solid #8D8D8D'
                    }
                    placeholder=""
                    selectedValue={props.selectedValue || 'gpt-4o-mini'}
                />
            )}
        </div>
    );
};

export default GPTSelect;
