interface IProps {
    size?: number;
}

export const HomeIcon = (props: IProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size || 32}
        height={props.size || 32}
        viewBox="0 0 32 32"
        fill="none"
    >
        <mask
            id="mask0_3910_108114"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="32"
            height="32"
        >
            <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3910_108114)">
            <path
                d="M7.98112 25.3333H11.9811V17.3333H19.9811V25.3333H23.9811V13.3333L15.9811 7.33333L7.98112 13.3333V25.3333ZM5.31445 28V12L15.9811 4L26.6478 12V28H17.3145V20H14.6478V28H5.31445Z"
                fill="#29319B"
            />
        </g>
    </svg>
);
