import { useContext } from 'react';
import { Constants } from '../constants';
import { AppContext } from '../providers/app-provider';
import {
    ILiveQAFiltersResponse,
    ILiveQAFiltersResponseData,
    RecentSearchesRequest,
    RecentSearchesRequestData,
    RecentSearchesResponse,
    RecentSearchesResponseData,
    Search,
} from './interfaces';
import { NetworkRequestMethod, useNetworkAPI } from './network.service';
import { UtilsService } from './utils.service';
import { PropaneSharp } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';

export const useQueryparamServices = () => {
    const [searchParam, setSearchParams] = useSearchParams();
    const addQueryParams = (key: string, value: string) => {
        const param = searchParam.get(key);

        if (param) {
            searchParam.delete(key);
        }
        searchParam.set(key, value);
        // 👇️ update state after
        setSearchParams(searchParam);
    };

    const removeQueryParams = (key: string) => {
        const param = searchParam.get(key);

        if (param) {
            // 👇️ delete each query param
            searchParam.delete(key);

            // 👇️ update state after
            setSearchParams(searchParam);
        }
    };

    const getQueryParam = (key: string) => {
        const param = searchParam.get(key);

        return param;
    };
    return {
        addQueryParams,
        removeQueryParams,
        getQueryParam,
    };
};
