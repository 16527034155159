import { Box, Typography, Divider, Button } from '@mui/material';
import { Input } from '../../../components/core/input';

interface IProps {
    handleComplete: () => void;
    setClusterName: (name: string) => void;
    clusterName: string | undefined;
    handleClose: () => void;
    setClusterId: (id: string) => void;
}

const CreateWatchlist = (props: IProps) => {
    const handleCreateWatchlist = async () => {
        if (props.clusterName) {
            props.handleComplete();
        }
    };

    const handleCancel = () => {
        props.setClusterName('');
        props.setClusterId('');
        props.handleClose();
    };

    return (
        <Box>
            <Typography variant="h4">Add Topic to your watchlist</Typography>
            <Divider sx={{ my: 4 }} />
            <Typography variant="subtitle2" mb={2}>
                Name your new watchlist and add topic to it
            </Typography>
            <Input
                type="text"
                mt={0}
                value={props.clusterName}
                onChange={(e) => {
                    props.setClusterName(e.target.value);
                }}
                placeholder="Name your watchlist here"
            />

            <Box display="flex" mt={4} justifyContent="right">
                <Button
                    sx={{ color: '#4B4B4C', mr: 3 }}
                    onClick={() => handleCancel()}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => handleCreateWatchlist()}
                    sx={{ minWidth: '120px' }}
                >
                    Create & Add
                </Button>
            </Box>
        </Box>
    );
};

export default CreateWatchlist;
