import Cookies from 'js-cookie';
import { IPlatformInterface } from '../interfaces/platform.interface';
import { IOSPlatformService } from './ios/ios.platform.service';
import { BrowserPlatformService } from './browser.platform.service';

export class PlatformService implements IPlatformInterface {
    platform: IPlatformInterface;
    constructor() {
        //@ts-ignore
        if (window?.webkit?.messageHandlers?.jsHandler) {
            this.platform = new IOSPlatformService();
        } else {
            this.platform = new BrowserPlatformService();
        }
    }
    getCookie = async (key: string) => {
        return this.platform.getCookie(key);
    };
    setCookie = async (key: string, value: string) => {
        await this.platform.setCookie(key, value);
    };
}
