import { Box, Divider, Grid, Typography } from '@mui/material';
import { Avator } from '../../../../../components/icons/avator';
import { Question } from '../../../../../components/icons/question';
import { EventStatements } from '../../../../../services/interfaces';
import { UtilsService } from '../../../../../services/utils.service';

interface IProps {
    data: EventStatements;
    isQuestion?: boolean;
    hideText?: boolean;
    isLast: boolean;
}

export const Statement = (props: IProps) => {
    const scrollToOrder = (orderId: number) => {
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(`order${orderId}`).scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }, 0);
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(`order${orderId}`).style.backgroundColor =
                '#EBECF6';
        }, 900);
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(`order${orderId}`).style.backgroundColor =
                '#fff';
        }, 1500);
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(`order${orderId}`).style.backgroundColor =
                '#EBECF6';
        }, 2000);
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(`order${orderId}`).style.backgroundColor =
                '#fff';
        }, 2900);
    };

    return (
        <Box
            sx={{
                cursor: 'pointer',
            }}
            onClick={() => scrollToOrder(props.data.orderId)}
        >
            <Grid pb={0.5} px={4} display="flex" container>
                <Grid sx={{ width: '20px' }} item>
                    {props.isQuestion ? (
                        <Box width={20} mr={4}>
                            <Question color="#E59413" size={20} />
                        </Box>
                    ) : (
                        <Box pt={1}>
                            <Avator size={18} />
                        </Box>
                    )}
                </Grid>
                <Grid
                    sx={{
                        maxWidth: 'calc(50% - 15px)',
                        borderRight: '1px solid #E3E3E3',
                    }}
                    item
                >
                    <Divider flexItem orientation="vertical" />
                    <Typography
                        color="#000033"
                        ml={1}
                        mr={2}
                        fontSize={16}
                        variant="subtitle1"
                        className="clamp-line-1"
                    >
                        {UtilsService.trimText(props.data.speakerName, 50)}
                    </Typography>
                </Grid>
                <Grid sx={{ maxWidth: 'calc(50% - 15px)' }} item>
                    <Divider flexItem orientation="vertical" />
                    <Typography
                        mx={2}
                        variant="caption"
                        pt={1}
                        color="#8D8D8D"
                        className="clamp-line-1"
                        fontSize={14}
                    >
                        {props.data.speakerRole}
                    </Typography>
                </Grid>
            </Grid>
            {!props.hideText && (
                <Box px={4} py={0.5}>
                    <Typography
                        color="#4B4B4C"
                        variant="caption"
                        pt={1}
                        lineHeight={'20px'}
                        className="clamp-line-2"
                        fontSize={14}
                    >
                        {props.data.rawText}
                    </Typography>
                </Box>
            )}
            <Box pt={4} pb={props.isLast ? 0 : 4}>
                <Divider
                    sx={{
                        borderColor: '#F5F5FA',
                        borderWidth: props.isLast ? 1 : 0.5,
                    }}
                />
            </Box>
        </Box>
    );
};
