interface IProps {
    size?: number;
}

export const ThumbUp = (props: IProps) => (
    <svg
        width={props.size || '20'}
        height={props.size || '20'}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_6925_57119"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="21"
        >
            <rect y="0.5" width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_6925_57119)">
            <path
                d="M6.0083 17.5853V7.58532L10.8561 2.77926C11.0216 2.61366 11.2134 2.51483 11.4314 2.48278C11.6493 2.45072 11.8582 2.48812 12.0579 2.59496C12.2577 2.7018 12.4041 2.8535 12.497 3.05007C12.59 3.24665 12.6098 3.45017 12.5563 3.66063L11.6589 7.58532H17.2422C17.6386 7.58532 17.9888 7.7373 18.2927 8.04125C18.5967 8.3452 18.7487 8.69536 18.7487 9.09173V10.4379C18.7487 10.5253 18.7374 10.619 18.715 10.719C18.6926 10.819 18.6675 10.9122 18.6397 10.9988L16.2515 16.6343C16.1321 16.9008 15.9319 17.1259 15.6509 17.3096C15.3699 17.4934 15.0767 17.5853 14.7714 17.5853H6.0083ZM7.25828 8.11736V16.3353H14.7743C14.8331 16.3353 14.8932 16.3193 14.9546 16.2872C15.016 16.2552 15.0628 16.2017 15.0948 16.1269L17.4987 10.502V9.09173C17.4987 9.01694 17.4746 8.9555 17.4266 8.90742C17.3785 8.85934 17.317 8.8353 17.2422 8.8353H10.0788L11.1237 4.26798L7.25828 8.11736ZM3.58845 17.5853C3.17418 17.5853 2.81955 17.4378 2.52455 17.1428C2.22954 16.8478 2.08203 16.4931 2.08203 16.0789V9.09173C2.08203 8.67747 2.22954 8.32284 2.52455 8.02784C2.81955 7.73282 3.17418 7.58532 3.58845 7.58532H6.0083V8.8353H3.58845C3.51366 8.8353 3.45222 8.85934 3.40414 8.90742C3.35605 8.9555 3.33201 9.01694 3.33201 9.09173V16.0789C3.33201 16.1537 3.35605 16.2151 3.40414 16.2632C3.45222 16.3113 3.51366 16.3353 3.58845 16.3353H6.0083V17.5853H3.58845Z"
                fill="#A5A6AB"
            />
        </g>
    </svg>
);
