import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { CheckedIcon } from '../../icons/checked-icon';
import { UncheckedIcon } from '../../icons/unchecked-icon';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { InputDownArrow } from '../../icons/input-down-arrow';
import { Button, Menu, Typography } from '@mui/material';
import { FilterChecked } from '../../icons/filter-checked';
import { FilterUnchecked } from '../../icons/filter-unchecked';
import { FeatureName, SubFeatureName } from '../../../services/interfaces';
import { Authorize } from '../../authorize';
import { usePermissions } from '../../../providers/permissions-provider';

const ITEM_HEIGHT = 58;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface IDownArrowProps {
    onClick: () => void;
}

export const SelectDownArrow = (props: IDownArrowProps) => {
    return (
        <Box onClick={props.onClick} sx={{ cursor: 'pointer' }} p={2.5} pr={3}>
            <InputDownArrow />
        </Box>
    );
};

interface IProps {
    options: any[];
    authOptions?: {
        featureName: FeatureName;
        subFeatureName: SubFeatureName;
        option: any;
    }[];
    placeholder: string;
    getOptionText: Function;
    getValueText?: Function;
    isOptionDisabled?: (option: any) => boolean;
    getEndIcon?: (option: any) => JSX.Element | undefined;
    onChange: Function;
    allowClearSelection?: boolean;
    selectedValue: any;
    icon?: JSX.Element;
    pt?: number;
    ptContainer?: number;
    pb?: number;
    mr?: number;
    ml?: number;
    width?: number;
    iconMt?: number;
    mt?: number;
    fontSize?: number;
    textMt?: number;
    backgroundColor?: string;
    border?: string;
    borderRadius?: number;
    disableCheckbox?: boolean;
}

export default function SingleSelect(props: IProps) {
    const [open, setOpen] = useState(false);
    const [selectEl, setSelectEl] = useState<null | HTMLElement>(null);
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    const openSelect = Boolean(selectEl);
    const selectContainer = React.useRef();
    const [selectedValues, setSelectedValues] = useState<any>(
        props.selectedValue
    );

    const handleChange = (value?: SelectChangeEvent<typeof selectedValues>) => {
        handleSelectClose();
        setSelectedValues(value);
        props.onChange(value);
    };

    const handleSelectClick = (event: React.MouseEvent<HTMLElement>) => {
        setSelectEl(event.currentTarget);
    };
    const handleSelectClose = () => {
        setSelectEl(null);
    };

    useEffect(() => {
        setSelectedValues(props.selectedValue);
        props.getOptionText(props.selectedValue);
    }, [props.selectedValue]);

    return (
        <div>
            <Box
                ml={props.ml}
                mr={props.mr}
                pt={
                    props.ptContainer == undefined
                        ? props.pt == undefined
                            ? 2
                            : props.pt
                        : props.ptContainer
                }
            >
                <Box
                    ref={selectContainer}
                    sx={{
                        border: props.border || '1px solid #E3E3E3',
                        borderRadius: props.borderRadius || 2,
                        backgroundColor: props.backgroundColor,
                        px: 2,
                        width: props.width,
                        pt: props.pt == undefined ? 3 : props.pt,
                        pb:
                            props.pb !== undefined
                                ? props.pb
                                : props.icon
                                ? 1
                                : 3,
                        mt: props.mt != undefined ? props.mt : 1,
                        cursor: 'pointer',
                    }}
                    onClick={handleSelectClick}
                    display="flex"
                    justifyContent="space-between"
                >
                    <Box height={24} pt={0.5}>
                        {props.icon}
                    </Box>
                    <Box>
                        <Typography
                            aria-label="more"
                            id="summary-button"
                            variant="subtitle2"
                            aria-controls={openSelect ? 'long-menu' : undefined}
                            aria-expanded={openSelect ? 'true' : undefined}
                            aria-haspopup="true"
                            sx={{
                                pl: 2,
                                mt: props.textMt || 1.5,
                                fontWeight: 400,
                                fontSize: props.fontSize || 16,
                                textAlign: 'center',
                            }}
                        >
                            {props.getValueText
                                ? props.getValueText(props.selectedValue)
                                : props.getOptionText(props.selectedValue)}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            lineHeight: '20px',
                        }}
                        component="span"
                        ml={2}
                        pt={0.5}
                        mr={2}
                        mt={props.iconMt}
                    >
                        <InputDownArrow
                            style={{
                                transform: `rotate(${openSelect ? 180 : 0}deg)`,
                            }}
                        />
                    </Box>
                </Box>

                <Menu
                    id="summary-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                        py: 0,
                    }}
                    anchorEl={selectEl}
                    open={openSelect}
                    onClose={handleSelectClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width:
                                Math.max(
                                    //@ts-ignore
                                    selectContainer.current?.offsetWidth,
                                    180
                                ) || '28ch',
                            marginTop: '10px',
                            padding: ' 0px 8px',
                        },
                    }}
                >
                    {props.options.map((option) => (
                        <MenuItem
                            onClick={() => handleChange(option)}
                            key={option.categoryId}
                            value={option}
                            sx={{
                                backgroundColor:
                                    option == props.selectedValue
                                        ? '#ebebf6'
                                        : '#fff',
                                borderRadius: 1,
                            }}
                            disabled={
                                props.isOptionDisabled
                                    ? props.isOptionDisabled(option)
                                    : false
                            }
                        >
                            {!props.disableCheckbox && (
                                <Checkbox
                                    checkedIcon={FilterChecked}
                                    icon={FilterUnchecked}
                                    checked={
                                        props.getOptionText(selectedValues) ==
                                        props.getOptionText(option)
                                    }
                                />
                            )}
                            <ListItemText
                                primary={
                                    props.getOptionText(option)
                                        ? props.getOptionText(option)
                                        : props.placeholder + ' '
                                }
                            />
                            {props.getEndIcon && props.getEndIcon(option)}
                        </MenuItem>
                    ))}
                    {props.authOptions?.map((o) => (
                        <Authorize
                            featureName={o.featureName}
                            subFeatureName={o.subFeatureName}
                        >
                            <MenuItem
                                onClick={() => handleChange(o.option)}
                                key={o.option.categoryId}
                                value={o.option}
                                sx={{
                                    backgroundColor:
                                        o.option == props.selectedValue
                                            ? '#ebebf6'
                                            : '#fff',
                                    borderRadius: 1,
                                }}
                                disabled={
                                    isSubFeatureAuthorized(
                                        o.featureName,
                                        o.subFeatureName
                                    )
                                        ? props.isOptionDisabled
                                            ? props.isOptionDisabled(o.option)
                                            : false
                                        : true
                                }
                            >
                                {!props.disableCheckbox && (
                                    <Checkbox
                                        checkedIcon={FilterChecked}
                                        icon={FilterUnchecked}
                                        disabled={
                                            isSubFeatureAuthorized(
                                                o.featureName,
                                                o.subFeatureName
                                            )
                                                ? false
                                                : true
                                        }
                                        checked={
                                            props.getOptionText(
                                                selectedValues
                                            ) == props.getOptionText(o.option)
                                        }
                                    />
                                )}
                                <ListItemText
                                    primary={
                                        props.getOptionText(o.option)
                                            ? props.getOptionText(o.option)
                                            : props.placeholder + ' '
                                    }
                                    primaryTypographyProps={{
                                        sx: {
                                            color: isSubFeatureAuthorized(
                                                o.featureName,
                                                o.subFeatureName
                                            )
                                                ? undefined
                                                : '#4a4b4c',
                                        },
                                    }}
                                />
                                {props.getEndIcon && props.getEndIcon(o.option)}
                            </MenuItem>
                        </Authorize>
                    ))}
                    {props.allowClearSelection && (
                        <MenuItem
                            onClick={() => handleChange(undefined)}
                            key={'clear'}
                            color="#DA1E28"
                            sx={{ color: '#DA1E28' }}
                            value={undefined}
                        >
                            Clear Selection
                        </MenuItem>
                    )}
                </Menu>
            </Box>
        </div>
    );
}
