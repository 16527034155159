interface IReportSections {
    id: number;
    tab: string;
    label: string;
    order: number;
    configType: 'PREDEFINED' | 'CUSTOM';
}

export const REPORT_SECTIONS: IReportSections[] = [
    {
        id: 0,
        tab: 'WHITE_LABELING',
        label: 'White label',
        order: 0,
        configType: 'PREDEFINED',
    },
    {
        id: 1,
        tab: 'RECENT_HAPPENINGS',
        label: 'Recent Information',
        order: 1,
        configType: 'PREDEFINED',
    },
    {
        id: 2,
        tab: 'RECENT_HEADLINES',
        label: 'Recent headlines',
        order: 2,
        configType: 'PREDEFINED',
    },
    {
        id: 3,
        tab: 'OVERVIEW',
        label: 'Overview',
        order: 3,
        configType: 'PREDEFINED',
    },
    {
        id: 4,
        tab: 'MANAGEMENT',
        label: 'Management team',
        order: 4,
        configType: 'PREDEFINED',
    },
    {
        id: 5,
        tab: 'PRODUCT_OFFERINGS',
        label: 'Product offerings',
        order: 5,
        configType: 'PREDEFINED',
    },
    {
        id: 6,
        tab: 'CUSTOMERS',
        label: 'Customers',
        order: 6,
        configType: 'PREDEFINED',
    },
    {
        id: 7,
        tab: 'SWOT',
        label: 'SWOT Analysis',
        order: 7,
        configType: 'PREDEFINED',
    },
    {
        id: 8,
        tab: 'COMPETITORS',
        label: 'Competitors',
        order: 8,
        configType: 'PREDEFINED',
    },
    {
        id: 9,
        tab: 'BUSINESS_MODEL',
        label: 'Business model/strategy',
        order: 9,
        configType: 'PREDEFINED',
    },
    {
        id: 10,
        tab: 'SENTIMENT_ANALYSIS',
        label: 'Sentiment analysis',
        order: 10,
        configType: 'PREDEFINED',
    },
    {
        id: 11,
        tab: 'FINANCIALS',
        label: 'Financials',
        order: 11,
        configType: 'PREDEFINED',
    },
    {
        id: 12,
        tab: 'RISK',
        label: 'ESG Analysis',
        order: 12,
        configType: 'PREDEFINED',
    },
    {
        id: 13,
        tab: 'CUSTOM_REQUEST',
        label: 'Fundamental Analysis',
        order: 13,
        configType: 'CUSTOM',
    },
    {
        id: 14,
        tab: 'CUSTOM_REQUEST',
        label: 'Custom Analysis',
        order: 14,
        configType: 'CUSTOM',
    },
];
