import {
    Box,
    Checkbox,
    CircularProgress,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
    tableCellClasses,
} from '@mui/material';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { UtilsService } from '../../../services/utils.service';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { Eye } from '../../../components/icons/eye';
import { Input } from '../../../components/core/input';
import { SearchInsights } from '../../../components/icons/search-insights';
import { useDiligenceService } from '../../../services/diligence.service';
import { IDiligenceReportEntity } from '../../../services/interfaces';
import { Link } from 'react-router-dom';
import { Delete } from '../../../components/icons/delete';
import { useSnackbar } from 'notistack';
import { DownloadIcon } from '../../../components/icons/download';
import { NavigationLink } from '../../../components/core/link';
import { Authorize } from '../../../components/authorize';
import { FeatureName, SubFeatureName } from '../../../services/interfaces';
import { usePermissions } from '../../../providers/permissions-provider';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        padding: '8px 24px',
        borderBottom: 'none',
        textAlign: 'left',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 'none',
        padding: '8px 24px',
        textAlign: 'left',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#F9F9FF',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface IProps {
    reports: IDiligenceReportEntity[];
    refreshReports: () => Promise<void>;
}

interface IDownloadReportButtonProps {
    id: string;
}

export const DownloadReportButton = (props: IDownloadReportButtonProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { downloadReport } = useDiligenceService();
    const [isDownloading, setIsDownloading] = useState(false);
    const onDownloadClick = async (format: string) => {
        handleClose();
        setIsDownloading(true);
        await downloadReport(props.id, format);
        setIsDownloading(false);
    };

    return (
        <Box>
            <Tooltip arrow placement="top" title="Download Report">
                <IconButton
                    sx={{ p: 0 }}
                    disabled={isDownloading}
                    onClick={handleClick}
                    color="primary"
                >
                    {isDownloading ? (
                        <CircularProgress sx={{ color: '#ccc' }} size={20} />
                    ) : (
                        DownloadIcon
                    )}
                </IconButton>
            </Tooltip>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Authorize
                    featureName={FeatureName.Diligence}
                    subFeatureName={SubFeatureName.EditableReport}
                >
                    <MenuItem
                        disabled={
                            !isSubFeatureAuthorized(
                                FeatureName.Diligence,
                                SubFeatureName.EditableReport
                            )
                        }
                        onClick={() => onDownloadClick('DOC')}
                    >
                        As Doc
                    </MenuItem>
                </Authorize>
                <MenuItem onClick={() => onDownloadClick('ODF')}>
                    As PDF
                </MenuItem>
            </Menu>
        </Box>
    );
};

interface IDeleteReportButton extends IDownloadReportButtonProps {
    refreshReports: () => Promise<void>;
}

export const DeleteReportButton = (props: IDeleteReportButton) => {
    const { enqueueSnackbar } = useSnackbar();
    const { deleteReport } = useDiligenceService();
    const [isDeleting, setIsDeleting] = useState(false);
    const onDeleteClick = async () => {
        setIsDeleting(true);
        let res = await deleteReport(props.id);
        setIsDeleting(false);
        if (res) {
            await props.refreshReports();
            enqueueSnackbar('Report deleted successfully', {
                variant: 'success',
            });
        } else {
            enqueueSnackbar('Report deletion failed', {
                variant: 'error',
            });
        }
    };

    return (
        <Box>
            <Tooltip arrow placement="top" title="Delete Report">
                <IconButton
                    sx={{ p: 0 }}
                    disabled={isDeleting}
                    onClick={onDeleteClick}
                    color="primary"
                >
                    {isDeleting ? (
                        <CircularProgress sx={{ color: '#ccc' }} size={20} />
                    ) : (
                        <Delete color="red" />
                    )}
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export const Reports = (props: IProps) => {
    const [searchValue, setSearchValue] = useState('');
    const { reports } = props;

    const [selected, setSelected] = useState<string[]>([]);

    return (
        <Box>
            <Box py={2} px={6} maxWidth={400}>
                <Input
                    mt={0}
                    pt={0}
                    pb={0}
                    sx={{
                        pl: '4px !important',
                    }}
                    value={searchValue}
                    backgroundColor="#fff"
                    type="text"
                    noShadow
                    startAdornment={
                        <InputAdornment position="end">
                            <SearchInsights
                                height={15}
                                width={15}
                                fillColor="#A5A6AB"
                            />
                        </InputAdornment>
                    }
                    onChange={(e) => {
                        setSearchValue(e.target.value.slice(0, 100));
                    }}
                    placeholder="Search by company name"
                />
            </Box>
            <TableContainer
                sx={{
                    maxHeight: 'calc(100vh - 520px)',
                    borderTop: '1px solid #D7D9EC',
                }}
            >
                <Table
                    stickyHeader
                    sx={{ minWidth: 700 }}
                    aria-label="customized table"
                >
                    <TableHead>
                        <TableRow>
                            {/* <StyledTableCell
                                sx={{ textAlign: 'left !important', width: 20 }}
                            >
                                <Checkbox
                                    color="primary"
                                    size="small"
                                    indeterminate={
                                        selected.length > 0 &&
                                        selected.length < reports.length
                                    }
                                    checked={
                                        selected.length > 0 &&
                                        selected.length === reports.length
                                    }
                                    onChange={() => {
                                        if (
                                            selected.length === reports.length
                                        ) {
                                            setSelected([]);
                                        } else {
                                            setSelected(
                                                reports.map((r) => r.id)
                                            );
                                        }
                                    }}
                                    inputProps={{
                                        'aria-label': 'select all desserts',
                                    }}
                                />
                            </StyledTableCell> */}
                            <StyledTableCell
                                sx={{ textAlign: 'left !important' }}
                            >
                                Name of the company
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                Date & Time (UTC)
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                Status
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                Action
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reports
                            .filter((t) =>
                                t.companyTitle
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase())
                            )
                            .map((row: IDiligenceReportEntity, index) => (
                                <StyledTableRow key={index}>
                                    {/* <StyledTableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                            textAlign: 'left !important',
                                        }}
                                    >
                                        <Checkbox
                                            size="small"
                                            onChange={() => {
                                                if (selected.includes(row.id)) {
                                                    setSelected(
                                                        selected.filter(
                                                            (id) =>
                                                                id !== row.id
                                                        )
                                                    );
                                                } else {
                                                    setSelected([
                                                        ...selected,
                                                        row.id,
                                                    ]);
                                                }
                                            }}
                                            color="primary"
                                            checked={selected.includes(row.id)}
                                            inputProps={{
                                                'aria-labelledby': row.id,
                                            }}
                                        />
                                    </StyledTableCell> */}
                                    <StyledTableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                            textAlign: 'left !important',
                                        }}
                                    >
                                        <Typography
                                            component={
                                                row.status == 'SUCCESS'
                                                    ? NavigationLink
                                                    : 'p'
                                            }
                                            color="#29319B"
                                            fontSize={14}
                                            sx={{ textDecoration: 'none' }}
                                            to={`/diligence-suite/report/${row.id}`}
                                        >
                                            {row.companyTitle}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {UtilsService.formatUTCStringtoDateTime12H(
                                            row.createdAt
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.status == 'SUCCESS' && (
                                            <Box
                                                sx={{
                                                    backgroundColor:
                                                        'rgba(36, 161, 72, 0.20)',
                                                    color: '#24A148',
                                                    width: 'fit-content',
                                                    px: 2,
                                                    py: 1,
                                                    fontSize: 12,
                                                    fontWeight: 600,
                                                    borderRadius: 1,
                                                }}
                                            >
                                                Completed
                                            </Box>
                                        )}
                                        {row.status == 'IN_PROGRESS' && (
                                            <Box
                                                sx={{
                                                    backgroundColor:
                                                        'rgba(255, 131, 43, 0.30)',
                                                    color: '#CF6100',
                                                    width: 'fit-content',
                                                    px: 2,
                                                    py: 1,
                                                    fontSize: 12,
                                                    fontWeight: 600,
                                                    borderRadius: 1,
                                                }}
                                            >
                                                Processing
                                            </Box>
                                        )}
                                        {row.status == 'FAILED' && (
                                            <Box
                                                sx={{
                                                    backgroundColor:
                                                        'rgba(218, 30, 40, 0.20)',
                                                    color: '#DA1E28',
                                                    width: 'fit-content',
                                                    px: 2,
                                                    py: 1,
                                                    fontSize: 12,
                                                    fontWeight: 600,
                                                    borderRadius: 1,
                                                }}
                                            >
                                                Failed
                                            </Box>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <Box
                                            justifyContent={'space-between'}
                                            display={'flex'}
                                        >
                                            {row.status == 'SUCCESS' && (
                                                <Tooltip
                                                    arrow
                                                    placement="top"
                                                    title="View Report"
                                                >
                                                    <IconButton
                                                        sx={{ p: 0, pt: 1.25 }}
                                                        color="primary"
                                                        component={Link}
                                                        to={`/diligence-suite/report/${row.id}`}
                                                    >
                                                        <Eye />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {row.status == 'SUCCESS' && (
                                                <DownloadReportButton
                                                    id={row.id}
                                                />
                                            )}

                                            <DeleteReportButton
                                                id={row.id}
                                                refreshReports={
                                                    props.refreshReports
                                                }
                                            />

                                            {/* <IconButton
                                            sx={{ p: 0 }}
                                            color="primary"
                                        >
                                            <Reload />
                                        </IconButton>
                                        <IconButton color="error" sx={{ p: 0 }}>
                                            <Delete color="red" />
                                        </IconButton> */}
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
