import {
    Box,
    Tooltip,
    TooltipProps,
    Typography,
    styled,
    tooltipClasses,
} from '@mui/material';
import { useState } from 'react';
import { IMessage } from '../../../services/interfaces';
import { IncomingMesage } from './incoming-message';
import { ArrowBack, ArrowForward, Language } from '@mui/icons-material';
import { ChatMessage, IncomingMessageWrapper, SentMessageWrapper } from '.';
import Lottie from 'lottie-react';
import loadingAnimation from '../../../components/animations/live-processing.json';
import { IncomingMessageIcon } from '../../../components/chat-box/incoming-message-icon';
import { PILogo } from '../../../components/icons/pi-logo';
import { LiveResponse } from '../../../components/live/live-response';

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF',
        color: 'black',
        maxWidth: 400,
        padding: '12px',
        fontSize: 12,
        border: '1px solid #D7D9EC',
        boxShadow: '0px 10px 20px -10px rgba(0, 0, 0, 0.50)',
    },
}));

interface IProps {
    answers: IMessage[];
    handleCopyToClipboard: (ans: IMessage) => void;
    updateMessage: (
        ans: IMessage,
        type: 'bookmark' | 'upvote' | 'downvote' | undefined
    ) => void;
    threadId: string;
    getWebResult: () => void;
    processingId: string | null;
    responseMessage: string | null;
    currentThreadId: string | null;
    isDialog: boolean | undefined;
}

export const DocumentInsightsAnswers = (props: IProps) => {
    const [currentPage, setCurrentPage] = useState<number>(1);

    const changePage = () => {
        setCurrentPage(currentPage == 1 ? 2 : 1);
    };

    return (
        <>
            {props.processingId == props.threadId && !props.responseMessage && (
                <Box display="flex" mt={4}>
                    {props.isDialog && <IncomingMessageIcon />}
                    <SentMessageWrapper>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    height: 40,
                                    width: 40,
                                    display: 'flex',
                                }}
                            >
                                <Lottie
                                    animationData={loadingAnimation}
                                    loop={true}
                                />
                            </Box>
                            <Typography
                                mt={2}
                                ml={1}
                                className="loading"
                                fontSize="14px"
                                fontWeight={600}
                            >
                                {'Exploring knowledge sources'}
                                {'.....'}
                            </Typography>
                        </Box>
                    </SentMessageWrapper>
                </Box>
            )}
            {props.processingId == props.threadId && props.responseMessage && (
                <Box pt={4} pb={0}>
                    <Box display="flex">
                        {props.isDialog && (
                            <Box
                                sx={{
                                    backgroundColor: '#29319B',
                                    height: 28,
                                    width: 28,
                                    paddingTop: 1.25,
                                    paddingLeft: 1.25,
                                    borderRadius: '50%',
                                }}
                            >
                                <PILogo size={18} />
                            </Box>
                        )}

                        <IncomingMessageWrapper>
                            <ChatMessage
                                sx={{
                                    '& .markdown p': {
                                        fontSize: '14px !important',
                                    },
                                    '& .markdown li': {
                                        fontSize: '14px !important',
                                    },
                                    '& .markdown td': {
                                        fontSize: '14px !important',
                                    },
                                    '& .markdown ul': {
                                        paddingLeft: '20px !important',
                                    },
                                }}
                            >
                                <LiveResponse
                                    onCitationClick={() => {}}
                                    text={props.responseMessage}
                                />
                            </ChatMessage>
                        </IncomingMessageWrapper>
                    </Box>
                </Box>
            )}
            {!(props.processingId == props.threadId && props.responseMessage) &&
                props.answers.map((ans, index) => (
                    <Box
                        display={currentPage == index + 1 ? 'block' : 'none'}
                        mt={4}
                    >
                        <Box display="flex">
                            {props.isDialog && <IncomingMessageIcon />}
                            <IncomingMesage
                                message={ans}
                                onCopyToClipBoardClick={() =>
                                    props.handleCopyToClipboard(ans)
                                }
                                onBookmarkClick={() =>
                                    props.updateMessage(ans, 'bookmark')
                                }
                                onUpVoteClick={() =>
                                    props.updateMessage(ans, 'upvote')
                                }
                                onDownVoteClick={() =>
                                    props.updateMessage(ans, 'downvote')
                                }
                            />
                        </Box>
                        {props.answers.length == 1 &&
                            props.currentThreadId == props.threadId && (
                                <Box
                                    sx={{
                                        maxWidth: '95%',
                                        mt: '6px',
                                        ml: 3,
                                    }}
                                    onClick={() => {
                                        props.getWebResult();
                                        setCurrentPage(2);
                                    }}
                                >
                                    <HtmlTooltip
                                        sx={{ padding: 2 }}
                                        placement="bottom"
                                        title={
                                            <Box>
                                                <Typography variant="caption">
                                                    Answer the query based on
                                                    web results
                                                </Typography>
                                            </Box>
                                        }
                                    >
                                        <Typography
                                            variant="caption"
                                            color="#29319B"
                                            sx={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                '&:hover': {
                                                    textDecoration: 'underline',
                                                },
                                            }}
                                        >
                                            Search the web
                                            <Language
                                                sx={{ fontSize: '16px', ml: 1 }}
                                            />
                                        </Typography>
                                    </HtmlTooltip>
                                </Box>
                            )}
                        {props.answers.length == 2 && (
                            <Box
                                sx={{
                                    maxWidth: '95%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mt: '6px',
                                    ml: 2,
                                }}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    onClick={() => changePage()}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {currentPage == 2 && (
                                        <ArrowBack
                                            sx={{
                                                fontSize: '16px',
                                                color: '#29319B',
                                            }}
                                        />
                                    )}
                                    <Typography variant="subtitle2" mx={1}>
                                        {currentPage}/2
                                    </Typography>
                                    {currentPage == 1 && (
                                        <ArrowForward
                                            sx={{
                                                fontSize: '16px',
                                                color: '#29319B',
                                            }}
                                        />
                                    )}
                                </Box>
                                <Typography variant="caption" color="#4B4B4CB2">
                                    {currentPage == 1 ? (
                                        'Result from document'
                                    ) : (
                                        <span
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Result from web search
                                            <Language
                                                sx={{ fontSize: '16px', ml: 1 }}
                                            />
                                        </span>
                                    )}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                ))}
        </>
    );
};
