export const SharePointIcon = (
    <svg
        width="40"
        height="40"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_4659_99801)">
            <path
                d="M17.9019 22.382C24.0826 22.382 29.0929 17.3716 29.0929 11.191C29.0929 5.01038 24.0826 0 17.9019 0C11.7213 0 6.71094 5.01038 6.71094 11.191C6.71094 17.3716 11.7213 22.382 17.9019 22.382Z"
                fill="#036C70"
            />
            <path
                d="M28.3525 30.6269C33.6386 30.6269 37.9237 26.3417 37.9237 21.0556C37.9237 15.7696 33.6386 11.4844 28.3525 11.4844C23.0664 11.4844 18.7812 15.7696 18.7812 21.0556C18.7812 26.3417 23.0664 30.6269 28.3525 30.6269Z"
                fill="#1A9BA1"
            />
            <path
                d="M19.0789 37.6968C23.3077 37.6968 26.7359 34.2687 26.7359 30.0398C26.7359 25.811 23.3077 22.3828 19.0789 22.3828C14.85 22.3828 11.4219 25.811 11.4219 30.0398C11.4219 34.2687 14.85 37.6968 19.0789 37.6968Z"
                fill="#37C6D0"
            />
            <path
                opacity="0.1"
                d="M19.8657 8.24609H7.10321C6.65181 9.90634 6.58905 11.6483 6.9198 13.3367C7.25054 15.0251 7.9659 16.6146 9.01034 17.9819C10.0548 19.3491 11.4002 20.4573 12.9423 21.2204C14.4843 21.9835 16.1814 22.381 17.9019 22.3821C18.2282 22.3821 18.551 22.3668 18.8714 22.3397L18.8773 22.3844C16.8823 22.4373 14.9867 23.2669 13.5943 24.6967C12.202 26.1265 11.4228 28.0434 11.4229 30.0391C11.4229 30.237 11.4304 30.4333 11.4453 30.6281H19.8657C20.2817 30.6268 20.6804 30.461 20.9746 30.1668C21.2689 29.8726 21.4347 29.4739 21.4359 29.0578V9.81637C21.4347 9.40029 21.2689 9.0016 20.9746 8.70739C20.6804 8.41318 20.2817 8.24734 19.8657 8.24609Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M18.6892 9.42188H6.8503C6.59566 11.0207 6.69077 12.6557 7.12907 14.2143C7.56736 15.7728 8.33843 17.2177 9.3891 18.4495C10.4398 19.6812 11.7451 20.6704 13.215 21.349C14.6849 22.0275 16.2845 22.3792 17.9035 22.3799C18.2298 22.3799 18.5526 22.3646 18.873 22.3375L18.8789 22.3822C17.7408 22.4132 16.6239 22.6973 15.6094 23.214C14.5949 23.7307 13.7082 24.467 13.0139 25.3693C12.3197 26.2717 11.8352 27.3173 11.5957 28.4304C11.3562 29.5434 11.3677 30.6959 11.6295 31.8039H18.6892C19.1053 31.8026 19.504 31.6368 19.7982 31.3426C20.0924 31.0484 20.2582 30.6497 20.2595 30.2336V10.9921C20.2582 10.5761 20.0924 10.1774 19.7982 9.88317C19.504 9.58896 19.1053 9.42312 18.6892 9.42188Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M18.6892 9.42188H6.8503C6.59566 11.0207 6.69077 12.6557 7.12907 14.2143C7.56736 15.7728 8.33843 17.2177 9.3891 18.4495C10.4398 19.6812 11.7451 20.6704 13.215 21.349C14.6849 22.0275 16.2845 22.3792 17.9035 22.3799C18.2298 22.3799 18.5526 22.3646 18.873 22.3375L18.8789 22.3822C16.9854 22.432 15.1775 23.1818 13.8046 24.4868C12.4318 25.7918 11.5913 27.5593 11.4457 29.4479H18.6892C19.1053 29.4466 19.504 29.2808 19.7982 28.9866C20.0924 28.6924 20.2582 28.2937 20.2595 27.8776V10.9921C20.2582 10.5761 20.0924 10.1774 19.7982 9.88317C19.504 9.58896 19.1053 9.42312 18.6892 9.42188Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M17.5112 9.42188H6.8503C6.59566 11.0207 6.69077 12.6557 7.12907 14.2143C7.56736 15.7728 8.33843 17.2177 9.3891 18.4495C10.4398 19.6812 11.7451 20.6704 13.215 21.349C14.6849 22.0275 16.2845 22.3792 17.9035 22.3799C18.2298 22.3799 18.5526 22.3646 18.873 22.3375L18.8789 22.3822C16.9854 22.432 15.1775 23.1818 13.8046 24.4868C12.4318 25.7918 11.5913 27.5593 11.4457 29.4479H17.5112C17.9273 29.4466 18.326 29.2808 18.6202 28.9866C18.9144 28.6924 19.0802 28.2937 19.0815 27.8776V10.9921C19.0802 10.5761 18.9144 10.1774 18.6202 9.88317C18.326 9.58896 17.9273 9.42312 17.5112 9.42188Z"
                fill="black"
            />
            <path
                d="M1.80465 9.42188H17.5121C17.9286 9.42188 18.328 9.58731 18.6225 9.8818C18.9169 10.1763 19.0824 10.5757 19.0824 10.9921V26.6996C19.0824 27.1161 18.9169 27.5155 18.6225 27.81C18.328 28.1044 17.9286 28.2699 17.5121 28.2699H1.80465C1.38819 28.2699 0.988781 28.1044 0.694298 27.81C0.399814 27.5155 0.234375 27.1161 0.234375 26.6996L0.234375 10.9921C0.234375 10.5757 0.399814 10.1763 0.694298 9.8818C0.988781 9.58731 1.38819 9.42188 1.80465 9.42188Z"
                fill="#03787C"
            />
            <path
                d="M6.90926 18.6428C6.51242 18.3797 6.18117 18.0291 5.94095 17.618C5.70697 17.1885 5.59035 16.705 5.60286 16.2161C5.58234 15.5545 5.80581 14.9084 6.23073 14.4008C6.67685 13.8924 7.25375 13.516 7.89878 13.3124C8.63402 13.0702 9.40415 12.9508 10.1782 12.959C11.1961 12.9215 12.2125 13.0639 13.1809 13.3795V15.4999C12.7601 15.2449 12.3017 15.0577 11.8227 14.9451C11.3027 14.8172 10.7691 14.7531 10.2336 14.7542C9.66929 14.7338 9.10862 14.8524 8.60087 15.0994C8.40923 15.1822 8.24594 15.3192 8.13106 15.4935C8.01617 15.6678 7.95468 15.8719 7.95415 16.0807C7.95179 16.3392 8.05024 16.5885 8.22862 16.7757C8.44055 16.9952 8.69088 17.1741 8.96723 17.3034C9.27665 17.4574 9.73843 17.6616 10.3526 17.916C10.4208 17.9374 10.4873 17.9637 10.5516 17.9949C11.1575 18.2321 11.7419 18.5206 12.2986 18.8572C12.7206 19.1172 13.0747 19.4737 13.3317 19.8974C13.5965 20.3768 13.7256 20.9192 13.7051 21.4665C13.7353 22.1459 13.5274 22.8146 13.1173 23.3572C12.7081 23.8558 12.1611 24.2228 11.5447 24.4127C10.82 24.6402 10.0636 24.7503 9.30414 24.739C8.62441 24.7417 7.94572 24.6857 7.27562 24.5717C6.70837 24.4817 6.15443 24.3218 5.62642 24.0958V21.8552C6.13159 22.2159 6.69619 22.485 7.29447 22.6504C7.88896 22.836 8.50706 22.9352 9.12979 22.9449C9.70652 22.9816 10.2823 22.8594 10.7943 22.5915C10.969 22.4912 11.1132 22.3454 11.2117 22.1698C11.3102 21.9941 11.3593 21.795 11.3539 21.5937C11.355 21.452 11.3279 21.3115 11.2741 21.1805C11.2203 21.0494 11.1409 20.9304 11.0405 20.8304C10.7874 20.5826 10.4948 20.3787 10.1747 20.2272C9.80636 20.0419 9.26212 19.7984 8.54197 19.4969C7.97069 19.2672 7.42375 18.9811 6.90926 18.6428Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_4659_99801">
                <rect width="38" height="37.696" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
