const {
    REACT_APP_USER_API_HOST,
    REACT_APP_INSIGHT_API_HOST,
    REACT_APP_GATEWAY_API_HOST,
    REACT_APP_CUSTOM_INSIGHT_API_HOST,
    REACT_APP_INTEGRATION_API_HOST,
    REACT_APP_DILIGENCE_API_HOST,
    REACT_APP_PAYMENTS_API_HOST,
    REACT_APP_BILLING_API_HOST,
    REACT_APP_CHATBOT_API_HOST,
    REACT_APP_ROOT_API_HOST,
} = process.env;

const PAGE_SIZE = 50;

export const Constants = {
    //Live API's
    getRecentSearches: `${REACT_APP_CHATBOT_API_HOST}api/v2/live/recent-searches`,
    deleteSearchByQueryId: `${REACT_APP_CHATBOT_API_HOST}api/v2/live/update-searches`,

    //Chat Configuration Api's
    getChatbotFilters: `${REACT_APP_CHATBOT_API_HOST}api/v2/get-chat-configurations`,
    updateChatbotFilters: `${REACT_APP_CHATBOT_API_HOST}api/v2/update-chat-configurations`,
    // Chatbot filter Api's
    topicChatFilters: `${REACT_APP_CHATBOT_API_HOST}api/v1/filters`,

    // Chatbot answer generation Api's
    liveQuery: `${REACT_APP_CHATBOT_API_HOST}api/v2/live/answer`,
    publicLiveQuery: `${REACT_APP_CHATBOT_API_HOST}api/v2/demo/live/answer`,
    // Scraping Api's
    liveTrendsInfo: `${REACT_APP_CHATBOT_API_HOST}api/v1/live/google-trends`,
    liveFinancialsInfo: `${REACT_APP_CHATBOT_API_HOST}api/v1/live/financial-info`,
    liveSentiments: `${REACT_APP_CHATBOT_API_HOST}api/v1/live/sentiments`,
    liveCorporateCulture: `${REACT_APP_CHATBOT_API_HOST}api/v1/live/glassdoor`,
    liveGoogleScholar: `${REACT_APP_CHATBOT_API_HOST}api/v1/live/google-scholar`,
    liveGooglePatents: `${REACT_APP_CHATBOT_API_HOST}api/v1/live/google-patents`,
    liveYoutubeMedia: `${REACT_APP_CHATBOT_API_HOST}api/v1/live/youtube`,
    liveTVCaptions: `${REACT_APP_CHATBOT_API_HOST}api/v1/live/tv-captions`,
    liveChatbotVolumeTrendQuery: `${REACT_APP_CHATBOT_API_HOST}api/v1/live/volume-trends`,
    scrapUrls: `${REACT_APP_CHATBOT_API_HOST}api/v1/live/scrape-urls`,
    liveChatbotQueryRecentHeadlines: `${REACT_APP_CHATBOT_API_HOST}api/v1/live/related-headlines`,
    liveChatbotQueryEntities: `${REACT_APP_CHATBOT_API_HOST}api/v1/live/detect-entities`,
    chatModels: `${REACT_APP_CHATBOT_API_HOST}api/v1/servers`,
    fetchPredefinedQueries: `${REACT_APP_CHATBOT_API_HOST}api/v2/docuinsight/get-predefined-queries`,
    updatePredefinedQueries: `${REACT_APP_CHATBOT_API_HOST}api/v2/docuinsight/update-predefined-queries`,

    getTopicChat: `${REACT_APP_CHATBOT_API_HOST}api/v1/answers`,
    getDocumentChat: `${REACT_APP_CHATBOT_API_HOST}api/v1/answers`,
    updateDocumentChat: `${REACT_APP_CHATBOT_API_HOST}api/v1/answer`,
    updateTopicChat: `${REACT_APP_CHATBOT_API_HOST}api/v1/answer`,
    //Widgets Api's
    getWidgets: `${REACT_APP_CHATBOT_API_HOST}api/v1/widgets`,
    getWidgetSourcesCount: `${REACT_APP_CHATBOT_API_HOST}api/v1/widget/count/sources`,
    generateWidgetId: `${REACT_APP_CHATBOT_API_HOST}api/v1/widget/id/generate`,
    getWidget: `${REACT_APP_CHATBOT_API_HOST}api/v1/widget`,
    getWidgetConversations: `${REACT_APP_CHATBOT_API_HOST}api/v2/widget`,
    getWidgetLeads: `${REACT_APP_CHATBOT_API_HOST}api/v2/widget/get-widget-leads`,

    //Other Api's
    authenticateUser: `${REACT_APP_GATEWAY_API_HOST}authenticate`,
    generateEmailVerification: `${REACT_APP_USER_API_HOST}api/v1/user/generate-email-verification-code`,
    generatePasswordVerification: `${REACT_APP_USER_API_HOST}api/v1/generate-password-verification-code`,
    externalTokenVerification: `${REACT_APP_USER_API_HOST}api/v1/authenticate-external-token`,
    resetForgotPassword: `${REACT_APP_USER_API_HOST}api/v1/reset-forgotten-password`,
    verifyEmailOtpCode: `${REACT_APP_USER_API_HOST}api/v1/user/verify-email-verification-code`,
    verifyPasswordOtpCode: `${REACT_APP_USER_API_HOST}api/v1/verify-password-verification-code`,
    createUser: `${REACT_APP_USER_API_HOST}api/v1/user/create-user`,
    getAccessTokens: `${REACT_APP_USER_API_HOST}api/v1/user/long-term-access-tokens`,
    deleteAccessTokens: `${REACT_APP_USER_API_HOST}api/v1/user/long-term-access-token`,
    generateAccessTokens: `${REACT_APP_USER_API_HOST}api/v1/user/generate-long-term-access-token`,
    updateAccessTokens: `${REACT_APP_USER_API_HOST}api/v1/user/edit-long-term-access-token`,
    resetPassword: `${REACT_APP_USER_API_HOST}api/v1/user/reset-password`,
    userPermission: `${REACT_APP_USER_API_HOST}api/v1/user/permissions`,
    generateAccessToken: `${REACT_APP_GATEWAY_API_HOST}refresh`,
    getUserCluster: `${REACT_APP_INSIGHT_API_HOST}api/v1/dashboard/clusters`,
    upsertUserCluster: `${REACT_APP_INSIGHT_API_HOST}api/v1/dashboard/cluster`,
    clusterExecutiveSummary: `${REACT_APP_INSIGHT_API_HOST}api/v1/dashboard/cluster`,
    deleteCluster: `${REACT_APP_INSIGHT_API_HOST}api/v1/dashboard/cluster`,
    getPredefinedClusters: `${REACT_APP_INSIGHT_API_HOST}api/v1/dashboard/predefined-clusters`,
    clusterAlertsSettings: `${REACT_APP_INSIGHT_API_HOST}api/v1/dashboard/alerts`,
    getPredefinedTopics: `${REACT_APP_INSIGHT_API_HOST}api/v1/dashboard/topics`,
    getUserTopics: `${REACT_APP_INSIGHT_API_HOST}api/v1/dashboard/user-topics`,
    subscribeTopic: `${REACT_APP_INSIGHT_API_HOST}api/v1/dashboard/add-topic-subscription`,
    getUserClusterSentiments: `${REACT_APP_INSIGHT_API_HOST}api/v2/sentiments`,
    getLatestSentiments: `${REACT_APP_INSIGHT_API_HOST}api/v1/latest-sentiment`,
    getTopicStockPrice: `${REACT_APP_INSIGHT_API_HOST}api/v2/stock-information`,
    getHighlightedTopics: `${REACT_APP_INSIGHT_API_HOST}api/v2/highlighted-topics`,
    getPotentialIndicators: `${REACT_APP_INSIGHT_API_HOST}api/v2/potential-indicators?perPage=${PAGE_SIZE}&page=`,
    getFinanciaPlotentialIndicators: `${REACT_APP_INSIGHT_API_HOST}api/v1/financials/dashboard/potential-indicators?perPage=${PAGE_SIZE}&page=`,
    getAllPotentialIndicators: `${REACT_APP_INSIGHT_API_HOST}api/v1/potential-indicators?fetch=all`,
    getLatestInsights: `${REACT_APP_INSIGHT_API_HOST}api/v2/latest-insights?perPage=${PAGE_SIZE}&page=`,
    getDetailedInsights: `${REACT_APP_INSIGHT_API_HOST}api/v2/detailed-information?perPage=${PAGE_SIZE}&page=`,
    getAllDetailedInsights: `${REACT_APP_INSIGHT_API_HOST}api/v2/detailed-information?fetch=all`,
    getArticleDetails: `${REACT_APP_INSIGHT_API_HOST}api/v1/id-article-details?perPage=${PAGE_SIZE}&page=`,
    getArticlsPotentialIndicators: `${REACT_APP_INSIGHT_API_HOST}api/v1/id-article-potential-indicator?perPage=${PAGE_SIZE}&page=`,
    getSourceDistributionPotentialIndicators: `${REACT_APP_INSIGHT_API_HOST}api/v2/source-distribution-potential-indicators?perPage=${PAGE_SIZE}&page=`,
    getTopicArticleDetails: `${REACT_APP_INSIGHT_API_HOST}api/v1/article-details?perPage=${PAGE_SIZE}&page=`,
    getTopicSentimentAssesment: `${REACT_APP_INSIGHT_API_HOST}api/v2/sentiment-assesment`,
    getTopicSourceDistribution: `${REACT_APP_INSIGHT_API_HOST}api/v2/source-distribution`,
    getTopicTrends: `${REACT_APP_INSIGHT_API_HOST}api/v1/recent-trends`,

    getTopicSourceArticleDetails: `${REACT_APP_INSIGHT_API_HOST}api/v2/source-distribution-article-details?perPage=${PAGE_SIZE}&page=`,
    getInsightDetails: `${REACT_APP_INSIGHT_API_HOST}api/v1/fetch-insight-details`,
    getS3FileUploadLink: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/s3-file-link`,
    pushDocument: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/file`,
    getFilesList: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v2/files`,
    getFilesCount: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v2/file-count`,
    getSharedFilesList: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/shared/files`,
    getFilesFiltersList: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/user-file-filters`,
    getFile: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/files`,
    getDownloadLink: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/file`,
    uploadUrl: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/url-upload`,
    fetchQuerySources: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/chatbot-response`,
    submitFile: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/files/mark-in-progress`,
    getFileGroups: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/groups`,
    uploadDocument: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/upload-document`,
    upsertGroup: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/group`,
    customUploadQuery: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/chatgpt-response`,
    uploadFile: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/upload-document`,
    topicArticleQuery: `${REACT_APP_INSIGHT_API_HOST}api/v2/chatbot-response`,

    // topicChatFilters: `${REACT_APP_INSIGHT_API_HOST}api/v1/chatbot/filters`,
    topicQuery: `${REACT_APP_INSIGHT_API_HOST}api/v1/chatbot/answer`,
    liveInsightQuery: `${REACT_APP_INSIGHT_API_HOST}api/v1/live/source-answer`,
    // documentQuery: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/chatbot/answer`,
    documentSearchSuggestions: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/internal/elasticsearch/document-search-suggestions`,
    documentQueryResults: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/internal/elasticsearch/documents-for-query`,
    documentQueryMatchesResults: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/internal/elasticsearch/search-within-document`,
    topicCompareQuery: `${REACT_APP_INSIGHT_API_HOST}api/v2/chatgpt-response`,
    topicCompareArticles: `${REACT_APP_INSIGHT_API_HOST}api/v2/chatbot-response`,
    updateDocumentAspects: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/file`,
    updateDocumentTags: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/tags`,
    updateMultipleDocumentTags: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v2/tags`,
    updateFileName: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v2/file`,
    deleteDocument: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/file`,
    shareDocument: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/shared/file`,
    deleteFileGroup: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/group`,
    getKeyInsights: `${REACT_APP_INSIGHT_API_HOST}api/v1/financials/key-insights`,
    getFinancialSummaries: `${REACT_APP_INSIGHT_API_HOST}api/v1/financials/summary`,
    getFinancialFilters: `${REACT_APP_INSIGHT_API_HOST}api/v1/financials/filters`,

    getWidgetConversationsStats: `${REACT_APP_CHATBOT_API_HOST}api/v1/widget/count/`,
    getWidgetImageUploadLink: `${REACT_APP_CHATBOT_API_HOST}/api/v1/widget/s3/upload-link`,

    getFinancialSummariesEvents: `${REACT_APP_INSIGHT_API_HOST}api/v1/financials/events`,
    getPersonalityTraits: `${REACT_APP_INSIGHT_API_HOST}api/v1/financials/personality-detection`,
    getPriceChart: `${REACT_APP_INSIGHT_API_HOST}api/v1/financials/dashboard/price-chart`,
    getExecutiveSummary: `${REACT_APP_INSIGHT_API_HOST}api/v1/financials/dashboard/executive-summary`,
    getRecentHeadlined: `${REACT_APP_INSIGHT_API_HOST}api/v1/financials/dashboard/recent-headlines`,
    getSentimentChart: `${REACT_APP_INSIGHT_API_HOST}api/v1/financials/dashboard/sentiments`,
    getFinancialtrends: `${REACT_APP_INSIGHT_API_HOST}api/v1/financials/dashboard/financial-trends`,
    getFinancialKeymatrix: `${REACT_APP_INSIGHT_API_HOST}api/v1/financials/dashboard/key-metrics`,
    getFinancialOwnership: `${REACT_APP_INSIGHT_API_HOST}api/v1/financials/dashboard/institution-ownership`,
    getFinancialInsiderTrading: `${REACT_APP_INSIGHT_API_HOST}api/v1/financials/dashboard/insider-trading`,
    getFinancialAllFilings: `${REACT_APP_INSIGHT_API_HOST}api/v1/financials/dashboard/all-filings`,

    integrationsList: `${REACT_APP_INTEGRATION_API_HOST}drive/list-integrations`,
    integrationAccounts: `${REACT_APP_INTEGRATION_API_HOST}drive/integration_accounts`,
    integrationsLoginUrl: `${REACT_APP_INTEGRATION_API_HOST}drive/`,
    integrationsFiles: `${REACT_APP_INTEGRATION_API_HOST}drive/`,
    integrationSourceFiles: `${REACT_APP_CUSTOM_INSIGHT_API_HOST}api/v2/files-for-integration?perPage=${PAGE_SIZE}&page=`,

    diliginceTemplatesList: `${REACT_APP_DILIGENCE_API_HOST}api/v1/fetch-configurations`,
    diliginceTemplatesDefaultConfiguration: `${REACT_APP_DILIGENCE_API_HOST}api/v1/fetch-default-configuration`,
    diliginceTemplatesConfiguration: `${REACT_APP_DILIGENCE_API_HOST}api/v1/fetch-configuration`,
    updateDiliginceTemplateConfiguration: `${REACT_APP_DILIGENCE_API_HOST}api/v1/update-configuration`,
    deleteDiliginceTemplateConfiguration: `${REACT_APP_DILIGENCE_API_HOST}api/v1/delete-configuration`,
    fetchDiligenceReport: `${REACT_APP_DILIGENCE_API_HOST}api/v1/fetch-report`,
    deleteDiligenceReport: `${REACT_APP_DILIGENCE_API_HOST}api/v1/delete-report`,
    diligenceWhiteLabelImageLink: `${REACT_APP_DILIGENCE_API_HOST}api/v1/get-whitelabelling-s3-link`,
    createDiligenceReport: `${REACT_APP_DILIGENCE_API_HOST}api/v1/generate-report`,
    downloadDiligenceReport: `${REACT_APP_DILIGENCE_API_HOST}/api/v1/fetch-pdf-access-link`,
    fetchUserDiligenceReport: `${REACT_APP_DILIGENCE_API_HOST}api/v1/fetch-user-reports`,
    upsertUserDomainList: `${REACT_APP_USER_API_HOST}api/v1/user/update-user-domain`,
    fetchUserDomainList: `${REACT_APP_USER_API_HOST}api/v1/user/fetch-trusted-domains`,
    deleteUserDomainList: `${REACT_APP_USER_API_HOST}api/v1/user/delete-user-domain`,

    usageOverview: `${REACT_APP_USER_API_HOST}api/v1/quota/company/usage-overview`,
    usageHistory: `${REACT_APP_USER_API_HOST}api/v1/quota/company/historical-analysis`,
    updateThreshold: `${REACT_APP_USER_API_HOST}api/v1/quota/company/update-threshold`,
    usersUsage: `${REACT_APP_USER_API_HOST}api/v1/quota/company/user-data-usage`,

    activeSubscription: `${REACT_APP_BILLING_API_HOST}fetch-subscription-details`,
    addOnTypes: `${REACT_APP_BILLING_API_HOST}fetch-add-on-types`,
    paymentDetails: `${REACT_APP_BILLING_API_HOST}fetch-payment-details`,
    addPaymentDetails: `${REACT_APP_PAYMENTS_API_HOST}collect-payment-details`,
    buySubscription: `${REACT_APP_PAYMENTS_API_HOST}buy-subscription`,
    modifySubscription: `${REACT_APP_ROOT_API_HOST}subscription/modify-subscription-plan`,
    buyProducts: `${REACT_APP_PAYMENTS_API_HOST}buy-products`,
    setDefaultPaymentMethod: `${REACT_APP_PAYMENTS_API_HOST}default-payment-method`,
    deletePaymentMethod: `${REACT_APP_PAYMENTS_API_HOST}payment-details`,
    fetchInvoices: `${REACT_APP_ROOT_API_HOST}invoicing/fetch-invoices`,
    downloadInvoice: `${REACT_APP_ROOT_API_HOST}invoicing/download-invoice?invoice_id=`,
    fetchProratedAmount: `${REACT_APP_ROOT_API_HOST}invoicing/get-prorated-amount`,
    subscriptionPlans: `${REACT_APP_BILLING_API_HOST}fetch-subscription-types`,
};
