import { useEffect, useState } from 'react';
import { BorderedBox } from '../../../../../components/core/box';
import { Box, Typography } from '@mui/material';
import SingleSelectTab from '../../../../../components/core/input/single-select-tab';
import { useFinancialsService } from '../../../../../services/financials.service';
import {
    BarChart,
    PlotProps,
} from '../../../../../components/charts/bar-chart';
import { useParams } from 'react-router-dom';
import {
    FinancialTrends,
    FinancialTrendsData,
} from '../../../live-insights/financial-info-interface';

interface IProps {
    data?: FinancialTrends;
}

export const Financialtrends = (props: IProps) => {
    let { topicSubscriptionId } = useParams();
    const [data, setData] = useState<PlotProps | null>(null);
    const [selectedRange, setSelectedRange] = useState('ANNUAL');
    const [financials, setFinancials] = useState<FinancialTrends | null>(null);
    const { fetchFinancialtrends } = useFinancialsService();

    useEffect(() => {
        refreshSentimentData();
    }, [topicSubscriptionId, props.data]);

    useEffect(() => {
        renderPlot();
    }, [financials, selectedRange]);

    const renderPlot = () => {
        if (!financials) return;
        const revenueSeries = financials[
            selectedRange == 'QUARTER' ? 'quarterly' : 'yearly'
        ].map((d: FinancialTrendsData) => d.revenue);
        const earningsSeries = financials[
            selectedRange == 'QUARTER' ? 'quarterly' : 'yearly'
        ].map((d: FinancialTrendsData) => d.earnings);
        const labels = financials[
            selectedRange == 'QUARTER' ? 'quarterly' : 'yearly'
        ].map((d: FinancialTrendsData) => d.date.toString());

        let sentimentData: PlotProps = {
            series: [
                {
                    name: 'Revenue',
                    data: revenueSeries,
                },
                {
                    name: 'Earnings',
                    data: earningsSeries,
                },
            ],
            labels: labels,
        };
        setData({ ...sentimentData });
    };

    const refreshSentimentData = async () => {
        if (!topicSubscriptionId && !props.data) return;
        if (props.data) {
            setFinancials(props.data);
        } else if (topicSubscriptionId) {
            let data = await fetchFinancialtrends(topicSubscriptionId);
            setFinancials(data.financialTrends);
        }
    };
    return (
        <BorderedBox
            sx={{ backgroundColor: '#FFFFFF', height: '430px' }}
            pt={3}
        >
            <Box>
                <Box px={4} display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="h6">Financial Trends</Typography>
                    <SingleSelectTab
                        getOptionText={(v: string) => v}
                        placeholder="Select source"
                        pb={3}
                        options={['ANNUAL', 'QUARTER']}
                        selectedValue={selectedRange}
                        onChange={setSelectedRange}
                    />
                </Box>
                {data && (
                    <Box pt={7}>
                        <BarChart showLinesOnly={true} {...data} />
                    </Box>
                )}
            </Box>
        </BorderedBox>
    );
};
