interface IProps {
    color: string;
}

export const LockIcon = (props: IProps) => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_3910_128565"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="32"
            height="32"
        >
            <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3910_128565)">
            <path
                d="M9.90476 25.5C9.38095 25.5 8.93254 25.3135 8.55952 24.9405C8.18651 24.5675 8 24.119 8 23.5952V14.0714C8 13.5476 8.18651 13.0992 8.55952 12.7262C8.93254 12.3532 9.38095 12.1667 9.90476 12.1667H10.8571V10.2619C10.8571 8.94444 11.3214 7.82143 12.25 6.89286C13.1786 5.96429 14.3016 5.5 15.619 5.5C16.9365 5.5 18.0595 5.96429 18.9881 6.89286C19.9167 7.82143 20.381 8.94444 20.381 10.2619V12.1667H21.3333C21.8571 12.1667 22.3056 12.3532 22.6786 12.7262C23.0516 13.0992 23.2381 13.5476 23.2381 14.0714V23.5952C23.2381 24.119 23.0516 24.5675 22.6786 24.9405C22.3056 25.3135 21.8571 25.5 21.3333 25.5H9.90476ZM9.90476 23.5952H21.3333V14.0714H9.90476V23.5952ZM15.619 20.7381C16.1429 20.7381 16.5913 20.5516 16.9643 20.1786C17.3373 19.8056 17.5238 19.3571 17.5238 18.8333C17.5238 18.3095 17.3373 17.8611 16.9643 17.4881C16.5913 17.1151 16.1429 16.9286 15.619 16.9286C15.0952 16.9286 14.6468 17.1151 14.2738 17.4881C13.9008 17.8611 13.7143 18.3095 13.7143 18.8333C13.7143 19.3571 13.9008 19.8056 14.2738 20.1786C14.6468 20.5516 15.0952 20.7381 15.619 20.7381ZM12.7619 12.1667H18.4762V10.2619C18.4762 9.46825 18.1984 8.79365 17.6429 8.2381C17.0873 7.68254 16.4127 7.40476 15.619 7.40476C14.8254 7.40476 14.1508 7.68254 13.5952 8.2381C13.0397 8.79365 12.7619 9.46825 12.7619 10.2619V12.1667Z"
                fill={props.color}
            />
        </g>
    </svg>
);
