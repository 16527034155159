import { Box, Button, IconButton, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Close } from '../../../components/icons/close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    FileFilters,
    IDocumentMatchedResponseFile,
    IDocumentMatchesRequest,
    IProximityFilters,
} from '../../../services/interfaces';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDocumentService } from '../../../services/document.service';
import { NavigationLink } from '../../../components/core/link';

export const FileMatches = () => {
    const { fileId } = useParams();
    const [activeMatch, setActiveMatch] = useState(1);
    const [searchParam] = useSearchParams();
    const navigate = useNavigate();
    const { fetchDocumentMatches } = useDocumentService();
    const [file, setFile] = useState<IDocumentMatchedResponseFile | null>(null);

    useEffect(() => {
        refreshFileMatches();
    }, [fileId, searchParam]);

    const refreshFileMatches = async () => {
        if (!fileId) return;
        setFile(null);
        let proximity = searchParam.get('proximityFilters');
        let filters = searchParam.get('filters');
        let query = searchParam.get('query');
        if (query) {
            let documentMatchesRequest: IDocumentMatchesRequest = {
                userQuery: query,
                fuzziness: 1,
                filters: {
                    userFileId: fileId,
                },
                proximity: 10,
                searchableField: 'EVERYWHERE',
            };
            if (proximity) {
                let proximityFilters: IProximityFilters = JSON.parse(proximity);
                documentMatchesRequest.fuzziness = proximityFilters.fuzziness;
                documentMatchesRequest.proximity = proximityFilters.proximity;
                documentMatchesRequest.searchableField =
                    proximityFilters.searchableFields;
            }
            if (filters) {
                let fileFilters: FileFilters = JSON.parse(filters);
                documentMatchesRequest.filters.includeSharedFiles =
                    fileFilters.includeSharedFiles == undefined
                        ? true
                        : fileFilters.includeSharedFiles;
            } else {
                documentMatchesRequest.filters.includeSharedFiles = true;
            }
            let res: IDocumentMatchedResponseFile = await fetchDocumentMatches(
                documentMatchesRequest
            );
            setActiveMatch(1);
            setFile(res);
            setTimeout(() => {
                focusIndex(1);
            }, 1000);
        } else {
            navigate(`/document-insight${window.location.search}`);
        }
    };

    useEffect(() => {
        focusIndex(activeMatch);
    }, [activeMatch]);

    const focusIndex = (index: number) => {
        let elList = [...document.getElementsByTagName('em')];
        elList.forEach((el, i: number) => {
            el.style.backgroundColor = '#FAE20580';
            if (index - 1 == i) {
                el.style.backgroundColor = '#FAE205';
                el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                });
            }
        });
    };

    return (
        <Box>
            <Box
                display="flex"
                py={4}
                pl={4}
                pr={2}
                sx={{
                    borderBottom: '1px solid #E3E3E3',
                }}
                justifyContent="space-between"
            >
                <Box textAlign="left">
                    <Typography variant="h6">Matches</Typography>
                    {file ? (
                        <Typography color="#4B4B4C" variant="subtitle2">
                            {file.matchCount} Matches Found
                        </Typography>
                    ) : (
                        <Skeleton sx={{ width: 200 }} variant="text" />
                    )}
                </Box>
                <Box display="flex" pt={2} pr={8}>
                    {file && file.matchCount > 0 && (
                        <Box
                            sx={{
                                border: '1px solid #D7D9EC',
                                borderRadius: 1,
                                px: 2,
                                py: 1,
                                mr: 3,
                            }}
                            display="flex"
                        >
                            <Typography>
                                {activeMatch} of {file.matchCount}
                            </Typography>

                            <Box
                                sx={{
                                    borderLeft: '1px solid #D7D9EC',
                                    ml: 2,
                                    pl: 2,
                                    pt: 0.5,
                                }}
                                display="flex"
                            >
                                <IconButton
                                    sx={{ mr: 1 }}
                                    onClick={() => {
                                        setActiveMatch(
                                            Math.max(activeMatch - 1, 1)
                                        );
                                    }}
                                >
                                    <KeyboardArrowUpIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        setActiveMatch(
                                            Math.min(
                                                activeMatch + 1,
                                                file.matchCount
                                            )
                                        );
                                    }}
                                >
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    )}
                    <Box pt={1}>
                        <NavigationLink
                            to={`/document-insight/chat${window.location.search}`}
                        >
                            <Close />
                        </NavigationLink>
                    </Box>
                </Box>
            </Box>
            <Box
                className="scrollable"
                px={4}
                sx={{
                    maxHeight: 'calc(100vh - 157px)',
                    overflow: 'auto',
                    textAlign: 'left',
                    '& em': {
                        background: '#FAE20580',
                        fontStyle: 'inherit',
                    },
                }}
                py={3}
            >
                {file ? (
                    file.rawText.split('\n').map((textBlock) =>
                        textBlock.trim() ? (
                            <Box
                                dangerouslySetInnerHTML={{
                                    __html: textBlock,
                                }}
                            />
                        ) : (
                            <p></p>
                        )
                    )
                ) : (
                    <Box>
                        {[...Array(50).keys()].map(() => (
                            <Skeleton sx={{ width: '100%' }} variant="text" />
                        ))}
                    </Box>
                )}
            </Box>
        </Box>
    );
};
