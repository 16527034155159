import { BorderedBox } from '../../../../components/core/box';
import { Box, Button, DialogContent, Grid, Typography } from '@mui/material';
import { IDiligenceTemplateConfiguration } from '../../../../services/interfaces';
import { DataSourceOptions } from './common-input';
import { Input } from '../../../../components/core/input';
import { useState } from 'react';
import { DropzoneIcon } from '../../../../components/icons/dropzone';
import { Circle } from '../../../../components/icons/circle';
import Dropzone from 'react-dropzone';
import { NewsImagePlaceholder } from '../../../../components/images/news-image-placeholder';
import { UtilsService } from '../../../../services/utils.service';
import { useSnackbar } from 'notistack';
import {
    BootstrapDialogLarge,
    BootstrapDialogTitle,
} from '../../../../components/core/dialog';

interface IProps {
    config: IDiligenceTemplateConfiguration;
    updateConfig: (config: IDiligenceTemplateConfiguration) => void;
}
const SUPPORTED_FORMATS = ['.jpeg', '.jpg', '.png', '.svg'];

export const WhiteLabel = (props: IProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [name, setName] = useState('');
    const uploadDocument = async (_uploadFiles: File[]) => {
        if (_uploadFiles && _uploadFiles.length > 0) {
            let file = _uploadFiles[0];
            if (!file) return;
            let fileExt =
                '.' + UtilsService.getFileType(file.name).toLowerCase();
            if (!SUPPORTED_FORMATS.includes(fileExt)) {
                enqueueSnackbar(`${fileExt} not supported`, {
                    variant: 'error',
                });
                return;
            }
            if (file.size > 1024 * 1000) {
                enqueueSnackbar(`Max file size allowed is 1MB`, {
                    variant: 'error',
                });
                return;
            }
            let url = await UtilsService.toBase64(file);
            props.updateConfig({
                ...props.config,
                sectionTemplate: {
                    ...props.config.sectionTemplate,
                    imagePresignedUrl: url,
                },
            });
        }
    };
    const closeDialog = () => {
        setOpenDialog(false);
    };
    return (
        <BorderedBox sx={{ backgroundColor: '#F9F9FF', p: 3 }}>
            <Box>
                <Box py={1} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            White Label
                        </Typography>
                        <Typography color="#8D8D8D" fontSize={12}>
                            Personalize your report for a branded touch
                        </Typography>
                    </Box>
                    <Box>
                        <Button
                            onClick={() => setOpenDialog(true)}
                            variant="text"
                        >
                            Preview
                        </Button>
                    </Box>
                </Box>
                <Box py={1} display="flex" justifyContent="space-between">
                    <Box>
                        <Typography color="#003" variant="h6">
                            Logo Upload<sup>*</sup>
                        </Typography>
                        <Typography color="#8D8D8D" fontSize={12}>
                            Upload the logo to be displayed on front page
                        </Typography>
                    </Box>
                    <Box></Box>
                </Box>
                <Box>
                    <Dropzone multiple={false} onDrop={uploadDocument}>
                        {({ getRootProps, getInputProps }) => (
                            <fieldset
                                {...getRootProps()}
                                style={{
                                    position: 'relative',
                                    border: '1px solid #D7D9EC',
                                    backgroundColor: '#fff',
                                    borderRadius: 8,
                                    cursor: 'pointer',
                                }}
                            >
                                <input {...getInputProps()} />{' '}
                                <Box p={4}>
                                    <Box display="flex">
                                        <Box
                                            sx={{
                                                border: '1px solid #ccc',
                                                backgroundColor: '#F9F9FF',
                                                borderRadius: 1,
                                                p: 2,
                                                mr: 4,
                                                height: '80px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <Box
                                                component={'span'}
                                                sx={{
                                                    display: 'inline-block',
                                                    height: '100%',
                                                    verticalAlign: 'middle',
                                                }}
                                            ></Box>
                                            {props.config.sectionTemplate
                                                .imagePresignedUrl ? (
                                                <img
                                                    style={{
                                                        maxHeight: '60px',
                                                        maxWidth: '300px',
                                                        verticalAlign: 'middle',
                                                    }}
                                                    src={
                                                        props.config
                                                            .sectionTemplate
                                                            .imagePresignedUrl
                                                    }
                                                />
                                            ) : (
                                                <NewsImagePlaceholder />
                                            )}
                                        </Box>
                                        <Box mt={2}>
                                            <Typography fontSize={14}>
                                                Drag and Drop an image or{' '}
                                            </Typography>
                                            <Box mt={2} display={'flex'}>
                                                <Button
                                                    sx={{ mr: 2 }}
                                                    variant="contained"
                                                >
                                                    Upload Image
                                                </Button>
                                                {props.config.sectionTemplate
                                                    .imagePresignedUrl && (
                                                    <Button
                                                        sx={{ ml: 2 }}
                                                        variant="text"
                                                        color="error"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            props.updateConfig({
                                                                ...props.config,
                                                                sectionTemplate:
                                                                    {
                                                                        ...props
                                                                            .config
                                                                            .sectionTemplate,
                                                                        imagePresignedUrl:
                                                                            '',
                                                                    },
                                                            });
                                                        }}
                                                    >
                                                        Remove Image
                                                    </Button>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box pt={2}>
                                        <Typography
                                            color="#A5A6AB"
                                            variant="caption"
                                        >
                                            .jpg, .jpeg, .png, .svg{' '}
                                            <Box px={1} component="span">
                                                <Circle mt={0} h={8} w={8} />{' '}
                                            </Box>
                                            Max file size upto 1 MB
                                        </Typography>
                                    </Box>
                                </Box>
                            </fieldset>
                        )}
                    </Dropzone>
                </Box>
            </Box>
            <Box>
                <Typography my={2} fontSize={14} fontWeight={600}>
                    Company name
                </Typography>
                <Input
                    mt={0}
                    pt={0}
                    pb={0}
                    fontSize={14}
                    value={props.config.sectionTemplate.companyName || ''}
                    noShadow
                    backgroundColor="#fff"
                    type="text"
                    onChange={(e) => {
                        props.updateConfig({
                            ...props.config,
                            sectionTemplate: {
                                ...props.config.sectionTemplate,
                                companyName: e.target.value,
                            },
                        });
                    }}
                    placeholder="Company name"
                />
            </Box>
            <Box>
                <Typography my={2} fontSize={14} fontWeight={600}>
                    Report Title
                </Typography>
                <Input
                    mt={0}
                    pt={0}
                    pb={0}
                    fontSize={14}
                    value={props.config.sectionTemplate.mainHeading || ''}
                    noShadow
                    backgroundColor="#fff"
                    type="text"
                    onChange={(e) => {
                        props.updateConfig({
                            ...props.config,
                            sectionTemplate: {
                                ...props.config.sectionTemplate,
                                mainHeading: e.target.value,
                            },
                        });
                    }}
                    placeholder="Report Title"
                />
            </Box>
            <Box>
                <Typography my={2} fontSize={14} fontWeight={600}>
                    Report Subtitle
                </Typography>
                <Input
                    mt={0}
                    pt={0}
                    pb={0}
                    fontSize={14}
                    value={props.config.sectionTemplate.subHeading || ''}
                    noShadow
                    backgroundColor="#fff"
                    type="text"
                    onChange={(e) => {
                        props.updateConfig({
                            ...props.config,
                            sectionTemplate: {
                                ...props.config.sectionTemplate,
                                subHeading: e.target.value,
                            },
                        });
                    }}
                    placeholder="Report Subtitle"
                />
            </Box>
            <Box>
                <Typography my={2} fontSize={14} fontWeight={600}>
                    Introduction Paragraph
                </Typography>
                <Input
                    mt={0}
                    pt={4}
                    pb={4}
                    multiline
                    fontSize={14}
                    value={props.config.sectionTemplate.paragraph || ''}
                    noShadow
                    backgroundColor="#fff"
                    type="text"
                    onChange={(e) => {
                        props.updateConfig({
                            ...props.config,
                            sectionTemplate: {
                                ...props.config.sectionTemplate,
                                paragraph: e.target.value,
                            },
                        });
                    }}
                    placeholder="Introduction Paragraph"
                />
            </Box>
            <Box>
                <Typography my={2} fontSize={14} fontWeight={600}>
                    Footer Information
                </Typography>
                <Input
                    mt={0}
                    pt={0}
                    pb={0}
                    fontSize={14}
                    value={props.config.sectionTemplate.footerText || ''}
                    noShadow
                    backgroundColor="#fff"
                    type="text"
                    onChange={(e) => {
                        props.updateConfig({
                            ...props.config,
                            sectionTemplate: {
                                ...props.config.sectionTemplate,
                                footerText: e.target.value,
                            },
                        });
                    }}
                    placeholder="email/website/any text"
                />
            </Box>
            <BootstrapDialogLarge
                onClose={closeDialog}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                sx={{
                    p: 4,
                }}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={closeDialog}
                >
                    Report Preview
                </BootstrapDialogTitle>
                <DialogContent>
                    <Grid mt={2} spacing={4} container>
                        <Grid item md={6}>
                            <Box
                                sx={{
                                    maxWidth: 600,
                                    mx: 'auto',
                                    backgroundColor: '#F9F9FF',
                                    position: 'relative',
                                }}
                            >
                                <Box position={'absolute'} right={0}>
                                    <svg
                                        width="411"
                                        height="230"
                                        viewBox="0 0 411 230"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g filter="url(#filter0_d_9505_110734)">
                                            <path
                                                d="M387.79 -263.003L500.808 -126.903L439.451 39.0233L265.077 68.8498L152.059 -67.2499L213.416 -233.176L387.79 -263.003Z"
                                                fill="#1165D9"
                                            />
                                        </g>
                                        <g filter="url(#filter1_d_9505_110734)">
                                            <path
                                                d="M377.378 -244.209L473.294 -128.883L421.378 11.846L273.545 37.2496L177.628 -78.0758L229.544 -218.805L377.378 -244.209Z"
                                                fill="#004AAC"
                                            />
                                        </g>
                                        <defs>
                                            <filter
                                                id="filter0_d_9505_110734"
                                                x="140.059"
                                                y="-271.004"
                                                width="372.75"
                                                height="355.855"
                                                filterUnits="userSpaceOnUse"
                                                color-interpolation-filters="sRGB"
                                            >
                                                <feFlood
                                                    flood-opacity="0"
                                                    result="BackgroundImageFix"
                                                />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="4" />
                                                <feGaussianBlur stdDeviation="6" />
                                                <feComposite
                                                    in2="hardAlpha"
                                                    operator="out"
                                                />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_9505_110734"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_9505_110734"
                                                    result="shape"
                                                />
                                            </filter>
                                            <filter
                                                id="filter1_d_9505_110734"
                                                x="167.628"
                                                y="-254.207"
                                                width="323.666"
                                                height="309.457"
                                                filterUnits="userSpaceOnUse"
                                                color-interpolation-filters="sRGB"
                                            >
                                                <feFlood
                                                    flood-opacity="0"
                                                    result="BackgroundImageFix"
                                                />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dx="4" dy="4" />
                                                <feGaussianBlur stdDeviation="7" />
                                                <feComposite
                                                    in2="hardAlpha"
                                                    operator="out"
                                                />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_9505_110734"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_9505_110734"
                                                    result="shape"
                                                />
                                            </filter>
                                        </defs>
                                    </svg>
                                </Box>
                                <Box pt={25} px={8}>
                                    {props.config.sectionTemplate
                                        .imagePresignedUrl ? (
                                        <img
                                            style={{
                                                maxHeight: '60px',
                                                maxWidth: '300px',
                                                verticalAlign: 'middle',
                                            }}
                                            src={
                                                props.config.sectionTemplate
                                                    .imagePresignedUrl
                                            }
                                        />
                                    ) : (
                                        <NewsImagePlaceholder />
                                    )}
                                    <Typography mt={2} variant="h6">
                                        {
                                            props.config.sectionTemplate
                                                .companyName
                                        }
                                    </Typography>
                                    <Typography
                                        mt={15}
                                        fontSize={41}
                                        fontWeight={600}
                                    >
                                        {
                                            props.config.sectionTemplate
                                                .mainHeading
                                        }
                                    </Typography>
                                    <Typography mt={8} variant="h6">
                                        {
                                            props.config.sectionTemplate
                                                .subHeading
                                        }
                                    </Typography>
                                    <Typography mt={2}>
                                        {props.config.sectionTemplate.paragraph}
                                    </Typography>
                                    <Box mb={10} mt={15}>
                                        <svg
                                            width="66"
                                            height="13"
                                            viewBox="0 0 66 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M65.2735 6.5L59.5 12.2735L53.7265 6.5L59.5 0.726497L65.2735 6.5ZM59.5 7.5H0V5.5H59.5V7.5Z"
                                                fill="url(#paint0_linear_11030_103248)"
                                            />
                                            <defs>
                                                <linearGradient
                                                    id="paint0_linear_11030_103248"
                                                    x1="54.0406"
                                                    y1="6.79064"
                                                    x2="7.42031"
                                                    y2="14.2559"
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop stop-color="#004AAC" />
                                                    <stop
                                                        offset="1"
                                                        stop-color="#1164D7"
                                                    />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <Typography fontSize={8}>
                                            Report On
                                        </Typography>
                                        <Typography variant="h4">
                                            Target_Company
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box display={'flex'}>
                                    <svg
                                        width="203"
                                        height="95"
                                        viewBox="0 0 203 95"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <mask
                                            id="mask0_9572_112787"
                                            maskUnits="userSpaceOnUse"
                                            x="0"
                                            y="0"
                                            width="203"
                                            height="95"
                                        >
                                            <rect
                                                width="203"
                                                height="95"
                                                fill="#F9F9FF"
                                            />
                                        </mask>
                                        <g mask="url(#mask0_9572_112787)">
                                            <g filter="url(#filter0_d_9572_112787)">
                                                <path
                                                    d="M294.023 557.979L358.997 230.671L108.027 10.7478L-207.917 118.132L-272.891 445.441L-21.9212 665.364L294.023 557.979Z"
                                                    fill="#1165D9"
                                                />
                                            </g>
                                            <g filter="url(#filter1_d_9572_112787)">
                                                <path
                                                    d="M260.878 504.197L316.184 226.714L103.529 40.0771L-164.431 130.923L-219.736 408.406L-7.08141 595.043L260.878 504.197Z"
                                                    fill="#004AAC"
                                                />
                                            </g>
                                        </g>
                                        <defs>
                                            <filter
                                                id="filter0_d_9572_112787"
                                                x="-284.892"
                                                y="-5.25391"
                                                width="655.889"
                                                height="678.617"
                                                filterUnits="userSpaceOnUse"
                                                color-interpolation-filters="sRGB"
                                            >
                                                <feFlood
                                                    flood-opacity="0"
                                                    result="BackgroundImageFix"
                                                />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="-4" />
                                                <feGaussianBlur stdDeviation="6" />
                                                <feComposite
                                                    in2="hardAlpha"
                                                    operator="out"
                                                />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_9572_112787"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_9572_112787"
                                                    result="shape"
                                                />
                                            </filter>
                                            <filter
                                                id="filter1_d_9572_112787"
                                                x="-237.736"
                                                y="22.0781"
                                                width="563.92"
                                                height="582.965"
                                                filterUnits="userSpaceOnUse"
                                                color-interpolation-filters="sRGB"
                                            >
                                                <feFlood
                                                    flood-opacity="0"
                                                    result="BackgroundImageFix"
                                                />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dx="-4" dy="-4" />
                                                <feGaussianBlur stdDeviation="7" />
                                                <feComposite
                                                    in2="hardAlpha"
                                                    operator="out"
                                                />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_9572_112787"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_9572_112787"
                                                    result="shape"
                                                />
                                            </filter>
                                        </defs>
                                    </svg>
                                    <Typography pt={15} ml="auto" mr={3}>
                                        {
                                            props.config.sectionTemplate
                                                .footerText
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box
                                sx={{
                                    maxWidth: 600,
                                    mx: 'auto',
                                    backgroundColor: '#F9F9FF',
                                    position: 'relative',
                                }}
                            >
                                <Box position={'absolute'} right={0}>
                                    <svg
                                        width="411"
                                        height="230"
                                        viewBox="0 0 411 230"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g filter="url(#filter0_d_9505_110734)">
                                            <path
                                                d="M387.79 -263.003L500.808 -126.903L439.451 39.0233L265.077 68.8498L152.059 -67.2499L213.416 -233.176L387.79 -263.003Z"
                                                fill="#1165D9"
                                            />
                                        </g>
                                        <g filter="url(#filter1_d_9505_110734)">
                                            <path
                                                d="M377.378 -244.209L473.294 -128.883L421.378 11.846L273.545 37.2496L177.628 -78.0758L229.544 -218.805L377.378 -244.209Z"
                                                fill="#004AAC"
                                            />
                                        </g>
                                        <defs>
                                            <filter
                                                id="filter0_d_9505_110734"
                                                x="140.059"
                                                y="-271.004"
                                                width="372.75"
                                                height="355.855"
                                                filterUnits="userSpaceOnUse"
                                                color-interpolation-filters="sRGB"
                                            >
                                                <feFlood
                                                    flood-opacity="0"
                                                    result="BackgroundImageFix"
                                                />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="4" />
                                                <feGaussianBlur stdDeviation="6" />
                                                <feComposite
                                                    in2="hardAlpha"
                                                    operator="out"
                                                />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_9505_110734"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_9505_110734"
                                                    result="shape"
                                                />
                                            </filter>
                                            <filter
                                                id="filter1_d_9505_110734"
                                                x="167.628"
                                                y="-254.207"
                                                width="323.666"
                                                height="309.457"
                                                filterUnits="userSpaceOnUse"
                                                color-interpolation-filters="sRGB"
                                            >
                                                <feFlood
                                                    flood-opacity="0"
                                                    result="BackgroundImageFix"
                                                />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dx="4" dy="4" />
                                                <feGaussianBlur stdDeviation="7" />
                                                <feComposite
                                                    in2="hardAlpha"
                                                    operator="out"
                                                />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_9505_110734"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_9505_110734"
                                                    result="shape"
                                                />
                                            </filter>
                                        </defs>
                                    </svg>
                                </Box>
                                <Box pt={10} px={8}>
                                    <Typography
                                        mt={2}
                                        fontSize={12}
                                        fontWeight={600}
                                    >
                                        Report Section
                                    </Typography>
                                    <svg
                                        width="295"
                                        height="12"
                                        viewBox="0 0 295 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M294.774 6L289 11.7735L283.226 6L289 0.226497L294.774 6ZM289 7L8.74228e-08 7.00003L-8.74228e-08 5.00003L289 5L289 7Z"
                                            fill="url(#paint0_linear_11030_103279)"
                                        />
                                        <defs>
                                            <linearGradient
                                                id="paint0_linear_11030_103279"
                                                x1="262.483"
                                                y1="6.29064"
                                                x2="117.774"
                                                y2="118.841"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop stop-color="#29319B" />
                                                <stop
                                                    offset="1"
                                                    stop-color="#4AB4C2"
                                                />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </Box>
                                <Box mt={138} display={'flex'}>
                                    <svg
                                        width="203"
                                        height="95"
                                        viewBox="0 0 203 95"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <mask
                                            id="mask0_9572_112787"
                                            maskUnits="userSpaceOnUse"
                                            x="0"
                                            y="0"
                                            width="203"
                                            height="95"
                                        >
                                            <rect
                                                width="203"
                                                height="95"
                                                fill="#F9F9FF"
                                            />
                                        </mask>
                                        <g mask="url(#mask0_9572_112787)">
                                            <g filter="url(#filter0_d_9572_112787)">
                                                <path
                                                    d="M294.023 557.979L358.997 230.671L108.027 10.7478L-207.917 118.132L-272.891 445.441L-21.9212 665.364L294.023 557.979Z"
                                                    fill="#1165D9"
                                                />
                                            </g>
                                            <g filter="url(#filter1_d_9572_112787)">
                                                <path
                                                    d="M260.878 504.197L316.184 226.714L103.529 40.0771L-164.431 130.923L-219.736 408.406L-7.08141 595.043L260.878 504.197Z"
                                                    fill="#004AAC"
                                                />
                                            </g>
                                        </g>
                                        <defs>
                                            <filter
                                                id="filter0_d_9572_112787"
                                                x="-284.892"
                                                y="-5.25391"
                                                width="655.889"
                                                height="678.617"
                                                filterUnits="userSpaceOnUse"
                                                color-interpolation-filters="sRGB"
                                            >
                                                <feFlood
                                                    flood-opacity="0"
                                                    result="BackgroundImageFix"
                                                />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="-4" />
                                                <feGaussianBlur stdDeviation="6" />
                                                <feComposite
                                                    in2="hardAlpha"
                                                    operator="out"
                                                />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_9572_112787"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_9572_112787"
                                                    result="shape"
                                                />
                                            </filter>
                                            <filter
                                                id="filter1_d_9572_112787"
                                                x="-237.736"
                                                y="22.0781"
                                                width="563.92"
                                                height="582.965"
                                                filterUnits="userSpaceOnUse"
                                                color-interpolation-filters="sRGB"
                                            >
                                                <feFlood
                                                    flood-opacity="0"
                                                    result="BackgroundImageFix"
                                                />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dx="-4" dy="-4" />
                                                <feGaussianBlur stdDeviation="7" />
                                                <feComposite
                                                    in2="hardAlpha"
                                                    operator="out"
                                                />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_9572_112787"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_9572_112787"
                                                    result="shape"
                                                />
                                            </filter>
                                        </defs>
                                    </svg>
                                    <Typography pt={15} ml="auto" mr={3}>
                                        {
                                            props.config.sectionTemplate
                                                .footerText
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialogLarge>
        </BorderedBox>
    );
};
