import { useContext } from 'react';
import {
    Box,
    FormControlLabel,
    FormGroup,
    Typography,
    Checkbox,
    Divider,
    Button,
} from '@mui/material';
import { AppContext } from '../../../providers/app-provider';
import { Add } from '@mui/icons-material';

interface IProps {
    query: string;
    clusterId: string | undefined;
    handleComplete: (step: number) => void;
    setClusterName: (name: string) => void;
    setClusterId: (id: string) => void;
    handleClose: () => void;
}

const SelectWatchlist = (props: IProps) => {
    const appContext = useContext(AppContext);

    const handleCancel = () => {
        props.setClusterId('');
        props.setClusterName('');
        props.handleClose();
    };

    return (
        <Box>
            <Typography variant="h4">Add Topic to your watchlist</Typography>
            {!appContext?.clusters && (
                <Typography variant="h6" mb={2}>
                    Looks like there is no watchlist here!
                </Typography>
            )}
            {appContext?.clusters && appContext?.clusters?.length > 0 && (
                <Box mt={4}>
                    <Typography variant="h6" mb={2}>
                        Save “{props.query}” to your watchlist
                    </Typography>
                    <FormGroup>
                        <Box sx={{ maxHeight: '40vh', overflowY: 'auto' }}>
                            {appContext.clusters.map((cluster) => (
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    props.clusterId ===
                                                    cluster.dashboardClusterId
                                                }
                                                onChange={() => {
                                                    props.setClusterId(
                                                        cluster.dashboardClusterId
                                                    );
                                                    props.setClusterName(
                                                        cluster.dashboardClusterName
                                                    );
                                                }}
                                            />
                                        }
                                        label={cluster.dashboardClusterName}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </FormGroup>
                </Box>
            )}
            <Divider sx={{ my: 4 }} />
            <Box
                display="flex"
                sx={{ color: '#29319B', cursor: 'pointer' }}
                onClick={() => props.handleComplete(2)}
            >
                <Add />
                <Typography variant="h6" ml={2}>
                    Create a new watchlist
                </Typography>
            </Box>
            <Box display="flex" mt={4} justifyContent="right">
                <Button
                    sx={{ color: '#4B4B4C', mr: 3 }}
                    onClick={() => handleCancel()}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => props.handleComplete(3)}
                >
                    Add
                </Button>
            </Box>
        </Box>
    );
};

export default SelectWatchlist;
