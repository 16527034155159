import React, { useEffect } from 'react';
import posthog, { PostHogConfig } from 'posthog-js';

const { REACT_APP_PUBLIC_POSTHOG_KEY, REACT_APP_PUBLIC_POSTHOG_HOST } =
    process.env;

export const Posthog = () => {
    useEffect(() => {
        if (REACT_APP_PUBLIC_POSTHOG_KEY && REACT_APP_PUBLIC_POSTHOG_HOST) {
            const posthogConfig: PostHogConfig = {
                api_host:
                    REACT_APP_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
                ui_host: null, // Set to null if not needed
                token: process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string,
                autocapture: true, // or use { some_option: true } for detailed AutocaptureConfig
                rageclick: true,
                bootstrap: {}, // Enable rageclick detection
                cross_subdomain_cookie: true, // Use cookies across subdomains
                persistence: 'localStorage', // Use localStorage for persistence
                persistence_name: 'ph', // Custom name for the storage key
                loaded: (posthog_instance: any) => {
                    if (posthog_instance.has_opted_out_capturing()) {
                        posthog_instance.opt_in_capturing(); // Opt-in if previously opted out
                    }
                },
                store_google: false, // Avoid storing Google-related data
                custom_campaign_params: ['utm_source', 'utm_medium'], // Customize campaign parameters
                custom_blocked_useragents: ['googlebot'], // Block specific user agents
                save_referrer: true, // Save the referrer data
                verbose: process.env.NODE_ENV === 'development', // Enable verbose logging in development
                capture_pageview: true, // Automatically capture pageviews
                capture_pageleave: 'if_capture_pageview', // Capture page leave events
                debug: process.env.NODE_ENV === 'development', // Enable debug mode in development
                cookie_expiration: 365, // Cookies expire after 1 year
                upgrade: false, // Disable automatic upgrades
                disable_session_recording: false, // Enable session recording
                disable_persistence: false, // Enable persistence
                disable_surveys: false, // Enable surveys
                enable_recording_console_log: true, // Capture console logs in session recordings
                secure_cookie: true, // Use secure cookies
                ip: true, // Capture IP addresses
                opt_out_capturing_by_default: false, // Capture by default unless opted out
                opt_out_capturing_persistence_type: 'cookie', // Use cookies for opt-out persistence
                opt_out_useragent_filter: false, // Don't filter user agents by default
                opt_out_capturing_cookie_prefix: 'ph_', // Prefix for opt-out cookies
                opt_in_site_apps: true, // Enable site apps
                respect_dnt: true, // Respect Do Not Track setting
                property_denylist: ['password', 'credit_card'], // Blacklist sensitive properties
                request_headers: {},
                inapp_protocol: 'https', // Use HTTPS for in-app requests
                inapp_link_new_window: true, // Open in-app links in a new window
                request_batching: true, // Enable request batching
                sanitize_properties: (properties: any, event_name: any) => {
                    // Custom sanitize function for properties
                    delete properties.sensitive_data;
                    return properties;
                },
                properties_string_max_length: 255, // Limit string properties to 255 characters
                session_recording: {
                    blockClass: 'no-record', // Block elements with this class from recording
                    maskTextClass: 'mask-text', // Mask text in elements with this class
                    maskAllInputs: true, // Mask all input fields
                },
                session_idle_timeout_seconds: 300, // Timeout sessions after 5 minutes of inactivity
                mask_all_element_attributes: true, // Mask all element attributes in session recordings
                mask_all_text: false, // Do not mask all text in session recordings
                advanced_disable_decide: false, // Enable the /decide endpoint
                advanced_disable_feature_flags: false, // Enable feature flags
                advanced_disable_feature_flags_on_first_load: false, // Enable feature flags on first load
                advanced_disable_toolbar_metrics: false, // Enable toolbar metrics
                feature_flag_request_timeout_ms: 2000, // Set feature flag request timeout to 2 seconds
                get_device_id: (uuid: any) => `custom-device-${uuid}`, // Custom device ID logic
                name: 'my-posthog-instance', // Custom name for the PostHog instance
                _onCapture: (eventName: any, eventData: any) => {
                    // console.log(`Event captured: ${eventName}`, eventData);
                },
                capture_performance: true, // Enable performance capture
                disable_compression: false, // Enable compression
                __preview_send_client_session_params: false, // Disable sending client session params in preview
                enable_heatmaps: true, // Enable heatmaps
                capture_heatmaps: true, // Capture heatmap data
                disable_scroll_properties: false, // Enable capturing scroll properties
                scroll_root_selector: '.scrollable', // Define root selector for scroll capturing
                person_profiles: 'identified_only', // Only process identified users' profiles
                rate_limiting: {
                    events_per_second: 10, // Limit to 10 events per second
                    events_burst_limit: 100, // Burst limit of 100 events
                },
                __add_tracing_headers: true, // Enable tracing headers for fetch requests (experimental)
            };
            posthog.init(REACT_APP_PUBLIC_POSTHOG_KEY, posthogConfig);
        }
    }, []);

    return null;
};
