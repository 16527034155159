import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import leftSectionImage from '../../../assets/images/login/left-section-bg.svg';
import crousal1 from '../../../assets/images/login/crousal-1.svg';
import crousal2 from '../../../assets/images/login/crousal-2.svg';
import crousal3 from '../../../assets/images/login/crousal-3.svg';

const LeftSection = styled(Box)(({ theme }: any) => ({
    backgroundColor: theme.palette.primary.main,
    height: '100vh',
    position: 'relative',
}));

const CrouselSubTitle = styled(Typography)(({ theme }: any) => ({
    color: theme.palette.primary.light,
}));

function CarouselItem(props: any) {
    const { item } = props;
    return (
        <Box sx={{ width: '100%' }}>
            <img
                style={{
                    width: '70%',
                    maxWidth: '80%',
                    maxHeight: '420px',
                    height: '420px',
                }}
                src={item.imageUrl}
            />
            <Typography color="#fff" variant="h2">
                {item.title}
            </Typography>
            <CrouselSubTitle variant="h6">{item.subTitle}</CrouselSubTitle>
        </Box>
    );
}

interface IProps {}
export const LeftSide = (props: IProps) => {
    const items = [
        {
            title: 'Welcome to QI',
            subTitle: 'Log in to streamline your research',
            imageUrl: crousal1,
        },
        {
            title: 'Welcome to QI',
            subTitle: 'Log in to streamline your research',
            imageUrl: crousal2,
        },
        {
            title: 'Welcome to QI',
            subTitle: 'Log in to streamline your research',
            imageUrl: crousal3,
        },
    ];

    return (
        <LeftSection>
            <img
                style={{ position: 'absolute', top: 0, right: 0 }}
                src={leftSectionImage}
            />
            <img
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '300px',
                    height: '200px',
                    objectFit: 'cover',
                }}
                height="137"
                width="200"
                src={leftSectionImage}
            />
            {/* Crousel begins here */}
            <Box
                sx={{
                    width: '100%',
                    paddingTop: 'calc(100vh/2 - 300px)',
                }}
            >
                <Carousel
                    indicatorIconButtonProps={{
                        style: {
                            color: '#FFFFFF',
                            opacity: 0.2,
                        },
                    }}
                    navButtonsAlwaysVisible={true}
                    navButtonsProps={{
                        style: {
                            backgroundColor: '#29319B',
                            borderRadius: 0,
                        },
                    }}
                    navButtonsWrapperProps={{
                        style: {
                            top: '256px',
                        },
                    }}
                    activeIndicatorIconButtonProps={{
                        style: {
                            color: '#F9F9FF',
                            opacity: 1,
                        },
                    }}
                >
                    {items.map((item, i) => (
                        <CarouselItem key={i} item={item} />
                    ))}
                </Carousel>
            </Box>
        </LeftSection>
    );
};
