interface IProps {
    size?: number;
    color?: string;
}

export const EarningsCallIcon = (props: IProps) => (
    <svg
        width={props.size || '20'}
        height={props.size || '20'}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_6925_61343"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
        >
            <rect
                width={props.size || '20'}
                height={props.size || '20'}
                fill="#D9D9D9"
            />
        </mask>
        <g mask="url(#mask0_6925_61343)">
            <path
                d="M16.1931 17.0334C14.5724 17.0334 12.9675 16.6487 11.3783 15.8795C9.78907 15.1103 8.37027 14.1015 7.12189 12.8531C5.87348 11.6047 4.86334 10.1872 4.09145 8.60069C3.31955 7.01416 2.93359 5.41053 2.93359 3.78981C2.93359 3.53981 3.01693 3.33148 3.18359 3.16481C3.35026 2.99814 3.55859 2.91481 3.80859 2.91481H6.52649C6.74231 2.91481 6.93061 2.98132 7.09141 3.11433C7.25218 3.24735 7.35181 3.41909 7.39028 3.62956L7.86784 6.08146C7.90095 6.28658 7.89748 6.47301 7.85743 6.64075C7.81736 6.80848 7.72948 6.95485 7.5938 7.07985L5.68995 8.95325C6.34807 10.0708 7.13066 11.0804 8.03772 11.9821C8.94477 12.8838 9.97416 13.6701 11.1259 14.3411L12.9881 12.4469C13.1237 12.3112 13.2843 12.2148 13.4696 12.1576C13.655 12.1004 13.8449 12.0884 14.0393 12.1215L16.3534 12.5927C16.5639 12.6397 16.7356 12.7415 16.8686 12.898C17.0016 13.0545 17.0681 13.2407 17.0681 13.4565V16.1584C17.0681 16.4084 16.9848 16.6167 16.8181 16.7834C16.6515 16.95 16.4431 17.0334 16.1931 17.0334ZM5.09061 7.77058L6.5778 6.3475C6.60451 6.32612 6.62187 6.29674 6.62989 6.25935C6.6379 6.22195 6.63657 6.18723 6.62589 6.15519L6.25089 4.293C6.24019 4.25026 6.22149 4.21821 6.19478 4.19685C6.16807 4.17548 6.13335 4.16479 6.09061 4.16479H4.32139C4.28933 4.16479 4.26262 4.17548 4.24126 4.19685C4.21989 4.21821 4.2092 4.24492 4.2092 4.27698C4.25193 4.84642 4.34515 5.42495 4.48884 6.01256C4.63254 6.60017 4.83313 7.18618 5.09061 7.77058ZM15.706 15.7577C15.738 15.7577 15.7648 15.747 15.7861 15.7257C15.8075 15.7043 15.8182 15.6776 15.8182 15.6455V13.8924C15.8182 13.8496 15.8075 13.8149 15.7861 13.7882C15.7648 13.7615 15.7327 13.7428 15.6899 13.7321L13.94 13.3635C13.9079 13.3528 13.8799 13.3515 13.8558 13.3595C13.8318 13.3675 13.8064 13.3849 13.7797 13.4116L12.3086 14.8988C12.8609 15.1562 13.4349 15.3574 14.0305 15.5021C14.6261 15.6469 15.1846 15.7321 15.706 15.7577Z"
                fill={props.color || '#4B4B4C'}
            />
        </g>
        <mask
            id="mask1_6925_61343"
            maskUnits="userSpaceOnUse"
            x="8"
            y="3"
            width="9"
            height="9"
        >
            <rect
                x="8.33594"
                y="3.32915"
                width="8.33333"
                height="8.33333"
                fill="#D9D9D9"
            />
        </mask>
        <g mask="url(#mask1_6925_61343)">
            <path
                d="M12.5126 10.619C12.4142 10.619 12.3318 10.5857 12.2652 10.5191C12.1987 10.4526 12.1654 10.3701 12.1654 10.2717V9.87243C11.905 9.81456 11.6764 9.71329 11.4796 9.56861C11.2829 9.42393 11.1237 9.22139 11.0022 8.96097C10.9617 8.87995 10.9603 8.79459 10.9979 8.7049C11.0355 8.6152 11.1035 8.55009 11.2019 8.50958C11.2829 8.47486 11.3668 8.47631 11.4536 8.51392C11.5404 8.55154 11.607 8.61375 11.6533 8.70055C11.7516 8.87417 11.8761 9.00582 12.0265 9.09552C12.177 9.18522 12.3622 9.23007 12.5821 9.23007C12.8193 9.23007 13.0204 9.17654 13.1854 9.06948C13.3503 8.96242 13.4328 8.79604 13.4328 8.57035C13.4328 8.3678 13.3691 8.20721 13.2418 8.08858C13.1145 7.96994 12.8193 7.83539 12.3564 7.68493C11.8587 7.52868 11.5173 7.34205 11.3321 7.12503C11.1469 6.90802 11.0543 6.64326 11.0543 6.33076C11.0543 5.95461 11.1758 5.66236 11.4189 5.45403C11.6619 5.24569 11.9108 5.12706 12.1654 5.09812V4.71618C12.1654 4.6178 12.1987 4.53533 12.2652 4.46878C12.3318 4.40223 12.4142 4.36896 12.5126 4.36896C12.611 4.36896 12.6935 4.40223 12.76 4.46878C12.8266 4.53533 12.8598 4.6178 12.8598 4.71618V5.09812C13.0798 5.13285 13.2707 5.20374 13.4328 5.3108C13.5948 5.41786 13.7279 5.54951 13.8321 5.70576C13.8842 5.78099 13.8943 5.86491 13.8625 5.9575C13.8306 6.05009 13.7655 6.11664 13.6671 6.15715C13.5861 6.19187 13.5022 6.19332 13.4154 6.16149C13.3286 6.12966 13.2476 6.07324 13.1723 5.99222C13.0971 5.9112 13.0089 5.84899 12.9076 5.80559C12.8063 5.76219 12.6805 5.74049 12.53 5.74049C12.2754 5.74049 12.0815 5.79691 11.9484 5.90976C11.8153 6.0226 11.7487 6.16294 11.7487 6.33076C11.7487 6.52174 11.8355 6.6722 12.0092 6.78215C12.1828 6.89211 12.4837 7.00785 12.9119 7.12937C13.3112 7.24511 13.6136 7.42885 13.8191 7.68059C14.0245 7.93233 14.1272 8.22312 14.1272 8.55299C14.1272 8.96386 14.0057 9.27636 13.7626 9.49049C13.5196 9.70461 13.2186 9.83771 12.8598 9.88979V10.2717C12.8598 10.3701 12.8266 10.4526 12.76 10.5191C12.6935 10.5857 12.611 10.619 12.5126 10.619Z"
                fill={props.color || '#4B4B4C'}
            />
        </g>
    </svg>
);
