import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Button,
    IconButton,
    Collapse,
    Divider,
    Grid,
    Chip,
    CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useBillingService } from '../../../services/billing.service';
import { IInvoice } from '../../../services/interfaces';
import { UtilsService } from '../../../services/utils.service';
import loadingAnimation from '../../../components/animations/circular-loader.json';
import Lottie from 'lottie-react';
import { useSnackbar } from 'notistack';
import { useQueryparamServices } from '../../../services/query-param.service';
import { Invoices } from '../../../components/images/invoices';

// Styled Components
const InvoiceContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(4),
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#FFFFFF',
}));

const InvoiceHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(4),
}));

const InvoiceTitle = styled(Typography)(({ theme }) => ({
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    color: theme.palette.text.primary,
}));

const SummaryItem = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.palette.text.primary,
}));

const InvoiceDetails = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
}));

const InvoiceSummary = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    alignItems: 'center',
}));

const InvoiceAmount = styled(Typography)(({ theme }) => ({
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    color: theme.palette.primary.main,
}));

const DownloadButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 600,
    color: theme.palette.primary.main,
    backgroundColor: '#F0F4FF',
    '&:hover': {
        backgroundColor: '#e0eaff',
    },
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
}));

const PayNowButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(4),
    padding: theme.spacing(1, 4),
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: theme.shape.borderRadius,
}));

interface AddOn {
    name: string;
    description: string;
    price: number;
    quantity: number;
}

interface IInvoiceItemProps {
    invoice: IInvoice;
}

const InvoiceItem = (props: IInvoiceItemProps) => {
    const { invoice } = props;
    const [open, setOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const { downloadInvoice } = useBillingService();
    const { enqueueSnackbar } = useSnackbar();

    const handleToggle = () => {
        setOpen((prev) => !prev);
    };

    const openUrlInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleDownloadClick = async () => {
        setIsDownloading(true);
        const res = await downloadInvoice(invoice.id);
        setIsDownloading(false);
        if (res.success) {
            openUrlInNewTab(res.data.download_link);
        } else {
            enqueueSnackbar('Download failed', { variant: 'error' });
        }
    };

    return (
        <InvoiceContainer elevation={0}>
            <InvoiceHeader>
                <Box>
                    <InvoiceTitle>
                        {invoice.invoice_type == 'products'
                            ? 'Add Ons'
                            : UtilsService.snakeCaseToTitleCase(
                                  invoice.plan || ''
                              )}
                    </InvoiceTitle>
                    <Typography variant="body2" color="textSecondary">
                        {invoice.invoice_type == 'products'
                            ? 'Flexible Add-Ons as per your need to boost up your features'
                            : 'Plan for all your needs'}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    {invoice.status && invoice.status == 'open' ? (
                        <Typography
                            sx={{
                                py: 2,
                                px: 3,
                                height: 38,
                                borderRadius: 6,
                                border: '1px solid #F1C21B',
                                backgroundColor: '#FFF2C2',
                            }}
                            fontSize={14}
                            color="#7E6300"
                        >
                            Payment Due
                        </Typography>
                    ) : (
                        <DownloadButton
                            disabled={isDownloading}
                            onClick={handleDownloadClick}
                        >
                            {isDownloading ? (
                                <CircularProgress
                                    size={25}
                                    sx={{
                                        color: '#ffffff',
                                        ml: 2,
                                    }}
                                />
                            ) : (
                                'Download Invoice'
                            )}
                        </DownloadButton>
                    )}
                    <IconButton sx={{ ml: 2 }} onClick={handleToggle}>
                        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>
                </Box>
            </InvoiceHeader>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <InvoiceDetails>
                    <Grid container justifyContent={'space-between'}>
                        {invoice.products && (
                            <Grid item sm={8}>
                                <Box>
                                    {invoice.products.map((addOn, index) => (
                                        <Box>
                                            {index !== 0 && (
                                                <Box py={3}>
                                                    <Divider />
                                                </Box>
                                            )}
                                            <SummaryItem key={index}>
                                                {`${
                                                    addOn.quantity /
                                                        addOn.increment <
                                                    10
                                                        ? '0' +
                                                          addOn.quantity /
                                                              addOn.increment
                                                        : addOn.quantity /
                                                          addOn.increment
                                                } × ${UtilsService.snakeCaseToTitleCase(
                                                    addOn.product
                                                )} ($${addOn.price}/month) = $${
                                                    (addOn.quantity /
                                                        addOn.increment) *
                                                    addOn.price
                                                }`}
                                            </SummaryItem>
                                        </Box>
                                    ))}
                                </Box>
                            </Grid>
                        )}
                        <Grid item sm={2} xs={3}>
                            <Box>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight={600}
                                >
                                    Total Amount Paid
                                </Typography>
                                <InvoiceSummary>
                                    <InvoiceAmount>
                                        ${invoice.amount.toLocaleString()}
                                    </InvoiceAmount>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        On{' '}
                                        {UtilsService.formatStringDate(
                                            invoice.invoice_date
                                        )}
                                    </Typography>
                                </InvoiceSummary>
                            </Box>
                        </Grid>
                        {invoice.status && invoice.status == 'open' && (
                            <Box display="flex" justifyContent="flex-end">
                                <PayNowButton
                                    variant="contained"
                                    color="primary"
                                    sx={{ height: 30 }}
                                    onClick={() =>
                                        openUrlInNewTab(invoice.payment_link)
                                    }
                                >
                                    Pay Now
                                </PayNowButton>
                            </Box>
                        )}
                    </Grid>
                </InvoiceDetails>
            </Collapse>
        </InvoiceContainer>
    );
};

export const BillingHistory: React.FC = () => {
    const { fetchInvoices } = useBillingService();
    const { addQueryParams, getQueryParam, removeQueryParams } =
        useQueryparamServices();
    const [invoices, setInvoices] = useState<IInvoice[] | undefined>(undefined);
    const initialize = async () => {
        const res = await fetchInvoices();
        setInvoices(res);
    };
    useEffect(() => {
        initialize();
    }, []);

    return invoices ? (
        <Box py={3}>
            {invoices.map((invoice, index) => (
                <InvoiceItem key={index} invoice={invoice} />
            ))}
            {invoices?.length == 0 && (
                <Box mt={10} textAlign={'center'}>
                    {Invoices}
                    <Typography mt={2}>No Billing History Yet.</Typography>
                    <Button
                        sx={{ height: 30, ml: 4, mt: 4 }}
                        onClick={() => addQueryParams('subTab', 'PLANS')}
                        variant="contained"
                    >
                        View Plans
                    </Button>
                </Box>
            )}
        </Box>
    ) : (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 150px)',
            }}
        >
            <Lottie animationData={loadingAnimation} loop={true} />
        </Box>
    );
};
