import { useContext } from 'react';
import { Constants } from '../constants';
import { AppContext } from '../providers/app-provider';
import { Granularity } from './insights.service';
import {
    Article,
    ChartDetail,
    ClusterSentimentsRequest,
    ClusterSentimentsResponse,
    HighlightedTopicsResponse,
    HighlightedTopicsResponseData,
    LatestSentimentsRequest,
    PredefinedTopicsResponse,
    SentimentChartData,
    SourceDistribution,
    SourceDistributionResponse,
    StockInformation,
    Topic,
    TopicBookmarksRequest,
    TopicBookmarksResponse,
    TopicBookmarksResponseDataElement,
    ChatFilterSource,
    ChatFiltersRequest,
    ChatFiltersResponse,
    TopicChatUpdateRequest,
    TopicCompareQueryRequest,
    TopicMetric,
    TopicQueryArticleRequest,
    TopicQueryArticleResponse,
    TopicQueryRequest,
    TopicSentimentAssesmentRequest,
    TopicSentimentAssesmentResponse,
    TopicStockPriceRequest,
    TopicStockPriceResponse,
    TopicSubscribeResponse,
    TopicTrendsRequest,
    TopicTrendsResponse,
    UserCluster,
    UserTopic,
    LiveQueryRequest,
    ILiveQAFiltersResponseData,
    ILiveQAFiltersResponse,
    IInsightsFiltersResponseData,
    IInsightsFiltersResponse,
    IUserConfiguration,
} from './interfaces';
import { NetworkRequestMethod, useNetworkAPI } from './network.service';
import { UtilsService } from './utils.service';

export const useTopicService = () => {
    const { triggerRequest, triggerRequestWithChunkedResponse } =
        useNetworkAPI();
    const appContext = useContext(AppContext);

    const fetchClusterTopicSentiments = async (clusterTopic: UserTopic[]) => {
        if (!appContext) return;
        let topicSubscriptionIds = clusterTopic.map(
            (c) => c.topicSubscriptionId
        );
        try {
            let data = await fetchTopicSentiments(topicSubscriptionIds);

            let _topics = processTopicsSentiments(data, topicSubscriptionIds);
            appContext.setSubscriptions([..._topics]);
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchTopicChatFilters = async (
        req: ChatFiltersRequest
    ): Promise<ChatFilterSource[]> => {
        // return [
        //     {
        //         "scrapingSourceId": "SC4039034094359840",
        //         "title": "CNN"
        //     },
        //     {
        //         "scrapingSourceId": "SC4398349345894399",
        //         "title": "CNBC"
        //     }
        // ]
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: ChatFiltersRequest = req;
            const result: {
                body: ChatFiltersResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.topicChatFilters,
                retryCount: 3,
            });
            return result.body.data.sources as ChatFilterSource[];
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };
    const getTopicSentiments = async (
        topicSubscriptionId: string[],
        granularity?: string,
        startDate?: string,
        endDate?: string
    ): Promise<SentimentChartData[]> => {
        if (!appContext) throw '';
        let durationDates: string[] = [];
        if (appContext) {
            durationDates = UtilsService.getDatesBetweenDates(
                appContext.filters.date.startDate,
                appContext.filters.date.endDate
            );
        }
        let sentimentsData = await fetchTopicSentiments(
            topicSubscriptionId,
            startDate || appContext.filters.date.startDate,
            endDate || appContext.filters.date.endDate,
            granularity || 'DAILY'
        );
        for (let s of sentimentsData) {
            for (let c of s.chartDetails) {
                c.sentiment.positive = c.sentiment.positive
                    ? c.sentiment.positive * 1000
                    : null;
                c.sentiment.negative = c.sentiment.negative
                    ? c.sentiment.negative * 1000
                    : null;
                c.sentiment.neutral = c.sentiment.neutral
                    ? c.sentiment.neutral * 1000
                    : null;
            }
            if (granularity && granularity == 'DAILY') {
                for (let date of durationDates) {
                    const currDateData = s.chartDetails.filter(
                        (c: ChartDetail) => c.date == date
                    )[0];
                    if (!currDateData) {
                        s.chartDetails.push(getNullChart(date));
                    }
                }
            }

            s.chartDetails = s.chartDetails.sort(
                (a: ChartDetail, b: ChartDetail) =>
                    new Date(a.date) < new Date(b.date) ? -1 : 1
            );
        }
        return sentimentsData;
    };

    const fetchQueryArticleResponse = async (
        query: string,
        topicSubscriptionIds: string[]
    ): Promise<Article[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: TopicQueryArticleRequest = {
                query,
                topicSubscriptionIds,
                promptPage: 'TOPIC_INSIGHTS',
            };
            const result: {
                body: TopicQueryArticleResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.topicArticleQuery,
                retryCount: 3,
            });
            return result.body.data.articles;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchQueryResponse = async (
        query: string,
        topicSubscriptionIds: string[],
        startDate: string,
        endDate: string,
        callback: (data: string) => void,
        sources?: ChatFilterSource[]
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: TopicQueryRequest = {
                    query,
                    startDate,
                    endDate,
                    sources,
                    topicSubscriptionIds:
                        topicSubscriptionIds.length > 1
                            ? topicSubscriptionIds
                            : undefined,
                    topicSubscriptionId:
                        topicSubscriptionIds.length == 1
                            ? topicSubscriptionIds[0]
                            : undefined,
                    chatBotPrompt:
                        topicSubscriptionIds.length > 1
                            ? 'DASHBOARD'
                            : 'TOPIC_INSIGHTS',
                    stream: true,
                };
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.liveQuery,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchTopicSentiments = async (
        topicSubscriptionIds: string[],
        startDate?: string,
        endDate?: string,
        granularity?: string,
        categoryId?: string
    ): Promise<SentimentChartData[]> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: ClusterSentimentsRequest = {
                topicSubscriptionIds,
                startDate: startDate || appContext.filters.date.startDate,
                endDate: endDate || appContext.filters.date.endDate,
                categoryId: categoryId
                    ? categoryId
                    : appContext?.category
                    ? appContext?.category
                    : '',
                granularity: granularity || 'DAILY',
            };
            const result: {
                body: ClusterSentimentsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.getUserClusterSentiments,
                retryCount: 3,
            });

            return result.body.data.sentimentChartData;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchLatestSentiments = async (
        topicSubscriptionIds: string[],
        granularity?: string
    ): Promise<SentimentChartData[]> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: LatestSentimentsRequest = {
                topicSubscriptionIds,
                categoryId: appContext.category,
                granularity: granularity || 'DAILY',
            };
            const result: {
                body: ClusterSentimentsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.getLatestSentiments,
                retryCount: 3,
            });

            return result.body.data.sentimentChartData;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchTopicStockPrice = async (
        topicSubscriptionId: string,
        startDate?: string,
        endDate?: string
    ): Promise<StockInformation[] | null> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: TopicStockPriceRequest = {
                topicSubscriptionId,
                startDate: startDate || appContext.filters.date.startDate,
                endDate: endDate || appContext.filters.date.endDate,
            };
            const result: {
                body: TopicStockPriceResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.getTopicStockPrice,
                retryCount: 3,
            });

            return result.body.data.ticker
                ? processTopicStockPrice(
                      result.body.data.ticker.stockInformation
                  )
                : null;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const subscribeToTopic = async (title: string): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: TopicSubscribeResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PATCH,
                headers,
                url: `${Constants.subscribeTopic}/${title}`,
                retryCount: 3,
            });

            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return false;
        }
    };

    const getPredefinedTopics = async (): Promise<Topic[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: PredefinedTopicsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: Constants.getPredefinedTopics,
                retryCount: 3,
            });
            if (result?.body?.data?.topics) {
                let t = result.body.data.topics.sort(
                    (a: Topic, b: Topic) =>
                        -b.subscriptionName[0].localeCompare(
                            a.subscriptionName[0]
                        )
                );
                if (UtilsService.isFirefox()) {
                    t.reverse();
                }
                return t;
            } else {
                return [];
            }
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const getUserTopics = async (): Promise<Topic[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: PredefinedTopicsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: Constants.getUserTopics,
                retryCount: 3,
            });
            if (result?.body?.data?.topics) {
                let t = result.body.data.topics;
                if (UtilsService.isFirefox()) {
                    t.reverse();
                }
                return t;
            } else {
                return [];
            }
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const processTopicStockPrice = (data: StockInformation[]) => {
        if (!appContext) throw '';
        let durationDates: string[] = [];
        if (appContext) {
            durationDates = UtilsService.getDatesBetweenDates(
                appContext.filters.date.startDate,
                appContext.filters.date.endDate
            );
        }
        for (let date of durationDates) {
            const currDateData = data.filter(
                (c: StockInformation) => c.date == date
            )[0];
            if (!currDateData) {
                data.push({
                    date,
                    closingPrice: 0,
                });
            }
        }

        data = data.sort((a: StockInformation, b: StockInformation) =>
            new Date(a.date) < new Date(b.date) ? -1 : 1
        );
        for (let index = 0; index < data.length; index++) {
            if (data[index].closingPrice == 0) {
                if (index == 0) {
                    data[index].closingPrice =
                        UtilsService.getForstNonZeroValue(
                            data.map((value) => value.closingPrice)
                        );
                } else {
                    data[index].closingPrice = data[index - 1].closingPrice;
                }
            }
        }
        return data;
    };

    const getNullChart = (date: string) => {
        return {
            date: date,
            sentiment: {
                positive: null,
                negative: null,
                neutral: null,
            },
        };
    };

    const processTopicsSentiments = (
        sentimentsData: SentimentChartData[],
        topicSubscriptionIds: string[]
    ): UserTopic[] => {
        if (!appContext) throw '';
        let _topics = [...appContext.subscriptions];
        let durationDates: string[] = [];
        if (appContext) {
            durationDates = UtilsService.getDatesBetweenDates(
                appContext.filters.date.startDate,
                appContext.filters.date.endDate
            );
        }
        for (let s of sentimentsData) {
            for (let c of s.chartDetails) {
                c.sentiment.positive = c.sentiment.positive
                    ? c.sentiment.positive * 1000
                    : null;
                c.sentiment.negative = c.sentiment.negative
                    ? c.sentiment.negative * 1000
                    : null;
                c.sentiment.neutral = c.sentiment.neutral
                    ? c.sentiment.neutral * 1000
                    : null;
            }

            for (let t of _topics) {
                if (t.topicSubscriptionId == s.topicSubscriptionId) {
                    t.sentiments = s;
                }
            }
        }

        for (let s of sentimentsData) {
            for (let date of durationDates) {
                const currDateData = s.chartDetails.filter(
                    (c: ChartDetail) => c.date == date
                )[0];
                if (!currDateData) {
                    s.chartDetails.push(getNullChart(date));
                }
            }

            s.chartDetails = s.chartDetails.sort(
                (a: ChartDetail, b: ChartDetail) =>
                    new Date(a.date) < new Date(b.date) ? -1 : 1
            );

            for (let t of _topics) {
                if (t.topicSubscriptionId == s.topicSubscriptionId) {
                    t.sentiments = s;
                }
            }
        }
        for (let t of _topics) {
            if (
                !sentimentsData.filter(
                    (s: SentimentChartData) =>
                        s.topicSubscriptionId == t.topicSubscriptionId
                ).length &&
                topicSubscriptionIds.includes(t.topicSubscriptionId)
            ) {
                let chartDetails: ChartDetail[] = [];
                for (let date of durationDates) {
                    chartDetails.push(getNullChart(date));
                }
                t.sentiments = {
                    topicName: t.subscriptionName,
                    chartDetails: chartDetails,
                    topicSubscriptionId: t.topicSubscriptionId,
                    sentimentAggregate: {
                        positive: 0,
                        negative: 0,
                        neutral: 0,
                    },
                };
            }
        }
        return [..._topics];
    };

    const fetchTopicSentimentAssesment = async (
        topicSubscriptionIds: string[],
        granularity: Granularity
    ) => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: TopicSentimentAssesmentRequest = {
                topicSubscriptionIds,
                startDate: appContext.filters.date.startDate,
                endDate: appContext.filters.date.endDate,
                categoryId: appContext?.category ? appContext?.category : '',
                granularity: granularity,
            };
            const result: {
                body: TopicSentimentAssesmentResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.getTopicSentimentAssesment,
                retryCount: 3,
            });
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchHighlights = async (cluster: UserCluster) => {
        if (!appContext || cluster.highlights) return;
        let topicSubscriptionIds: string[] =
            appContext
                ?.getClusterTopics(cluster.dashboardClusterId)
                .map(
                    (clusterTopic: UserTopic) =>
                        clusterTopic.topicSubscriptionId
                ) || [];

        const headers = {
            'Content-Type': 'application/json',
        };
        const body: ClusterSentimentsRequest = {
            topicSubscriptionIds,
            startDate: appContext.filters.date.startDate,
            endDate: appContext.filters.date.endDate,
            categoryId: appContext?.category ? appContext?.category : '',
            granularity: 'DAILY',
        };
        const result: {
            body: HighlightedTopicsResponse;
            headers: { [key: string]: string };
        } = await triggerRequest({
            method: NetworkRequestMethod.POST,
            headers,
            body: JSON.stringify(body),
            url: Constants.getHighlightedTopics,
            retryCount: 3,
        });

        processTopicsHighlights(result.body.data, cluster);
    };

    const processTopicsHighlights = (
        highlightTopics: HighlightedTopicsResponseData,
        cluster: UserCluster
    ) => {
        if (!appContext?.clusters) return;
        let _clusters = [...appContext.clusters];
        for (let c of _clusters) {
            if (c.dashboardClusterId == cluster.dashboardClusterId) {
                c.highlights = highlightTopics;
                c.highlightStatus = true;
            }
        }
        appContext.setClusters([..._clusters]);
    };

    const getTopicByTopicSubscriptionId = (topicSubscriptionId: string) => {
        return appContext?.subscriptions.map(
            (s: UserTopic) => s.topicSubscriptionId == topicSubscriptionId
        )[0];
    };

    const fetchTopicSourceDistribution = async (
        topicSubscriptionIds: string[]
    ): Promise<SourceDistribution[]> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: ClusterSentimentsRequest = {
                topicSubscriptionIds,
                startDate: appContext.filters.date.startDate,
                endDate: appContext.filters.date.endDate,
                categoryId: appContext?.category ? appContext?.category : '',
                granularity: 'DAILY',
            };
            const result: {
                body: SourceDistributionResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.getTopicSourceDistribution,
                retryCount: 3,
            });

            return result.body.data.sourceDistributions;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchTopicChatBookmarks = async (
        chatBotPrompt: string,
        topicSubscriptionId?: string
    ): Promise<TopicBookmarksResponseDataElement[]> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: TopicBookmarksRequest = {
                topicSubscriptionId,
                chatBotPrompt,
                isBookmarked: true,
            };
            const result: {
                body: TopicBookmarksResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: `${Constants.getTopicChat}?fetch=all`,
                retryCount: 3,
            });

            return result.body.data
                .elements as TopicBookmarksResponseDataElement[];
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchInsigtsFilter =
        async (): Promise<IInsightsFiltersResponseData> => {
            if (!appContext) throw '';
            try {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body = {
                    feature: 'INSTANT_INSIGHTS',
                };
                const result: {
                    body: IInsightsFiltersResponse;
                    headers: { [key: string]: string };
                } = await triggerRequest({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getChatbotFilters,
                    retryCount: 3,
                });

                return result.body.data;
            } catch (error: any) {
                console.error(error);
                return error;
            }
        };

    const updateInsightsFilter = async (
        userConfiguration: IUserConfiguration
    ): Promise<IInsightsFiltersResponse> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body = {
                feature: 'INSTANT_INSIGHTS',
                settings: {
                    dateRange: 'ANYTIME',
                    onlineSearchSource: 'INTERNET',
                    userConfiguration: userConfiguration,
                },
            };
            const result: {
                body: IInsightsFiltersResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.updateChatbotFilters,
                retryCount: 3,
            });

            return result.body;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const updateTopicChat = async (
        chatHistoryId: string,
        isBookmarked: boolean,
        vote: 'upvote' | 'downvote' | 'neutral',
        chatBotPrompt: string,
        chatQueryId: string,
        chatAnswerId: string
    ): Promise<boolean> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: TopicChatUpdateRequest = {
                isBookmarked,
                vote,
                chatBotPrompt,
                chatQueryId,
                chatAnswerId,
            };
            const result: {
                body: TopicBookmarksResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PATCH,
                headers,
                body: JSON.stringify(body),
                url: `${Constants.updateTopicChat}/${chatHistoryId}`,
                retryCount: 3,
            });

            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchTopicTrends = async (
        topicSubscriptionIds: string[],
        startDate?: string,
        endDate?: string
    ): Promise<TopicMetric[]> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: TopicTrendsRequest = {
                topicSubscriptionIds: topicSubscriptionIds,
                startDate: startDate || appContext.filters.date.startDate,
                endDate: endDate || appContext.filters.date.endDate,
                categoryId: appContext?.category ? appContext?.category : '',
            };
            const result: {
                body: TopicTrendsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.getTopicTrends,
                retryCount: 3,
            });

            return result.body.data.topicMetrics;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    return {
        getTopicByTopicSubscriptionId,
        fetchHighlights,
        fetchClusterTopicSentiments,
        fetchTopicSentimentAssesment,
        getTopicSentiments,
        fetchTopicStockPrice,
        fetchTopicSourceDistribution,
        fetchTopicSentiments,
        getPredefinedTopics,
        subscribeToTopic,
        fetchQueryArticleResponse,
        fetchQueryResponse,
        fetchTopicTrends,
        fetchLatestSentiments,
        fetchTopicChatFilters,
        fetchInsigtsFilter,
        updateInsightsFilter,
        fetchTopicChatBookmarks,
        updateTopicChat,
        getUserTopics,
    };
};
