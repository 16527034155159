import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';
import { UtilsService } from '../../services/utils.service';

interface IProps {
    series: {
        name: string;
        data: number[];
    }[];
    labels: string[];
}

export const ColumnChart = (props: IProps) => {
    const getMaxYValue = () => {
        const rawMax =
            Math.max(
                ...props.series.reduce(
                    (sums, { data }) => (
                        data.forEach(
                            (num, i) => (sums[i] = (sums[i] || 0) + num)
                        ),
                        sums
                    ),
                    Array(
                        Math.max(...props.series.map(({ data }) => data.length))
                    ).fill(0)
                )
            ) * 1.3;

        // Function to round up to the nearest significant figure
        const roundUpToSignificantFigure = (num: number) => {
            const digits = Math.floor(Math.log10(num));
            const factor = Math.pow(10, digits - 1);
            return Math.ceil(num / factor) * factor;
        };

        return roundUpToSignificantFigure(rawMax);
    };

    const calculateTickAmount = (maxValue: number) => {
        const tickOptions = [1, 2, 4, 5, 10];
        const digits = Math.floor(Math.log10(maxValue));
        const baseTick = Math.pow(10, digits - 1);

        for (let tick of tickOptions) {
            const tickValue = tick * baseTick;
            if (maxValue / tickValue <= 10) {
                return maxValue / tickValue;
            }
        }

        return 5; // Default to 5 ticks if no suitable option is found
    };

    const maxYValue = getMaxYValue();
    const tickAmount = calculateTickAmount(maxYValue);
    const options: ApexCharts.ApexOptions = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '20%',
                // borderRadius: 3,
                // borderRadiusApplication: 'end',
                // borderRadiusWhenStacked: 'last',
            },
        },
        dataLabels: {
            enabled: false,
        },
        // colors: ['#22C0E3', '#545EFF', '#A153B5'],
        colors: ['#635BFF', '#ff6692', '#16CDC7', '#4A772F', '#FF9D00'],
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (value: number) {
                    return value.toString() + ' Credit';
                },
            },
        },
        yaxis: {
            max: maxYValue,
            tickAmount: tickAmount,
            labels: {
                formatter: function (value) {
                    return UtilsService.convertToInternationalCurrencySystem(
                        value
                    );
                },
            },
        },
        grid: {
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        xaxis: {
            categories: props.labels,
        },
        legend: {
            position: 'bottom',
        },
        fill: {
            opacity: 1,
        },
    };
    return (
        <div style={{ maxWidth: '100%' }}>
            <Chart
                options={options}
                type="bar"
                series={props.series}
                height={510}
            />
        </div>
    );
};
