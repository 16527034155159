import { Button, Chip, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { Topic, UserTopic } from '../../../services/interfaces';
import { ContainedBox } from '../../core/box';
import { GroupStage } from '..';
import { Close } from '@mui/icons-material';

interface IProps {
    topics: Topic[];
    handleDelete: (topic: Topic) => void;
    addMoreClick?: () => void;
    stage?: GroupStage;
}

export const SelectedTopicsContainer = (props: IProps) => {
    const handleClick = () => {
        console.info('You clicked the Chip.');
    };

    return (
        <Box
            sx={{
                height: '300px',
            }}
        >
            <Box
                sx={{
                    height: '240px',
                    overflowY: 'auto',
                }}
            >
                {props.topics.length > 0 && (
                    <Grid container>
                        {props.topics.map((topic: Topic, index) => (
                            <Grid key={index} item>
                                <Chip
                                    sx={{
                                        mr: 2,
                                        mt: 2,
                                        borderRadius: '25px',
                                        color: '#29319B',
                                        borderColor: '#29319B',
                                    }}
                                    label={
                                        topic.topicName
                                            ? `${topic.topicName}`
                                            : `${topic.subscriptionName}`
                                    }
                                    onClick={handleClick}
                                    onDelete={() => props.handleDelete(topic)}
                                    deleteIcon={
                                        <Close
                                            sx={{
                                                color: '#DA1E28',
                                                height: '14px',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    }
                                    variant="outlined"
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
        </Box>
    );
};
