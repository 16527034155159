import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Input } from '../../../components/core/input';
import {
    EFileUploadType,
    FileUploadRequest,
    IFileUploadResponse,
    IFileUploadResponseChunkDBUpdateStatusData,
    IFileUploadResponseChunkStreamError,
} from '../../../services/interfaces';
import { useDocumentService } from '../../../services/document.service';
import { useSnackbar } from 'notistack';
import { UtilsService } from '../../../services/utils.service';
import { DocumentInsightsContext } from '../../../providers/document-provider';

interface IProps {
    onClose: (v?: number) => void;
}

export const TextUpload = (props: IProps) => {
    const [fileName, setFileName] = useState('');
    const documentContext = useContext(DocumentInsightsContext);
    const { enqueueSnackbar } = useSnackbar();
    const [processing, setProcessing] = useState<boolean>(false);
    const [content, setContent] = useState('');
    const [error, setError] = useState<string>('');
    const { submitFile, fetchUploadFileResponse } = useDocumentService();
    const onSubmitClick = () => {
        setError('');
        if (fileName.length < 3) {
            setError('Filename should be atleast 3 chars');
            return;
        }
        let data: FileUploadRequest = {
            fileName,
            userEnteredText: content,
            upload: EFileUploadType.TEXT,
        };
        setProcessing(true);
        fetchUploadFileResponse(data, handleFileUploadResponse);
    };

    const handleFileUploadResponse = async (res: string) => {
        let fileId = '';
        let error = false;
        for (let v of res.split('\n')) {
            try {
                let data = JSON.parse(v);
                switch (data.chunkType) {
                    case 'DB_UPDATE_SUCCESS': {
                        fileId = (
                            data.data as IFileUploadResponseChunkDBUpdateStatusData
                        ).userFileId;
                        break;
                    }
                    case 'STREAM_ERROR': {
                        error = true;
                        setError(
                            (data.data as IFileUploadResponseChunkStreamError)
                                .message
                        );
                        break;
                    }
                    case 'TERMINATION': {
                        if (error) {
                            setProcessing(false);
                            enqueueSnackbar('Text Upload Failed', {
                                variant: 'error',
                            });
                        }
                        let res = await submitFile([fileId]);
                        if (res && documentContext) {
                            documentContext.setRefreshCount(
                                documentContext.refreshCount + 1
                            );
                            setProcessing(false);
                            setFileName('');
                            setContent('');
                            enqueueSnackbar(
                                `${UtilsService.trimText(
                                    fileName,
                                    35
                                )} uploaded successfully!`
                            );
                            props.onClose(0);
                        }
                        break;
                    }
                }
            } catch (error) {}
        }
    };
    return (
        <Box>
            <Box minHeight={404}>
                <Input
                    type="text"
                    mt={2}
                    borderRadius={2}
                    value={fileName}
                    noShadow
                    onChange={(e) => {
                        setFileName(e.target.value);
                    }}
                    placeholder="Name your file"
                />
                {error && (
                    <Typography
                        mt={1}
                        textAlign={'left'}
                        fontSize={12}
                        color="error"
                    >
                        {error}
                    </Typography>
                )}
                <Input
                    type="text"
                    mt={3}
                    pt={4}
                    pb={4}
                    multiline
                    rows={10}
                    noShadow
                    borderRadius={2}
                    value={content}
                    onChange={(e) => {
                        setContent(e.target.value);
                    }}
                    placeholder="Enter or copy/paste your text here..."
                />
                <Typography
                    textAlign={'left'}
                    mt={1}
                    fontSize={14}
                    fontWeight={600}
                    color="#A5A6AB"
                >
                    Character Count: {content.length}
                </Typography>
            </Box>
            <Box display="flex" mt={4} justifyContent="right">
                <Button
                    sx={{
                        color: '#4B4B4C',
                        mr: 3,
                        fontSize: 16,
                        fontWeight: 600,
                        height: 36,
                    }}
                    onClick={() => props.onClose()}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    disabled={!fileName || !content || processing}
                    onClick={onSubmitClick}
                    sx={{
                        minWidth: '80px',
                        fontSize: 16,
                        fontWeight: 600,
                        py: 0.5,
                        height: 36,
                    }}
                >
                    {processing ? (
                        <CircularProgress
                            size={25}
                            sx={{
                                color: '#ffffff',
                                ml: 2,
                            }}
                        />
                    ) : (
                        'Submit'
                    )}
                </Button>
            </Box>
        </Box>
    );
};
