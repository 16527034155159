interface IProps {
    height: string;
    width: string;
}
export const MiniLogo = (props: IProps) => (
    <svg
        width={props.width || 20}
        height={props.height || 20}
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        viewBox="0 0 106.000000 80.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g
            transform="translate(0.000000,80.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
        >
            <path d="M300 787 c-118 -34 -209 -109 -258 -215 -24 -50 -27 -69 -27 -162 0 -92 3 -112 26 -160 70 -149 212 -233 386 -230 43 1 94 -1 115 -5 29 -6 40 -3 57 14 42 42 12 111 -49 111 -29 0 -63 -24 -72 -51 -4 -10 -26 -14 -89 -14 -73 0 -92 4 -142 30 -202 101 -253 362 -105 531 158 180 454 135 559 -85 33 -68 37 -179 10 -255 -16 -46 -18 -62 -8 -68 26 -17 37 -6 57 58 77 250 -88 495 -343 510 -42 2 -91 -1 -117 -9z" />
            <path d="M332 475 c-50 -42 -50 -108 0 -155 18 -17 33 -21 71 -19 l48 3 137 -137 137 -137 123 0 122 0 0 90 c0 89 0 90 -25 90 -24 0 -25 -2 -25 -65 l0 -65 -87 0 -88 0 -132 132 c-122 122 -131 133 -122 156 14 37 2 78 -33 107 -39 33 -87 33 -126 0z m104 -50 c29 -45 -5 -85 -61 -71 -51 13 -33 96 20 96 15 0 31 -10 41 -25z" />
            <path d="M874 396 c-38 -38 -43 -72 -17 -116 32 -55 102 -66 152 -24 27 22 31 32 31 74 0 42 -4 52 -31 74 -45 38 -92 35 -135 -8z m112 -41 c15 -23 15 -27 0 -50 -19 -29 -56 -33 -80 -9 -21 20 -20 43 1 66 23 26 59 23 79 -7z" />
        </g>
    </svg>
);
