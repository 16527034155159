import { Box, Breadcrumbs, Skeleton, Typography } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { useContext, useEffect, useState } from 'react';
import { ComparisonTableWrapper } from '../core/table/comparison/table';
import { TableHeadRow } from '../core/table/comparison/head-row';
import { TableHeadCell } from '../core/table/comparison/head-cell';
import { TableBodyRow } from '../core/table/common/row';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { TablePositiveCell } from '../core/table/comparison/positive-cell';
import { TableNegativeCell } from '../core/table/comparison/negative-cell';
import {
    Granularity,
    useInsightsService,
} from '../../services/insights.service';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { PotentialSentiment, Topic } from '../../services/interfaces';
import { TextHighlight } from '../sentiment-highlights';
import { NavigationLink } from '../core/link';
import { AppContext } from '../../providers/app-provider';
import { useArticleService } from '../../services/article.service';
import { useDistributionService } from '../../services/distribution.service';
import { UtilsService } from '../../services/utils.service';
import {
    potentialIndicatorPageClick,
    potentialIndicatorTitleClick,
} from '../../analytics/analytics';
import { useFinancialsService } from '../../services/financials.service';
import { DetailedInformation } from '../../pages/insight/details/detailed-information';
import { IndicatorsTable } from './indicators-table';

interface IProps {
    granularity?: Granularity;
    compact?: boolean;
    topicSubscriptionIds?: string[];
    id?: string;
    sortBy?: string;
    summaryId?: string;
    context: 'TOPIC' | 'ARTICLE' | 'SOURCE_DISTRIBUTION' | 'FINANCIAL';
    metaSearchTerm?: string;
    inbuiltDetail?: boolean;
}

export const PotentialIndicators = (props: IProps) => {
    const { fetchPotentialIndicators } = useInsightsService();
    const [topicName, setTopicName] = useState('');
    const [topicSubscriptionId, setTopicSubscriptionId] = useState('');
    const [date, setDate] = useState('');
    const [id, setId] = useState('');
    const [summaryId, setSummaryId] = useState(0);
    const { fetchFinancialPotentialIndicators } = useFinancialsService();
    const { fetchArticlePotentialIndicators } = useArticleService();
    const { fetchSourceDistributionPotentialIndicators } =
        useDistributionService();
    const [pagesCount, setPagesCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const appContext = useContext(AppContext);
    const [data, setData] = useState<(PotentialSentiment | null)[][] | null>();

    useEffect(() => {
        if (topicSubscriptionId && appContext?.topics.length) {
            let topic = appContext.topics.filter(
                (s: Topic) => s.topicSubscriptionId == topicSubscriptionId
            )[0];
            if (topic) {
                setTopicName(topic.subscriptionName);
            }
        }
    }, [topicSubscriptionId, appContext?.topics]);
    const refetchTopicInsights = async () => {
        if (props.topicSubscriptionIds && props.granularity && props.sortBy) {
            let response: {
                data: (PotentialSentiment | null)[][];
                count: string;
            } = await fetchPotentialIndicators(
                props.topicSubscriptionIds,
                props.granularity,
                currentPage,
                props.sortBy
            );
            setData([...response.data]);
            setPagesCount(Math.ceil(parseInt(response.count) / 10));
        }
    };
    const refetchArticleInsights = async () => {
        if (props.id) {
            let response: {
                data: (PotentialSentiment | null)[][];
                count: string;
            } = await fetchArticlePotentialIndicators(
                props.id,
                currentPage,
                props.summaryId
            );
            setData([...response.data]);

            setPagesCount(Math.ceil(parseInt(response.count) / 10));
        }
    };
    const refetchSourceDistributionInsights = async () => {
        if (props.topicSubscriptionIds && props.metaSearchTerm) {
            let response: {
                data: (PotentialSentiment | null)[][];
                count: string;
            } = await fetchSourceDistributionPotentialIndicators(
                props.topicSubscriptionIds,
                Granularity.ARTICLE,
                props.metaSearchTerm,
                currentPage
            );
            setData([...response.data]);

            setPagesCount(Math.ceil(parseInt(response.count) / 10));
        }
    };

    const refetchFinantialPotentialIndicators = async () => {
        if (props.id) {
            let response: {
                data: (PotentialSentiment | null)[][];
                count: string;
            } = await fetchFinancialPotentialIndicators(props.id, currentPage);
            setData([...response.data]);

            setPagesCount(Math.ceil(parseInt(response.count) / 10));
        }
    };

    const refreshInsights = () => {
        setData(null);
        if (props.context == 'ARTICLE') {
            refetchArticleInsights();
        }
        if (props.context == 'TOPIC') {
            refetchTopicInsights();
        }
        if (props.context == 'FINANCIAL') {
            refetchFinantialPotentialIndicators();
        }
        if (props.context == 'SOURCE_DISTRIBUTION') {
            refetchSourceDistributionInsights();
        }
    };

    const renderDetails = (
        _topicSubscriptionId: string,
        _date: string,

        _id: string,
        _summaryId: number
    ) => {
        setTopicSubscriptionId(_topicSubscriptionId);
        setDate(_date);
        setId(_id);
        setSummaryId(_summaryId);
    };

    useEffect(refreshInsights, [currentPage]);

    useEffect(() => {
        refreshInsights();
        if (currentPage != 1) {
            setCurrentPage(1);
        }
    }, [
        props.granularity,
        props.topicSubscriptionIds,
        props.context,
        props.id,
        props.sortBy,
        appContext?.category,
        appContext?.filters.date,
    ]);

    return (
        <Box>
            {topicSubscriptionId && (
                <Box pt={6}>
                    <Stack spacing={1}>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            <Typography
                                color="#29319B"
                                sx={{
                                    textDecoration: 'none',
                                }}
                            >
                                {topicName}
                            </Typography>
                            <Typography
                                onClick={() => {
                                    setTopicSubscriptionId('');
                                }}
                                color="#29319B"
                                sx={{
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                Potential Indicators
                            </Typography>
                            <Typography
                                color="inherit"
                                sx={{
                                    textDecoration: 'none',
                                }}
                            >
                                Insight
                            </Typography>
                        </Breadcrumbs>
                    </Stack>
                    <Box pt={2}>
                        <Typography variant="h1">Insight collected</Typography>
                        {appContext && (
                            <Typography variant="subtitle1">
                                {UtilsService.formatStringDate(date)}
                            </Typography>
                        )}
                    </Box>
                    <DetailedInformation
                        topicSubscriptionId={topicSubscriptionId}
                        level="article"
                        filter1={id}
                        filter2={summaryId.toString()}
                    />
                </Box>
            )}

            <Box display={topicSubscriptionId ? 'none' : ''}>
                {data && (
                    <IndicatorsTable
                        context={props.context}
                        renderDetails={renderDetails}
                        compact={props.compact}
                        data={data}
                    />
                )}
                {/* <TableContainer>
                    <ComparisonTableWrapper
                        sx={{
                            border: props.compact ? 'none !important' : '',
                        }}
                        aria-label="simple table"
                    >
                        <TableHead
                            sx={{
                                borderTop: props.compact
                                    ? '1px solid #e0e0e0'
                                    : '',
                                backgroundColor: props.compact ? '#F9F9FF' : '',
                            }}
                        >
                            <TableHeadRow>
                                <TableHeadCell>
                                    <Typography
                                        sx={{ color: '#19AF55' }}
                                        variant="subtitle1"
                                    >
                                        Positive
                                    </Typography>
                                </TableHeadCell>
                                <TableHeadCell>
                                    <Typography
                                        sx={{ color: '#D82F44' }}
                                        variant="subtitle1"
                                    >
                                        Negative
                                    </Typography>
                                </TableHeadCell>
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                            {data?.map(
                                (row: (PotentialSentiment | null)[], index) => (
                                    <TableBodyRow key={index}>
                                        <TablePositiveCell
                                            sx={{
                                                borderLeftWidth: props.compact
                                                    ? '2px !important'
                                                    : '10px !important',
                                                padding: '8px 10px',
                                            }}
                                        >
                                            {row[0] && (
                                                <Box pb={1}>
                                                    <Box pb={2}>
                                                        {(props.context ==
                                                            'TOPIC' ||
                                                            props.context ==
                                                                'FINANCIAL') && (
                                                            <Box display="flex">
                                                                <Typography
                                                                    to={`/insight/article/${row[0].topicSubscriptionId}/${row[0].date}/${row[0].id}/${row[0].summaryId}`}
                                                                    component={
                                                                        props.inbuiltDetail
                                                                            ? Box
                                                                            : NavigationLink
                                                                    }
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    color="#29319B"
                                                                    fontSize={
                                                                        props.compact
                                                                            ? 12
                                                                            : 14
                                                                    }
                                                                    onClick={() => {
                                                                        if (
                                                                            row[0]
                                                                        ) {
                                                                            if (
                                                                                props.inbuiltDetail
                                                                            ) {
                                                                                renderDetails(
                                                                                    row[0]
                                                                                        .topicSubscriptionId,
                                                                                    row[0]
                                                                                        .date,
                                                                                    row[0]
                                                                                        .id,
                                                                                    row[0]
                                                                                        .summaryId
                                                                                );
                                                                            }
                                                                            potentialIndicatorTitleClick(
                                                                                row[0].summaryId.toString(),
                                                                                row[0]
                                                                                    .id,
                                                                                row[0]
                                                                                    .topicSubscriptionId,
                                                                                row[0]
                                                                                    .topicName,
                                                                                `/insight/article/${row[0].topicSubscriptionId}/${row[0].date}/${row[0].id}/${row[0].summaryId}`
                                                                            );
                                                                        }
                                                                    }}
                                                                    variant="subtitle2"
                                                                >
                                                                    {
                                                                        row[0]
                                                                            .topicName
                                                                    }
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        borderLeft:
                                                                            '1px solid #ccc',
                                                                        ml: 2,
                                                                        pl: 2,
                                                                        color: '#8D8D8D',
                                                                    }}
                                                                    fontSize={
                                                                        props.compact
                                                                            ? 12
                                                                            : 14
                                                                    }
                                                                    variant="subtitle2"
                                                                >
                                                                    {UtilsService.formatStringDateForPotentialIndicators(
                                                                        row[0]
                                                                            .date
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                        {(props.context ==
                                                            'ARTICLE' ||
                                                            props.context ==
                                                                'SOURCE_DISTRIBUTION') && (
                                                            <Typography variant="subtitle2">
                                                                {
                                                                    row[0]
                                                                        .sourceName
                                                                }
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                    <Typography
                                                        text={row[0].summary}
                                                        textFontSize={
                                                            props.compact
                                                                ? '12px'
                                                                : '14px'
                                                        }
                                                        highlight={
                                                            row[0]
                                                                .highlightIndexes
                                                        }
                                                        collapsable={
                                                            props.compact
                                                        }
                                                        component={
                                                            TextHighlight
                                                        }
                                                        textVariant="caption"
                                                    ></Typography>
                                                    {!props.compact &&
                                                        Array.isArray(
                                                            row[0].sources
                                                        ) && (
                                                            <Box>
                                                                <Typography
                                                                    color="#4B4B4C"
                                                                    variant="caption"
                                                                >
                                                                    Sources: [
                                                                    {row[0].sources.join(
                                                                        ', '
                                                                    )}
                                                                    ]
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                   
                                                </Box>
                                            )}
                                        </TablePositiveCell>
                                        <TableNegativeCell
                                            sx={{
                                                borderLeftWidth: props.compact
                                                    ? '2px !important'
                                                    : '10px !important',
                                                padding: '8px 10px',
                                            }}
                                        >
                                            {row[1] && (
                                                <Box>
                                                    <Box pb={2}>
                                                        {(props.context ==
                                                            'TOPIC' ||
                                                            props.context ==
                                                                'FINANCIAL') && (
                                                            <Box display="flex">
                                                                <Typography
                                                                    to={`/insight/article/${row[1].topicSubscriptionId}/${row[1].date}/${row[1].id}/${row[1].summaryId}`}
                                                                    component={
                                                                        props.inbuiltDetail
                                                                            ? Box
                                                                            : NavigationLink
                                                                    }
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    color="#29319B"
                                                                    fontSize={
                                                                        props.compact
                                                                            ? 12
                                                                            : 14
                                                                    }
                                                                    onClick={() => {
                                                                        if (
                                                                            row[1]
                                                                        ) {
                                                                            if (
                                                                                props.inbuiltDetail
                                                                            ) {
                                                                                renderDetails(
                                                                                    row[1]
                                                                                        .topicSubscriptionId,
                                                                                    row[1]
                                                                                        .date,
                                                                                    row[1]
                                                                                        .id,
                                                                                    row[1]
                                                                                        .summaryId
                                                                                );
                                                                            }
                                                                            potentialIndicatorTitleClick(
                                                                                row[1].summaryId.toString(),
                                                                                row[1]
                                                                                    .id,
                                                                                row[1]
                                                                                    .topicSubscriptionId,
                                                                                row[1]
                                                                                    .topicName,
                                                                                `/insight/article/${row[1].topicSubscriptionId}/${row[1].date}/${row[1].id}/${row[1].summaryId}`
                                                                            );
                                                                        }
                                                                    }}
                                                                    variant="subtitle2"
                                                                >
                                                                    {
                                                                        row[1]
                                                                            .topicName
                                                                    }
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        borderLeft:
                                                                            '1px solid #ccc',
                                                                        ml: 2,
                                                                        pl: 2,
                                                                        color: '#8D8D8D',
                                                                    }}
                                                                    fontSize={
                                                                        props.compact
                                                                            ? 12
                                                                            : 14
                                                                    }
                                                                    variant="subtitle2"
                                                                >
                                                                    {UtilsService.formatStringDateForPotentialIndicators(
                                                                        row[1]
                                                                            .date
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                        {(props.context ==
                                                            'ARTICLE' ||
                                                            props.context ==
                                                                'SOURCE_DISTRIBUTION') && (
                                                            <Typography variant="subtitle2">
                                                                {
                                                                    row[1]
                                                                        .sourceName
                                                                }
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                    <Box>
                                                        <Typography
                                                            text={
                                                                row[1].summary
                                                            }
                                                            textFontSize={
                                                                props.compact
                                                                    ? '12px'
                                                                    : '14px'
                                                            }
                                                            highlight={
                                                                row[1]
                                                                    .highlightIndexes
                                                            }
                                                            collapsable={
                                                                props.compact
                                                            }
                                                            component={
                                                                TextHighlight
                                                            }
                                                            textVariant="caption"
                                                        ></Typography>
                                                        {!props.compact &&
                                                            Array.isArray(
                                                                row[1].sources
                                                            ) && (
                                                                <Box>
                                                                    <Typography
                                                                        color="#4B4B4C"
                                                                        variant="caption"
                                                                    >
                                                                        Sources:
                                                                        [
                                                                        {row[1].sources.join(
                                                                            ', '
                                                                        )}
                                                                        ]
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                        
                                                    </Box>
                                                </Box>
                                            )}
                                        </TableNegativeCell>
                                    </TableBodyRow>
                                )
                            )}
                            {!data &&
                                [1, 2, 3, 4, 5, 6].map(() => (
                                    <TableBodyRow>
                                        <TablePositiveCell
                                            sx={{
                                                borderLeftWidth: props.compact
                                                    ? '2px !important'
                                                    : '10px !important',
                                            }}
                                        >
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: '1rem' }}
                                            />
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: '1rem' }}
                                            />
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: '1rem' }}
                                            />
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: '1rem' }}
                                            />
                                        </TablePositiveCell>
                                        <TableNegativeCell
                                            sx={{
                                                borderLeftWidth: props.compact
                                                    ? '2px !important'
                                                    : '10px !important',
                                            }}
                                        >
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: '1rem' }}
                                            />
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: '1rem' }}
                                            />
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: '1rem' }}
                                            />
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: '1rem' }}
                                            />
                                        </TableNegativeCell>
                                    </TableBodyRow>
                                ))}
                        </TableBody>
                    </ComparisonTableWrapper>
                </TableContainer> */}
                <Box sx={{ mt: 2 }}>
                    <Stack spacing={2}>
                        <Pagination
                            sx={{ mr: 'auto' }}
                            count={pagesCount}
                            size="small"
                            page={currentPage}
                            onChange={(e, p) => {
                                potentialIndicatorPageClick(p);
                                setCurrentPage(p);
                            }}
                            renderItem={(item) => (
                                <PaginationItem
                                    slots={{
                                        previous: ArrowBackIcon,
                                        next: ArrowForwardIcon,
                                    }}
                                    {...item}
                                />
                            )}
                        />
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
};
