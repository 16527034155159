interface IProps {
    color: string;
    size?: number;
}

export const Add = (props: IProps) => {
    return (
        <svg
            width={props.size || '10'}
            height={props.size || '10'}
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.9902 5C10.9902 5.39449 10.6704 5.71429 10.2759 5.71429H6.70452V9.28571C6.70452 9.6802 6.38472 10 5.99023 10V10C5.59575 10 5.27595 9.6802 5.27595 9.28571V5.71429H1.70452C1.31003 5.71429 0.990234 5.39449 0.990234 5V5C0.990234 4.60551 1.31003 4.28571 1.70452 4.28571H5.27595V0.714286C5.27595 0.319797 5.59575 0 5.99023 0V0C6.38472 0 6.70452 0.319797 6.70452 0.714286V4.28571H10.2759C10.6704 4.28571 10.9902 4.60551 10.9902 5V5Z"
                fill={props.color}
            />
        </svg>
    );
};
