import { Article } from '@mui/icons-material';
import { Box, Typography, Divider } from '@mui/material';
import React from 'react';
import { NavigationLink } from '../../../components/core/link';
import { NewTab } from '../../../components/icons/new-tab';
import { SentimentChangeArrow } from '../../../components/icons/sentiment-change-arrow';
import {
    HeadlineArticle,
    IRecentHeadlines,
    RecentHeadlinesResponseDataElement,
    ReportModule,
} from '../../../services/interfaces';
import { UtilsService } from '../../../services/utils.service';
import { SentimentChange } from '../../cir/cir-dashboard/group-details/sentiment-plot/topic-sentiment-plot';
import { BorderedBox } from '../../../components/core/box';
import { NewsImagePlaceholder } from '../../../components/images/news-image-placeholder';
import { NoData } from './no-data';

interface IEntityArticleDataProps {
    d: HeadlineArticle;
}

export const EntityArticleData = (props: IEntityArticleDataProps) => {
    const { d } = props;
    return (
        <Box width={'100%'}>
            {d && (
                <Typography fontSize={14} fontWeight={400}>
                    {d.summary}
                </Typography>
            )}
            <Box mt={2} justifyContent={'space-between'} display="flex">
                {
                    <Box height={16} display="flex">
                        <Typography
                            color="#4B4B4C"
                            variant="caption"
                            pr={2}
                            mr={2}
                            sx={
                                d?.sourceName
                                    ? { borderRight: '1px solid #ccc' }
                                    : {}
                            }
                        >
                            {d
                                ? UtilsService.formatStringDateInMDYFormat(
                                      d?.timestamp
                                  )
                                : ''}
                        </Typography>

                        <Typography color="#4B4B4C" pr={1} variant="caption">
                            {d?.sourceName}
                        </Typography>

                        {/* {!d?.source && (
                            <Typography
                                color="#4B4B4C"
                                pr={1}
                                variant="caption"
                            >
                                <Skeleton width={130} variant="text" />
                            </Typography>
                        )} */}
                    </Box>
                }
            </Box>
        </Box>
    );
};

interface IProps {
    data: ReportModule;
}

export const RecentHeadlines = (props: IProps) => {
    const { data } = props;
    const onImageError = (e: any, name: string) => {
        e.target.src =
            'https://ui-avatars.com/api/?font-size=.35&color=ffffff&background=c7c7cb&name=' +
            name;
    };
    return (
        <BorderedBox
            sx={{
                backgroundColor: '#F9F9FF',
            }}
            pb={4}
            my={4}
        >
            <Typography px={4} pt={4} variant="h4">
                Recent headlines
            </Typography>
            <Box
                className="scrollable"
                sx={{
                    maxHeight: 'calc(100vh - 220px)',
                    overflow: 'auto',
                }}
                px={4}
            >
                {!data.articles?.length && <NoData />}
                {data.articles?.map((d: HeadlineArticle, index: number) => {
                    return (
                        <Box key={index} py={2} width={'100%'}>
                            <Box
                                sx={{
                                    '&:hover': {
                                        backgroundColor: '#f9f9ff',
                                    },
                                }}
                            >
                                <Box display="flex">
                                    <img
                                        onError={(event) =>
                                            onImageError(event, d.sourceName)
                                        }
                                        style={{
                                            marginTop: 5,
                                            width: 14,
                                            height: 14,
                                            borderRadius: '8px',
                                        }}
                                        src={d.icon}
                                    />
                                    <Typography
                                        to={d.url}
                                        sx={{
                                            textDecoration: 'none',
                                            color: '#29319B',
                                            ml: 1,
                                        }}
                                        target="_blank"
                                        component={NavigationLink}
                                        typography={'h6'}
                                    >
                                        {d.title}
                                    </Typography>
                                </Box>
                                <Box pt={2} width={'100%'} display="flex">
                                    <Box mr={3} pt={1} maxWidth={64}>
                                        {d.imageUrl ? (
                                            <img
                                                onError={(event) =>
                                                    onImageError(
                                                        event,
                                                        d.sourceName
                                                    )
                                                }
                                                style={{
                                                    width: 64,
                                                    height: 64,
                                                    borderRadius: '8px',
                                                }}
                                                src={d.imageUrl}
                                            />
                                        ) : (
                                            <NewsImagePlaceholder />
                                        )}
                                        {/* <NewsImagePlaceholder /> */}
                                    </Box>
                                    <EntityArticleData d={d} />
                                </Box>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </BorderedBox>
    );
};
