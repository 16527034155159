import React from 'react';
import { PILogo } from '../icons/pi-logo';
import { Box } from '@mui/material';

export const IncomingMessageIcon = () => {
    return (
        <Box
            sx={{
                backgroundColor: '#29319B',
                height: 28,
                width: 28,
                paddingTop: 1.25,
                paddingLeft: 1.25,
                borderRadius: '50%',
            }}
        >
            <PILogo size={18} />
        </Box>
    );
};
