import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';
import { UtilsService } from '../../services/utils.service';

interface MixedChartProps {
    categories: string[];
    series: {
        name: string;
        type: string;
        data: { x: string | undefined; y: number[] | number | undefined }[];
    }[];
    markers: {
        seriesIndex: number;
        dataPointIndex: number;
        fillColor: string;
        strokeColor: string;
        size: number;
        shape?: string;
    }[];
    seriesTitles: string[];
    chartTitle: string;
    xaxisTitle: string;
    yaxisTitle: string;
    markerColors: string[];
}

const MixedChart: React.FC<MixedChartProps> = ({
    categories,
    series,
    markers,
    seriesTitles,
    chartTitle,
    xaxisTitle,
    yaxisTitle,
    markerColors,
}) => {
    const options: ApexOptions = {
        chart: {
            height: 350,
            type: 'boxPlot',
            toolbar: {
                show: false,
            },
        },
        title: {
            text: chartTitle,
        },
        xaxis: {
            categories: categories,
            title: {
                text: xaxisTitle,
            },
            tooltip: {
                enabled: false,
            },
            labels: {
                formatter: (value) => {
                    return value ? value.split('\n') : '';
                },
            },
        },
        yaxis: {
            forceNiceScale: true,
            title: {
                text: yaxisTitle,
            },
            labels: {
                formatter: (val) => {
                    return val
                        ? UtilsService.convertToInternationalCurrencySystem(val)
                        : '';
                },
            },
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
            padding: {
                top: 0,
                right: 30,
                bottom: 0,
                left: 30,
            },
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                const category = w.globals.categoryLabels[dataPointIndex];
                const boxPlotData =
                    w.globals.initialSeries[0].data[dataPointIndex].y;
                const yearAgoEpsData =
                    w.globals.initialSeries[1].data[dataPointIndex].y;
                return `<div style="padding: 5px;">
                            <div class="apexcharts-tooltip-title">${category}</div>
                            Min: ${UtilsService.convertToInternationalCurrencySystem(
                                boxPlotData[0]
                            )}
                            <br/>
                            Avg: ${UtilsService.convertToInternationalCurrencySystem(
                                boxPlotData[2]
                            )}
                            <br/>
                            Max: ${UtilsService.convertToInternationalCurrencySystem(
                                boxPlotData[4]
                            )}
                            <br/>
                           Year ago EPS: ${UtilsService.convertToInternationalCurrencySystem(
                               yearAgoEpsData
                           )}
                        </div>`;
            },
        },
        markers: {
            size: 6,
            width: 10,
            height: 5,
            //@ts-ignore
            discrete: markers,
        },
        legend: {
            show: true,
            markers: {
                fillColors: markerColors,
            },
            formatter: (seriesName: string, opts: any) => {
                return seriesTitles[opts.seriesIndex];
            },
        },
    };

    return (
        <div>
            <Chart
                options={options}
                series={series}
                type="boxPlot"
                height={350}
            />
        </div>
    );
};

export default MixedChart;
