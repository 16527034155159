import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useChatWidgetService } from '../../../../services/chat-widget.service';
import { IChatbotConfig } from '../../../../services/interfaces';
import { DEFAULT_CHATBOT_CONFIG } from '../settings/default';
const { REACT_APP_WIDGET_HOST } = process.env;

declare const window: any;

export const ChatbotDemo = () => {
    const { chatbotId } = useParams();
    const { getChatbotById } = useChatWidgetService();
    const [isFetched, setIsFetched] = useState(false);

    const initializeChatbot = async () => {
        if (isFetched) {
            return;
        }
        setIsFetched(true);
        //@ts-ignore
        if (!chatbotId || !window.photon) return;
        setIsFetched(true);
        if (chatbotId != 'new') {
            setIsFetched(true);
            let data: IChatbotConfig = await getChatbotById(chatbotId);
            //@ts-ignore
            setTimeout(() => {
                data.withCitations = data.dataSourceConfig.withCitations;
                //@ts-ignore
                window.photon.updateChatbotConfig(data);
            }, 1000);
        } else if (chatbotId == 'new') {
            setIsFetched(true);
            setTimeout(() => {
                //@ts-ignore

                window.photon.updateChatbotConfig(DEFAULT_CHATBOT_CONFIG);
            }, 1000);
        }
    };

    useEffect(() => {
        if (!window.photon) {
            //@ts-ignore
            window.photon = {};
        }
        window.photon.getChatbotId = () => chatbotId;
    }, [chatbotId]);

    useEffect(() => {
        //@ts-ignore
        if (!window.photon) {
            //@ts-ignore
            window.photon = {};
        }
        window.photon.getChatbotId = () => chatbotId;
        //@ts-ignore
        window.photon.onLoad = initializeChatbot.bind(this);
        if (!document.getElementById('photon-chat-widget')) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `${REACT_APP_WIDGET_HOST}inject.js`;
            document.head.appendChild(script);
        }
    }, [isFetched]);
    return <Box id="photon-chat-widget-container"></Box>;
};
