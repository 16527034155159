import { useContext } from 'react';
import { Constants } from '../constants';
import { AppContext } from '../providers/app-provider';
import {
    INetworkResponse,
    NetworkRequestMethod,
    useNetworkAPI,
} from './network.service';
import { db } from '../utils/db';
import { UtilsService } from './utils.service';
import {
    AddOnForPurchase,
    IActiveSubscriptionResponse,
    IActiveSubscriptionResponseData,
    IAddOn,
    IAddOnsListResponse,
    ICheckoutResponse,
    IDownlodInvoiceResponse,
    IInvoice,
    IInvoicesResponse,
    IPaymentCard,
    IPaymentDetailsResponse,
    IProratedAmountResponse,
    IProratedAmountResponseData,
    ISubscription,
    ISubscriptionsResponse,
    IUsageHistoryResponse,
    IUsageHistoryResponseData,
    IUsageOverviewResponse,
    IUsageOverviewResponseData,
    IUsersUsageList,
    IUsersUsageResponse,
} from './interfaces';

export const useBillingService = () => {
    const { triggerRequest, triggerUploadRequest } = useNetworkAPI();
    const appContext = useContext(AppContext);

    const fetchActiveSubscription =
        async (): Promise<IActiveSubscriptionResponseData> => {
            try {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const result: {
                    body: IActiveSubscriptionResponse;
                    headers: { [key: string]: string };
                } = await triggerRequest(
                    {
                        method: NetworkRequestMethod.GET,
                        headers,
                        url: Constants.activeSubscription,
                        retryCount: 3,
                    },
                    ['x-pi-content-length']
                );
                return result.body.data;
            } catch (error: any) {
                console.error(error);
                throw error;
            }
        };

    const fetchAvailableAddons = async (): Promise<IAddOn[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IAddOnsListResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.GET,
                    headers,
                    url: Constants.addOnTypes,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.data.products;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchPaymentDetails = async (): Promise<IPaymentCard[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IPaymentDetailsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.GET,
                    headers,
                    url: Constants.paymentDetails,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.data.cards;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchInvoices = async (): Promise<IInvoice[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IInvoicesResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.GET,
                    headers,
                    url: Constants.fetchInvoices,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.data.invoices;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchSubscriptions = async (): Promise<ISubscription[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: ISubscriptionsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.GET,
                    headers,
                    url: Constants.subscriptionPlans,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.data.plans;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const processCheckoutUrl = async (url: string) => {
        window.open(url, '_self');
    };

    const addPaymentMethod = async (): Promise<void> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: ICheckoutResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify({
                        cancel_url:
                            'https://app.photoninsights.com/my-account?tab=PLANS_BILLING&subTab=BILLING_METHOD&success=false',
                        success_url:
                            'https://app.photoninsights.com/my-account?tab=PLANS_BILLING&subTab=BILLING_METHOD&success=true',
                    }),
                    url: Constants.addPaymentDetails,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            await processCheckoutUrl(result.body.data.checkout_url);
            return;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const buySubscription = async (plan: string): Promise<any> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: ICheckoutResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify({
                        plan,
                        cancel_url:
                            'https://app.photoninsights.com/my-account?tab=PLANS_BILLING&subTab=SUBSCRIPTIONS&success=false',
                        success_url:
                            'https://app.photoninsights.com/my-account?tab=PLANS_BILLING&subTab=SUBSCRIPTIONS&success=true',
                    }),
                    url: Constants.buySubscription,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.data.checkout_url) {
                await processCheckoutUrl(result.body.data.checkout_url);
            } else {
                return result.body;
            }
            return;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const downloadInvoice = async (
        invoiceId: string
    ): Promise<IDownlodInvoiceResponse> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IDownlodInvoiceResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.GET,
                    headers,
                    url: `${Constants.downloadInvoice}${invoiceId}`,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchProratedAmount = async (
        plan: string
    ): Promise<IProratedAmountResponseData> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IProratedAmountResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify({
                        plan,
                    }),
                    url: Constants.fetchProratedAmount,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );

            return result.body.data;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const setPaymentMethodAsDefault = async (
        paymentMethodId: string
    ): Promise<INetworkResponse> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify({
                        payment_method_id: paymentMethodId,
                    }),
                    url: Constants.setDefaultPaymentMethod,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );

            return result.body;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const deletePaymentMethod = async (
        paymentMethodId: string
    ): Promise<INetworkResponse> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.DELETE,
                    headers,
                    url: `${Constants.deletePaymentMethod}?payment_method_id=${paymentMethodId}`,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );

            return result.body;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const purchaseAddOn = async (
        products: AddOnForPurchase[]
    ): Promise<void> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: ICheckoutResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify({
                        products: products,
                        cancel_url:
                            'https://app.photoninsights.com/my-account?tab=PLANS_BILLING&subTab=SUBSCRIPTIONS&success=false',
                        success_url:
                            'https://app.photoninsights.com/my-account?tab=PLANS_BILLING&subTab=SUBSCRIPTIONS&success=true',
                    }),
                    url: Constants.buyProducts,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            await processCheckoutUrl(result.body.data.checkout_url);
            return;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    return {
        fetchActiveSubscription,
        fetchAvailableAddons,
        fetchPaymentDetails,
        addPaymentMethod,
        purchaseAddOn,
        fetchInvoices,
        fetchSubscriptions,
        buySubscription,
        fetchProratedAmount,
        setPaymentMethodAsDefault,
        deletePaymentMethod,
        downloadInvoice,
    };
};
