export const OneDriveIcon = (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="40" height="40" rx="4" fill="white" />
        <g clip-path="url(#clip0_5673_32005)">
            <path
                d="M15.1689 14.4808L23.4929 19.4656L28.4528 17.3778C29.461 16.9423 30.5477 16.7184 31.6459 16.7198C31.8293 16.7198 32.0102 16.7285 32.1898 16.7397C31.5937 14.4142 30.335 12.3121 28.5665 10.6887C26.7981 9.06524 24.5962 7.9905 22.2284 7.59505C19.8606 7.19961 17.4289 7.50051 15.2289 8.46116C13.0289 9.42182 11.1553 11.0009 9.83594 13.0064L9.96232 13.0026C11.8017 13.0002 13.6051 13.5123 15.1689 14.4808Z"
                fill="#0364B8"
            />
            <path
                d="M15.1873 14.4848C13.6231 13.5164 11.8193 13.0048 9.97958 13.0078L9.85319 13.0115C8.05284 13.0341 6.29265 13.5467 4.76175 14.4944C3.23084 15.442 1.98707 16.7889 1.16408 18.3903C0.341091 19.9917 -0.0300152 21.7871 0.0906339 23.5835C0.211283 25.38 0.819127 27.1096 1.84886 28.5865L9.18658 25.4976L12.4503 24.1247L19.7149 21.0667L23.5089 19.4708L15.1873 14.4848Z"
                fill="#0078D4"
            />
            <path
                d="M32.1872 16.762C32.0062 16.7494 31.8247 16.7427 31.6433 16.7422C30.5454 16.7413 29.4591 16.9656 28.4514 17.4014L23.4902 19.488L24.9288 20.3491L29.6434 23.1729L31.7003 24.4045L38.7344 28.6174C39.3735 27.431 39.7048 26.1032 39.6977 24.7556C39.6907 23.408 39.3457 22.0837 38.6942 20.904C38.0427 19.7244 37.1056 18.727 35.9688 18.0034C34.832 17.2797 33.5317 16.8529 32.1872 16.762Z"
                fill="#1490DF"
            />
            <path
                d="M31.7175 24.399L29.6606 23.1674L24.946 20.3423L23.5087 19.4824L19.7135 21.0783L12.4489 24.1363L9.18394 25.5092L1.84375 28.5982C2.7561 29.9098 3.97237 30.9811 5.38868 31.7205C6.80498 32.46 8.37923 32.8456 9.97694 32.8445H31.6605C33.1145 32.845 34.5415 32.4518 35.7901 31.7065C37.0386 30.9613 38.062 29.8919 38.7516 28.6118L31.7175 24.399Z"
                fill="#28A8EA"
            />
        </g>
        <defs>
            <clipPath id="clip0_5673_32005">
                <rect
                    width="40"
                    height="25"
                    fill="white"
                    transform="translate(0 7.5)"
                />
            </clipPath>
        </defs>
    </svg>
);
