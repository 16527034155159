import { Box, Grid } from '@mui/material';
import { PriceChart } from './price-chart';
import { FinancialAllFilingsResponseDataElement } from '../../../../../services/interfaces';
import { FinancialInfoResponseResult } from '../../../live-insights/financial-info-interface';
import { Financialtrends } from './financial-trends';
import { Matrix } from './matrix';
import { Ownership } from './ownership';
import { AllFilings } from './all-filings';
import { ErrorBoundary } from '../../../../../utils/error-boundary';
import { Trading } from './trading';
interface IProps {
    companyData: FinancialInfoResponseResult;
    openFiling: (filing: FinancialAllFilingsResponseDataElement) => void;
    openSearch: () => void;
    query: string;
}
export const CompanyDetails = (props: IProps) => {
    let topicSubscriptionId = props.companyData.topicSubscriptionId;
    return (
        <Box pt={6}>
            <Grid spacing={6} container>
                <Grid item sm={7}>
                    <ErrorBoundary>
                        <PriceChart
                            openSearch={props.openSearch}
                            topicSubscriptionId={topicSubscriptionId}
                            companyData={props.companyData}
                        />
                    </ErrorBoundary>
                </Grid>
                <Grid item sm={5}>
                    <ErrorBoundary>
                        <Financialtrends
                            data={props.companyData.financial_trends}
                        />
                    </ErrorBoundary>
                </Grid>
            </Grid>
            <Box my={6}>
                <Matrix
                    data={{
                        valuationMetrics: props.companyData.valuation_metrics,
                        profitabilityMetrics:
                            props.companyData.profitability_metrics,
                        liquidityAndSolvencyMetrics:
                            props.companyData.liquidity_and_solvency_metrics,
                        cashFlowMetrics: props.companyData.cash_flow_metrics,
                    }}
                />
            </Box>
            <Grid spacing={6} container>
                <Grid item sm={8}>
                    <ErrorBoundary>
                        <Ownership
                            data={{
                                institutionOwnership:
                                    props.companyData.institution_ownership,
                            }}
                        />
                    </ErrorBoundary>
                </Grid>
                <Grid item sm={4}>
                    <AllFilings
                        openFiling={props.openFiling}
                        topicSubscriptionId={topicSubscriptionId}
                        query={props.query}
                    />
                </Grid>
            </Grid>
            <Box mt={6}>
                <ErrorBoundary>
                    <Trading
                        data={{
                            insiderTrading:
                                props.companyData.insider_transactions,
                        }}
                    />
                </ErrorBoundary>
            </Box>
        </Box>
    );
};
