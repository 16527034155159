export const PlansQuotaData = [
    {
        group: 'Usage Limits (Monthly)',
        items: [
            {
                feature: 'AI Message Credits',
                free: '25',
                basic: '1,500',
                pro: '5,000',
                enterprise: 'Custom',
            },
            {
                feature: 'Word Count',
                free: '20,000',
                basic: '3,000,000',
                pro: '10,000,000',
                enterprise: 'Custom',
            },
            {
                feature: 'Chatbots',
                free: '-',
                basic: '5',
                pro: '10',
                enterprise: 'Unlimited',
            },
            {
                feature: 'Photon Web Extension Calls',
                free: '50',
                basic: '500',
                pro: '5000',
                enterprise: 'Unlimited',
            },
            {
                feature: 'Diligence Reports',
                free: '-',
                basic: '-',
                pro: '5',
                enterprise: 'Custom',
            },
        ],
    },
    {
        group: 'Feature - Photon Live',
        items: [
            {
                feature: 'Custom Domain Filters (Photon Live)',
                free: 'yes',
                basic: 'yes',
                pro: 'yes',
                enterprise: 'yes',
            },
            {
                feature: 'Chat across Web and Docs (Photon Live)',
                free: '-',
                basic: 'yes',
                pro: 'yes',
                enterprise: 'yes',
            },
            {
                feature: 'Insights Mode (Photon Live)',
                free: '-',
                basic: '-',
                pro: 'yes',
                enterprise: 'yes',
            },
            {
                feature: 'Slack/MS Teams Integration',
                free: '-',
                basic: '-',
                pro: '-',
                enterprise: 'yes',
            },
        ],
    },
    {
        group: 'Feature - Document Insights',
        items: [
            {
                feature: 'Supported File Types (Document Insights)',
                free: 'non-scanned pdfs, doc, docx, ppt, txt, images',
                basic: 'non-scanned pdfs, doc, docx, ppt, txt, images',
                pro: 'non-scanned pdfs, doc, docx, ppt, txt, images, audio/video files',
                enterprise: 'yes',
            },
            {
                feature: 'Max File Size (Document Insights)',
                free: '10MB',
                basic: '50MB',
                pro: '100MB',
                enterprise: '500MB',
            },
            {
                feature: 'Elastic Search across Files (Document Insights)',
                free: '-',
                basic: 'yes',
                pro: 'yes',
                enterprise: 'yes',
            },
            {
                feature: 'Web Crawling',
                free: '-',
                basic: 'yes',
                pro: 'yes',
                enterprise: 'yes',
            },
            {
                feature: 'Sync with One Drive, Sharepoint etc',
                free: '-',
                basic: '-',
                pro: 'yes',
                enterprise: 'yes',
            },
            {
                feature: 'Slack/MS Teams Integration',
                free: '-',
                basic: '-',
                pro: '-',
                enterprise: 'yes',
            },
        ],
    },
    {
        group: 'Feature - Photon Chat',
        items: [
            {
                feature: 'Embed on Websites',
                free: '-',
                basic: 'Unlimited',
                pro: 'Unlimited',
                enterprise: 'Unlimited',
            },
            {
                feature: 'Conversation History',
                free: '-',
                basic: '30 Days',
                pro: '1 year',
                enterprise: 'Unlimited',
            },
            {
                feature: 'Lead Capturing',
                free: '-',
                basic: 'yes',
                pro: 'yes',
                enterprise: 'yes',
            },
            {
                feature: 'Export Leads',
                free: '-',
                basic: '-',
                pro: 'yes',
                enterprise: 'yes',
            },
            {
                feature: 'Remove Branding',
                free: '-',
                basic: '-',
                pro: '-',
                enterprise: 'yes',
            },
        ],
    },
    {
        group: 'Feature - Diligence',
        items: [
            {
                feature: 'Report Templates',
                free: '-',
                basic: '-',
                pro: 'Preset',
                enterprise: 'Custom',
            },
            {
                feature: 'Data Sources',
                free: '-',
                basic: '-',
                pro: 'Web Data',
                enterprise: 'Web + Internal Data',
            },
            {
                feature: 'Whitelabelling',
                free: '-',
                basic: '-',
                pro: '-',
                enterprise: 'yes',
            },
        ],
    },
    {
        group: 'AI Models',
        items: [
            {
                feature: 'GPT 4o Mini',
                free: 'yes',
                basic: 'yes',
                pro: 'yes',
                enterprise: 'yes',
            },
            {
                feature: 'GPT 4o',
                free: '-',
                basic: '-',
                pro: 'yes',
                enterprise: 'yes',
            },
            {
                feature: 'GPT 4 Turbo',
                free: '-',
                basic: '-',
                pro: 'yes',
                enterprise: 'yes',
            },
            {
                feature: 'Own API Key',
                free: '-',
                basic: '-',
                pro: '-',
                enterprise: 'yes',
            },
        ],
    },
    {
        group: 'Security and Support',
        items: [
            {
                feature: 'SOC2 and ISO Compliance',
                free: 'yes',
                basic: 'yes',
                pro: 'yes',
                enterprise: 'yes',
            },
            {
                feature: 'SOC2 and ISO Certificate',
                free: '-',
                basic: '-',
                pro: '-',
                enterprise: 'yes',
            },
            {
                feature: 'Uptime SLA',
                free: '-',
                basic: '-',
                pro: '-',
                enterprise: 'yes',
            },
            {
                feature: 'Support',
                free: 'Email',
                basic: 'Email',
                pro: 'Guaranteed 48 hour (email)',
                enterprise: 'Dedicated support team',
            },
        ],
    },
    {
        group: 'Others',
        items: [
            {
                feature: 'API Access',
                free: '-',
                basic: 'yes',
                pro: 'yes',
                enterprise: 'Custom',
            },
        ],
    },
];
