import { Box, Button, Typography } from '@mui/material';
import { BorderedBox } from '../../../components/core/box';
import { MSTeams } from '../../../components/icons/ms-teams';
import { Link } from 'react-router-dom';
import { Authorize } from '../../../components/authorize';
import { FeatureName, SubFeatureName } from '../../../services/interfaces';
import { usePermissions } from '../../../providers/permissions-provider';

export const MSTeamsIntegration = () => {
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    return (
        <BorderedBox
            mt={6}
            p={6}
            borderRadius="16px !important"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
            <Box width="70%">
                <MSTeams height={24} width={26} />
                <Typography variant="h4" mt={2}>
                    Microsoft Teams
                </Typography>
                <Typography
                    mt={3}
                    fontSize="14px"
                    lineHeight="24px"
                    letterSpacing="0.16px"
                >
                    Our Microsoft Teams integration allows you to bring the
                    power of Photon's AI bot directly into your Teams workspace.
                    This integration enables you to query and interact with your
                    documents without ever leaving Teams.
                </Typography>
            </Box>
            <Authorize
                placement="left"
                featureName={FeatureName.LiveQA}
                subFeatureName={SubFeatureName.TeamsLive}
            >
                <Button
                    disabled={
                        !isSubFeatureAuthorized(
                            FeatureName.LiveQA,
                            SubFeatureName.TeamsDocu
                        )
                    }
                    sx={{
                        border: '1px solid #29319B',
                        borderRadius: 2,
                        width: 160,
                        fontSize: 14,
                        opacity: isSubFeatureAuthorized(
                            FeatureName.LiveQA,
                            SubFeatureName.TeamsDocu
                        )
                            ? 1
                            : 0.5,
                        fontWeight: 600,
                    }}
                    startIcon={<MSTeams height={19} width={20} />}
                    component={Link}
                    to="https://teams.microsoft.com/l/chat/0/0?users=28:7bf032ad-bf10-4fa1-aa09-e45f76b8ed6c"
                    target="_blank"
                >
                    Add to Teams
                </Button>
            </Authorize>
        </BorderedBox>
    );
};
