import { useContext } from 'react';
import { Constants } from '../constants';
import { AppContext } from '../providers/app-provider';
import {
    IDiligenceReport,
    IDiligenceReportEntity,
    IDiligenceReportListResponse,
    IDiligenceReportResponse,
    IDiligenceTemplate,
    IDiligenceTemplateConfiguration,
    IDiligenceTemplateConfigurationResponse,
    IDiligenceTemplateConfigurationResponseData,
    IDiligenceTemplateData,
    IDiligenceTemplateListResponse,
    IWhiteLableS3Link,
    IWhiteLableS3LinkResponse,
    WidgetImageUploadLinkResponse,
    WidgetImageUploadLinkResponseData,
} from './interfaces';
import {
    INetworkResponse,
    NetworkRequestMethod,
    useNetworkAPI,
} from './network.service';
import { db } from '../utils/db';
import { UtilsService } from './utils.service';

export const useDiligenceService = () => {
    const { triggerRequest, triggerUploadRequest } = useNetworkAPI();
    const appContext = useContext(AppContext);

    const fetchDiligencetemplates = async (): Promise<IDiligenceTemplate[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IDiligenceTemplateListResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.GET,
                    headers,
                    url: Constants.diliginceTemplatesList,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.data.templates;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchDefaultConfiguration =
        async (): Promise<IDiligenceTemplateConfigurationResponseData> => {
            try {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const result: {
                    body: IDiligenceTemplateConfigurationResponse;
                    headers: { [key: string]: string };
                } = await triggerRequest(
                    {
                        method: NetworkRequestMethod.GET,
                        headers,
                        url: Constants.diliginceTemplatesDefaultConfiguration,
                        retryCount: 3,
                    },
                    ['x-pi-content-length']
                );
                return result.body.data;
            } catch (error: any) {
                console.error(error);
                throw error;
            }
        };

    const fetchTemplateConfiguration = async (
        id: string
    ): Promise<IDiligenceTemplateConfigurationResponseData> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IDiligenceTemplateConfigurationResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.GET,
                    headers,
                    url: `${Constants.diliginceTemplatesConfiguration}/${id}`,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.data;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const downloadReport = async (id: string, format: string) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: {
                    data: { pdfUrl: string; docUrl: string };
                };
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.downloadDiligenceReport}/${id}`,
                retryCount: 3,
            });
            window.open(
                format == 'DOC'
                    ? result.body.data.docUrl
                    : result.body.data.pdfUrl,
                '_blank'
            );
            return;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const deleteReport = async (id: string): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.DELETE,
                    headers,
                    url: `${Constants.deleteDiligenceReport}/${id}`,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const deleteTemplateConfiguration = async (
        id: string
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.DELETE,
                    headers,
                    url: `${Constants.deleteDiliginceTemplateConfiguration}/${id}`,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchDiligenceReports = async (): Promise<
        IDiligenceReportEntity[]
    > => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IDiligenceReportListResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.GET,
                    headers,
                    url: Constants.fetchUserDiligenceReport,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.data.reports;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchDiligenceReport = async (
        id: string
    ): Promise<IDiligenceReport> => {
        try {
            try {
                await db.init();
                const fetchedData = await db.getData(id);
                if (fetchedData) {
                    return fetchedData.data;
                }
            } catch (error) {}
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IDiligenceReportResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.GET,
                    headers,
                    url: `${Constants.fetchDiligenceReport}/${id}`,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            let data: IDiligenceReport = result.body.data;
            try {
                await db.init();
                const dataToSave = { id, data };
                await db.addData(dataToSave);
            } catch (error) {}
            return result.body.data;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const generateReport = async (
        companyList: string[],
        templateId?: string
    ) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify({
                        useDefault: !templateId,
                        companies: companyList,
                        id: templateId,
                    }),
                    url: Constants.createDiligenceReport,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const getUploadLink = async (): Promise<IWhiteLableS3Link> => {
        const headers = {
            'Content-Type': 'application/json',
        };
        const result: {
            body: IWhiteLableS3LinkResponse;
            headers: { [key: string]: string };
        } = await triggerRequest({
            method: NetworkRequestMethod.GET,
            headers,
            url: Constants.diligenceWhiteLabelImageLink,
            retryCount: 3,
        });
        return result.body.data;
    };

    const uploadImage = async (file: File): Promise<string> => {
        const uploadLinkData = await getUploadLink();
        const result: {
            body: { status: boolean };
            headers: { [key: string]: string };
        } = await triggerUploadRequest({
            method: NetworkRequestMethod.PUT,
            body: file,
            url: uploadLinkData.imagePresignedUrl,
            retryCount: 3,
        });
        return uploadLinkData.s3Link;
    };

    const updateTemplateConfiguration = async (
        data: IDiligenceTemplateData
    ) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            data.configurations = await Promise.all(
                data.configurations.map(async (m) => {
                    if (
                        m.sectionTemplate.imagePresignedUrl &&
                        m.sectionTemplate.imagePresignedUrl.includes(
                            'data:image'
                        )
                    ) {
                        const fileDate = UtilsService.dataURLtoFile(
                            m.sectionTemplate.imagePresignedUrl,
                            m.sectionTemplate.diligenceSection
                        );
                        if (fileDate) {
                            let imageUrl = await uploadImage(fileDate.file);
                            m.sectionTemplate.uploadedImageS3Link = imageUrl;
                            m.sectionTemplate.imagePresignedUrl = undefined;
                        }
                    }
                    return m;
                })
            );

            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.PATCH,
                    headers,
                    body: JSON.stringify(data),
                    url: Constants.updateDiliginceTemplateConfiguration,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    return {
        fetchDiligencetemplates,
        fetchDefaultConfiguration,
        fetchTemplateConfiguration,
        updateTemplateConfiguration,
        deleteTemplateConfiguration,
        fetchDiligenceReport,
        fetchDiligenceReports,
        generateReport,
        downloadReport,
        deleteReport,
    };
};
