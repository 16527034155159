export const FilterChecked = (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="16" height="16" rx="2" fill="#29319B" />
        <path
            d="M6 12.4L2 8.4L3.4 7L6 9.6L12.6 3L14 4.4L6 12.4Z"
            fill="white"
        />
    </svg>
);
