import React from 'react';
import { Box, Typography } from '@mui/material';
import { BorderedBox } from '../components/core/box';
import { SomethingWentWrong } from '../components/icons/something-went-wrong';

interface Props {
    children: React.ReactNode;
}

interface State {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        console.log({ error, errorInfo });
    }

    render(): React.ReactNode {
        return this.state.hasError ? (
            <BorderedBox
                m={6}
                minHeight={400}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Box>{SomethingWentWrong}</Box>
                <Typography variant="h4">
                    Oops! Something went wrong.
                </Typography>
                <Typography fontSize="14px">
                    The Information is not available currently. We are working
                    on it.
                </Typography>
                <Typography fontSize="14px">
                    We appreciate your patience.
                </Typography>
            </BorderedBox>
        ) : (
            this.props.children
        );
    }
}
