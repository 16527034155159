import { IIOSRequest, IIOSResponse } from '../../interfaces';

declare const window: any;

export class IOSBridgeService {
    constructor() {
        if (!window.iosCallbacks) {
            window.iosCallbacks = {};
            window.handleiOSResponse = this.handleiOSResponse.bind(this);
        }
    }

    handleiOSResponse = (reqData: string) => {
        console.log(reqData);
        const data: IIOSResponse = JSON.parse(reqData);

        window.iosCallbacks[data.requestId] &&
            window.iosCallbacks[data.requestId](data.data);
    };

    callBridge = (request: IIOSRequest, callback: Function) => {
        window.iosCallbacks[request.requestId] = callback;
        window.webkit.messageHandlers.jsHandler.postMessage(
            JSON.stringify(request)
        );
    };

    requestBridge = (request: IIOSRequest): Promise<any> => {
        return new Promise((resolve, reject) => {
            this.callBridge(request, (data: any) => {
                resolve(data);
            });
        });
    };
}
