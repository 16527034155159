import { Box } from '@mui/material';
import { NoDataFound } from '../../../components/images/no-data-found';

export const NoData = () => {
    return (
        <Box my={10} textAlign={'center'}>
            {NoDataFound}
        </Box>
    );
};
