import Cookies from 'js-cookie';
import { IPlatformInterface } from '../../interfaces/platform.interface';
import { IOSBridgeService } from './ios.bridge.service';
import { UtilsService } from '../../utils.service';
import { IOSBridgeFunctions } from '../../interfaces';

export class IOSPlatformService implements IPlatformInterface {
    bridge = new IOSBridgeService();
    getCookie = async (key: string) => {
        let data = await this.bridge.requestBridge({
            requestId: UtilsService.uuidv4(),
            functionName: IOSBridgeFunctions.COOKIE_GET,
            data: {
                cookieName: key,
            },
        });
        return data.cookieValue;
    };
    setCookie = async (key: string, value: string) => {
        return await this.bridge.requestBridge({
            requestId: UtilsService.uuidv4(),
            functionName: IOSBridgeFunctions.COOKIE_SET,
            data: {
                cookieName: key,
                cookieValue: value,
            },
        });
    };
}
