import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { renderToString } from 'react-dom/server';
import { Box, Typography } from '@mui/material';
import { UtilsService } from '../../services/utils.service';
import dayjs from 'dayjs';
export interface IProps {
    labels: string[];
    price: number[];
    volumePositive: number[];
    volumeNegative: number[];
}

export const StockChart = (props: IProps) => {
    let options: ApexOptions = {
        chart: {
            height: 300,
            type: 'area',
            stacked: false,
            sparkline: {
                enabled: false,
            },
            toolbar: {
                show: false,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: [],
                },
            },
            animations: {
                enabled: true,
                easing: 'easein',
                speed: 300,
                animateGradually: {
                    enabled: true,
                },
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '1px',
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [1, 1, 2],
            curve: ['smooth', 'smooth', 'smooth'],
        },
        xaxis: {
            type: 'category',
            categories: [...props.labels],

            labels: {
                style: {
                    fontSize: '1px',
                },
                formatter: function (val) {
                    return new Date(val).getDate().toString();
                },
            },
            tickPlacement: 'between',
            group: {
                style: {
                    fontSize: '10px',
                    fontWeight: 400,
                },
                groups: [...UtilsService.generateDateGroups(props.labels)],
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: [
            {
                min: Math.min(...props.volumePositive) / 2,
                max: Math.max(...props.volumePositive) * 5,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#008FFB',
                },
                labels: {
                    style: {
                        colors: '#008FFB',
                    },
                },

                title: {
                    text: 'Income (thousand crores)',
                    style: {
                        color: '#008FFB',
                    },
                },
                tooltip: {
                    enabled: true,
                },
                show: false,
            },
            {
                min: Math.min(...props.volumeNegative) / 2,
                max: Math.max(...props.volumeNegative) * 5,
                seriesName: 'Income',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#00E396',
                },
                labels: {
                    style: {
                        colors: '#00E396',
                    },
                },
                title: {
                    text: 'Operating Cashflow (thousand crores)',
                    style: {
                        color: '#00E396',
                    },
                },
                show: false,
            },
            {
                min: Math.min(...props.price) / 1.2,
                max: Math.max(...props.price) * 1,
                seriesName: 'Revenue',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#FEB019',
                },
                labels: {
                    style: {
                        colors: '#FEB019',
                    },
                },
                title: {
                    text: 'Revenue (thousand crores)',
                    style: {
                        color: '#FEB019',
                    },
                },
                show: false,
            },
        ],
        tooltip: {
            followCursor: true,

            // custom: function({series, seriesIndex, dataPointIndex, w}) {
            //     var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            //     return '<ul>' +
            //     '<li><b>Price</b>: ' + data.x + '</li>' +
            //     '<li><b>Number</b>: ' + data.y + '</li>' +
            //     '<li><b>Product</b>: \'' + data.product + '\'</li>' +
            //     '<li><b>Info</b>: \'' + data.info + '\'</li>' +
            //     '<li><b>Site</b>: \'' + data.site + '\'</li>' +
            //     '</ul>';
            //   }
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return renderToString(
                    <div style={{ display: 'flex', padding: '2px 10px' }}>
                        <div>
                            <p
                                style={{
                                    margin: 0,
                                    fontSize: 14,
                                    fontWeight: 600,
                                }}
                            >
                                $
                                {parseFloat(series[2][dataPointIndex]).toFixed(
                                    2
                                )}
                            </p>
                            <p
                                style={{
                                    margin: 0,
                                    fontSize: 12,
                                    fontWeight: 400,
                                    color: '#4B4B4C',
                                }}
                            >
                                {UtilsService.formatStringDate(
                                    props.labels[dataPointIndex]
                                )}
                            </p>
                        </div>
                        <div style={{ marginLeft: '10px' }}>
                            <p
                                style={{
                                    margin: 0,
                                    fontSize: 14,
                                    fontWeight: 600,
                                }}
                            >
                                {UtilsService.convertToInternationalCurrencySystem(
                                    series[0][dataPointIndex]
                                        ? series[0][dataPointIndex]
                                        : series[1][dataPointIndex]
                                )}
                            </p>
                            <p
                                style={{
                                    margin: 0,
                                    fontSize: 12,
                                    fontWeight: 400,
                                    color: '#4B4B4C',
                                }}
                            >
                                Volume
                            </p>
                        </div>
                    </div>
                );
            },
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                inverseColors: true,
                shade: 'light',
                type: 'vertical',
                shadeIntensity: 0.5,
                opacityFrom: 1,
                opacityTo: 0,
                colorStops: [
                    [
                        {
                            offset: 0,
                            color: '#19AF55',
                            opacity: 1,
                        },
                    ],
                    [
                        {
                            offset: 0,
                            color: '#D82F44',
                            opacity: 1,
                        },
                    ],
                    [
                        {
                            offset: 0,
                            color: '#E59413',
                            opacity: 0.5,
                        },
                        {
                            offset: 50,
                            color: '#E59413',
                            opacity: 0.25,
                        },
                        {
                            offset: 100,
                            color: '#E59413',
                            opacity: 0,
                        },
                    ],
                ],
            },
        },
        colors: ['#19AF55', '#D82F44', '#E59413'],
        legend: {
            show: false,
            horizontalAlign: 'left',
            offsetX: 40,
        },
    };

    let series = [
        {
            name: 'Income',
            type: 'column',
            data: [...props.volumePositive],
        },
        {
            name: 'Cashflow',
            type: 'column',
            data: [...props.volumeNegative],
        },
        {
            name: 'Revenue',
            type: 'area',
            data: [...props.price],
        },
    ];

    return (
        <div>
            <Chart options={options} series={series} type="line" height={250} />
        </div>
    );
};
