import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
export interface IProps {
    series: number[];
    colors: string[];
    labels: string[];
}

export const PieChart = (props: IProps) => {
    const [count, setCount] = useState(0);

    let options: ApexOptions = {
        chart: {
            type: 'donut',
        },
        colors: props.colors,
        labels: props.labels,
        dataLabels: {
            formatter: (val: number, opts) => {
                return props.series[opts.seriesIndex];
            },
        },
        legend: {
            show: false,
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (
                    value,
                    { series, seriesIndex, dataPointIndex, w }
                ) => {
                    // Calculate percentage
                    return `${props.series[seriesIndex]}`;
                },
                title: {
                    formatter: (seriesName) => seriesName,
                },
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: 'Analysts',
                            color: '#000',
                            formatter: () => {
                                return props.series
                                    .reduce((a, b) => a + b, 0)
                                    .toString();
                            },
                        },
                    },
                },
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    useEffect(() => {
        if (props.series) setCount(count + 1);
    }, [props.series]);

    return (
        <div>
            <Chart
                options={options}
                type="donut"
                key={count}
                series={props.series}
                height={320}
            />
        </div>
    );
};
