import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
export interface IProps {
    value: number;
    color: string;
    height: number;
    fontSize?: number;
}

export const SemiRadialChart = (props: IProps) => {
    let options: ApexOptions = {
        chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
                enabled: true,
            },
        },
        stroke: {
            lineCap: 'round',
        },
        colors: [props.color],
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                hollow: {
                    size: '60%',
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        offsetY: -2,
                        fontSize: `${props.fontSize ? props.fontSize : 24}px`,
                    },
                },
                track: {
                    strokeWidth: '90%',
                    background: '#f2f2f2',
                },
            },
        },
    };

    return (
        <div>
            <Chart
                options={options}
                type="radialBar"
                series={[props.value]}
                height={props.height}
            />
        </div>
    );
};
