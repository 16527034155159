import {
    AnalystRecommendation,
    ReportModule,
} from '../../../../../services/interfaces';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import { BorderedBox } from '../../../../../components/core/box';
import { PieChart } from '../../../../../components/charts/pie-chart';
import { useEffect, useState } from 'react';
import SingleSelect from '../../../../../components/core/input/single-select';
import { Circle } from '@mui/icons-material';
interface IProps {
    data: ReportModule;
}
const legendItems = [
    { label: 'Strongly Buy', color: '#00695c' },
    { label: 'Buy', color: '#8bc34a' },
    { label: 'Hold', color: '#ffeb3b' },
    { label: 'Underperform', color: '#ff9800' },
    { label: 'Sell', color: '#f44336' },
];
export const AnalystRecommendations = (props: IProps) => {
    const [selectedRecommendation, setSelectedRecommendation] = useState<
        AnalystRecommendation | undefined
    >(undefined);
    const [series, setSeries] = useState<number[]>([]);
    const [recommendations, setRecommendations] = useState<
        AnalystRecommendation[] | undefined
    >(props.data.data?.yahoo.coreFinancials.analystRecommendations);
    useEffect(() => {
        setRecommendations(
            props.data.data?.yahoo.coreFinancials.analystRecommendations
        );
    }, [props.data]);
    useEffect(() => {
        if (recommendations && recommendations.length) {
            setSelectedRecommendation(recommendations[0]);
        }
    }, [recommendations]);
    useEffect(() => {
        if (selectedRecommendation)
            setSeries([
                ...[
                    selectedRecommendation.strongBuy,
                    selectedRecommendation.buy,
                    selectedRecommendation.hold,
                    selectedRecommendation.sell,
                    selectedRecommendation.strongSell,
                ],
            ]);
    }, [selectedRecommendation]);
    return (
        <BorderedBox p={4}>
            <Box display="flex" justifyContent={'space-between'}>
                <Typography variant="h6">Analyst Recommendations</Typography>
                {recommendations && (
                    <SingleSelect
                        getOptionText={(v: AnalystRecommendation) =>
                            v ? `${v.month} ${v.year}` : ''
                        }
                        disableCheckbox
                        placeholder="Auto Open Behaviour"
                        pt={0.5}
                        pb={2}
                        mt={0}
                        fontSize={14}
                        options={recommendations}
                        selectedValue={selectedRecommendation}
                        onChange={(v: AnalystRecommendation) => {
                            setSelectedRecommendation(v);
                        }}
                    />
                )}
            </Box>
            {selectedRecommendation && (
                <Box justifyContent={'space-between'} display={'flex'}>
                    <Box sx={{ width: 'calc(100% - 170px)' }}>
                        <PieChart
                            labels={[
                                'Strongly Buy',
                                'Buy',
                                'Hold',
                                'Sell',
                                'Strong Sell',
                            ]}
                            colors={[
                                '#027B66',
                                '#81A949',
                                '#FFD648',
                                '#EA7035',
                                '#D60A22',
                            ]}
                            series={series}
                        />
                    </Box>
                    <Box mt={5}>
                        <Typography pl={5} variant="h6">
                            {selectedRecommendation.month}{' '}
                            {selectedRecommendation.year}
                        </Typography>
                        <List>
                            {legendItems.map((item, index) => (
                                <ListItem sx={{ py: 1 }} key={index}>
                                    <ListItemIcon>
                                        <Circle style={{ color: item.color }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            sx: { fontSize: 12, ml: 1 },
                                        }}
                                        primary={item.label}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            )}
        </BorderedBox>
    );
};
