export interface IProps {
    size: number;
}
export const Doc = (props: IProps) => (
    <svg
        width={props.size}
        height={props.size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_5931_30563"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
        >
            <rect width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_5931_30563)">
            <path
                d="M4.20513 14.3312C3.86838 14.3312 3.58333 14.2145 3.35 13.9812C3.11667 13.7479 3 13.4628 3 13.1261V2.86968C3 2.53293 3.11667 2.24788 3.35 2.01455C3.58333 1.78122 3.86838 1.66455 4.20513 1.66455H9.00128C9.16196 1.66455 9.31645 1.69575 9.46473 1.75815C9.61302 1.82054 9.74187 1.90643 9.85128 2.01583L12.6487 4.81323C12.7581 4.92265 12.844 5.0515 12.9064 5.19978C12.9688 5.34807 13 5.50256 13 5.66323V7.69657H12V5.66452H9.60257C9.43078 5.66452 9.28741 5.60703 9.17245 5.49207C9.05748 5.37711 9 5.23374 9 5.06195V2.66453H4.20513C4.15384 2.66453 4.10683 2.6859 4.06408 2.72863C4.02135 2.77138 3.99998 2.8184 3.99998 2.86968V13.1261C3.99998 13.1773 4.02135 13.2244 4.06408 13.2671C4.10683 13.3098 4.15384 13.3312 4.20513 13.3312H7.75638V14.3312H4.20513ZM11.8923 9.69402L12.4615 10.2568L9.88457 12.8235V13.5235H10.5846L13.1615 10.9568L13.7205 11.5158L11.1051 14.1376C11.0427 14.2 10.9752 14.2457 10.9025 14.2748C10.8299 14.3038 10.7534 14.3184 10.673 14.3184H9.39098C9.31065 14.3184 9.24036 14.2893 9.1801 14.2312C9.11984 14.1731 9.08972 14.1017 9.08972 14.0171V12.7479C9.08972 12.6675 9.10424 12.591 9.1333 12.5184C9.16236 12.4457 9.20808 12.3782 9.27048 12.3158L11.8923 9.69402ZM13.7205 11.5158L11.8923 9.69402L12.7628 8.82352C12.8764 8.71412 13.0181 8.65942 13.1878 8.65942C13.3574 8.65942 13.497 8.71412 13.6064 8.82352L14.591 9.80812C14.7004 9.91752 14.7551 10.057 14.7551 10.2267C14.7551 10.3964 14.7004 10.538 14.591 10.6517L13.7205 11.5158Z"
                fill="#000033"
            />
        </g>
    </svg>
);
