export const DropzoneIcon = (
    <svg
        width="147"
        height="102"
        viewBox="0 0 147 102"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_6292_161903)">
            <path
                d="M14.2188 38.4258L28.0602 74.2218"
                stroke="#9C9FD0"
                stroke-width="2.25"
                stroke-linecap="round"
            />
            <path
                d="M60.7578 32.3203L70.4515 57.3895"
                stroke="#9C9FD0"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M18.5078 28.4551L36.1677 21.4343"
                stroke="#9C9FD0"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M37.8984 78.5938L66.1542 67.3605"
                stroke="#9C9FD0"
                stroke-width="2.25"
                stroke-linecap="round"
            />
            <path
                d="M70.4468 57.389C71.8493 60.9585 69.6844 65.9557 66.1524 67.3599"
                stroke="#9C9FD0"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M28.0611 74.238C29.4459 77.8193 34.3626 79.9965 37.8946 78.5923"
                stroke="#9C9FD0"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M14.2173 38.4216C12.8345 34.8455 15.0125 29.9398 18.5136 28.4559"
                stroke="#9C9FD0"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M60.7349 32.3431L36.1641 21.4336"
                stroke="#9C9FD0"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M40.3267 32.1774C41.7148 35.7349 46.6425 37.9303 50.1602 36.5318"
                stroke="#9C9FD0"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M40.3185 32.1775L36.1641 21.4336"
                stroke="#9C9FD0"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M50.1562 36.5312L60.7522 32.3188"
                stroke="#9C9FD0"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M35.125 71.4297L45.7209 67.2172"
                stroke="#9C9FD0"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M32.3594 64.2676L50.0192 57.2468"
                stroke="#9C9FD0"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M29.5859 57.1055L40.1819 52.893"
                stroke="#9C9FD0"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <mask
            id="mask0_6292_161903"
            maskUnits="userSpaceOnUse"
            x="54"
            y="1"
            width="93"
            height="98"
        >
            <path
                d="M89.0156 1.30859L146.363 35.3553L112.217 98.8462L54.8703 64.7994L89.0156 1.30859Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_6292_161903)">
            <path
                d="M129.153 25.3533L126.235 24.5086L90.2861 14.1024L87.3682 13.2577C84.4176 12.4072 81.4175 14.1737 80.6618 17.2067L68.1482 67.669C67.3992 70.7039 69.18 73.8545 72.1289 74.7118L72.1365 74.7139L113.906 86.8052L113.913 86.8074C116.864 87.6579 119.864 85.8913 120.62 82.8584L133.134 32.396C133.883 29.3612 132.102 26.2105 129.153 25.3533Z"
                fill="#9C9FD0"
            />
            <path
                d="M128.759 26.9312L125.303 25.9308L90.4294 15.8356L86.9734 14.8351C84.8721 14.2295 82.7356 15.4876 82.1975 17.6473L69.684 68.1107C69.1507 70.2718 70.4188 72.5156 72.5188 73.126L72.5264 73.1282L114.296 85.2199L114.304 85.2222C116.405 85.8278 118.542 84.5698 119.08 82.4099L131.593 31.9467C132.127 29.7854 130.859 27.5418 128.759 26.9312Z"
                fill="white"
            />
            <path
                d="M118.878 39.6691L97.8352 33.5915C97.3459 33.4497 97.051 32.9271 97.1765 32.4243C97.3018 31.9223 97.7991 31.6297 98.2879 31.7703L119.331 37.848C119.82 37.9902 120.114 38.5128 119.989 39.0155C119.863 39.517 119.366 39.8094 118.878 39.6691Z"
                fill="#9C9FD0"
            />
            <path
                d="M121.744 43.811L97.047 36.6785C96.5575 36.5377 96.2617 36.0158 96.3861 35.5128C96.5107 35.0098 97.0085 34.7162 97.498 34.8571L97.4997 34.8576L122.196 41.9901C122.686 42.1313 122.981 42.6536 122.856 43.1564C122.731 43.6593 122.233 43.9523 121.744 43.811Z"
                fill="#9C9FD0"
            />
            <path
                d="M115.356 53.5731L94.313 47.4954C93.8235 47.3546 93.5275 46.8326 93.652 46.3296C93.7765 45.8266 94.2743 45.533 94.7638 45.6739L94.7656 45.6744L115.808 51.7519C116.298 51.8925 116.594 52.4144 116.47 52.9175C116.345 53.4206 115.848 53.7144 115.358 53.5737L115.356 53.5731Z"
                fill="#9C9FD0"
            />
            <path
                d="M118.228 57.7192L93.5314 50.5868C93.042 50.4459 92.7461 49.924 92.8705 49.421C92.9951 48.9181 93.4928 48.6245 93.9824 48.7653L93.9841 48.7657L118.681 55.8982C119.17 56.0396 119.465 56.5618 119.34 57.0647C119.215 57.5675 118.717 57.8605 118.228 57.7192Z"
                fill="#9C9FD0"
            />
            <path
                d="M92.0243 36.8471L84.5511 34.6837C84.3158 34.6152 84.1736 34.3638 84.2334 34.1216L85.9301 27.2798C85.9903 27.0377 86.2297 26.8967 86.4652 26.9646L93.9385 29.1279C94.1738 29.1964 94.3159 29.4478 94.2561 29.69L92.5596 36.5319C92.4993 36.774 92.2599 36.915 92.0243 36.8471Z"
                fill="#9C9FD0"
            />
            <path
                d="M88.4931 50.7415L81.0199 48.5781C80.7845 48.5098 80.6424 48.2583 80.7022 48.0161L82.3988 41.1743C82.4591 40.9322 82.6985 40.7913 82.934 40.8591L90.4073 43.0225C90.6425 43.0909 90.7847 43.3423 90.7249 43.5845L89.0284 50.4264C88.9681 50.6684 88.7287 50.8094 88.4931 50.7415Z"
                fill="#9C9FD0"
            />
            <path
                d="M84.9541 64.644L77.4808 62.4806C77.2454 62.4121 77.1033 62.1607 77.1631 61.9185L78.8597 55.0766C78.9201 54.8346 79.1595 54.6936 79.3949 54.7614L86.8682 56.9249C87.1036 56.9933 87.2457 57.2447 87.1859 57.487L85.4893 64.3288C85.429 64.5709 85.1895 64.7118 84.9541 64.644Z"
                fill="#9C9FD0"
            />
            <path
                d="M111.838 67.4698L90.7953 61.3922C90.3061 61.2499 90.0116 60.727 90.1376 60.2244C90.2631 59.7232 90.7596 59.4309 91.2479 59.571L112.291 65.6487C112.78 65.7901 113.075 66.3124 112.95 66.8153C112.825 67.3181 112.327 67.6111 111.838 67.4698Z"
                fill="#9C9FD0"
            />
            <path
                d="M114.705 71.6161L90.0088 64.4836C89.5191 64.3433 89.2228 63.8216 89.3468 63.3185C89.4709 62.8153 89.9684 62.5213 90.4581 62.6616L90.4612 62.6626L115.158 69.7951C115.647 69.9364 115.943 70.4587 115.818 70.9616C115.693 71.4643 115.195 71.7573 114.705 71.6161Z"
                fill="#9C9FD0"
            />
        </g>
        <mask
            id="mask1_6292_161903"
            maskUnits="userSpaceOnUse"
            x="38"
            y="0"
            width="76"
            height="88"
        >
            <path d="M38.5234 0H113.074V87.8969H38.5234V0Z" fill="white" />
        </mask>
        <g mask="url(#mask1_6292_161903)">
            <path
                d="M105.421 0H46.1085C41.9214 0.00489222 38.5282 3.51555 38.5234 7.84794V79.9065C38.5282 84.2387 41.9214 87.7495 46.1085 87.7544H105.421C109.608 87.7495 113.001 84.2387 113.006 79.9065V7.84794C113.001 3.51555 109.608 0.00489222 105.421 0Z"
                fill="#9C9FD0"
            />
            <path
                d="M105.409 2.25781H46.0968C43.1151 2.26124 40.6988 4.76133 40.6953 7.84652V79.905C40.6988 82.9901 43.1151 85.4902 46.0968 85.4936H105.409C108.391 85.4902 110.807 82.9901 110.81 79.905V7.84652C110.807 4.76133 108.391 2.26124 105.409 2.25781Z"
                fill="white"
            />
            <path
                d="M96.8577 41.4049H66.3488C65.6392 41.4057 65.0633 40.8113 65.0625 40.0771C65.0617 39.3428 65.6362 38.7469 66.3458 38.7461H66.3488H96.8577C97.5673 38.7469 98.1419 39.3428 98.141 40.0771C98.1402 40.81 97.5662 41.4039 96.8577 41.4049Z"
                fill="#9C9FD0"
            />
            <path
                d="M102.158 45.8931H66.3488C65.6392 45.894 65.0633 45.2995 65.0625 44.5654C65.0617 43.8312 65.6362 43.2354 66.3458 43.2344H66.3488H102.158C102.867 43.2344 103.442 43.8296 103.442 44.5638C103.442 45.2979 102.867 45.8931 102.158 45.8931Z"
                fill="#9C9FD0"
            />
            <path
                d="M96.8561 61.5121H66.3472C65.6376 61.5121 65.0625 60.9171 65.0625 60.1829C65.0625 59.4487 65.6376 58.8535 66.3472 58.8535H96.8561C97.5657 58.8535 98.141 59.4487 98.141 60.1829C98.141 60.9171 97.5657 61.5121 96.8561 61.5121Z"
                fill="#9C9FD0"
            />
            <path
                d="M102.156 66.0006H66.3472C65.6376 66.0006 65.0625 65.4053 65.0625 64.6712C65.0625 63.937 65.6376 63.3418 66.3472 63.3418H102.156C102.866 63.3418 103.441 63.937 103.441 64.6712C103.441 65.4053 102.866 66.0006 102.156 66.0006Z"
                fill="#9C9FD0"
            />
            <path
                d="M59.5065 47.9255H48.7099C48.3697 47.9252 48.0941 47.64 48.0938 47.2883V37.3443C48.0941 36.9926 48.3697 36.7074 48.7099 36.707H59.5065C59.8467 36.7074 60.1223 36.9926 60.1226 37.3443V47.2883C60.1223 47.64 59.8467 47.9252 59.5065 47.9255Z"
                fill="#9C9FD0"
            />
            <path
                d="M59.5065 68.0331H48.7099C48.3697 68.0328 48.0941 67.7476 48.0938 67.3957V57.4519C48.0941 57.1 48.3697 56.8149 48.7099 56.8145H59.5065C59.8467 56.8149 60.1223 57.1 60.1226 57.4519V67.3957C60.1223 67.7476 59.8467 68.0328 59.5065 68.0331Z"
                fill="#9C9FD0"
            />
            <path
                d="M96.8872 19.0238H74.3942C73.6847 19.0238 73.1094 18.4286 73.1094 17.6945C73.1094 16.9603 73.6847 16.3652 74.3942 16.3652H96.8872C97.5968 16.3652 98.1721 16.9603 98.1721 17.6945C98.1721 18.4286 97.5968 19.0238 96.8872 19.0238Z"
                fill="#CCCCCC"
            />
            <path
                d="M102.187 23.5102H74.3942C73.6847 23.5102 73.1094 22.9151 73.1094 22.1809C73.1094 21.4468 73.6847 20.8516 74.3942 20.8516H102.187C102.897 20.8516 103.472 21.4468 103.472 22.1809C103.472 22.9151 102.897 23.5102 102.187 23.5102Z"
                fill="#CCCCCC"
            />
            <path
                d="M69.4152 28.9473H48.6786C48.3385 28.9469 48.0628 28.6617 48.0625 28.3098V11.5652C48.0628 11.2133 48.3385 10.9281 48.6786 10.9277H69.4152C69.7553 10.9281 70.0308 11.2133 70.0313 11.5652V28.3098C70.0308 28.6617 69.7553 28.9469 69.4152 28.9473Z"
                fill="#29319B"
            />
        </g>
        <path
            d="M92.7527 100.001C85.2651 100.001 79.1953 93.6555 79.1953 85.8277C79.1953 77.9999 85.2651 71.6543 92.7527 71.6543"
            fill="white"
        />
        <path
            d="M92.7527 100.001C85.2651 100.001 79.1953 93.6555 79.1953 85.8277C79.1953 77.9999 85.2651 71.6543 92.7527 71.6543"
            stroke="#29319B"
            stroke-width="2.25"
            stroke-linecap="round"
        />
        <path
            d="M92.7578 71.6543C100.245 71.6543 106.315 77.9999 106.315 85.8277C106.315 93.6555 100.245 100.001 92.7578 100.001"
            fill="white"
        />
        <path
            d="M92.7578 71.6543C100.245 71.6543 106.315 77.9999 106.315 85.8277C106.315 93.6555 100.245 100.001 92.7578 100.001"
            stroke="#29319B"
            stroke-width="2.25"
            stroke-linecap="round"
        />
        <path d="M92.7578 92.1256V80.3145V92.1256Z" fill="white" />
        <path
            d="M92.7578 92.1256V80.3145"
            stroke="#29319B"
            stroke-width="2.25"
            stroke-linecap="round"
        />
        <path
            d="M92.7489 80.3145L98.0212 85.8263L92.7489 80.3145ZM92.7489 80.3145L87.4766 85.8263L92.7489 80.3145Z"
            fill="white"
        />
        <path
            d="M98.0212 85.8263L92.7489 80.3145L87.4766 85.8263"
            stroke="#29319B"
            stroke-width="2.25"
            stroke-linecap="round"
        />
        <defs>
            <clipPath id="clip0_6292_161903">
                <rect
                    width="60.8136"
                    height="61.4356"
                    fill="white"
                    transform="matrix(0.929258 -0.36943 0.360652 0.9327 0 30.4883)"
                />
            </clipPath>
        </defs>
    </svg>
);
