import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { BorderedContainedBox } from '../../../../../components/core/box';
import { NegativeInsight } from '../../../../../components/icons/negative-insight';
import { PositiveInsight } from '../../../../../components/icons/positive-insight';
import { useFinancialsService } from '../../../../../services/financials.service';
import {
    EarningFilter,
    KeyInsightsResponseData,
    PositiveSummary,
} from '../../../../../services/interfaces';
import { Subscribe } from '../../../../../utils/subscribe';

interface IProps {
    selectedEarningFilter: EarningFilter | null;
    topicSubscriptionId: string;
    query: string;
}

export const KeyInsights = (props: IProps) => {
    const { fetchKeyInsights } = useFinancialsService();
    const topicSubscriptionId = props.topicSubscriptionId;
    const [insights, setInsights] = useState<KeyInsightsResponseData | null>(
        null
    );

    const refreshKeyInsights = async () => {
        if (topicSubscriptionId && props.selectedEarningFilter) {
            let data = await fetchKeyInsights({
                financeCategory: 'earnings_calls',
                topicSubscriptionId: topicSubscriptionId,
                year: props.selectedEarningFilter.year,
                quarter: props.selectedEarningFilter.quarter,
            });
            setInsights({ ...data });
        }
    };

    useEffect(() => {
        refreshKeyInsights();
    }, [topicSubscriptionId, props.selectedEarningFilter]);

    return (
        <>
            {/* {!insights && !props.topicSubscriptionId && (
                <Box sx={{ position: 'relative' }}>
                    <BorderedContainedBox
                        sx={{
                            background:
                                'linear-gradient(180deg, rgba(147, 158, 255, 0.25) 0%, rgba(255, 255, 255, 0.25) 100%)',
                        }}
                    >
                        <Typography
                            color="#000033"
                            fontWeight={600}
                            variant="h4"
                        >
                            Key Financial Insights from Management Discussion
                        </Typography>
                        <Grid spacing={4} container>
                            <Grid item sm={6}>
                                <Box pt={2} pl={'0px !important'}>
                                    {[
                                        'Deployed 4 gigawatt hours of energy storage products in Q3',
                                        'Energy and service now contribute over $0.5 billion to quarterly profit',
                                        'Record quarter of profitability in the energy business',
                                        'Generated operating cash flows of approximately $8.9 billion and free cash flows of approximately $2.3 billion this year',
                                    ].map((ps: string) => (
                                        <Box my={2} display="flex">
                                            <Box pt={0.5} sx={{ width: 16 }}>
                                                {PositiveInsight}
                                            </Box>
                                            <Typography
                                                pl={2}
                                                fontSize={14}
                                                color="#4B4B4C"
                                                lineHeight={'20px'}
                                                variant="caption"
                                            >
                                                {ps}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item sm={6}>
                                <Box pt={2}>
                                    {[
                                        'Deployed 4 gigawatt hours of energy storage products in Q3',
                                        'Energy and service now contribute over $0.5 billion to quarterly profit',
                                        'Record quarter of profitability in the energy business',
                                        'Generated operating cash flows of approximately $8.9 billion and free cash flows of approximately $2.3 billion this year',
                                    ].map((ps: string) => (
                                        <Box my={2} display="flex">
                                            <Box pt={1} sx={{ width: 16 }}>
                                                {NegativeInsight}
                                            </Box>
                                            <Typography
                                                pl={2}
                                                fontSize={14}
                                                lineHeight={'20px'}
                                                color="#4B4B4C"
                                                variant="caption"
                                            >
                                                {ps}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                    </BorderedContainedBox>
                    <Subscribe
                        message="Unlock Exclusive Insights: Subscribe to Access High-Level Earnings Call Highlights, Event-Based Sentiment Analysis, and Forward-Looking Statements"
                        query={props.query}
                        wideBox={true}
                    />
                </Box>
            )} */}
            {insights && (
                <BorderedContainedBox sx={{ backgroundColor: '#F9F9FF' }}>
                    <Typography color="#000033" fontWeight={600} variant="h4">
                        Key Financial Insights from Management Discussion
                    </Typography>
                    <Grid spacing={4} container>
                        <Grid item sm={6}>
                            <Box pt={2} pl={'0px !important'}>
                                {insights?.positiveSummaries.map(
                                    (ps: PositiveSummary) => (
                                        <Box my={2} display="flex">
                                            <Box pt={0.5} sx={{ width: 16 }}>
                                                {PositiveInsight}
                                            </Box>
                                            <Typography
                                                pl={2}
                                                fontSize={14}
                                                color="#4B4B4C"
                                                lineHeight={'20px'}
                                                variant="caption"
                                            >
                                                {ps.summary}
                                            </Typography>
                                        </Box>
                                    )
                                )}
                            </Box>
                        </Grid>
                        <Grid item sm={6}>
                            <Box pt={2}>
                                {insights?.negativeSummaries.map(
                                    (ps: PositiveSummary) => (
                                        <Box my={2} display="flex">
                                            <Box pt={1} sx={{ width: 16 }}>
                                                {NegativeInsight}
                                            </Box>
                                            <Typography
                                                pl={2}
                                                fontSize={14}
                                                lineHeight={'20px'}
                                                color="#4B4B4C"
                                                variant="caption"
                                            >
                                                {ps.summary}
                                            </Typography>
                                        </Box>
                                    )
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </BorderedContainedBox>
            )}
        </>
    );
};
