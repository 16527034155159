export const DocumentInsightChatboxImage = (
    <svg
        width="208"
        height="186"
        viewBox="0 0 208 186"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_7196_133486)">
            <path
                d="M68.0073 150.966V170.841C68.0059 171.528 68.1372 172.181 68.3938 172.766C68.6505 173.35 69.0274 173.853 69.5029 174.246C69.9785 174.639 70.5434 174.914 71.1652 175.056C71.7871 175.198 72.4538 175.204 73.127 175.073L180.864 154.131L185.315 163.198L189.979 152.359L195.399 151.306C196.072 151.175 196.739 150.91 197.361 150.526C197.983 150.143 198.548 149.648 199.023 149.07C199.499 148.492 199.876 147.842 200.132 147.158C200.389 146.474 200.52 145.769 200.519 145.084V125.208C200.52 124.522 200.389 123.869 200.132 123.285C199.875 122.702 199.498 122.2 199.023 121.807C198.547 121.415 197.982 121.141 197.36 121C196.738 120.86 196.072 120.855 195.399 120.987L73.127 144.755C72.4543 144.884 71.7879 145.148 71.1661 145.53C70.5443 145.912 69.9793 146.406 69.5036 146.983C69.0279 147.561 68.6508 148.209 68.3941 148.893C68.1373 149.576 68.0059 150.281 68.0073 150.966Z"
                fill="#E4ECFF"
            />
        </g>
        <g filter="url(#filter1_d_7196_133486)">
            <path
                d="M66.0679 65.0175V85.4781C66.0664 86.1844 66.2016 86.8576 66.4658 87.459C66.73 88.0604 67.1179 88.5782 67.6075 88.9828C68.097 89.3875 68.6785 89.6709 69.3187 89.8171C69.9589 89.9632 70.6451 89.969 71.3382 89.8343L182.245 68.2762L186.828 77.6098L191.628 66.4522L197.208 65.3676C197.901 65.2329 198.587 64.9602 199.227 64.5653C199.868 64.1703 200.449 63.6607 200.939 63.0658C201.428 62.4708 201.816 61.8021 202.08 61.098C202.344 60.3939 202.48 59.6682 202.478 58.9625V38.5019C202.48 37.7961 202.344 37.1235 202.08 36.5228C201.816 35.9221 201.428 35.405 200.938 35.0014C200.448 34.5977 199.867 34.3153 199.226 34.1705C198.586 34.0256 197.9 34.0212 197.208 34.1573L71.3382 58.624C70.6457 58.7571 69.9597 59.0282 69.3196 59.4219C68.6795 59.8156 68.0979 60.3241 67.6082 60.9182C67.1185 61.5122 66.7303 62.1802 66.466 62.8836C66.2017 63.5871 66.0664 64.3123 66.0679 65.0175Z"
                fill="#E4ECFF"
            />
        </g>
        <g filter="url(#filter2_d_7196_133486)">
            <path
                d="M138.918 94.0775V114.257C138.92 114.952 138.787 115.667 138.526 116.361C138.265 117.054 137.883 117.712 137.4 118.298C136.917 118.883 136.344 119.385 135.713 119.773C135.082 120.161 134.406 120.428 133.723 120.559L24.4019 141.809L19.8845 152.777L15.1525 143.607L9.65257 144.676C8.96993 144.811 8.29372 144.806 7.66276 144.663C7.03179 144.521 6.45851 144.242 5.97581 143.844C5.49311 143.446 5.1105 142.937 4.84995 142.345C4.5894 141.753 4.45604 141.09 4.45753 140.394V120.214C4.45753 118.811 5.00488 117.358 5.97913 116.176C6.95339 114.994 8.27476 114.18 9.65257 113.912L133.769 89.7862C135.139 89.5321 136.448 89.8404 137.413 90.6441C138.377 91.4478 138.918 92.6818 138.918 94.0775Z"
                fill="#AEC7FF"
            />
        </g>
        <g filter="url(#filter3_d_7196_133486)">
            <path
                d="M141.832 6.52225V27.5798C141.833 28.3052 141.694 29.0511 141.422 29.7747C141.15 30.4983 140.751 31.1853 140.248 31.7963C139.744 32.4074 139.146 32.9304 138.487 33.3354C137.829 33.7403 137.123 34.0192 136.411 34.1561L22.3335 56.3306L17.6196 67.7757L12.6817 58.2067L6.94253 59.3223C6.2302 59.4624 5.52457 59.4578 4.86616 59.3088C4.20775 59.1598 3.60952 58.8693 3.10582 58.4541C2.60212 58.0389 2.20287 57.5071 1.93099 56.8892C1.65911 56.2713 1.51994 55.5795 1.5215 54.8535V33.796C1.5215 32.3313 2.09265 30.8156 3.10929 29.5823C4.12593 28.349 5.50479 27.4992 6.94253 27.2197L136.458 2.04432C137.888 1.7791 139.254 2.10084 140.261 2.93951C141.267 3.77817 141.832 5.06586 141.832 6.52225Z"
                fill="#AEC7FF"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_7196_133486"
                x="67.0073"
                y="119.891"
                width="138.512"
                height="60.2773"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="1.99999" dy="1.99999" />
                <feGaussianBlur stdDeviation="1.49999" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.160784 0 0 0 0 0.192157 0 0 0 0 0.607843 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_7196_133486"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_7196_133486"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_d_7196_133486"
                x="65.0679"
                y="33.0586"
                width="142.41"
                height="61.873"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="1.99999" dy="1.99999" />
                <feGaussianBlur stdDeviation="1.49999" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.160784 0 0 0 0 0.192157 0 0 0 0 0.607843 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_7196_133486"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_7196_133486"
                    result="shape"
                />
            </filter>
            <filter
                id="filter2_d_7196_133486"
                x="3.45753"
                y="88.6992"
                width="140.461"
                height="69.0781"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="1.99999" dy="1.99999" />
                <feGaussianBlur stdDeviation="1.49999" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.160784 0 0 0 0 0.192157 0 0 0 0 0.607843 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_7196_133486"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_7196_133486"
                    result="shape"
                />
            </filter>
            <filter
                id="filter3_d_7196_133486"
                x="0.521491"
                y="0.953132"
                width="146.311"
                height="71.8222"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="1.99999" dy="1.99999" />
                <feGaussianBlur stdDeviation="1.49999" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.160784 0 0 0 0 0.192157 0 0 0 0 0.607843 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_7196_133486"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_7196_133486"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);
