import React, { useEffect, useState } from 'react';

import {
    BarChart,
    PlotProps,
} from '../../../../../components/charts/bar-chart';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { BorderedBox } from '../../../../../components/core/box';
import SingleSelectTab from '../../../../../components/core/input/single-select-tab';
import { useFinancialsService } from '../../../../../services/financials.service';
import {
    ReportModule,
    FinancialTrends,
} from '../../../../../services/interfaces';

interface IProps {
    data?: ReportModule;
}

export const FinancialTrendsPlot = (props: IProps) => {
    let { topicSubscriptionId } = useParams();
    const [data, setData] = useState<PlotProps | null>(null);
    const [selectedRange, setSelectedRange] = useState('Annual');
    const [financials, setFinancials] = useState<FinancialTrends | null>(null);
    const { fetchFinancialtrends } = useFinancialsService();

    useEffect(() => {
        if (props.data?.data?.yahoo?.coreFinancials.financialTrends)
            setFinancials(
                props.data?.data?.yahoo.coreFinancials.financialTrends
            );
    }, [props.data]);

    useEffect(() => {
        renderPlot();
    }, [financials, selectedRange]);

    const renderPlot = () => {
        if (!financials) return;
        const revenueSeries = financials[
            selectedRange == 'Quarter' ? 'quarterly' : 'yearly'
        ].map((d: any) => d.revenue);
        const earningsSeries = financials[
            selectedRange == 'Quarter' ? 'quarterly' : 'yearly'
        ].map((d: any) => d.earnings);
        const labels = financials[
            selectedRange == 'Quarter' ? 'quarterly' : 'yearly'
        ].map((d: any) => d.date.toString());

        let sentimentData: PlotProps = {
            series: [
                {
                    name: 'Revenue',
                    data: revenueSeries,
                },
                {
                    name: 'Earnings',
                    data: earningsSeries,
                },
            ],
            labels: labels,
        };
        setData({ ...sentimentData });
    };

    return (
        <BorderedBox sx={{ backgroundColor: '#FFFFFF' }} pt={3}>
            <Box>
                <Box px={4} display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="h6">Financial Trends</Typography>
                    <SingleSelectTab
                        getOptionText={(v: string) => v}
                        placeholder="Select source"
                        pb={3}
                        fontSize={12}
                        width={130}
                        options={['Annual', 'Quarter']}
                        selectedValue={selectedRange}
                        onChange={setSelectedRange}
                    />
                </Box>
                {data && (
                    <Box pt={2}>
                        <BarChart showLinesOnly={true} {...data} />
                    </Box>
                )}
            </Box>
        </BorderedBox>
    );
};
