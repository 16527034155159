import { Box, Divider, Skeleton, Tooltip, Typography } from '@mui/material';
import { IRecentHeadlines } from '../../../services/interfaces';
import { NavigationLink } from '../../../components/core/link';
import { NewsImagePlaceholder } from '../../../components/images/news-image-placeholder';
import { Info } from '../../../components/icons/info';
import { EntityArticleData } from './entity-articles';
import { RecentHeadlinesImage } from '../../../components/images/recent-headlines';
import { useSearchParams } from 'react-router-dom';
import { NoDataImage } from '../../../components/images/no-data';
import { AppContext } from '../../../providers/app-provider';
import { useContext } from 'react';

interface IProps {
    headlines: IRecentHeadlines[] | null;
}

export const RecentHeadlines = (props: IProps) => {
    const [searchParam] = useSearchParams();
    const appContext = useContext(AppContext);
    return (
        <Box>
            <Box
                pb={6}
                sx={{
                    maxHeight: `calc(100vh - ${
                        appContext?.accountActionBannerVisible ? '22px' : '0px'
                    } - 255px)`,
                    overflowY: 'auto',
                }}
            >
                {!props.headlines &&
                    searchParam.get('query') &&
                    Array.from(Array(5).keys()).map((index: number) => {
                        return (
                            <Box width={'100%'} px={1}>
                                <Typography
                                    fontSize={14}
                                    component="div"
                                    display="flex !important"
                                    fontWeight={600}
                                >
                                    <Skeleton
                                        width={20}
                                        variant="rectangular"
                                    />{' '}
                                    <Skeleton
                                        sx={{ ml: 2, mt: -0.5 }}
                                        width={'calc(100% - 30px)'}
                                        variant="text"
                                    />
                                </Typography>
                                <Box
                                    px={1}
                                    pt={2}
                                    width={'100%'}
                                    display="flex"
                                >
                                    <Box mr={2} pt={1} maxWidth={128}>
                                        <NewsImagePlaceholder />
                                    </Box>
                                    <EntityArticleData d={null} />
                                </Box>
                                <Box py={2}>
                                    <Divider />
                                </Box>
                            </Box>
                        );
                    })}
                {props.headlines?.map((d: IRecentHeadlines, index: number) => {
                    return (
                        <Box key={index} width={'100%'}>
                            <Box
                                sx={{
                                    '&:hover': {
                                        backgroundColor: '#f9f9ff',
                                    },
                                }}
                            >
                                <NavigationLink to={d.url} target="_blank">
                                    <Typography fontSize={14} fontWeight={600}>
                                        <img
                                            style={{
                                                maxWidth: 14,
                                                maxHeight: 14,
                                                marginRight: 4,
                                                paddingTop: 2,
                                            }}
                                            src={d.icon}
                                        />{' '}
                                        {d.title}
                                    </Typography>
                                </NavigationLink>
                                <Box pt={2} width={'100%'} display="flex">
                                    <Box mr={3} pt={1} maxWidth={64}>
                                        {d.image ? (
                                            <img
                                                style={{
                                                    width: 64,
                                                    height: 64,
                                                    borderRadius: '8px',
                                                }}
                                                src={d.image}
                                            />
                                        ) : (
                                            <NewsImagePlaceholder />
                                        )}
                                    </Box>
                                    <EntityArticleData d={d} />
                                </Box>
                            </Box>
                            {index + 1 !== props.headlines?.length && (
                                <Box py={4}>
                                    <Divider />
                                </Box>
                            )}
                        </Box>
                    );
                })}
                {!props.headlines && !searchParam.get('query') && (
                    <Box textAlign={'center'} px={4} py={20}>
                        <Box>{RecentHeadlinesImage}</Box>
                        <br />
                        <Typography variant="h4" mt={10}>
                            Related headlines
                        </Typography>
                        <Typography fontSize={14} fontWeight={400} mt={2}>
                            Get the headlines related to your topic
                        </Typography>
                    </Box>
                )}
                {props.headlines && props.headlines.length == 0 && (
                    <Box textAlign={'center'} px={4} py={20}>
                        <Box>{NoDataImage}</Box>
                        <br />
                        <Typography variant="h4">
                            Sorry, No data available
                        </Typography>
                        <Typography fontSize={14} fontWeight={400} mt={2}>
                            No data available for this entities. Please try with
                            some different query
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
