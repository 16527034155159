export const Invalid = (
    <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_8918_57459"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="17"
            height="17"
        >
            <rect x="0.227234" y="0.5" width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_8918_57459)">
            <path
                d="M8.19373 16.5C6.08088 16.5 4.05458 15.6607 2.56057 14.1667C1.06656 12.6727 0.227234 10.6463 0.227234 8.5335V8.4665C0.227234 6.35365 1.06656 4.32734 2.56057 2.83333C4.05458 1.33932 6.08089 0.5 8.19373 0.5H8.26074C10.3736 0.5 12.3999 1.33932 13.8939 2.83333C15.3879 4.32734 16.2272 6.35365 16.2272 8.4665V8.5335C16.2272 10.6463 15.3879 12.6727 13.8939 14.1667C12.3999 15.6607 10.3736 16.5 8.26073 16.5H8.19373ZM5.07168 11.6556C5.41532 11.9992 5.97248 11.9992 6.31612 11.6556L8.22723 9.74444L10.1383 11.6556C10.482 11.9992 11.0391 11.9992 11.3828 11.6556C11.7264 11.3119 11.7264 10.7548 11.3828 10.4111L9.47168 8.5L11.3828 6.58889C11.7264 6.24525 11.7264 5.68809 11.3828 5.34444C11.0391 5.0008 10.482 5.0008 10.1383 5.34444L8.22723 7.25556L6.31612 5.34444C5.97248 5.0008 5.41532 5.0008 5.07168 5.34444C4.72803 5.68809 4.72803 6.24525 5.07168 6.58889L6.98279 8.5L5.07168 10.4111C4.72803 10.7548 4.72803 11.3119 5.07168 11.6556ZM3.82723 12.9C4.99398 14.0667 6.5772 14.7222 8.22723 14.7222C9.87727 14.7222 11.4605 14.0667 12.6272 12.9C13.794 11.7333 14.4495 10.15 14.4495 8.5C14.4495 6.84997 13.794 5.26675 12.6272 4.1C11.4605 2.93325 9.87727 2.27778 8.22723 2.27778C6.5772 2.27778 4.99398 2.93325 3.82723 4.1C2.66048 5.26675 2.00501 6.84997 2.00501 8.5C2.00501 10.15 2.66048 11.7333 3.82723 12.9Z"
                fill="#D82F44"
            />
        </g>
    </svg>
);
