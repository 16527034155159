export const NegativeInsight = (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.06 9.71935L8 12.7793L4.94 9.71935L4 10.666L8 14.666L12 10.666L11.06 9.71935ZM11.06 5.71935L8 8.77935L4.94 5.71935L4 6.66601L8 10.666L12 6.66601L11.06 5.71935ZM11.06 1.71935L8 4.77935L4.94 1.71935L4 2.66601L8 6.66601L12 2.66601L11.06 1.71935Z"
            fill="#D82F44"
        />
    </svg>
);
