import styled from '@emotion/styled';
import { TableCell } from '@mui/material';

export const TableNegativeCell = styled(TableCell)(() => ({
    borderLeftWidth: `10px`,
    borderColor: '#D82F44 !important',
    borderStyle: 'solid !important',
    borderTop: 'none !important',
    borderRight: 'none !important',
    borderBottom: 'none !important',
    verticalAlign: 'top',
}));
