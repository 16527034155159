import {
    Box,
    Dialog,
    DialogTitle,
    IconButton,
    styled,
    Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
        padding: theme.spacing(4),
        maxWidth: 'none',
    },
}));
export const BootstrapDialogLarge = styled(BootstrapDialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        padding: theme.spacing(4),
        width: '80%',
        maxWidth: 'none',
    },
}));
export const BootstrapDialogMedium = styled(BootstrapDialogLarge)(
    ({ theme }) => ({
        '& .MuiPaper-root': {
            maxWidth: '650px',
        },
    })
);
export const BootstrapDialogSmall = styled(BootstrapDialogLarge)(
    ({ theme }) => ({
        '& .MuiPaper-root': {
            maxWidth: '450px',
        },
    })
);

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose?: () => void;
}

export function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, px: 2, py: 0 }} {...other}>
            <Box maxWidth={onClose ? `calc(100% - 20px)` : '100%'}>
                {children}
            </Box>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 24,
                        top: 20,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Tooltip arrow placement="top" title={`Close`}>
                        <CloseIcon />
                    </Tooltip>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
