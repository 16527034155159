import * as React from 'react';
import Box from '@mui/material/Box';
import { LeftMenu } from './left-menu';
import { useClusterService } from '../../services/cluster.service';
import { useEffect, useState } from 'react';
import { Topic, UserCluster } from '../../services/interfaces';
import { useTopicService } from '../../services/topic.service';
import { AppContext } from '../../providers/app-provider';
import { UtilsService } from '../../services/utils.service';
import { SupportChat } from '../support-chat';

interface IProps {
    children: React.ReactNode;
}

export default function Layout(props: IProps) {
    const { fetchUserClusters } = useClusterService();
    const { getPredefinedTopics } = useTopicService();
    const appContext = React.useContext(AppContext);
    const refreshTopics = async () => {
        let topics = await getPredefinedTopics();
        if (Array.isArray(topics)) {
            appContext?.setTopics([...topics]);
        }
    };
    const dataFetchedRef = React.useRef(false);

    const fetchData = () => {
        fetchUserClusters();
    };

    useEffect(() => {
        if (dataFetchedRef.current || UtilsService.isDemoApp()) return;
        dataFetchedRef.current = true;
        fetchData();
        refreshTopics();
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                '& .available-width': {
                    width: UtilsService.isDemoApp()
                        ? '100% !important'
                        : 'calc(100% - 88px) !important',
                },
            }}
        >
            {appContext?.isMobile === false && !UtilsService.isDemoApp() && (
                <LeftMenu />
            )}
            <Box
                component="main"
                sx={{
                    marginLeft: 'auto',
                    width:
                        UtilsService.isDemoApp() ||
                        appContext?.isMobile === true
                            ? '100% !important'
                            : 'calc(100% - 88px) !important',
                }}
            >
                {props.children}
            </Box>
        </Box>
    );
}
