import Avatar from '@mui/material/Avatar';
import React from 'react';
import { UtilsService } from '../../../services/utils.service';

interface IProps {
    value: string;
    color?: string;
    backgroundColor?: string;
    height?: number;
    width?: number;
    fontSize?: number;
}

const InitialsAvator = (props: IProps) => {
    const getInitials = (value: string) => {
        const hasTokens = value.indexOf(' ') !== -1;
        value =
            value.substring(0, hasTokens ? 1 : 2) +
            (hasTokens ? value.charAt(value.lastIndexOf(' ') + 1) : '');
        value = value.toLocaleUpperCase();
        return value;
    };
    return (
        <Avatar
            sx={{
                bgcolor:
                    props.backgroundColor || UtilsService.getRandomDarkColor(),
                height: props.height || 18,
                width: props.width || 18,
                color: props.color,
                fontSize: props.fontSize || 10,
                fontWeight: 600,
                padding: '3px',
            }}
            alt={props.value}
        >
            {getInitials(props.value)}
        </Avatar>
    );
};

export default React.memo(InitialsAvator);
