interface IProps {
    rating?: number;
}

const getColor = (rating: number) => {
    if (rating <= 2) {
        return '#E94E4E';
    }
    if (rating > 2 && rating < 3) {
        return '#CF6100';
    }
    if (rating >= 3) {
        return '#00AA44';
    }
};

export const DoubleStar = (props: IProps) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_1773_101455"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
        >
            <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1773_101455)">
            <path
                d="M13.255 12.6519L16.1604 10.1519L17.7197 10.2913L14.4088 13.1054L15.412 17.3185L14.1941 16.5926L13.255 12.6519ZM11.6268 7.71601L10.763 5.71762L11.473 4.06543L13.0931 7.8346L11.6268 7.71601ZM6.33518 14.8955L9.00184 13.3121L11.6893 14.8955L10.981 11.8955L13.2935 9.9163L10.231 9.64547L9.00184 6.7913L7.77268 9.64547L4.71018 9.9163L7.04351 11.8955L6.33518 14.8955ZM4.68939 17.1342L5.84161 12.3057L2.01953 9.05735L7.03232 8.62147L9.00184 4.06543L10.9714 8.6423L15.9841 9.05735L12.1621 12.3057L13.3143 17.1342L9.00184 14.5733L4.68939 17.1342Z"
                fill={getColor(props.rating ? props.rating : 0)}
            />
        </g>
    </svg>
);
