import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Skeleton,
    Tooltip,
    Typography,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Input } from '../../../components/core/input';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Lottie from 'lottie-react';
import { QuestionLight } from '../../../components/icons/question-light';
import loadingAnimation from '../../../components/animations/live-processing.json';
import { useChatbotService } from '../../../services/chatbot.service';
import FadeIn from 'react-fade-in';
import {
    HighlightIndexes,
    LiveQueryEntities,
    LiveQueryResponse,
    LiveQueryResponseDataSources,
    LiveQueryResponseDataSourcesResult,
    LiveQueryResponseLLMData,
    LiveQueryResponseSummaryData,
    LiveQueryResponseTerminationData,
} from '../../../services/interfaces';
import { useSearchParams } from 'react-router-dom';
import { AppContext } from '../../../providers/app-provider';
import { NewTabArrow } from '../../../components/icons/new-tab-arrow';
import { UtilsService } from '../../../services/utils.service';
import { Copy } from '../../../components/icons/copy';
import { Stop } from '../../../components/icons/stop';
import { Reset } from '../../../components/icons/reset';
import { BorderedBox } from '../../../components/core/box';
import { NewsImagePlaceholder } from '../../../components/images/news-image-placeholder';
import { NavigationLink } from '../../../components/core/link';
import Edit from '@mui/icons-material/Edit';
import { FileIcon } from '../../../components/icons/file';
import { DocumentImageLight } from '../../../components/icons/document-image-light';
import { CookieService } from '../../../services/cookie.service';
import { NoChatImage } from '../../../components/images/no-chat';
import { Close } from '../../../components/icons/close';
import { Filters } from '../landing/filters';
import { HomeIcon } from '../../../components/icons/home';
import { ChatMessage } from './message';
import { ViewFile } from '../../document-insights/drive/view-file';
import { Eye } from '../../../components/icons/eye';
import { Header } from '../header';
import { useNavigate } from 'react-router-dom';
import { ThumbUp } from '../../../components/icons/thumb-up';
import { ThumbDown } from '../../../components/icons/thumb-down';
import { ChatIds, IChatMessage } from './interface';
import { useDocumentService } from '../../../services/document.service';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { LiveResponse } from '../../../components/live/live-response';
import { FeatureName, SubFeatureName } from '../../../services/interfaces';
import { usePermissions } from '../../../providers/permissions-provider';
import { ActionButton } from '../../document-insights/chat';

declare const window: any;

interface IProps {
    disableInlineScroll?: boolean;
}

export const Chat = (props: IProps) => {
    let inputRef = useRef();
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    const navigate = useNavigate();
    const [searchParam] = useSearchParams();
    const [filtersFetched, setFiltersfetched] = useState(false);
    const [open, setOpen] = useState(false);
    const [isInsightMode, setIsInsightMode] = useState<boolean>(false);
    const [messages, setMessages] = useState<IChatMessage[]>([]);
    const [entities, setEntities] = useState<LiveQueryEntities[]>([]);
    const [chatIds, setChatIds] = useState<ChatIds>({
        chatHistoryId: '',
        chatQueryId: '',
        chatAnswerId: '',
        chatThreadId: '',
    });
    const messagesEndRef = useRef(null);
    const messageStartRef = useRef(null);
    const [editQuery, setEditQuery] = useState(false);
    const [highlightIndexes, setHighlightIndexes] = useState<
        HighlightIndexes | undefined
    >(undefined);
    const [sources, setSources] = useState<
        LiveQueryResponseDataSourcesResult[] | null
    >(null);
    const appContext = useContext(AppContext);
    const [streamFinished, setStreamFinished] = useState(true);
    const [copied, setCopied] = useState<boolean>(false);
    const [relatedSearches, setRelatedSearches] = useState<string[]>([]);
    const [relatedQuestions, setRelatedQuestions] = useState<string[]>([]);
    const {
        fetchChatbotLiveQueryResponse,
        fetchPublicChatbotLiveQueryResponse,
    } = useChatbotService();
    const [currentStatus, setCurrentStatus] = useState<string>('');
    const [queryMessage, setQueryMessage] = useState<string>('');
    const [userQuery, setUserQuery] = useState<string>('');
    const [vote, setVote] = useState<'neutral' | 'upvote' | 'downvote'>(
        'neutral'
    );
    const [aiResponse, setAIResponse] = useState<string>('');
    const [expandPeopleAlsoAsks, setExpandPeopleAlsoAsks] =
        useState<boolean>(false);

    const { updateDocumentInsightChat } = useDocumentService();

    useEffect(() => {
        let query = searchParam.get('query');
        let insight = searchParam.get('insights');

        setIsInsightMode(
            insight === 'true' &&
                isSubFeatureAuthorized(
                    FeatureName.InstantInsights,
                    SubFeatureName.InsightsMode
                )
                ? true
                : false
        );
        if (query && insight == 'false') {
            processMessage(query);
            setEditQuery(false);
        }
    }, [searchParam, appContext?.permissions]);

    const sendMessage = async () => {
        if (!streamFinished) return;
        let query = searchParam.get('query');
        if (!isInsightMode && query != queryMessage) {
            navigate(
                `/live?query=${encodeURIComponent(
                    queryMessage
                )}&id=&insights=false`
            );
        } else {
            processMessage(queryMessage);
        }
    };
    const handleClose = () => {
        setOpen(false);
    };
    const processMessage = async (message: string) => {
        if (aiResponse) {
            setMessages([
                ...messages,
                {
                    query: userQuery,
                    text: aiResponse,
                    highlightIndexes: highlightIndexes,
                    entities: entities,
                    sources: sources,
                    vote: vote,
                    chatIds: chatIds,
                },
            ]);
        }
        setUserQuery(message);

        window.scrollTo(0, 0);

        setQueryMessage('');
        setAIResponse('');
        setHighlightIndexes(undefined);
        setSources(null);
        setEntities([]);
        setVote('neutral');
        setEditQuery(false);
        setChatIds({
            chatHistoryId: '',
            chatQueryId: '',
            chatAnswerId: '',
            chatThreadId: '',
        });
        setExpandPeopleAlsoAsks(false);
        setRelatedSearches([]);
        setRelatedQuestions([]);
        let messageId = UtilsService.uuidv4();
        window.activeMessageId = messageId;
        setCurrentStatus('Exploring knowledge sources');
        setStreamFinished(false);
        setTimeout(() => {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
            });
            //@ts-ignore
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
        if (UtilsService.isDemoApp()) {
            let f = await new CookieService().get('live-settings');

            setTimeout(async () => {
                let response = await fetchPublicChatbotLiveQueryResponse(
                    message,
                    'LIVE_QA',
                    JSON.parse(f || 'null'),
                    (data: string) => {
                        if (data && messageId == window.activeMessageId) {
                            processQueryResponse(data.split('\n'));
                        }
                    }
                );
            }, 100);
        } else {
            setTimeout(async () => {
                let response = await fetchChatbotLiveQueryResponse(
                    message,
                    'LIVE_QA',
                    (data: string) => {
                        if (data && messageId == window.activeMessageId) {
                            processQueryResponse(data.split('\n'));
                        }
                    },
                    undefined,
                    chatIds.chatThreadId ? chatIds.chatThreadId : undefined
                );
            }, 100);
        }
    };

    const resetChat = () => {
        window.activeMessageId = '';
        setSources(null);
        setStreamFinished(true);
        setCopied(false);
        setEntities([]);
        setRelatedSearches([]);
        setRelatedQuestions([]);
        setQueryMessage('');
        setAIResponse('');
        setUserQuery('');
        setCurrentStatus('');
        setMessages([]);
        setVote('neutral');
        setChatIds({
            chatHistoryId: '',
            chatQueryId: '',
            chatAnswerId: '',
            chatThreadId: '',
        });
        window.scrollTo(0, 0);
    };

    const getSourcesText = (sources: LiveQueryResponseDataSourcesResult[]) => {
        if (!sources || !sources.length) {
            return '';
        }
        return `Sources: \n${sources
            .map(
                (s: LiveQueryResponseDataSourcesResult) =>
                    `[${s.id || 0}] ${s.url}`
            )
            .join('\n')}`;
    };

    const addSourcesLinks = (
        answer: string,
        sources: LiveQueryResponseDataSourcesResult[]
    ) => {
        let message = answer;
        for (let i = 1; i < 10; i++) {
            if (message.includes(`[${i}]`))
                message = message
                    .split(`[${i}]`)
                    .join(`[${i}][${sources[i - 1].url}]`);
        }
        return message;
    };

    const handleCopyToClipboard = () => {
        if (!sources) return;
        let text = `${aiResponse}\n\n${getSourcesText(sources)}`;
        navigator.clipboard.writeText(text);
    };

    const processQueryResponse = (responses: string[]) => {
        let responseString = '';
        if (!aiResponse) {
            setTimeout(() => {
                window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: 'smooth',
                });
                //@ts-ignore
                messageStartRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
        let sources: LiveQueryResponseDataSourcesResult[] = [];
        let finalSources: LiveQueryResponseDataSourcesResult[] = [];
        for (let res of responses) {
            try {
                let parsedRes: LiveQueryResponse = JSON.parse(res);
                switch (parsedRes.chunk) {
                    case 'SOURCES':
                        setStreamFinished(false);
                        setCurrentStatus('Crunching relevant information');
                        sources = [
                            ...(parsedRes.data as LiveQueryResponseDataSources)
                                .sources,
                        ];
                        setRelatedQuestions([
                            ...(parsedRes.data as LiveQueryResponseDataSources)
                                .relatedQuestions,
                        ]);
                        setRelatedSearches([
                            ...(parsedRes.data as LiveQueryResponseDataSources)
                                .relatedSearches,
                        ]);
                        setSources([...sources]);
                        break;
                    case 'SUMMARY':
                        setStreamFinished(false);
                        let summaries: string[] = (
                            parsedRes.data as LiveQueryResponseSummaryData
                        ).data;
                        setCurrentStatus('Crafting response for you');
                        for (let index = 0; index < sources.length; index++) {
                            if (summaries[index]) {
                                sources[index].article = summaries[index];
                                finalSources.push(sources[index]);
                            }
                        }
                        let parsedData = processResponseStringCitation(
                            responseString,
                            finalSources
                        );
                        setAIResponse(parsedData.responseString);
                        setSources([...parsedData.sources]);
                        break;
                    case 'LLM':
                        setStreamFinished(false);
                        responseString =
                            responseString +
                            (parsedRes.data as LiveQueryResponseLLMData).value;
                        let data = processResponseStringCitation(
                            responseString,
                            finalSources
                        );
                        setAIResponse(data.responseString);
                        setSources([...data.sources]);
                        break;
                    case 'ANSWER_SENTIMENTS':
                        setStreamFinished(false);
                        let indexes = parsedRes.data as HighlightIndexes;
                        setHighlightIndexes(indexes);
                        break;
                    case 'ANSWER_ENTITIES':
                        setStreamFinished(false);
                        let _entities = parsedRes.data as LiveQueryEntities[];
                        setEntities(
                            _entities.filter((e) => e.entityGroup != 'MISC')
                        );
                        break;
                    case 'TERMINATION':
                        let finalData = processResponseStringCitation(
                            responseString,
                            finalSources
                        );
                        let chatMeta =
                            parsedRes.data as LiveQueryResponseTerminationData;
                        setChatIds({
                            chatHistoryId: chatMeta.chatHistoryId,
                            chatQueryId: chatMeta.chatQueryId,
                            chatAnswerId: chatMeta.chatAnswerId,
                            chatThreadId: chatMeta.chatThreadId,
                        });
                        setCurrentStatus('');
                        setAIResponse(finalData.responseString);
                        setSources([...finalData.sources]);
                        setStreamFinished(true);
                        break;
                }
            } catch (error) {
                // console.error(error)
            }
        }
    };

    const processResponseStringCitation = (
        responseString: string,
        sources: LiveQueryResponseDataSourcesResult[]
    ): {
        responseString: string;
        sources: LiveQueryResponseDataSourcesResult[];
    } => {
        for (let i = 0; i < sources.length; i++) {
            sources[i].id = i + 1;
            if (responseString.includes(`[${i + 1}]`)) {
                sources[i].citationIndex = i;
            }
        }
        return {
            responseString,
            sources: sources,
            // sources,
        };
    };

    const handleUpdateMessage = async (
        chatId: ChatIds,
        vote: 'upvote' | 'downvote'
    ) => {
        if (chatId.chatAnswerId == chatIds.chatAnswerId) {
            setVote(vote);
        } else {
            let newMessages = messages.map((message) => {
                if (message.chatIds.chatAnswerId == chatId.chatAnswerId) {
                    message.vote = vote;
                    return message;
                } else {
                    return message;
                }
            });
            setMessages(newMessages);
        }
        let status = await updateDocumentInsightChat(
            chatId.chatHistoryId,
            false,
            vote,
            'LIVE_QA',
            chatId.chatQueryId,
            chatId.chatAnswerId
        );
        if (!status) {
            if (chatId.chatAnswerId == chatIds.chatAnswerId) {
                setVote(vote);
            } else {
                let newMessages = messages.map((message) => {
                    if (message.chatIds.chatAnswerId == chatId.chatAnswerId) {
                        message.vote = vote;
                        return message;
                    } else {
                        return message;
                    }
                });
                setMessages(newMessages);
            }
        }
    };

    return (
        <Box
            sx={{
                maxHeight:
                    window.location.href.indexOf('live') > -1
                        ? '100vh'
                        : 'calc(100vh - 173px)',
            }}
        >
            {appContext?.isMobile === false && !isInsightMode && <Header />}
            <Box
                pt={appContext?.isMobile == false ? 2 : 1}
                pl={appContext?.isMobile == false ? 0 : 5}
                pr={appContext?.isMobile == false ? 0 : 2}
                sx={
                    props.disableInlineScroll
                        ? {}
                        : {
                              height:
                                  window.location.href.indexOf('live') > -1
                                      ? `calc(100vh - ${
                                            appContext?.accountActionBannerVisible
                                                ? '22px'
                                                : '0px'
                                        } - 92px)`
                                      : `calc(100vh - ${
                                            appContext?.accountActionBannerVisible
                                                ? '22px'
                                                : '0px'
                                        } - 265px)`,
                              overflowY: 'auto',
                              marginTop:
                                  window.location.href.indexOf('live') > -1
                                      ? 4
                                      : 0,
                          }
                }
            >
                <Box
                    id="chat-container"
                    sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        maxWidth: '1000px',
                    }}
                >
                    {messages.map((m) => (
                        <ChatMessage
                            query={m.query}
                            text={m.text}
                            sources={m.sources}
                            highlightIndexes={m.highlightIndexes}
                            entities={m.entities}
                            vote={m.vote}
                            chatIds={m.chatIds}
                            handleUpdateMessage={handleUpdateMessage}
                        />
                    ))}
                    {!userQuery && (
                        <Box>
                            <Box
                                minHeight={
                                    window.location.href.indexOf('live') > -1
                                        ? 'calc(100vh - 350px)'
                                        : 'calc(100vh - 615px)'
                                }
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <NoChatImage width="40%" height="30vh" />

                                <Typography variant="h4" mb={2}>
                                    Advance Your Research with Precision
                                </Typography>
                                <Typography fontSize={'14px'}>
                                    Get real-time, web-sourced answers to your
                                    specific research queries.
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    position: 'fixed',
                                    bottom: 30,
                                    right: 0,
                                    margin: 'auto',
                                    marginLeft: appContext?.isMobile
                                        ? 0
                                        : UtilsService.isDemoApp()
                                        ? '20%'
                                        : appContext?.menuExpanded
                                        ? '28%'
                                        : '23%',
                                    width: appContext?.isMobile
                                        ? 'calc(100% - 10px)'
                                        : '60%',
                                    left: appContext?.isMobile ? 5 : 0,
                                }}
                            >
                                <Box display="flex">
                                    <Box
                                        sx={{
                                            paddingLeft: appContext?.isMobile
                                                ? '0px'
                                                : window.location.href.indexOf(
                                                      'live'
                                                  ) > -1
                                                ? '82px'
                                                : '0px',
                                            width: `calc(100% - ${
                                                appContext?.isMobile ? 0 : 105
                                            }px)`,
                                        }}
                                    >
                                        {expandPeopleAlsoAsks &&
                                            streamFinished &&
                                            relatedSearches.length > 0 && (
                                                <Box
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        borderRadius:
                                                            '16px 16px 0px 0px',
                                                        borderTop:
                                                            '1px solid #29319B',
                                                        borderLeft:
                                                            '1px solid #29319B',
                                                        borderRight:
                                                            '1px solid #29319B',
                                                    }}
                                                    textAlign={'left'}
                                                >
                                                    {relatedSearches.map(
                                                        (
                                                            query: string,
                                                            index: number
                                                        ) => (
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    py: 3,
                                                                    px: 4,
                                                                    borderTopRightRadius:
                                                                        index ==
                                                                        0
                                                                            ? '16px'
                                                                            : '',
                                                                    borderTopLeftRadius:
                                                                        index ==
                                                                        0
                                                                            ? '16px'
                                                                            : '',
                                                                    borderTop:
                                                                        index ==
                                                                        0
                                                                            ? 'none'
                                                                            : '1px solid #A5A6AB',
                                                                    backgroundColor:
                                                                        '#FFF',
                                                                    '&:hover': {
                                                                        cursor: 'pointer',
                                                                        background:
                                                                            'linear-gradient(132deg, rgba(41, 49, 155, 0.05) 0%, rgba(41, 49, 155, 0.00) 100%)',
                                                                    },
                                                                }}
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    processMessage(
                                                                        query
                                                                    );
                                                                    event.stopPropagation();
                                                                    event.preventDefault();
                                                                }}
                                                            >
                                                                <SearchIcon
                                                                    sx={{
                                                                        fontSize: 28,
                                                                        color: '#4B4B4C',
                                                                    }}
                                                                />

                                                                <Typography
                                                                    color="#4B4B4C"
                                                                    ml={2}
                                                                    onClick={(
                                                                        event
                                                                    ) => {
                                                                        processMessage(
                                                                            query
                                                                        );
                                                                        event.stopPropagation();
                                                                        event.preventDefault();
                                                                    }}
                                                                    variant="h6"
                                                                >
                                                                    {query}
                                                                </Typography>
                                                            </Box>
                                                        )
                                                    )}
                                                </Box>
                                            )}
                                        <Input
                                            type="text"
                                            mt={0}
                                            onClick={(e: any) => {
                                                if (!streamFinished) {
                                                    e.stopPropagation();
                                                }
                                            }}
                                            noShadow={true}
                                            inputRef={inputRef}
                                            background="linear-gradient(131deg, #F5F6FF 0%, #FFF 100%)"
                                            borderRadius={
                                                expandPeopleAlsoAsks &&
                                                streamFinished &&
                                                relatedSearches.length > 0
                                                    ? '0px 0px 16px 16px'
                                                    : '16px'
                                            }
                                            borderColor={
                                                expandPeopleAlsoAsks
                                                    ? '#29319B !important'
                                                    : '#A5A6AB'
                                            }
                                            sx={{
                                                boxShadow:
                                                    '0px 10px 20px -10px rgba(0, 0, 0, 0.50)',
                                                pl: 2,
                                                '&:hover': {
                                                    boxShadow:
                                                        '0px 10px 30px -10px rgba(0, 0, 0, 0.50)',
                                                    '& fieldset': {
                                                        border: '1px solid #29319B !important',
                                                    },
                                                },
                                            }}
                                            value={queryMessage}
                                            onChange={(e) => {
                                                setQueryMessage(e.target.value);
                                            }}
                                            onEnterPress={sendMessage}
                                            inputPadding={
                                                '10.5px 14px 10.5px 0px'
                                            }
                                            startAdornment={
                                                <Button
                                                    sx={{
                                                        px: 2,
                                                        py: 1,
                                                        fontSize: 18,
                                                        borderRadius: 10,
                                                        minWidth: 10,
                                                    }}
                                                    variant="text"
                                                    disabled={
                                                        !queryMessage ||
                                                        !streamFinished
                                                    }
                                                    onClick={sendMessage}
                                                >
                                                    <SearchIcon
                                                        sx={{ fontSize: 28 }}
                                                    />
                                                </Button>
                                            }
                                            endAdornment={
                                                <Box
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    sx={{
                                                        pr: 2,
                                                    }}
                                                >
                                                    {queryMessage && (
                                                        <Box
                                                            display={'flex'}
                                                            alignItems={
                                                                'center'
                                                            }
                                                        >
                                                            <IconButton
                                                                sx={{
                                                                    minWidth: 0,
                                                                    padding: 0,
                                                                    marginRight:
                                                                        '8px',
                                                                    width: 'auto',
                                                                }}
                                                                onClick={() =>
                                                                    setQueryMessage(
                                                                        ''
                                                                    )
                                                                }
                                                            >
                                                                <Close
                                                                    color="#4B4B4C"
                                                                    size={24}
                                                                />
                                                            </IconButton>
                                                            <Divider
                                                                sx={{
                                                                    marginRight:
                                                                        '8px',
                                                                    height: '40px',
                                                                }}
                                                                orientation="vertical"
                                                                flexItem
                                                            />
                                                        </Box>
                                                    )}
                                                    <Filters
                                                        setFiltersfetched={
                                                            setFiltersfetched
                                                        }
                                                        filtersFetched={
                                                            filtersFetched
                                                        }
                                                        onFiltersUpdate={() => {}}
                                                        min={true}
                                                    />
                                                </Box>
                                            }
                                            placeholder="Ask me anything..."
                                        />
                                    </Box>
                                    {window.location.href.indexOf('live') >
                                        -1 && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: 2,
                                                left: 0,
                                            }}
                                        >
                                            <Button
                                                onClick={() => navigate(`/`)}
                                                sx={{
                                                    border: '1px solid #29319B',
                                                    backgroundColor: '#EBECF6',
                                                    borderRadius: 2,
                                                    fontSize: 16,
                                                    maxHeight: '50px',
                                                    fontWeight: 600,
                                                    '&:hover': {
                                                        border: '1px solid #29319B',
                                                        backgroundColor: '#FFF',
                                                    },
                                                    '& path': {
                                                        fill: '#29319B',
                                                    },
                                                    ml: 2,
                                                    py: 2.5,
                                                    px: 1,
                                                }}
                                                variant="outlined"
                                            >
                                                <HomeIcon />
                                            </Button>
                                        </Box>
                                    )}
                                    {!appContext?.isMobile && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: 2,
                                                right: -8,
                                            }}
                                        >
                                            <Button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    resetChat();
                                                }}
                                                sx={{
                                                    border: '1px solid #29319B',
                                                    backgroundColor: '#EBECF6',
                                                    borderRadius: 2,
                                                    fontSize: 16,
                                                    fontWeight: 600,
                                                    '&:hover': {
                                                        border: '1px solid #29319B',
                                                        backgroundColor: '#FFF',
                                                    },
                                                    '& path': {
                                                        fill: '#29319B',
                                                    },
                                                    ml: 2,
                                                    py: 2.5,
                                                }}
                                                startIcon={Reset}
                                                variant="outlined"
                                            >
                                                Reset
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {userQuery && (
                        <Box
                            sx={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                maxWidth: '1000px',
                            }}
                            pb={appContext?.isMobile ? 13 : 30}
                        >
                            <div ref={messageStartRef} />
                            {!editQuery && (
                                <Box display="flex">
                                    <Box pt={1.5}>
                                        <QuestionLight
                                            size={
                                                appContext?.isMobile
                                                    ? '20px'
                                                    : undefined
                                            }
                                            color="#003"
                                        />
                                    </Box>

                                    <Typography
                                        ml={2}
                                        lineHeight={
                                            appContext?.isMobile
                                                ? '26px'
                                                : '32px'
                                        }
                                        fontWeight={600}
                                        fontSize={
                                            appContext?.isMobile ? 18 : 28
                                        }
                                        textAlign={'left'}
                                    >
                                        {userQuery}
                                        <Tooltip
                                            arrow
                                            placement="top"
                                            title={'Edit'}
                                        >
                                            <Button
                                                sx={{
                                                    pt: 0.5,
                                                    ml: 'auto',
                                                    '&:hover': {
                                                        backgroundColor: '#fff',
                                                    },
                                                    '&:hover path': {
                                                        fill: '#000',
                                                    },
                                                }}
                                                onMouseLeave={() => {
                                                    setTimeout(() => {
                                                        setCopied(false);
                                                    }, 100);
                                                }}
                                                onClick={(event: any) => {
                                                    event.stopPropagation();
                                                    setQueryMessage(userQuery);
                                                    setEditQuery(true);
                                                }}
                                            >
                                                <Edit
                                                    fontSize={
                                                        appContext?.isMobile
                                                            ? 'small'
                                                            : undefined
                                                    }
                                                />
                                            </Button>
                                        </Tooltip>
                                    </Typography>
                                </Box>
                            )}
                            {editQuery && (
                                <Box>
                                    <Input
                                        type="text"
                                        mt={0}
                                        multiline={true}
                                        inputRef={inputRef}
                                        borderRadius={2}
                                        fontSize={
                                            appContext?.isMobile ? 18 : 28
                                        }
                                        fontWeight={700}
                                        borderColor={'#A5A6AB'}
                                        sx={{
                                            p: appContext?.isMobile
                                                ? undefined
                                                : 4,
                                            px: appContext?.isMobile
                                                ? 3
                                                : undefined,

                                            '&:hover': {
                                                '& fieldset': {
                                                    border: '1px solid #29319B !important',
                                                },
                                            },
                                        }}
                                        value={queryMessage}
                                        onChange={(e) => {
                                            setQueryMessage(e.target.value);
                                        }}
                                        onEnterPress={(
                                            event: React.KeyboardEvent<
                                                | HTMLTextAreaElement
                                                | HTMLInputElement
                                            >
                                        ) => {
                                            sendMessage();
                                            event.preventDefault();
                                            event.stopPropagation();
                                        }}
                                        inputPadding={'10.5px 14px 10.5px 0px'}
                                        placeholder="Type your query here..."
                                    />
                                    <Box
                                        mt={2}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row-reverse',
                                        }}
                                    >
                                        <Button
                                            disabled={!streamFinished}
                                            onClick={sendMessage}
                                            variant="contained"
                                        >
                                            SUBMIT
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setQueryMessage('');
                                                setEditQuery(false);
                                            }}
                                            sx={{
                                                mx: 2,
                                            }}
                                            variant="text"
                                        >
                                            CANCEL
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                            <Box
                                sx={{
                                    borderRadius: 2,
                                    padding: 1,
                                    textAlign: 'left',
                                }}
                            >
                                {!aiResponse && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                height: 40,
                                                width: 40,
                                                display: 'flex',
                                            }}
                                        >
                                            <Lottie
                                                animationData={loadingAnimation}
                                                loop={true}
                                            />
                                        </Box>
                                        <Typography
                                            mt={2}
                                            ml={1}
                                            className="loading"
                                            variant="h6"
                                        >
                                            {currentStatus}
                                            {'.....'}
                                        </Typography>
                                    </Box>
                                )}

                                {aiResponse && (
                                    <Box py={1}>
                                        <LiveResponse
                                            entities={entities}
                                            onCitationClick={(
                                                citationIndex: number
                                            ) => {
                                                //@ts-ignore
                                                document
                                                    .getElementById(
                                                        `citation-${citationIndex}`
                                                    )
                                                    .scrollIntoView({
                                                        behavior: 'smooth',
                                                        block: 'center',
                                                        inline: 'center',
                                                    });
                                                UtilsService.highlightContent(
                                                    `citation-${citationIndex}`,
                                                    '#fff',
                                                    '#F9F9FF'
                                                );
                                            }}
                                            highlight={highlightIndexes}
                                            text={aiResponse}
                                        />
                                        <Box mt={-2} textAlign="right">
                                            <Tooltip
                                                arrow
                                                placement="top"
                                                title={
                                                    copied
                                                        ? 'Copied to clipboard'
                                                        : 'Copy'
                                                }
                                            >
                                                <ActionButton
                                                    disabled={!streamFinished}
                                                    sx={{
                                                        '&:hover': {
                                                            backgroundColor:
                                                                '#fff',
                                                        },
                                                        '&:hover path': {
                                                            fill: '#000',
                                                        },
                                                        marginRight: '4px',
                                                    }}
                                                    onMouseLeave={() => {
                                                        setTimeout(() => {
                                                            setCopied(false);
                                                        }, 100);
                                                    }}
                                                    onClick={(event: any) => {
                                                        event.stopPropagation();
                                                        handleCopyToClipboard();
                                                        setCopied(true);
                                                    }}
                                                >
                                                    <Copy />
                                                </ActionButton>
                                            </Tooltip>
                                            <Tooltip
                                                arrow
                                                placement="top"
                                                title="Upvote"
                                            >
                                                <ActionButton
                                                    onClick={() =>
                                                        handleUpdateMessage(
                                                            chatIds,
                                                            'upvote'
                                                        )
                                                    }
                                                    sx={{
                                                        marginRight: '4px',
                                                    }}
                                                >
                                                    {vote == 'upvote' ? (
                                                        <ThumbUpIcon
                                                            sx={{
                                                                color: '#E59413',
                                                                fontSize: 20,
                                                            }}
                                                        />
                                                    ) : (
                                                        <ThumbUp />
                                                    )}
                                                </ActionButton>
                                            </Tooltip>

                                            <Tooltip
                                                arrow
                                                placement="top"
                                                title="Downvote"
                                            >
                                                <ActionButton
                                                    onClick={() =>
                                                        handleUpdateMessage(
                                                            chatIds,
                                                            'downvote'
                                                        )
                                                    }
                                                >
                                                    {vote == 'downvote' ? (
                                                        <ThumbDownIcon
                                                            sx={{
                                                                color: '#E59413',
                                                                fontSize: 20,
                                                            }}
                                                        />
                                                    ) : (
                                                        <ThumbDown />
                                                    )}
                                                </ActionButton>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                            <Box>
                                {sources && sources?.length > 0 && (
                                    <Typography
                                        textAlign="left"
                                        variant="h6"
                                        mb={4}
                                    >
                                        {' '}
                                        Relevant Sources
                                        <IconButton
                                            aria-label="close"
                                            onClick={() => {
                                                setOpen(true);
                                            }}
                                            disabled={!sources}
                                            sx={{
                                                color: '#003',
                                                ml: 2,
                                                mt: -0.5,
                                            }}
                                        >
                                            <Tooltip
                                                arrow
                                                placement="top"
                                                title={`View Detailed sources`}
                                            >
                                                <FormatListBulletedIcon
                                                    sx={{
                                                        fontSize: 20,
                                                        color: '#29319B',
                                                    }}
                                                />
                                            </Tooltip>
                                        </IconButton>
                                    </Typography>
                                )}

                                <Box textAlign="left">
                                    <Grid spacing={4} container>
                                        {sources == null &&
                                            Array.from(
                                                Array(
                                                    appContext?.isMobile ? 2 : 3
                                                ).keys()
                                            ).map(() => (
                                                <Grid item sm={4} xs={12}>
                                                    <FadeIn delay={200}>
                                                        <Box p={4}>
                                                            <Box>
                                                                <Box display="flex">
                                                                    <Skeleton
                                                                        variant="rectangular"
                                                                        sx={{
                                                                            width: 20,
                                                                            marginTop: 1,
                                                                            height: 16,
                                                                            marginRight:
                                                                                '8px',
                                                                        }}
                                                                    />
                                                                    <Typography variant="h6">
                                                                        <Skeleton
                                                                            sx={{
                                                                                xs: {
                                                                                    width: '100%',
                                                                                },
                                                                                sm: {
                                                                                    width: 400,
                                                                                },
                                                                            }}
                                                                            variant="text"
                                                                        />
                                                                    </Typography>
                                                                </Box>
                                                                <Typography
                                                                    mt={3}
                                                                    variant="h6"
                                                                >
                                                                    <Skeleton variant="text" />
                                                                </Typography>
                                                                <Box mt={3}>
                                                                    <Skeleton variant="text" />
                                                                    <Skeleton variant="text" />
                                                                    <Skeleton variant="text" />
                                                                </Box>
                                                            </Box>
                                                            <Box pt={6}>
                                                                <Divider />
                                                            </Box>
                                                        </Box>
                                                    </FadeIn>
                                                </Grid>
                                            ))}
                                        {sources?.map(
                                            (
                                                s: LiveQueryResponseDataSourcesResult,
                                                index
                                            ) => (
                                                <Grid item sm={4} xs={12}>
                                                    <FadeIn delay={200}>
                                                        <BorderedBox
                                                            sx={{
                                                                height: 114,
                                                                '&:hover': {
                                                                    boxShadow:
                                                                        '0px 2px 10px 0px rgba(0, 0, 0, 0.20);',
                                                                    backgroundColor:
                                                                        '#F9F9FF',
                                                                },
                                                            }}
                                                            p={4}
                                                        >
                                                            <Box
                                                                display="flex"
                                                                id={
                                                                    'citation-' +
                                                                    (index + 1)
                                                                }
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        width: 'calc(100% - 80px)',
                                                                        pr: 1,
                                                                    }}
                                                                >
                                                                    <Box display="flex">
                                                                        <Typography
                                                                            variant="caption"
                                                                            color="#4B4B4C"
                                                                            fontWeight={
                                                                                600
                                                                            }
                                                                        >
                                                                            {index +
                                                                                1}
                                                                            .
                                                                        </Typography>
                                                                        {s.answerSource ==
                                                                            'ARTICLE' && (
                                                                            <img
                                                                                style={{
                                                                                    maxWidth: 20,
                                                                                    maxHeight: 16,
                                                                                    marginLeft:
                                                                                        '4px',
                                                                                    marginRight:
                                                                                        '4px',
                                                                                }}
                                                                                src={
                                                                                    s.icon
                                                                                }
                                                                            />
                                                                        )}
                                                                        {s.answerSource ==
                                                                            'USER_DATA' && (
                                                                            <FileIcon
                                                                                size={
                                                                                    18
                                                                                }
                                                                                type={
                                                                                    UtilsService.getFileType(
                                                                                        s.fileName
                                                                                    )
                                                                                        .length <=
                                                                                    3
                                                                                        ? UtilsService.getFileType(
                                                                                              s.fileName
                                                                                          )
                                                                                        : UtilsService.getFileType(
                                                                                              s.fileName
                                                                                          )
                                                                                }
                                                                                textBackgroundColor="#29319B"
                                                                            />
                                                                        )}
                                                                        <span
                                                                            style={{
                                                                                marginTop:
                                                                                    '-3px',
                                                                            }}
                                                                            className="clamp-line-1"
                                                                        >
                                                                            <Tooltip
                                                                                arrow
                                                                                placement="top"
                                                                                title={
                                                                                    s.answerSource ==
                                                                                    'ARTICLE'
                                                                                        ? s?.source
                                                                                        : 'Document'
                                                                                }
                                                                            >
                                                                                <Typography
                                                                                    color="#4B4B4C"
                                                                                    pr={
                                                                                        1
                                                                                    }
                                                                                    sx={{
                                                                                        borderRight:
                                                                                            (s.answerSource ==
                                                                                                'ARTICLE' &&
                                                                                                s?.source &&
                                                                                                s?.datetime) ||
                                                                                            s.answerSource ==
                                                                                                'USER_DATA'
                                                                                                ? '1px solid #ccc'
                                                                                                : '',
                                                                                    }}
                                                                                    fontWeight={
                                                                                        600
                                                                                    }
                                                                                    variant="caption"
                                                                                >
                                                                                    {s.answerSource ==
                                                                                    'ARTICLE'
                                                                                        ? s?.source
                                                                                        : 'Document'}
                                                                                </Typography>
                                                                            </Tooltip>
                                                                            <Tooltip
                                                                                arrow
                                                                                placement="top"
                                                                                title={
                                                                                    s &&
                                                                                    (s.answerSource ==
                                                                                    'ARTICLE'
                                                                                        ? UtilsService.formatStringDate(
                                                                                              s?.datetime
                                                                                          )
                                                                                        : s.answerSource ==
                                                                                              'USER_DATA' &&
                                                                                          (s.pageNo
                                                                                              ? 'Page ' +
                                                                                                s.pageNo
                                                                                              : UtilsService.formatStringDate(
                                                                                                    s?.uploadedOn
                                                                                                )))
                                                                                }
                                                                            >
                                                                                <Typography
                                                                                    pl={
                                                                                        1
                                                                                    }
                                                                                    color="#4B4B4C"
                                                                                    variant="caption"
                                                                                >
                                                                                    {s &&
                                                                                        s.answerSource ==
                                                                                            'ARTICLE' &&
                                                                                        UtilsService.formatStringDate(
                                                                                            s?.datetime
                                                                                        )}
                                                                                    {s &&
                                                                                        s.answerSource ==
                                                                                            'USER_DATA' &&
                                                                                        (s.pageNo
                                                                                            ? 'Page ' +
                                                                                              s.pageNo
                                                                                            : UtilsService.formatStringDate(
                                                                                                  s?.uploadedOn
                                                                                              ))}
                                                                                </Typography>
                                                                            </Tooltip>
                                                                            {s.answerSource ==
                                                                                'USER_DATA' && (
                                                                                <ViewFile
                                                                                    fileName={
                                                                                        s.fileName
                                                                                    }
                                                                                    pageNo={
                                                                                        s.pageNo
                                                                                    }
                                                                                    fileId={
                                                                                        s.userFileId
                                                                                    }
                                                                                >
                                                                                    <Tooltip
                                                                                        arrow
                                                                                        placement="top"
                                                                                        title={`View File`}
                                                                                    >
                                                                                        <Button
                                                                                            sx={{
                                                                                                px: 2,
                                                                                                py: 0,
                                                                                                minWidth: 30,
                                                                                            }}
                                                                                        >
                                                                                            <Eye
                                                                                                size={
                                                                                                    14
                                                                                                }
                                                                                            />
                                                                                        </Button>
                                                                                    </Tooltip>
                                                                                </ViewFile>
                                                                            )}
                                                                        </span>
                                                                    </Box>
                                                                    <Typography
                                                                        mt={1.5}
                                                                        component={
                                                                            NavigationLink
                                                                        }
                                                                        to={
                                                                            s.answerSource ==
                                                                            'ARTICLE'
                                                                                ? s.url
                                                                                : '/document-insight/file/' +
                                                                                  s.userFileId
                                                                        }
                                                                        target="_blank"
                                                                        className="clamp-line-3"
                                                                        fontSize={
                                                                            14
                                                                        }
                                                                        lineHeight={
                                                                            '19px'
                                                                        }
                                                                        fontWeight={
                                                                            600
                                                                        }
                                                                    >
                                                                        {s.answerSource ==
                                                                            'ARTICLE' &&
                                                                            s.title}
                                                                        {s.answerSource ==
                                                                            'USER_DATA' &&
                                                                            s.fileName}
                                                                    </Typography>
                                                                </Box>
                                                                {s.answerSource ==
                                                                    'ARTICLE' && (
                                                                    <Box
                                                                        sx={{
                                                                            display:
                                                                                'content',
                                                                            width: 64,
                                                                        }}
                                                                    >
                                                                        {s.image ? (
                                                                            <img
                                                                                style={{
                                                                                    width: 80,
                                                                                    height: 80,
                                                                                    borderRadius: 4,
                                                                                    objectFit:
                                                                                        'cover',
                                                                                }}
                                                                                src={
                                                                                    s.image
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            <BorderedBox
                                                                                sx={{
                                                                                    width: 80,
                                                                                    height: 80,
                                                                                    borderRadius:
                                                                                        '4px !important',
                                                                                    p: 2,
                                                                                    backgroundColor:
                                                                                        '#F9F9FF',
                                                                                    '& svg':
                                                                                        {
                                                                                            width: 64,
                                                                                            height: 64,
                                                                                        },
                                                                                }}
                                                                            >
                                                                                <NewsImagePlaceholder />
                                                                            </BorderedBox>
                                                                        )}
                                                                    </Box>
                                                                )}
                                                                {s.answerSource ==
                                                                    'USER_DATA' && (
                                                                    <Box
                                                                        sx={{
                                                                            display:
                                                                                'content',
                                                                            width: 64,
                                                                        }}
                                                                    >
                                                                        {
                                                                            DocumentImageLight
                                                                        }
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        </BorderedBox>
                                                    </FadeIn>
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                </Box>
                            </Box>
                            <Dialog
                                PaperComponent={Paper}
                                PaperProps={{
                                    sx: {
                                        width: appContext?.isMobile
                                            ? '100%'
                                            : '70%',
                                    },
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <DialogTitle pb={0}>
                                    <Box>
                                        <Box display="flex">
                                            <Typography variant="h4">
                                                Sources
                                            </Typography>
                                            <Tooltip
                                                arrow
                                                placement="top"
                                                title={
                                                    copied
                                                        ? 'Copied to clipboard'
                                                        : 'Copy'
                                                }
                                            >
                                                <Button
                                                    disabled={!streamFinished}
                                                    sx={{
                                                        minWidth: 40,
                                                        '&:hover': {
                                                            backgroundColor:
                                                                '#fff',
                                                        },
                                                        '&:hover path': {
                                                            fill: '#000',
                                                        },
                                                    }}
                                                    onMouseLeave={() => {
                                                        setTimeout(() => {
                                                            setCopied(false);
                                                        }, 100);
                                                    }}
                                                    onClick={(event: any) => {
                                                        event.stopPropagation();
                                                        handleCopyToClipboard();
                                                        setCopied(true);
                                                    }}
                                                >
                                                    <Copy />
                                                </Button>
                                            </Tooltip>
                                            <IconButton
                                                aria-label="close"
                                                onClick={handleClose}
                                                sx={{
                                                    position: 'absolute',
                                                    right: 24,
                                                    top: 24,
                                                    color: (theme) =>
                                                        theme.palette.grey[500],
                                                }}
                                            >
                                                <Tooltip
                                                    arrow
                                                    placement="top"
                                                    title={`Close`}
                                                >
                                                    <CloseIcon />
                                                </Tooltip>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </DialogTitle>
                                <DialogContent sx={{ pr: 2 }}>
                                    <Box textAlign="left">
                                        <FadeIn delay={200}>
                                            {sources?.map(
                                                (
                                                    s: LiveQueryResponseDataSourcesResult,
                                                    index
                                                ) => (
                                                    <Box py={2}>
                                                        <Box
                                                            id={
                                                                'citation-' +
                                                                (index + 1)
                                                            }
                                                        >
                                                            <Box
                                                                minHeight={30}
                                                                display="flex"
                                                            >
                                                                <Typography
                                                                    mt={0.5}
                                                                    lineHeight={
                                                                        '20px'
                                                                    }
                                                                    variant="h6"
                                                                >
                                                                    {index + 1}.
                                                                </Typography>
                                                                {s.answerSource ==
                                                                    'ARTICLE' && (
                                                                    <img
                                                                        style={{
                                                                            maxWidth: 20,
                                                                            maxHeight: 16,
                                                                            marginTop:
                                                                                '4px',
                                                                            marginLeft:
                                                                                '4px',
                                                                            marginRight:
                                                                                '4px',
                                                                        }}
                                                                        src={
                                                                            s.icon
                                                                        }
                                                                    />
                                                                )}
                                                                {s.answerSource ==
                                                                    'USER_DATA' && (
                                                                    <FileIcon
                                                                        size={
                                                                            20
                                                                        }
                                                                        type={
                                                                            UtilsService.getFileType(
                                                                                s.fileName
                                                                            )
                                                                                .length <=
                                                                            3
                                                                                ? UtilsService.getFileType(
                                                                                      s.fileName
                                                                                  )
                                                                                : UtilsService.getFileType(
                                                                                      s.fileName
                                                                                  )
                                                                        }
                                                                        textBackgroundColor="#29319B"
                                                                    />
                                                                )}
                                                                <Typography
                                                                    className="clamp-line-1"
                                                                    variant="h6"
                                                                    mt={0.5}
                                                                    lineHeight={
                                                                        '20px'
                                                                    }
                                                                >
                                                                    {s.answerSource ==
                                                                    'ARTICLE'
                                                                        ? s?.source
                                                                        : 'Document'}
                                                                </Typography>
                                                                <Typography
                                                                    ml="auto"
                                                                    mt={1}
                                                                    fontSize={
                                                                        14
                                                                    }
                                                                    color="#4B4B4C"
                                                                >
                                                                    {s &&
                                                                        s.answerSource ==
                                                                            'ARTICLE' &&
                                                                        UtilsService.formatStringDate(
                                                                            s?.datetime
                                                                        )}
                                                                    {s &&
                                                                        s.answerSource ==
                                                                            'USER_DATA' &&
                                                                        (s.pageNo
                                                                            ? 'Page ' +
                                                                              s.pageNo
                                                                            : UtilsService.formatStringDate(
                                                                                  s?.uploadedOn
                                                                              ))}
                                                                </Typography>
                                                            </Box>
                                                            <Typography
                                                                component={
                                                                    NavigationLink
                                                                }
                                                                to={
                                                                    s.answerSource ==
                                                                    'ARTICLE'
                                                                        ? s.url
                                                                        : '/document-insight/file/' +
                                                                          s.userFileId
                                                                }
                                                                target="_blank"
                                                                className="clamp-line-1"
                                                                variant="h6"
                                                                color="#29319B"
                                                            >
                                                                {s.answerSource ==
                                                                'ARTICLE'
                                                                    ? s?.title
                                                                    : s.fileName}
                                                            </Typography>
                                                            {!s.article && (
                                                                <Box mt={3}>
                                                                    <Skeleton variant="text" />
                                                                    <Skeleton variant="text" />
                                                                    <Skeleton variant="text" />
                                                                </Box>
                                                            )}
                                                            {s.article && (
                                                                <Typography
                                                                    mt={2}
                                                                    className="clamp-line-4"
                                                                    fontSize={
                                                                        14
                                                                    }
                                                                    variant="subtitle1"
                                                                >
                                                                    {s.article}{' '}
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                        <Box pt={3}>
                                                            <Divider />
                                                        </Box>
                                                    </Box>
                                                )
                                            )}
                                        </FadeIn>
                                    </Box>
                                </DialogContent>
                            </Dialog>

                            {relatedQuestions.length > 0 && streamFinished && (
                                <Box mt={5} textAlign={'left'}>
                                    <Typography variant="h6" fontWeight={700}>
                                        Related Queries
                                    </Typography>
                                    <Box mt={4}>
                                        {relatedQuestions.map(
                                            (query: string) => (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        borderRadius: 2,
                                                        mt: 2,
                                                        py: 3,
                                                        px: 4,
                                                        justifyContent:
                                                            'space-between',
                                                        backgroundColor: '#FFF',
                                                        border: '1px solid #FFF',
                                                        boxShadow:
                                                            '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
                                                        '&:hover': {
                                                            border: '1px solid #D7D9EC',
                                                            cursor: 'pointer',
                                                            background:
                                                                'linear-gradient(132deg, rgba(41, 49, 155, 0.05) 0%, rgba(41, 49, 155, 0.00) 100%)',
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        processMessage(query);
                                                    }}
                                                >
                                                    <Typography variant="h6">
                                                        {query}
                                                    </Typography>
                                                    {NewTabArrow}
                                                </Box>
                                            )
                                        )}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )}
                    {userQuery && !editQuery && (
                        <Box
                            sx={{
                                position: 'fixed',
                                bottom: appContext?.isMobile ? 70 : 60,
                                right: 0,
                                margin: 'auto',
                                marginLeft: appContext?.isMobile
                                    ? 0
                                    : UtilsService.isDemoApp()
                                    ? '20%'
                                    : '23%',
                                width: appContext?.isMobile
                                    ? 'calc(100% - 32px)'
                                    : '60%',
                                left: appContext?.isMobile ? 16 : 0,
                            }}
                            onClick={() => {
                                setTimeout(() => {
                                    if (streamFinished)
                                        setExpandPeopleAlsoAsks(true);
                                }, 0);
                            }}
                            onBlur={() => {
                                setTimeout(() => {
                                    setExpandPeopleAlsoAsks(false);
                                }, 300);
                            }}
                        >
                            {!streamFinished && aiResponse && (
                                <Box>
                                    <Button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            window.activeMessageId = '';
                                            setStreamFinished(true);
                                        }}
                                        sx={{
                                            border: '1px solid #29319B',
                                            backgroundColor: '#EBECF6',
                                            borderRadius: 1,
                                            fontSize: 16,
                                            fontWeight: 400,
                                            '&:hover': {
                                                border: '1px solid #29319B',
                                                backgroundColor: '#FFF',
                                            },
                                            mb: 2,
                                        }}
                                        startIcon={Stop}
                                        variant="outlined"
                                    >
                                        Stop Generating
                                    </Button>
                                </Box>
                            )}
                            <Box display="flex">
                                <Box
                                    sx={{
                                        paddingLeft: appContext?.isMobile
                                            ? '0px'
                                            : window.location.href.indexOf(
                                                  'live'
                                              ) > -1
                                            ? '72px'
                                            : '0px',
                                        width: `calc(100% - ${
                                            appContext?.isMobile
                                                ? 0
                                                : window.location.href.indexOf(
                                                      'live'
                                                  ) > -1
                                                ? 105
                                                : 105
                                        }px)`,
                                    }}
                                >
                                    {expandPeopleAlsoAsks &&
                                        streamFinished &&
                                        relatedSearches.length > 0 && (
                                            <Box
                                                sx={{
                                                    backgroundColor: '#fff',
                                                    borderRadius:
                                                        '16px 16px 0px 0px',
                                                    borderTop:
                                                        '1px solid #29319B',
                                                    borderLeft:
                                                        '1px solid #29319B',
                                                    borderRight:
                                                        '1px solid #29319B',
                                                }}
                                                textAlign={'left'}
                                            >
                                                {relatedSearches.map(
                                                    (
                                                        query: string,
                                                        index: number
                                                    ) => (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                py: 3,
                                                                px: 4,
                                                                borderTopRightRadius:
                                                                    index == 0
                                                                        ? '16px'
                                                                        : '',
                                                                borderTopLeftRadius:
                                                                    index == 0
                                                                        ? '16px'
                                                                        : '',
                                                                borderTop:
                                                                    index == 0
                                                                        ? 'none'
                                                                        : '1px solid #A5A6AB',
                                                                backgroundColor:
                                                                    '#FFF',
                                                                '&:hover': {
                                                                    cursor: 'pointer',
                                                                    background:
                                                                        'linear-gradient(132deg, rgba(41, 49, 155, 0.05) 0%, rgba(41, 49, 155, 0.00) 100%)',
                                                                },
                                                            }}
                                                            onClick={(
                                                                event
                                                            ) => {
                                                                processMessage(
                                                                    query
                                                                );
                                                                event.stopPropagation();
                                                                event.preventDefault();
                                                            }}
                                                        >
                                                            <SearchIcon
                                                                sx={{
                                                                    fontSize: 28,
                                                                    color: '#4B4B4C',
                                                                }}
                                                            />

                                                            <Typography
                                                                color="#4B4B4C"
                                                                ml={2}
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    processMessage(
                                                                        query
                                                                    );
                                                                    event.stopPropagation();
                                                                    event.preventDefault();
                                                                }}
                                                                variant="h6"
                                                            >
                                                                {query}
                                                            </Typography>
                                                        </Box>
                                                    )
                                                )}
                                            </Box>
                                        )}
                                    <Input
                                        type="text"
                                        mt={0}
                                        onClick={(e: any) => {
                                            if (!streamFinished) {
                                                e.stopPropagation();
                                            }
                                        }}
                                        noShadow={true}
                                        inputRef={inputRef}
                                        background="linear-gradient(131deg, #F5F6FF 0%, #FFF 100%)"
                                        borderRadius={
                                            expandPeopleAlsoAsks &&
                                            streamFinished &&
                                            relatedSearches.length > 0
                                                ? '0px 0px 16px 16px'
                                                : '16px'
                                        }
                                        borderColor={
                                            expandPeopleAlsoAsks
                                                ? '#29319B !important'
                                                : '#A5A6AB'
                                        }
                                        sx={{
                                            boxShadow:
                                                '0px 10px 20px -10px rgba(0, 0, 0, 0.50)',
                                            pl: 2,
                                            '&:hover': {
                                                boxShadow:
                                                    '0px 10px 30px -10px rgba(0, 0, 0, 0.50)',
                                                '& fieldset': {
                                                    border: '1px solid #29319B !important',
                                                },
                                            },
                                        }}
                                        value={queryMessage}
                                        onChange={(e) => {
                                            setQueryMessage(e.target.value);
                                        }}
                                        onEnterPress={sendMessage}
                                        inputPadding={'10.5px 14px 10.5px 0px'}
                                        startAdornment={
                                            <Button
                                                sx={{
                                                    px: 2,
                                                    py: 1,
                                                    fontSize: 18,
                                                    borderRadius: 10,
                                                    minWidth: 10,
                                                }}
                                                variant="text"
                                                disabled={
                                                    !queryMessage ||
                                                    !streamFinished
                                                }
                                                onClick={sendMessage}
                                            >
                                                <SearchIcon
                                                    sx={{ fontSize: 28 }}
                                                />
                                            </Button>
                                        }
                                        endAdornment={
                                            <Box
                                                display={'flex'}
                                                alignItems={'center'}
                                                sx={{
                                                    pr: 2,
                                                }}
                                            >
                                                {queryMessage && (
                                                    <Box
                                                        display={'flex'}
                                                        alignItems={'center'}
                                                    >
                                                        <IconButton
                                                            sx={{
                                                                minWidth: 0,
                                                                padding: 0,
                                                                marginRight:
                                                                    '8px',
                                                                width: 'auto',
                                                            }}
                                                            onClick={() =>
                                                                setQueryMessage(
                                                                    ''
                                                                )
                                                            }
                                                        >
                                                            <Close
                                                                color="#4B4B4C"
                                                                size={24}
                                                            />
                                                        </IconButton>
                                                        {!appContext?.isMobile && (
                                                            <Divider
                                                                sx={{
                                                                    marginRight:
                                                                        '8px',
                                                                    height: '40px',
                                                                }}
                                                                orientation="vertical"
                                                                flexItem
                                                            />
                                                        )}
                                                    </Box>
                                                )}

                                                {!appContext?.isMobile && (
                                                    <Filters
                                                        setFiltersfetched={
                                                            setFiltersfetched
                                                        }
                                                        min={true}
                                                        filtersFetched={
                                                            filtersFetched
                                                        }
                                                        onFiltersUpdate={() => {}}
                                                    />
                                                )}
                                            </Box>
                                        }
                                        placeholder="Type your query here..."
                                    />
                                </Box>
                                {window.location.href.indexOf('live') > -1 &&
                                    !appContext?.isMobile && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: 2,
                                                left: 0,
                                            }}
                                        >
                                            <Button
                                                onClick={() => navigate(`/`)}
                                                sx={{
                                                    border: '1px solid #29319B',
                                                    backgroundColor: '#EBECF6',
                                                    borderRadius: 2,
                                                    fontSize: 16,
                                                    minWidth: '54px !important',
                                                    maxHeight: '50px',
                                                    fontWeight: 600,
                                                    '&:hover': {
                                                        border: '1px solid #29319B',
                                                        backgroundColor: '#FFF',
                                                    },
                                                    '& path': {
                                                        fill: '#29319B',
                                                    },
                                                    ml: 2,
                                                    py: 2.5,
                                                    px: 1,
                                                }}
                                                variant="outlined"
                                            >
                                                <HomeIcon />
                                            </Button>
                                        </Box>
                                    )}
                                {!appContext?.isMobile && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            bottom: 2,
                                            right: -8,
                                        }}
                                    >
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                resetChat();
                                            }}
                                            sx={{
                                                border: '1px solid #29319B',
                                                backgroundColor: '#EBECF6',
                                                borderRadius: 2,
                                                fontSize: 16,
                                                fontWeight: 600,
                                                '&:hover': {
                                                    border: '1px solid #29319B',
                                                    backgroundColor: '#FFF',
                                                },
                                                '& path': {
                                                    fill: '#29319B',
                                                },
                                                ml: 2,
                                                py: 2.5,
                                            }}
                                            startIcon={Reset}
                                            variant="outlined"
                                        >
                                            Reset
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                    <div ref={messagesEndRef} />
                </Box>
            </Box>
        </Box>
    );
};
