interface IProps {
    size?: number;
    color?: string;
}

export const Info = (props: IProps) => (
    <svg
        width={props.size || '15'}
        height={props.size || '15'}
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_6925_60004"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width={props.size || '15'}
            height={props.size || '15'}
        >
            <rect x="0.5" width="14" height="14" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_6925_60004)">
            <path
                d="M7.50228 9.91577C7.66756 9.91577 7.8061 9.85987 7.9179 9.74806C8.02971 9.63626 8.08561 9.49772 8.08561 9.33244V6.9991C8.08561 6.83383 8.02971 6.69529 7.9179 6.58348C7.8061 6.47167 7.66756 6.41577 7.50228 6.41577C7.337 6.41577 7.19846 6.47167 7.08665 6.58348C6.97485 6.69529 6.91895 6.83383 6.91895 6.9991V9.33244C6.91895 9.49772 6.97485 9.63626 7.08665 9.74806C7.19846 9.85987 7.337 9.91577 7.50228 9.91577ZM7.50228 5.2491C7.66756 5.2491 7.8061 5.1932 7.9179 5.0814C8.02971 4.96959 8.08561 4.83105 8.08561 4.66577C8.08561 4.50049 8.02971 4.36195 7.9179 4.25015C7.8061 4.13834 7.66756 4.08244 7.50228 4.08244C7.337 4.08244 7.19846 4.13834 7.08665 4.25015C6.97485 4.36195 6.91895 4.50049 6.91895 4.66577C6.91895 4.83105 6.97485 4.96959 7.08665 5.0814C7.19846 5.1932 7.337 5.2491 7.50228 5.2491ZM7.50228 12.8324C6.69533 12.8324 5.937 12.6793 5.22728 12.3731C4.51756 12.0668 3.9002 11.6512 3.3752 11.1262C2.8502 10.6012 2.43457 9.98383 2.12832 9.27411C1.82207 8.56438 1.66895 7.80605 1.66895 6.9991C1.66895 6.19216 1.82207 5.43383 2.12832 4.7241C2.43457 4.01438 2.8502 3.39702 3.3752 2.87202C3.9002 2.34702 4.51756 1.9314 5.22728 1.62515C5.937 1.3189 6.69533 1.16577 7.50228 1.16577C8.30922 1.16577 9.06756 1.3189 9.77728 1.62515C10.487 1.9314 11.1044 2.34702 11.6294 2.87202C12.1544 3.39702 12.57 4.01438 12.8762 4.7241C13.1825 5.43383 13.3356 6.19216 13.3356 6.9991C13.3356 7.80605 13.1825 8.56438 12.8762 9.27411C12.57 9.98383 12.1544 10.6012 11.6294 11.1262C11.1044 11.6512 10.487 12.0668 9.77728 12.3731C9.06756 12.6793 8.30922 12.8324 7.50228 12.8324ZM7.50228 11.6658C8.80506 11.6658 9.90853 11.2137 10.8127 10.3095C11.7169 9.40535 12.1689 8.30188 12.1689 6.9991C12.1689 5.69633 11.7169 4.59285 10.8127 3.68869C9.90853 2.78452 8.80506 2.33244 7.50228 2.33244C6.1995 2.33244 5.09603 2.78452 4.19186 3.68869C3.2877 4.59285 2.83561 5.69633 2.83561 6.9991C2.83561 8.30188 3.2877 9.40535 4.19186 10.3095C5.09603 11.2137 6.1995 11.6658 7.50228 11.6658Z"
                fill={props.color || '#000033'}
            />
        </g>
    </svg>
);
