import React, { useState } from 'react';
import { Input } from '.';
import { IconButton, InputAdornment } from '@mui/material';
import { ShowPassword } from '../../icons/show-password';
import { HidePassword } from '../../icons/hide-password';

interface IProps {
    handlePasswordChange?:
        | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
        | undefined;
    onEnterPress?: Function | undefined;
    placeholder: string;
    title?: string;
}

export const PasswordInput = (props: IProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <Input
            title={props.title}
            placeholder={props.placeholder}
            type={showPassword ? 'text' : 'password'}
            onChange={props.handlePasswordChange}
            onEnterPress={props.onEnterPress}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={(
                            event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                            event.preventDefault();
                        }}
                        sx={{
                            p: 0,
                            mr: 4,
                        }}
                        edge="end"
                    >
                        {showPassword ? HidePassword : ShowPassword}
                    </IconButton>
                </InputAdornment>
            }
        />
    );
};
