import {
    ReportModule,
    RiskTopic,
    RiskTopicQuestion,
    RiskTopicQuestionSource,
} from '../../../services/interfaces';
import { BorderedBox } from '../../../components/core/box';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Paper,
    TableCell,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import { Question } from '../../../components/icons/question';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FadeIn from 'react-fade-in';
import { NavigationLink } from '../../../components/core/link';
import { useState } from 'react';
import { NoData } from './no-data';
import { LiveResponse } from '../../../components/live/live-response';

interface ISASBSourcesProps {
    sources: RiskTopicQuestionSource[];
}

const SASBSources = (props: ISASBSourcesProps) => {
    const { sources } = props;
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Button
                aria-label="close"
                onClick={() => {
                    setOpen(true);
                }}
                sx={{
                    color: '#003',
                    mx: 3,
                }}
                startIcon={
                    <FormatListBulletedIcon
                        sx={{
                            fontSize: 20,
                            color: '#29319B',
                        }}
                    />
                }
            >
                View sources
            </Button>
            <Dialog
                PaperComponent={Paper}
                PaperProps={{
                    sx: {
                        width: '100%',
                    },
                }}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle pb={0}>
                    <Box>
                        <Box display="flex">
                            <Typography variant="h4">Sources</Typography>

                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 24,
                                    top: 24,
                                    color: '#29319B',
                                }}
                            >
                                <Tooltip arrow placement="top" title={`Close`}>
                                    <CloseIcon />
                                </Tooltip>
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box textAlign="left">
                        <FadeIn delay={200}>
                            {sources?.map(
                                (s: RiskTopicQuestionSource, index) => (
                                    <Box py={2}>
                                        <Box id={'citation-' + (index + 1)}>
                                            <Box minHeight={30} display="flex">
                                                <Typography
                                                    mt={0.5}
                                                    lineHeight={'20px'}
                                                    variant="h6"
                                                >
                                                    {index + 1}.
                                                </Typography>
                                                <Typography
                                                    pl={1}
                                                    fontWeight={600}
                                                    variant="h6"
                                                >
                                                    {s.sourceName}
                                                </Typography>
                                            </Box>
                                            <Typography
                                                component={NavigationLink}
                                                to={s.url}
                                                target="_blank"
                                                className="clamp-line-1"
                                                variant="h6"
                                                color="#29319B"
                                            >
                                                {s?.title}
                                            </Typography>

                                            {s.summary && (
                                                <Typography
                                                    mt={2}
                                                    className="clamp-line-4"
                                                    fontSize={14}
                                                    variant="subtitle1"
                                                >
                                                    {s.summary}{' '}
                                                </Typography>
                                            )}
                                        </Box>
                                        <Box pt={3}>
                                            <Divider />
                                        </Box>
                                    </Box>
                                )
                            )}
                        </FadeIn>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

interface IProps {
    data: ReportModule;
}

export const SASB = (props: IProps) => {
    const { data } = props;
    const handleCitationClick = (
        sources: RiskTopicQuestionSource[],
        index: number
    ) => {
        if (!sources) return;
        let s = sources[index - 1];
        if (s) {
            window.open(s.url, '_blank');
        }
    };
    return (
        <BorderedBox
            sx={{
                backgroundColor: '#F9F9FF',
            }}
            pb={4}
            my={4}
        >
            <Typography px={4} pt={4} variant="h4">
                ESG Analysis
            </Typography>
            <Box
                className="scrollable"
                sx={{
                    maxHeight: 'calc(100vh - 220px)',
                    overflow: 'auto',
                }}
                px={4}
            >
                <Box mt={2} display="flex">
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: 600,
                            backgroundColor: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: 2,
                            px: 2,
                            py: 1,
                            mr: 2,
                        }}
                    >
                        Sector: {props.data.sectorName}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: 600,
                            backgroundColor: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: 2,
                            px: 2,
                            py: 1,
                            mr: 2,
                        }}
                    >
                        Industry: {props.data.industryName}
                    </Typography>
                </Box>
                {props.data.topics?.length ? (
                    <Box mt={2}>
                        {props.data.topics?.map((t: RiskTopic) => (
                            <Box my={4}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                        sx={{
                                            minHeight: '0px !important',
                                            '& .Mui-expanded': { mb: 1 },
                                        }}
                                    >
                                        <Typography variant="h6">
                                            {t.topicName}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box>
                                            {t.questions.map(
                                                (q: RiskTopicQuestion) => (
                                                    <Box
                                                        p={2}
                                                        my={4}
                                                        border="1px solid #ccc"
                                                        borderRadius={2}
                                                    >
                                                        <Box
                                                            justifyContent={
                                                                'space-between'
                                                            }
                                                            display="flex"
                                                        >
                                                            <Box pt={1.5}>
                                                                <Question
                                                                    color="#000"
                                                                    size={16}
                                                                />
                                                            </Box>
                                                            <Typography
                                                                mt={1}
                                                                width={
                                                                    'calc(100% - 25px)'
                                                                }
                                                            >
                                                                {q.question}
                                                                {q.answer ==
                                                                    'YES' && (
                                                                    <Box
                                                                        sx={{
                                                                            px: 2,
                                                                            py: 1,
                                                                            ml: 2,
                                                                            color: '#24A148',
                                                                            backgroundColor:
                                                                                'rgba(36, 161, 72, 0.20)',
                                                                            borderRadius: 1,
                                                                        }}
                                                                        component={
                                                                            'span'
                                                                        }
                                                                    >
                                                                        YES
                                                                    </Box>
                                                                )}
                                                                {q.answer ==
                                                                    'NO' && (
                                                                    <Box
                                                                        sx={{
                                                                            px: 2,
                                                                            py: 1,
                                                                            ml: 2,
                                                                            color: '#DA1E28',
                                                                            backgroundColor:
                                                                                'rgba(218, 30, 40, 0.30)',
                                                                            borderRadius: 1,
                                                                        }}
                                                                        component={
                                                                            'span'
                                                                        }
                                                                    >
                                                                        NO
                                                                    </Box>
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            justifyContent={
                                                                'space-between'
                                                            }
                                                            display="flex"
                                                            mt={1}
                                                        >
                                                            <Box
                                                                mt={2.5}
                                                                pt={1.5}
                                                            >
                                                                <TaskAltIcon
                                                                    sx={{
                                                                        fontSize: 16,
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box
                                                                width={
                                                                    'calc(100% - 25px)'
                                                                }
                                                            >
                                                                <Typography
                                                                    mt={1}
                                                                >
                                                                    <LiveResponse
                                                                        onCitationClick={(
                                                                            index
                                                                        ) => {
                                                                            handleCitationClick(
                                                                                q.sources,
                                                                                index
                                                                            );
                                                                        }}
                                                                        text={
                                                                            q.answerExplanation
                                                                        }
                                                                    />
                                                                </Typography>
                                                                <Box display="flex">
                                                                    <Typography
                                                                        fontSize={
                                                                            12
                                                                        }
                                                                        fontWeight={
                                                                            600
                                                                        }
                                                                        color="#A5A6AB"
                                                                        mt={2}
                                                                    >
                                                                        SASB
                                                                        Code(s):
                                                                        <Box
                                                                            color="#29319B"
                                                                            sx={{
                                                                                textDecoration:
                                                                                    'underline',
                                                                            }}
                                                                            component={
                                                                                'span'
                                                                            }
                                                                        >
                                                                            {' '}
                                                                            {
                                                                                q.SASBCodes
                                                                            }
                                                                        </Box>
                                                                    </Typography>
                                                                    <SASBSources
                                                                        sources={
                                                                            q.sources
                                                                        }
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )
                                            )}
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <NoData />
                )}
            </Box>
        </BorderedBox>
    );
};
