export const NotificationIcon = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_9744_59611"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_9744_59611)">
            <path
                d="M4.82804 19.0901C4.37073 19.0901 4 18.7194 4 18.2621V18.2621C4 17.8047 4.37073 17.434 4.82804 17.434H5.99587V9.19576C5.99587 7.7109 6.45413 6.39803 7.37065 5.25713C8.2872 4.11624 9.46383 3.38655 10.9006 3.06808V2.38014C10.9006 1.99679 11.0346 1.67093 11.3027 1.40258C11.5708 1.13422 11.8964 1.00003 12.2794 1.00003C12.6624 1.00003 12.9884 1.13422 13.2573 1.40258C13.5263 1.67093 13.6607 1.99679 13.6607 2.38014V3.06808C15.0975 3.38655 16.2741 4.11624 17.1907 5.25713C18.1072 6.39803 18.5654 7.7109 18.5654 9.19576V17.434H19.7333C20.1906 17.434 20.5613 17.8047 20.5613 18.2621V18.2621C20.5613 18.7194 20.1906 19.0901 19.7333 19.0901H4.82804ZM12.2788 22.19C11.7294 22.19 11.2597 21.9946 10.8698 21.6038C10.4798 21.2129 10.2848 20.7431 10.2848 20.1942H14.2765C14.2765 20.7448 14.0809 21.2151 13.6897 21.6051C13.2985 21.995 12.8282 22.19 12.2788 22.19ZM7.65195 17.434H16.9094V9.19576C16.9094 7.91755 16.4575 6.82655 15.5537 5.92276C14.6499 5.01895 13.5589 4.56705 12.2807 4.56705C11.0025 4.56705 9.91145 5.01895 9.00766 5.92276C8.10385 6.82655 7.65195 7.91755 7.65195 9.19576V17.434Z"
                fill="#000033"
            />
        </g>
    </svg>
);
