import React, { useEffect, useState } from 'react';
import { ChatMessage, IncomingMessageWrapper } from '.';
import { ResponseSources } from './response-sources';
import {
    DocumentInsightChatSource,
    IMessage,
} from '../../../services/interfaces';
import { LiveResponse } from '../../../components/live/live-response';

interface IProps {
    message: IMessage;
    onCopyToClipBoardClick?: () => void;
    onUpVoteClick?: () => void;
    onDownVoteClick?: () => void;
    onBookmarkClick?: () => void;
}

export const IncomingMesage = (props: IProps) => {
    const [selectedCitationIndex, setSelectedCitationIndex] = useState<
        number | undefined
    >(undefined);
    const { message } = props;
    const handleCitationClick = (citationIndex: number) => {
        setSelectedCitationIndex(citationIndex);
    };
    return (
        <IncomingMessageWrapper>
            <ChatMessage
                sx={{
                    '& .markdown p': {
                        fontSize: '14px !important',
                    },
                    '& .markdown li': {
                        fontSize: '14px !important',
                    },
                    '& .markdown td': {
                        fontSize: '14px !important',
                    },
                    '& .markdown ul': {
                        paddingLeft: '20px !important',
                    },
                }}
            >
                {message.data.answer && (
                    <LiveResponse
                        onCitationClick={handleCitationClick}
                        text={message.data.answer}
                    />
                )}
            </ChatMessage>
            {message.data.sources && (
                <ResponseSources
                    onCopyToClipBoardClick={props.onCopyToClipBoardClick}
                    // onBookmarkClick={props.onBookmarkClick}
                    messageId={props.message.messageID}
                    onUpVoteClick={props.onUpVoteClick}
                    onDownVoteClick={props.onDownVoteClick}
                    isBookmarked={message.isBookmarked}
                    vote={message.vote}
                    selectedCitationIndex={selectedCitationIndex}
                    sources={
                        message.data.sources as DocumentInsightChatSource[]
                    }
                />
            )}
        </IncomingMessageWrapper>
    );
};
