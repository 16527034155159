import { Constants } from '../constants';
import { Granularity, useInsightsService } from './insights.service';
import {
    ArticleDetailsRequest,
    ArticleDetailsResponse,
    ArticleInsight,
    ArticleInsightsResponse,
    ArticlePotentialIndicatorsResponse,
    ArticleTopicDetailsRequest,
    IAccountAccessToken,
    IAccountAccessTokensResponse,
    ICreateAccountAccessTokensResponse,
    ITrustedDomain,
    ITrustedDomainListResponse,
    Insight,
    PotentialSentiment,
} from './interfaces';
import {
    INetworkResponse,
    NetworkRequestMethod,
    useNetworkAPI,
} from './network.service';

export const useAccountService = () => {
    const { triggerRequest } = useNetworkAPI();
    const { processPotentialIndicatorsResponse } = useInsightsService();

    const fetchAccountAccessTokens = async (): Promise<
        IAccountAccessToken[]
    > => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IAccountAccessTokensResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: Constants.getAccessTokens,
                retryCount: 3,
            });
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const fetchDomainsList = async (): Promise<ITrustedDomain[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: ITrustedDomainListResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: Constants.fetchUserDomainList,
                retryCount: 3,
            });
            if (result.body.success) {
                return result.body.data.trustedDomains;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const deleteDomainlist = async (id: string): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: ITrustedDomainListResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.DELETE,
                headers,
                url: `${Constants.deleteUserDomainList}/${id}`,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const saveDomainList = async (data: ITrustedDomain): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body = data;
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.upsertUserDomainList,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const createAccessToken = async (
        tokenName: string,
        validFor?: number
    ): Promise<IAccountAccessToken> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: any = {
                tokenName,
            };
            if (validFor) {
                body.validFor = validFor;
            } else {
                body.noExpiry = true;
            }
            const result: {
                body: ICreateAccountAccessTokensResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.generateAccessTokens,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const updateAccessToken = async (
        newTokenName: string,
        tokenId: string
    ): Promise<IAccountAccessToken> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: any = {
                newTokenName,
                tokenId,
            };
            const result: {
                body: ICreateAccountAccessTokensResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.updateAccessTokens,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };
    const deteteAccessToken = async (
        tokenId: string
    ): Promise<IAccountAccessToken> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: ICreateAccountAccessTokensResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.DELETE,
                    headers,
                    url: Constants.deleteAccessTokens + '/' + tokenId,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            if (result.body.success) {
                return result.body.data;
            } else {
                throw result.body.error;
            }
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    return {
        createAccessToken,
        fetchAccountAccessTokens,
        deteteAccessToken,
        updateAccessToken,
        saveDomainList,
        fetchDomainsList,
        deleteDomainlist,
    };
};
