import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';
import { DocumentFile } from '../../../services/interfaces';
import { DocumentInsightsContext } from '../../../providers/document-provider';
import { useDocumentService } from '../../../services/document.service';
import { DeleteImg } from '../../../components/icons/delete-img';
import { useNavigate, useParams } from 'react-router-dom';
import {
    documentInsightFileDeleteCancel,
    documentInsightFileDeleteConfirm,
} from '../../../analytics/analytics';
import { UtilsService } from '../../../services/utils.service';
interface IProps {
    userFileGroupId?: string;
    open: boolean;
    onClose: () => void;
    file: DocumentFile;
}

const DialogWrapper = styled(Paper)(() => ({
    width: '40%',
    maxWidth: '450px !important',
}));

export const DeleteData = (props: IProps) => {
    const { typeId } = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(props.open);
    const documentContext = useContext(DocumentInsightsContext);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const { deleteFileGroup, fetchFiles, deleteDocument } =
        useDocumentService();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleDelete = async () => {
        if (!documentContext) return;
        documentInsightFileDeleteConfirm(props.file.userFileId);
        setIsDeleting(true);
        let res = await deleteDocument(props.file.userFileId);
        if (res) {
            if (typeId == props.file.userFileId) {
                navigate('/document-insight');
            }
            documentContext.setRefreshCount(documentContext.refreshCount + 1);
            enqueueSnackbar(
                `${UtilsService.trimText(
                    props.file.fileName,
                    35
                )} deleted successfully`
            );
            handleClose();
        }
        setIsDeleting(false);
    };

    const handleClose = () => {
        setOpen(false);
        props.onClose();
        documentInsightFileDeleteCancel(props.file.userFileId);
    };
    return (
        <Box>
            <Dialog
                PaperComponent={DialogWrapper}
                open={open}
                onClose={handleClose}
            >
                <DialogContent>
                    <Box textAlign="center">
                        <Box>{DeleteImg}</Box>
                        <Typography
                            color="#000033"
                            mb={2}
                            textAlign="center"
                            variant="h4"
                        >
                            Delete{' '}
                            {UtilsService.trimText(props.file.fileName, 30)}{' '}
                            File?
                        </Typography>
                        <Typography
                            color="#000033"
                            mb={2}
                            fontSize={14}
                            textAlign="center"
                            variant="caption"
                        >
                            You are about to delete a File from document
                            Insights. Once deleted, you will lose the insights
                            for the file.
                        </Typography>
                    </Box>
                    <Box></Box>
                </DialogContent>
                <DialogActions>
                    <Grid px={6} py={4} spacing={6} container>
                        <Grid xs={6} item>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid xs={6} item>
                            <Button
                                fullWidth
                                variant="contained"
                                disabled={isDeleting}
                                onClick={handleDelete}
                            >
                                {isDeleting ? (
                                    <CircularProgress
                                        size={25}
                                        sx={{
                                            color: '#ffffff',
                                            ml: 2,
                                        }}
                                    />
                                ) : (
                                    'Delete'
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
