import {
    Avatar,
    Box,
    Grid,
    Pagination,
    PaginationItem,
    Stack,
    Typography,
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import LinearProgress, {
    linearProgressClasses,
} from '@mui/material/LinearProgress';
import styled from '@emotion/styled';
import { Sort, SortOrder } from '../../../../components/icons/sort';
import { Expand } from '../../../../components/icons/expand';
import { BorderedContainedBox } from '../../../../components/core/box';
import {
    NegativeLinearProgress,
    PositiveLinearProgress,
} from '../../../../components/progress';
import {
    BorderedButton,
    BorderedLightButton,
} from '../../../../components/core/button';
import { useArticleService } from '../../../../services/article.service';
import { ArticleInsight } from '../../../../services/interfaces';
import { UtilsService } from '../../../../services/utils.service';
import { NavigationLink } from '../../../../components/core/link';
import { TextHighlight } from '../../../../components/sentiment-highlights';
import {
    articleListPageClick,
    goToArticlePageClick,
} from '../../../../analytics/analytics';

interface IProps {
    level: string;
    topicSubscriptionId?: string;
    filter1?: string;
    filter2?: string;
}

export const DetailedInformation = (props: IProps) => {
    const { fetchArticleDetails, fetchTopicArticleDetails } =
        useArticleService();
    const { level, topicSubscriptionId, filter1, filter2 } = props;
    const [insights, setInsights] = useState<ArticleInsight[]>([]);
    const [pagesCount, setPagesCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const refreshArticleDetails = async () => {
        if (filter1) {
            let res: { data: ArticleInsight[]; count: string } =
                await fetchArticleDetails(filter1, currentPage, filter2);
            setInsights([...res.data]);
            setPagesCount(Math.ceil(parseInt(res.count) / 10));
        }
    };
    const refreshTopicArticleDetails = async () => {
        if (topicSubscriptionId && filter1 && filter2) {
            let res: { data: ArticleInsight[]; count: string } =
                await fetchTopicArticleDetails(
                    topicSubscriptionId,
                    filter2,
                    filter1,
                    currentPage
                );
            setInsights([...res.data]);
            setPagesCount(Math.ceil(parseInt(res.count) / 10));
        }
    };

    useEffect(() => {
        if (level == 'article') {
            refreshArticleDetails();
        } else {
            refreshTopicArticleDetails();
        }
    }, [currentPage, props]);

    return (
        <Box>
            {insights.map((insight: ArticleInsight, index) => (
                <BorderedContainedBox key={index} my={4}>
                    <Grid spacing={8} container>
                        <Grid xs={12} sm={6} md={8} item>
                            <Box>
                                <Box sx={{ mb: 2 }}>
                                    <Typography
                                        sx={{
                                            color: '#000033',
                                            fontWeight: 600,
                                        }}
                                        variant="h4"
                                    >
                                        {insight.sourceName}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        highlight={insight.highlightIndexes}
                                        text={insight.summary}
                                        component={TextHighlight}
                                        variant="caption"
                                    ></Typography>
                                </Box>
                                <NavigationLink
                                    target="_blank"
                                    onClick={() => {
                                        if (filter2 && topicSubscriptionId) {
                                            goToArticlePageClick(
                                                topicSubscriptionId,
                                                '',
                                                filter2,
                                                insight.articleLink,
                                                filter1
                                            );
                                        }
                                    }}
                                    to={insight.articleLink}
                                >
                                    <BorderedButton
                                        sx={{
                                            mt: 4,
                                            backgroundColor: '#ffffff',
                                        }}
                                    >
                                        Go to Article page
                                    </BorderedButton>
                                </NavigationLink>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={6} md={4} item>
                            <Grid pt={4} spacing={8} container>
                                <Grid xs={12} sm={6} item>
                                    {insight.sentiment.positive && (
                                        <Box>
                                            <PositiveLinearProgress
                                                variant="determinate"
                                                value={UtilsService.convertSentimentToPercentage(
                                                    insight.sentiment.positive
                                                )}
                                            />
                                            <Typography
                                                sx={{ mt: 2 }}
                                                variant="h6"
                                            >
                                                {UtilsService.convertSentimentToPercentage(
                                                    insight.sentiment.positive
                                                )}
                                                %
                                            </Typography>
                                        </Box>
                                    )}
                                </Grid>
                                <Grid xs={12} sm={6} item>
                                    {insight.sentiment.negative && (
                                        <Box>
                                            <NegativeLinearProgress
                                                variant="determinate"
                                                value={UtilsService.convertSentimentToPercentage(
                                                    insight.sentiment.negative
                                                )}
                                            />
                                            <Typography
                                                sx={{ mt: 2 }}
                                                variant="h6"
                                            >
                                                {UtilsService.convertSentimentToPercentage(
                                                    insight.sentiment.negative
                                                )}
                                                %
                                            </Typography>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </BorderedContainedBox>
            ))}
            <Box sx={{ mt: 2 }}>
                <Stack spacing={2}>
                    <Pagination
                        sx={{ mr: 'auto' }}
                        count={pagesCount}
                        size="small"
                        onChange={(e, p) => {
                            setCurrentPage(p);
                            if (topicSubscriptionId && level && filter2) {
                                articleListPageClick(
                                    topicSubscriptionId,
                                    '',
                                    level,
                                    p.toString(),
                                    filter2,
                                    filter1
                                );
                            }
                        }}
                        renderItem={(item) => (
                            <PaginationItem
                                slots={{
                                    previous: ArrowBackIcon,
                                    next: ArrowForwardIcon,
                                }}
                                {...item}
                            />
                        )}
                    />
                </Stack>
            </Box>
        </Box>
    );
};
