import { Box } from '@mui/material';
import { EarningFilter } from '../../../../../services/interfaces';
import { Summary } from './summary';

interface IProps {
    selectedSECFilter: EarningFilter | null;
    topicSubscriptionId: string;
    query: string;
}

export const SECFilings = (props: IProps) => {
    return (
        <Box>
            <Box pt={4}>
                <Summary
                    selectedSECFilter={props.selectedSECFilter}
                    topicSubscriptionId={props.topicSubscriptionId}
                    query={props.query}
                />
            </Box>
        </Box>
    );
};
