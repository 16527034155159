export const NewTabArrow = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.94975 16.8482L16.8492 6.94869M16.8492 6.94869L7.44472 6.45372M16.8492 6.94869L17.3442 16.3532"
            stroke="#4B4B4C"
            stroke-width="1.5"
        />
    </svg>
);
