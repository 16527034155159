import { IChatbotConfig } from '../../../../services/interfaces';

export const DEFAULT_CHATBOT_CONFIG: IChatbotConfig = {
    title: '',
    status: 'ACTIVE',
    whiteListedDomains: [],
    rateLimit: 20,
    designConfig: {
        data: {
            version: '1',
            config: {
                appearance: {
                    colors: {
                        primary: '#29319B',
                        text: '#FFFFFF',
                    },
                    shape: {
                        container: {
                            width: 500,
                            radius: 8,
                        },
                        icon: {
                            size: 56,
                        },
                    },
                    images: {
                        chatIcon:
                            'https://photon-chat-widget.netlify.app/pi-logo.svg',
                        chatBot:
                            'https://photon-chat-widget.netlify.app/pi-logo.svg',
                    },
                    font: {
                        fontSize: 14,
                        fontFamily: 'open-sans',
                    },
                },
                behaviour: {
                    sourcesCitation: false,
                    autoOpen: {
                        frequency: 'ONCE_PER_VISIT',
                        delay: 10,
                    },
                },
                text: {
                    title: 'AI Bot',
                    inputPlaceholder: 'Type your question here...',
                    welcomeMessages: [
                        {
                            order: 0,
                            message: {
                                messageID: '000000',
                                vote: 'neutral',
                                isBookmarked: false,
                                messageType: 'INCOMING',
                                data: {
                                    answer: `<meta charset="utf-8" /><span style="white-space:pre-wrap">Hi there ! It's Photon AI bot. I am here to help you with anything you need.</span>`,
                                },
                            },
                        },
                    ],
                },
            },
        },
    },
    dataSourceConfig: {
        customData: {
            chatBotPrompt: 'DOCUMENT_INSIGHTS',
            fileTypes: [],
            userTagIds: [],
        },
        server: 'gpt-4o-mini',
        withCitations: false,
        answerFormat: 'BRIEF',
    },
    withCitations: false,
};
