import React, { useEffect, useState } from 'react';
import {
    IActiveSubscriptionResponseData,
    IAuthResponseData,
    Permission,
    SentimentChartData,
    Server,
    Topic,
    UserCluster,
    UserTopic,
} from '../services/interfaces';
import { UtilsService } from '../services/utils.service';
import { IMenuItem } from '../components/layout/menu-items/menu-item.interface';
import { useChatbotService } from '../services/chatbot.service';

//: (dateRange: DateRange) => void;

export interface AppFilters {
    date: {
        startDate: string;
        endDate: string;
    };
}

const DEFAULT_FILTERS: AppFilters = {
    date: {
        startDate: UtilsService.formatDateForNetworkCall(
            UtilsService.getDateWeeksAgo(2)
        ),
        endDate: UtilsService.formatDateForNetworkCall(new Date()),
    },
};

export interface AppContext {
    user: any;
    isMobile: boolean;
    category: string;
    servers: Server[];
    setServers: (servers: Server[]) => void;
    showHighlights: boolean;
    accountActionBannerVisible: boolean;
    menuItems: IMenuItem[];
    setMenuItems: (items: IMenuItem[]) => void;
    setShowHighlights: (showHighlights: boolean) => void;
    updateCategory: (category: string) => void;
    setUser: (user: IAuthResponseData) => void;
    setFilters: (filter: AppFilters) => void;
    setClusters: (clusters: UserCluster[]) => void;
    setMenuExpanded: (expanded: boolean) => void;
    setSubscriptions: (subscriptions: UserTopic[]) => void;
    clusters: UserCluster[] | null;
    filters: AppFilters;
    permissions: Permission[];
    setPermissions: (permissions: Permission[]) => void;
    menuExpanded: boolean;
    subscriptions: UserTopic[];
    subscriptionInfo?: IActiveSubscriptionResponseData;
    setSubscriptionInfo: (s?: IActiveSubscriptionResponseData) => void;
    topics: Topic[];
    setTopics: (topics: Topic[]) => void;
    getClusterTopics: (clusterId: string) => UserTopic[];
    setTopicSentiment: (
        topicSubscriptionId: string,
        sentiments: SentimentChartData
    ) => void;
}

export const AppContext = React.createContext<AppContext | null>(null);

interface IProps {
    children: any;
}

const AppProvider = (props: IProps) => {
    const { children } = props;
    const [category, setCategory] = useState('CAT762672153847094467');
    const [subscriptionInfo, setSubscriptionInfo] = useState<
        IActiveSubscriptionResponseData | undefined
    >(undefined);
    const [showHighlights, setShowHighlights] = useState(true);
    const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [servers, setServers] = useState<Server[]>([]);
    const [menuExpanded, setMenuExpanded] = React.useState(false);
    const [user, setUser] = useState<IAuthResponseData | null>(null);
    const [filters, setFilters] = useState<AppFilters>(DEFAULT_FILTERS);
    const [clusters, setClusters] = useState<UserCluster[] | null>(null);
    const [subscriptions, setSubscriptions] = useState<UserTopic[]>([]);
    const [isMobile, setIsMobile] = useState(false);
    const [topics, setTopics] = useState<Topic[]>([]);

    const [accountActionBannerVisible, setAccountActionBannerVisible] =
        useState(false);

    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= 850);
    }
    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        if (
            subscriptionInfo &&
            ((subscriptionInfo.status == 'trialing' &&
                !subscriptionInfo.payment_method_added) ||
                (subscriptionInfo.status == 'past_due' &&
                    subscriptionInfo.cancellation_date))
        ) {
            setAccountActionBannerVisible(true);
        } else {
            setAccountActionBannerVisible(false);
        }
    }, [subscriptionInfo]);

    const getClusterTopics = (clusterId: string): UserTopic[] => {
        if (!clusters) return [];
        let cluster = clusters.filter(
            (c) => c.dashboardClusterId == clusterId
        )[0];
        if (cluster) {
            return cluster.userTopicSubscriptions;
        }
        return [];
    };

    const updateCategory = (category: string) => {
        setCategory(category);
        resetClusters();
    };

    const resetClusters = () => {
        if (!clusters) return;
        let _clusters = [...clusters];
        for (let c of _clusters) {
            c.sentimentStatus = false;
            c.highlights = null;
        }
        setClusters([..._clusters]);
    };

    const setTopicSentiment = (
        topicSubscriptionId: string,
        sentiments: SentimentChartData
    ) => {
        let topics = [...subscriptions];
        for (let topic of topics) {
            if (topic.topicSubscriptionId == topicSubscriptionId) {
                topic.sentiments = sentiments;
            }
        }
        setSubscriptions([...topics]);
    };

    return (
        <AppContext.Provider
            value={{
                showHighlights,
                setShowHighlights,
                menuItems,
                permissions,
                setPermissions,
                category,
                updateCategory,
                user,
                filters,
                setFilters,
                setUser,
                isMobile,
                setClusters,
                setSubscriptions,
                clusters,
                subscriptions,
                topics,
                setMenuItems,
                setTopics,
                menuExpanded,
                setMenuExpanded,
                getClusterTopics,
                setTopicSentiment,
                servers,
                setServers,
                accountActionBannerVisible,
                subscriptionInfo,
                setSubscriptionInfo,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
