import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    InputBase,
    Paper,
    Tooltip,
    Typography,
    linearProgressClasses,
} from '@mui/material';
import React, { createRef, useContext, useEffect, useState } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import { Circle } from '../../../components/icons/circle';
import { FileIcon } from '../../../components/icons/file';
import { PositiveLinearProgress } from '../../../components/progress';
import {
    FileUploadStatus,
    useDocumentService,
} from '../../../services/document.service';
import { UtilsService } from '../../../services/utils.service';
import { useSnackbar } from 'notistack';
import { FileInfo } from '../drive/file';
import {
    AddFileRequest,
    EFileUploadType,
    FileUploadRequest,
    IFileUploadResponse,
    IFileUploadResponseChunkS3UploadLinkData,
    IFileUploadResponseChunkStreamError,
    IFileUploadResponseChunkURLTypeDetails,
    IFileUploadResponseChunkUploadProgressData,
} from '../../../services/interfaces';
import {
    DocumentInsightsContext,
    IUploadFile,
} from '../../../providers/document-provider';
import { documentInsightFileUploadSuccess } from '../../../analytics/analytics';
import { useLocation } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { DropzoneIcon } from '../../../components/icons/dropzone';
import { Close } from '../../../components/icons/close';
import { ErrorIcon } from '../../../components/icons/error';
import { Reload } from '../../../components/icons/reload';
import { KeyboardKeys } from '../../../components/core/enums/keyboard-keys.enum';
import { UploadFileIcon } from '../../../components/icons/upload-file';
import { YoutubeFileIcon } from '../../../components/icons/youtube-file-icon';
import { YouTube } from '../../../components/icons/youtube';
import { WebArticle } from '../../../components/icons/web-article';

interface IProps {
    urlData: IUploadUrl;
    skipUpload?: boolean;
    onRemoveFile: () => void;
}

interface IUploadUrl {
    url: string;
    id: string;
    uploaded?: boolean;
}

export const UrlUpload = (props: IProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const documentContext = useContext(DocumentInsightsContext);
    const { uploadFileToS3, submitFile, fetchUploadFileResponse } =
        useDocumentService();
    const [progress, setProgress] = useState<number>(0);
    const [urlType, setURLType] = useState<'WEB_ARTICLE' | 'PDF' | 'YOUTUBE'>(
        'WEB_ARTICLE'
    );
    const filePushRef = React.useRef(false);
    const [uploadStatus, setUploadStatus] = useState<FileUploadStatus | null>(
        null
    );
    const [fileStatus, setFileStatus] = useState<
        'IN_PROGRESS' | 'SUCCESS' | 'FAILED'
    >('IN_PROGRESS');
    const [error, setError] = useState<string>('');

    const uploadProgress = async (data: FileUploadStatus) => {
        let p = Math.floor(data.progress);
        setProgress(Math.max(p, progress));
        setUploadStatus(data);
    };

    useEffect(() => {
        if (filePushRef.current) return;
        filePushRef.current = true;
        if (!props.skipUpload) {
            uploadDocument();
        }
    }, [props.urlData.url]);

    const handleFileUploadResponse = async (res: string) => {
        let fileId = '';
        let error = false;
        for (let v of res.split('\n')) {
            try {
                let data = JSON.parse(v);
                switch (data.chunkType) {
                    case 'UPLOAD_PROGRESS': {
                        fileId = (
                            data.data as IFileUploadResponseChunkUploadProgressData
                        ).details.userFileId;

                        uploadProgress({
                            progress: (
                                data.data as IFileUploadResponseChunkUploadProgressData
                            ).value,
                        });
                        break;
                    }
                    case 'STREAM_ERROR': {
                        error = true;
                        setError(
                            (data.data as IFileUploadResponseChunkStreamError)
                                .message
                        );
                        break;
                    }
                    case 'URL_TYPE': {
                        setURLType(
                            (
                                data.data as IFileUploadResponseChunkURLTypeDetails
                            ).type
                        );
                        break;
                    }
                    case 'TERMINATION': {
                        if (error) {
                            setFileStatus('FAILED');
                            setProgress(100);
                            enqueueSnackbar('File upload failed', {
                                variant: 'error',
                            });
                        } else {
                            await submitFile([fileId]);
                            if (documentContext) {
                                documentContext.setRefreshCount(
                                    documentContext.refreshCount + 1
                                );
                            }
                            enqueueSnackbar(
                                `${UtilsService.trimText(
                                    props.urlData.url,
                                    35
                                )} uploaded successfully!`
                            );
                            setFileStatus('SUCCESS');
                        }
                        break;
                    }
                }
            } catch (error) {}
        }
    };

    const uploadDocument = () => {
        setFileStatus('IN_PROGRESS');
        setError('');
        setProgress(0);
        let data: FileUploadRequest = {
            url: props.urlData.url,
            upload: EFileUploadType.URL,
        };
        fetchUploadFileResponse(data, handleFileUploadResponse);
    };

    return (
        <Box>
            <Box my={2}>
                <Grid container>
                    <Grid item>
                        {urlType == 'PDF' && <UploadFileIcon />}
                        {urlType == 'YOUTUBE' && (
                            <Box pt={2}>
                                {' '}
                                <YouTube width={36} height={26} />
                            </Box>
                        )}
                        {urlType == 'WEB_ARTICLE' && WebArticle}
                    </Grid>

                    <Grid
                        sx={{
                            pl: 2,
                            width: `calc(100% - ${
                                fileStatus !== 'SUCCESS' ? 64 : 40
                            }px)`,
                        }}
                        item
                    >
                        <Box display="flex" justifyContent="space-between">
                            <Tooltip placement="top" title={props.urlData.url}>
                                <Typography
                                    className="clamp-line-1"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontSize: 14,
                                    }}
                                    color="#4B4B4C"
                                >
                                    {props.urlData.url}
                                </Typography>
                            </Tooltip>
                            {fileStatus == 'SUCCESS' && (
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M9.9974 18.3337C14.5998 18.3337 18.3307 14.6027 18.3307 10.0003C18.3307 5.39795 14.5998 1.66699 9.9974 1.66699C5.39502 1.66699 1.66406 5.39795 1.66406 10.0003C1.66406 14.6027 5.39502 18.3337 9.9974 18.3337ZM14.6297 7.91722C14.86 7.66005 14.8381 7.26492 14.581 7.03468C14.3238 6.80444 13.9287 6.82626 13.6984 7.08343L10.8908 10.2194C10.3219 10.8549 9.93873 11.2806 9.61116 11.5559C9.29912 11.8182 9.11584 11.8753 8.95573 11.8753C8.79562 11.8753 8.61234 11.8182 8.3003 11.5559C7.97273 11.2806 7.5896 10.8549 7.02068 10.2194L6.29637 9.4104C6.06613 9.15323 5.67101 9.1314 5.41384 9.36165C5.15667 9.59189 5.13484 9.98702 5.36508 10.2442L6.12034 11.0878C6.65021 11.6796 7.09218 12.1733 7.49602 12.5128C7.92322 12.8718 8.384 13.1253 8.95573 13.1253C9.52746 13.1253 9.98824 12.8718 10.4154 12.5128C10.8193 12.1733 11.2612 11.6797 11.7911 11.0878L14.6297 7.91722Z"
                                        fill="#19AF55"
                                    />
                                </svg>
                            )}
                            {fileStatus == 'FAILED' && (
                                <IconButton
                                    sx={{ p: 0, ml: 2 }}
                                    onClick={uploadDocument}
                                >
                                    <CachedIcon
                                        sx={{ fontSize: 16 }}
                                        color="error"
                                    />
                                </IconButton>
                            )}
                            {fileStatus == 'IN_PROGRESS' && (
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                    }}
                                    color="#A5A6AB"
                                >
                                    {progress}%
                                </Typography>
                            )}
                        </Box>
                        {!props.skipUpload && (
                            <PositiveLinearProgress
                                sx={{
                                    ml: 'auto',
                                    mt: 1,

                                    height: '6px !important',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor:
                                            fileStatus == 'FAILED'
                                                ? '#DA1E28 !important'
                                                : '#19AF55 !important',
                                    },
                                }}
                                variant={
                                    (progress > 0 && progress < 100) ||
                                    fileStatus == 'SUCCESS' ||
                                    fileStatus == 'FAILED'
                                        ? 'determinate'
                                        : 'indeterminate'
                                }
                                value={
                                    fileStatus == 'SUCCESS' ||
                                    fileStatus == 'FAILED'
                                        ? 100
                                        : progress
                                }
                            />
                        )}
                        <FileInfo>
                            {/* {UtilsService.getFileSize(
                                (props.fileData.file.size * progress) / 100
                            )}{' '}
                            of{' '}
                            {UtilsService.getFileSize(props.fileData.file.size)} */}
                            {fileStatus == 'SUCCESS' ? ' Completed' : ''}
                        </FileInfo>
                        {error && (
                            <Typography fontSize={12} color="error">
                                {error}
                            </Typography>
                        )}
                    </Grid>
                    {fileStatus !== 'SUCCESS' && (
                        <Grid item>
                            <IconButton
                                sx={{ p: 0, mt: 4.5, ml: 1 }}
                                onClick={props.onRemoveFile}
                            >
                                <Close color="#000" />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Divider />
        </Box>
    );
};

export const UrlUploadSection = () => {
    const documentContext = useContext(DocumentInsightsContext);
    const { pathname } = useLocation();
    const [urlInput, setUrlInput] = useState<string>('');
    const [isUploadingUrl, setIsUploadingUrl] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const [uploadUrls, setUploadUrls] = useState<IUploadUrl[]>([]);
    const [discardedFiles, setDiscardedFiles] = useState<string[]>([]);
    const container = createRef();
    useEffect(() => {
        if (container.current) {
            //@ts-ignore
            container.current.scrollTo(0, 0);
        }
    }, [pathname]);

    const uploadUrlClick = () => {
        if (!documentContext) return;
        if (UtilsService.isUrlValid(urlInput)) {
            let f: IUploadUrl = {
                url: urlInput,
                id: UtilsService.uuidv4(),
            };

            setUploadUrls([...uploadUrls, f]);
            setUrlInput('');
        } else {
            enqueueSnackbar('Enter valid url', {
                variant: 'error',
            });
        }
    };
    return (
        <Box minHeight={406}>
            <Box pb={4}>
                <Box textAlign={'left'}>
                    <Typography fontSize={14} fontWeight={400}>
                        Provide a link to any website article, and Quantum will
                        extract the content for you.
                    </Typography>
                    <Typography fontSize={14} fontWeight={400}>
                        <b>Note:</b> We support all{' '}
                        <b>web articles, YouTube links</b>, and{' '}
                        <b>online PDFs</b>.
                    </Typography>
                </Box>
                <Paper
                    elevation={0}
                    component="form"
                    sx={{
                        p: '8px !important',
                        mt: 4,
                        width: '100% !important',
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid #D7D9EC',
                        borderRadius: 2,
                    }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1, width: '100%' }}
                        placeholder={'Enter an URL here'}
                        value={urlInput}
                        onKeyDown={(
                            event: React.KeyboardEvent<
                                HTMLTextAreaElement | HTMLInputElement
                            >
                        ) => {
                            if (event.key == KeyboardKeys.ENTER) {
                                uploadUrlClick();
                                event.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            setUrlInput(e.target.value);
                        }}
                        inputProps={{ 'aria-label': 'Enter an URL here' }}
                    />
                    <Button
                        sx={{
                            px: 2,
                            fontSize: 12,
                            borderRadius: 2,
                            py: 1,
                        }}
                        onClick={uploadUrlClick}
                        disabled={
                            !UtilsService.isUrlValid(urlInput) || isUploadingUrl
                        }
                        variant="contained"
                    >
                        {isUploadingUrl ? (
                            <CircularProgress
                                size={20}
                                sx={{
                                    color: '#ffffff',
                                    ml: 2,
                                }}
                            />
                        ) : (
                            'Fetch'
                        )}
                    </Button>
                </Paper>
            </Box>

            <Box textAlign="left">
                {uploadUrls.length > 0 && (
                    <Typography variant="h6">Fetched Links</Typography>
                )}
                {uploadUrls.length > 0 && (
                    <Box
                        className="scrollable"
                        sx={{
                            borderRadius: 1,
                            maxHeight: '250px',
                            overflow: 'auto',
                            px: 1,
                        }}
                    >
                        {uploadUrls
                            .filter((f) => !discardedFiles.includes(f.id))
                            .map((file) => (
                                <UrlUpload
                                    onRemoveFile={() => {
                                        setDiscardedFiles([
                                            ...discardedFiles,
                                            file.id,
                                        ]);
                                    }}
                                    urlData={file}
                                />
                            ))}
                    </Box>
                )}
            </Box>
        </Box>
    );
};
