import { useState } from 'react';
import { Cluster } from '../../../../services/interfaces';
import { deleteGroupClick } from '../../../../analytics/analytics';
import { useClusterService } from '../../../../services/cluster.service';
import { useSnackbar } from 'notistack';
import {
    DeleteForeverOutlined,
    DeleteOutlineRounded,
} from '@mui/icons-material';
import {
    Box,
    Button,
    CircularProgress,
    Modal,
    Typography,
} from '@mui/material';
import { ErrorIcon } from '../../../../components/icons/error';
import { BorderedBox } from '../../../../components/core/box';

interface IProps {
    cluster: Cluster;
    refreshClusters: () => void;
}
export const DeleteWatchlist = (props: IProps) => {
    const [open, setOpen] = useState(false);
    const [processing, setProcessing] = useState(false);

    let cluster = props.cluster;
    const { enqueueSnackbar } = useSnackbar();
    const { deleteCluster } = useClusterService();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleDeleteCluster = async () => {
        if (cluster && cluster.clusterId) {
            setProcessing(true);
            const res = await deleteCluster(cluster.clusterId);
            setProcessing(false);
            deleteGroupClick(cluster.clusterName, cluster.clusterId);
            if (res) {
                enqueueSnackbar('Watchlist deleted Successfully');
                props.refreshClusters();
            }
            setOpen(false);
        }
    };

    return (
        <>
            <DeleteOutlineRounded
                sx={{
                    color: '#29319B',
                    cursor: 'pointer',
                }}
                onClick={handleOpen}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ backdropFilter: 'blur(2px)' }}
            >
                <BorderedBox
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: '#FFFFFF',
                        p: 5,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                        }}
                    >
                        {ErrorIcon}
                        <Typography variant="h3" my={4}>
                            Delete “<span>{cluster.clusterName}</span>”
                        </Typography>
                        <Typography fontSize="14px" align="center">
                            Are you sure you want to delete this watchlist? This
                            change can not be reverted.
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="right" mt={10}>
                        <Button
                            sx={{ color: '#4B4B4C', mr: 3 }}
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => handleDeleteCluster()}
                            disabled={processing}
                            color="error"
                        >
                            Delete
                            {processing && (
                                <CircularProgress
                                    size={14}
                                    sx={{ color: '#fff', ml: 1 }}
                                />
                            )}
                        </Button>
                    </Box>
                </BorderedBox>
            </Modal>
        </>
    );
};
