import * as rudderanalytics from 'rudder-sdk-js';
import { UtilsService } from '../services/utils.service';
import { CookieService } from '../services/cookie.service';
import { REF_CODE_KEY } from '../components/referal-tracker';
import posthog from 'posthog-js';
const {
    REACT_APP_RUDDERSTACK_WRITE_KEY,
    REACT_APP_RUDDERSTACK_DATAPANE_URL,
    REACT_APP_ENVIRONMENT,
} = process.env;
const cookieService = new CookieService();
const init = () => {
    rudderanalytics.ready(async () => {
        // alert('we are all set!!!');
        const userId = await cookieService.get('uid');
        const name = await cookieService.get('u');
        rudderanalytics.identify(userId || '');
        posthog.identify(
            userId || '', // Required. Replace 'distinct_id' with your user's unique identifier
            { name } // $set, optional
        );
    });
    if (REACT_APP_RUDDERSTACK_WRITE_KEY && REACT_APP_RUDDERSTACK_DATAPANE_URL) {
        rudderanalytics.load(
            REACT_APP_RUDDERSTACK_WRITE_KEY,
            REACT_APP_RUDDERSTACK_DATAPANE_URL
        );
    }
};

export const getCommonEventProperties =
    async (): Promise<rudderanalytics.apiObject> => {
        const refCode = await cookieService.get(REF_CODE_KEY);
        return {
            userId: await UtilsService.getUserId(),
            companyId: await UtilsService.getCompanyId(),
            env: REACT_APP_ENVIRONMENT,
            refCode,
        };
    };

const trackEvent = async (event: string, props: rudderanalytics.apiObject) => {
    if (await cookieService.get('uid')) {
        rudderanalytics.track(event, {
            ...props,
            ...(await getCommonEventProperties()),
        });
        // posthog.capture(event, {
        //     ...props,
        //     ...(await getCommonEventProperties()),
        // });
    }
};

export { rudderanalytics, init, trackEvent };
