import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    Grid,
    Collapse,
    IconButton,
} from '@mui/material';
import {
    BalanceSheet,
    CashFlow,
    IncomeStatement,
    ReportModule,
} from '../../../../../services/interfaces';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { UtilsService } from '../../../../../services/utils.service';
import { FinancialTrendsPlot } from './financial-trend';
import SingleSelectTab from '../../../../../components/core/input/single-select-tab';

const balanceSheetStructure = [
    {
        key: 'totalAssets',
        title: 'Total Assets',
        children: [
            {
                key: 'currentAssets',
                title: 'Current Assets',
                children: [
                    {
                        key: 'cashCashEquivalentsAndShortTermInvestments',
                        title: 'Cash, Cash Equivalents & Short Term Investments',
                        children: [
                            {
                                key: 'cashAndCashEquivalents',
                                title: 'Cash And Cash Equivalents',
                                children: [
                                    {
                                        key: 'cashFinancial',
                                        title: 'Cash',
                                        children: [],
                                    },
                                    {
                                        key: 'cashEquivalents',
                                        title: 'Cash Equivalents',
                                        children: [],
                                    },
                                ],
                            },
                            {
                                key: 'otherShortTermInvestments',
                                title: 'Other Short Term Investments',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'receivables',
                        title: 'Receivables',
                        children: [
                            {
                                key: 'accountsReceivable',
                                title: 'Accounts receivable',
                                children: [
                                    {
                                        key: 'grossAccountsReceivable',
                                        title: 'Gross Accounts Receivable',
                                        children: [],
                                    },
                                    {
                                        key: 'allowanceForDoubtfulAccountsReceivable',
                                        title: 'Allowance For Doubtful Accounts Receivable',
                                        children: [],
                                    },
                                ],
                            },
                            {
                                key: 'loansReceivable',
                                title: 'Loans Receivable',
                                children: [],
                            },
                            {
                                key: 'notesReceivable',
                                title: 'Notes Receivable',
                                children: [],
                            },
                            {
                                key: 'accruedInterestReceivable',
                                title: 'Accrued Interest Receivable',
                                children: [],
                            },
                            {
                                key: 'taxesReceivable',
                                title: 'Taxes Receivable',
                                children: [],
                            },
                            {
                                key: 'duefromRelatedPartiesCurrent',
                                title: 'Due from Related Parties Current',
                                children: [],
                            },
                            {
                                key: 'otherReceivables',
                                title: 'Other Receivables',
                                children: [],
                            },
                            {
                                key: 'receivablesAdjustmentsAllowances',
                                title: 'Receivables Adjustments Allowances',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'inventory',
                        title: 'Inventory',
                        children: [
                            {
                                key: 'rawMaterials',
                                title: 'Raw Materials',
                                children: [],
                            },
                            {
                                key: 'workInProcess',
                                title: 'Work in Process',
                                children: [],
                            },
                            {
                                key: 'finishedGoods',
                                title: 'Finished Goods',
                                children: [],
                            },
                            {
                                key: 'otherInventories',
                                title: 'Other Inventories',
                                children: [],
                            },
                            {
                                key: 'inventoriesAdjustmentsAllowances',
                                title: 'Inventories Adjustments Allowances',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'prepaidAssets',
                        title: 'Prepaid Assets',
                        children: [],
                    },
                    {
                        key: 'restrictedCash',
                        title: 'Restricted Cash',
                        children: [],
                    },
                    {
                        key: 'currentDeferredAssets',
                        title: 'Current Deferred Assets',
                        children: [
                            {
                                key: 'currentDeferredTaxesAssets',
                                title: 'Current Deferred Taxes Assets',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'assetsHeldForSaleCurrent',
                        title: 'Assets Held for Sale Current',
                        children: [],
                    },
                    {
                        key: 'hedgingAssetsCurrent',
                        title: 'Hedging Assets Current',
                        children: [],
                    },
                    {
                        key: 'otherCurrentAssets',
                        title: 'Other Current Assets',
                        children: [],
                    },
                ],
            },
            {
                key: 'totalNonCurrentAssets',
                title: 'Total non-current assets',
                children: [
                    {
                        key: 'netPPE',
                        title: 'Net PPE',
                        children: [
                            {
                                key: 'grossPPE',
                                title: 'Gross PPE',
                                children: [
                                    {
                                        key: 'properties',
                                        title: 'Properties',
                                        children: [],
                                    },
                                    {
                                        key: 'landAndImprovements',
                                        title: 'Land And Improvements',
                                        children: [],
                                    },
                                    {
                                        key: 'buildingsAndImprovements',
                                        title: 'Buildings And Improvements',
                                        children: [],
                                    },
                                    {
                                        key: 'machineryFurnitureEquipment',
                                        title: 'Machinery Furniture Equipment',
                                        children: [],
                                    },
                                    {
                                        key: 'otherProperties',
                                        title: 'Other Properties',
                                        children: [],
                                    },
                                    {
                                        key: 'constructionInProgress',
                                        title: 'Construction in Progress',
                                        children: [],
                                    },
                                    {
                                        key: 'leases',
                                        title: 'Leases',
                                        children: [],
                                    },
                                ],
                            },
                            {
                                key: 'accumulatedDepreciation',
                                title: 'Accumulated Depreciation',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'goodwillAndOtherIntangibleAssets',
                        title: 'Goodwill And Other Intangible Assets',
                        children: [
                            {
                                key: 'goodwill',
                                title: 'Goodwill',
                                children: [],
                            },
                            {
                                key: 'otherIntangibleAssets',
                                title: 'Other Intangible Assets',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'investmentProperties',
                        title: 'Investment Properties',
                        children: [],
                    },
                    {
                        key: 'investmentsAndAdvances',
                        title: 'Investments And Advances',
                        children: [
                            {
                                key: 'longTermEquityInvestment',
                                title: 'Long Term Equity Investment',
                                children: [
                                    {
                                        key: 'investmentsinSubsidiariesatCost',
                                        title: 'Investments in Subsidiariesat Cost',
                                        children: [],
                                    },
                                    {
                                        key: 'investmentsinAssociatesatCost',
                                        title: 'Investments in Associatesat Cost',
                                        children: [],
                                    },
                                    {
                                        key: 'investmentsInOtherVenturesUnderEquityMethod',
                                        title: 'Investments in Other Ventures Under Equity Method',
                                        children: [],
                                    },
                                    {
                                        key: 'investmentsinJointVenturesatCost',
                                        title: 'Investments in Joint Venturesat Cost',
                                        children: [],
                                    },
                                ],
                            },
                            {
                                key: 'investmentinFinancialAssets',
                                title: 'Investment in Financial Assets',
                                children: [
                                    {
                                        key: 'tradingSecurities',
                                        title: 'Trading Securities',
                                        children: [],
                                    },
                                    {
                                        key: 'financialAssetsDesignatedasFairValueThroughProfitorLossTotal',
                                        title: 'Financial Assets Designatedas Fair Value Through Profitor Loss Total',
                                        children: [],
                                    },
                                    {
                                        key: 'availableForSaleSecurities',
                                        title: 'Available for Sale Securities',
                                        children: [],
                                    },
                                    {
                                        key: 'heldToMaturitySecurities',
                                        title: 'Held To Maturity Securities',
                                        children: [],
                                    },
                                ],
                            },
                            {
                                key: 'otherInvestments',
                                title: 'Other Investments',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'financialAssets',
                        title: 'Financial Assets',
                        children: [],
                    },
                    {
                        key: 'nonCurrentAccountsReceivable',
                        title: 'Non Current Accounts Receivable',
                        children: [],
                    },
                    {
                        key: 'nonCurrentNoteReceivables',
                        title: 'Non Current Note Receivables',
                        children: [],
                    },
                    {
                        key: 'duefromRelatedPartiesNonCurrent',
                        title: 'Due from Related Parties Non Current',
                        children: [],
                    },
                    {
                        key: 'nonCurrentDeferredAssets',
                        title: 'Non Current Deferred Assets',
                        children: [
                            {
                                key: 'nonCurrentDeferredTaxesAssets',
                                title: 'Non Current Deferred Taxes Assets',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'nonCurrentPrepaidAssets',
                        title: 'Non Current Prepaid Assets',
                        children: [],
                    },
                    {
                        key: 'definedPensionBenefit',
                        title: 'Defined Pension Benefit',
                        children: [],
                    },
                    {
                        key: 'otherNonCurrentAssets',
                        title: 'Other Non Current Assets',
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        key: 'totalLiabilitiesNetMinorityInterest',
        title: 'Total Liabilities Net Minority Interest',
        children: [
            {
                key: 'currentLiabilities',
                title: 'Current Liabilities',
                children: [
                    {
                        key: 'payablesAndAccruedExpenses',
                        title: 'Payables And Accrued Expenses',
                        children: [
                            {
                                key: 'payables',
                                title: 'Payables',
                                children: [
                                    {
                                        key: 'accountsPayable',
                                        title: 'Accounts Payable',
                                        children: [],
                                    },
                                    {
                                        key: 'totalTaxPayable',
                                        title: 'Total Tax Payable',
                                        children: [
                                            {
                                                key: 'incomeTaxPayable',
                                                title: 'Income Tax Payable',
                                                children: [],
                                            },
                                        ],
                                    },
                                    {
                                        key: 'dividendsPayable',
                                        title: 'Dividends Payable',
                                        children: [],
                                    },
                                    {
                                        key: 'duetoRelatedPartiesCurrent',
                                        title: 'Due to Related Parties Current',
                                        children: [],
                                    },
                                    {
                                        key: 'otherPayable',
                                        title: 'Other Payable',
                                        children: [],
                                    },
                                ],
                            },
                            {
                                key: 'currentAccruedExpenses',
                                title: 'Current Accrued Expenses',
                                children: [
                                    {
                                        key: 'interestPayable',
                                        title: 'Interest Payable',
                                        children: [],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        key: 'currentProvisions',
                        title: 'Current Provisions',
                        children: [],
                    },
                    {
                        key: 'pensionandOtherPostRetirementBenefitPlansCurrent',
                        title: 'Pension & Other Post Retirement Benefit Plans Current',
                        children: [],
                    },
                    {
                        key: 'currentDebtAndCapitalLeaseObligation',
                        title: 'Current Debt And Capital Lease Obligation',
                        children: [
                            {
                                key: 'currentDebt',
                                title: 'Current Debt',
                                children: [
                                    {
                                        key: 'currentNotesPayable',
                                        title: 'Current Notes Payable',
                                        children: [],
                                    },
                                    {
                                        key: 'commercialPaper',
                                        title: 'Commercial Paper',
                                        children: [],
                                    },
                                    {
                                        key: 'lineOfCredit',
                                        title: 'Line of Credit',
                                        children: [],
                                    },
                                    {
                                        key: 'otherCurrentBorrowings',
                                        title: 'Other Current Borrowings',
                                        children: [],
                                    },
                                ],
                            },
                            {
                                key: 'currentCapitalLeaseObligation',
                                title: 'Current Capital Lease Obligation',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'currentDeferredLiabilities',
                        title: 'Current Deferred Liabilities',
                        children: [
                            {
                                key: 'currentDeferredTaxesLiabilities',
                                title: 'Current Deferred Taxes Liabilities',
                                children: [],
                            },
                            {
                                key: 'currentDeferredRevenue',
                                title: 'Current Deferred Revenue',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'otherCurrentLiabilities',
                        title: 'Other Current Liabilities',
                        children: [],
                    },
                ],
            },
            {
                key: 'totalNonCurrentLiabilitiesNetMinorityInterest',
                title: 'Total Non Current Liabilities Net Minority Interest',
                children: [
                    {
                        key: 'longTermProvisions',
                        title: 'Long Term Provisions',
                        children: [],
                    },
                    {
                        key: 'longTermDebtAndCapitalLeaseObligation',
                        title: 'Long Term Debt And Capital Lease Obligation',
                        children: [
                            {
                                key: 'longTermDebt',
                                title: 'Long Term Debt',
                                children: [],
                            },
                            {
                                key: 'longTermCapitalLeaseObligation',
                                title: 'Long Term Capital Lease Obligation',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'nonCurrentDeferredLiabilities',
                        title: 'Non Current Deferred Liabilities',
                        children: [
                            {
                                key: 'nonCurrentDeferredTaxesLiabilities',
                                title: 'Non Current Deferred Taxes Liabilities',
                                children: [],
                            },
                            {
                                key: 'nonCurrentDeferredRevenue',
                                title: 'Non Current Deferred Revenue',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'tradeandOtherPayablesNonCurrent',
                        title: 'Tradeand Other Payables Non Current',
                        children: [],
                    },
                    {
                        key: 'duetoRelatedPartiesNonCurrent',
                        title: 'Due to Related Parties Non Current',
                        children: [],
                    },
                    {
                        key: 'nonCurrentAccruedExpenses',
                        title: 'Non Current Accrued Expenses',
                        children: [],
                    },
                    {
                        key: 'employeeBenefits',
                        title: 'Employee Benefits',
                        children: [
                            {
                                key: 'nonCurrentPensionAndOtherPostretirementBenefitPlans',
                                title: 'Non Current Pension And Other Post-Retirement Benefit Plans',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'derivativeProductLiabilities',
                        title: 'Derivative Product Liabilities',
                        children: [],
                    },
                    {
                        key: 'preferredSecuritiesOutsideStockEquity',
                        title: 'Preferred Securities Outside Stock Equity',
                        children: [],
                    },
                    {
                        key: 'restrictedCommonStock',
                        title: 'Restricted Common Stock',
                        children: [],
                    },
                    {
                        key: 'liabilitiesHeldforSaleNonCurrent',
                        title: 'Liabilities Held for Sale Non Current',
                        children: [],
                    },
                    {
                        key: 'otherNonCurrentLiabilities',
                        title: 'Other Non Current Liabilities',
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        key: 'totalEquityGrossMinorityInterest',
        title: 'Total Equity Gross Minority Interest',
        children: [
            {
                key: 'totalPartnershipCapital',
                title: 'Total Partnership Capital',
                children: [
                    {
                        key: 'limitedPartnershipCapital',
                        title: 'Limited Partnership Capital',
                        children: [],
                    },
                    {
                        key: 'generalPartnershipCapital',
                        title: 'General Partnership Capital',
                        children: [],
                    },
                ],
            },
            {
                key: 'stockholdersEquity',
                title: "Stockholders' Equity",
                children: [
                    {
                        key: 'capitalStock',
                        title: 'Capital Stock',
                        children: [
                            {
                                key: 'preferredStock',
                                title: 'Preferred Stock',
                                children: [],
                            },
                            {
                                key: 'commonStock',
                                title: 'Common Stock',
                                children: [],
                            },
                            {
                                key: 'otherCapitalStock',
                                title: 'Other Capital Stock',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'additionalPaidInCapital',
                        title: 'Additional Paid in Capital',
                        children: [],
                    },
                    {
                        key: 'retainedEarnings',
                        title: 'Retained Earnings',
                        children: [],
                    },
                    {
                        key: 'treasuryStock',
                        title: 'Treasury Stock',
                        children: [],
                    },
                    {
                        key: 'gainsLossesNotAffectingRetainedEarnings',
                        title: 'Gains Losses Not Affecting Retained Earnings',
                        children: [
                            {
                                key: 'unrealizedGainLoss',
                                title: 'Unrealized Gain Loss',
                                children: [],
                            },
                            {
                                key: 'minimumPensionLiabilities',
                                title: 'Minimum Pension Liabilities',
                                children: [],
                            },
                            {
                                key: 'foreignCurrencyTranslationAdjustments',
                                title: 'Foreign Currency Translation Adjustments',
                                children: [],
                            },
                            {
                                key: 'fixedAssetsRevaluationReserve',
                                title: 'Fixed Assets Revaluation Reserve',
                                children: [],
                            },
                            {
                                key: 'otherEquityAdjustments',
                                title: 'Other Equity Adjustments',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'otherEquityInterest',
                        title: 'Other Equity Interest',
                        children: [],
                    },
                ],
            },
            {
                key: 'minorityInterest',
                title: 'Minority Interest',
                children: [],
            },
        ],
    },
    {
        key: 'totalCapitalization',
        title: 'Total Capitalization',
        children: [],
    },
    {
        key: 'preferredStockEquity',
        title: 'Preferred Stock Equity',
        children: [],
    },
    {
        key: 'commonStockEquity',
        title: 'Common Stock Equity',
        children: [],
    },
    {
        key: 'capitalLeaseObligations',
        title: 'Capital Lease Obligations',
        children: [],
    },
    {
        key: 'netTangibleAssets',
        title: 'Net Tangible Assets',
        children: [],
    },
    {
        key: 'workingCapital',
        title: 'Working Capital',
        children: [],
    },
    {
        key: 'investedCapital',
        title: 'Invested Capital',
        children: [],
    },
    {
        key: 'tangibleBookValue',
        title: 'Tangible Book Value',
        children: [],
    },
    {
        key: 'totalDebt',
        title: 'Total Debt',
        children: [],
    },
    {
        key: 'netDebt',
        title: 'Net Debt',
        children: [],
    },
    {
        key: 'shareIssued',
        title: 'Share Issued',
        children: [],
    },
    {
        key: 'ordinarySharesNumber',
        title: 'Ordinary Shares Number',
        children: [],
    },
    {
        key: 'preferredSharesNumber',
        title: 'Preferred Shares Number',
        children: [],
    },
    {
        key: 'treasurySharesNumber',
        title: 'Treasury Shares Number',
        children: [],
    },
];

const incomeStatementStructure = [
    {
        key: 'totalRevenue',
        title: 'Total Revenue',
        children: [
            {
                key: 'operatingRevenue',
                title: 'Operating Revenue',
                children: [],
            },
            {
                key: 'exciseTaxes',
                title: 'Excise Taxes',
                children: [],
            },
        ],
    },
    {
        key: 'costOfRevenue',
        title: 'Cost of Revenue',
        children: [],
    },
    {
        key: 'grossProfit',
        title: 'Gross Profit',
        children: [],
    },
    {
        key: 'operatingExpense',
        title: 'Operating Expense',
        children: [
            {
                key: 'sellingGeneralAndAdministration',
                title: 'Selling General and Administrative',
                children: [
                    {
                        key: 'generalAndAdministrativeExpense',
                        title: 'General & Administrative Expense',
                        children: [
                            {
                                key: 'salariesAndWages',
                                title: 'Salaries and Wages',
                                children: [],
                            },
                            {
                                key: 'rentAndLandingFees',
                                title: 'Rental & Landing Fees',
                                children: [],
                            },
                            {
                                key: 'insuranceAndClaims',
                                title: 'Insurance & Claims',
                                children: [],
                            },
                            {
                                key: 'otherGandA',
                                title: 'Other G and A',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'sellingAndMarketingExpense',
                        title: 'Selling & Marketing Expense',
                        children: [],
                    },
                ],
            },
            {
                key: 'researchAndDevelopment',
                title: 'Research & Development',
                children: [],
            },
            {
                key: 'depreciationAmortizationDepletionIncomeStatement',
                title: 'Depreciation Amortization Depletion',
                children: [
                    {
                        key: 'depreciationAndAmortizationInIncomeStatement',
                        title: 'Depreciation & amortization',
                        children: [
                            {
                                key: 'depreciationIncomeStatement',
                                title: 'Depreciation',
                                children: [],
                            },
                            {
                                key: 'amortization',
                                title: 'Amortization',
                                children: [
                                    {
                                        key: 'amortizationOfIntangiblesIncomeStatement',
                                        title: 'Amortization of Intangibles',
                                        children: [],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        key: 'depletionIncomeStatement',
                        title: 'Depletion',
                        children: [],
                    },
                ],
            },
            {
                key: 'provisionForDoubtfulAccounts',
                title: 'Provision for Doubtful Accounts',
                children: [],
            },
            {
                key: 'otherTaxes',
                title: 'Other Taxes',
                children: [],
            },
            {
                key: 'otherOperatingExpenses',
                title: 'Other Operating Expenses',
                children: [],
            },
        ],
    },
    {
        key: 'operatingIncome',
        title: 'Operating Income',
        children: [],
    },
    {
        key: 'netNonOperatingInterestIncomeExpense',
        title: 'Net Non Operating Interest Income Expense',
        children: [
            {
                key: 'interestIncomeNonOperating',
                title: 'Interest Income Non Operating',
                children: [],
            },
            {
                key: 'interestExpenseNonOperating',
                title: 'Interest Expense Non Operating',
                children: [],
            },
            {
                key: 'totalOtherFinanceCost',
                title: 'Total Other Finance Cost',
                children: [],
            },
        ],
    },
    {
        key: 'otherIncomeExpense',
        title: 'Other Income Expense',
        children: [
            {
                key: 'gainOnSaleOfSecurity',
                title: 'Gain on Sale of Security',
                children: [],
            },
            {
                key: 'earningsFromEquityInterest',
                title: 'Earnings from Equity Interest',
                children: [],
            },
            {
                key: 'securitiesAmortization',
                title: 'Securities Amortization',
                children: [],
            },
            {
                key: 'specialIncomeCharges',
                title: 'Special Income Charges',
                children: [
                    {
                        key: 'restructuringAndMergernAcquisition',
                        title: 'Restructuring & Mergers Acquisition',
                        children: [],
                    },
                    {
                        key: 'impairmentOfCapitalAssets',
                        title: 'Impairment of Capital Assets',
                        children: [],
                    },
                    {
                        key: 'writeOff',
                        title: 'Write Off',
                        children: [],
                    },
                    {
                        key: 'otherSpecialCharges',
                        title: 'Other Special Charges',
                        children: [],
                    },
                    {
                        key: 'gainOnSaleOfBusiness',
                        title: 'Gain on Sale of Business',
                        children: [],
                    },
                    {
                        key: 'gainOnSaleOfPPE',
                        title: 'Gain on Sale of PPE',
                        children: [],
                    },
                ],
            },
            {
                key: 'otherNonOperatingIncomeExpenses',
                title: 'Other Non Operating Income Expenses',
                children: [],
            },
        ],
    },
    {
        key: 'pretaxIncome',
        title: 'Pretax Income',
        children: [],
    },
    {
        key: 'taxProvision',
        title: 'Tax Provision',
        children: [],
    },
    {
        key: 'earningsFromEquityInterestNetOfTax',
        title: 'Earnings from Equity Interest Net of Tax',
        children: [],
    },
    {
        key: 'netIncomeCommonStockholders',
        title: 'Net Income Common Stockholders',
        children: [
            {
                key: 'netIncome',
                title: 'Net Income',
                children: [
                    {
                        key: 'netIncomeIncludingNoncontrollingInterests',
                        title: 'Net Income Including Non-Controlling Interests',
                        children: [
                            {
                                key: 'netIncomeContinuousOperations',
                                title: 'Net Income Continuous Operations',
                                children: [],
                            },
                            {
                                key: 'netIncomeDiscontinuousOperations',
                                title: 'Net Income Discontinuous Operations',
                                children: [],
                            },
                            {
                                key: 'netIncomeExtraordinary',
                                title: 'Net Income Extraordinary',
                                children: [],
                            },
                            {
                                key: 'netIncomeFromTaxLossCarryforward',
                                title: 'Net Income from Tax Loss Carryforward',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'minorityInterests',
                        title: 'Minority Interests',
                        children: [],
                    },
                ],
            },
            {
                key: 'preferredStockDividends',
                title: 'Preferred Stock Dividends',
                children: [],
            },
            {
                key: 'otherunderPreferredStockDividend',
                title: 'Otherunder Preferred Stock Dividend',
                children: [],
            },
        ],
    },
    {
        key: 'averageDilutionEarnings',
        title: 'Average Dilution Earnings',
        children: [],
    },
    {
        key: 'dilutedNIAvailtoComStockholders',
        title: 'Diluted NI Available to Com Stockholders',
        children: [],
    },
    {
        key: 'basicEPS',
        title: 'Basic EPS',
        children: [
            {
                key: 'basicContinuousOperations',
                title: 'Basic Continuous Operations',
                children: [],
            },
            {
                key: 'basicDiscontinuousOperations',
                title: 'Basic Discontinuous Operations',
                children: [],
            },
            {
                key: 'basicExtraordinary',
                title: 'Basic Extraordinary',
                children: [],
            },
            {
                key: 'basicAccountingChange',
                title: 'Basic Accounting Change',
                children: [],
            },
            {
                key: 'taxLossCarryforwardBasicEPS',
                title: 'Tax Loss Carryforward Basic EPS',
                children: [],
            },
            {
                key: 'basicEPSOtherGainsLosses',
                title: 'Basic EPS Other Gains Losses',
                children: [],
            },
        ],
    },
    {
        key: 'dilutedEPS',
        title: 'Diluted EPS',
        children: [
            {
                key: 'dilutedContinuousOperations',
                title: 'Diluted Continuous Operations',
                children: [],
            },
            {
                key: 'dilutedDiscontinuousOperations',
                title: 'Diluted Discontinuous Operations',
                children: [],
            },
            {
                key: 'dilutedExtraordinary',
                title: 'Diluted Extraordinary',
                children: [],
            },
            {
                key: 'dilutedAccountingChange',
                title: 'Diluted Accounting Change',
                children: [],
            },
            {
                key: 'taxLossCarryforwardDilutedEPS',
                title: 'Tax Loss Carryforward Diluted EPS',
                children: [],
            },
            {
                key: 'dilutedEPSOtherGainsLosses',
                title: 'Diluted EPS Other Gains Losses',
                children: [],
            },
        ],
    },
    {
        key: 'basicAverageShares',
        title: 'Basic Average Shares',
        children: [],
    },
    {
        key: 'dilutedAverageShares',
        title: 'Diluted Average Shares',
        children: [],
    },
    {
        key: 'dividendPerShare',
        title: 'Dividend Per Share',
        children: [],
    },
    {
        key: 'totalOperatingIncomeAsReported',
        title: 'Total Operating Income as Reported',
        children: [],
    },
    {
        key: 'reportedNormalizedBasicEPS',
        title: 'Reported Normalized Basic EPS',
        children: [],
    },
    {
        key: 'reportedNormalizedDilutedEPS',
        title: 'Reported Normalized Diluted EPS',
        children: [],
    },
    {
        key: 'rentExpenseSupplemental',
        title: 'Rent Expense Supplemental',
        children: [],
    },
    {
        key: 'totalExpenses',
        title: 'Total Expenses',
        children: [],
    },
    {
        key: 'netIncomeFromContinuingAndDiscontinuedOperation',
        title: 'Net Income from Continuing & Discontinued Operation',
        children: [],
    },
    {
        key: 'normalizedIncome',
        title: 'Normalized Income',
        children: [],
    },
    {
        key: 'continuingAndDiscontinuedBasicEPS',
        title: 'Continuing & Discontinued Basic EPS',
        children: [],
    },
    {
        key: 'continuingAndDiscontinuedDilutedEPS',
        title: 'Continuing & Discontinued Diluted EPS',
        children: [],
    },
    {
        key: 'interestIncome',
        title: 'Interest Income',
        children: [],
    },
    {
        key: 'interestExpense',
        title: 'Interest Expense',
        children: [],
    },
    {
        key: 'netInterestIncome',
        title: 'Net Interest Income',
        children: [],
    },
    {
        key: 'eBIT',
        title: 'EBIT',
        children: [],
    },
    {
        key: 'eBITDA',
        title: 'EBITDA',
        children: [],
    },
    {
        key: 'reconciledCostOfRevenue',
        title: 'Reconciled Cost of Revenue',
        children: [],
    },
    {
        key: 'reconciledDepreciation',
        title: 'Reconciled Depreciation',
        children: [],
    },
    {
        key: 'netIncomeFromContinuingOperationNetMinorityInterest',
        title: 'Net Income from Continuing Operation Net Minority Interest',
        children: [],
    },
    {
        key: 'totalUnusualItemsExcludingGoodwill',
        title: 'Total Unusual Items Excluding Goodwill',
        children: [],
    },
    {
        key: 'totalUnusualItems',
        title: 'Total Unusual Items',
        children: [],
    },
    {
        key: 'normalizedBasicEPS',
        title: 'Normalized Basic EPS',
        children: [],
    },
    {
        key: 'normalizedDilutedEPS',
        title: 'Normalized Diluted EPS',
        children: [],
    },
    {
        key: 'normalizedEBITDA',
        title: 'Normalized EBITDA',
        children: [],
    },
    {
        key: 'taxRateForCalcs',
        title: 'Tax Rate for Calcs',
        children: [],
    },
    {
        key: 'taxEffectOfUnusualItems',
        title: 'Tax Effect of Unusual Items',
        children: [],
    },
];

const cashFlowStructure = [
    {
        key: 'cashFlowsfromusedinOperatingActivitiesDirect',
        title: 'Cash Flows from Used in Operating Activities Direct',
        children: [
            {
                key: 'classesofCashReceiptsfromOperatingActivities',
                title: 'Classes of Cash Receipts from Operating Activities',
                children: [
                    {
                        key: 'receiptsfromCustomers',
                        title: 'Receipts from Customers',
                        children: [],
                    },
                    {
                        key: 'receiptsfromGovernmentGrants',
                        title: 'Receipts from Government Grants',
                        children: [],
                    },
                    {
                        key: 'otherCashReceiptsfromOperatingActivities',
                        title: 'Other Cash Receipts from Operating Activities',
                        children: [],
                    },
                ],
            },
            {
                key: 'classesofCashPayments',
                title: 'Classes of Cash Payments',
                children: [
                    {
                        key: 'paymentstoSuppliersforGoodsandServices',
                        title: 'Payments to Suppliers for Goods & Services',
                        children: [],
                    },
                    {
                        key: 'paymentsonBehalfofEmployees',
                        title: 'Payments on Behalf of Employees',
                        children: [],
                    },
                    {
                        key: 'otherCashPaymentsfromOperatingActivities',
                        title: 'Other Cash Payments from Operating Activities',
                        children: [],
                    },
                ],
            },
            {
                key: 'dividendsPaidDirect',
                title: 'Dividends Paid Direct',
                children: [],
            },
            {
                key: 'dividendsReceivedDirect',
                title: 'Dividends Received Direct',
                children: [],
            },
            {
                key: 'interestPaidDirect',
                title: 'Interest Paid Direct',
                children: [],
            },
            {
                key: 'interestReceivedDirect',
                title: 'Interest Received Direct',
                children: [],
            },
            {
                key: 'taxesRefundPaidDirect',
                title: 'Taxes Refund Paid Direct',
                children: [],
            },
        ],
    },
    {
        key: 'operatingCashFlow',
        title: 'Operating Cash Flow',
        children: [
            {
                key: 'cashFlowFromContinuingOperatingActivities',
                title: 'Cash Flow from Continuing Operating Activities',
                children: [
                    {
                        key: 'netIncomeFromContinuingOperations',
                        title: 'Net Income from Continuing Operations',
                        children: [],
                    },
                    {
                        key: 'operatingGainsLosses',
                        title: 'Operating Gains Losses',
                        children: [
                            {
                                key: 'gainLossOnSaleOfBusiness',
                                title: 'Gain Loss On Sale of Business',
                                children: [],
                            },
                            {
                                key: 'gainLossOnSaleOfPPE',
                                title: 'Gain Loss On Sale of PPE',
                                children: [],
                            },
                            {
                                key: 'netForeignCurrencyExchangeGainLoss',
                                title: 'Net Foreign Currency Exchange Gain Loss',
                                children: [],
                            },
                            {
                                key: 'gainLossOnInvestmentSecurities',
                                title: 'Gain Loss On Investment Securities',
                                children: [],
                            },
                            {
                                key: 'earningsLossesFromEquityInvestments',
                                title: 'Earnings Losses from Equity Investments',
                                children: [],
                            },
                            {
                                key: 'pensionAndEmployeeBenefitExpense',
                                title: 'Pension And Employee Benefit Expense',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'depreciationAmortizationDepletion',
                        title: 'Depreciation Amortization Depletion',
                        children: [
                            {
                                key: 'depreciationAndAmortization',
                                title: 'Depreciation & amortization',
                                children: [
                                    {
                                        key: 'depreciation',
                                        title: 'Depreciation',
                                        children: [],
                                    },
                                    {
                                        key: 'amortizationCashFlow',
                                        title: 'Amortization',
                                        children: [
                                            {
                                                key: 'amortizationOfIntangibles',
                                                title: 'Amortization of Intangibles',
                                                children: [],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                key: 'depletion',
                                title: 'Depletion',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'deferredTax',
                        title: 'Deferred Tax',
                        children: [
                            {
                                key: 'deferredIncomeTax',
                                title: 'Deferred Income Tax',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'amortizationOfSecurities',
                        title: 'Amortization of Securities',
                        children: [],
                    },
                    {
                        key: 'assetImpairmentCharge',
                        title: 'Asset Impairment Charge',
                        children: [],
                    },
                    {
                        key: 'provisionandWriteOffofAssets',
                        title: 'Provision & Write Off of Assets',
                        children: [],
                    },
                    {
                        key: 'unrealizedGainLossOnInvestmentSecurities',
                        title: 'Unrealized Gain Loss On Investment Securities',
                        children: [],
                    },
                    {
                        key: 'stockBasedCompensation',
                        title: 'Stock based compensation',
                        children: [],
                    },
                    {
                        key: 'excessTaxBenefitFromStockBasedCompensation',
                        title: 'Excess Tax Benefit from Stock Based Compensation',
                        children: [],
                    },
                    {
                        key: 'otherNonCashItems',
                        title: 'Other non-cash items',
                        children: [],
                    },
                    {
                        key: 'changeInWorkingCapital',
                        title: 'Change in working capital',
                        children: [
                            {
                                key: 'changeInReceivables',
                                title: 'Change in Receivables',
                                children: [
                                    {
                                        key: 'changesInAccountReceivables',
                                        title: 'Changes in Account Receivables',
                                        children: [],
                                    },
                                ],
                            },
                            {
                                key: 'changeInInventory',
                                title: 'Change in Inventory',
                                children: [],
                            },
                            {
                                key: 'changeInPrepaidAssets',
                                title: 'Change in Prepaid Assets',
                                children: [],
                            },
                            {
                                key: 'changeInPayablesAndAccruedExpense',
                                title: 'Change in Payables And Accrued Expense',
                                children: [
                                    {
                                        key: 'changeInPayable',
                                        title: 'Change in Payable',
                                        children: [
                                            {
                                                key: 'changeInTaxPayable',
                                                title: 'Change in Tax Payable',
                                                children: [
                                                    {
                                                        key: 'changeInIncomeTaxPayable',
                                                        title: 'Change in Income Tax Payable',
                                                        children: [],
                                                    },
                                                ],
                                            },
                                            {
                                                key: 'changeInAccountPayable',
                                                title: 'Change in Account Payable',
                                                children: [],
                                            },
                                            {
                                                key: 'changeInDividendPayable',
                                                title: 'Change in Dividend Payable',
                                                children: [],
                                            },
                                        ],
                                    },
                                    {
                                        key: 'changeInAccruedExpense',
                                        title: 'Change in Accrued Expense',
                                        children: [
                                            {
                                                key: 'changeInInterestPayable',
                                                title: 'Change in Interest Payable',
                                                children: [],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                key: 'changeInOtherCurrentAssets',
                                title: 'Change in Other Current Assets',
                                children: [],
                            },
                            {
                                key: 'changeInOtherCurrentLiabilities',
                                title: 'Change in Other Current Liabilities',
                                children: [],
                            },
                            {
                                key: 'changeInOtherWorkingCapital',
                                title: 'Change in Other Working Capital',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'dividendPaidCFO',
                        title: 'Dividend Paid CFO',
                        children: [],
                    },
                    {
                        key: 'dividendReceivedCFO',
                        title: 'Dividend Received CFO',
                        children: [],
                    },
                    {
                        key: 'interestPaidCFO',
                        title: 'Interest Paid CFO',
                        children: [],
                    },
                    {
                        key: 'interestReceivedCFO',
                        title: 'Interest Received CFO',
                        children: [],
                    },
                    {
                        key: 'taxesRefundPaid',
                        title: 'Taxes Refund Paid',
                        children: [],
                    },
                ],
            },
            {
                key: 'cashFromDiscontinuedOperatingActivities',
                title: 'Cash from Discontinued Operating Activities',
                children: [],
            },
        ],
    },
    {
        key: 'investingCashFlow',
        title: 'Investing Cash Flow',
        children: [
            {
                key: 'cashFlowFromContinuingInvestingActivities',
                title: 'Cash Flow from Continuing Investing Activities',
                children: [
                    {
                        key: 'capitalExpenditureReported',
                        title: 'Capital Expenditure Reported',
                        children: [],
                    },
                    {
                        key: 'netPPEPurchaseAndSale',
                        title: 'Net PPE Purchase And Sale',
                        children: [
                            {
                                key: 'purchaseOfPPE',
                                title: 'Purchase of PPE',
                                children: [],
                            },
                            {
                                key: 'saleOfPPE',
                                title: 'Sale of PPE',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'netIntangiblesPurchaseAndSale',
                        title: 'Net Intangibles Purchase And Sale',
                        children: [
                            {
                                key: 'purchaseOfIntangibles',
                                title: 'Purchase of Intangibles',
                                children: [],
                            },
                            {
                                key: 'saleOfIntangibles',
                                title: 'Sale of Intangibles',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'netBusinessPurchaseAndSale',
                        title: 'Net Business Purchase And Sale',
                        children: [
                            {
                                key: 'purchaseOfBusiness',
                                title: 'Purchase of Business',
                                children: [],
                            },
                            {
                                key: 'saleOfBusiness',
                                title: 'Sale of Business',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'netInvestmentPropertiesPurchaseAndSale',
                        title: 'Net Investment Properties Purchase And Sale',
                        children: [
                            {
                                key: 'purchaseOfInvestmentProperties',
                                title: 'Purchase of Investment Properties',
                                children: [],
                            },
                            {
                                key: 'saleOfInvestmentProperties',
                                title: 'Sale of Investment Properties',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'netInvestmentPurchaseAndSale',
                        title: 'Net Investment Purchase And Sale',
                        children: [
                            {
                                key: 'purchaseOfInvestment',
                                title: 'Purchase of Investment',
                                children: [],
                            },
                            {
                                key: 'saleOfInvestment',
                                title: 'Sale of Investment',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'dividendsReceivedCFI',
                        title: 'Dividends Received CFI',
                        children: [],
                    },
                    {
                        key: 'interestReceivedCFI',
                        title: 'Interest Received CFI',
                        children: [],
                    },
                    {
                        key: 'netOtherInvestingChanges',
                        title: 'Net Other Investing Changes',
                        children: [],
                    },
                ],
            },
            {
                key: 'cashFromDiscontinuedInvestingActivities',
                title: 'Cash from Discontinued Investing Activities',
                children: [],
            },
        ],
    },
    {
        key: 'financingCashFlow',
        title: 'Financing Cash Flow',
        children: [
            {
                key: 'cashFlowFromContinuingFinancingActivities',
                title: 'Cash Flow from Continuing Financing Activities',
                children: [
                    {
                        key: 'netIssuancePaymentsOfDebt',
                        title: 'Net Issuance Payments of Debt',
                        children: [
                            {
                                key: 'netLongTermDebtIssuance',
                                title: 'Net Long Term Debt Issuance',
                                children: [
                                    {
                                        key: 'longTermDebtIssuance',
                                        title: 'Long Term Debt Issuance',
                                        children: [],
                                    },
                                    {
                                        key: 'longTermDebtPayments',
                                        title: 'Long Term Debt Payments',
                                        children: [],
                                    },
                                ],
                            },
                            {
                                key: 'netShortTermDebtIssuance',
                                title: 'Net Short Term Debt Issuance',
                                children: [
                                    {
                                        key: 'shortTermDebtIssuance',
                                        title: 'Short Term Debt Issuance',
                                        children: [],
                                    },
                                    {
                                        key: 'shortTermDebtPayments',
                                        title: 'Short Term Debt Payments',
                                        children: [],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        key: 'netCommonStockIssuance',
                        title: 'Net Common Stock Issuance',
                        children: [
                            {
                                key: 'commonStockIssuance',
                                title: 'Common Stock Issuance',
                                children: [],
                            },
                            {
                                key: 'commonStockPayments',
                                title: 'Common Stock Payments',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'netPreferredStockIssuance',
                        title: 'Net Preferred Stock Issuance',
                        children: [
                            {
                                key: 'preferredStockIssuance',
                                title: 'Preferred Stock Issuance',
                                children: [],
                            },
                            {
                                key: 'preferredStockPayments',
                                title: 'Preferred Stock Payments',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'cashDividendsPaid',
                        title: 'Cash Dividends Paid',
                        children: [
                            {
                                key: 'commonStockDividendPaid',
                                title: 'Common Stock Dividend Paid',
                                children: [],
                            },
                            {
                                key: 'preferredStockDividendPaid',
                                title: 'Preferred Stock Dividend Paid',
                                children: [],
                            },
                        ],
                    },
                    {
                        key: 'proceedsFromStockOptionExercised',
                        title: 'Proceeds from Stock Option Exercised',
                        children: [],
                    },
                    {
                        key: 'interestPaidCFF',
                        title: 'Interest Paid CFF',
                        children: [],
                    },
                    {
                        key: 'netOtherFinancingCharges',
                        title: 'Net Other Financing Charges',
                        children: [],
                    },
                ],
            },
            {
                key: 'cashFromDiscontinuedFinancingActivities',
                title: 'Cash from Discontinued Financing Activities',
                children: [],
            },
        ],
    },
    {
        key: 'cashFlowFromDiscontinuedOperation',
        title: 'Cash Flow from Discontinued Operation',
        children: [],
    },
    {
        key: 'otherCashAdjustmentInsideChangeinCash',
        title: 'Other Cash Adjustment Inside Change in Cash',
        children: [],
    },
    {
        key: 'endCashPosition',
        title: 'End Cash Position',
        children: [
            {
                key: 'changesInCash',
                title: 'Changes in Cash',
                children: [],
            },
            {
                key: 'effectOfExchangeRateChanges',
                title: 'Effect of Exchange Rate Changes',
                children: [],
            },
            {
                key: 'beginningCashPosition',
                title: 'Beginning Cash Position',
                children: [],
            },
            {
                key: 'otherCashAdjustmentOutsideChangeinCash',
                title: 'Other Cash Adjustment Outside Change in Cash',
                children: [],
            },
        ],
    },
    {
        key: 'incomeTaxPaidSupplementalData',
        title: 'Income Tax Paid Supplemental Data',
        children: [],
    },
    {
        key: 'interestPaidSupplementalData',
        title: 'Interest Paid Supplemental Data',
        children: [],
    },
    {
        key: 'capitalExpenditure',
        title: 'Capital Expenditure',
        children: [],
    },
    {
        key: 'issuanceOfCapitalStock',
        title: 'Issuance of Capital Stock',
        children: [],
    },
    {
        key: 'issuanceOfDebt',
        title: 'Issuance of Debt',
        children: [],
    },
    {
        key: 'repaymentOfDebt',
        title: 'Repayment of Debt',
        children: [],
    },
    {
        key: 'repurchaseOfCapitalStock',
        title: 'Repurchase of Capital Stock',
        children: [],
    },
    {
        key: 'freeCashFlow',
        title: 'Free Cash Flow',
        children: [],
    },
    {
        key: 'adjustedGeographySegmentData',
        title: 'Adjusted Geography Segment Data',
        children: [],
    },
    {
        key: 'domesticSales',
        title: 'Domestic Sales',
        children: [],
    },
    {
        key: 'foreignSales',
        title: 'Foreign Sales',
        children: [],
    },
];

interface IBalanceSheetDataProps {
    statement: any[];
    periodType: '3M' | '12M';
    structure: any;
}

const FinancialData: React.FC<IBalanceSheetDataProps> = ({
    statement,
    periodType,
    structure,
}) => {
    const [data, setData] = useState<any[]>([]);
    const [openRows, setOpenRows] = useState<{ [key: string]: boolean }>({});

    const removeDuplicatesAndSort = (finalStatements: any[]): any[] => {
        const seen = new Set<string>();
        const processedStatements = finalStatements
            .filter((event) => {
                if (seen.has(event.periodType) && event.periodType === 'TTM') {
                    return false;
                } else {
                    seen.add(event.periodType);
                    return true;
                }
            })
            .filter(
                (s) => s.periodType === 'TTM' || s.periodType === periodType
            )
            .sort((a, b) => {
                const dateA = new Date(a.asOfDate);
                const dateB = new Date(b.asOfDate);
                if (a.periodType == 'TTM') {
                    return -1;
                }
                if (b.periodType == 'TTM') {
                    return +1;
                }
                return dateB.getTime() - dateA.getTime();
            });

        return processedStatements;
    };

    useEffect(() => {
        setData(removeDuplicatesAndSort([...statement]));
    }, [statement, periodType]);

    const toggleRow = (key: string) => {
        setOpenRows((prev) => ({ ...prev, [key]: !prev[key] }));
    };

    const hasNonNullChildren = (children: any[]): boolean => {
        return children.some((child) => {
            const childKey = child.key;
            if (
                data.every(
                    (d) => d[childKey] === null || d[childKey] === undefined
                )
            ) {
                return false;
            }
            return true;
        });
    };

    const renderRow = (
        label: string,
        key: string,
        indentLevel = 0,
        children: any[] = []
    ) => {
        const isAllValuesNull = data.every(
            (d) => d[key] === null || d[key] === undefined
        );
        if (isAllValuesNull) return null;

        const hasChildren = children.length > 0 && hasNonNullChildren(children);

        return (
            <React.Fragment key={key}>
                <TableRow>
                    <TableCell
                        onClick={() => {
                            hasChildren && toggleRow(key as string);
                        }}
                        style={{
                            paddingLeft: `${(indentLevel + 1) * 20}px`,
                            cursor: 'pointer',
                        }}
                    >
                        <Box display={'flex'}>
                            {hasChildren && (
                                <IconButton
                                    sx={{ mr: 2, mt: 1 }}
                                    size="small"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        toggleRow(key as string);
                                    }}
                                >
                                    {openRows[key as string] ? (
                                        <KeyboardArrowUp />
                                    ) : (
                                        <KeyboardArrowDown />
                                    )}
                                </IconButton>
                            )}
                            <Typography>{label}</Typography>
                        </Box>
                    </TableCell>
                    {data.map((d, index) => (
                        <TableCell key={index}>
                            {d[key] !== undefined && d[key] !== null
                                ? (d[key] / 1000).toLocaleString('en-US')
                                : '--'}
                        </TableCell>
                    ))}
                </TableRow>
                {hasChildren &&
                    openRows[key as string] &&
                    renderStructure(children, indentLevel + 1)}
            </React.Fragment>
        );
    };

    const renderStructure = (structure: any[], indentLevel = 0) => {
        return structure.map((item) => (
            <React.Fragment key={item.key}>
                {renderRow(item.title, item.key, indentLevel, item.children)}
            </React.Fragment>
        ));
    };

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table aria-label="balance sheet table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Breakdown</TableCell>
                            {data.map((d, index) => (
                                <TableCell key={index}>
                                    {d.periodType === 'TTM'
                                        ? 'TTM'
                                        : UtilsService.formatStringDate(
                                              d.asOfDate,
                                              'dd/MM/yyyy'
                                          )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {data.length > 0 && (
                        <TableBody>{renderStructure(structure)}</TableBody>
                    )}
                </Table>
            </TableContainer>
            <Typography
                mt={2}
                mr={0}
                textAlign={'right'}
                fontSize={14}
                color="#A5A6AB"
            >
                *All numbers are in thousands and Currency in USD
            </Typography>
        </Box>
    );
};

interface ICustomTab {
    tab: string;
    activeTab: string;
    setTab: (tab: string) => void;
    value: string;
}

const CustomTab = (props: ICustomTab) => {
    const { tab, setTab, value, activeTab } = props;
    return (
        <Box
            sx={{
                p: 2,
                mr: 2,
                cursor: 'pointer',
                borderRadius: 1,
                backgroundColor: tab == activeTab ? '#EBECF6' : '#FFF',
                border: `1px solid ${tab == activeTab ? '#29319B' : '#FFF'} `,
            }}
            onClick={() => setTab(tab)}
        >
            <Typography
                fontWeight={600}
                color={tab == activeTab ? '#29319B' : '#A5A6AB'}
            >
                {value}
            </Typography>
        </Box>
    );
};

interface IProps {
    data?: ReportModule;
}

export const FiancialStatement = (props: IProps) => {
    const [duration, setDuration] = useState('Annual');
    const [tab, setTab] = useState('INCOME');
    return (
        <Box>
            <Box pb={4} display={'flex'} justifyContent={'space-between'}>
                <Box display="flex">
                    <CustomTab
                        tab="INCOME"
                        activeTab={tab}
                        setTab={setTab}
                        value="Income Statement"
                    />
                    <CustomTab
                        tab="BALANCE"
                        activeTab={tab}
                        setTab={setTab}
                        value="Balance Sheet"
                    />
                    <CustomTab
                        tab="CASH"
                        activeTab={tab}
                        setTab={setTab}
                        value="Cash Flow"
                    />
                    <CustomTab
                        tab="TRENDS"
                        activeTab={tab}
                        setTab={setTab}
                        value="Trends"
                    />
                </Box>

                {tab != 'TRENDS' && (
                    <SingleSelectTab
                        getOptionText={(v: string) => v}
                        placeholder="Select source"
                        pb={3}
                        fontSize={12}
                        width={130}
                        options={['Annual', 'Quarter']}
                        selectedValue={duration}
                        onChange={setDuration}
                    />
                )}
            </Box>
            {props.data?.data?.yahoo.coreFinancials.incomeStatement && (
                <Box>
                    {tab == 'INCOME' && (
                        <FinancialData
                            structure={incomeStatementStructure}
                            periodType={duration == 'Annual' ? '12M' : '3M'}
                            statement={
                                props.data?.data?.yahoo.coreFinancials
                                    .incomeStatement
                            }
                        />
                    )}
                    {tab == 'BALANCE' && (
                        <FinancialData
                            periodType={duration == 'Annual' ? '12M' : '3M'}
                            statement={props.data?.data?.yahoo.balanceSheets}
                            structure={balanceSheetStructure}
                        />
                    )}
                    {tab == 'CASH' && (
                        <FinancialData
                            periodType={duration == 'Annual' ? '12M' : '3M'}
                            structure={cashFlowStructure}
                            statement={
                                props.data?.data?.yahoo.coreFinancials.cashFlow
                            }
                        />
                    )}
                    {tab == 'TRENDS' && (
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <FinancialTrendsPlot data={props.data} />
                            </Grid>
                        </Grid>
                    )}
                </Box>
            )}
        </Box>
    );
};
