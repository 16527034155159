import { Box, Button, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { DocumentFile } from '../../../services/interfaces';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface IProps {
    files: DocumentFile[] | null;
    selectedFiles: DocumentFile[] | null;
    setSelectedFiles: (files: DocumentFile[] | null) => void;
}

export const FileSelectionMenu = (props: IProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { files, selectedFiles, setSelectedFiles } = props;
    return (
        <Box>
            {files && (
                <Box
                    sx={{
                        mr: 1.5,
                        '&:hover': {
                            backgroundColor: 'rgba(41, 49, 155, 0.04)',
                        },
                    }}
                >
                    <Button
                        onClick={() => {
                            if (
                                files &&
                                (!selectedFiles ||
                                    selectedFiles.filter((f) =>
                                        files
                                            .filter((f) => f.isOwner)
                                            .map((ff) => ff.userFileId)
                                            .includes(f.userFileId)
                                    ).length <
                                        files.filter((f) => f.isOwner).length)
                            ) {
                                setSelectedFiles([
                                    ...(selectedFiles ? selectedFiles : []),
                                    ...files.filter(
                                        (f) =>
                                            !(
                                                selectedFiles
                                                    ? selectedFiles
                                                    : []
                                            )
                                                .map((ff) => ff.userFileId)
                                                .includes(f.userFileId) &&
                                            f.isOwner
                                    ),
                                ]);
                            } else {
                                setSelectedFiles([
                                    ...(selectedFiles
                                        ? selectedFiles.filter(
                                              (f) =>
                                                  !files
                                                      .filter((f) => f.isOwner)
                                                      .map(
                                                          (ff) => ff.userFileId
                                                      )
                                                      .includes(f.userFileId)
                                          )
                                        : []),
                                ]);
                            }
                        }}
                        size="small"
                        aria-controls={open ? 'account-menu' : undefined}
                        sx={{
                            ml: 2,
                            pr: 0,
                            minWidth: 0,
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        {(!selectedFiles ||
                            (files &&
                                selectedFiles.filter((f) =>
                                    files
                                        .filter((f) => f.isOwner)
                                        .map((ff) => ff.userFileId)
                                        .includes(f.userFileId)
                                ).length == 0)) && (
                            <CheckBoxOutlineBlankIcon
                                sx={{
                                    color: '#29319B',
                                }}
                            />
                        )}
                        {selectedFiles &&
                            files &&
                            selectedFiles.filter((f) =>
                                files
                                    .filter((f) => f.isOwner)
                                    .map((ff) => ff.userFileId)
                                    .includes(f.userFileId)
                            ).length > 0 &&
                            selectedFiles.filter((f) =>
                                files
                                    .filter((f) => f.isOwner)
                                    .map((ff) => ff.userFileId)
                                    .includes(f.userFileId)
                            ).length <
                                files.filter((f) => f.isOwner).length && (
                                <IndeterminateCheckBoxIcon
                                    sx={{
                                        color: '#29319B',
                                    }}
                                />
                            )}
                        {selectedFiles &&
                            files &&
                            selectedFiles.filter((f) =>
                                files
                                    .filter((f) => f.isOwner)
                                    .map((ff) => ff.userFileId)
                                    .includes(f.userFileId)
                            ).length > 0 &&
                            selectedFiles.filter((f) =>
                                files
                                    .filter((f) => f.isOwner)
                                    .map((ff) => ff.userFileId)
                                    .includes(f.userFileId)
                            ).length ==
                                files.filter((f) => f.isOwner).length && (
                                <CheckBoxIcon
                                    sx={{
                                        color: '#29319B',
                                    }}
                                />
                            )}
                    </Button>
                    <Button
                        onClick={handleClick}
                        sx={{
                            minWidth: 0,
                            px: 0,
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        <ArrowDropDownIcon sx={{ color: '#29319B' }} />
                    </Button>
                </Box>
            )}
            {files && (
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top',
                    }}
                    anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'bottom',
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            setSelectedFiles([
                                ...(selectedFiles ? selectedFiles : []),
                                //@ts-ignore
                                ...files.filter(
                                    (f) =>
                                        !(selectedFiles ? selectedFiles : [])
                                            .map((ff) => ff.userFileId)
                                            .includes(f.userFileId) && f.isOwner
                                ),
                            ]);
                            handleClose();
                        }}
                    >
                        Select All
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setSelectedFiles([
                                ...(selectedFiles ? selectedFiles : []),
                            ]);
                            handleClose();
                        }}
                    >
                        Select Multiple
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setSelectedFiles(null);
                            handleClose();
                        }}
                    >
                        Clear Selection
                    </MenuItem>
                </Menu>
            )}
        </Box>
    );
};
