interface IProps {
    filled?: boolean;
    color?: string;
    size?: number;
}
export const Filter = (props: IProps) => (
    <svg
        width={props.size || '16'}
        height={props.size || '16'}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        {!props.filled && (
            <>
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.35714 0C1.05533 0 0 1.01023 0 2.25641C0 3.50259 1.05533 4.51282 2.35714 4.51282C3.65896 4.51282 4.71429 3.50259 4.71429 2.25641C4.71429 1.01023 3.65896 0 2.35714 0ZM1.28571 2.25641C1.28571 1.68996 1.76541 1.23077 2.35714 1.23077C2.94888 1.23077 3.42857 1.68996 3.42857 2.25641C3.42857 2.82286 2.94888 3.28205 2.35714 3.28205C1.76541 3.28205 1.28571 2.82286 1.28571 2.25641Z"
                    fill={props.color || '#29319B'}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.6429 5.74359C11.341 5.74359 10.2857 6.75382 10.2857 8C10.2857 9.24618 11.341 10.2564 12.6429 10.2564C13.9447 10.2564 15 9.24618 15 8C15 6.75382 13.9447 5.74359 12.6429 5.74359ZM11.5714 8C11.5714 7.43355 12.0511 6.97436 12.6429 6.97436C13.2346 6.97436 13.7143 7.43355 13.7143 8C13.7143 8.56645 13.2346 9.02564 12.6429 9.02564C12.0511 9.02564 11.5714 8.56645 11.5714 8Z"
                    fill={props.color || '#29319B'}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.35714 11.4872C1.05533 11.4872 0 12.4974 0 13.7436C0 14.9898 1.05533 16 2.35714 16C3.65896 16 4.71429 14.9898 4.71429 13.7436C4.71429 12.4974 3.65896 11.4872 2.35714 11.4872ZM1.28571 13.7436C1.28571 13.1771 1.76541 12.7179 2.35714 12.7179C2.94888 12.7179 3.42857 13.1771 3.42857 13.7436C3.42857 14.31 2.94888 14.7692 2.35714 14.7692C1.76541 14.7692 1.28571 14.31 1.28571 13.7436Z"
                    fill={props.color || '#29319B'}
                />
            </>
        )}
        <path
            d="M7.5 1.64103C7.14496 1.64103 6.85714 1.91654 6.85714 2.25641C6.85714 2.59628 7.14496 2.87179 7.5 2.87179L14.3571 2.8718C14.7122 2.8718 15 2.59628 15 2.25641C15 1.91654 14.7122 1.64103 14.3571 1.64103L7.5 1.64103Z"
            fill={props.color || '#29319B'}
        />
        <path
            d="M0.642857 7.38461C0.287817 7.38461 0 7.66013 0 8C0 8.33987 0.287817 8.61538 0.642857 8.61538L7.5 8.61539C7.85504 8.61539 8.14286 8.33987 8.14286 8C8.14286 7.66013 7.85504 7.38462 7.5 7.38462L0.642857 7.38461Z"
            fill={props.color || '#29319B'}
        />
        <path
            d="M7.5 13.1282C7.14496 13.1282 6.85714 13.4037 6.85714 13.7436C6.85714 14.0835 7.14496 14.359 7.5 14.359H14.3571C14.7122 14.359 15 14.0835 15 13.7436C15 13.4037 14.7122 13.1282 14.3571 13.1282H7.5Z"
            fill={props.color || '#29319B'}
        />
        {props.filled && (
            <>
                <path
                    d="M0 2.25641C0 1.01023 1.12568 0 2.51429 0C3.90289 0 5.02857 1.01023 5.02857 2.25641C5.02857 3.50259 3.90289 4.51282 2.51429 4.51282C1.12568 4.51282 0 3.50259 0 2.25641Z"
                    fill={props.color || '#29319B'}
                />
                <path
                    d="M10.9714 8C10.9714 6.75382 12.0971 5.74359 13.4857 5.74359C14.8743 5.74359 16 6.75382 16 8C16 9.24618 14.8743 10.2564 13.4857 10.2564C12.0971 10.2564 10.9714 9.24618 10.9714 8Z"
                    fill={props.color || '#29319B'}
                />
                <path
                    d="M0 13.7436C0 12.4974 1.12568 11.4872 2.51429 11.4872C3.90289 11.4872 5.02857 12.4974 5.02857 13.7436C5.02857 14.9898 3.90289 16 2.51429 16C1.12568 16 0 14.9898 0 13.7436Z"
                    fill={props.color || '#29319B'}
                />
            </>
        )}
    </svg>
);
