import React, { useState } from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableSortLabel,
    tableSortLabelClasses,
    tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/system';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { UtilsService } from '../../../services/utils.service';

interface Column {
    id: string;
    label: string;
}

interface Data {
    id: string;
    [key: string]: any;
}

interface SortableTableProps {
    columns: Column[];
    data: Data[];
    convertToInternationalCurrencySystem?: boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        padding: '10px 24px',
        borderBottom: 'none',
        textAlign: 'left',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 'none',
        padding: '10px 24px',
        textAlign: 'left',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#F9F9FF',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const SortableTable: React.FC<SortableTableProps> = ({
    columns,
    data,
    convertToInternationalCurrencySystem,
}) => {
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<string>(columns[0].id);

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedData = [...data].sort((a, b) => {
        if (typeof a[orderBy] === 'string') {
            return order === 'asc'
                ? a[orderBy].localeCompare(b[orderBy])
                : b[orderBy].localeCompare(a[orderBy]);
        } else if (typeof a[orderBy] === 'number') {
            return order === 'asc'
                ? a[orderBy] - b[orderBy]
                : b[orderBy] - a[orderBy];
        }
        return 0;
    });

    return (
        <TableContainer
            sx={{
                maxHeight: '600px',
                borderTop: '1px solid #D7D9EC',
            }}
        >
            <Table
                stickyHeader
                sx={{ minWidth: 700 }}
                aria-label="sortable table"
            >
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <StyledTableCell
                                key={column.id}
                                sx={{ textAlign: 'left !important' }}
                            >
                                <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={
                                        orderBy === column.id ? order : 'asc'
                                    }
                                    onClick={() => handleRequestSort(column.id)}
                                    IconComponent={SwapVertIcon}
                                    hideSortIcon={false}
                                    sx={{
                                        [`.${tableSortLabelClasses.icon}`]: {
                                            opacity: 0.3,
                                        },
                                    }}
                                >
                                    {column.label}
                                </TableSortLabel>
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData.map((row) => (
                        <StyledTableRow>
                            {columns.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    component="th"
                                    scope="row"
                                    sx={{
                                        textAlign: 'left !important',
                                    }}
                                >
                                    {typeof row[column.id] === 'number' &&
                                    isFinite(row[column.id]) &&
                                    convertToInternationalCurrencySystem
                                        ? UtilsService.convertToInternationalCurrencySystem(
                                              row[column.id]
                                          )
                                        : row[column.id]}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SortableTable;
