import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BorderedBox } from '../../../components/core/box';
import InitialsAvator from '../../../components/core/avator';
import {
    Competitor,
    EmployeeDetail,
    LinkedInEducation,
    LinkedInExperience,
    LinkedInPublication,
    ReportModule,
} from '../../../services/interfaces';
import { NewsImagePlaceholder } from '../../../components/images/news-image-placeholder';
import { UtilsService } from '../../../services/utils.service';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { XIcon } from '../../../components/icons/x';
import { NoData } from './no-data';

const TableCellBorderless = styled(TableCell)(({ theme }) => ({
    border: 'none',
    padding: '4px 0px 4px 0px',
}));

interface IProps {
    data: ReportModule;
}

export const Competitors = (props: IProps) => {
    const [selected, setSelected] = useState<Competitor | null>(null);
    const { data } = props;
    const placeholderImage =
        'https://images.unsplash.com/photo-1511285605577-4d62fb50d2f7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=876&q=80';

    const onImageError = (e: any, name: string) => {
        e.target.src =
            'https://ui-avatars.com/api/?font-size=.35&color=ffffff&background=c7c7cb&name=' +
            name;
    };
    useEffect(() => {
        if (!selected && data && data?.competitors?.length) {
            setSelected(data?.competitors[0]);
        }
    }, [props.data]);
    return (
        <BorderedBox
            sx={{
                backgroundColor: '#F9F9FF',
            }}
            pb={4}
            my={4}
        >
            <Typography px={4} pt={4} variant="h4">
                Competitors
            </Typography>
            <Box
                className="scrollable"
                sx={{
                    maxHeight: 'calc(100vh - 220px)',
                    overflow: 'auto',
                }}
                px={4}
            >
                {data?.competitors?.length ? (
                    <Grid container>
                        <Grid item sm={4}>
                            <Box pr={2}>
                                {data?.competitors?.map(
                                    (e: Competitor, index) => (
                                        <Box
                                            key={index}
                                            onClick={() => setSelected(e)}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                my: 2,
                                                px: 1,
                                                py: 3,
                                                cursor: 'pointer',
                                                borderRadius: 1,
                                                border:
                                                    selected?.name == e.name
                                                        ? '1px solid #ccc'
                                                        : 'none',
                                                backgroundColor:
                                                    selected?.name == e.name
                                                        ? '#FFFFFF'
                                                        : '#F9F9FF',
                                            }}
                                        >
                                            {e.imageData ? (
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        width: 48,
                                                        height: 48,
                                                        borderRadius: 2,
                                                    }}
                                                    onError={(event) =>
                                                        onImageError(
                                                            event,
                                                            e.name
                                                        )
                                                    }
                                                    src={`data:image/png;base64,${e.imageData}`}
                                                />
                                            ) : (
                                                <Box
                                                    sx={{
                                                        width: 48,
                                                        height: 48,
                                                        borderRadius: 2,
                                                        backgroundColor:
                                                            '#c7c7cb',
                                                    }}
                                                >
                                                    <InitialsAvator
                                                        height={48}
                                                        width={48}
                                                        fontSize={16}
                                                        backgroundColor="#c7c7cb"
                                                        value={e.name}
                                                    />
                                                </Box>
                                            )}
                                            <Box pl={2}>
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight={600}
                                                >
                                                    {e.name}
                                                </Typography>
                                                <Typography
                                                    color="#4B4B4C"
                                                    fontSize={12}
                                                    className="clamp-line-2"
                                                >
                                                    {
                                                        e.competitorInfo
                                                            .companyDescription
                                                    }
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )
                                )}
                            </Box>
                        </Grid>
                        <Grid sm={8}>
                            {selected && (
                                <Box pl={2}>
                                    <Typography variant="h4">
                                        {selected.name}
                                    </Typography>
                                    <Typography py={3} fontSize={14}>
                                        {
                                            selected.competitorInfo
                                                .companyDescription
                                        }
                                    </Typography>
                                    <Box
                                        sx={{
                                            borderTop: '1px dashed #E0E0E0',
                                            borderBottom: '1px dashed #E0E0E0',
                                        }}
                                        py={3}
                                        mb={3}
                                    >
                                        <TableContainer component={Box}>
                                            <Table sx={{ minWidth: 650 }}>
                                                <TableBody>
                                                    {[
                                                        {
                                                            key: 'Location',
                                                            value: selected
                                                                .competitorInfo
                                                                .location
                                                                ? `${selected.competitorInfo.location?.city}, ${selected.competitorInfo.location?.region}, ${selected.competitorInfo.location?.country}`
                                                                : '',
                                                        },
                                                        {
                                                            key: 'Number Of Employees',
                                                            value: selected
                                                                .competitorInfo
                                                                .numEmployees,
                                                        },
                                                        {
                                                            key: 'Last funding type',
                                                            value: selected
                                                                .competitorInfo
                                                                .lastFundingType,
                                                        },
                                                        {
                                                            key: 'IPO Status',
                                                            value: selected
                                                                .competitorInfo
                                                                .ipoStatus,
                                                        },
                                                        {
                                                            key: 'Website',
                                                            value: selected
                                                                .competitorInfo
                                                                .website,
                                                        },
                                                    ].map((pair) => (
                                                        <TableRow
                                                            key={pair.key}
                                                        >
                                                            <TableCellBorderless
                                                                sx={{
                                                                    width: '200px',
                                                                }}
                                                                scope="row"
                                                            >
                                                                <Typography variant="h6">
                                                                    {pair.key}
                                                                </Typography>
                                                            </TableCellBorderless>
                                                            <TableCellBorderless align="left">
                                                                <Typography
                                                                    fontSize={
                                                                        16
                                                                    }
                                                                >
                                                                    {pair.value}
                                                                </Typography>
                                                            </TableCellBorderless>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                    <TableContainer component={Box}>
                                        <Table sx={{ minWidth: 650 }}>
                                            <TableBody>
                                                {[
                                                    {
                                                        key: 'Industries',
                                                        value: (
                                                            <Box
                                                                flexWrap={
                                                                    'wrap'
                                                                }
                                                                display="flex"
                                                            >
                                                                {selected.competitorInfo.industries?.map(
                                                                    (
                                                                        industry
                                                                    ) => (
                                                                        <Box
                                                                            px={
                                                                                1
                                                                            }
                                                                            mr={
                                                                                2
                                                                            }
                                                                            borderRadius={
                                                                                0.75
                                                                            }
                                                                            sx={{
                                                                                backgroundColor:
                                                                                    '#EBECF6',
                                                                            }}
                                                                            key={
                                                                                industry
                                                                            }
                                                                        >
                                                                            {
                                                                                industry
                                                                            }
                                                                        </Box>
                                                                    )
                                                                )}
                                                            </Box>
                                                        ),
                                                    },
                                                    {
                                                        key: 'Headquarters regions',
                                                        value: selected
                                                            .competitorInfo
                                                            .location?.region,
                                                    },
                                                    {
                                                        key: 'Founding date',
                                                        value: selected
                                                            .competitorInfo
                                                            .foundingDate,
                                                    },
                                                    {
                                                        key: 'Founders',
                                                        value: selected.competitorInfo.founders?.join(
                                                            ', '
                                                        ),
                                                    },
                                                    {
                                                        key: 'Legal name',
                                                        value: selected
                                                            .competitorInfo
                                                            .legalName,
                                                    },
                                                    {
                                                        key: 'Stock symbol',
                                                        value: selected
                                                            .competitorInfo
                                                            .stockSymbol,
                                                    },
                                                    {
                                                        key: 'Contact email',
                                                        value: (
                                                            <a
                                                                href={`mailto:${data.contactEmail}`}
                                                            >
                                                                {
                                                                    selected
                                                                        .competitorInfo
                                                                        .contactEmail
                                                                }
                                                            </a>
                                                        ),
                                                    },
                                                    {
                                                        key: 'Social Media',
                                                        value: (
                                                            <Box
                                                                display={'flex'}
                                                            >
                                                                {selected.competitorInfo.socialMedia?.map(
                                                                    (
                                                                        social
                                                                    ) => (
                                                                        <Box
                                                                            pr={
                                                                                2
                                                                            }
                                                                            component={
                                                                                'a'
                                                                            }
                                                                            href={
                                                                                social.url
                                                                            }
                                                                            target="_blank"
                                                                            lineHeight={
                                                                                '10px'
                                                                            }
                                                                        >
                                                                            {social.platform ==
                                                                                'facebook' && (
                                                                                <FacebookIcon
                                                                                    sx={{
                                                                                        fontSize: 26,
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {social.platform ==
                                                                                'linkedin' && (
                                                                                <LinkedInIcon
                                                                                    sx={{
                                                                                        fontSize: 26,
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {social.platform ==
                                                                                'twitter' &&
                                                                                XIcon}
                                                                        </Box>
                                                                    )
                                                                )}
                                                            </Box>
                                                        ),
                                                    },
                                                ].map((pair) => (
                                                    <TableRow key={pair.key}>
                                                        <TableCellBorderless
                                                            sx={{
                                                                width: '200px',
                                                            }}
                                                            scope="row"
                                                        >
                                                            <Typography variant="h6">
                                                                {pair.key}
                                                            </Typography>
                                                        </TableCellBorderless>
                                                        <TableCellBorderless align="left">
                                                            <Typography
                                                                fontSize={16}
                                                            >
                                                                {pair.value}
                                                            </Typography>
                                                        </TableCellBorderless>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <NoData />
                )}
            </Box>
        </BorderedBox>
    );
};
