import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    Paper,
    Typography,
    styled,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { BorderedBox } from '../../../components/core/box';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DoneIcon from '@mui/icons-material/Done';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { RecentHappening } from './config/recent-happening';
import { Common } from './config/common';
import { ProductOffering } from './config/product-offering';
import { Customers } from './config/customers';
import { BusinessModel } from './config/business-model';
import { SentimentAnalysis } from './config/setiment-analysis';
import { Financials } from './config/financials';
import { IOSSwitch } from '../../../components/core/ios-switch';
import { SASB } from './config/sasb';
import { CustomRequest } from './custom-request';
import { Delete } from '../../../components/icons/delete';
import { CustomRequestConfig } from './config/custom-request';
import { useDiligenceService } from '../../../services/diligence.service';
import {
    IDiligenceTemplateConfiguration,
    IDiligenceTemplateConfigurationResponseData,
    IDiligenceTemplateData,
} from '../../../services/interfaces';
import { Input } from '../../../components/core/input';
import { Edit } from '../../../components/icons/edit';
import { Close } from '../../../components/icons/close';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { NavigationLink } from '../../../components/core/link';
import { UtilsService } from '../../../services/utils.service';

import { ErrorIcon } from '../../../components/icons/error';
import { REPORT_SECTIONS } from '../constant';
import { DefaultConfiguration } from './default-configuration';
import { WhiteLabel } from './config/white-label';
import { FeatureName, SubFeatureName } from '../../../services/interfaces';
import { Authorize } from '../../../components/authorize';
import { usePermissions } from '../../../providers/permissions-provider';

const SECTIONS_AUTHORIZATION = [
    {
        section: 'WHITE_LABELING',
        featureName: FeatureName.Diligence,
        subFeatureName: SubFeatureName.DiligenceWhiteLabeling,
    },
];

const DialogWrapper = styled(Paper)(() => ({
    width: '50%',
    maxWidth: '450px !important',
}));

interface IAuthorizeDiligenceSection {
    children: React.ReactNode;
    section: string;
}

const AuthorizeDiligenceSection = (props: IAuthorizeDiligenceSection) => {
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    const sectionAuthDetails = SECTIONS_AUTHORIZATION.filter(
        (s) => s.section == props.section
    )[0];
    return sectionAuthDetails &&
        !isSubFeatureAuthorized(
            sectionAuthDetails.featureName,
            sectionAuthDetails.subFeatureName
        ) ? (
        <Authorize
            featureName={sectionAuthDetails.featureName}
            subFeatureName={sectionAuthDetails.subFeatureName}
        >
            <Box
                sx={{
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    pointerEvents: 'none',
                }}
            >
                {props.children}
            </Box>
        </Authorize>
    ) : (
        <Box>{props.children}</Box>
    );
};

export const DiligenceTemplate = () => {
    const { templateId, action } = useParams();
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    const [defaultConfig, setDefaultConfig] =
        useState<IDiligenceTemplateConfiguration | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const {
        fetchDefaultConfiguration,
        updateTemplateConfiguration,
        fetchTemplateConfiguration,
    } = useDiligenceService();
    const [templateName, setTemplateName] = useState('New Template');
    const [editedTemplateName, setEditedTemplateName] =
        useState('New Template');
    const draggingPos = useRef(null);
    const [editName, setEditName] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const dragOverPos = useRef(null);
    const [selectedTab, setSelectedTab] =
        useState<IDiligenceTemplateConfiguration | null>(null);
    const [items, setItems] = useState<IDiligenceTemplateConfiguration[]>([]);
    const [isunsavedChanges, setIsUnsavedChanges] = useState(false);
    const [confirmNavigation, setConfirmNavigation] = useState(false);

    const isSectionAuthorized = (section: string) => {
        let sectionAuth = SECTIONS_AUTHORIZATION.filter(
            (s) => s.section == section
        )[0];
        if (!sectionAuth) return true;
        else
            return isSubFeatureAuthorized(
                sectionAuth.featureName,
                sectionAuth.subFeatureName
            );
    };

    const refreshConfiguration = async () => {
        if (!templateId) return;
        if (templateId == 'new') {
            let templateData = await fetchDefaultConfiguration();
            let templateConfigurations = templateData.configurations;
            setDefaultConfig({
                guid: UtilsService.uuidv4(),
                isEnabled: true,
                templateConfiguration: { relativeOrder: 1 },
                sectionTemplate: templateData.defaultTemplateConfiguration,
            });
            templateConfigurations = templateConfigurations.map(
                (item, index) => {
                    item.guid = UtilsService.uuidv4();
                    item.templateConfiguration = { relativeOrder: index };
                    return item;
                }
            );
            setItems(
                templateConfigurations.sort((a, b) =>
                    a.templateConfiguration &&
                    b.templateConfiguration &&
                    a.templateConfiguration.relativeOrder <
                        b.templateConfiguration.relativeOrder
                        ? -1
                        : 1
                )
            );
            setSelectedTab(
                templateConfigurations
                    .filter(
                        (s) =>
                            s.isEnabled &&
                            isSectionAuthorized(
                                s.sectionTemplate.diligenceSection
                            )
                    )
                    .sort((a, b) =>
                        a.templateConfiguration &&
                        b.templateConfiguration &&
                        a.templateConfiguration.relativeOrder <
                            b.templateConfiguration.relativeOrder
                            ? -1
                            : 1
                    )[0]
            );
        } else if (templateId != 'new') {
            let template: IDiligenceTemplateConfigurationResponseData =
                await fetchTemplateConfiguration(templateId);
            if (action == 'clone') {
                setTemplateName(template.templateTitle + '_clone');
            } else {
                setTemplateName(template.templateTitle);
            }
            let templateConfigurations = template.configurations;
            setDefaultConfig({
                guid: UtilsService.uuidv4(),
                isEnabled: true,
                templateConfiguration: { relativeOrder: 1 },
                sectionTemplate: template.defaultTemplateConfiguration,
            });
            templateConfigurations = templateConfigurations.map(
                (item, index) => {
                    item.guid = UtilsService.uuidv4();
                    return item;
                }
            );
            setItems(
                templateConfigurations.sort((a, b) =>
                    a.templateConfiguration &&
                    b.templateConfiguration &&
                    a.templateConfiguration.relativeOrder <
                        b.templateConfiguration.relativeOrder
                        ? -1
                        : 1
                )
            );
            setSelectedTab(
                templateConfigurations

                    .filter(
                        (s) =>
                            s.isEnabled &&
                            isSectionAuthorized(
                                s.sectionTemplate.diligenceSection
                            )
                    )
                    .sort((a, b) =>
                        a.templateConfiguration &&
                        b.templateConfiguration &&
                        a.templateConfiguration.relativeOrder <
                            b.templateConfiguration.relativeOrder
                            ? -1
                            : 1
                    )[0]
            );
        }
    };
    useEffect(() => {
        refreshConfiguration();
    }, [templateId]);

    const saveTemplate = async () => {
        for (let config of items) {
            if (
                config.sectionTemplate.dateRange == 'CUSTOM' &&
                (!config.sectionTemplate.querySearchStartDate ||
                    !config.sectionTemplate.querySearchStartDate)
            ) {
                enqueueSnackbar(
                    `Invalid date range for ${
                        config.sectionTemplate.name
                            ? config.sectionTemplate.name
                            : REPORT_SECTIONS.filter(
                                  (i) =>
                                      i.tab ==
                                      config.sectionTemplate.diligenceSection
                              )[0].label
                    }`,
                    {
                        variant: 'error',
                    }
                );
                return;
            }
        }
        setIsProcessing(true);
        const data: IDiligenceTemplateData = {
            templateTitle: templateName,
            id: templateId,
            configurations: items,
            defaultTemplateConfiguration: defaultConfig?.sectionTemplate,
        };
        if (action == 'clone' || templateId == 'new') {
            delete data.id;
            data.configurations = data.configurations.map((item) => {
                item.id = undefined;
                return item;
            });
        }

        let res = await updateTemplateConfiguration(data);
        if (res) {
            enqueueSnackbar('Template successfully saved', {
                variant: 'success',
            });
            setIsUnsavedChanges(false);
            navigate('/diligence-suite');
        } else {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
        }
        setIsProcessing(false);
    };

    const handleDragStart = (position: any) => {
        draggingPos.current = position;
    };

    const handleDragEnter = (position: any) => {
        dragOverPos.current = position;
        const newItems = [...items];
        //@ts-ignore
        const draggingItem = newItems[draggingPos.current];
        if (!draggingItem) return;

        //@ts-ignore
        newItems.splice(draggingPos.current, 1);
        //@ts-ignore
        newItems.splice(dragOverPos.current, 0, draggingItem);

        const reorderedItems = newItems.map((item, index) => ({
            ...item,
            templateConfiguration: {
                ...item.templateConfiguration,
                relativeOrder: index,
            },
        }));

        draggingPos.current = position;
        dragOverPos.current = null;
        setItems(reorderedItems);
        setIsUnsavedChanges(true);
    };

    const addCustomCategory = (name: string, explaination: string) => {
        let newCategory = {
            guid: UtilsService.uuidv4(),
            isEnabled: true,
            sectionTemplate: {
                diligenceSection: 'CUSTOM_CATEGORIES',
                name: name,
                explanation: explaination,
                enableInternalData: false,
                answerLength: 'DEFAULT',
                onlineSearchSource: 'INTERNET',
            },
            templateConfiguration: {
                relativeOrder: 14,
            },
        };
        setItems([...items, newCategory]);
        setSelectedTab(newCategory);
        setIsUnsavedChanges(true);
    };

    const removeCustomCategory = (guid: string) => {
        setItems([...items.filter((item) => item.guid !== guid)]);
        setIsUnsavedChanges(true);
        if (selectedTab?.guid == guid) {
            setSelectedTab(null);
        }
    };

    const applyDefaultConfig = () => {
        if (!defaultConfig) return;
        setItems(
            items.map((item) => {
                item.sectionTemplate.answerLength =
                    defaultConfig.sectionTemplate.answerLength;
                item.sectionTemplate.enableInternalData =
                    defaultConfig.sectionTemplate.enableInternalData;
                item.sectionTemplate.dateRange =
                    defaultConfig.sectionTemplate.dateRange;
                item.sectionTemplate.querySearchStartDate =
                    defaultConfig.sectionTemplate.querySearchStartDate;
                item.sectionTemplate.querySearchEndDate =
                    defaultConfig.sectionTemplate.querySearchEndDate;
                item.sectionTemplate.userFilesFilter =
                    defaultConfig.sectionTemplate.userFilesFilter;
                item.sectionTemplate.customDomains =
                    defaultConfig.sectionTemplate.customDomains;
                item.sectionTemplate.onlineSearchSource =
                    defaultConfig.sectionTemplate.onlineSearchSource;
                item.sectionTemplate.userTrustedDomainId =
                    defaultConfig.sectionTemplate.userTrustedDomainId;
                return item;
            })
        );
    };

    const updateConfig = (
        config: IDiligenceTemplateConfiguration,
        refreshOrder?: boolean
    ) => {
        let newItems = items.map((i) => {
            if (i.guid === config.guid) {
                return config;
            }
            return i;
        });
        if (!refreshOrder) {
            setItems([...newItems]);
        } else {
            setItems([
                ...newItems
                    .filter((item) => item.isEnabled)
                    .map((item, index) => {
                        item.templateConfiguration.relativeOrder = index;
                        return item;
                    }),
                ...newItems
                    .filter((item) => !item.isEnabled)
                    .map((item, index) => {
                        item.templateConfiguration.relativeOrder =
                            newItems.filter((item) => item.isEnabled).length +
                            index;
                        return item;
                    }),
            ]);
        }
        setIsUnsavedChanges(true);
        setSelectedTab(config);
    };

    // useEffect(refreshRelativeOrder,[items])

    return (
        <Box px={5} textAlign={'left'} pt={5}>
            <Dialog PaperComponent={DialogWrapper} open={confirmNavigation}>
                <DialogContent>
                    <Box textAlign="center">
                        <Box>{ErrorIcon}</Box>
                        <Typography
                            color="#000033"
                            mb={2}
                            textAlign="center"
                            variant="h4"
                        >
                            Are you sure?
                        </Typography>
                        <Typography
                            color="#000033"
                            mb={2}
                            fontSize={14}
                            textAlign="center"
                            variant="caption"
                        >
                            "If you go back, all the progress will be lost.
                            Please save your template"
                        </Typography>
                    </Box>
                    <Box></Box>
                </DialogContent>
                <DialogActions>
                    <Grid px={6} py={4} spacing={6} container>
                        <Grid xs={6} item>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={() => setConfirmNavigation(false)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid xs={6} item>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                onClick={() => navigate('/diligence-suite')}
                            >
                                Leave
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <Box display="flex" justifyContent={'space-between'}>
                <Box display="flex">
                    <IconButton
                        sx={{ mt: 2 }}
                        onClick={() => {
                            if (isunsavedChanges) {
                                setConfirmNavigation(true);
                            } else {
                                navigate('/diligence-suite');
                            }
                        }}
                    >
                        <KeyboardBackspaceIcon
                            sx={{ cursor: 'pointer', color: '#29319B' }}
                        />
                    </IconButton>
                    <Box px={3} mr={3} sx={{ borderRight: '1px solid #ccc' }}>
                        <Typography variant="h4">Template Builder</Typography>
                    </Box>

                    {!editName && (
                        <Typography mt={1} color="#4a4b4c" variant="h6">
                            {templateName}{' '}
                            <IconButton
                                sx={{ p: 0 }}
                                onClick={() => {
                                    setEditedTemplateName(templateName);
                                    setEditName(true);
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </Typography>
                    )}
                    {editName && (
                        <Box width={400}>
                            <Input
                                mt={0}
                                pt={0}
                                pb={0}
                                endAdornment={
                                    <Box display={'flex'}>
                                        <IconButton
                                            sx={{ p: 0 }}
                                            onClick={() => {
                                                setTemplateName(
                                                    editedTemplateName
                                                );
                                                setEditName(false);
                                            }}
                                        >
                                            <DoneIcon color="success" />
                                        </IconButton>
                                        <IconButton
                                            sx={{ p: 0, ml: 3 }}
                                            onClick={() => {
                                                setEditName(false);
                                            }}
                                        >
                                            <Close color="red" />
                                        </IconButton>
                                    </Box>
                                }
                                fontSize={14}
                                autoFocus={true}
                                value={editedTemplateName}
                                backgroundColor="#fff"
                                type="text"
                                inputPadding="6px 10px"
                                onChange={(e) => {
                                    setEditedTemplateName(
                                        e.target.value.slice(0, 100)
                                    );
                                }}
                                placeholder="Enter Template name"
                            />
                        </Box>
                    )}
                </Box>
                {defaultConfig?.sectionTemplate && (
                    <DefaultConfiguration
                        config={defaultConfig}
                        applyDefaultConfig={applyDefaultConfig}
                        updateConfig={setDefaultConfig}
                    />
                )}
            </Box>
            <Box py={2}>
                <Divider />
            </Box>
            <Grid mt={1} container>
                <Grid item sm={4}>
                    <Typography variant="h6">Category section</Typography>
                    <Box
                        sx={{
                            maxHeight: 'calc(100vh - 186px)',
                            overflowY: 'auto',
                        }}
                        mt={2}
                    >
                        {items
                            .filter((t) => t.isEnabled)
                            .sort((a, b) =>
                                a.templateConfiguration &&
                                b.templateConfiguration &&
                                a.templateConfiguration.relativeOrder <
                                    b.templateConfiguration.relativeOrder
                                    ? -1
                                    : 1
                            )
                            .map((item, index) => (
                                <Box
                                    display="flex"
                                    onDragStart={() =>
                                        handleDragStart(index + 1)
                                    }
                                    draggable
                                    key={item.id}
                                >
                                    <Box pt={3}>
                                        <DragIndicatorIcon
                                            onDragEnter={() =>
                                                handleDragEnter(index + 1)
                                            }
                                            onDragOver={(e) =>
                                                e.preventDefault()
                                            }
                                            sx={{
                                                cursor: 'grab',
                                                '&:active': {
                                                    cursor: 'grabbing',
                                                },
                                            }}
                                        />
                                    </Box>
                                    <BorderedBox
                                        // draggable={false}
                                        className="item"
                                        width={'calc(100% - 32px)'}
                                        onClick={() => setSelectedTab(item)}
                                        display="flex"
                                        my={1}
                                        sx={{
                                            borderColor:
                                                selectedTab?.guid == item.guid
                                                    ? '#29319B !important'
                                                    : '#E3E3E3',
                                            backgroundColor:
                                                selectedTab?.guid == item.guid
                                                    ? '#F9F9FF !important'
                                                    : '#fff',
                                            cursor: 'pointer',
                                        }}
                                        justifyContent={'space-between'}
                                        p={2}
                                    >
                                        <Box display={'flex'}>
                                            <Box>
                                                <IOSSwitch
                                                    sx={{
                                                        width: 32,
                                                        verticalAlign:
                                                            'text-bottom',
                                                        height: 16,
                                                        '& .MuiSwitch-switchBase':
                                                            {
                                                                '&.Mui-checked':
                                                                    {
                                                                        transform:
                                                                            'translateX(16px)',
                                                                    },
                                                            },
                                                        '& .MuiSwitch-thumb': {
                                                            width: 12,
                                                            height: 12,
                                                        },
                                                    }}
                                                    size="small"
                                                    color="success"
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                        checked: boolean
                                                    ) => {
                                                        updateConfig(
                                                            {
                                                                ...item,
                                                                isEnabled:
                                                                    checked,
                                                            },
                                                            true
                                                        );
                                                    }}
                                                    checked={item.isEnabled}
                                                />
                                            </Box>
                                            <Typography
                                                mt={
                                                    item.sectionTemplate
                                                        .diligenceSection ===
                                                    'CUSTOM_CATEGORIES'
                                                        ? -1.5
                                                        : 0
                                                }
                                                ml={2}
                                            >
                                                {item.sectionTemplate.name
                                                    ? item.sectionTemplate.name
                                                    : REPORT_SECTIONS.filter(
                                                          (i) =>
                                                              i.tab ==
                                                              item
                                                                  .sectionTemplate
                                                                  .diligenceSection
                                                      )[0].label}
                                                {item.sectionTemplate
                                                    .diligenceSection ===
                                                    'CUSTOM_CATEGORIES' && (
                                                    <Box
                                                        pl={0.5}
                                                        fontSize={15}
                                                        color="#0F62FE"
                                                        component="sup"
                                                    >
                                                        *
                                                    </Box>
                                                )}
                                            </Typography>
                                        </Box>
                                        <Box display={'flex'}>
                                            {item.sectionTemplate
                                                .diligenceSection ===
                                                'CUSTOM_CATEGORIES' && (
                                                <IconButton
                                                    color="error"
                                                    onClick={(e) => {
                                                        removeCustomCategory(
                                                            item.guid
                                                        );
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}
                                                    sx={{ p: 0, mr: 2 }}
                                                >
                                                    <Delete color="red" />
                                                </IconButton>
                                            )}
                                            <ArrowForwardIcon
                                                sx={{
                                                    color:
                                                        item.sectionTemplate
                                                            .diligenceSection ===
                                                        'CUSTOM_CATEGORIES'
                                                            ? '#0F62FE'
                                                            : '#1C1B1F',
                                                }}
                                                fontSize="small"
                                            />
                                        </Box>
                                    </BorderedBox>
                                </Box>
                            ))}
                        {items
                            .filter((t) => !t.isEnabled)
                            .sort((a, b) =>
                                a.templateConfiguration &&
                                b.templateConfiguration &&
                                a.templateConfiguration.relativeOrder <
                                    b.templateConfiguration.relativeOrder
                                    ? -1
                                    : 1
                            )
                            .map((item, index) => (
                                <AuthorizeDiligenceSection
                                    section={
                                        item.sectionTemplate.diligenceSection
                                    }
                                >
                                    <Box display="flex" key={item.id}>
                                        <BorderedBox
                                            className="item"
                                            width={'100%'}
                                            onClick={() => setSelectedTab(item)}
                                            display="flex"
                                            my={1}
                                            sx={{
                                                borderColor:
                                                    selectedTab?.guid ==
                                                    item.guid
                                                        ? '#29319B !important'
                                                        : '#E3E3E3',
                                                backgroundColor:
                                                    selectedTab?.guid ==
                                                    item.guid
                                                        ? '#F9F9FF !important'
                                                        : '#fff',
                                                cursor: 'pointer',
                                            }}
                                            justifyContent={'space-between'}
                                            p={2}
                                        >
                                            <Box display={'flex'}>
                                                <Box>
                                                    <IOSSwitch
                                                        sx={{
                                                            width: 32,
                                                            height: 16,
                                                            verticalAlign:
                                                                'text-bottom',
                                                            '& .MuiSwitch-switchBase':
                                                                {
                                                                    '&.Mui-checked':
                                                                        {
                                                                            transform:
                                                                                'translateX(16px)',
                                                                        },
                                                                },
                                                            '& .MuiSwitch-thumb':
                                                                {
                                                                    width: 12,
                                                                    height: 12,
                                                                },
                                                        }}
                                                        size="small"
                                                        color="success"
                                                        onChange={(
                                                            event: React.ChangeEvent<HTMLInputElement>,
                                                            checked: boolean
                                                        ) => {
                                                            updateConfig(
                                                                {
                                                                    ...item,
                                                                    isEnabled:
                                                                        checked,
                                                                },
                                                                true
                                                            );
                                                        }}
                                                        checked={item.isEnabled}
                                                    />
                                                </Box>
                                                <Typography
                                                    mt={
                                                        item.sectionTemplate
                                                            .diligenceSection ===
                                                        'CUSTOM_CATEGORIES'
                                                            ? -1.5
                                                            : 0
                                                    }
                                                    ml={2}
                                                >
                                                    {item.sectionTemplate.name
                                                        ? item.sectionTemplate
                                                              .name
                                                        : REPORT_SECTIONS.filter(
                                                              (i) =>
                                                                  i.tab ==
                                                                  item
                                                                      .sectionTemplate
                                                                      .diligenceSection
                                                          )[0].label}
                                                    {item.sectionTemplate
                                                        .diligenceSection ===
                                                        'CUSTOM_CATEGORIES' && (
                                                        <Box
                                                            pl={0.5}
                                                            fontSize={15}
                                                            color="#0F62FE"
                                                            component="sup"
                                                        >
                                                            *
                                                        </Box>
                                                    )}
                                                </Typography>
                                            </Box>
                                            <Box display={'flex'}>
                                                {item.sectionTemplate
                                                    .diligenceSection ===
                                                    'CUSTOM_CATEGORIES' && (
                                                    <IconButton
                                                        color="error"
                                                        onClick={(e) => {
                                                            removeCustomCategory(
                                                                item.guid
                                                            );
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                        sx={{ p: 0, mr: 2 }}
                                                    >
                                                        <Delete color="red" />
                                                    </IconButton>
                                                )}
                                                <ArrowForwardIcon
                                                    sx={{
                                                        color:
                                                            item.sectionTemplate
                                                                .diligenceSection ===
                                                            'CUSTOM_CATEGORIES'
                                                                ? '#0F62FE'
                                                                : '#1C1B1F',
                                                    }}
                                                    fontSize="small"
                                                />
                                            </Box>
                                        </BorderedBox>
                                    </Box>
                                </AuthorizeDiligenceSection>
                            ))}
                        <CustomRequest addCategory={addCustomCategory} />
                    </Box>
                </Grid>
                <Grid pl={8} item sm={8}>
                    <Box display="flex" justifyContent={'space-between'}>
                        <Typography variant="h6">
                            Section configuration
                        </Typography>
                    </Box>
                    <Box
                        height={'calc(100vh - 280px)'}
                        sx={{
                            overflowY: 'auto',
                        }}
                    >
                        {selectedTab && (
                            <Box pt={3}>
                                {selectedTab.sectionTemplate
                                    .diligenceSection === 'WHITE_LABELING' && (
                                    <WhiteLabel
                                        updateConfig={updateConfig}
                                        config={selectedTab}
                                    />
                                )}
                                {selectedTab.sectionTemplate
                                    .diligenceSection ===
                                    'RECENT_HAPPENINGS' && (
                                    <RecentHappening
                                        updateConfig={updateConfig}
                                        config={selectedTab}
                                    />
                                )}
                                {selectedTab.sectionTemplate
                                    .diligenceSection ===
                                    'RECENT_HEADLINES' && (
                                    <Common
                                        enableDataSourceSelection={true}
                                        config={selectedTab}
                                        updateConfig={updateConfig}
                                        enabledSource="WEB"
                                        title="Recent Headlines"
                                        caption="News Headlines corresponding to the company"
                                    />
                                )}
                                {selectedTab.sectionTemplate
                                    .diligenceSection === 'OVERVIEW' && (
                                    <Common
                                        config={selectedTab}
                                        updateConfig={updateConfig}
                                        title="Overview"
                                        caption="Company Overview"
                                    />
                                )}
                                {selectedTab.sectionTemplate
                                    .diligenceSection === 'MANAGEMENT' && (
                                    <Common
                                        config={selectedTab}
                                        updateConfig={updateConfig}
                                        title="Management Team"
                                        caption="List of company executives and work history"
                                    />
                                )}
                                {selectedTab.sectionTemplate
                                    .diligenceSection ===
                                    'PRODUCT_OFFERINGS' && (
                                    <ProductOffering
                                        updateConfig={updateConfig}
                                        config={selectedTab}
                                    />
                                )}
                                {selectedTab.sectionTemplate
                                    .diligenceSection === 'CUSTOMERS' && (
                                    <Customers
                                        updateConfig={updateConfig}
                                        config={selectedTab}
                                    />
                                )}
                                {selectedTab.sectionTemplate
                                    .diligenceSection === 'SWOT' && (
                                    <Common
                                        config={selectedTab}
                                        updateConfig={updateConfig}
                                        title="SWOT Analysis"
                                        enableDataSourceSelection={true}
                                        caption="Strengths, Weaknesses, Opportunities and Threats"
                                    />
                                )}
                                {selectedTab.sectionTemplate
                                    .diligenceSection === 'COMPETITORS' && (
                                    <Common
                                        config={selectedTab}
                                        title="Competitors"
                                        updateConfig={updateConfig}
                                        caption="Competitive Landscape"
                                    />
                                )}
                                {selectedTab.sectionTemplate
                                    .diligenceSection === 'BUSINESS_MODEL' && (
                                    <BusinessModel
                                        updateConfig={updateConfig}
                                        config={selectedTab}
                                    />
                                )}
                                {selectedTab.sectionTemplate
                                    .diligenceSection ===
                                    'SENTIMENT_ANALYSIS' && (
                                    <SentimentAnalysis
                                        updateConfig={updateConfig}
                                        config={selectedTab}
                                    />
                                )}
                                {selectedTab.sectionTemplate
                                    .diligenceSection === 'FINANCIALS' && (
                                    <Financials
                                        updateConfig={updateConfig}
                                        config={selectedTab}
                                    />
                                )}
                                {selectedTab.sectionTemplate
                                    .diligenceSection === 'RISK' && (
                                    <SASB
                                        updateConfig={updateConfig}
                                        config={selectedTab}
                                    />
                                )}
                                {selectedTab.sectionTemplate
                                    .diligenceSection ===
                                    'CUSTOM_CATEGORIES' && (
                                    <CustomRequestConfig
                                        updateConfig={updateConfig}
                                        config={selectedTab}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                    <Button
                        onClick={saveTemplate}
                        disabled={isProcessing}
                        sx={{ float: 'right', mt: -5, minWidth: 125 }}
                        variant="contained"
                    >
                        {isProcessing ? (
                            <CircularProgress
                                size={25}
                                sx={{
                                    color: '#ffffff',
                                    mr: 2,
                                }}
                            />
                        ) : (
                            'Save template'
                        )}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};
