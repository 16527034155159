import { useContext, useState } from 'react';
import { LiveQueryResponseDataSourcesResult } from '../../../services/interfaces';
import CloseIcon from '@mui/icons-material/Close';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {
    Box,
    Typography,
    Tooltip,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Skeleton,
    styled,
} from '@mui/material';
import { QuestionLight } from '../../../components/icons/question-light';
import { Copy } from '../../../components/icons/copy';
import { UtilsService } from '../../../services/utils.service';
import FadeIn from 'react-fade-in';
import { NavigationLink } from '../../../components/core/link';
import { FileIcon } from '../../../components/icons/file';
import { AppContext } from '../../../providers/app-provider';
import { ThumbDown } from '../../../components/icons/thumb-down';
import { ThumbUp } from '../../../components/icons/thumb-up';
import { IChatMessage } from './interface';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { LiveResponse } from '../../../components/live/live-response';

export const ActionButton = styled(Button)(() => ({
    marginLeft: 2,
    padding: 0,
    minWidth: 0,
    '&:hover  path': {
        fill: '#4B4B4C',
    },
    '&:hover': {
        backgroundColor: 'transparent !important',
    },
}));

export const ChatMessage = (props: IChatMessage) => {
    const [copied, setCopied] = useState<boolean>(false);
    const appContext = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleCopyToClipboard = () => {
        let text = `${props.text}\n\n${getSourcesText(props.sources || [])}`;
        navigator.clipboard.writeText(text);
    };
    const getSourcesText = (sources: LiveQueryResponseDataSourcesResult[]) => {
        if (!sources || !sources.length) {
            return '';
        }
        return `Sources: \n${sources
            .map(
                (s: LiveQueryResponseDataSourcesResult) =>
                    `[${s.id || 0}] ${s.url}`
            )
            .join('\n')}`;
    };

    return (
        <Box
            sx={{
                borderBottom: '1px solid #D7D9EC',
            }}
            pb={6}
            mb={6}
        >
            <Box display="flex">
                <Box pt={1.5}>
                    <QuestionLight
                        size={appContext?.isMobile ? '20px' : undefined}
                        color="#003"
                    />
                </Box>

                <Typography
                    ml={2}
                    lineHeight={appContext?.isMobile ? '26px' : '32px'}
                    fontWeight={600}
                    fontSize={appContext?.isMobile ? 18 : 28}
                    textAlign={'left'}
                >
                    {props.query}
                </Typography>
            </Box>
            <Box
                sx={{
                    borderRadius: 2,
                    padding: 1,
                    textAlign: 'left',
                }}
            >
                <Box py={1}>
                    <LiveResponse
                        entities={props.entities}
                        onCitationClick={(citationIndex: number) => {
                            //@ts-ignore
                            document
                                .getElementById(
                                    `${
                                        props.chatIds.chatAnswerId ||
                                        props.chatIds.chatQueryId
                                    }-citation-${citationIndex}`
                                )
                                .scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                    inline: 'center',
                                });
                            UtilsService.highlightContent(
                                `${
                                    props.chatIds.chatAnswerId ||
                                    props.chatIds.chatQueryId
                                }-citation-${citationIndex}`,
                                '#fff',
                                '#F9F9FF'
                            );
                        }}
                        highlight={props.highlightIndexes}
                        text={props.text}
                    />
                </Box>
            </Box>
            <Box>
                <Box display="flex" textAlign="left">
                    <Grid
                        sx={{ maxWidth: 'calc(100% - 80px)' }}
                        spacing={2}
                        container
                    >
                        {props.sources?.map(
                            (s: LiveQueryResponseDataSourcesResult, index) => (
                                <Grid item>
                                    <FadeIn delay={200}>
                                        <Box
                                            component={NavigationLink}
                                            to={
                                                s.answerSource == 'ARTICLE'
                                                    ? s.url
                                                    : '/document-insight/file/' +
                                                      s.userFileId
                                            }
                                            target="_blank"
                                            id={`${
                                                props.chatIds.chatAnswerId ||
                                                props.chatIds.chatQueryId
                                            }-citation-${index + 1}`}
                                            sx={{
                                                p: 2,
                                                borderRadius: '20px',
                                                border: '1px solid #D7D9EC',
                                                background: '#F9F9FF',
                                                cursor: 'pointer',
                                                boxShadow:
                                                    '0px 2px 10px 0px rgba(0, 0, 0, 0.20)',
                                            }}
                                            display="flex"
                                        >
                                            {s.answerSource == 'ARTICLE' && (
                                                <img
                                                    style={{
                                                        maxWidth: 20,
                                                        maxHeight: 16,
                                                        marginLeft: '4px',
                                                        marginRight: '4px',
                                                    }}
                                                    src={s.icon}
                                                />
                                            )}
                                            {s.answerSource == 'USER_DATA' && (
                                                <FileIcon
                                                    size={18}
                                                    type={
                                                        UtilsService.getFileType(
                                                            s.fileName
                                                        ).length <= 3
                                                            ? UtilsService.getFileType(
                                                                  s.fileName
                                                              )
                                                            : UtilsService.getFileType(
                                                                  s.fileName
                                                              )
                                                    }
                                                    textBackgroundColor="#29319B"
                                                />
                                            )}
                                            <span
                                                style={{
                                                    marginTop: '-3px',
                                                }}
                                                className="clamp-line-1"
                                            >
                                                <Tooltip
                                                    arrow
                                                    placement="top"
                                                    title={
                                                        s.answerSource ==
                                                        'ARTICLE'
                                                            ? s?.source
                                                            : 'Document'
                                                    }
                                                >
                                                    <Typography
                                                        color="#4B4B4C"
                                                        pr={1}
                                                        fontWeight={600}
                                                        variant="caption"
                                                    >
                                                        {s.answerSource ==
                                                        'ARTICLE'
                                                            ? s?.source
                                                            : 'Document'}
                                                    </Typography>
                                                </Tooltip>
                                            </span>
                                            <Typography
                                                sx={{
                                                    backgroundColor: `#D7D9EC`,
                                                    borderRadius: '50%',
                                                    fontSize: 10,
                                                    mt: 0.25,
                                                    padding: '2px 7px',
                                                    color: '#003',
                                                    py: 0.25,
                                                    ml: 1,
                                                    position: 'relative',
                                                    top: '-1px',
                                                }}
                                                component="span"
                                            >
                                                {index + 1}
                                            </Typography>
                                        </Box>
                                    </FadeIn>
                                </Grid>
                            )
                        )}
                        <Grid item>
                            <IconButton
                                aria-label="close"
                                onClick={() => {
                                    setOpen(true);
                                }}
                                sx={{
                                    color: '#003',
                                    ml: 2,
                                    mt: 3,
                                }}
                            >
                                <Tooltip
                                    arrow
                                    placement="top"
                                    title={`View Detailed sources`}
                                >
                                    <FormatListBulletedIcon
                                        sx={{
                                            fontSize: 20,
                                            color: '#29319B',
                                        }}
                                    />
                                </Tooltip>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Box mt={2} textAlign="right">
                        {/* <Tooltip
                                        arrow
                                        placement="top"
                                        title="Bookmark"
                                    >
                                        <ActionButton
                                        // onClick={
                                        //     props.onBookmarkClick
                                        // }
                                        >
                                            <BookmarkIcon color="#A5A6AB" />
                                        </ActionButton>
                                    </Tooltip> */}

                        <Tooltip
                            arrow
                            placement="top"
                            title={copied ? 'Copied to clipboard' : 'Copy'}
                        >
                            <ActionButton
                                sx={{
                                    '&:hover': {
                                        backgroundColor: '#fff',
                                    },
                                    '&:hover path': {
                                        fill: '#000',
                                    },
                                    marginRight: '4px',
                                }}
                                onMouseLeave={() => {
                                    setTimeout(() => {
                                        setCopied(false);
                                    }, 100);
                                }}
                                onClick={(event: any) => {
                                    event.stopPropagation();
                                    handleCopyToClipboard();
                                    setCopied(true);
                                }}
                            >
                                <Copy />
                            </ActionButton>
                        </Tooltip>
                        <Tooltip arrow placement="top" title="Upvote">
                            <ActionButton
                                onClick={() =>
                                    props.handleUpdateMessage
                                        ? props.handleUpdateMessage(
                                              props.chatIds,
                                              'upvote'
                                          )
                                        : {}
                                }
                                sx={{
                                    marginRight: '4px',
                                }}
                            >
                                {props.vote == 'upvote' ? (
                                    <ThumbUpIcon
                                        sx={{
                                            color: '#E59413',
                                            fontSize: 20,
                                        }}
                                    />
                                ) : (
                                    <ThumbUp />
                                )}
                            </ActionButton>
                        </Tooltip>
                        <Tooltip arrow placement="top" title="Downvote">
                            <ActionButton
                                onClick={() =>
                                    props.handleUpdateMessage
                                        ? props.handleUpdateMessage(
                                              props.chatIds,
                                              'downvote'
                                          )
                                        : {}
                                }
                            >
                                {props.vote == 'downvote' ? (
                                    <ThumbDownIcon
                                        sx={{
                                            color: '#E59413',
                                            fontSize: 20,
                                        }}
                                    />
                                ) : (
                                    <ThumbDown />
                                )}
                            </ActionButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
            <Dialog
                PaperComponent={Paper}
                PaperProps={{
                    sx: {
                        width: appContext?.isMobile ? '100%' : '70%',
                    },
                }}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle pb={0}>
                    <Box>
                        <Box display="flex">
                            <Typography variant="h4">Sources</Typography>
                            <Tooltip
                                arrow
                                placement="top"
                                title={copied ? 'Copied to clipboard' : 'Copy'}
                            >
                                <Button
                                    sx={{
                                        minWidth: 40,
                                        '&:hover': {
                                            backgroundColor: '#fff',
                                        },
                                        '&:hover path': {
                                            fill: '#000',
                                        },
                                    }}
                                    onMouseLeave={() => {
                                        setTimeout(() => {
                                            setCopied(false);
                                        }, 100);
                                    }}
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        handleCopyToClipboard();
                                        setCopied(true);
                                    }}
                                >
                                    <Copy />
                                </Button>
                            </Tooltip>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 24,
                                    top: 24,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <Tooltip arrow placement="top" title={`Close`}>
                                    <CloseIcon />
                                </Tooltip>
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ pr: 2 }}>
                    <Box textAlign="left">
                        <FadeIn delay={200}>
                            {props.sources?.map(
                                (
                                    s: LiveQueryResponseDataSourcesResult,
                                    index
                                ) => (
                                    <Box py={2}>
                                        <Box
                                            id={`${
                                                props.chatIds.chatAnswerId ||
                                                props.chatIds.chatQueryId
                                            }-citation-${index + 1}`}
                                        >
                                            <Box minHeight={30} display="flex">
                                                <Typography
                                                    mt={0.5}
                                                    lineHeight={'20px'}
                                                    variant="h6"
                                                >
                                                    {index + 1}.
                                                </Typography>
                                                {s.answerSource ==
                                                    'ARTICLE' && (
                                                    <img
                                                        style={{
                                                            maxWidth: 20,
                                                            maxHeight: 16,
                                                            marginTop: '4px',
                                                            marginLeft: '4px',
                                                            marginRight: '4px',
                                                        }}
                                                        src={s.icon}
                                                    />
                                                )}
                                                {s.answerSource ==
                                                    'USER_DATA' && (
                                                    <FileIcon
                                                        size={20}
                                                        type={
                                                            UtilsService.getFileType(
                                                                s.fileName
                                                            ).length <= 3
                                                                ? UtilsService.getFileType(
                                                                      s.fileName
                                                                  )
                                                                : UtilsService.getFileType(
                                                                      s.fileName
                                                                  )
                                                        }
                                                        textBackgroundColor="#29319B"
                                                    />
                                                )}
                                                <Typography
                                                    className="clamp-line-1"
                                                    variant="h6"
                                                    mt={0.5}
                                                    lineHeight={'20px'}
                                                >
                                                    {s.answerSource == 'ARTICLE'
                                                        ? s?.source
                                                        : 'Document'}
                                                </Typography>
                                                <Typography
                                                    ml="auto"
                                                    mt={1}
                                                    fontSize={14}
                                                    color="#4B4B4C"
                                                >
                                                    {s &&
                                                        s.answerSource ==
                                                            'ARTICLE' &&
                                                        UtilsService.formatStringDate(
                                                            s?.datetime
                                                        )}
                                                    {s &&
                                                        s.answerSource ==
                                                            'USER_DATA' &&
                                                        (s.pageNo
                                                            ? 'Page ' + s.pageNo
                                                            : UtilsService.formatStringDate(
                                                                  s?.uploadedOn
                                                              ))}
                                                </Typography>
                                            </Box>
                                            <Typography
                                                component={NavigationLink}
                                                to={
                                                    s.answerSource == 'ARTICLE'
                                                        ? s.url
                                                        : '/document-insight/file/' +
                                                          s.userFileId
                                                }
                                                target="_blank"
                                                className="clamp-line-1"
                                                variant="h6"
                                                color="#29319B"
                                            >
                                                {s.answerSource == 'ARTICLE'
                                                    ? s?.title
                                                    : s.fileName}
                                            </Typography>
                                            {!s.article && (
                                                <Box mt={3}>
                                                    <Skeleton variant="text" />
                                                    <Skeleton variant="text" />
                                                    <Skeleton variant="text" />
                                                </Box>
                                            )}
                                            {s.article && (
                                                <Typography
                                                    mt={2}
                                                    className="clamp-line-4"
                                                    fontSize={14}
                                                    variant="subtitle1"
                                                >
                                                    {s.article}{' '}
                                                </Typography>
                                            )}
                                        </Box>
                                        <Box pt={3}>
                                            <Divider />
                                        </Box>
                                    </Box>
                                )
                            )}
                        </FadeIn>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};
