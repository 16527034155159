import { Delete } from '@mui/icons-material';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Paper,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import React, { useState } from 'react';
import { DeleteImg } from '../../../../../components/icons/delete-img';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { UtilsService } from '../../../../../services/utils.service';
import { useSnackbar } from 'notistack';
import {
    IDriveItem,
    IIntegrationAccount,
} from '../../../../../services/interfaces';
import { useDriveService } from '../../../../../services/drive.service';
import { ErrorIcon } from '../../../../../components/icons/error';

const DialogWrapper = styled(Paper)(() => ({
    width: '40%',
    maxWidth: '450px !important',
}));

interface IProps {
    integrationAccount: IIntegrationAccount;
    sourcesForDeletion: IDriveItem[];
    onDelete: () => void;
}

export const DeleteSourceFolder = (props: IProps) => {
    const { deleteSource } = useDriveService();
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleDelete = async () => {
        setIsDeleting(true);
        if (props.sourcesForDeletion.length == 0 || isDeleting) return;
        let res = await deleteSource(
            props.integrationAccount.integration_name,
            props.integrationAccount.account_id,
            props.sourcesForDeletion.map((s) => s.item_id)
        );
        if (res) {
            props.onDelete();
            setOpen(false);
            setIsDeleting(false);
            enqueueSnackbar('Source folder deleted successfully', {
                variant: 'success',
            });
        } else {
            setOpen(false);
            enqueueSnackbar('Failed to delete source folder', {
                variant: 'error',
            });
            setIsDeleting(false);
        }
    };
    return (
        <Box>
            {isDeleting ? (
                <CircularProgress size={25} color="error" />
            ) : (
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(true)}
                    sx={{
                        color: (theme) => theme.palette.grey[500],
                        mr: 1,
                    }}
                >
                    <Tooltip arrow placement="top" title={`Delete`}>
                        <DeleteOutlineRoundedIcon color="error" />
                    </Tooltip>
                </IconButton>
            )}
            <Dialog PaperComponent={DialogWrapper} open={open}>
                <DialogContent>
                    <Box textAlign="center">
                        <Box>{ErrorIcon}</Box>
                        <Typography
                            color="#000033"
                            mb={2}
                            textAlign="center"
                            variant="h4"
                        >
                            Remove{' '}
                            {props.sourcesForDeletion.length == 1
                                ? `"${props.sourcesForDeletion[0].display_name}"`
                                : 'selected sources'}{' '}
                            as source folder
                            {props.sourcesForDeletion.length == 1 ? `` : 's'}.
                        </Typography>
                        <Typography
                            color="#000033"
                            mb={2}
                            fontSize={14}
                            textAlign="center"
                            variant="caption"
                        >
                            {props.sourcesForDeletion.length == 1
                                ? `Are you sure you want to remove "${props.sourcesForDeletion[0].display_name}" as a source folder? `
                                : `Are you sure you want to remove selected sources as a source folders? `}
                            All the files shared will also be get removed.
                        </Typography>
                    </Box>
                    <Box></Box>
                </DialogContent>
                <DialogActions>
                    <Grid px={6} py={4} spacing={6} container>
                        <Grid xs={6} item>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid xs={6} item>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                disabled={isDeleting}
                                onClick={handleDelete}
                            >
                                {isDeleting ? (
                                    <CircularProgress
                                        size={25}
                                        sx={{
                                            color: '#ffffff',
                                            ml: 2,
                                        }}
                                    />
                                ) : (
                                    'Remove'
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
