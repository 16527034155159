import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import React from 'react';

export const ContainedBox = styled(Box)(({ theme }) => ({
    padding: 20,
    textAlign: 'left',
}));

export const BorderedBox = styled(Box)(({ theme }) => ({
    border: '1px solid',
    borderColor: '#e3e3e3',
    borderRadius: 8,
}));

export const BorderedContainedBox = styled(ContainedBox)(({ theme }) => ({
    border: '1px solid',
    borderColor: '#e3e3e3',
    borderRadius: 8,
}));
