import React, { Suspense, useContext, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import  Login  from './pages/auth/login';
import Layout from './components/layout';
import AuthLayout from './components/auth-layout';

import * as Sentry from '@sentry/react';
import ScrollToTop from './components/scroll-to-top';
import { NotFound } from './pages/error/not-found';
import { Logout } from './pages/auth/logout';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import { PageLoader } from './components/page-loader';
import { UtilsService } from './services/utils.service';
import DocumentProvider from './providers/document-provider';
import NavigationAnalytics from './analytics/navigation-analytics';
import { FileMatches } from './pages/document-insights/drive/file-matches';

import { AppContext } from './providers/app-provider';
import { useAuthService } from './services/auth.service';
import { useNetworkAPI } from './services/network.service';
import {
    MenuItems,
    getMenuItems,
} from './components/layout/menu-items/menu-items';
import Account from './pages/account';
import { Research } from './pages/research';
import { WatchlistsAndSubscriptions } from './pages/watchlist-and-subscriptions';
import { Insights } from './pages/research/insights';
import { Chat } from './pages/research/chat';
import { DiligenceSuiteHome } from './pages/diligence-suite/home';
import { DiligenceTemplate } from './pages/diligence-suite/template';
import { DiligenceReport } from './pages/diligence-suite/diligence-report';
import { PermissionsProvider } from './providers/permissions-provider';
import { DocumentInsightsChat } from './pages/document-insights/chat';
import { Permission } from './services/interfaces';
import { AuthPermission } from './services/permissions.service';
import ReferralTracker from './components/referal-tracker';
import { Posthog } from './analytics/posthog';
import { SupportChat } from './components/support-chat';
const { REACT_APP_SHOW_SIGN_UP } = process.env;

const CompanyAnalysis = UtilsService.lazyWithRetry(
    () => import('./pages/company-analysis')
);

const DocumentData = UtilsService.lazyWithRetry(
    () => import('./pages/document-insights/drive/document')
);

const ChatbotWidgets = UtilsService.lazyWithRetry(
    () => import('./pages/chat-widget/home')
);

const ChatbotWidget = UtilsService.lazyWithRetry(
    () => import('./pages/chat-widget/chatbot')
);

const DiligenceSuite = UtilsService.lazyWithRetry(
    () => import('./pages/diligence-suite')
);

const DocumentInsight = UtilsService.lazyWithRetry(
    () => import('./pages/document-insights')
);

const InsightsData = UtilsService.lazyWithRetry(
    () => import('./pages/document-insights/insight-data')
);

const ResetPassword = UtilsService.lazyWithRetry(
    () => import('./pages/auth/reset-password')
);
const Comparison = UtilsService.lazyWithRetry(
    () => import('./pages/comparison')
);

const CIRReport = UtilsService.lazyWithRetry(
    () => import('./pages/cir/report')
);
const GroupDashboard = UtilsService.lazyWithRetry(
    () => import('./pages/cir/cir-dashboard')
);
const CIRAlert = UtilsService.lazyWithRetry(() => import('./pages/cir/alert'));
const Topic = UtilsService.lazyWithRetry(() => import('./pages/topic'));
const Login = UtilsService.lazyWithRetry(() => import('./pages/auth/login'));
const SignUp = UtilsService.lazyWithRetry(() => import('./pages/auth/signup'));
const ForgotPassword = UtilsService.lazyWithRetry(
    () => import('./pages/auth/forgot-password')
);
const SourceDistribution = UtilsService.lazyWithRetry(
    () => import('./pages/source-distribution')
);
const Insight = UtilsService.lazyWithRetry(() => import('./pages/insight'));
const Home = UtilsService.lazyWithRetry(() => import('./pages/home'));

interface IInitilizeProps {
    setPermissions: (p: Permission[]) => void;
}

function Initilize(props: IInitilizeProps) {
    const appContext = useContext(AppContext);
    const { getPermissions } = useAuthService();
    const { triggerRequest } = useNetworkAPI();
    const initializeApp = async () => {
        try {
            let permissions = await getPermissions(triggerRequest);
            if (permissions.length) {
                appContext?.setPermissions(permissions);
            }
        } catch {
            appContext?.setPermissions([AuthPermission]);
        }
    };
    useEffect(() => {
        if (UtilsService.isDemoApp()) return;
        if (!appContext?.permissions.length) {
            initializeApp();
            appContext?.setPermissions([AuthPermission]);
        }
    }, []);

    useEffect(() => {
        if (appContext?.permissions.length) {
            props.setPermissions(appContext?.permissions);
            appContext?.setPermissions(appContext?.permissions);
            appContext?.setMenuItems(
                getMenuItems(
                    MenuItems,
                    appContext?.permissions.map((p) => p.featureName)
                )
            );
        }
    }, [appContext?.permissions]);
    return (
        <Box>
            {appContext?.permissions.length == 0 &&
                !UtilsService.isDemoApp() && <PageLoader />}
        </Box>
    );
}

function App() {
    const appContext = useContext(AppContext);
    const [authPermissions, setAuthPermissions] = useState<Permission[]>([]);

    return (
        <div className="App">
            <Router>
                <Initilize setPermissions={setAuthPermissions} />
                {authPermissions.length >= 0 && (
                    <PermissionsProvider permissions={authPermissions}>
                        <NavigationAnalytics />
                        <ScrollToTop />
                        <Posthog />
                        <ReferralTracker />

                        {/* <SupportChat /> */}
                        <Routes>
                            <Route
                                path="/login"
                                element={
                                    <AuthLayout
                                        children={
                                            <Suspense fallback={<PageLoader />}>
                                                <Login />
                                            </Suspense>
                                        }
                                    />
                                }
                            />
                            {REACT_APP_SHOW_SIGN_UP == 'true' && (
                                <Route
                                    path="/signup"
                                    element={
                                        <AuthLayout
                                            children={
                                                <Suspense
                                                    fallback={<PageLoader />}
                                                >
                                                    <SignUp />
                                                </Suspense>
                                            }
                                        />
                                    }
                                />
                            )}

                            <Route
                                path="/forgot-password"
                                element={
                                    <AuthLayout
                                        children={
                                            <Suspense fallback={<PageLoader />}>
                                                <ForgotPassword />
                                            </Suspense>
                                        }
                                    />
                                }
                            />
                            <Route path="/logout" element={<Logout />} />
                            {((appContext &&
                                appContext?.permissions.length > 0) ||
                                UtilsService.isDemoApp()) && (
                                <React.Fragment>
                                    <Route
                                        path="/dashboard"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <Home />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/topic/:topicSubscriptionId"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <Topic />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/company-analysis"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <CompanyAnalysis />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/company-analysis/:topicSubscriptionId"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <CompanyAnalysis />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/distribution/:topicSubscriptionId"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <SourceDistribution />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/insight/:level/:topicSubscriptionId/:date/:filter1/:filter2"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <Insight />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/insight/:level/:topicSubscriptionId/:date/:filter1/"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <Insight />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/cir/report/"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <CIRReport />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/cir/report/:date/:clusterId/:categoryId/:mode"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <CIRReport />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/cir/alert/"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <CIRAlert />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/cir/dashboard/"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <GroupDashboard />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/view/:type/:typeId"
                                        element={
                                            <DocumentProvider>
                                                <Suspense
                                                    fallback={<PageLoader />}
                                                >
                                                    <DocumentData />
                                                </Suspense>
                                            </DocumentProvider>
                                        }
                                    />
                                    <Route
                                        path="/chat-widget"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <ChatbotWidgets />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/chat-widget/:chatbotId"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <ChatbotWidget />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/chat-widget/:chatbotId/:tabId"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <ChatbotWidget />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/document-insight/*"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <DocumentInsight />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    >
                                        <Route
                                            path=""
                                            element={<InsightsData />}
                                        />
                                        <Route
                                            path="chat/"
                                            element={
                                                <DocumentInsightsChat
                                                    handleClose={() => {}}
                                                />
                                            }
                                        />
                                        <Route
                                            path=":tab/"
                                            element={<InsightsData />}
                                        />
                                        <Route
                                            path=":type/:typeId"
                                            element={<InsightsData />}
                                        />
                                        <Route
                                            path=":type/:typeId"
                                            element={<InsightsData />}
                                        />
                                        <Route
                                            path="matches/:fileId/"
                                            element={<FileMatches />}
                                        />
                                        <Route
                                            path="matches/:fileId/:isShared"
                                            element={<FileMatches />}
                                        />
                                        <Route
                                            path=":type/:typeId/:tab"
                                            element={<InsightsData />}
                                        />
                                        <Route
                                            path=":type/:typeId/:tab/:accountId"
                                            element={<InsightsData />}
                                        />
                                    </Route>
                                    <Route
                                        path="/comparison"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <Comparison />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/reset-password"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <ResetPassword />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/my-account"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <Account />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/watchlists-and-alerts"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <WatchlistsAndSubscriptions />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    ></Route>

                                    <Route
                                        path="/diligence-suite/*"
                                        element={
                                            <Layout
                                                children={
                                                    <DocumentProvider>
                                                        <Suspense
                                                            fallback={
                                                                <PageLoader />
                                                            }
                                                        >
                                                            <DiligenceSuite />
                                                        </Suspense>
                                                    </DocumentProvider>
                                                }
                                            />
                                        }
                                    >
                                        <Route
                                            path=""
                                            element={<DiligenceSuiteHome />}
                                        />
                                        <Route
                                            path="template/:templateId"
                                            element={<DiligenceTemplate />}
                                        />
                                        <Route
                                            path="template/:templateId/:action"
                                            element={<DiligenceTemplate />}
                                        />
                                        <Route
                                            path="report/:reportId/"
                                            element={<DiligenceReport />}
                                        />
                                    </Route>
                                    <Route
                                        path="/"
                                        element={
                                            <Layout
                                                children={
                                                    <Suspense
                                                        fallback={
                                                            <PageLoader />
                                                        }
                                                    >
                                                        <Research />
                                                    </Suspense>
                                                }
                                            />
                                        }
                                    >
                                        <Route path="live" element={<Chat />} />
                                        <Route
                                            path="research/:tab?"
                                            element={
                                                <Insights
                                                    onSelected={() => {}}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route
                                        path="*"
                                        element={
                                            <Layout children={<NotFound />} />
                                        }
                                    />
                                </React.Fragment>
                            )}
                        </Routes>
                    </PermissionsProvider>
                )}
            </Router>
        </div>
    );
}

export default Sentry.withProfiler(App);
