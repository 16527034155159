import {
    Box,
    Button,
    Checkbox,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
    tableCellClasses,
} from '@mui/material';
import { useEffect, useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Delete } from '../../../components/icons/delete';
import { Input } from '../../../components/core/input';
import { SearchInsights } from '../../../components/icons/search-insights';
import { IDiligenceTemplate } from '../../../services/interfaces';
import { NavigationLink } from '../../../components/core/link';
import { Link } from 'react-router-dom';
import { useDiligenceService } from '../../../services/diligence.service';
import { useSnackbar } from 'notistack';
import { Add } from '../../../components/icons/add';
import { UtilsService } from '../../../services/utils.service';
import { usePermissions } from '../../../providers/permissions-provider';
import { Authorize } from '../../../components/authorize';
import { FeatureName, SubFeatureName } from '../../../services/interfaces';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        padding: '12px 24px',
        borderBottom: 'none',
        textAlign: 'left',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 'none',
        padding: '12px 24px',
        textAlign: 'left',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#F9F9FF',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface IProps {
    templates: IDiligenceTemplate[];
    refreshtemplate: () => void;
}

export const Templates = (props: IProps) => {
    const [searchValue, setSearchValue] = useState('');

    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    const { deleteTemplateConfiguration } = useDiligenceService();
    const { enqueueSnackbar } = useSnackbar();
    const { templates } = props;
    const deleteTemplate = async (templateId: string) => {
        const res = await deleteTemplateConfiguration(templateId);
        if (res) {
            props.refreshtemplate();
            enqueueSnackbar('Template successfully deleted', {
                variant: 'success',
            });
        } else {
            enqueueSnackbar('Template deletion failed', { variant: 'error' });
        }
    };
    const [selected, setSelected] = useState<string[]>([]);
    return (
        <Box>
            <Box
                display={'flex'}
                width={'100%'}
                justifyContent={'space-between'}
            >
                <Box py={2} px={6} width={'100%'} maxWidth={400}>
                    <Input
                        mt={0}
                        pt={0}
                        pb={0}
                        sx={{
                            pl: '4px !important',
                        }}
                        noShadow={true}
                        value={searchValue}
                        backgroundColor="#fff"
                        type="text"
                        startAdornment={
                            <InputAdornment position="end">
                                <SearchInsights
                                    height={15}
                                    width={15}
                                    fillColor="#A5A6AB"
                                />
                            </InputAdornment>
                        }
                        onChange={(e) => {
                            setSearchValue(e.target.value.slice(0, 100));
                        }}
                        placeholder="Search by template name"
                    />
                </Box>
                <Authorize
                    featureName={FeatureName.Diligence}
                    subFeatureName={SubFeatureName.CustomTemplate}
                >
                    <Button
                        component={NavigationLink}
                        disabled={
                            !isSubFeatureAuthorized(
                                FeatureName.Diligence,
                                SubFeatureName.CustomTemplate
                            )
                        }
                        to={'/diligence-suite/template/new'}
                        onClick={() => {}}
                        sx={{
                            height: 45,
                            mt: 3,
                            mr: 4,
                        }}
                    >
                        <Typography
                            variant="h6"
                            color={
                                isSubFeatureAuthorized(
                                    FeatureName.Diligence,
                                    SubFeatureName.CustomTemplate
                                )
                                    ? 'primary'
                                    : '#ccc'
                            }
                        >
                            <Box
                                sx={{ verticalAlign: 'middle' }}
                                mr={2}
                                component="span"
                            >
                                <Add
                                    size={14}
                                    color={
                                        isSubFeatureAuthorized(
                                            FeatureName.Diligence,
                                            SubFeatureName.CustomTemplate
                                        )
                                            ? '29319B'
                                            : '#ccc'
                                    }
                                />
                            </Box>
                            New template
                        </Typography>
                    </Button>
                </Authorize>
            </Box>
            <TableContainer
                sx={{
                    maxHeight: 'calc(100vh - 510px)',
                    borderTop: '1px solid #D7D9EC',
                }}
            >
                <Table
                    stickyHeader
                    sx={{ minWidth: 700 }}
                    aria-label="customized table"
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell
                                sx={{ textAlign: 'left !important' }}
                            >
                                Name of the Template
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                Date & Time (UTC)
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                Action
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {templates
                            .filter((t) =>
                                t.templateTitle
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase())
                            )
                            .map((row: IDiligenceTemplate, index) => (
                                <StyledTableRow key={index}>
                                    {/* <StyledTableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                            textAlign: 'center !important',
                                        }}
                                    >
                                        <Checkbox
                                            size="small"
                                            onChange={() => {
                                                if (selected.includes(row.id)) {
                                                    setSelected(
                                                        selected.filter(
                                                            (id) =>
                                                                id !== row.id
                                                        )
                                                    );
                                                } else {
                                                    setSelected([
                                                        ...selected,
                                                        row.id,
                                                    ]);
                                                }
                                            }}
                                            color="primary"
                                            checked={selected.includes(row.id)}
                                            inputProps={{
                                                'aria-labelledby': row.id,
                                            }}
                                        />
                                    </StyledTableCell> */}
                                    <StyledTableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                            textAlign: 'left !important',
                                        }}
                                    >
                                        {row.templateTitle}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {row.lastUpdatedAt &&
                                            UtilsService.formatUTCStringtoDateTime12H(
                                                row.lastUpdatedAt
                                            )}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <Box
                                            justifyContent={'space-between'}
                                            display={'flex'}
                                        >
                                            {row.id != 'default' && (
                                                <Authorize
                                                    featureName={
                                                        FeatureName.Diligence
                                                    }
                                                    subFeatureName={
                                                        SubFeatureName.CustomTemplate
                                                    }
                                                >
                                                    <Tooltip
                                                        arrow
                                                        open={
                                                            isSubFeatureAuthorized(
                                                                FeatureName.Diligence,
                                                                SubFeatureName.CustomTemplate
                                                            )
                                                                ? undefined
                                                                : false
                                                        }
                                                        placement="top"
                                                        title="Edit Template"
                                                    >
                                                        <IconButton
                                                            component={Link}
                                                            disabled={
                                                                !isSubFeatureAuthorized(
                                                                    FeatureName.Diligence,
                                                                    SubFeatureName.CustomTemplate
                                                                )
                                                            }
                                                            to={`/diligence-suite/template/${row.id}`}
                                                            sx={{ p: 0 }}
                                                            color="primary"
                                                        >
                                                            <EditOutlinedIcon
                                                                sx={{
                                                                    fontSize: 18,
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Authorize>
                                            )}
                                            <Authorize
                                                featureName={
                                                    FeatureName.Diligence
                                                }
                                                subFeatureName={
                                                    SubFeatureName.CustomTemplate
                                                }
                                            >
                                                <Tooltip
                                                    arrow
                                                    open={
                                                        isSubFeatureAuthorized(
                                                            FeatureName.Diligence,
                                                            SubFeatureName.CustomTemplate
                                                        )
                                                            ? undefined
                                                            : false
                                                    }
                                                    placement="top"
                                                    title="Clone Template"
                                                >
                                                    <IconButton
                                                        component={Link}
                                                        to={`/diligence-suite/template/${
                                                            row.id == 'default'
                                                                ? 'new'
                                                                : `${row.id}/clone`
                                                        }`}
                                                        disabled={
                                                            !isSubFeatureAuthorized(
                                                                FeatureName.Diligence,
                                                                SubFeatureName.CustomTemplate
                                                            )
                                                        }
                                                        sx={{ p: 0 }}
                                                        color="primary"
                                                    >
                                                        <FileCopyOutlinedIcon
                                                            sx={{
                                                                fontSize: 14,
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </Authorize>
                                            {row.id != 'default' && (
                                                <Authorize
                                                    featureName={
                                                        FeatureName.Diligence
                                                    }
                                                    subFeatureName={
                                                        SubFeatureName.CustomTemplate
                                                    }
                                                >
                                                    <Tooltip
                                                        arrow
                                                        open={
                                                            isSubFeatureAuthorized(
                                                                FeatureName.Diligence,
                                                                SubFeatureName.CustomTemplate
                                                            )
                                                                ? undefined
                                                                : false
                                                        }
                                                        placement="top"
                                                        title="Delete Template"
                                                    >
                                                        <IconButton
                                                            onClick={() =>
                                                                deleteTemplate(
                                                                    row.id
                                                                )
                                                            }
                                                            disabled={
                                                                !isSubFeatureAuthorized(
                                                                    FeatureName.Diligence,
                                                                    SubFeatureName.CustomTemplate
                                                                )
                                                            }
                                                            color="error"
                                                            sx={{ p: 0 }}
                                                        >
                                                            <Delete
                                                                color={
                                                                    isSubFeatureAuthorized(
                                                                        FeatureName.Diligence,
                                                                        SubFeatureName.CustomTemplate
                                                                    )
                                                                        ? 'red'
                                                                        : '#ccc'
                                                                }
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Authorize>
                                            )}
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
