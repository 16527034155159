export const Pointer = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
    >
        <path
            d="M6 9L6.936 6.948L9 6L6.936 5.064L6 3L5.058 5.064L3 6L5.058 6.948L6 9ZM3.702 0.456C4.43105 0.155969 5.21163 0.00107491 6 0C6.786 0 7.566 0.156 8.298 0.456C9.024 0.756 9.684 1.2 10.242 1.758C10.8 2.316 11.244 2.976 11.544 3.702C11.844 4.434 12 5.214 12 6C12 7.59 11.37 9.12 10.242 10.242C9.68557 10.8 9.02438 11.2425 8.2964 11.5442C7.56843 11.8459 6.78802 12.0008 6 12C5.21163 11.9989 4.43105 11.844 3.702 11.544C2.97484 11.2418 2.31428 10.7994 1.758 10.242C1.20001 9.68557 0.757463 9.02437 0.45577 8.2964C0.154077 7.56842 -0.000810844 6.78801 3.1921e-06 6C3.1921e-06 4.41 0.630003 2.88 1.758 1.758C2.316 1.2 2.976 0.756 3.702 0.456ZM2.604 9.396C3.504 10.296 4.728 10.8 6 10.8C7.272 10.8 8.496 10.296 9.396 9.396C10.296 8.496 10.8 7.272 10.8 6C10.8 4.728 10.296 3.504 9.396 2.604C8.9499 2.15839 8.42038 1.80505 7.83768 1.56414C7.25498 1.32324 6.63053 1.1995 6 1.2C4.728 1.2 3.504 1.704 2.604 2.604C2.1584 3.0501 1.80505 3.57962 1.56415 4.16232C1.32324 4.74502 1.19951 5.36947 1.2 6C1.2 7.272 1.704 8.496 2.604 9.396Z"
            fill="black"
        />
    </svg>
);
