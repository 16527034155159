import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { CheckedIcon } from '../../icons/checked-icon';
import { UncheckedIcon } from '../../icons/unchecked-icon';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { InputDownArrow } from '../../icons/input-down-arrow';
import { Button, Menu, styled, Tooltip, Typography } from '@mui/material';
import { FilterChecked } from '../../icons/filter-checked';
import { FilterUnchecked } from '../../icons/filter-unchecked';
import { HtmlTooltip } from '../../authorize';

const CustomTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .MuiTooltip-tooltip`]: {
        backgroundColor: '#fff',
        color: '#333',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: '0px',
        maxWidth: 300,
        right: 14,
        border: '1px solid #e0e0e0',
    },
    [`& .MuiTooltip-arrow`]: {
        color: '#fff',
        '&::before': {
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
        },
    },
}));

const ITEM_HEIGHT = 58;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface IDownArrowProps {
    onClick: () => void;
}

export const SelectDownArrow = (props: IDownArrowProps) => {
    return (
        <Box onClick={props.onClick} sx={{ cursor: 'pointer' }} p={2.5} pr={3}>
            <InputDownArrow />
        </Box>
    );
};

interface OptionDetails {
    id: string;
    description: string;
    displayName: string;
    additionalInfo: string;
    status: 'ACTIVE' | 'DISABLED';
}

interface IIconProps {
    color?: string;
    size?: number;
}

interface IProps {
    options: OptionDetails[];
    placeholder: string;
    isOptionDisabled?: (option: any) => boolean;
    getEndIcon?: (option: any) => JSX.Element | undefined;
    onChange?: (value: string) => void;
    allowClearSelection?: boolean;
    selectedValue: any;
    icon: (props: IIconProps) => JSX.Element;
    pt?: number;
    ptContainer?: number;
    disabled?: boolean;
    pb?: number;
    renderIconSize: number;
    mr?: number;
    ml?: number;
    renderColor?: string;
    width?: number;
    iconMt?: number;
    mt?: number;
    fontSize?: number;
    textMt?: number;
    iconPt?: number;
    backgroundColor?: string;
    border?: string;
    borderRadius?: number;
    disableCheckbox?: boolean;
    noBorder?: boolean;
}

export default function SingleSelectTooltip(props: IProps) {
    const [open, setOpen] = useState(false);
    const [selectEl, setSelectEl] = useState<null | HTMLElement>(null);
    const openSelect = Boolean(selectEl);
    const selectContainer = React.useRef();
    const [selectedValues, setSelectedValues] = useState<any>(
        props.selectedValue
    );

    const handleChange = (value: string) => {
        handleSelectClose();
        setSelectedValues(value);
        props.onChange && props.onChange(value);
    };

    const handleSelectClick = (event: React.MouseEvent<HTMLElement>) => {
        if (!props.disabled) setSelectEl(event.currentTarget);
    };
    const handleSelectClose = () => {
        setSelectEl(null);
    };

    useEffect(() => {
        setSelectedValues(props.selectedValue);
    }, [props.selectedValue]);

    return (
        <div>
            <Box
                ml={props.ml}
                mr={props.mr}
                pt={
                    props.ptContainer == undefined
                        ? props.pt == undefined
                            ? 2
                            : props.pt
                        : props.ptContainer
                }
            >
                <Box
                    ref={selectContainer}
                    sx={{
                        border: props.border || '1px solid #E3E3E3',
                        borderRadius: props.borderRadius || 2,
                        backgroundColor: props.backgroundColor,
                        px: 2,
                        width: props.width,
                        pt: props.pt == undefined ? 3 : props.pt,
                        pb: props.pb !== undefined ? props.pb : 1,
                        mt: props.mt != undefined ? props.mt : 1,
                        cursor: props.disabled ? 'not-allowed' : 'pointer',
                    }}
                    onClick={handleSelectClick}
                    display="flex"
                    justifyContent="space-between"
                >
                    <Box height={24} pt={0.5}>
                        <props.icon
                            size={props.renderIconSize}
                            color={props.renderColor}
                        />
                    </Box>
                    <Box>
                        <Typography
                            aria-label="more"
                            id="summary-button"
                            variant="subtitle2"
                            color={props.renderColor}
                            aria-controls={openSelect ? 'long-menu' : undefined}
                            aria-expanded={openSelect ? 'true' : undefined}
                            aria-haspopup="true"
                            sx={{
                                pl: 2,
                                mt: props.textMt || 1.5,
                                fontWeight: 400,
                                fontSize: props.fontSize || 16,
                                textAlign: 'center',
                            }}
                        >
                            {props.options.filter(
                                (o) => o.id == props.selectedValue
                            )[0]
                                ? props.options.filter(
                                      (o) => o.id == props.selectedValue
                                  )[0].displayName
                                : ''}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            lineHeight: '20px',
                            transform: `rotate(${openSelect ? 180 : 0}deg)`,
                            '& path': {
                                stroke: props.renderColor || '',
                            },
                        }}
                        component="span"
                        ml={2}
                        pt={props.iconPt !== undefined ? props.iconPt : 1}
                        mr={2}
                        mt={props.iconMt}
                    >
                        <InputDownArrow />
                    </Box>
                </Box>

                <Menu
                    id="summary-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                        py: 0,
                    }}
                    anchorEl={selectEl}
                    open={openSelect}
                    onClose={handleSelectClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            padding: '0px 6px',
                            width:
                                Math.max(
                                    //@ts-ignore
                                    selectContainer.current?.offsetWidth,
                                    180
                                ) || '28ch',
                            marginTop: '10px',
                        },
                    }}
                >
                    {props.options
                        .filter((o) => o.status == 'ACTIVE')
                        .map((o: OptionDetails) => (
                            <MenuItem
                                onClick={() => handleChange(o.id)}
                                key={o.id}
                                value={o.id}
                                sx={{
                                    backgroundColor:
                                        o.id == props.selectedValue
                                            ? '#ebebf6'
                                            : '#fff',
                                    borderRadius: 1,
                                }}
                                disabled={
                                    props.isOptionDisabled
                                        ? props.isOptionDisabled(o)
                                        : false
                                }
                            >
                                <CustomTooltip
                                    title={
                                        <Box
                                            p={4}
                                            sx={{
                                                backgroundColor: '#fff',
                                                color: '#333',
                                                borderRadius: '8px',
                                                border: 'none',
                                            }}
                                        >
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                mb={1}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        color: '#0F0F3E',
                                                        px: 1,
                                                    }}
                                                >
                                                    {o.displayName}
                                                </Typography>
                                                {props.icon && (
                                                    <Box
                                                        sx={{
                                                            height: '24px',
                                                            backgroundColor:
                                                                '#F4F4F4',
                                                            borderRadius: '4px',
                                                            p: 0.5,
                                                            px: 2,
                                                        }}
                                                        display={'flex'}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: '17px',
                                                                height: '24px',
                                                                py: 0.5,
                                                            }}
                                                        >
                                                            <props.icon />
                                                        </Box>
                                                        Open AI
                                                    </Box>
                                                )}
                                            </Box>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: '#333',
                                                    mb: 2,
                                                    py: 2,
                                                }}
                                            >
                                                {o.description}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    backgroundColor: '#F0F0F5',
                                                    color: '#0F0F3E',
                                                    borderRadius: '8px',
                                                    display: 'inline-block',
                                                    padding: '4px 8px',
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    width: '100%',
                                                }}
                                            >
                                                {o.additionalInfo}
                                            </Box>
                                        </Box>
                                    }
                                    placement="left"
                                    arrow
                                >
                                    <Box width={'100%'} display={'flex'}>
                                        <Box
                                            component={'span'}
                                            mt={1}
                                            sx={{ pr: 2 }}
                                        >
                                            {' '}
                                            <props.icon size={16} />
                                        </Box>{' '}
                                        <Typography>{o.displayName}</Typography>
                                    </Box>
                                </CustomTooltip>
                            </MenuItem>
                        ))}
                    {props.options
                        .filter((o) => o.status == 'DISABLED')
                        .map((o: OptionDetails) => (
                            <HtmlTooltip
                                arrow
                                placement={'left'}
                                title={
                                    <Box py={2} textAlign={'center'} px={1}>
                                        <Typography variant="h5">
                                            {'Feature Unavailable'}
                                        </Typography>
                                        <Typography mt={2}>
                                            {`This feature is not included in your current plan. Upgrade to access this functionality.`}
                                        </Typography>
                                    </Box>
                                }
                            >
                                <span>
                                    <MenuItem
                                        onClick={() => handleChange(o.id)}
                                        key={o.id}
                                        value={o.id}
                                        sx={{
                                            backgroundColor:
                                                o.id == props.selectedValue
                                                    ? '#ebebf6'
                                                    : '#fff',
                                            borderRadius: 1,
                                        }}
                                        disabled={true}
                                    >
                                        <Box width={'100%'} display={'flex'}>
                                            <Box
                                                component={'span'}
                                                mt={1}
                                                sx={{ pr: 2 }}
                                            >
                                                {' '}
                                                <props.icon size={16} />
                                            </Box>{' '}
                                            <Typography>
                                                {o.displayName}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                </span>
                            </HtmlTooltip>
                        ))}
                </Menu>
            </Box>
        </div>
    );
}
