import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryparamServices } from '../../services/query-param.service';
import { CookieService } from '../../services/cookie.service';

export const REF_CODE_KEY = 'REF_CODE';

export default function ReferralTracker() {
    const { pathname } = useLocation();
    const { addQueryParams, getQueryParam, removeQueryParams } =
        useQueryparamServices();
    let cookieService = new CookieService();
    useEffect(() => {
        if (getQueryParam('ref')) {
            let refCode = getQueryParam('ref');
            if (refCode) {
                cookieService.set(REF_CODE_KEY, refCode);
            } else {
            }
            removeQueryParams('ref');
        }
    }, [getQueryParam('ref')]);

    return null;
}
