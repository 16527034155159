interface IProps {
    height?: number;
    width?: number;
}

export const YouTube = (props: IProps) => (
    <svg
        width={props.width || '24'}
        height={props.height || '18'}
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_152_119231)">
            <path
                d="M23.47 3.19453C23.3323 2.6858 23.0638 2.22201 22.6911 1.84934C22.3185 1.47667 21.8547 1.20812 21.346 1.07044C19.4835 0.5625 11.9878 0.5625 11.9878 0.5625C11.9878 0.5625 4.49177 0.577875 2.62933 1.08581C2.1206 1.2235 1.65681 1.49206 1.28415 1.86475C0.911497 2.23744 0.642976 2.70125 0.505331 3.21C-0.0580132 6.51919 -0.276545 11.5616 0.520799 14.7384C0.658459 15.2472 0.926986 15.711 1.29964 16.0836C1.6723 16.4563 2.13608 16.7248 2.6448 16.8625C4.50724 17.3705 12.0031 17.3705 12.0031 17.3705C12.0031 17.3705 19.4989 17.3705 21.3612 16.8625C21.87 16.7249 22.3338 16.4563 22.7064 16.0836C23.0791 15.711 23.3477 15.2472 23.4853 14.7384C24.0795 11.4246 24.2626 6.38522 23.47 3.19453Z"
                fill="#FF0000"
            />
            <path
                d="M9.60156 12.5686L15.8198 8.96692L9.60156 5.36523V12.5686Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_152_119231">
                <rect
                    width="24"
                    height="16.875"
                    fill="white"
                    transform="translate(0 0.5625)"
                />
            </clipPath>
        </defs>
    </svg>
);
