import {
    Box,
    Button,
    Checkbox,
    Collapse,
    Dialog,
    FormControlLabel,
    FormGroup,
    Grid,
    Menu,
    Tooltip,
    Switch,
    Typography,
    styled,
    IconButton,
    IconButtonProps,
} from '@mui/material';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { BorderedBox } from '../../../components/core/box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Filter } from '../../../components/icons/filter';
import { FilterChecked } from '../../../components/icons/filter-checked';
import { FilterUnchecked } from '../../../components/icons/filter-unchecked';
import { useDocumentService } from '../../../services/document.service';
import { UtilsService } from '../../../services/utils.service';
import { FileFilters, IFilesCount } from '../../../services/interfaces';
import {
    DateRange,
    DateRangePicker,
} from '../../../components/core/input/date-range-picker';
import { Calendar } from '../../../components/icons/calendar';
import { DocumentInsightsContext } from '../../../providers/document-provider';
import { FilterIntermediate } from '../../../components/icons/filter-intermediate';
import { usePermissions } from '../../../providers/permissions-provider';
import { FeatureName, SubFeatureName } from '../../../services/interfaces';
import { Authorize } from '../../../components/authorize';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
interface IFilterOption {
    title: string;
    key: string;
    component?: ReactElement<any, any>;
    childrens?: IFilterOption[];
}
interface IFilter {
    title: string;
    order: number;
    key: string;
    multiple: boolean;
    options: IFilterOption[];
}

interface IFilterChangeData {
    key: string;
    data: any;
}

interface IDatepickerProps {
    onChange: (data: IFilterChangeData) => void;
    appliedFilters: FileFilters;
}

const GetDatePicker = (props: IDatepickerProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dates, setDates] = useState<DateRange | null>(null);

    useEffect(() => {
        if (props.appliedFilters.startDate && props.appliedFilters.endDate) {
            setDates({
                startDate: new Date(props.appliedFilters.startDate),
                endDate: new Date(props.appliedFilters.endDate),
            });
        }
    }, [props.appliedFilters]);

    const handleClose = () => {
        setIsOpen(false);
        props.onChange({ key: 'date_range', data: dates });
    };
    return (
        <Box>
            <BorderedBox
                onClick={() => setIsOpen(true)}
                sx={{ py: 2, px: 2, mt: 3, cursor: 'pointer' }}
            >
                <Grid justifyContent="space-between" container>
                    <Grid sx={{ verticalAlign: 'middle' }} item>
                        {dates && dates.startDate && dates?.endDate ? (
                            <>
                                {UtilsService.formatDateWithoutYearWithoutOffset(
                                    dates.startDate
                                )}
                                {' - '}

                                {UtilsService.formatDateWithoutYearWithoutOffset(
                                    dates.endDate
                                )}
                            </>
                        ) : (
                            <>Choose Date</>
                        )}
                    </Grid>

                    <Tooltip
                        arrow
                        placement="top"
                        title={'Select Custom Date Range'}
                    >
                        <Grid sx={{ verticalAlign: 'middle' }} item>
                            <Calendar color="#29319B" />
                        </Grid>
                    </Tooltip>
                </Grid>
            </BorderedBox>
            <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
                <Box
                    sx={{
                        backgroundColor: '#ccc',
                        '& .MuiPaper-elevation': {
                            boxShadow: 'none',
                        },
                        '& .MuiBox > div > div': {
                            color: '#fff',
                        },
                    }}
                >
                    <DateRangePicker
                        open={true}
                        toggle={() => {}}
                        initialDateRange={
                            dates && dates.startDate && dates?.endDate
                                ? {
                                      startDate: new Date(dates.startDate),
                                      endDate: new Date(dates.endDate),
                                  }
                                : {}
                        }
                        minDate={new Date('2020-01-01')}
                        maxDate={new Date()}
                        onChange={(dateRange: DateRange) => {
                            setDates(dateRange);
                        }}
                        wrapperClassName="date-range-picker"
                        closeOnClickOutside={true}
                    />
                </Box>
                <Button
                    onClick={() => {
                        handleClose();
                    }}
                >
                    Confirm
                </Button>
            </Dialog>
        </Box>
    );
};

const STATIC_DATE_FILTERS: IFilterOption[] = [
    {
        title: 'Last 7 days',
        key: UtilsService.formatDateForNetworkCall(
            UtilsService.getDateDaysAgo(7)
        ),
    },
    {
        title: 'Last 30 days',
        key: UtilsService.formatDateForNetworkCall(
            UtilsService.getDateDaysAgo(30)
        ),
    },
    {
        title: 'Last 90 days',
        key: UtilsService.formatDateForNetworkCall(
            UtilsService.getDateDaysAgo(90)
        ),
    },
];

const getStaticFilters = (
    onChange: (data: IFilterChangeData) => void,
    appliedFilters: FileFilters
): IFilter[] => [
    {
        title: 'Uploaded date',
        key: 'date_range',
        order: 4,
        multiple: false,
        options: [
            ...STATIC_DATE_FILTERS,
            {
                title: 'Custom',
                key: 'date_range_custom',
                component: (
                    <GetDatePicker
                        onChange={(a: any) => {
                            onChange(a);
                        }}
                        appliedFilters={appliedFilters}
                    />
                ),
            },
        ],
    },
];

interface IProps {
    appliedFilters: FileFilters;
    setAppliedFilters: (value?: FileFilters) => void;
}

interface ICheckBoxProps {
    option: IFilterOption;
    toggleFilter: (category: string, value: string, data?: any) => void;
    isChecked: (category: string, value: string) => boolean;
    filter: IFilter;
}

const DriveFilterCheckbox = (props: ICheckBoxProps) => {
    const { option, toggleFilter, filter, isChecked } = props;
    const [expanded, setExpanded] = React.useState(false);
    const [expansionChecked, setExpansionChecked] = React.useState(false);

    useEffect(() => {
        if (option.childrens && !expansionChecked) {
            for (let cOption of option.childrens) {
                if (isChecked(filter.key, cOption.key)) {
                    setExpanded(true);
                }
            }
        }
        setExpansionChecked(true);
    }, [option]);

    const handleParentClick = () => {
        if (option.childrens) {
            let checkedCount = option.childrens.filter((cOption) =>
                isChecked(filter.key, cOption.key)
            ).length;

            for (let cOption of option.childrens) {
                if (
                    checkedCount == option.childrens.length ||
                    !isChecked(filter.key, cOption.key)
                ) {
                    toggleFilter(filter.key, cOption.key);
                }
            }
        }
    };

    const getParentIcon = (): JSX.Element => {
        if (!option.childrens) {
            return FilterUnchecked;
        }
        if (option.childrens) {
            let checkedCount = option.childrens.filter((cOption) =>
                isChecked(filter.key, cOption.key)
            ).length;
            if (checkedCount == option.childrens.length) {
                return FilterChecked;
            } else if (checkedCount == 0) {
                return FilterUnchecked;
            } else {
                return FilterIntermediate;
            }
        }
        return FilterUnchecked;
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
        <Box>
            <FormControlLabel
                sx={{
                    fontSize: 12,
                }}
                control={
                    <Checkbox
                        size="small"
                        icon={getParentIcon()}
                        onClick={() =>
                            !option.childrens
                                ? toggleFilter(filter.key, option.key)
                                : handleParentClick()
                        }
                        checked={
                            option.childrens
                                ? false
                                : isChecked(filter.key, option.key)
                        }
                        checkedIcon={FilterChecked}
                    />
                }
                label={
                    <Typography variant="caption" color="textSecondary">
                        {option.title}
                        {props.option.childrens && (
                            <ExpandMore
                                sx={{ ml: 1 }}
                                expand={expanded}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        )}
                    </Typography>
                }
            />
            {isChecked(filter.key, option.key) && option.component}
            {option.childrens && (
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box ml={6.5}>
                        {option.childrens?.map((cOption: IFilterOption) => (
                            <Box>
                                <FormControlLabel
                                    sx={{
                                        fontSize: 12,
                                    }}
                                    control={
                                        <Checkbox
                                            size="small"
                                            icon={FilterUnchecked}
                                            onClick={() =>
                                                toggleFilter(
                                                    filter.key,
                                                    cOption.key
                                                )
                                            }
                                            checked={isChecked(
                                                filter.key,
                                                cOption.key
                                            )}
                                            checkedIcon={FilterChecked}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            {cOption.title}
                                        </Typography>
                                    }
                                />
                                {isChecked(filter.key, cOption.key) &&
                                    cOption.component}
                            </Box>
                        ))}
                    </Box>
                </Collapse>
            )}
        </Box>
    );
};

const DRIVE_FILTERS_SECTIONS_AUTHORIZATION = [
    {
        id: 'dataSourceIds',
        featureName: FeatureName.DocumentInsights,
        subFeatureName: SubFeatureName.Integrations,
    },
];

interface IAuthorizeDriveFiltersSection {
    children: React.ReactNode;
    id: string;
}

const AuthorizeDriveFiltersSection = (props: IAuthorizeDriveFiltersSection) => {
    const { isSubFeatureAuthorized } = usePermissions();
    const sectionAuthDetails = DRIVE_FILTERS_SECTIONS_AUTHORIZATION.filter(
        (s) => s.id == props.id
    )[0];
    return sectionAuthDetails ? (
        <Authorize
            featureName={sectionAuthDetails.featureName}
            subFeatureName={sectionAuthDetails.subFeatureName}
        >
            {!isSubFeatureAuthorized(
                sectionAuthDetails.featureName,
                sectionAuthDetails.subFeatureName
            ) ? (
                <Box
                    sx={{
                        cursor: 'not-allowed',
                        opacity: 0.5,
                        pointerEvents: 'none',
                    }}
                >
                    {props.children}
                </Box>
            ) : (
                props.children
            )}
        </Authorize>
    ) : (
        <Box>{props.children}</Box>
    );
};
export const DriveFilters = (props: IProps) => {
    const [filterMenuEl, setFilterMenuEl] = useState<null | HTMLElement>(null);
    const { isSubFeatureAuthorized } = usePermissions();

    const openFilterMenu = Boolean(filterMenuEl);
    const [filteredFilesCount, setFilteredFilesCount] =
        useState<IFilesCount | null>(null);
    const { fetchFilteredFileCount } = useDocumentService();
    const documentContext = useContext(DocumentInsightsContext);
    const [filtersCount, setFiltersCount] = React.useState(0);
    const [filters, setFilters] = useState<IFilter[]>([]);
    const [appliedFilters, setAppliedFilters] = useState<FileFilters>({
        ...props.appliedFilters,
    });
    const [expandedMenuItems, setExpandedMenuItems] = useState<string[]>([]);
    const [customFilters, setCustomFilters] = useState<string[]>([]);

    const toggleMenu = (menuKey: string) => {
        const state = isMenuExpanded(menuKey);
        if (state) {
            setExpandedMenuItems([
                ...new Set([
                    ...expandedMenuItems.filter((key) => key != menuKey),
                ]),
            ]);
        } else {
            setExpandedMenuItems([...new Set([...expandedMenuItems, menuKey])]);
        }
    };

    const applyFilterClick = () => {
        props.setAppliedFilters(appliedFilters);
        handleFilterMenuClose();
    };
    const toggleFilter = (category: string, value: string, data?: any) => {
        let _appliedFilters = appliedFilters;
        if (value.indexOf('custom') != -1) {
            if (customFilters.includes(value)) {
                setCustomFilters([
                    ...customFilters.filter((v: string) => v != value),
                ]);
            } else {
                setCustomFilters([...customFilters, value]);
            }
            if (value.indexOf('date_range') != -1) {
                _appliedFilters.startDate = undefined;
                _appliedFilters.endDate = undefined;
                if (data) {
                    _appliedFilters.startDate =
                        UtilsService.formatDateForNetworkCallWithoutOffset(
                            data.data.startDate
                        );

                    _appliedFilters.endDate =
                        UtilsService.formatDateForNetworkCallWithoutOffset(
                            data.data.endDate
                        );
                }
            }
            setAppliedFilters({ ..._appliedFilters });
            return;
        }
        switch (category) {
            case 'file_type':
                {
                    _appliedFilters.fileTypes = _appliedFilters.fileTypes || [];
                    if (_appliedFilters.fileTypes.includes(value))
                        _appliedFilters.fileTypes =
                            _appliedFilters.fileTypes.filter(
                                (v: string) => v != value
                            );
                    else {
                        _appliedFilters.fileTypes = [
                            ..._appliedFilters.fileTypes,
                            value,
                        ];
                    }
                }
                break;
            case 'tags':
                {
                    _appliedFilters.userTagIds =
                        _appliedFilters.userTagIds || [];
                    if (_appliedFilters.userTagIds.includes(value))
                        _appliedFilters.userTagIds =
                            _appliedFilters.userTagIds.filter(
                                (v: string) => v != value
                            );
                    else {
                        _appliedFilters.userTagIds = [
                            ..._appliedFilters.userTagIds,
                            value,
                        ];
                    }
                }
                break;
            case 'dataSourceIds':
                {
                    _appliedFilters.dataSourceIds =
                        _appliedFilters.dataSourceIds || [];
                    if (_appliedFilters.dataSourceIds.includes(value))
                        _appliedFilters.dataSourceIds =
                            _appliedFilters.dataSourceIds.filter(
                                (v: string) => v != value
                            );
                    else {
                        _appliedFilters.dataSourceIds = [
                            ..._appliedFilters.dataSourceIds,
                            value,
                        ];
                    }
                }
                break;
            case 'date_range':
                setCustomFilters([
                    ...customFilters.filter(
                        (v: string) => v != 'date_range_custom'
                    ),
                ]);
                if (_appliedFilters.startDate != value) {
                    _appliedFilters.startDate = value;
                    _appliedFilters.endDate =
                        UtilsService.formatDateForNetworkCall(new Date());
                } else {
                    _appliedFilters.startDate = undefined;
                    _appliedFilters.endDate = undefined;
                }
                break;
            case 'shared_files':
                {
                    _appliedFilters.includeSharedFiles = !!data;
                }
                break;
        }
        setAppliedFilters({ ..._appliedFilters });
    };

    const isChecked = (category: string, value: string): boolean => {
        if (value.indexOf('custom') != -1) {
            return customFilters.includes(value);
        }
        let _appliedFilters = appliedFilters;
        switch (category) {
            case 'file_type':
                return !!_appliedFilters.fileTypes?.includes(value);
            case 'tags':
                return !!_appliedFilters.userTagIds?.includes(value);
            case 'dataSourceIds':
                return !!_appliedFilters.dataSourceIds?.includes(value);
            case 'date_range':
                return !!(_appliedFilters.startDate == value);
            case 'shared_files':
                return !!_appliedFilters.includeSharedFiles;
        }
        return false;
    };

    const isMenuExpanded = (menuKey: string) => {
        return expandedMenuItems.includes(menuKey);
    };
    const handleFilterMenuClose = () => {
        setFilterMenuEl(null);
    };
    const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAppliedFilters({ ...props.appliedFilters });
        setFilterMenuEl(event.currentTarget);
    };

    const onCustomFilterValueChange = (data: IFilterChangeData) => {
        toggleFilter(data.key, 'date_range_custom', data);
    };

    const selectAllClick = () => {
        filters.forEach((v) => {
            if (v.key !== 'date_range') {
                v.options.forEach((v1) => {
                    if (v1.childrens) {
                        v1.childrens.forEach((v2) => {
                            if (!isChecked(v.key, v2.key)) {
                                toggleFilter(v.key, v2.key);
                            }
                        });
                    } else {
                        if (!isChecked(v.key, v1.key)) {
                            toggleFilter(v.key, v1.key);
                        }
                    }
                });
            } else {
                v.options.forEach((v1) => {
                    if (isChecked(v.key, v1.key)) {
                        toggleFilter(v.key, v1.key);
                    }
                });
            }
        });
    };

    const refreshFilters = async () => {
        if (!documentContext?.filters) {
            return;
        }
        let fileTypeFilters: IFilter = {
            title: 'File Type',
            order: 1,
            key: 'file_type',
            multiple: true,
            options: [],
        };
        for (let v of documentContext.filters.fileTypes) {
            fileTypeFilters.options.push({
                title: UtilsService.firstCharUppercase(v),
                key: v,
            });
        }
        let fileTagFilters: IFilter = {
            title: 'Tags',
            key: 'tags',
            order: 2,
            multiple: true,
            options: [],
        };
        for (let v of documentContext.filters.tags) {
            fileTagFilters.options.push({
                title: `${v.title} (${v.filesCount})`,
                key: v.userTagId,
            });
        }

        let fileIntegrationFilters: IFilter = {
            title: 'Integration',
            key: 'dataSourceIds',
            order: 3,
            multiple: true,
            options: [],
        };
        for (let v of documentContext.filters.integrationTypes) {
            if (
                !fileIntegrationFilters.options
                    .map((o) => o.key)
                    .includes(v.integrationType)
            ) {
                fileIntegrationFilters.options.push({
                    title: `${
                        v.dataSourceName != 'PHOTON'
                            ? v.integrationType
                            : `${v.integrationType} (${v.filesCount})`
                    }`,
                    key: v.integrationType,
                });
            }
            // debugger
            for (let i = 0; i < fileIntegrationFilters.options.length; i++) {
                if (
                    fileIntegrationFilters.options[i].key ==
                        v.integrationType &&
                    v.dataSourceName != 'PHOTON'
                ) {
                    if (!fileIntegrationFilters.options[i].childrens) {
                        fileIntegrationFilters.options[i].childrens = [];
                    }

                    fileIntegrationFilters.options[i].childrens?.push({
                        title: `${v.dataSourceName} (${v.filesCount})`,
                        key: v.dataSourceId,
                    });
                }
            }
        }

        setFilters([
            ...getStaticFilters(
                onCustomFilterValueChange.bind(this),
                props.appliedFilters
            ),
            fileTypeFilters,
            fileTagFilters,
            fileIntegrationFilters,
        ]);
    };

    useEffect(() => {
        if (documentContext?.filters) {
            refreshFilters();
        }
    }, [props.appliedFilters, documentContext?.filters]);

    const refreshFilesCount = async () => {
        if (appliedFilters) {
            const count = await fetchFilteredFileCount(appliedFilters);
            setFilteredFilesCount(count);
        }
    };

    useEffect(() => {
        refreshFilesCount();
    }, [appliedFilters]);

    const handleFiltersUpdate = (updatedFilters: FileFilters) => {
        setAppliedFilters({ ...updatedFilters });
        let count = 0;
        if (updatedFilters.startDate) {
            count++;
            checkDateCustomFilter(updatedFilters.startDate);
        } else {
            setCustomFilters([
                ...customFilters.filter(
                    (v: string) => v != 'date_range_custom'
                ),
            ]);
        }
        if (updatedFilters.fileTypes) {
            count = count + updatedFilters.fileTypes.length;
        }
        if (updatedFilters.dataSourceIds) {
            count = count + updatedFilters.dataSourceIds.length;
        }
        if (updatedFilters.userTagIds) {
            count = count + updatedFilters.userTagIds.length;
        }
        if (!updatedFilters.includeSharedFiles) {
            count = count + 1;
        }
        setFiltersCount(count);
    };

    const checkDateCustomFilter = (startDate: string) => {
        let dateFilter = STATIC_DATE_FILTERS.filter(
            (f: IFilterOption) => f.key == startDate
        )[0];
        if (!dateFilter) {
            setCustomFilters([...customFilters, 'date_range_custom']);
        }
    };

    useEffect(() => {
        handleFiltersUpdate(props.appliedFilters);
    }, [props.appliedFilters]);

    const resetFiltersClick = () => {
        props.setAppliedFilters();
        handleFilterMenuClose();
    };

    return (
        <Box>
            <Button
                startIcon={<Filter filled={filtersCount > 0} />}
                onClick={handleFilterMenuClick}
                variant="contained"
                disabled={
                    !isSubFeatureAuthorized(
                        FeatureName.DocumentInsights,
                        SubFeatureName.ElasticSearch
                    )
                }
                id="doc-filter-button"
                sx={{
                    mr: 2,
                    pr: 2,
                    pl: 3,
                    fontSize: 12,
                    fontWeight: 600,
                    height: 43,
                    marginRight: 0,
                    minWidth: 43,
                    borderRadius: 1,
                    backgroundColor:
                        filtersCount > 0 || openFilterMenu
                            ? '#EBECF6'
                            : '#FFFFFF',
                    color: '#29319B',
                    '&:hover': {
                        backgroundColor: '#EBECF6',
                        color: '#29319B',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#fff',
                        color: '#29319B',
                    },
                    '& .MuiButton-startIcon': {
                        marginRight: 0,
                    },
                    '& svg': {
                        transform: 'scale(1.2)',
                    },
                }}
            >
                {filtersCount > 0 && (
                    <Box
                        sx={{
                            px: 1.75,
                            color: '#fff',
                            height: 21,
                            borderRadius: '50%',
                            ml: 2,
                            backgroundColor: '#29319B',
                        }}
                    >
                        {' '}
                        {filtersCount}
                    </Box>
                )}
            </Button>

            <Menu
                id="more-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    py: 0,
                }}
                anchorEl={filterMenuEl}
                open={openFilterMenu}
                onClose={handleFilterMenuClose}
                PaperProps={{
                    style: {
                        width: '790px',
                        border: '1px solid #D7D9EC',
                        borderRadius: 8,
                        marginTop: '10px',
                        backgroundColor: '#FEFEFE',
                    },
                    elevation: 0,
                }}
            >
                <Box>
                    <Box>
                        <Box sx={{ borderBottom: '1px solid #D7D9EC' }}>
                            <Box
                                display="flex"
                                ml={2}
                                mr={4}
                                pb={2}
                                justifyContent="space-between"
                            >
                                <Typography pt={2} ml={2} variant="h6">
                                    Filters
                                </Typography>
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            onChange={(e) => {
                                                toggleFilter(
                                                    'shared_files',
                                                    'null',
                                                    e.target.checked
                                                );
                                            }}
                                            checked={isChecked(
                                                'shared_files',
                                                'null'
                                            )}
                                        />
                                    }
                                    label={
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                            }}
                                        >
                                            Shared Files
                                        </Typography>
                                    }
                                />
                            </Box>
                        </Box>
                        <Box
                            mx={2}
                            sx={{
                                maxHeight: '600px',
                                display: 'flex',
                                pl: 2,
                                overflowY: 'auto',
                            }}
                        >
                            {filters
                                .sort((a: IFilter, b: IFilter) =>
                                    a.order < b.order ? -1 : 1
                                )
                                .map((filter: IFilter, index: number) => (
                                    <Box
                                        sx={{
                                            minWidth: 190,
                                        }}
                                    >
                                        <Box
                                            sx={
                                                index > 0
                                                    ? {
                                                          borderLeft:
                                                              '1px solid #ccc',
                                                          pl: 3,
                                                          height: '305px',
                                                      }
                                                    : {}
                                            }
                                            mt={3}
                                        >
                                            <Box
                                                onClick={() =>
                                                    toggleMenu(filter.key)
                                                }
                                                display="flex"
                                                sx={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <Tooltip title={filter.title}>
                                                    <Typography
                                                        mt={0.5}
                                                        variant="subtitle2"
                                                    >
                                                        {filter.title}
                                                    </Typography>
                                                </Tooltip>
                                            </Box>
                                            <Box
                                                pt={2}
                                                sx={{
                                                    maxHeight: '285px',
                                                    pr: 3,
                                                    overflowY: 'auto',
                                                }}
                                                px={1}
                                            >
                                                <FormGroup>
                                                    {filter.options.map(
                                                        (
                                                            option: IFilterOption
                                                        ) => (
                                                            <AuthorizeDriveFiltersSection
                                                                id={filter.key}
                                                            >
                                                                <DriveFilterCheckbox
                                                                    isChecked={
                                                                        isChecked
                                                                    }
                                                                    toggleFilter={
                                                                        toggleFilter
                                                                    }
                                                                    filter={
                                                                        filter
                                                                    }
                                                                    option={
                                                                        option
                                                                    }
                                                                />
                                                            </AuthorizeDriveFiltersSection>
                                                        )
                                                    )}
                                                </FormGroup>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                        </Box>
                    </Box>
                    <Box pt={3}>
                        <Box
                            pt={3}
                            pb={1}
                            pr={3}
                            sx={{ borderTop: '1px solid #D7D9EC' }}
                            justifyContent="space-between"
                            display="flex"
                        >
                            <Box display="flex" pl={4}>
                                <Button
                                    variant="contained"
                                    onClick={selectAllClick}
                                    sx={{
                                        float: 'right',
                                        fontSize: 12,
                                        fontWeight: 600,
                                        height: 30,
                                        borderRadius: 1,
                                        backgroundColor: '#EBECF6',
                                        color: '#29319B',
                                        '&:hover': {
                                            backgroundColor: '#d2d5f3',
                                        },
                                    }}
                                >
                                    Select All
                                </Button>
                                {filteredFilesCount && (
                                    <Typography
                                        mt={1.5}
                                        ml={4}
                                        color="#393939"
                                        fontSize={12}
                                    >
                                        {filteredFilesCount.filteredCount}/
                                        {filteredFilesCount.totalCount} files
                                        included
                                    </Typography>
                                )}
                            </Box>
                            <Box justifyContent="end" display="flex">
                                <Button
                                    variant="contained"
                                    onClick={applyFilterClick}
                                    sx={{
                                        float: 'right',
                                        fontSize: 12,
                                        fontWeight: 600,
                                        height: 30,
                                        borderRadius: 1,
                                    }}
                                >
                                    Apply Filters
                                </Button>
                                <Button
                                    onClick={resetFiltersClick}
                                    variant="text"
                                    sx={{
                                        float: 'right',
                                        fontSize: 12,
                                        fontWeight: 600,
                                        height: 30,
                                        ml: 2,
                                        borderRadius: 1,
                                    }}
                                >
                                    Reset all
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Menu>
        </Box>
    );
};
