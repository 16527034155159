import { useContext, useEffect, useState } from 'react';
import {
    Box,
    FormControlLabel,
    FormGroup,
    Typography,
    Checkbox,
    Divider,
    Button,
} from '@mui/material';
import { AppContext } from '../../../providers/app-provider';
import { Add } from '@mui/icons-material';
import { ErrorIcon } from '../../../components/icons/error';
import { useSnackbar } from 'notistack';
import { UserTopic, UserCluster } from '../../../services/interfaces';
import cluster from 'cluster';
import { UpsertClusterRequest } from '../../../services/interfaces';
import { useClusterService } from '../../../services/cluster.service';

interface IProps {
    query: string;
    handleClose: () => void;
}

const RemoveWatchlist = (props: IProps) => {
    const appContext = useContext(AppContext);
    const [clusterToUpdate, setClusterToUpdate] = useState<UserCluster>();

    const { upsertCluster, reFetchUserClusters } = useClusterService();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        let cluster = appContext?.clusters?.find((cluster) =>
            cluster.userTopicSubscriptions.find(
                (topic) =>
                    topic.subscriptionName.toLowerCase() ==
                    props.query.toLowerCase()
            )
        );
        if (cluster) {
            setClusterToUpdate(cluster);
        }
    }, []);

    const handleRemove = async () => {
        let updatedTopics = clusterToUpdate?.userTopicSubscriptions.filter(
            (topic) => topic.subscriptionName.toLowerCase() != props.query
        );
        if (clusterToUpdate && updatedTopics) {
            const req: UpsertClusterRequest = {
                dashboardClusterId: clusterToUpdate.dashboardClusterId,
                dashboardClusterName: clusterToUpdate.dashboardClusterName,
                topicSubscriptionIds: updatedTopics.map(
                    (topic) => topic.topicSubscriptionId
                ),
            };
            let res = await upsertCluster(req);
            if (res.success) {
                await reFetchUserClusters();
                props.handleClose();
                enqueueSnackbar(
                    `Removed “${props.query}” from watchlist “${clusterToUpdate?.dashboardClusterName}” successfully!`
                );
            }
        }
    };

    const handleCancel = () => {
        props.handleClose();
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                {ErrorIcon}
                <Typography variant="h3" my={4}>
                    Remove “<span>{props.query}</span>”
                </Typography>
                <Typography fontSize="14px" align="center">
                    Are you sure you want to remove “{props.query}” from "
                    {clusterToUpdate?.dashboardClusterName}"?
                </Typography>
            </Box>
            <Box display="flex" justifyContent="right" mt={10}>
                <Button
                    sx={{ color: '#4B4B4C', mr: 3 }}
                    onClick={() => {
                        handleCancel();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => handleRemove()}
                    color="error"
                >
                    Remove
                </Button>
            </Box>
        </Box>
    );
};

export default RemoveWatchlist;
