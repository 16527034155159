import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
    ChatbotSourcesCountResponseData,
    DataSourceConfig,
    DataSourceConfigCustomData,
    FileFiltersListResponseData,
    Tag,
} from '../../../../services/interfaces';
import { useDocumentService } from '../../../../services/document.service';
import MultiSelect from '../../../../components/core/input/multi-select';
import { filter } from 'lodash';
import { useChatWidgetService } from '../../../../services/chat-widget.service';

interface IProps {
    datasourceConfig: DataSourceConfigCustomData;
    contentWidth?: number;
    updateFilters: (newFilters: {
        fileTypes: string[];
        userTagIds: string[];
    }) => void;
}

export const ChatbotFilters = (props: IProps) => {
    const { fetchFilesFilters } = useDocumentService();
    const { getSourcesCount } = useChatWidgetService();
    const [filtersUpdated, setFiltersUpdated] = useState(false);
    const [dataSourcesCount, setDataSourcesCount] =
        useState<ChatbotSourcesCountResponseData | null>(null);
    const [selectedFilters, setSelectedFilters] = useState<{
        fileTypes: string[];
        tags: Tag[];
    }>({ fileTypes: [], tags: [] });
    const [filters, setFilters] = useState<FileFiltersListResponseData | null>(
        null
    );
    const [filtersFetched, setFiltersFetched] = useState(false);
    useEffect(() => {
        if (!filtersFetched) {
            refreshFilters();
        }
    }, [filtersFetched]);

    const refreshFilters = async () => {
        let data = await fetchFilesFilters();
        setFilters(data);
        setSelectedFilters({
            fileTypes: props.datasourceConfig.fileTypes,
            tags: data.tags.filter((t: Tag) =>
                props.datasourceConfig.userTagIds.includes(t.userTagId)
            ),
        });
    };

    useEffect(() => {
        refreshFilesCount();
    }, [selectedFilters, props.datasourceConfig]);

    const refreshFilesCount = async () => {
        let data = await getSourcesCount(
            selectedFilters.fileTypes,
            selectedFilters.tags.map((t: Tag) => t.userTagId)
        );
        setDataSourcesCount(data);
    };

    const onResetClick = () => {
        setSelectedFilters({ fileTypes: [], tags: [] });
        props.updateFilters({
            fileTypes: [],
            userTagIds: [],
        });
    };

    const onApplyClick = () => {
        props.updateFilters({
            fileTypes: selectedFilters.fileTypes,
            userTagIds: selectedFilters.tags.map((t: Tag) => t.userTagId),
        });
        setFiltersUpdated(false);
    };

    return (
        <Box pb={0}>
            {filters && (
                <Box>
                    {dataSourcesCount && (
                        <Box pb={2}>
                            <Typography
                                color="#003"
                                fontSize={14}
                                fontWeight={600}
                            >
                                User Documents
                            </Typography>
                            <Box display="flex">
                                <Typography color="#8D8D8D" variant="caption">
                                    Total files:{' '}
                                    {dataSourcesCount?.totalFileCount}
                                </Typography>
                                <Typography
                                    sx={{
                                        ml: 2,
                                        pl: 2,
                                        borderLeft: '1px solid #ccc',
                                    }}
                                    color="#8D8D8D"
                                    variant="caption"
                                >
                                    {dataSourcesCount?.filtersFileCount}/
                                    {dataSourcesCount?.totalFileCount} Files
                                    included
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    <Grid container pb={2}>
                        <Grid pr={4} item sm={4}>
                            <MultiSelect
                                getOptionId={(v: string) => v}
                                title="File Types"
                                disableSearch
                                fullWidth
                                contentWidth={props.contentWidth}
                                width={props.contentWidth}
                                borderRadius={1}
                                pb={2}
                                pt={2}
                                ptContainer={0}
                                getOptionText={(v: string) => v}
                                onChange={(value: string[]) => {
                                    setSelectedFilters({
                                        ...selectedFilters,
                                        fileTypes: value,
                                    });
                                    setFiltersUpdated(true);
                                }}
                                placeholder="File Types"
                                selectedValue={selectedFilters.fileTypes}
                                options={filters.fileTypes}
                            />
                        </Grid>

                        <Grid pr={4} item sm={4}>
                            <MultiSelect
                                getOptionId={(v: Tag) => v.userTagId}
                                title="File tags"
                                borderRadius={1}
                                fullWidth
                                contentWidth={props.contentWidth}
                                width={props.contentWidth}
                                placeholder="File tags"
                                pb={2}
                                pt={2}
                                ptContainer={0}
                                disableSearch={filters.tags.length < 10}
                                onChange={(value: Tag[]) => {
                                    setSelectedFilters({
                                        ...selectedFilters,
                                        tags: value,
                                    });
                                    setFiltersUpdated(true);
                                }}
                                getOptionText={(v: Tag) =>
                                    `${v.title}(${v.filesCount})`
                                }
                                selectedValue={selectedFilters.tags}
                                options={filters.tags}
                            />
                        </Grid>

                        <Grid item sm={4} pl={8}>
                            <Box display="flex">
                                <Button
                                    fullWidth
                                    disabled={!filtersUpdated}
                                    sx={{ mt: 1, height: 40, width: 25 }}
                                    variant="contained"
                                    onClick={onApplyClick}
                                >
                                    Apply
                                </Button>
                                <Button
                                    fullWidth
                                    sx={{ mt: 1, height: 40, width: 25 }}
                                    variant="text"
                                    onClick={onResetClick}
                                >
                                    Reset
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Box>
    );
};
