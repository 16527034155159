import React from 'react';

export const PageLoader = () => {
    return (
        <div className="spinner">
            <div className="ball"></div>
            <p className="loader-text">LOADING</p>
        </div>
    );
};
