import Cookies from 'js-cookie';
import { IPlatformInterface } from '../interfaces/platform.interface';

export class BrowserPlatformService implements IPlatformInterface {
    getCookie = async (key: string): Promise<string | undefined> => {
        return Cookies.get(key);
    };
    setCookie = async (key: string, value: string): Promise<void> => {
        Cookies.set(key, value);
        return;
    };
}
