// material-ui
import { PaletteMode } from '@mui/material';
import { createTheme } from '@mui/material/styles';

// project import

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode: PaletteMode) => {
    return createTheme({
        palette: {
            mode,
            primary: {
                main: '#29319B',
                light: '#9C9FD0',
                contrastText: '#fff', //button text white instead of black
            },
            secondary: {
                main: '#29319B',
                light: '#F5F5FA',
            },
            error: {
                main: '#D82F44',
                light: '#FEEDE6',
            },
            common: {
                black: '#000',
                white: '#fff',
            },
        },
    });
};

export default Palette;
