import React, { useState } from 'react';
import { EarningsTrend } from '../../../../../services/interfaces';
import {
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Box,
} from '@mui/material';
import MixedChart from '../../../../../components/charts/eps-chart';
import { BorderedBox } from '../../../../../components/core/box';
import SingleSelectTab from '../../../../../components/core/input/single-select-tab';
import { SlopeChart } from '../../../../../components/charts/slope-chart';

interface IProps {
    earningsTrends: EarningsTrend[];
}

export const EPSPlot: React.FC<IProps> = ({ earningsTrends }) => {
    const getPeriodLabel = (period: string) => {
        const currentYear = new Date().getFullYear();
        const currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3);

        let periodLabel = '';

        if (period.endsWith('q')) {
            const quarterOffset = parseInt(period);
            const quarter = ((currentQuarter + quarterOffset - 1) % 4) + 1;
            const year =
                currentYear +
                Math.floor((currentQuarter + quarterOffset - 1) / 4);
            periodLabel = `Q${quarter} ${year}`;
        } else if (period.endsWith('y')) {
            const yearOffset = parseInt(period);
            periodLabel = `${currentYear + yearOffset}`;
        }

        return periodLabel;
    };

    const series = earningsTrends
        .filter((e) => !['+5y', '-5y'].includes(e.period))
        .map((entry) => ({
            name: getPeriodLabel(entry.period),
            data: [
                { x: '90 Days Ago', y: entry.epsTrend.ninetyDaysAgo || 'NA' },
                { x: '60 Days Ago', y: entry.epsTrend.sixtyDaysAgo || 'NA' },
                { x: '30 Days Ago', y: entry.epsTrend.thirtyDaysAgo || 'NA' },
                { x: '7 Days Ago', y: entry.epsTrend.sevenDaysAgo || 'NA' },
                { x: 'Current', y: entry.epsTrend.currentEstimate || 'NA' },
            ],
        }));
    const categories = [
        '90 Days Ago',
        '60 Days Ago',
        '30 Days Ago',
        '7 Days Ago',
        'Current',
    ];
    return (
        <BorderedBox p={3}>
            <Box pb={4} display={'flex'} justifyContent={'space-between'}>
                <Typography variant="h6">EPS Trends</Typography>
            </Box>
            <SlopeChart categories={categories} series={series} />
        </BorderedBox>
    );
};
