import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import { ChangeEventHandler } from 'react';
import { UtilsService } from '../../../services/utils.service';
import { KeyboardKeys } from '../enums/keyboard-keys.enum';

interface Props {
    title?: string;
    placeholder: string;
    type: string;
    pt?: number;
    inputPadding?: string;
    pb?: number;
    disabelAutoComplete?: boolean;
    inputRef?: React.Ref<any> | undefined;
    onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    disabled?: boolean;
    onClick?: Function;
    error?: boolean;
    errorMessage?: string;
    borderRadius?: number | string;
    startAdornment?: any;
    fontSize?: number;
    fontWeight?: number;
    endAdornment?: any;
    backgroundColor?: string;
    background?: string;
    borderColor?: string;
    value?: string;
    mt?: number;
    onEnterPress?: Function;
    onKeyDown?: Function;
    disaledColor?: string;
    noBorder?: boolean;
    noShadow?: boolean;
    multiline?: boolean;
    rows?: number;
    autoFocus?: boolean;
    textAlign?: string;
    containerWidth?: string;
    sx?: SxProps<Theme> | undefined;
    inputId?: string;
}

export const Input = (props: Props) => {
    const keyPress = (
        event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        if (event.key == KeyboardKeys.ENTER && props.onEnterPress) {
            props.onEnterPress(event);
        }
        if (props.onKeyDown) {
            props.onKeyDown(event);
        }
    };
    return (
        <Box
            sx={{
                mt: props.mt !== undefined ? `${props.mt}px` : '10px',
                width: props.containerWidth,
            }}
        >
            {props.title && <Typography align="left">{props.title}</Typography>}
            <FormControl fullWidth variant="outlined">
                <OutlinedInput
                    id={props.inputId ? props.inputId : UtilsService.uuidv4()}
                    type={props.type}
                    onClick={() => {
                        props.onClick && props.onClick();
                    }}
                    autoFocus={props.autoFocus}
                    onKeyDown={keyPress}
                    onChange={props.onChange}
                    multiline={props.multiline}
                    rows={props.rows}
                    value={props.value}
                    autoComplete={props.disabelAutoComplete ? 'false' : 'true'}
                    disabled={props.disabled}
                    inputProps={{
                        ref: props.inputRef,
                    }}
                    error={props.error}
                    sx={{
                        pt: props.pt != undefined ? props.pt : 1,
                        pb: props.pb != undefined ? props.pb : 1,
                        ...props.sx,
                        fontSize: props.fontSize ? props.fontSize : 16,
                        fontWeight: props.fontWeight ? props.fontWeight : 400,
                        borderRadius: props.borderRadius,
                        backgroundColor: props.backgroundColor
                            ? props.backgroundColor
                            : '',
                        background: props.background ? props.background : '',
                        marginTop: props.mt,

                        '& fieldset': {
                            borderColor: props.borderColor
                                ? props.borderColor
                                : '',

                            border: props.noBorder ? 'none !important' : '',
                        },

                        '& input': {
                            textAlign: props.textAlign || '',
                            padding: props.inputPadding || '',
                        },

                        '& textarea': {
                            textAlign: props.textAlign || '',
                            padding: props.inputPadding || '',
                        },
                        '&.Mui-focused': {
                            mt: props.mt,
                            boxShadow:
                                props.noBorder || props.noShadow
                                    ? 'none !important'
                                    : '',
                        },
                    }}
                    placeholder={props.placeholder}
                    label={props.title}
                    endAdornment={props.endAdornment}
                    startAdornment={props.startAdornment}
                />

                {props.errorMessage && props.error && (
                    <Typography
                        sx={{ mt: 1, mb: 1 }}
                        color="error.main"
                        align="left"
                    >
                        {props.errorMessage}
                    </Typography>
                )}
            </FormControl>
        </Box>
    );
};
