import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { HighlightIndexes, LiveQueryEntities } from '../../services/interfaces';

interface IProps {
    text: string;
    entities?: LiveQueryEntities[];
    highlight?: HighlightIndexes;
    onCitationClick: (index: number) => void;
}

export const LiveResponse = (props: IProps) => {
    const [texts, setTexts] = useState(props.text.split('\n'));

    const updateHighlights = (
        highlights: HighlightIndexes
    ): HighlightIndexes => {
        const adjustHighlight = (highlight: number[]): number[] => {
            let newStart = highlight[0];
            while (['*', '-', ' ', '#'].includes(props.text[newStart])) {
                newStart++;
            }
            return [newStart, highlight[1]];
        };

        return {
            positive: highlights.positive?.map(adjustHighlight),
            negative: highlights.negative?.map(adjustHighlight),
        };
    };

    const formatString = (): string => {
        if (!props.highlight) {
            return props.text;
        }
        const highlights = updateHighlights(props.highlight);
        let formatData: { start: number; end: number; cssClass: string }[] = [];

        if (highlights.negative) {
            highlights.negative.forEach((n: number[]) => {
                if (
                    formatData.filter(
                        (d) =>
                            (n[0] > d.start && n[0] < d.end) ||
                            (n[1] > d.start && n[1] < d.end)
                    ).length == 0
                ) {
                    formatData.push({
                        start: n[0],
                        end: n[1],
                        cssClass: 'text-negetive',
                    });
                }
            });
        }
        if (highlights.positive) {
            highlights.positive.forEach((n: number[]) => {
                if (
                    formatData.filter(
                        (d) =>
                            (n[0] > d.start && n[0] < d.end) ||
                            (n[1] > d.start && n[1] < d.end)
                    ).length == 0
                ) {
                    formatData.push({
                        start: n[0],
                        end: n[1],
                        cssClass: 'text-positive',
                    });
                }
            });
        }
        formatData.sort(
            (
                a: { start: number; end: number; cssClass: string },
                b: { start: number; end: number; cssClass: string }
            ) => {
                return a.start < b.start ? 1 : -1;
            }
        );
        let _text = props.text;
        formatData.forEach(
            (pos: { start: number; end: number; cssClass: string }) => {
                _text =
                    _text.substring(0, pos.end) +
                    '</span>' +
                    _text.substring(pos.end);
                _text =
                    _text.substring(0, pos.start) +
                    `<span class="${pos.cssClass}">` +
                    _text.substring(pos.start);
            }
        );

        return _text;
    };

    const updatedIndex = (
        originalText: string,
        text: string,
        index: number
    ) => {
        const openingPositiveSpan = `<span class="text-positive">`;
        const openingNegetiveSpan = `<span class="text-negetive">`;
        const closingSpan = `</span>`;
        let updatedIndex = index;
        let originalPart = originalText.substring(0, index);
        let updatedPart = text.substring(0, index);
        while (originalPart != updatedPart) {
            if (
                text.indexOf(openingPositiveSpan) > -1 &&
                ((text.indexOf(openingNegetiveSpan) > -1 &&
                    text.indexOf(openingPositiveSpan) <
                        text.indexOf(openingNegetiveSpan)) ||
                    text.indexOf(openingNegetiveSpan) == -1)
            ) {
                text = text.replace(openingPositiveSpan, '');
                updatedIndex = updatedIndex + openingPositiveSpan.length;
                updatedPart = text.substring(0, index);
                if (originalPart == updatedPart) continue;
            } else if (
                text.indexOf(openingNegetiveSpan) > -1 &&
                ((text.indexOf(openingPositiveSpan) > -1 &&
                    text.indexOf(openingNegetiveSpan) <
                        text.indexOf(openingPositiveSpan)) ||
                    text.indexOf(openingPositiveSpan) == -1)
            ) {
                text = text.replace(openingNegetiveSpan, '');
                updatedIndex = updatedIndex + openingNegetiveSpan.length;
                updatedPart = text.substring(0, index);
                if (originalPart == updatedPart) continue;
            } else {
                break;
            }
            if (text.indexOf(closingSpan) > -1) {
                text = text.replace(closingSpan, '');
                updatedIndex = updatedIndex + closingSpan.length;
                updatedPart = text.substring(0, index);
                if (originalPart == updatedPart) continue;
            }
        }
        return updatedIndex;
    };

    useEffect(() => {
        let processedMessages = [];
        let text = formatString();
        if (props.entities) {
            let entities = props.entities
                .sort((a, b) => (a.start < b.start ? 1 : -1))
                .map((e) => {
                    return {
                        ...e,
                    };
                });
            for (let entity of entities) {
                let w = props.text.substring(entity.start, entity.end);
                entity.start = updatedIndex(
                    props.text.substring(0),
                    text.substring(0),
                    entity.start
                );
                entity.end = updatedIndex(
                    props.text.substring(0),
                    text.substring(0),
                    entity.end
                );
                let word = text.substring(entity.start, entity.end);
                text =
                    text.substring(0, entity.start) +
                    `<a href="/live?query=${entity.word}&insights=false">${word}</a>` +
                    text.substring(entity.end);
            }
        }
        let messages = text.split('\n');
        for (let message of messages) {
            for (let i = 0; i < 100; i++) {
                message = message
                    .split(`[${i}]`)
                    .join(
                        `<span citationId="${i}" class='citation-cta'>${i}</span>`
                    );
            }
            processedMessages.push(message);
        }
        setTexts([...processedMessages]);
    }, [props.text, props.highlight, props.entities]);

    const handleClick = (event: any) => {
        try {
            let citationId = (event.target as HTMLElement).getAttribute(
                'citationId'
            );
            if (citationId) {
                props.onCitationClick(parseInt(citationId));
                event.stopPropagation();
            }
        } catch (error) {}
    };

    return (
        <>
            <Box>
                <Box onClick={handleClick}>
                    <ReactMarkdown
                        linkTarget="_blank"
                        className="markdown"
                        rehypePlugins={[rehypeRaw]}
                        remarkPlugins={[remarkGfm]}
                    >
                        {texts.join('\n')}
                    </ReactMarkdown>
                </Box>
            </Box>
        </>
    );
};
