import { Box, Button, Fab, Paper, styled } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams, Outlet } from 'react-router-dom';
import { Landing } from './landing';
import { OnboardDialog } from '../../components/onboarding';
import { AppContext } from '../../providers/app-provider';
import { MobileMenu } from '../../components/mobile-menu';

export const Research = () => {
    const [searchParam] = useSearchParams();
    const appContext = useContext(AppContext);
    const [queryMessage, setQueryMessage] = useState<string>('');

    useEffect(() => {
        let query = searchParam.get('query');
        setQueryMessage(query == null ? '' : query);
    }, [searchParam.get('query')]);

    return (
        <Box>
            <OnboardDialog />
            {!queryMessage && <Landing />}
            {queryMessage && <Outlet />}
            {appContext?.isMobile && <MobileMenu />}
        </Box>
    );
};
