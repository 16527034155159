interface IProps {
    value?: string;
}

export const RecommendTiles = (props: IProps) => {
    if (props.value === 'POSITIVE' || props.value === 'APPROVE') {
        return (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <mask
                    id="mask0_1773_101702"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                >
                    <rect width="20" height="20" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_1773_101702)">
                    <path
                        d="M10.0052 18.3346C8.85243 18.3346 7.7691 18.1159 6.75521 17.6784C5.74132 17.2409 4.85937 16.6471 4.10937 15.8971C3.35937 15.1471 2.76562 14.2652 2.32812 13.2513C1.89062 12.2374 1.67188 11.1541 1.67188 10.0013C1.67188 8.84852 1.89062 7.76519 2.32812 6.7513C2.76562 5.73741 3.35937 4.85547 4.10937 4.10547C4.85937 3.35547 5.74132 2.76172 6.75521 2.32422C7.7691 1.88672 8.85243 1.66797 10.0052 1.66797C10.908 1.66797 11.7622 1.79991 12.5677 2.0638C13.3733 2.32769 14.1163 2.69575 14.7969 3.16797L13.5885 4.39714C13.0608 4.0638 12.4983 3.80339 11.901 3.61589C11.3038 3.42839 10.6719 3.33464 10.0052 3.33464C8.15799 3.33464 6.58507 3.98394 5.28646 5.28255C3.98785 6.58116 3.33854 8.15408 3.33854 10.0013C3.33854 11.8485 3.98785 13.4214 5.28646 14.72C6.58507 16.0187 8.15799 16.668 10.0052 16.668C11.8524 16.668 13.4253 16.0187 14.724 14.72C16.0226 13.4214 16.6719 11.8485 16.6719 10.0013C16.6719 9.7513 16.658 9.5013 16.6302 9.2513C16.6024 9.0013 16.5608 8.75825 16.5052 8.52213L17.8594 7.16797C18.0122 7.61241 18.1302 8.07075 18.2135 8.54297C18.2969 9.01519 18.3385 9.5013 18.3385 10.0013C18.3385 11.1541 18.1198 12.2374 17.6823 13.2513C17.2448 14.2652 16.651 15.1471 15.901 15.8971C15.151 16.6471 14.2691 17.2409 13.2552 17.6784C12.2413 18.1159 11.158 18.3346 10.0052 18.3346ZM8.83854 13.8346L5.29687 10.293L6.46354 9.1263L8.83854 11.5013L17.1719 3.14714L18.3385 4.3138L8.83854 13.8346Z"
                        fill="#19AF55"
                    />
                </g>
            </svg>
        );
    }
    if (props.value === 'NEGATIVE' || props.value === 'DISAPPROVE') {
        return (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.0013 1.66699C5.4013 1.66699 1.66797 5.40032 1.66797 10.0003C1.66797 14.6003 5.4013 18.3337 10.0013 18.3337C14.6013 18.3337 18.3346 14.6003 18.3346 10.0003C18.3346 5.40032 14.6013 1.66699 10.0013 1.66699ZM10.0013 16.667C6.31797 16.667 3.33464 13.6837 3.33464 10.0003C3.33464 8.45866 3.85964 7.04199 4.74297 5.91699L14.0846 15.2587C12.9206 16.1739 11.482 16.67 10.0013 16.667ZM15.2596 14.0837L5.91797 4.74199C7.08202 3.8268 8.52056 3.33065 10.0013 3.33366C13.6846 3.33366 16.668 6.31699 16.668 10.0003C16.668 11.542 16.143 12.9587 15.2596 14.0837Z"
                    fill="#D82F44"
                />
            </svg>
        );
    }
    if (props.value === 'NEUTRAL') {
        return (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <mask
                    id="mask0_1773_101711"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                >
                    <rect width="20" height="20" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_1773_101711)">
                    <path
                        d="M9.99617 17.7181C8.90122 17.7181 7.87203 17.5103 6.90858 17.0948C5.94513 16.6792 5.10706 16.1153 4.39438 15.4029C3.68168 14.6905 3.11747 13.8528 2.70173 12.8898C2.28599 11.9268 2.07812 10.8978 2.07812 9.80287C2.07812 8.70792 2.2859 7.67873 2.70146 6.71528C3.11701 5.75182 3.68097 4.91376 4.39333 4.20108C5.10571 3.48838 5.94341 2.92417 6.90644 2.50843C7.86945 2.09269 8.89843 1.88482 9.99338 1.88482C11.0883 1.88482 12.1175 2.0926 13.081 2.50816C14.0444 2.92371 14.8825 3.48767 15.5952 4.20003C16.3079 4.91241 16.8721 5.75011 17.2878 6.71314C17.7036 7.67615 17.9114 8.70513 17.9114 9.80008C17.9114 10.895 17.7036 11.9242 17.2881 12.8877C16.8725 13.8511 16.3086 14.6892 15.5962 15.4019C14.8838 16.1146 14.0461 16.6788 13.0831 17.0945C12.1201 17.5103 11.0911 17.7181 9.99617 17.7181ZM9.99477 16.4681C11.8559 16.4681 13.4323 15.8223 14.7239 14.5306C16.0156 13.239 16.6614 11.6626 16.6614 9.80147C16.6614 7.94036 16.0156 6.36397 14.7239 5.0723C13.4323 3.78064 11.8559 3.1348 9.99477 3.1348C8.13366 3.1348 6.55727 3.78064 5.2656 5.0723C3.97394 6.36397 3.3281 7.94036 3.3281 9.80147C3.3281 11.6626 3.97394 13.239 5.2656 14.5306C6.55727 15.8223 8.13366 16.4681 9.99477 16.4681Z"
                        fill="#A5A6AB"
                    />
                </g>
                <mask
                    id="mask1_1773_101711"
                    maskUnits="userSpaceOnUse"
                    x="1"
                    y="1"
                    width="18"
                    height="18"
                >
                    <rect
                        x="1.66016"
                        y="1.66504"
                        width="16.6667"
                        height="16.6667"
                        rx="8.33333"
                        fill="#D9D9D9"
                    />
                </mask>
                <g mask="url(#mask1_1773_101711)">
                    <path
                        d="M6.52648 10.6946C6.14294 10.6946 5.83203 10.3836 5.83203 10.0001C5.83203 9.61658 6.14294 9.30566 6.52648 9.30566H13.4709C13.8545 9.30566 14.1654 9.61658 14.1654 10.0001C14.1654 10.3836 13.8545 10.6946 13.4709 10.6946H6.52648Z"
                        fill="#A5A6AB"
                    />
                </g>
            </svg>
        );
    }

    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_1773_101885"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
            >
                <rect width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1773_101885)">
                <path
                    d="M10.0052 18.3346C8.85243 18.3346 7.7691 18.1159 6.75521 17.6784C5.74132 17.2409 4.85937 16.6471 4.10937 15.8971C3.35937 15.1471 2.76562 14.2652 2.32812 13.2513C1.89062 12.2374 1.67188 11.1541 1.67188 10.0013C1.67188 8.84852 1.89062 7.76519 2.32812 6.7513C2.76562 5.73741 3.35937 4.85547 4.10937 4.10547C4.85937 3.35547 5.74132 2.76172 6.75521 2.32422C7.7691 1.88672 8.85243 1.66797 10.0052 1.66797C11.158 1.66797 12.2413 1.88672 13.2552 2.32422C14.2691 2.76172 15.151 3.35547 15.901 4.10547C16.651 4.85547 17.2448 5.73741 17.6823 6.7513C18.1198 7.76519 18.3385 8.84852 18.3385 10.0013C18.3385 11.1541 18.1198 12.2374 17.6823 13.2513C17.2448 14.2652 16.651 15.1471 15.901 15.8971C15.151 16.6471 14.2691 17.2409 13.2552 17.6784C12.2413 18.1159 11.158 18.3346 10.0052 18.3346ZM10.0052 16.668C11.8663 16.668 13.4427 16.0221 14.7344 14.7305C16.026 13.4388 16.6719 11.8624 16.6719 10.0013C16.6719 8.14019 16.026 6.5638 14.7344 5.27214C13.4427 3.98047 11.8663 3.33464 10.0052 3.33464C8.1441 3.33464 6.56771 3.98047 5.27604 5.27214C3.98437 6.5638 3.33854 8.14019 3.33854 10.0013C3.33854 11.8624 3.98437 13.4388 5.27604 14.7305C6.56771 16.0221 8.1441 16.668 10.0052 16.668Z"
                    fill="#9C9FD0"
                />
            </g>
        </svg>
    );
};
