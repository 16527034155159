import { Constants } from '../constants';
import sha256 from 'sha256';
import { NetworkRequestMethod } from './network.service';
import { useContext } from 'react';
import { AppContext } from '../providers/app-provider';
import { useNavigate } from 'react-router-dom';
import { UtilsService } from './utils.service';
import * as Sentry from '@sentry/react';
import OneSignal from 'react-onesignal';
import jwt_decode from 'jwt-decode';
import { rudderanalytics } from '../analytics';
import {
    IAuthRequest,
    IEmailVerification,
    IEmailVerificationResponse,
    IOtpVerification,
    IOtpVerificationResponse,
    ICreateUserRequest,
    ICreateUserResponse,
    IAuthResponse,
    IPasswordResetRequest,
    IPasswordResetResponse,
    IPermissionsResponse,
    IExternalTokenRequest,
    IExternalTokenVerification,
    IResetForgotPasswordRequest,
    IResetForgotPassword,
    Permission,
} from './interfaces';
import { CookieService } from './cookie.service';
import posthog from 'posthog-js';
const cookieService = new CookieService();

export const ACCESS_TOKEN_KEY = 'at';
const REFRESH_TOKEN_KEY = 'rt';
const USER_NAME_KEY = 'u';
export const COMPANY_ID_KEY = 'cid';
const USER_ID_KEY = 'uid';
const USER_ROLE_KEY = 'r';
const PLAN_TYPE_KEY = 'pt';

interface IAuthRes {}

interface IProps {
    triggerRequest: Function;
}

export const useAuthService = () => {
    const navigate = useNavigate();

    const authenticateUser = async (
        emailId: string,
        password: string,
        triggerRequest: Function
    ) => {
        password = sha256(emailId + sha256(password));

        try {
            const body: IAuthRequest = {
                emailId,
                password,
            };
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IAuthResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    body: JSON.stringify(body),
                    headers,
                    url: Constants.authenticateUser,
                    retryCount: 3,
                },
                ['x-pi-bearer', 'x-pi-refresh'],
                true
            );
            if (result.body.success) {
                saveAccessToken(result.headers['x-pi-bearer']);
                saveRefreshToken(result.headers['x-pi-refresh']);
                saveUserID(result.body.data.userId);
                saveCompanyId(result.body.data.companyId);
                saveUserRole(result.body.data.role);
                savePlanType(result.body.data.planType);
                rudderanalytics.identify(result.body.data.userId);
                posthog.identify(
                    result.body.data.userId || '', // Required. Replace 'distinct_id' with your user's unique identifier
                    {
                        email: result.body.data.emailId,
                        name: `${result.body.data.firstName} ${result.body.data.lastName}`,
                    } // $set, optional
                );
                Sentry.setUser({
                    email: result.body.data.emailId,
                    id: result.body.data.userId,
                });
                saveUserName(
                    `${result.body.data.firstName} ${result.body.data.lastName}`
                );
            }
            return result.body;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const verifyExternalToken = async (
        token: string,
        triggerRequest: Function
    ) => {
        try {
            const body: IExternalTokenRequest = {
                authRequest: 'GOOGLE',
                token,
            };
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IExternalTokenVerification;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    body: JSON.stringify(body),
                    headers,
                    url: Constants.externalTokenVerification,
                    retryCount: 3,
                },
                ['x-pi-bearer', 'x-pi-refresh'],
                true
            );
            return result;
        } catch (error: any) {
            return error;
        }
    };

    const resetForgottenPassword = async (
        token: string,
        newPassword: string,
        emailId: string,
        triggerRequest: Function
    ) => {
        newPassword = sha256(newPassword);

        try {
            const body: IResetForgotPasswordRequest = {
                emailId,
                newPassword,
            };
            const headers = {
                'Content-Type': 'application/json',
                'x-pi-bearer': token,
            };
            const result: {
                body: IResetForgotPassword;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    body: JSON.stringify(body),
                    headers,
                    url: Constants.resetForgotPassword,
                    retryCount: 3,
                },
                ['x-pi-bearer', 'x-pi-refresh'],
                true
            );
            return result.body;
        } catch (error: any) {
            return error;
        }
    };

    const generateEmailVerification = async (
        emailId: string,
        triggerRequest: Function,
        type: boolean
    ) => {
        try {
            const body: IEmailVerification = {
                emailId,
            };
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IEmailVerificationResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    body: JSON.stringify(body),
                    headers,
                    url: type
                        ? Constants.generateEmailVerification
                        : Constants.generatePasswordVerification,
                },
                ['x-pi-bearer', 'x-pi-refresh'],
                true
            );
            return result.body;
        } catch (error: any) {
            return error;
        }
    };

    const verifyEmailOtp = async (
        emailId: string,
        code: number,
        triggerRequest: Function,
        type: boolean
    ) => {
        try {
            const body: IOtpVerification = {
                emailId,
                code,
            };
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IOtpVerificationResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    body: JSON.stringify(body),
                    headers,
                    url: type
                        ? Constants.verifyEmailOtpCode
                        : Constants.verifyPasswordOtpCode,
                },
                ['x-pi-bearer', 'x-pi-refresh'],
                true
            );
            return result;
        } catch (error: any) {
            return error;
        }
    };

    const createUser = async (
        firstName: string,
        lastName: string,
        emailId: string,
        token: string,
        password: string,
        triggerRequest: Function
    ) => {
        password = sha256(password);

        try {
            const body: ICreateUserRequest = {
                emailId,
                firstName,
                lastName,
                password,
                validFor: 30,
                maxSeats: 1,
            };
            const headers = {
                'Content-Type': 'application/json',
                'x-pi-bearer': token,
            };
            const result: {
                body: ICreateUserResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    body: JSON.stringify(body),
                    headers,
                    url: Constants.createUser,
                    retryCount: 3,
                },
                ['x-pi-bearer', 'x-pi-refresh'],
                true
            );
            return result.body;
        } catch (error: any) {
            return error;
        }
    };

    const getPermissions = async (
        triggerRequest: Function
    ): Promise<Permission[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IPermissionsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: Constants.userPermission,
                retryCount: 3,
            });

            return result.body.data.accessCodes;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
        return [];
    };

    const resetPassword = async (
        currentPassword: string,
        newPassword: string,
        triggerRequest: Function
    ): Promise<IPasswordResetResponse> => {
        currentPassword = sha256((await getUserId()) + sha256(currentPassword));
        newPassword = sha256(newPassword);
        try {
            const body: IPasswordResetRequest = {
                newPassword,
                currentPassword,
            };
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IPasswordResetResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                body: JSON.stringify(body),
                headers,
                url: Constants.resetPassword,
                retryCount: 3,
            });

            return result.body;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const saveAccessToken = async (value: string) => {
        await cookieService.set(ACCESS_TOKEN_KEY, value);
    };

    const saveUserName = async (value: string) => {
        await cookieService.set(USER_NAME_KEY, value);
    };

    const saveCompanyId = async (value: string) => {
        OneSignal.getTags().then((tags: any) => {
            if (!tags['CompanyId']) {
                OneSignal.sendTag('CompanyId', value).then(() => {
                    console.log('CompanyID updated successfully');
                });
            }
        });
        await cookieService.set(COMPANY_ID_KEY, value);
    };

    const saveUserRole = async (value: string) => {
        await cookieService.set(USER_ROLE_KEY, value);
    };

    const savePlanType = async (value: string) => {
        await cookieService.set(PLAN_TYPE_KEY, value);
    };

    const getUserRole = async () => {
        return await cookieService.get(USER_ROLE_KEY);
    };

    const getPlanType = async () => {
        return await cookieService.get(PLAN_TYPE_KEY);
    };

    const getCompanyId = async () => {
        return await cookieService.get(COMPANY_ID_KEY);
    };

    const saveUserID = async (value: string) => {
        OneSignal.setExternalUserId(value);
        await cookieService.set(USER_ID_KEY, value);
    };

    const saveRefreshToken = async (value: string) => {
        await cookieService.set(REFRESH_TOKEN_KEY, value);
    };

    const logOut = () => {
        saveAccessToken('');
        saveRefreshToken('');
        window.location.href = '/login';
    };

    const getUserName = async () => {
        let userName = await cookieService.get(USER_NAME_KEY);
        if (!userName) {
            Sentry.setUser({ username: userName });
            if (window.location.pathname != '/signup') {
                navigate('/login');
            }
            Sentry.setUser(null);
        }
        return userName;
    };

    const getAccessToken = async (
        triggerRequest: Function
    ): Promise<string> => {
        OneSignal.getExternalUserId().then(async (uId: any) => {
            if (!uId) {
                OneSignal.setExternalUserId(await getUserId()).then(() => {
                    console.log('CompanyID updated successfully');
                });
            }
        });
        return (await cookieService.get(ACCESS_TOKEN_KEY)) || '';
    };

    const getUserId = async () => {
        return await cookieService.get(USER_ID_KEY);
    };

    const generateAccessToken = async (
        triggerRequest: Function
    ): Promise<boolean> => {
        try {
            //@ts-ignore
            if (window.fetchingToken) {
                await UtilsService.waitUntilTokenFetched();
                return true;
            }
            //@ts-ignore
            window.fetchingToken = true;
            const headers = {
                'Content-Type': 'application/json',
                'x-pi-refresh': await getRefreshToken(),
            };
            const result: {
                body: IAuthResponse;
                status: number;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.GET,
                    headers,
                    url: Constants.generateAccessToken,
                    retryCount: 1,
                },
                ['x-pi-bearer', 'x-pi-refresh'],
                false,
                true
            );
            if (result.body.success) {
                saveAccessToken(result.headers['x-pi-bearer']);
                //@ts-ignore
                window.fetchingToken = false;
                return true;
            } else {
                if (window.location.pathname != '/signup') {
                    navigate('/login');
                }
                return false;
            }
        } catch (error: any) {
            if (window.location.pathname != '/signup') {
                navigate('/login');
            }
            return false;
        }
    };

    const getRefreshToken = async (): Promise<string> => {
        return (await cookieService.get(REFRESH_TOKEN_KEY)) || '';
    };
    return {
        authenticateUser,
        verifyExternalToken,
        generateEmailVerification,
        verifyEmailOtp,
        createUser,
        getAccessToken,
        getRefreshToken,
        getUserName,
        saveAccessToken,
        saveRefreshToken,
        getCompanyId,
        generateAccessToken,
        logOut,
        getUserRole,
        getPlanType,
        getPermissions,
        resetPassword,
        resetForgottenPassword,
    };
};
