import React, { useState } from 'react';
import {
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Fab,
    Paper,
    styled,
    Typography,
} from '@mui/material';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { MiniLogo } from '../../components/icons/mini-logo';
import { Filters } from '../../pages/research/landing/filters';
import { Live } from '../icons/live';
import { useAuthService } from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';

// Create a styled Fab (Floating Action Button) for the center button
const CenterFab = styled(Fab)({
    position: 'absolute',
    top: '-30px', // Slightly raise it above the bottom navigation bar
    backgroundColor: '#F9F9FF',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    '&:hover': {
        backgroundColor: '#F9F9FF',
    },
});

export const MobileMenu: React.FC = () => {
    const { logOut } = useAuthService();
    const [openMenu, setOpenMenu] = useState(false);
    const navigate = useNavigate();

    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,

                border: '1px solid #ccc',
            }}
            elevation={0}
        >
            <BottomNavigation
                showLabels
                sx={{ justifyContent: 'space-around' }}
            >
                <BottomNavigationAction
                    label={'Home'}
                    onClick={() => navigate(`/`)}
                    sx={{
                        '& .MuiBottomNavigationAction-label': {
                            mt: 1,
                            color: '#000',
                        },
                    }}
                    icon={<Live fillColor="#29319B" />}
                />
                <BottomNavigationAction
                    label="Settings"
                    onClick={() => setOpenMenu(true)}
                    sx={{
                        '& .MuiBottomNavigationAction-label': {
                            mt: 1,
                        },
                    }}
                    icon={
                        <Filters
                            color="#666"
                            min={true}
                            outlined={true}
                            open={openMenu}
                            setOpen={setOpenMenu}
                            setFiltersfetched={() => {}}
                            filtersFetched={true}
                            onFiltersUpdate={() => {}}
                        />
                    }
                />
                {/* <Box
                    sx={{
                        position: 'absolute',
                        background:
                            'linear-gradient(to bottom, #fff 50%, #ccc 50%)',
                        height: 70,
                        width: 70,
                        top: -36,
                        borderRadius: '50%',
                    }}
                ></Box>
                <Box
                    sx={{
                        position: 'absolute',
                        background: '#fff',
                        height: 68,
                        width: 68,
                        top: -35,
                        borderRadius: '50%',
                    }}
                ></Box>

                <CenterFab>
                    <MiniLogo height="20px" width="20px" />
                </CenterFab> */}
                <BottomNavigationAction
                    onClick={logOut}
                    label="Log Out"
                    sx={{
                        '& .MuiBottomNavigationAction-label': {
                            mt: 1,
                        },
                    }}
                    icon={<LogoutRoundedIcon />}
                />
            </BottomNavigation>
        </Paper>
    );
};
