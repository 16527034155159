import { useContext } from 'react';
import { Constants } from '../constants';
import { AppContext } from '../providers/app-provider';
import {
    ILiveQAFiltersResponse,
    ILiveQAFiltersResponseData,
    RecentSearchesRequest,
    RecentSearchesRequestData,
    RecentSearchesResponse,
    RecentSearchesResponseData,
    Search,
} from './interfaces';
import { NetworkRequestMethod, useNetworkAPI } from './network.service';
import { UtilsService } from './utils.service';
import { PropaneSharp } from '@mui/icons-material';

export const useLiveServices = () => {
    const { triggerRequest, triggerRequestWithChunkedResponse } =
        useNetworkAPI();
    const appContext = useContext(AppContext);

    const fetchRecentSearches =
        async (): Promise<RecentSearchesResponseData> => {
            try {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const result: {
                    headers: { [key: string]: string };
                    body: RecentSearchesResponse;
                } = await triggerRequest({
                    method: NetworkRequestMethod.GET,
                    headers,
                    url: Constants.getRecentSearches,
                    retryCount: 3,
                });
                return result.body.data;
            } catch (error: any) {
                console.error(error);
                throw error;
            }
        };

    const deleteSelectedSearch = async (
        q: Search
    ): Promise<RecentSearchesRequestData> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                headers: { [key: string]: string };
                body: RecentSearchesRequest;
            } = await triggerRequest({
                method: NetworkRequestMethod.PATCH,
                headers,
                body: JSON.stringify({ chatQueries: q.chatQueryIds }),
                url: `${Constants.deleteSearchByQueryId}`,
                retryCount: 3,
            });
            return result.body.data;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };
    const fetchLiveQueryFilter =
        async (): Promise<ILiveQAFiltersResponseData> => {
            if (!appContext) throw '';
            try {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body = {
                    feature: 'LIVE_QA',
                };
                const result: {
                    body: ILiveQAFiltersResponse;
                    headers: { [key: string]: string };
                } = await triggerRequest({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.getChatbotFilters,
                    retryCount: 3,
                });

                return result.body.data;
            } catch (error: any) {
                console.error(error);
                return error;
            }
        };
    const updateLiveQueryFilter = async (
        settings: ILiveQAFiltersResponseData
    ): Promise<boolean> => {
        if (!appContext) throw '';
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body = {
                feature: 'LIVE_QA',
                settings,
            };
            const result: {
                body: ILiveQAFiltersResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.updateChatbotFilters,
                retryCount: 3,
            });

            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };
    return {
        fetchRecentSearches,
        deleteSelectedSearch,
        updateLiveQueryFilter,
        fetchLiveQueryFilter,
    };
};
