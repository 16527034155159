interface IProps {
    fillColor: string;
}
export const Live = (props: IProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.9005 20.11V20.12M19.0405 17.61V17.62M20.7705 14V14.01M20.7705 10V10.01M19.0405 6.39V6.4M15.9005 3.89V3.9M12.0005 3V3.01M8.10047 3.89V3.9M4.96047 6.39V6.4M3.23047 10V10.01M3.23047 14V14.01M4.96047 17.61V17.62M8.10047 20.11V20.12M12.0005 21V21.01M7.00047 12C7.00047 13.3261 7.52725 14.5979 8.46493 15.5355C9.40262 16.4732 10.6744 17 12.0005 17C13.3266 17 14.5983 16.4732 15.536 15.5355C16.4737 14.5979 17.0005 13.3261 17.0005 12C17.0005 10.6739 16.4737 9.40215 15.536 8.46447C14.5983 7.52678 13.3266 7 12.0005 7C10.6744 7 9.40262 7.52678 8.46493 8.46447C7.52725 9.40215 7.00047 10.6739 7.00047 12Z"
            stroke={props.fillColor}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <g clip-path="url(#clip0_11064_117182)">
            <path
                d="M11.1309 14.2453V9.8633C11.1309 9.68827 10.9899 9.54639 10.8159 9.54639C10.642 9.54639 10.501 9.68827 10.501 9.8633V14.2453C10.501 14.4203 10.642 14.5622 10.8159 14.5622C10.9899 14.5622 11.1309 14.4203 11.1309 14.2453Z"
                fill={props.fillColor}
            />
            <path
                d="M12.0625 12.4685V10.4021C12.0625 10.2248 11.9215 10.2475 11.7476 10.2475C11.5736 10.2475 11.4326 10.2248 11.4326 10.4021V12.4685C11.4326 12.6458 11.5736 12.7895 11.7476 12.7895C11.9215 12.7895 12.0625 12.6458 12.0625 12.4685Z"
                fill={props.fillColor}
            />
            <path
                d="M13.9277 11.8218V10.5366C13.9277 10.4071 13.7867 10.2466 13.6128 10.2466C13.4389 10.2466 13.2979 10.4071 13.2979 10.5366V11.8218C13.2979 11.9513 13.4389 12.0896 13.6128 12.0896C13.7867 12.0896 13.9277 11.9513 13.9277 11.8218Z"
                fill={props.fillColor}
            />
            <path
                d="M12.3682 9.86339V11.9041C12.3682 12.0792 12.5116 12.0893 12.6855 12.0893C12.8595 12.0893 12.9981 12.0792 12.9981 11.9041V9.86339C12.9981 9.68831 12.8571 9.54639 12.6831 9.54639C12.5092 9.54639 12.3682 9.68831 12.3682 9.86339Z"
                fill={props.fillColor}
            />
            <path
                d="M11.6901 9.59798C11.7174 9.55328 11.7823 9.55328 11.8096 9.59798L12.0586 10.006C12.0871 10.0527 12.0535 10.1125 11.9989 10.1125H11.5008C11.4461 10.1125 11.4126 10.0527 11.441 10.006L11.6901 9.59798Z"
                fill={props.fillColor}
            />
            <path
                d="M12.7393 12.7381C12.712 12.7829 12.6471 12.7829 12.6198 12.7381L12.3707 12.3301C12.3422 12.2834 12.3758 12.2236 12.4305 12.2236H12.9286C12.9832 12.2236 13.0168 12.2834 12.9883 12.3301L12.7393 12.7381Z"
                fill={props.fillColor}
            />
        </g>
        <defs>
            <clipPath id="clip0_11064_117182">
                <rect
                    width="3.42944"
                    height="5.0625"
                    fill={props.fillColor}
                    transform="translate(10.5 9.5)"
                />
            </clipPath>
        </defs>
    </svg>
);
