import React from 'react';
import { ReportModule } from '../../../services/interfaces';
import { BorderedBox } from '../../../components/core/box';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { XIcon } from '../../../components/icons/x';
import { NoData } from './no-data';
import { LiveResponse } from '../../../components/live/live-response';

const TableCellBorderless = styled(TableCell)(({ theme }) => ({
    border: 'none',
    padding: '4px 0px 4px 0px',
}));

interface IProps {
    data: ReportModule;
}

export const Overview = (props: IProps) => {
    const { data } = props;
    return (
        <BorderedBox
            sx={{
                backgroundColor: '#F9F9FF',
            }}
            pb={4}
            my={4}
        >
            <Typography px={4} pt={4} variant="h4">
                Overview
            </Typography>
            <Box
                className="scrollable"
                sx={{
                    maxHeight: 'calc(100vh - 220px)',
                    overflow: 'auto',
                }}
                px={4}
            >
                {!data.companyDescription &&
                !data.numEmployees &&
                !data.website ? (
                    <NoData />
                ) : (
                    <Box>
                        {data.companyDescription && (
                            <LiveResponse
                                onCitationClick={() => {}}
                                text={data.companyDescription}
                            />
                        )}
                        <Box
                            sx={{
                                borderTop: '1px dashed #E0E0E0',
                                borderBottom: '1px dashed #E0E0E0',
                            }}
                            py={3}
                            mb={3}
                        >
                            <TableContainer component={Box}>
                                <Table sx={{ minWidth: 650 }}>
                                    <TableBody>
                                        {[
                                            {
                                                key: 'Location',
                                                value: data.location
                                                    ? `${data.location?.city}, ${data.location?.region}, ${data.location?.country}`
                                                    : '',
                                            },
                                            {
                                                key: 'Number Of Employees',
                                                value: data.numEmployees,
                                            },
                                            {
                                                key: 'Last funding type',
                                                value: data.lastFundingType,
                                            },
                                            {
                                                key: 'IPO Status',
                                                value: data.ipoStatus,
                                            },
                                            {
                                                key: 'Website',
                                                value: data.website ? (
                                                    <a
                                                        href={data.website}
                                                        target="_blank"
                                                    >
                                                        {data.website}
                                                    </a>
                                                ) : null,
                                            },
                                        ]
                                            .filter((p) => p.value)
                                            .map((pair) => (
                                                <TableRow key={pair.key}>
                                                    <TableCellBorderless
                                                        sx={{ width: '200px' }}
                                                        scope="row"
                                                    >
                                                        <Typography variant="h6">
                                                            {pair.key}
                                                        </Typography>
                                                    </TableCellBorderless>
                                                    <TableCellBorderless align="left">
                                                        <Typography
                                                            fontSize={16}
                                                        >
                                                            {pair.value}
                                                        </Typography>
                                                    </TableCellBorderless>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <TableContainer component={Box}>
                            <Table sx={{ minWidth: 650 }}>
                                <TableBody>
                                    {[
                                        {
                                            key: 'Industries',
                                            value:
                                                data.industries &&
                                                data.industries.length > 0 ? (
                                                    <Box
                                                        flexWrap={'wrap'}
                                                        display="flex"
                                                    >
                                                        {data.industries?.map(
                                                            (industry) => (
                                                                <Box
                                                                    px={1}
                                                                    mr={2}
                                                                    borderRadius={
                                                                        0.75
                                                                    }
                                                                    sx={{
                                                                        backgroundColor:
                                                                            '#EBECF6',
                                                                    }}
                                                                    key={
                                                                        industry
                                                                    }
                                                                >
                                                                    {industry}
                                                                </Box>
                                                            )
                                                        )}
                                                    </Box>
                                                ) : null,
                                        },
                                        {
                                            key: 'Headquarters regions',
                                            value: data.location?.region,
                                        },
                                        {
                                            key: 'Founding date',
                                            value: data.foundingDate,
                                        },
                                        {
                                            key: 'Founders',
                                            value: data.founders?.join(', '),
                                        },
                                        {
                                            key: 'Legal name',
                                            value: data.legalName,
                                        },
                                        {
                                            key: 'Stock symbol',
                                            value: data.stockSymbol,
                                        },
                                        {
                                            key: 'Contact email',
                                            value: data.contactEmail ? (
                                                <a
                                                    href={`mailto:${data.contactEmail}`}
                                                >
                                                    {data.contactEmail}
                                                </a>
                                            ) : null,
                                        },
                                        {
                                            key: 'Social Media',
                                            value:
                                                data.socialMedia &&
                                                data.socialMedia.length > 0 ? (
                                                    <Box display={'flex'}>
                                                        {data.socialMedia?.map(
                                                            (social) => (
                                                                <Box
                                                                    pr={2}
                                                                    component={
                                                                        'a'
                                                                    }
                                                                    href={
                                                                        social.url
                                                                    }
                                                                    target="_blank"
                                                                    lineHeight={
                                                                        '10px'
                                                                    }
                                                                >
                                                                    {social.platform ==
                                                                        'facebook' && (
                                                                        <FacebookIcon
                                                                            sx={{
                                                                                fontSize: 26,
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {social.platform ==
                                                                        'linkedin' && (
                                                                        <LinkedInIcon
                                                                            sx={{
                                                                                fontSize: 26,
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {social.platform ==
                                                                        'twitter' &&
                                                                        XIcon}
                                                                </Box>
                                                            )
                                                        )}
                                                    </Box>
                                                ) : null,
                                        },
                                    ]
                                        .filter((p) => p.value)
                                        .map((pair) => (
                                            <TableRow key={pair.key}>
                                                <TableCellBorderless
                                                    sx={{ width: '200px' }}
                                                    scope="row"
                                                >
                                                    <Typography variant="h6">
                                                        {pair.key}
                                                    </Typography>
                                                </TableCellBorderless>
                                                <TableCellBorderless align="left">
                                                    <Typography fontSize={16}>
                                                        {pair.value}
                                                    </Typography>
                                                </TableCellBorderless>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
            </Box>
        </BorderedBox>
    );
};
