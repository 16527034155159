export const BuzzwordCloudIcon = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_152_111214"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
        >
            <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_152_111214)">
            <path
                d="M5.41665 16.25C4.26495 16.25 3.28258 15.8499 2.46954 15.0496C1.65651 14.2494 1.25 13.2735 1.25 12.1218C1.25 11.1025 1.57639 10.196 2.22917 9.40223C2.88194 8.60844 3.71474 8.1389 4.72756 7.9936C4.99466 6.74787 5.62099 5.72917 6.60656 4.9375C7.59213 4.14583 8.72327 3.75 9.99998 3.75C11.5075 3.75 12.7871 4.27591 13.8389 5.32773C14.8907 6.37954 15.4166 7.65917 15.4166 9.16665V9.58333H15.673C16.5512 9.65171 17.2836 10.0048 17.8701 10.6426C18.4567 11.2804 18.75 12.0385 18.75 12.9166C18.75 13.8461 18.4268 14.634 17.7804 15.2804C17.134 15.9268 16.3461 16.25 15.4166 16.25H5.41665ZM5.41665 15H15.4166C16 15 16.493 14.7986 16.8958 14.3958C17.2986 13.993 17.5 13.5 17.5 12.9166C17.5 12.3333 17.2986 11.8403 16.8958 11.4375C16.493 11.0347 16 10.8333 15.4166 10.8333H14.1666V9.16665C14.1666 8.01387 13.7604 7.03123 12.9479 6.21873C12.1354 5.40623 11.1528 4.99998 9.99998 4.99998C8.8472 4.99998 7.86456 5.40623 7.05206 6.21873C6.23956 7.03123 5.83331 8.01387 5.83331 9.16665H5.41665C4.61109 9.16665 3.92359 9.45137 3.35415 10.0208C2.7847 10.5903 2.49998 11.2778 2.49998 12.0833C2.49998 12.8889 2.7847 13.5764 3.35415 14.1458C3.92359 14.7153 4.61109 15 5.41665 15Z"
                fill="#000033"
            />
        </g>
    </svg>
);
