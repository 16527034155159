import { Constants } from '../constants';
import {
    IIntegration,
    IIntegrationData,
    ILoginUrl,
    ILoginUrlData,
    IIntegrationAccount,
    IntegrationAccountsResponse,
    IntegrationsList,
    DriveItemResponse,
    IDriveItem,
    IIntegrationSourcesResponse,
    FileListResponse,
    DocumentFile,
    IAddDataSourceResponse,
} from './interfaces';
import {
    INetworkResponse,
    NetworkRequestMethod,
    useNetworkAPI,
} from './network.service';

export interface FileUploadStatus {
    progress: number;
    created?: boolean;
}

export const useDriveService = () => {
    const { triggerRequest, triggerRequestWithChunkedResponse } =
        useNetworkAPI();

    const fetchIntegrations = async (): Promise<IIntegration[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IntegrationsList;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: Constants.integrationsList,
                retryCount: 3,
            });
            return result.body.data.integrations;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };
    const fetchRoot = async (
        integrationName: string,
        accountId: string
    ): Promise<IDriveItem[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: DriveItemResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.integrationsFiles}${integrationName}/account/${accountId}/list-root`,
                retryCount: 3,
            });
            return result.body.data.items;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const getSourceChildrens = async (
        integrationName: string,
        accountId: string,
        itemId: string
    ): Promise<IDriveItem[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: DriveItemResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify({
                    item_id: itemId,
                }),
                url: `${Constants.integrationsFiles}${integrationName}/account/${accountId}/list-items`,
                retryCount: 3,
            });
            return result.body.data.items;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const deleteIntegration = async (
        integrationName: string,
        accountId: string
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.DELETE,
                headers,
                url: `${Constants.integrationsFiles}${integrationName}/account/${accountId}`,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };
    const addSource = async (
        integrationName: string,
        accountId: string,
        itemId: string
    ): Promise<IDriveItem> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IAddDataSourceResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify({
                    item_id: itemId,
                }),
                url: `${Constants.integrationsFiles}${integrationName}/account/${accountId}/add-data-source`,
                retryCount: 3,
            });
            return result.body.data;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const syncSource = async (
        integrationName: string,
        accountId: string,
        itemId: string
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify({
                    data_source_id: itemId,
                }),
                url: `${Constants.integrationsFiles}${integrationName}/account/${accountId}/sync-data-source`,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const deleteSource = async (
        integrationName: string,
        accountId: string,
        itemIds: string[]
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify({
                    item_ids: itemIds,
                }),
                url: `${Constants.integrationsFiles}${integrationName}/account/${accountId}/delete-data-source`,
                retryCount: 3,
            });
            return result.body.success;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };
    const fetchSourceFiles = async (
        accountId: string,
        dataSourceIds: string[],
        page: number
    ): Promise<{ data: DocumentFile[]; count: string }> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: FileListResponse;
                headers: { [key: string]: string };
            } = await triggerRequest(
                {
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify({
                        accountId,
                        dataSourceIds,
                    }),
                    url: Constants.integrationSourceFiles + page,
                    retryCount: 3,
                },
                ['x-pi-content-length']
            );
            return {
                data: result.body.data.files,
                count: result.headers['x-pi-content-length'],
            };
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };
    const fetchIntegrationSources = async (
        integrationName: string,
        accountId: string
    ): Promise<IDriveItem[]> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IIntegrationSourcesResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: `${Constants.integrationsFiles}${integrationName}/account/${accountId}/get-all-data-sources`,
                retryCount: 3,
            });
            return result.body.data.data_sources;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };
    const fetchIntegrationAccounts = async (): Promise<
        IIntegrationAccount[]
    > => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: IntegrationAccountsResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.GET,
                headers,
                url: Constants.integrationAccounts,
                retryCount: 3,
            });
            return result.body.data.accounts;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const fetchIntegrationLoginUrl = async (
        integrationName: string
    ): Promise<ILoginUrlData> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const result: {
                body: ILoginUrl;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify({
                    temp_redirect_url:
                        window.location.origin +
                        '/document-insight/integration',
                }),
                url: `${Constants.integrationsLoginUrl}${integrationName}/oauth2-login-url`,
                retryCount: 3,
            });
            return result.body.data;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };
    return {
        fetchIntegrations,
        fetchIntegrationLoginUrl,
        fetchIntegrationAccounts,
        fetchRoot,
        addSource,
        fetchIntegrationSources,
        fetchSourceFiles,
        deleteIntegration,
        deleteSource,
        getSourceChildrens,
        syncSource,
    };
};
