import {
    Box,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { FeatureName, SubFeatureName } from '../../services/interfaces';
import { usePermissions } from '../../providers/permissions-provider';
import { BorderColor } from '@mui/icons-material';

interface IProps {
    children: React.ReactNode;
    featureName: FeatureName;
    disabled?: boolean;
    placement?:
        | 'bottom'
        | 'left'
        | 'right'
        | 'top'
        | 'bottom-end'
        | 'bottom-start'
        | 'left-end'
        | 'left-start'
        | 'right-end'
        | 'right-start'
        | 'top-end'
        | 'top-start';
    subFeatureName: SubFeatureName;
    unauthorizedMessageTitle?: string;
    unauthorizedMessageCaption?: string;
}

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 12,
        border: 'none',
        maxWidth: 400,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow using theme shadows
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#fff', // Match arrow color with tooltip background
        '&::before': {
            border: '1px solid #e1dfdf',
        },
    },
}));

export const Authorize = (props: IProps) => {
    const { isFeatureAuthorized, isSubFeatureAuthorized } = usePermissions();
    return props.disabled ||
        isSubFeatureAuthorized(props.featureName, props.subFeatureName) ? (
        (props.children as ReactElement)
    ) : (
        <HtmlTooltip
            arrow
            placement={props.placement || 'top'}
            open={
                isSubFeatureAuthorized(props.featureName, props.subFeatureName)
                    ? false
                    : undefined
            }
            title={
                <Box py={2} textAlign={'center'} px={1}>
                    <Typography variant="h5">
                        {props.unauthorizedMessageTitle ||
                            'Feature Unavailable'}
                    </Typography>
                    <Typography mt={2}>
                        {props.unauthorizedMessageCaption ||
                            `This feature is not included in your current plan. Upgrade to access this functionality.`}
                    </Typography>
                </Box>
            }
        >
            <Box
                sx={{
                    cursor: isSubFeatureAuthorized(
                        props.featureName,
                        props.subFeatureName
                    )
                        ? undefined
                        : 'not-allowed',
                    '&>*': {
                        cursor: isSubFeatureAuthorized(
                            props.featureName,
                            props.subFeatureName
                        )
                            ? undefined
                            : 'not-allowed !important',
                    },
                }}
            >
                {props.children}
            </Box>
        </HtmlTooltip>
    );
};
