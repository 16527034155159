import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const WHITELISTED_PATHNAMES = ['/document-insight/file'];

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        let match = WHITELISTED_PATHNAMES.filter((path: string) =>
            pathname.includes(path)
        );
        if (match.length == 0) {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    return null;
}
